import React from "react";

import FrmSoporte from "../../../components/formularios/FrmSoporte";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const Soporte = (props) => {
  return (
    <div className="mt-5 text-center">
      <ContainerViewComponent className="bodycontent">
        <h1>Soporte al cliente</h1>
        <div>
          <FrmSoporte></FrmSoporte>
        </div>
        <br></br>
      </ContainerViewComponent>
    </div>
  );
};

export default Soporte;
