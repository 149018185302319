import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Circle,
} from "@react-google-maps/api";
import mapStyleMinimap from "../MapsGoogle/mapStyleMinimap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, LinearProgress, Tooltip } from "@mui/material";

// Definimos las librerías a cargar
const libraries = ["places", "geometry"];

// Opciones del mapa
const mapOptions = {
  styles: mapStyleMinimap,
  disableDefaultUI: false,
  zoomControl: true,
  zoom: 15,
};

// Definimos el estilo del contenedor del mapa
const mapContainerStyle = {
  height: "350px",
  width: "450px",
};

const generateRandomKey = () => {
  return Math.random().toString(36).substring(2, 7);
};

// Componente principal de la posición del mapa
export default function MapDeliveryPosition({
  markersPending,
  riderDestails,
  dispachtHistory,
}) {
  // Cargamos la API de Google Maps con la clave proporcionada
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  // Estados para los marcadores y la posición del centro del mapa
  const [markerPosition, setMarkerPosition] = useState(null);
  const [markerRder, setMarkerRider] = React.useState(null);
  const [markerDelivery, setMarkerDelivery] = React.useState(null);
  const [exceptionGeoRef, setExceptionGeoRef] = React.useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [tooltip, setTooltip] = useState("Haz clic para copiar");

  // Referencia para el mapa
  const mapRef = useRef(null);

  // Efecto para actualizar las posiciones del marcador y el centro cuando cambian las coordenadas
  useEffect(() => {
    if (markersPending && markersPending.toAddressLocation) {
      const lat = parseFloat(markersPending.toAddressLocation._lat);
      const lng = parseFloat(markersPending.toAddressLocation._long);

      if (!isNaN(lat) && !isNaN(lng)) {
        setMarkerPosition({ lat, lng });
        setMapCenter({ lat, lng });
      } else {
        console.error("Coordenadas inválidas para markersPending");
      }
    }

    if (markersPending && markersPending.exceptionGeoRef) {
      const lat = parseFloat(markersPending.exceptionGeoRef._lat);
      const lng = parseFloat(markersPending.exceptionGeoRef._long);

      if (!isNaN(lat) && !isNaN(lng)) {
        setExceptionGeoRef({ lat, lng });
      } else {
        console.error("Coordenadas inválidas para markersPending");
      }
    }
  }, [markersPending]);

  useEffect(() => {
    // if (markersPending.status !== 8) {
    if (riderDestails && riderDestails.online === true) {
      const lat = parseFloat(riderDestails.driverLocation._lat);
      const lng = parseFloat(riderDestails.driverLocation._long);
      setMarkerRider({ lat, lng });
    }
    // }
  }, [riderDestails]);

  useEffect(() => {
    //  console.log(dispachtHistory);
    if (!Array.isArray(dispachtHistory)) {
      console.error(
        "⚠️ Error: dispachtHistory no es un array",
        dispachtHistory
      );
      return; // O maneja el error de otra forma
    }
    let positionSuccess = dispachtHistory.find((item) => item.status == "99");
    if (positionSuccess) {
      const lat = parseFloat(positionSuccess.eventGps.latitude);
      const lng = parseFloat(positionSuccess.eventGps.longitude);
      setMarkerDelivery({ lat, lng });
    }
  }, [dispachtHistory]);

  useEffect(() => {}, [markerPosition]);

  // Función para cuando el mapa se carga
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  // Si hay un error de carga
  if (loadError) {
    return <div>Error al cargar el mapa</div>;
  }

  const handleCopy = () => {
    if (markerDelivery?.lat && markerDelivery?.lng) {
      const formattedData = `${markerDelivery.lat}\t${markerDelivery.lng}`;
      navigator.clipboard.writeText(formattedData);
      setTooltip("Copiado!");
      setTimeout(() => setTooltip("Haz clic para copiar"), 2000); // Restablecer el tooltip después de 2 segundos
    }
  };

  // Mientras se cargan los scripts de Google Maps
  if (!isLoaded) {
    return <LinearProgress />;
  }

  // Renderizado principal
  return (
    <div>
      <div>
        {markerDelivery?.lat && markerDelivery?.lng ? (
          <Tooltip title={tooltip} arrow>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCopy}
              endIcon={<ContentCopyIcon />}
            >
              lat: {markerDelivery.lat} lng : {markerDelivery.lng}
            </Button>
          </Tooltip>
        ) : null}
      </div>

      <div className="mapControlStyle">
        <GoogleMap
          id="mapControl2"
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={mapCenter || { lat: -33.446535, lng: -70.6513006 }}
          options={mapOptions}
          onLoad={onMapLoad}
        >
          {markerPosition && <MarkerComponent position={markerPosition} />}
          {markerRder && <MarkerComponentRider position={markerRder} />}
          {exceptionGeoRef && (
            <MarkerComponentExceptionGeoRef position={exceptionGeoRef} />
          )}
          {markerDelivery && (
            <MarkerComponentDelivery position={markerDelivery} />
          )}
          {markerDelivery && (
            <MarkerComponentDeliveryText position={markerDelivery} />
          )}
        </GoogleMap>
      </div>
    </div>
  );
}

// Componente separado para renderizar el marcador
const MarkerComponent = ({ position }) => {
  return (
    <Marker
      key={generateRandomKey()} // key dinámico
      position={position}
      icon={{
        url: "/static/images/casaDelivery.png",
        scaledSize: new window.google.maps.Size(45, 45),
        anchor: new window.google.maps.Point(17.5, 17.5),
      }}
    />
  );
};

const MarkerComponentRider = ({ position }) => {
  return (
    <Marker
      key={generateRandomKey()} // key dinámico
      position={position}
      icon={{
        url: "/static/images/conductor.png",
        scaledSize: new window.google.maps.Size(45, 45),
        anchor: new window.google.maps.Point(0.1, 0.1),
      }}
    />
  );
};

const MarkerComponentDelivery = ({ position }) => {
  const circleOptions = {
    strokeColor: "#0af079",
    strokeOpacity: 0.8,
    strokeWeight: 0.5,
    fillColor: "#187d49",
    fillOpacity: 0.01,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 300,
    zIndex: 1,
  };
  return (
    <Circle
      key={generateRandomKey()} // key dinámico
      center={{
        lat: parseFloat(position.lat), // Utilizamos las coordenadas de `position`
        lng: parseFloat(position.lng),
      }}
      options={circleOptions}
    />
  );
};

const MarkerComponentDeliveryText = ({ position }) => {
  return (
    <Marker
      key={generateRandomKey()} // key dinámico
      position={position}
      // label={{
      //   text: "🏁",
      //   color: "#800080",
      //   fontSize: "24px",
      // }}
      icon={{
        url: "/static/images/delivery-man.png",
        scaledSize: new window.google.maps.Size(35, 35),
        anchor: new window.google.maps.Point(17.5, 17.5),
      }}
    />
  );
};

const MarkerComponentExceptionGeoRef = ({ position }) => {
  return (
    <Marker
      key={generateRandomKey()} // key dinámico
      position={position}
      label={{
        text: `Excepción GeoRef`,
        color: "#F8F8F8",
        className: "my-custom-label-ping",
      }}
    />
  );
};
