import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { collection, doc, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { db } from "../../firebase";
import { registrarCambioEnOrden } from "../../redux/accionsRegister";

const NombreReceptorForm = ({
  orderDetails,
  orderID,
  usuarioStorage,
  setOrderDetails,
}) => {
  const dispatch = useDispatch();
  const [userNameReceptor, setUserNameReceptor] = useState("");
  const [hasTypedName, setHasTypedName] = useState(false);
  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  const guardarUserNameReceptor = async (e) => {
    e.preventDefault();
    let userNameReceptorClear = userNameReceptor.trim();

    let userNameReceptorClearRemove = userNameReceptorClear
      .normalize("NFD") // Descompone caracteres Unicode
      .replace(/[\u0300-\u036f]/g, "") // Quita tildes
      .replace(/[^\w\s]/gi, ""); // Elimina caracteres especiales

    let data = {
      date: new Date(),
      lastValue: orderDetails.userNameReceptor,
      newValue: userNameReceptorClearRemove,
      orderId: orderID,
      type: "infoAdd",
      userId: usuarioStorage.uid,
      var: "userNameReceptor",
    };
    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${orderID}`);
    try {
      await updateDoc(orderRef1, {
        userNameReceptor: userNameReceptorClearRemove,
      });
      setOrderDetails((prev) => ({
        ...prev,
        userNameReceptor: userNameReceptorClearRemove,
      }));
      setUserNameReceptor("");
      setHasTypedName(false);
    } catch (error) {
      console.log("Error guardando userNameReceptor", error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={guardarUserNameReceptor}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 1,
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold">
        Nombre del Receptor: {orderDetails.userNameReceptor}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          value={userNameReceptor}
          onChange={(e) => {
            setUserNameReceptor(e.target.value);
            setHasTypedName(true);
          }}
          placeholder="Ingrese nombre del receptor"
          variant="outlined"
          sx={{ mt: 1, width: "50%" }}
        />

        <Button
          variant="contained"
          sx={{
            mt: 1,
            backgroundColor: "#591E8F",
            color: "#fff",
            width: "50%",
          }}
          type="submit"
          disabled={!hasTypedName}
        >
          Guardar Nombre
        </Button>
      </Box>
    </Box>
  );
};

export default NombreReceptorForm;
