import React, { useEffect, useState, useRef } from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyledCard, StyledTextField } from "./FromStyles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  FormControl,
  Divider,
  FormGroup,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { OutlinedInput } from "@mui/material";
import GooglePlacesAutocomplete from "../../utils/objects/GooglePlacesAutocomplete";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const FromShippingOrigin = ({
  onChange,
  order,
  dataDirectory,
  companyData,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [method, setMethod] = useState(false); // Method for selecting or manual entry
  const [selectedAddress, setSelectedAddress] = useState("");

  // Sync the component with the received order data
  const [origenAdress, setOrigenAdress] = useState(
    order.fromAddress || {
      fromAddress: "",
      fromLocation: { lat: "", lng: "" },
    }
  );

  // Handle sync of order prop with local state when order changes
  useEffect(() => {
    if (order.fromAddress) {
      setOrigenAdress({
        fromAddress: order.fromAddress,
        fromLocation: order.fromLocation || { lat: "", lng: "" },
      });
    }
  }, [order]);

  // When user selects a predefined address
  const handleSelect = (event) => {
    const value = event.target.value;

    if (value === companyData.id) {
      const updatedAddress = {
        fromAddress: companyData.companyAddress,
        fromLocation: {
          lat: companyData.addressGeoreft.lat
            ? companyData.addressGeoreft.lat
            : companyData.addressGeoreft._lat,
          lng: companyData.addressGeoreft.lng
            ? companyData.addressGeoreft.lng
            : companyData.addressGeoreft._lng,
        },
      };
      setOrigenAdress(updatedAddress);
      setSelectedAddress(companyData.id);
      onChange(updatedAddress); // Propagate changes to parent
    } else {
      const selected = dataDirectory.find((item) => item.id === value);
      if (selected) {
        const updatedAddress = {
          fromAddress: selected.toAddressOrigin,
          fromLocation: {
            lat: selected.addressGeoreft.lat,
            lng: selected.addressGeoreft.lng,
          },
        };
        setOrigenAdress(updatedAddress);
        setSelectedAddress(selected.id);
        onChange(updatedAddress); // Propagate changes to parent
      }
    }
  };

  const handleSelections = (item) => {
    if (item && item.address !== "" && item.addressGeoreft !== "") {
      const updatedAddress = {
        fromAddress: item.address,
        fromLocation: item.addressGeoreft,
      };
      setOrigenAdress(updatedAddress);
      onChange(updatedAddress); // Propagate changes to parent
    }
  };

  const enterEditMode = () => {
    setShowEdit(!showEdit);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    setOrigenAdress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveEditedAddress = () => {
    onChange(origenAdress); // Propagate changes to parent
    setShowEdit(false);
  };

  const handleMethodChange = (e) => {
    setMethod(e.target.checked);
    if (!e.target.checked) {
      setSelectedAddress(""); // Reset address when switching back to manual entry
    }
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardHeader
        title={
          <Typography sx={{ color: "#fff" }}>
            {"Origen (Punto A) -> Direcciones de empresa"}
          </Typography>
        }
        sx={{
          color: "#fff",
          borderRadius: "10px 10px 0 0",
          padding: "16px",
        }}
      />
      <CardContent sx={{ padding: "1px" }}>
        <Grid item sx={{ width: "100%" }}>
          <FormGroup sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Switch
                checked={method}
                onChange={handleMethodChange}
                name="seguro"
                color="primary"
              />
              {!method ? (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    ml: 2,
                  }}
                >
                  <Typography sx={{ color: "#fff", flexGrow: 1 }}>
                    {"Ingrese una dirección"}
                  </Typography>
                  <Button onClick={enterEditMode} sx={{ color: "#FFF" }}>
                    <EditOutlinedIcon />
                    {!showEdit ? "Editar" : "Cerrar editor"}
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    ml: 2,
                  }}
                >
                  <Typography sx={{ color: "#fff", flexGrow: 1 }}>
                    {"Seleccion una dirección"}
                  </Typography>
                </Box>
              )}
            </Box>
          </FormGroup>
        </Grid>

        {method ? (
          <FormControl fullWidth margin="dense">
            <Select
              sx={{
                backgroundColor: "#fff",
                ".MuiOutlinedInput-input": {
                  borderRadius: "10px",
                  padding: "10px",
                  fontSize: "1rem",
                  color: "#333",
                },
              }}
              value={selectedAddress}
              onChange={(e) => handleSelect(e)}
              input={
                <OutlinedInput
                  notched
                  startAdornment={<LocationOnIcon color="secondary" />}
                />
              }
            >
              {dataDirectory
                .filter(
                  (item) =>
                    item.addressGeoreft && // Verifica que addressGeoreft exista
                    item.addressGeoreft.lat &&
                    item.addressGeoreft.lng // Verifica que lat y lng contengan valores válidos
                )
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.adressName}
                  </MenuItem>
                ))}

              {/* Validación para companyData */}
              {companyData.addressGeoreft &&
              companyData.addressGeoreft.lat &&
              companyData.addressGeoreft.lng ? (
                <MenuItem key={companyData.id} value={companyData.id}>
                  {companyData.companyName} (Default)
                </MenuItem>
              ) : null}
            </Select>
          </FormControl>
        ) : (
          <GooglePlacesAutocomplete
            adress={origenAdress}
            handleSelections={handleSelections}
            defaultAddress={origenAdress.fromAddress}
          />
        )}

        <Divider />
        {showEdit ? (
          <>
            <StyledTextField
              name="fromLocation.lat"
              value={origenAdress.fromLocation.lat}
              onChange={handleEditChange}
              placeholder="Latitude"
              fullWidth
              margin="normal"
              sx={{ marginTop: "1px", marginBottom: "1px" }}
            />
            <StyledTextField
              name="fromLocation.lng"
              value={origenAdress.fromLocation.lng}
              onChange={handleEditChange}
              placeholder="Longitude"
              fullWidth
              margin="normal"
              sx={{ marginTop: "1px", marginBottom: "1px" }}
            />
            <StyledTextField
              name="fromAddress"
              value={origenAdress.fromAddress}
              onChange={handleEditChange}
              placeholder="Address"
              fullWidth
              margin="normal"
              sx={{ marginTop: "1px", marginBottom: "1px" }}
            />
            <Button onClick={saveEditedAddress} sx={{ color: "#FFF", mt: 2 }}>
              Guardar cambios
            </Button>
          </>
        ) : null}
      </CardContent>
    </StyledCard>
  );
};

export default FromShippingOrigin;
