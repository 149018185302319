import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Box, Typography, Card, CardContent } from "@mui/material";
import * as routeManager from "../../../components/MapPanelViewRoutes/RouteManager";
import StaticPolygonsLayer from "../../../components/MapStaticPolygonsLayer/StaticPolygonsLayer";
import { MapVectoriales } from "../../../utils/Polygons/VectorialSatelite";
import StaticPolygonsLayer3d from "../../../components/MapStaticPolygonsLayer/StaticPolygonsLayer3D";

const comunasSummary = {
  totalOrders: 1600000,
  comunas: [
    {
      id: 1,
      name: "Ñuñoa",
      lat: -33.4543759,
      lng: -70.6217548,
      orders: 204000,
      percentage: ((204000 / 1600000) * 100).toFixed(2) + "%",
    },
    {
      id: 2,
      name: "Santiago Centro",
      lat: -33.4489,
      lng: -70.6693,
      orders: 658000,
      percentage: ((658000 / 1600000) * 100).toFixed(2) + "%",
    },
    {
      id: 3,
      name: "Providencia",
      lat: -33.4314,
      lng: -70.6095,
      orders: 67000,
      percentage: ((67000 / 1600000) * 100).toFixed(2) + "%",
    },
    {
      id: 4,
      name: "La Reina",
      lat: -33.4534,
      lng: -70.5427,
      orders: 34000,
      percentage: ((34000 / 1600000) * 100).toFixed(2) + "%",
    },
    {
      id: 5,
      name: "Recoleta",
      lat: -33.4046,
      lng: -70.6402,
      orders: 44000,
      percentage: ((44000 / 1600000) * 100).toFixed(2) + "%",
    },
    {
      id: 6,
      name: "Independencia",
      lat: -33.417,
      lng: -70.6641,
      orders: 55000,
      percentage: ((55000 / 1600000) * 100).toFixed(2) + "%",
    },
    {
      id: 7,
      name: "Las Condes",
      lat: -33.4089,
      lng: -70.5679,
      orders: 78000,
      percentage: ((78000 / 1600000) * 100).toFixed(2) + "%",
    },
    {
      id: 8,
      name: "Lo Barnechea",
      lat: -33.3517,
      lng: -70.5027,
      orders: 52000,
      percentage: ((52000 / 1600000) * 100).toFixed(2) + "%",
    },
  ],
  topComunas: ["Santiago Centro", "Ñuñoa"],
  topComunasPercentage: (((204000 + 658000) / 1600000) * 100).toFixed(2) + "%",
};

// Icono personalizado para los marcadores
const customIcon = new L.Icon({
  iconUrl: "https://leafletjs.com/examples/custom-icons/leaf-green.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const MapaCobertura = () => {
  const [selectedComuna, setSelectedComuna] = useState(null);

  return (
    <Box sx={{ width: "100%", height: "500px", position: "relative" }}>
      {/* Contenedor del Mapa */}
      <MapContainer
        center={[-33.4489, -70.6693]} // Punto central (Santiago)
        zoom={10}
        style={{ width: "100%", height: "100%", backgroundColor: "#f8f8f8" }}
      >
        {/* Capa de Mapa Base */}
        <TileLayer
          //  attribution={routeManager.attributionBICCI}
          url={routeManager.urlGrey}
        />

        {MapVectoriales ? (
          <StaticPolygonsLayer3d mapVectoriales={MapVectoriales} />
        ) : null}

        {/* Iterar sobre cada comuna y agregar un marcador */}
        {comunasSummary.comunas.map((comuna) => (
          <Marker
            key={comuna.id}
            position={[comuna.lat, comuna.lng]} // Asegúrate de agregar las coordenadas a comunasSummary si no están
            icon={customIcon}
            eventHandlers={{
              click: () => setSelectedComuna(comuna),
            }}
          >
            <Popup>
              <Typography variant="h6">{comuna.name}</Typography>
              <Typography variant="body2">
                Órdenes entregadas: {comuna.orders}
              </Typography>
              <Typography variant="body2">
                Porcentaje del total: {comuna.percentage}
              </Typography>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      {/* Tarjeta con Información de la Comuna Seleccionada */}
      {selectedComuna && (
        <Card
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            bgcolor: "white",
            boxShadow: 3,
            borderRadius: 2,
            p: 2,
            maxWidth: 250,
          }}
        >
          <CardContent>
            <Typography variant="h6" color="primary">
              {selectedComuna.name}
            </Typography>
            <Typography variant="body1">
              Órdenes entregadas: {selectedComuna.orders}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default MapaCobertura;
