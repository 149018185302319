import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import {
  LinearProgress,
  Typography,
  Avatar,
  Chip,
  Box,
  Button,
} from "@mui/material";
import { Stack } from "react-bootstrap";
import { db } from "../../../firebase.js";
import {
  collection,
  getDocs,
  where,
  query,
  getFirestore,
} from "firebase/firestore";
import PopUpAsignament from "../../../components/PopUpAsignament/PopUpAsignament.jsx";
import { differenceInDays } from "date-fns";
import { MapVectoriales } from "../../../utils/Polygons/VectorialSatelite.js";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames.js";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext.jsx";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../components/CustomDateRangePicker/index.jsx";
import Moment from "moment";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys.jsx";
import ToolFiltersComponent from "../../../components/Dashboard/dashboard/ToolFiltersComponent/ToolFiltersComponent.jsx";
import SimulatorOps from "../../../pages/Dashboard/SimulatorOps/SimulatorOps.jsx";
import MapComponentDistributions from "../../../components/MapComponentDistributions/MapComponentDistributions.jsx";
import ControlPanel from "../../../components/Dashboard/dashboard/ControlPanel/ControlPanel.jsx";
import MapComponentDistributionsLeaflet from "../../MapComponentDistributionsLeaflet/MapComponentDistributionsLeaflet.jsx";
import LoadingSpiner from "../../LoadingSpinner/LoadingSpiner.jsx";
import * as utils from "../../../utils/objects/BicciExpressOrderStatuses";
import MapComponentMonitorLeaflet from "../../MapComponentMonitorLeaflet/MapComponentMonitorLeaflet.jsx";
import SplitPane from "react-split-pane";
import MapPanelAdminRoutes from "../../MapPanelAdminRoutes/MapPanelAdminRoutes.jsx";
import useCreateSetResumenTable from "../../../hooks/useCreateSetResumenTable.js";

import { colorPalette } from "../../../utils/objects/stylesStates.jsx";
import { useActiveRidersByIds } from "../../../hooks/useActiveRidersByIds.jsx";
import useRutasDelDia from "../LiveOperationsMap/hook/useRutasDelDia.jsx";
import PopUpAsignamentRoute from "../../PopUpAsignamentRoute/PopUpAsignamentRoute.jsx";

const libraries = ["places"];

const RouteEditorMap = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [companyCollaborator, setCompanyCollaborator] = useState(null);
  const [stateUser, setStateUser] = useState(null);
  const [path, setPath] = useState([]);
  const [viewDrivers, setViewDrivers] = useState(true);
  const [viewDeliverys, setViewDeliverys] = useState(true);
  const [viewExepcions, setViewViewExepcions] = useState(true);
  const [viewPendings, setViewPendings] = useState(true);
  const [viewPoligons, setViewPoligons] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerDriver, setSelectedMarkerDriver] = useState(null);
  const [center, setCenter] = useState({ lat: -33.448891, lng: -70.669266 });
  const [zoom, setZoom] = useState(13);
  const [driver, setDriver] = useState("");
  const [driverFilter, setDriverFilter] = useState([]);
  const [ordersByPoligons, setOrdersByPoligons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState("");
  const [polygons, setPolygons] = useState(null);
  const [polygonsType, setPolygonsType] = useState("redpoligonos");
  const [opemPopUpAsignament, setOpemPopUpAsignament] = useState(false);
  const [ordersForAccions, setOrdersForAccions] = useState([]);
  const [vertical, setServiceVertical] = useState("all");
  const [polygonSelectedName, setPolygonSelectedName] = useState("");
  let infoWindowMap = {};
  const user = useSelector((store) => store.usuario.user);
  const { VehiclesNames } = useGetVehiclesNames();
  const [progressValue, setProgressValue] = useState(0);
  const [mensajeAdmin, setMensajeAdmin] = useState("");
  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);
  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);
  const [companiesOrigin, setCompaniesOrigin] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedOrigins, setSelectedOrigins] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredOrigins, setFilteredOrigins] = useState([]);
  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  const [routeColors, setRouteColors] = useState({});

  const [activeSimulationsOps, setActiveSimulationsOps] = useState(false);
  const [orderSistem, setRemoveOrderSystem] = useState(false);

  const [routeGeometry, setRouteGeometry] = useState([]); // Geometría de la ruta

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const [loading, setLoading] = useState(false);

  //Rutas a monitorear

  const [contextReq, setContextReq] = useState({
    companyID: "",
    token: "",
  });

  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
  } = useOrders({ initDateInitial, endDateInitial });

  // Hooks y efectos
  const [cantListItems, setcantListItems] = useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  //Cambio de vista
  const [mapViewByRiders, setMapViewByRiders] = useState(false);

  const [optimizedWaypoints, setOptimizedWaypoints] = useState([]); // Waypoints optimizados
  const [selectedViewRoutes, setSelectedViewRoutes] = useState([]);
  const [selectedViewLines, setSelectedViewLines] = useState([]);

  const [routeNameArrays, setRouteNameArrays] = useState([]);

  const [routeIdsArrays, setRouteIdsArrays] = useState([]);

  const [hoveredWaypoint, setHoveredWaypoint] = useState(null);

  const listItems = utils.listItems;

  const [ridersNames, setRidersNames] = useState({});

  const [ridersIds, setRidersIds] = useState({});

  const { dataResumentables } = useCreateSetResumenTable(
    viewMergedOrders,
    selectedCompanies,
    filteredCompanies
  );

  const { routes } = useRutasDelDia(companiesData);

  const ridersMonitor = useActiveRidersByIds(ridersIds);

  const [localRoutes, setLocalRoues] = useState();

  const [opemPopUpAsignamentRoute, setOpenPopUpAsignamentRoute] =
    useState(false);

  const [routeToAsing, setRouteToAsing] = useState(null);

  //Botones de filtros

  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {}, [user]);

  useEffect(() => {
    if (mergedOrders && mergedOrders.length) {
      // Buscar Drivers
      const uniqueDriverIDs = Array.from(
        new Set(
          mergedOrders
            .filter((order) => order.driverID && order.driverID.trim() !== "")
            .map((order) => order.driverID)
        )
      );

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      const driverIDChunks = chunkArray(uniqueDriverIDs, 10);

      const fetchDriverData = async () => {
        let dataResult = [];
        for (let chunk of driverIDChunks) {
          const querySnapshot = await getDocs(
            query(
              collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`),
              where("id", "in", chunk)
            )
          );
          querySnapshot.forEach((doc) => {
            const driverData = { id: doc.id, ...doc.data() };
            if (!dataResult.some((driver) => driver.id === doc.id)) {
              dataResult.push(driverData);
            }
          });
        }
        setRidersDataByCompany(dataResult);
      };

      fetchDriverData();
    }
  }, [mergedOrders]);

  useEffect(() => {
    if (!Array.isArray(routes) || routes.length === 0) return; // Validación segura
    if (!Array.isArray(selectedCompanies) || selectedCompanies.length === 0) {
      setLocalRoues(routes); // Si `selectedCompanies` está vacío, usa todas las rutas
      return;
    }

    // 🔹 Extraer companyID de `filteredCompanies` si están en `selectedCompanies`
    let selectedCompaniesCompanyID = filteredCompanies
      .filter((company) => selectedCompanies.includes(company.id)) // Filtrar solo las que coincidan
      .map((company) => company.companyID); // Extraer los companyID

    // 🔹 Si "falabella" está seleccionada, agregar manualmente su ID
    if (selectedCompanies.includes("falabella")) {
      selectedCompaniesCompanyID.push("falabella");
    }

    // 🔹 Filtrar rutas basadas en `selectedCompaniesCompanyID`
    const filteredRoutes = routes.filter((ruta) =>
      selectedCompaniesCompanyID.includes(ruta.companyID)
    );

    setLocalRoues(filteredRoutes);
  }, [selectedCompanies, routes, filteredCompanies]);

  useEffect(() => {
    if (mergedOrders) {
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  useEffect(() => {
    //-console.log("📌 localRoutes:", localRoutes);

    if (localRoutes && Array.isArray(localRoutes)) {
      let extractedWp = []; // 🔥 Inicializamos un array vacío para almacenar todos los waypoints

      const extracOptimizedWaypoints = (ruta) =>
        ruta.waypoints
          ? ruta.waypoints.flatMap((wp) =>
              wp.ids.map((id) => ({
                routeId: ruta.id, // 🔥 Agregamos el ID de la ruta
                routeName: ruta.route, // 🔥 Agregamos el nombre de la ruta
                id: id, // 🔹 ID de la orden
                index: wp.index, // 🔹 Posición en la ruta
                lat: wp.location?.lat ?? 0, // 🔹 Ubicación (validación con `??` para evitar `null`)
                lon: wp.location?.lon ?? 0, // 🔥 CORREGIDO: `lon` en lugar de `log`
                ...wp, // 🔹 Mantiene toda la información del waypoint
              }))
            )
          : []; // 🔥 Si no hay waypoints, devuelve un array vacío

      for (let n = 0; n < localRoutes.length; n++) {
        extractedWp.push(...extracOptimizedWaypoints(localRoutes[n]));
      }

      //-console.log("✅ Waypoints extraídos con ID de ruta:", extractedWp);

      // 🔹 1️⃣ Crear `ValoresAactualizar` en base a `extractedWp`
      const ValoresAactualizar = Object.fromEntries(
        extractedWp.map((wp) => [wp.id, wp.currentStatus])
      );

      //-console.log("✅ ValoresAactualizar generado:", ValoresAactualizar);

      // 🔹 2️⃣ Actualizar `viewMergedOrders` basado en `ValoresAactualizar`
      setViewMergedOrders((prevOrders) =>
        prevOrders.map((order) => ({
          ...order,
          status: ValoresAactualizar[order.id] ?? order.status, // Solo actualiza si existe en `ValoresAactualizar`
        }))
      );
      setOptimizedWaypoints(extractedWp);
      setRouteColors(extractColorsRouteMap(localRoutes));

      const extractGeometryRouteMap = (array) =>
        Object.fromEntries(
          array
            .filter((item) => item.id && item.geometry) // 🔥 Filtra rutas sin `id` o `geometry`
            .map((item) => [
              item.id,
              { routeId: item.id, geometry: item.geometry },
            ])
        );

      const rutasGeometry = extractGeometryRouteMap(localRoutes);
      //-console.log("✅ Desarrollo 5 - Rutas con geometría:", rutasGeometry);
      setRouteGeometry(rutasGeometry);
    }
  }, [localRoutes]);

  const getRandomColor = () =>
    colorPalette[Math.floor(Math.random() * colorPalette.length)];
  // routeColors -> Colores de rutas
  const extractColorsRouteMap = (rutas) =>
    Object.fromEntries(
      rutas.map((item) => [
        item.id,
        item.routeColor && item.routeColor.trim() !== ""
          ? item.routeColor
          : getRandomColor(),
      ])
    );

  useEffect(() => {
    if (dataResumentables) {
      setcantListItems(dataResumentables);
    }
  }, [dataResumentables]);

  useEffect(() => {
    if (companiesData && companiesData.length > 0) {
      setFilteredCompanies(companiesData);

      // Intentar seleccionar "FroY2Jbeir..." si existe en la lista
      const preferredCompany = null;

      // Si no existe, seleccionar el primer companyID válido que tenga token
      const defaultCompany =
        preferredCompany ||
        companiesData.find((company) => company.companyID && company.token);

      if (defaultCompany) {
        setContextReq({
          companyID: defaultCompany.companyID,
          token: defaultCompany.token,
        });
      }
    }
  }, [companiesData]);

  useEffect(() => {
    if (viewMergedOrders) {
      createSelectorOrigins(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    if (mergedOrders && ridersDataByCompany && VehiclesNames) {
      getResumenOps(mergedOrders);
    }

    if (ridersDataByCompany) {
      const ridersNames = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.name;
        return acc;
      }, {});

      setRidersNames(ridersNames);
    }

    if (ridersDataByCompany) {
      // 🔍 Filtrar solo conductores que están en línea
      const activeRiders = ridersDataByCompany
        //  .filter((rider) => rider.online === true) todos los conductore
        .map((rider) => rider.id); // ✅ Obtener solo los IDs

      setRidersIds(activeRiders); // 🔄 Guardar solo los IDs en el estado
    }
  }, [ridersDataByCompany]);

  useEffect(() => {
    if (companiesOrigin.length > 0) {
      setFilteredOrigins(companiesOrigin);
    }
  }, [companiesOrigin]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const getResumenOps = async (dataMerge) => {
    setProgressValue(75);
    setMensajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMensajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.placeName)),
      ];

      const getOrdersByLocal = (data) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.placeName;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === 8) {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});
        return Object.values(listStoreOriging);
      };

      const listStoreOriging = getOrdersByLocal(dataMerge);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID || "sinAsignacion";
        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName || "sinAsignacion",
            driverID: order.driverID || "sinAsignacion",
            email: order.email || "",
            driverAvatar: order.driverAvatar || "sinAsignacion",
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [],
            countersListOrders: [],
            countersIssuresListOrders: [],
            listOrdersData: { data: [] },
            listOrdersIssuresData: { data: [] },
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);
        acc[driverID].listOrdersData.data.push(order);

        if (order.status === 8) {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
          acc[driverID].listOrdersIssuresData.data.push(order);
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));
      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMensajeAdmin(null);
    } catch (error) {
      setProgressValue(100);
      setMensajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const memoizedData = useMemo(() => {
    if (viewMergedOrders && metricsOps && ridersDataByCompany) {
      return { dataMerge: viewMergedOrders, metricsOps, ridersDataByCompany };
    }
    return null;
  }, [viewMergedOrders, metricsOps, ridersDataByCompany]);

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "firo"];

    let tempView = mergedOrders;

    // Filtro para las compañías seleccionadas
    if (selectedCompanies.length > 0) {
      // Crear una lista temporal que combine los resultados de las diferentes compañías seleccionadas
      let filteredByCompanies = [];

      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            let tempOrigins = OriginsCompanys[companyID];
            let companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Si la compañía no está en ExternalIntegrationsOrigins, filtrar por companyID
          let companyFiltered = tempView.filter((items) => {
            return items.companyID === companyID;
          });
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });

      // Actualizamos tempView con el conjunto de resultados acumulados
      tempView = filteredByCompanies;
    }

    // Aplicar filtro por origen si hay alguno seleccionado
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    // Actualizar las vistas con los resultados filtrados
    setViewMergedOrders(tempView);
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const handleChangeDriver = (e) => {
    setDriver(e.target.value);
    const seleccionRider = memoizedData?.dataMerge?.filter(
      (item) => item.driverID === e.target.value
    );
    setDriverFilter(seleccionRider || []);
  };

  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    setSelectedOrigins(event.target.value);
  };

  const setNewDate = () => {
    const createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    const FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });
    setCompaniesOrigin(placeNames);
  };

  const handleChangeService = (e) => {
    setServiceVertical(e.target.value);
  };

  const handleTypePoligons = (e) => {
    setPolygonsType(e.target.value);
  };

  const loadStaticPolygons = () => {
    setPolygons(MapVectoriales);
  };

  const donwLoadPolygons = async () => {
    setPolygons(null);
  };

  useEffect(() => {
    if (viewPoligons) {
      switch (polygonsType) {
        case "redpoligonos":
          loadStaticPolygons();
          break;
        case "comunaspoligonos":
          setPolygonsType("redpoligonos");
          donwLoadPolygons();
          break;
        default:
          setPolygons(null);
          break;
      }
    } else {
      setPolygons(null);
    }
  }, [viewPoligons, polygonsType, isLoaded]); // Añadimos isLoaded para asegurarnos que la API esté lista

  const handleMapViewChange = (view) => {
    //-console.log("view", view);
    setMapViewByRiders(view);
  };

  const handleAsingDriver = async (id) => {
    const ruta = routes.find((item) => item.id === id);

    if (!ruta) {
      console.error(`❌ No se encontró la ruta con ID: ${id}`);
      return;
    }
    setRouteToAsing(ruta);
    setOpenPopUpAsignamentRoute(true);
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }

  // console.log("memoizedData.dataMerge", memoizedData.dataMerge);
  // console.log("viewMergedOrders", viewMergedOrders);

  return memoizedData ? (
    <div>
      {loading ? (
        <LoadingSpiner
          transparent={"transparent"}
          textMessj={"Optimizando rutas..."}
        ></LoadingSpiner>
      ) : null}

      <ToolFiltersComponent
        routes={localRoutes}
        selectedCompanies={selectedCompanies}
        selectedOrigins={selectedOrigins}
        filteredCompanies={filteredCompanies}
        filteredOrigins={filteredOrigins}
        viewRangeInputPicker={false}
        handleChange={handleChange}
        handleOrigins={handleOrigins}
        rangeInputPicker={rangeInputPicker}
        setNewDate={setNewDate}
        rightComponent={false}
        listItems={listItems}
        cantListItems={cantListItems}
        ridersNames={ridersNames}
      />
      <PopUpAsignamentRoute
        opemPopUpAsignamentRoute={opemPopUpAsignamentRoute}
        setOpenPopUpAsignamentRoute={setOpenPopUpAsignamentRoute}
        ridersDataByCompany={ridersDataByCompany}
        routeToAsing={routeToAsing}
        mergedOrders={mergedOrders}
      ></PopUpAsignamentRoute>

      {ridersDataByCompany ? (
        <PopUpAsignament
          opemPopUpAsignament={opemPopUpAsignament}
          setOpemPopUpAsignament={setOpemPopUpAsignament}
          ordersForAccions={ordersForAccions}
          ridersDataByCompany={ridersDataByCompany}
        />
      ) : null}
      {/* <ControlPanel
        activeSimulationsOps={activeSimulationsOps}
        setActiveSimulationsOps={setActiveSimulationsOps}
        orderSistem={orderSistem}
        setRemoveOrderSystem={setRemoveOrderSystem}
        driverFilter={driverFilter}
        setDriverFilter={setDriverFilter}
        memoizedData={memoizedData}
        vertical={vertical}
        handleChangeService={handleChangeService}
        polygonsType={polygonsType}
        handleTypePoligons={handleTypePoligons}
        driver={driver}
        handleChangeDriver={handleChangeDriver}
        viewDrivers={viewDrivers}
        setViewDrivers={setViewDrivers}
        viewPendings={viewPendings}
        setViewPendings={setViewPendings}
        viewDeliverys={viewDeliverys}
        setViewDeliverys={setViewDeliverys}
        viewExepcions={viewExepcions}
        setViewViewExepcions={setViewViewExepcions}
        viewPoligons={viewPoligons}
        setViewPoligons={setViewPoligons}
      ></ControlPanel> */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "450px",
          backgroundColor: "#F8F8F8",
          padding: "0px",
        }}
      >
        {selectedMarkerDriver ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
              borderBottomRightRadius: "10px",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <Avatar
              sx={{ bgcolor: "#f8f8f8", width: 70, height: 70 }}
              src={
                selectedMarkerDriver.avatar ? selectedMarkerDriver.avatar : ""
              }
              alt=""
            />
            <Typography>{selectedMarkerDriver.name}</Typography>
            <Typography>{selectedMarkerDriver.id}</Typography>
            <Typography>{selectedMarkerDriver.email}</Typography>
            <Typography>{selectedMarkerDriver.dni}</Typography>
            <Typography>{selectedMarkerDriver.bicciNumber}</Typography>
            <Typography>{selectedMarkerDriver.phone}</Typography>
            <Typography>Carga : {driverFilter.length} </Typography>
            <Button onClick={() => setSelectedMarkerDriver(null)}>
              Cerrar
            </Button>
          </Box>
        ) : null}

        {ordersByPoligons && ordersByPoligons.length > 0 ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              maxHeight: "80%",
              overflowY: "scroll",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", color: "#030303" }}
            >
              {polygonSelectedName}
            </Typography>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", color: "#030303" }}
            >
              Selección en polígono {ordersByPoligons.length} Folios
            </Typography>
            {ordersByPoligons.map((item) => (
              <Stack key={item.id} sx={{ with: "100%" }}>
                <Chip
                  size="small"
                  label={<Typography>ID: {item.id}</Typography>}
                  sx={{ mb: 1 }}
                />
              </Stack>
            ))}

            <Button onClick={() => setOrdersByPoligons([])}>Cerrar</Button>
            <Button onClick={() => setOpemPopUpAsignament(true)}>
              Asignar Bicci Riders
            </Button>
            <Button onClick={() => console.log("asignar")}>
              Asignar Bicci Express
            </Button>
          </Box>
        ) : null}

        {activeSimulationsOps ? (
          <>
            {
              <SimulatorOps
                initialData={orderSistem ? memoizedData.dataMerge : []}
                companyID={user && user.company ? user.company : "noCompanyID"}
                userId={user && user.uid ? user.uid : "noUserId"}
              ></SimulatorOps>
            }
          </>
        ) : (
          <>
            {/* Render del mapa */}
            {memoizedData && driverFilter.length === 0 ? (
              <>
                <SplitPane
                  id="SplitPaneContent"
                  split="vertical"
                  minSize={200}
                  defaultSize={"50%"}
                  style={{
                    //marginLeft: "250px",
                    height: "calc(100vh - 250px)",
                    width: "100%",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1, // Permite que el Box crezca y se ajuste dinámicamente
                      height: "100%", // Garantiza que ocupe todo el alto de la pantalla
                      overflowY: "auto", // Permite scroll si el contenido es grande
                      backgroundColor: "#fff",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <MapPanelAdminRoutes
                      hiddenfiter={true}
                      hidenChangeControl={true}
                      routes={localRoutes}
                      loading={loading}
                      setLoading={setLoading}
                      optimizedWaypoints={
                        optimizedWaypoints?.length > 0 ? true : false
                      }
                      routeGeometry={routeGeometry ? true : false}
                      selectedViewRoutes={selectedViewRoutes}
                      setSelectedViewRoutes={setSelectedViewRoutes}
                      hoveredWaypoint={hoveredWaypoint}
                      setHoveredWaypoint={setHoveredWaypoint}
                      selectedViewLines={selectedViewLines}
                      setSelectedViewLines={setSelectedViewLines}
                      ridersDataByCompany={ridersDataByCompany}
                      mapViewByRiders={"rutas"}
                      setMapViewByRiders={setMapViewByRiders}
                      handleMapViewChange={handleMapViewChange}
                      handleAsingDriver={handleAsingDriver}
                      viewMergedOrders={viewMergedOrders}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    ></MapPanelAdminRoutes>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1, // Permite que el Box crezca y se ajuste dinámicamente
                      height: "100%", // Garantiza que ocupe todo el alto de la pantalla
                      overflowY: "auto", // Permite scroll si el contenido es grande
                      backgroundColor: "#fff",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <MapPanelAdminRoutes
                      viewMergedOrders={viewMergedOrders}
                      titleComponent={"Rutas por conductor"}
                      hiddenfiter={true}
                      hidenChangeControl={true}
                      routes={localRoutes}
                      loading={loading}
                      setLoading={setLoading}
                      optimizedWaypoints={
                        optimizedWaypoints?.length > 0 ? true : false
                      }
                      routeGeometry={routeGeometry ? true : false}
                      selectedViewRoutes={selectedViewRoutes}
                      setSelectedViewRoutes={setSelectedViewRoutes}
                      hoveredWaypoint={hoveredWaypoint}
                      setHoveredWaypoint={setHoveredWaypoint}
                      selectedViewLines={selectedViewLines}
                      setSelectedViewLines={setSelectedViewLines}
                      ridersDataByCompany={ridersDataByCompany}
                      mapViewByRiders={"conductores"}
                      setMapViewByRiders={setMapViewByRiders}
                      handleMapViewChange={handleMapViewChange}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                    ></MapPanelAdminRoutes>
                  </Box>
                </SplitPane>
              </>
            ) : (
              <> Mapa de ruteo </>
            )}
          </>
        )}
      </Box>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default RouteEditorMap;
