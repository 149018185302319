import React from "react";
import { useSelector } from "react-redux";

import RegistroEmail from "../LoginEmail/RegistroEmail";
import { useParams } from "react-router-dom";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";

const Login = () => {
  const activo = useSelector((store) => store.usuario.activo);
  const { id } = useParams();

  return (
    <ContainerViewComponent className="bodyFroms" id="bodyFroms">
      <div className="mt-5 text-center"></div>
      <RegistroEmail />
    </ContainerViewComponent>
  );
};

export default Login;
