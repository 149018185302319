import { Fragment, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  Typography,
  IconButton,
  Divider,
  Avatar,
  Tooltip,
  Button,
  ListItemButton,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  globalsStatesCompanys,
  translateStatesBicciFilter,
} from "../../utils/objects/translateStates";
import {
  colorByStatus,
  contrastColorByStatus,
  stlyleClassStates,
  styleState,
} from "../../utils/objects/stylesStates";
import RecalculateRouteDialog from "./Utils/RecalculateRouteDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RouteIcon from "@mui/icons-material/Route";
import InfoIcon from "@mui/icons-material/Info";

/**
 * @param {Array} rutas - Array de rutas con estructura { id, route, driverID, waypoints: [{ driverID, currentStatus, ids, routingData, ... }], initHour, etc. }
 * @param {Array} ridersDataByCompany - Array de conductores: [{ id, name, avatar, phone }, ... ]
 */
const DriverRoutesView = ({
  rutas,
  ridersDataByCompany,
  viewMergedOrders,
  handleToggleVisibilityInfo,
  waypointsOfRider = [],
  selectedViewRouteRider = [],
  handleToggleVisibilityRider,
  routeGeometryRider = [],
  handleToggleVisibilityLinesRider,
  selectedViewLinesRider = [],
  selectedInfoRoute = [],
  metricsData,
}) => {
  const [expandedDrivers, setExpandedDrivers] = useState([]);
  const [expandedRoutes, setExpandedRoutes] = useState([]);
  const [reecalRuta, setReecalRuta] = useState(null);
  const [openRecalculateRouteDialog, setOpenRecalculateRouteDialog] =
    useState(false);

  useEffect(() => {}, [metricsData]);

  // ----------------------------------------------------------------------------------
  // 1) Nueva lógica "corrigida" para agrupar rutas por conductor basándose en waypoints
  // ----------------------------------------------------------------------------------

  const drivers = rutas.reduce((acc, ruta) => {
    // Si no hay waypoints, ignoramos
    if (!ruta.waypoints || ruta.waypoints.length === 0) {
      return acc;
    }

    // Conductor principal (opcional)
    const mainDriverOfRoute = ruta.driverID || null;

    // Recorrer cada waypoint de la ruta
    ruta.waypoints.forEach((wp) => {
      const driverID = wp.driverID || "unassigned";

      // 1) Inicializar el conductor en el acumulador
      if (!acc[driverID]) {
        // Buscar datos en ridersDataByCompany
        const driverInfo =
          driverID !== "unassigned"
            ? ridersDataByCompany.find((r) => r.id === driverID) || {}
            : {};

        acc[driverID] = {
          id: driverID,
          name: driverInfo.name || "Sin conductor asignado",
          avatar: driverInfo.avatar || "",
          phone: driverInfo.phone || "Sin teléfono",
          totalOrders: 0,
          delivered: 0,
          pending: 0,
          progress: 0, // % global
          routes: {}, // Objeto con key = ruta.id
        };
      }

      // 2) Revisar si es el conductor principal o "PARCIAL"
      const isMainDriver = mainDriverOfRoute === driverID;

      // 3) Si todavía no existe esta ruta en el objeto del conductor, crearla
      if (!acc[driverID].routes[ruta.id]) {
        acc[driverID].routes[ruta.id] = {
          id: ruta.id,
          routeName: isMainDriver
            ? `${ruta.route} (PRINCITAL)`
            : `${ruta.route} (PARCIAL)`,
          initHour: ruta.initHour || "",
          waypoints: [], // Guardaremos los waypoints reales
          totalPackages: 0,
          totalDistance: 0,
          totalTime: 0,
          completed: 0, // cuántos waypoints se han entregado
          completionPercentage: 0, // calculado luego
        };
      }

      // 4) Agregar este waypoint a la ruta del conductor
      acc[driverID].routes[ruta.id].waypoints.push(wp);

      // Sumar los bultos que hay en este waypoint
      acc[driverID].routes[ruta.id].totalPackages += wp.ids?.length || 0;

      // Sumar distancias y tiempos *parciales*
      acc[driverID].routes[ruta.id].totalDistance +=
        wp.routingData?.distanceMeters || 0;
      acc[driverID].routes[ruta.id].totalTime +=
        wp.routingData?.estimatedTimeSeconds || 0;

      // 5) Actualizar métricas globales del conductor
      acc[driverID].totalOrders += 1; // cada waypoint cuenta como 1 “orden” (o 1 parada)
      if (wp.currentStatus >= 8) {
        acc[driverID].delivered += 1;
        acc[driverID].routes[ruta.id].completed += 1;
      } else {
        acc[driverID].pending += 1;
      }
    });

    return acc;
  }, {});

  // ----------------------------------------------------------------------------------
  // 2) Calcular porcentajes globales y formatear distancias/tiempos
  // ----------------------------------------------------------------------------------

  Object.values(drivers).forEach((driver) => {
    // Calcular el % de progreso global del conductor
    if (driver.totalOrders > 0) {
      driver.progress = ((driver.delivered * 100) / driver.totalOrders).toFixed(
        2
      );
    } else {
      driver.progress = 0;
    }

    // Para cada ruta, formatear distancias y tiempos
    Object.values(driver.routes).forEach((route) => {
      // Convertir a km y min
      const distanceKm = (route.totalDistance / 1000).toFixed(2);
      const timeMin = (route.totalTime / 60).toFixed(2);

      route.totalDistance = distanceKm + " km";
      route.totalTime = timeMin + " min";

      if (route.totalPackages > 0) {
        route.completionPercentage = (
          (route.completed * 100) /
          route.totalPackages
        ).toFixed(2);
      } else {
        route.completionPercentage = "0";
      }
    });
  });

  // ----------------------------------------------------------------------------------
  // Funciones de Expansión y Diálogo
  // ----------------------------------------------------------------------------------

  const toggleDriverExpansion = (driverID) => {
    setExpandedDrivers((prev) =>
      prev.includes(driverID)
        ? prev.filter((id) => id !== driverID)
        : [...prev, driverID]
    );
  };

  const toggleRouteExpansion = (routeID) => {
    setExpandedRoutes((prev) =>
      prev.includes(routeID)
        ? prev.filter((id) => id !== routeID)
        : [...prev, routeID]
    );
  };

  const recalcularRuta = (ruta) => {
    setReecalRuta(ruta);
    setOpenRecalculateRouteDialog(true);
  };

  const HandleonCloseRecalculateRouteDialog = () => {
    setOpenRecalculateRouteDialog(false);
  };

  // ----------------------------------------------------------------------------------
  // RENDER
  // ----------------------------------------------------------------------------------

  return (
    <List>
      {Object.values(drivers).map((driver) => {
        const driverId = driver.id;
        const expandedDriver = expandedDrivers.includes(driverId);

        // Convertir las rutas del conductor (objeto) en array para mapear
        const driverRoutes = Object.values(driver.routes);

        return (
          <Box key={driverId} mb={3}>
            {/* --------------------------------- */}
            {/* Encabezado del Conductor          */}
            {/* --------------------------------- */}
            <ListItemButton
              onClick={() => toggleDriverExpansion(driverId)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                bgcolor: expandedDriver ? "#f5f5f5" : "transparent",
                borderRadius: "8px",
              }}
            >
              {/* El Diálogo de Recalcular Ruta */}
              <RecalculateRouteDialog
                open={openRecalculateRouteDialog}
                onClose={HandleonCloseRecalculateRouteDialog}
                ruta={reecalRuta}
                viewMergedOrders={viewMergedOrders}
              />

              {/* Avatar y Datos del Conductor */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar src={driver.avatar} alt={driver.name} />
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        fontSize: "1.2em",
                        fontWeight: 600,
                        color: "#030303",
                      }}
                    >
                      {driver.name}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#666",
                          display: "flex",
                          alignItems: "center",
                        }}
                        component="div"
                      >
                        <PhoneIcon sx={{ fontSize: 16, mr: 1 }} />
                        {driver.phone}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#666",
                          display: "flex",
                          alignItems: "center",
                        }}
                        component="div"
                      >
                        {`📦 Pedidos: ${driver.totalOrders} | 
                          🚚 Progreso: ${driver.progress}% | 
                          ⏳ Pendientes: ${driver.pending} | 
                          ✅ Entregados: ${driver.delivered}`}
                      </Typography>
                    </>
                  }
                />
              </Box>

              {/* Botón de expansión */}
              <IconButton>
                {expandedDriver ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItemButton>
            <Divider />

            {/* --------------------------------- */}
            {/* Rutas del Conductor (colapsables) */}
            {/* --------------------------------- */}
            <Collapse in={expandedDriver} timeout="auto" unmountOnExit>
              <List>
                {driverRoutes.map((ruta) => {
                  const isRouteExpanded = expandedRoutes.includes(ruta.id);

                  return (
                    <Box key={ruta.id} mb={2} sx={{ pl: 4 }}>
                      <ListItemButton
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#030303",
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                                component="div"
                              >
                                📍 {ruta.routeName} | 🚗 Distancia:{" "}
                                {ruta.totalDistance} | 🕒 {ruta.totalTime}
                              </Typography>

                              {/* Botón "Recalcular Ruta" si no hay routingData */}
                              {/* {ruta.totalDistance.includes("0.00") && (
                                <Button
                                  onClick={() => recalcularRuta(ruta)}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  sx={{
                                    ml: 2,
                                    textTransform: "none",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  🔄 Recalcular
                                </Button>
                              )} */}
                              <Button
                                onClick={() => recalcularRuta(ruta)}
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{
                                  ml: 2,
                                  textTransform: "none",
                                  fontSize: "0.8rem",
                                }}
                              >
                                🔄 Recalcular
                              </Button>
                            </Box>
                          }
                          secondary={
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                                component="div"
                              >
                                REF#: {ruta.id}
                              </Typography>
                              <Box>
                                📦 Ordenes de entrega: {ruta.totalPackages} | 🚛
                                Inicio: {ruta.initHour || "?"} | Progreso:{" "}
                                {ruta.completionPercentage}%
                              </Box>
                            </Box>
                          }
                        />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row", // 🔥 Alinea los elementos en columna (uno sobre otro)
                            alignItems: "end", // 🔹 Centra los elementos horizontalmente (opcional)
                            justifyContent: "end", // 🔹 Centra los elementos verticalmente (opcional)
                            gap: 0, // 🔥 Agrega espacio entre los elementos (opcional)
                            width: "20%", // 🔹 Asegura que ocupen el ancho completo
                          }}
                        >
                          {selectedInfoRoute.includes(ruta.id) && (
                            <Tooltip
                              title={
                                routeGeometryRider.includes(ruta.id)
                                  ? "Ocultar líneas"
                                  : "Mostrar líneas"
                              }
                            >
                              <IconButton
                                onClick={() =>
                                  handleToggleVisibilityLinesRider(ruta)
                                }
                                sx={{
                                  padding: "4px",
                                  margin: "2px",
                                  bgcolor: routeGeometryRider.includes(ruta.id)
                                    ? "#591e8f"
                                    : "f8f8f8",
                                  color: routeGeometryRider.includes(ruta.id)
                                    ? "#f8f8f8"
                                    : "#591e8f",
                                  borderRadius: "50%",
                                  "&:hover": {
                                    bgcolor: routeGeometryRider.includes(
                                      ruta.id
                                    )
                                      ? "#4a0e6f"
                                      : "#e0e0e0", // 🔹 Asegura que no desaparezca
                                    color: routeGeometryRider.includes(ruta.id)
                                      ? "#ffffff"
                                      : "#4a0e6f",
                                  },
                                }}
                              >
                                <RouteIcon
                                  sx={{
                                    color: routeGeometryRider.includes(ruta.id)
                                      ? "#f8f8f8"
                                      : "6634ab",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          {selectedInfoRoute.includes(ruta.id) && (
                            <Tooltip
                              title={
                                selectedViewRouteRider.includes(ruta.id)
                                  ? "Ocultar paradas"
                                  : "Mostrar paradas"
                              }
                            >
                              <IconButton
                                onClick={() =>
                                  handleToggleVisibilityRider(ruta)
                                }
                                sx={{
                                  padding: "4px",
                                  margin: "2px",
                                  bgcolor: selectedViewRouteRider.includes(
                                    ruta.id
                                  )
                                    ? "#591e8f"
                                    : "f8f8f8",
                                  color: selectedViewRouteRider.includes(
                                    ruta.id
                                  )
                                    ? "#f8f8f8"
                                    : "#591e8f",
                                  borderRadius: "50%",
                                  "&:hover": {
                                    bgcolor: selectedViewRouteRider.includes(
                                      ruta.id
                                    )
                                      ? "#4a0e6f"
                                      : "#e0e0e0", // 🔹 Asegura que no desaparezca
                                    color: selectedViewRouteRider.includes(
                                      ruta.id
                                    )
                                      ? "#ffffff"
                                      : "#4a0e6f",
                                  },
                                }}
                              >
                                {selectedViewRouteRider.includes(ruta.id) ? (
                                  <VisibilityIcon
                                    sx={{
                                      color: selectedViewRouteRider.includes(
                                        ruta.id
                                      )
                                        ? "#f8f8f8"
                                        : "6634ab",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                          {waypointsOfRider && (
                            <Tooltip
                              title={
                                selectedInfoRoute.includes(ruta.id)
                                  ? "Ocultar informacion"
                                  : "Mostrar informacion"
                              }
                            >
                              <IconButton
                                onClick={() =>
                                  handleToggleVisibilityInfo(ruta, driverId)
                                }
                                sx={{
                                  padding: "4px",
                                  margin: "2px",
                                  bgcolor: selectedInfoRoute.includes(ruta.id)
                                    ? "#591e8f"
                                    : "f8f8f8",
                                  color: selectedInfoRoute.includes(ruta.id)
                                    ? "#f8f8f8"
                                    : "#591e8f",
                                  borderRadius: "50%",
                                  "&:hover": {
                                    bgcolor: selectedInfoRoute.includes(ruta.id)
                                      ? "#4a0e6f"
                                      : "#e0e0e0", // 🔹 Asegura que no desaparezca
                                    color: selectedInfoRoute.includes(ruta.id)
                                      ? "#ffffff"
                                      : "#4a0e6f",
                                  },
                                }}
                              >
                                {waypointsOfRider.includes(ruta.id) ? (
                                  <InfoIcon
                                    sx={{
                                      color: selectedInfoRoute.includes(ruta.id)
                                        ? "#f8f8f8"
                                        : "6634ab",
                                    }}
                                  />
                                ) : (
                                  <InfoIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                          <IconButton
                            onClick={() => toggleRouteExpansion(ruta.id)}
                          >
                            {isRouteExpanded ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </Box>
                      </ListItemButton>

                      {/* ------------------------- */}
                      {/* Waypoints (colapsables)  */}
                      {/* ------------------------- */}
                      <Collapse
                        in={isRouteExpanded}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List sx={{ pl: 6 }}>
                          {ruta.waypoints && ruta.waypoints.length > 0 ? (
                            [...ruta.waypoints]
                              .sort(
                                (a, b) =>
                                  (a.index ?? ruta.waypoints.indexOf(a)) -
                                  (b.index ?? ruta.waypoints.indexOf(b))
                              )
                              .map((wp, wpIndex) => {
                                const filteredIds = wp.ids.filter(
                                  (id) => id !== "origen" && id !== "destino"
                                );

                                return (
                                  <ListItem
                                    key={wp.id || wpIndex}
                                    sx={{
                                      borderBottom: "1px solid #ddd",
                                      paddingY: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <ListItemText
                                      primary={
                                        <>
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontWeight: "bold",
                                              color: "#333",
                                            }}
                                            component="div"
                                          >
                                            🛑 Parada {wpIndex + 1}:{" "}
                                            <a
                                              href={`https://www.google.com/maps?q=${wp.location?.lat},${wp.location?.lon}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{
                                                color: "#007bff",
                                                textDecoration: "none",
                                                fontWeight: "bold",
                                                fontSize: "0.8em",
                                              }}
                                            >
                                              Ver GoogleMaps
                                            </a>
                                          </Typography>
                                        </>
                                      }
                                      secondary={
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 0.5,
                                          }}
                                        >
                                          {/* Estado e ID */}
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: 1,
                                              alignItems: "center",
                                            }}
                                          >
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontSize: "0.85em",
                                                  fontWeight: "bold",
                                                  color:
                                                    stlyleClassStates[
                                                      wp.currentStatus
                                                    ] || "#000",
                                                }}
                                                component="div"
                                              >
                                                Estado:{" "}
                                              </Typography>
                                              <>
                                                {" "}
                                                <div>
                                                  {!wp.intState ? (
                                                    <span
                                                      className={
                                                        stlyleClassStates[
                                                          wp.currentStatus
                                                        ]
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          colorByStatus[
                                                            wp.currentStatus
                                                          ],
                                                        color:
                                                          contrastColorByStatus[
                                                            wp.currentStatus
                                                          ],
                                                      }}
                                                    >
                                                      {
                                                        translateStatesBicciFilter[
                                                          wp.currentStatus
                                                        ]
                                                      }
                                                    </span>
                                                  ) : (
                                                    <div
                                                      className={
                                                        styleState[wp.intState]
                                                      }
                                                    >
                                                      {
                                                        globalsStatesCompanys[
                                                          "F"
                                                        ][wp.intState]
                                                      }
                                                    </div>
                                                  )}
                                                </div>
                                              </>
                                            </Box>

                                            <strong>
                                              Carga: {filteredIds.length}📦 |
                                            </strong>

                                            {/* IDs enlazados */}
                                            {filteredIds.map((id, idx) => (
                                              <Fragment key={id}>
                                                <a
                                                  href={`https://bicciexpress.cl/dashboard/dispatch/details/${id}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    fontWeight: "bold",
                                                    textDecoration: "none",
                                                    color: "blue",
                                                  }}
                                                >
                                                  {id}
                                                </a>
                                                {idx < filteredIds.length - 1 &&
                                                  ", "}
                                              </Fragment>
                                            ))}
                                          </Box>

                                          {filteredIds.map((id, idx) => (
                                            <Fragment key={id}>
                                              {metricsData &&
                                              metricsData[id] ? (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 0.5,
                                                  }}
                                                >
                                                  <Box key={id}>
                                                    <Typography variant="body2">
                                                      🕒 SLA Operación (Min:){" "}
                                                      {metricsData[id]?.[
                                                        "SLA Operación (Min)"
                                                      ] || "N/A"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      🕒 SLA Bulto (Min) :{" "}
                                                      {metricsData[id]?.[
                                                        "SLA Bulto (Min)"
                                                      ] || "N/A"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      🕒 Tiempo Espera Origen
                                                      (Min):{" "}
                                                      {metricsData[id]?.[
                                                        "Tiempo Espera Origen (Min)"
                                                      ] || "N/A"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      🕒 Tiempo Tránsito (Min) :{" "}
                                                      {metricsData[id]?.[
                                                        "Tiempo Tránsito (Min)"
                                                      ] || "N/A"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                      🕒 Tiempo Espera Destino
                                                      (Min) :{" "}
                                                      {metricsData[id]?.[
                                                        "Tiempo Espera Destino (Min)"
                                                      ] || "N/A"}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              ) : (
                                                <></>
                                              )}
                                            </Fragment>
                                          ))}

                                          {/* Distancia/tiempo del waypoint */}
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              fontSize: "0.85rem",
                                              color: "#666",
                                            }}
                                            component="div"
                                          >
                                            {wp.routingData ? (
                                              <>
                                                📏{" "}
                                                {
                                                  wp.routingData
                                                    .formattedDistance
                                                }{" "}
                                                | 🕒{" "}
                                                {wp.routingData.formattedTime} |
                                                🚦 {wp.routingData.segment}
                                              </>
                                            ) : (
                                              "Sin datos de ruta"
                                            )}
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </ListItem>
                                );
                              })
                          ) : (
                            <Typography variant="body2">
                              🔹 No hay waypoints
                            </Typography>
                          )}
                        </List>
                      </Collapse>
                    </Box>
                  );
                })}
              </List>
            </Collapse>
          </Box>
        );
      })}
    </List>
  );
};

export default DriverRoutesView;
