import { getFarthestPoint } from "./getFarthestPoint";

export const generateManualRoutingInverseData = async (
  route,
  dataMerge,
  origen,
  destino
) => {
  if (
    !route ||
    !Array.isArray(route.waypoints) ||
    route.waypoints.length === 0
  ) {
    console.error("⛔ No se han asignado rutas manualmente.");
    return [];
  }
  if (!Array.isArray(dataMerge) || dataMerge.length === 0) {
    console.error("⛔ No hay órdenes disponibles para asignar.");
    return [];
  }

  // 🔹 Filtrar todas las órdenes asignadas a los waypoints de la ruta
  const assignedOrders = dataMerge.filter((order) =>
    route.waypoints.some((wp) => wp.id === order.id)
  );

  if (assignedOrders.length === 0) {
    console.warn(`⚠️ La ruta ${route.id} no tiene órdenes asignadas.`);
    return [];
  }

  // ✅ Validamos el origen
  let validOrigen =
    origen && typeof origen.lat === "number" && typeof origen.lng === "number"
      ? origen
      : {
          id: `origen`,
          name: "",
          lat: assignedOrders[0]?.fromAddressLocation?._long || 0,
          lon: assignedOrders[0]?.fromAddressLocation?._lat || 0,
        };

  // ✅ Validamos el destino
  let validDestino =
    destino &&
    typeof destino.lat === "number" &&
    typeof destino.lng === "number"
      ? { id: `destino`, name: "", lat: destino.lat, lon: destino.lng }
      : { id: `destino`, name: "", lat: validOrigen.lat, lon: validOrigen.lon };

  // 🔹 Construir la lista de waypoints
  const waypoints = assignedOrders.map((order) => ({
    id: order.id,
    name: "",
    lat: order.toAddressLocation?._long || 0,
    lon: order.toAddressLocation?._lat || 0,
  }));

  // ✅ Generar una única solicitud de optimización basada en la ruta manual
  const routeOptimizationPayload = {
    vehicle: "driving",
    route: route.id, // 📌 Solo un resultado para la ruta
    waypoints: [validOrigen, ...waypoints, validDestino], // Agregar origen y destino
  };

  return [routeOptimizationPayload]; // 🔹 Retorna un solo array con un solo resultado
};
