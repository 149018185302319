import React from "react";

import Bannerfooter from "../../../../../components/Bannerfooter/Bannerfooter";
import { Box, Button, CardActionArea, Container, Grid2 } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useGlobalConfig from "../../../../../hooks/globalConfig";
import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";
const Pos5 = () => {
  const footermensaje = {
    className: "resaltadoCurvo FloatRignt marging20",
    title: "CIUDADES INTELIGENTES",
    paragramh:
      "La electromovilidad contribuye también al desarrollo de ciudades inteligentes o smart cities (en inglés), concepto que apunta al desarrollo urbano sostenible, dando respuesta a las necesidades de la sociedad, pero también de organizaciones y empresas, buscando la eficiencia energética y la reducción de emisiones de CO2, al mismo tiempo que el desarrollo económico y el cuidado del medioambiente.",
  };

  const {
    img1,
    img2,
    img3,
    img4,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    ecotribicci,
    homeImgUrl,
    biccidashboard,
    ecotribicci2,
    cobertura,
    btnvehicle,
    btnuseradmin,
    btnacuerdo,
    icomshome1,
    icomshome2,
    icomshome3,
    icomshome4,
    icomshome5,
  } = useGlobalConfig();

  //const { totalDelivery, totalCal } = useGetInfo();

  const [totalDelivery, setTotalDelivery] = useState(36000);
  const [totalCal, setTotalCal] = useState(26000);
  const [clientsEstimat, setClientsEstimat] = useState(26000);

  useEffect(() => {
    callUptade();
  }, []);

  async function callUptade() {
    try {
      const ordersDispachtRef = collection(db, "xpress_config");
      const docRefc = doc(ordersDispachtRef, "fromdConfig");
      const querySnapshot = await getDoc(docRefc);
      const totalDocuments = querySnapshot.data().totalDelivery;
      setTotalDelivery(totalDocuments);
      let estimaet = totalDelivery * 0.0005;
      let clientsEstimate = totalDelivery * 0.85;
      setClientsEstimat(clientsEstimate);
      setTotalCal(estimaet + 26000);
    } catch (error) {}
  }

  const features = [
    {
      img: btnvehicle,
      text: "Durante los plazos de entrega, usted y sus clientes pueden saber exactamente adónde va el paquete con nuestra tecnología de seguimiento en tiempo real.",
    },
    {
      img: btnuseradmin,
      text: "El reloj comienza a correr cuando crea la orden en nuestro sistema y uno de nuestros riders se ocupará de entregar el pedido en las manos de tu cliente.",
    },
    {
      img: btnacuerdo,
      text: "Potenciamos a nuestros clientes asegurando soluciones logísticas enfocadas en el objetivo correcto, con nuestro excelente servicio y la última tecnología.",
    },
  ];

  return (
    <div>
      <Grid2 celled="internally">
        {/* Sección de Introducción */}

        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
            flexDirection: "row", // Asegura que los elementos se apilen correctamente
            alignItems: "center", // Alinea elementos en el centro verticalmente
            justifyContent: "center", // Alinea elementos en el centro horizontalmente
            px: 3,
            py: 4,
            maxWidth: "1200px",
            width: "100%", // Asegura que use todo el ancho disponible
            mx: "auto", // Centra horizontalmente en el contenedor padre
          }}
        >
          <Grid2 item xs={12} md={6}>
            <Typography
              variant="h3"
              fontWeight="bold"
              color="primary"
              sx={{ fontSize: { xs: "2rem", md: "3rem" } }}
            >
              BICCI
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Última milla sustentable.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Somos BICCI, una Startup de última milla sustentable desarrollada
              para empresas. Ofrecemos un novedoso servicio de entrega a través
              de nuestros vehículos eléctricos, reduciendo la huella de carbono.
            </Typography>
          </Grid2>
          <Grid2 item xs={12} md={6}>
            <Box
              component="img"
              src={homeImgUrl}
              alt="Bicci"
              sx={{ width: "100%", borderRadius: 2, boxShadow: 3 }}
            />
          </Grid2>
        </Box>

        <div className="spacedivider"></div>

        <Box
          sx={{
            backgroundColor: "#741AB0 ",
            color: "white",
            py: 4,
            textAlign: "center",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Llega a más clientes con nuestras modalidades de envío
          </Typography>
          <Grid2 container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
            {[
              {
                img: img1,
                title: "Quick Commerce",
                desc: "Entrega en menos de 30 minutos.",
              },
              {
                img: img11,
                title: "Servicio Express",
                desc: "Entrega en aproximadamente 3 horas.",
              },
              {
                img: img2,
                title: "Same Day",
                desc: "Entrega el mismo día antes de las 21h.",
              },
              {
                img: img3,
                title: "Next Day",
                desc: "Entrega programada para el día siguiente.",
              },
              {
                img: img4,
                title: "Logística Inversa",
                desc: "Servicio de devoluciones fácil y rápido.",
              },
            ].map((item, index) => (
              <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card sx={{ maxWidth: 300, mx: "auto", minHeight: 400 }}>
                  <CardActionArea>
                    <Box
                      component="img"
                      src={item.img}
                      alt={item.title}
                      sx={{
                        width: "100%",
                        minHeight: "280px",
                        borderRadius: 1,
                      }}
                    />
                    <CardContent>
                      <Typography variant="h6" fontWeight="bold">
                        {item.title}
                      </Typography>
                      <Typography variant="body2">{item.desc}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </Box>
        <div className="spacedivider"></div>

        <Grid2 container spacing={4} alignItems="center" sx={{ px: 3, py: 6 }}>
          {/* Imagen del Dashboard */}
          <Container
            maxWidth="md"
            sx={{
              mt: 4,
              mb: 4,
              display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
            }}
          >
            <Grid2 item xs={12} md={6} sx={{ textAlign: "center" }}>
              <NavLink to="/eco-bicci">
                <Box
                  component="img"
                  src={biccidashboard}
                  alt="Dashboard Bicci"
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    height: "auto",
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                />
              </NavLink>
            </Grid2>

            {/* Descripción del Dashboard */}

            <Grid2 container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
              <Box item xs={12} md={6} sx={{ p: 2 }}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color="primary"
                  sx={{ fontSize: { xs: "2rem", md: "2.5rem" } }}
                >
                  Bicci Dashboard
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  Accede a herramientas avanzadas para gestionar tus envíos,
                  monitorear entregas en tiempo real y optimizar la logística
                  con tecnología inteligente.
                </Typography>
                {/* <Button
              component={NavLink}
              to="/eco-bicci"
              variant="contained"
              color="primary"
              sx={{ mt: 3, fontSize: { xs: "0.9rem", md: "1rem" } }}
            >
              Ver más
            </Button> */}
              </Box>
            </Grid2>
          </Container>
        </Grid2>

        <div className="spacedivider"></div>

        <Grid2 className="bannertiposdeservicio fondobicci">
          <Grid2 computer={16} mobile={16} tablet={16}>
            <div className="colorWhite font-sizeBig">
              <h2 className="colorWhite">
                {" "}
                <strong>Herramientas para mejorar tu negocio</strong>{" "}
              </h2>
            </div>
            <div className="cardHomeContent">
              <Card
                className="cardHome3"
                sx={{ maxWidth: 300, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_badgeContainer">
                    <span className="services_badge">Próximamente</span>
                  </div>

                  <div className="services_header">
                    <span className="services_border"></span>PUNTOS DE DESPACHO
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img12} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="h5"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Genera tus órdenes de con diferentes puntos despacho.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_badgeContainer"></div>
                  <div className="services_header">
                    <span className="services_border"></span>INTEGRACIONES
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img13} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="h5"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Es posible integrarnos a tu plataforma y generar ordenes
                      automaticas en cada compra. previa evaluación.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_badgeContainer">
                    <span className="services_badge">Nuevo</span>
                  </div>
                  <div className="services_header">
                    <span className="services_border"></span>GESTIÓN DE RUTAS
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img14} alt="bicci" className="Home_cardContent3" />
                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="h5"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Ordena las fechas y prioridades de entregas con una ruta
                      óptima y eficiente.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <br></br>
          </Grid2>
        </Grid2>

        <div className="spacedivider"></div>

        <div className="spacedivider"></div>

        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
            flexDirection: "row", // Asegura que los elementos se apilen correctamente
            alignItems: "center", // Alinea elementos en el centro verticalmente
            justifyContent: "center", // Alinea elementos en el centro horizontalmente
            px: 3,
            py: 4,
            maxWidth: "1200px",
            width: "100%", // Asegura que use todo el ancho disponible
            mx: "auto", // Centra horizontalmente en el contenedor padre
          }}
        >
          <Grid2 item xs={12} md={6}>
            <Box
              component="img"
              src={ecotribicci}
              alt="Electromovilidad"
              sx={{ width: "100%", borderRadius: 2, boxShadow: 3 }}
            />
          </Grid2>
          <Grid2 item xs={12} md={6}>
            <Typography variant="h4" fontWeight="bold" color="primary">
              Beneficios de la Electromovilidad
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Un vehículo eléctrico es CERO EMISIÓN, reduciendo la contaminación
              local y mejorando la calidad del aire en ciudades.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              La electromovilidad combate el cambio climático al usar
              electricidad en vez de combustibles fósiles.
            </Typography>
          </Grid2>
        </Box>

        {/* Sección de Métricas */}
        <Box
          sx={{
            backgroundColor: "#741AB0 ",
            color: "white",
            py: 6,
            textAlign: "center",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Nuestros Logros
          </Typography>
          <Grid2 container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
            {[
              {
                img: icomshome5,
                value: totalDelivery,
                label: "Productos entregados",
              },
              {
                img: icomshome3,
                value: clientsEstimat,
                label: "Clientes felices",
              },
              { img: icomshome2, value: 7, label: "Tiendas afiliadas" },
              {
                img: icomshome4,
                value: 36,
                label: "Tiendas usan nuestro shipping",
              },
              { img: icomshome1, value: totalCal, label: "CO2 Ahorrados (TH)" },
            ].map((item, index) => (
              <Grid2 item xs={12} sm={6} md={4} lg={2} key={index}>
                <Card sx={{ maxWidth: 250, mx: "auto", minHeight: 200 }}>
                  <CardActionArea>
                    <Box
                      component="img"
                      src={item.img}
                      alt={item.label}
                      sx={{ width: "100%", borderRadius: 1 }}
                    />
                    <CardContent>
                      <Typography variant="h5" fontWeight="bold">
                        <CountUp start={0} end={item.value} />
                      </Typography>
                      <Typography variant="body2">{item.label}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </Box>

        <div className="spacedivider"></div>

        <Grid2 className="rowmarging">
          <Grid2 computer={16} mobile={16} tablet={16}>
            <div className="text-center">
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo subtilepage">
                  SOLICITAR COBERTURA.
                </h2>
              </span>
            </div>
          </Grid2>
        </Grid2>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row", // Asegura que los elementos se apilen correctamente
            alignItems: "center", // Alinea elementos en el centro verticalmente
            justifyContent: "center", // Alinea elementos en el centro horizontalmente
            px: 3,
            py: 4,
            maxWidth: "1200px",
            width: "100%", // Asegura que use todo el ancho disponible
            mx: "auto", // Centra horizontalmente en el contenedor padre
          }}
        >
          <Grid2 className="rowmarging">
            <Grid2 computer={8} mobile={16} tablet={6}>
              <Box
                component="img"
                src={cobertura}
                alt=""
                sx={{ width: "100%", height: "auto" }}
              />
            </Grid2>
            <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
              <div>
                <h2 className="resaltadoCurvo columnright">¿Porque Bicci?</h2>
                <h1 className="resaltado columnright">
                  Conviértete en un punto de recolección de paquetes
                </h1>
                <h3 className="columnright">
                  Genera ingresos extras desde tu local. Aumenta la circulación
                  de potenciales clientes en tu negocio, con la posibilidad de
                  aumentar tu ticket promedio de ventas.
                </h3>
                <div className="columnright">
                  <NavLink
                    className="btn btn-ebiex m-1"
                    to="/contacto"
                    exact="true"
                  >
                    Incluir mi ubicación
                  </NavLink>
                </div>
              </div>
            </Grid2>
          </Grid2>
        </Box>

        <Grid2 className="bannertiposdeservicio">
          <Grid2 computer={16} mobile={16} tablet={16}>
            <br></br>
            <div className="colorBlack font-sizeBig">
              <h2>
                <strong>¿Por qué elegir a bicci ?</strong>{" "}
              </h2>
            </div>
            <div className="cardHomeContent">
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>ASESORÍA DE
                    EXPERTOS
                  </div>
                  <img src={img16} alt="bicci" className="Home_cardContent3" />
                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Nos preocupamos por darte la mejor atención. Nuestros
                      asesores dan seguimiento durante todo el recorrido de tu
                      producto hasta llegar a su destino final. Te notificaremos
                      según tus preferencias.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>

              <Card
                className="cardHome3"
                sx={{ maxWidth: 300, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>SERVICIOS DE
                    ENCOMIENDAS Y MENSAJERÍAS
                    {/* <img src={img11}   className='Home-cardContent-services' alt="Icon text"/> */}
                  </div>

                  <img src={img17} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Puedes cotizar el despacho de tu producto de forma simple,
                      también una vez iniciada la ruta podrás verificar su
                      status en tiempo real
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>¡QUEREMOS SER PARTE
                    DE TU ÚLTIMA MILLA!
                  </div>

                  <img src={img15} alt="bicci" className="Home_cardContent3" />

                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Tenemos soluciones adaptadas a tus necesidades, Te damos
                      una experiencia de usuario única. Dale un Valor agregado a
                      tus productos al confiar tus despachos en nosotros.
                      Cuidamos el medio ambiente y reducimos la huella de
                      carbono con nuestros vehículos eco-sustentables
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                className="cardHome3"
                sx={{ maxWidth: 280, margin: 2, minWidth: 280 }}
              >
                <CardActionArea>
                  <div className="services_header">
                    <span className="services_border"></span>SERVICIOS DE
                    CALIDAD
                  </div>

                  <img
                    src={img18}
                    alt="SERVICIO"
                    className="Home_cardContent3"
                  />
                  <CardContent>
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      variant="body1"
                      color="text.secondary"
                      className="min-heght"
                    >
                      Una variedad de servicios que cubran las necesidades de
                      nuestros clientes, dentro de un ambiente amigable,
                      transparente y ecológico, con una atención personalizada e
                      innovadora, logrando con ello poner el mundo a su alcance.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <br></br>
          </Grid2>
        </Grid2>

        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
            flexDirection: "row", // Asegura que los elementos se apilen correctamente
            alignItems: "center", // Alinea elementos en el centro verticalmente
            justifyContent: "center", // Alinea elementos en el centro horizontalmente
            px: 3,
            py: 4,
            maxWidth: "1200px",
            width: "100%", // Asegura que use todo el ancho disponible
            mx: "auto", // Centra horizontalmente en el contenedor padre
          }}
        >
          {" "}
          <Grid2 computer={8} mobile={16} tablet={6}>
            <Box
              component="img"
              src={ecotribicci2}
              alt=""
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid2>
          <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                EVITA LA CONTAMINACIÓN ACÚSTICA:
              </h2>
              <p>
                Los vehículos eléctricos casi no emiten ruido, esto se hace más
                relevante en flotas numerosas de vehículos, en vehículos pesados
                con motores diesel o en vehículos operando a velocidades por
                sobre los 50 (km/h)
              </p>
            </div>
            <br></br>
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                GESTIÓN DE RESIDUOS RESPONSABLE:
              </h2>
              <p>
                Los mecanismos y tecnologías de los vehículos eléctricos no
                requieren de lubricantes y filtros, lo que reduce el manejo de
                residuos peligrosos. Por otra parte, los sistemas de freno
                sufren menor desgaste dado que los vehículos eléctricos emplean
                un sistema de frenos regenerativos. Las baterías eléctricas,
                luego de cumplir su ciclo de vida en el vehículo (8 a 10 años)
                pueden seguir siendo usadas para aplicaciones estacionarias (10
                - 15 años) y posteriormente pueden ser recicladas.{" "}
              </p>
            </div>
            <br></br>
            <div>
              <h2 className="resaltadoCurvo subtilepage">
                {" "}
                BENEFICIOS DE LA ELECTROMOVILIDAD
              </h2>
              <p>
                Cuando logramos transportar una determinada cantidad de
                kilómetros a personas o carga pero utilizando menos energía,
                entonces estamos haciendo eficiencia energética en el
                transporte. Un vehículo eléctrico gasta en torno a un quinto de
                la energía que un vehículo a combustión equivalente,
                principalmente porque las eficiencias de un motor de combustión
                interna no superan el 50% mientras que las de un motor eléctrico
                están por sobre un 95%. Además, los vehículos eléctricos tienen
                la ventaja de recuperar energía gracias a los sistemas de frenos
                regenerativos. Por lo tanto, para una misma distancia y una
                misma condición de carga o pasajeros transportados, esta
                eficiencia energética se traduce en un ahorro económico en la
                operación.
              </p>
            </div>
          </Grid2>
        </Box>

        <div className="spacedivider"></div>

        <Grid2 className="rowmargingsmall">
          <Grid2 computer={16} mobile={16} tablet={16}>
            <div className="text-center">
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo subtilepage">MÁS SOBRE BICCI.</h2>
              </span>
            </div>
          </Grid2>
        </Grid2>
        <br></br>
        <Grid2 className="rowmarging">
          <Grid2 computer={8} mobile={16} tablet={6}>
            {/* Cards con información */}
            <Box
              container
              spacing={3}
              justifyContent="center"
              sx={{
                display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
              }}
            >
              {features.map((feature, index) => (
                <Card
                  key={index}
                  sx={{
                    background:
                      "linear-gradient(to right bottom, #a4f6ff, #8df1fd, #72ecfc, #50e6fb, #00e1fb)",
                    boxShadow: 4,
                    borderRadius: 3,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    p: 2,
                    m: 1,
                  }}
                >
                  <Box
                    component="img"
                    src={feature.img}
                    alt=""
                    sx={{
                      width: "300px",
                      minWidth: "150px",
                      height: "auto",
                      mb: 1,
                    }}
                  />
                  <CardContent>
                    <Typography variant="body1" fontSize="1.1rem">
                      {feature.text}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>

      <Bannerfooter footermensaje={footermensaje} />
    </div>
  );
};

export default Pos5;
