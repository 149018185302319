import { Box } from "@mui/material";
import FormLogin from "./FormLogin";
import React from "react";

function formularioDeLoging() {
  return <FormLogin />;
}
function LoginEmail() {
  // const [isRegistrando, setIsRegistrando] = useState(false);
  // return(
  //   <div className="mt-5 text-center">
  //     <h2>{isRegistrando ? "Inicia sesión con email" : "Regístrate"  }</h2>
  //     <button className="btn btn-ebiex m-1" onClick={() => setIsRegistrando(!isRegistrando)}>
  //       {isRegistrando ? "Quiero registrarme": "Ya tengo una cuenta" }
  //     </button>
  //       {
  //         isRegistrando ?  formularioDeLoging() : formularioDeRegistro()
  //       }
  //   </div>
  // )
  return (
    <div className="mt-5 text-center formcontainer">
      <div id="brandsLogo">
        <Box
          component="img"
          src={
            "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(24).svg?alt=media&token=ef6abc30-ba25-4422-bd45-5821ae5dc2cf"
          }
          alt=""
          sx={{
            height: "auto",
            maxWidth: "100px",
            backgroundColor: "#fff",
            borderRadius: "100%",
          }}
        />
      </div>
      <h2>INICIAR SESIÓN</h2>
      {formularioDeLoging()}
    </div>
  );
}

export default LoginEmail;
