import L from "leaflet";

export const createSVGIcon = (color, strokeWidth = 9) => {
  return L.divIcon({
    className: "",
    html: `
      <svg width="27" height="25" viewBox="0 0 395.71 395.71" xmlns="http://www.w3.org/2000/svg">
        <g>
          <!-- Borde exterior grueso -->
          <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
            c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
            C335.179,61.609,273.569,0,197.849,0z"
            fill="${color}" stroke="black" stroke-width="${strokeWidth}"/>
          
          <!-- Círculo interior -->
          <circle cx="197.849" cy="137.329" r="49.191" fill="#fff" stroke="black" stroke-width="3"/>
        </g>
      </svg>
    `,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
};

export const urlGrey =
  "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png";

export const attributionBICCI = `
  <div style="display: flex; align-items: center;">
    <img src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00" width="90" height="40" style="margin-right: 5px;" alt="BICCI Logo"/>
  </div>
`;
