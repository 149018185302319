import { db } from "../firebase";
import moment from "moment";
import axios from "axios";
import {
  doc,
  query,
  setDoc,
  collection,
  getDocs,
  where,
} from "firebase/firestore";

// data inicial
const dataInicial = {
  upload: false,
};

// types

const USER_NEW_REGISTER_DATA = "USER_NEW_REGISTER_DATA";
const USER_NEW_REGISTER_DATA_ERROR = "USER_NEW_REGISTER_DATA_ERROR";
const GET_REGISTER_DATA = "GET_REGISTER_DATA";

// reducer
export default function usuarioRegistrosDeCambios(state = dataInicial, action) {
  switch (action.type) {
    case USER_NEW_REGISTER_DATA:
      return { ...state, upload: true };
    case USER_NEW_REGISTER_DATA_ERROR:
      return { ...state, upload: false };
    case GET_REGISTER_DATA:
      return { ...action.payload };
    default:
      return { ...state };
  }
}

export const registrarCambioEnOrden = (data) => async (dispatch) => {
  try {
    // Normalizar los valores para evitar null o undefined
    const sanitizedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, value ?? ""])
    );

    const docRef = doc(collection(db, process.env.REACT_APP_COL_XPRESSLOGS));
    await setDoc(docRef, sanitizedData);

    console.log("Salvado ", docRef.id);
  } catch (error) {
    console.error("Error al registrar cambio en orden:", error);
  }
};

export const obtnerCambioEnOrden = (id) => async (dispatch) => {
  try {
    const collectionRef = collection(db, process.env.REACT_APP_COL_XPRESSLOGS);

    const q = query(collectionRef, where("orderId", "==", id));

    const querySnapshot = await getDocs(q);

    const dataOrdesLogs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    dataOrdesLogs.forEach((item) => {
      item.date.totalNanoseconds =
        item.date.seconds * 1e9 + item.date.nanoseconds;
    });
    dataOrdesLogs.sort(
      (a, b) => a.date.totalNanoseconds - b.date.totalNanoseconds
    );

    dispatch({
      type: GET_REGISTER_DATA,
      payload: { upload: true, logs: dataOrdesLogs },
    });
  } catch (error) {
    console.log(error);
  }
};

export const registrarNotifyArrival = (data) => async (dispatch) => {
  try {
    console.log(data);
    const docRef = doc(collection(db, "xpress_positionAttendanceRecord"));
    const reponse = await setDoc(docRef, data);
  } catch (error) {
    console.log(error);
  }
};
