import React from "react";
import useGlobalConfig from "../../../../hooks/globalConfig";
import { Grid2, Box } from "@mui/material";
const Tyc = () => {
  const { siteName, logoOficialUrl1x4 } = useGlobalConfig();
  return (
    <Grid2 celled="internally">
      <Grid2>
        <Grid2 computer={16} mobile={16} tablet={16}>
          <Box
            component="img"
            src={logoOficialUrl1x4}
            alt=""
            sx={{ width: "100%", height: "auto" }}
          />
        </Grid2>
        <Grid2 computer={16} mobile={16} tablet={16} id="paragraph1">
          <div>
            <h2>Términos y Condiciones</h2>
            <p>
              Los presentes términos y condiciones (en adelante los “Términos y
              Condiciones”) han sido preparados por PRINCITAL SPA (en adelante
              la “Compañía”) para el uso de sus herramientas y softwares de
              planificación y seguimiento de entregas de última milla, “Last
              Mile” (en adelante los “Productos”) a través de la solución
              tecnológica {siteName} (en adelante plataforma)
            </p>
            <p>
              Al acceder, usar o registrarse para una cuenta de usuario (en
              adelante una “Cuenta”), usted reconoce que ha leído y aceptado
              estos Términos y Condiciones en su totalidad y sin modificaciones.{" "}
            </p>
            <p>
              Los presentes Términos y Condiciones rigen todo uso del sitio web
              de la Compañía http://www.bicci.cl/ (en adelante el “Sitio”) y
              todo el contenido, servicios y productos disponibles en el sitio
              web, las aplicaciones de iOS y Android, incluyendo los Productos.
            </p>
            <p>
              Estos Términos y Condiciones sirven como un contrato vinculante
              entre {siteName} Y el usuario con respecto al uso de la
              plataforma. Además, acepta estar sujeto a la Política de
              Privacidad de la Compañía, a la que se puede acceder en el Sitio.
            </p>
            <p>
              Tal como se utiliza en estos Términos y Condiciones, las palabras
              "usted" y "su" se refieren a usted personalmente o, si accede a{" "}
              {siteName} en nombre de una persona jurídica o entidad, esa
              persona jurídica o entidad. La Compañía se reserva el derecho de
              revisar estos Términos y Condiciones en cualquier momento mediante
              la actualización de este documento. Tras dicha actualización, se
              le alertará sobre el cambio mediante una notificación dentro de{" "}
              {siteName}. Al continuar usando {siteName} después de dicha
              notificación, usted acepta estar sujeto a la versión más reciente
              de los Términos y Condiciones.‍
            </p>
            <h3>Uso de {siteName}</h3>
            <p>
              Sujeto a su cumplimiento de estos Términos y Condiciones y al
              mantenimiento de una Cuenta de usuario, la Compañía le otorga una
              licencia limitada, no exclusiva, no sublicenciable, revocable y
              limitada para usar {siteName}. Usted reconoce que la Compañía
              mantiene todos los derechos, títulos e intereses en y para{" "}
              {siteName}, así como todas las patentes, derechos de autor, marcas
              registradas, secretos comerciales, ideas, información técnica,
              interfaces de usuario, diseños, códigos fuente y procesos (en
              adelante colectivamente la "Propiedad Intelectual").
            </p>
            <p>
              1. Para utilizar {siteName}, debe ser mayor de 18 años de edad, lo
              que usted declara personalmente en este acto.
            </p>
            <p>
              2. Si accede a {siteName} en nombre de una empresa o entidad,
              usted declara personalmente que tiene la autoridad y poder de
              representación necesarios para obligar a dicha empresa o entidad a
              estos Términos y Condiciones.
            </p>
            <p>
              3. {siteName} está sujeto a cambios en cualquier momento. Además,
              podemos dejar de proporcionar los servicios de {siteName} en
              cualquier momento. Podemos restringir su acceso a {siteName} en
              cualquier momento sin previo aviso y por cualquier motivo,
              incluidos, entre otros, los siguientes.
            </p>
            <div className="p_paddingleft">
              <br /> 1. Violación o incumplimiento de estos Términos y
              Condiciones;
              <br /> 2. Actividad que podría considerarse ilegal en cualquier
              jurisdicción;
              <br /> 3. Mal uso de la Propiedad Intelectual o la violación de
              los derechos de un tercero;
              <br /> 4. Mal uso o interrupción de los servidores o redes de{" "}
              {siteName}; o
              <br /> 5. Falta de pago o retraso superior a 10 días corridos en
              el pago de los servicios de {siteName}.
            </div>
            <p>4. Usted declara, garantiza y se compromete a:</p>
            <div className="p_paddingleft">
              <br /> 1. No interferir con la seguridad o las redes de {siteName}{" "}
              ni interceptar ningún dato enviado o recibido por {siteName}.
              <br /> 2. No decodificar, rastrear, realizar ingeniería inversa,
              deconstruir ni volver a crear el código fuente de {siteName}.
              <br /> 3. No sublicenciar, alquilar, arrendar, prestar, poner a
              disposición, transferir, asignar, vender, reproducir, adaptar,
              traducir, divulgar, mostrar, publicar, explotar, modificar o
              distribuir {siteName}, su Propiedad Intelectual o cualquier
              producto derivado de {siteName}.
              <br /> 4. No violar ninguna ley, regla o reglamento que se pudiera
              infringir mediante la utilización de {siteName}.
              <br /> 5. No utilizar {siteName} para hostigar, intimidar o violar
              los derechos de terceros.
              <br /> 6. No acceder a {siteName} para realizar investigaciones
              con el fin de crear o ayudar en la creación de cualquier producto
              o servicio de la competencia.
              <br /> 7. Obtener, a su cargo, todo el software, hardware, equipo,
              licencias, autorizaciones y permisos necesarios para usar{" "}
              {siteName}.
              <br /> 8. Enviar sólo información veraz, precisa y completa a{" "}
              {siteName}.
              <br /> 9. Cumplir con los Términos y Condiciones de servicio (y
              todos los acuerdos relacionados) de software y scripts de terceros
              utilizados en {siteName}.
            </div>
            <h3>Cuenta de usuario y Suscripción.</h3>
            <p>
              1. {siteName} requiere que los usuarios se registren mediante la
              creación de cuentas (en adelante cada una de éstas una "Cuenta de
              Usuario" y su titular un “Usuario”) para acceder a todas las
              funciones. Las Cuentas de Usuario no se pueden compartir, salvo lo
              dispuesto en las instrucciones de registro de la Cuenta de Usuario
              en {siteName}. Usted acepta mantener la confidencialidad de la
              información de inicio de sesión de su Cuenta de Usuario y no
              compartirla con ninguna otra persona que no sea la permitida
              expresamente.
            </p>
            <p>
              2. {siteName} ofrece acceso a la plataforma al precio indicado en
              el contrato firmado, o en la cotización enviada al Usuario. El
              precio será facturado automáticamente una vez a la semana, a menos
              que se indique expresamente lo contrario (en adelante cada uno de
              estos meses es un "Ciclo de Pago") a la tarjeta de crédito o
              información de pago que tiene en el archivo de la Compañía (en
              adelante la "Suscripción").
            </p>
            <p>
              3. Al firmar el contrato, acepta pagar en su totalidad todas las
              tarifas (ya sean iniciales o recurrentes) indicadas en la página
              de registro correspondiente en {siteName}.
            </p>
            <p>
              4. Usted autoriza a la Compañía a cargar a su tarjeta de crédito u
              otra información de pago por los cobros semanales de su acuerdo en
              contrato. Los cobros pueden facturarse mediante el uso de
              servicios de procesamiento de pagos de terceros ("Procesadores")
              utilizados por la Compañía. La Compañía y los Procesadores pueden
              recibir información actualizada de la tarjeta de crédito del
              emisor o banco de su tarjeta de crédito.
            </p>
            <p>
              5. Puede cancelar su Cuenta de usuario en cualquier momento
              siguiendo las instrucciones en {siteName}. La Compañía puede
              cancelar su Suscripción de cuenta de usuario en cualquier momento
              y sin previo aviso de conformidad con estos Términos y
              Condiciones. Tras dicha cancelación, (i) sus obligaciones en
              virtud de la Suscripción cesarán y Usted no tendrá que pagar más,
              y (ii) no se adeudarán reembolsos por pagos anteriores.
            </p>
            <p>
              6. La Compañía se reserva el derecho de negarse a proporcionar una
              Cuenta de Usuario o Suscripción a cualquier persona por cualquier
              motivo y sin justificación de causa.
            </p>
            <p>
              7. Una vez que Usted o la Compañía cierren su Cuenta de Usuario,
              la Compañía podrá eliminar permanentemente su Cuenta de Usuario y
              todos los datos asociados a ella. Si no inicia sesión en su Cuenta
              de Usuario durante 12 meses o más, podemos tratar su Cuenta de
              Usuario como "inactiva" y eliminar permanentemente la cuenta y
              todos los datos asociados con ella. Para cerrar su Cuenta de
              Usuario, envíenos un correo electrónico a contacto@bicci.cl
            </p>
            <p>
              8. Todos los precios publicados en {siteName} están en moneda
              nacional corriente, a menos que se indique expresamente lo
              contrario. Los precios y la disponibilidad están sujetos a cambios
              en cualquier momento y sin previo aviso. La Compañía se esfuerza
              por proporcionar información precisa, pero no es responsable de
              los errores tipográficos o imprecisiones que se pueden encontrar
              en {siteName}.
            </p>
            <h3>Planificación de Ruta</h3>
            <p>
              1. {siteName} permite hacer seguimiento en línea de envíos de
              productos, pudiendo optimizar el proceso de entrega y mejorar su
              desempeño. De esta manera, se fortalece el control de los
              despachos, monitoreando cada una de sus etapas. {siteName} está
              diseñado para proporcionar herramientas de planificación logística
              precisas y eficientes. Sin embargo, {siteName} no garantiza la
              calidad o precisión de ninguno de los servicios prestados.{" "}
              {siteName} no se hace responsable por los errores, inexactitudes u
              omisiones en la información que el Usuario carga en {siteName}, ya
              sea en la plataforma web, aplicación móvil o en cualquiera de sus
              productos.
            </p>
            <p>
              2. {siteName} no se hace responsable de las eventuales
              inexactitudes que arroje la georreferenciación, ya sea que se
              obtenga mediante normalización de direcciones o a través de la
              obtención de coordenadas desde la aplicación móvil.
            </p>
            <p>
              3. Usted acepta tener la debida precaución en la planificación de
              rutas y en la conducción que realicen sus colaboradores, y
              reconoce que las rutas sugeridas por {siteName} pueden no ser
              seguras o transitables. El tráfico, el clima y las condiciones de
              emergencia pueden cambiar rápidamente. Usted acepta ser el único
              responsable de su dependencia de los servicios de {siteName}.
            </p>
            <p>
              4. Bajo ninguna circunstancia {siteName} será responsable de las
              estimaciones o rutas de tiempo de logística inadecuadas. Las
              estimaciones son aproximadas y no exactas.
            </p>
            <h3>Propiedad Intelectual</h3>
            <p>
              1. El Sitio, los Productos de {siteName} y su contenido,
              características y funcionalidad originales son propiedad de la
              Compañía y están protegidos por propiedad intelectual y derechos
              de autor internacionales, marcas registradas, patentes, secretos
              comerciales y otras leyes de propiedad intelectual o derechos de
              propiedad.
            </p>
            <p>
              2. Usted reconoce que la Propiedad intelectual utilizada dentro o
              en conexión con {siteName} es propiedad de la Compañía o sus
              respectivos terceros. Al usar {siteName} no se le ha ofrecido
              ninguna licencia, cesión, arrendamiento o venta de propiedad
              intelectual. Usted acepta no utilizar la Propiedad Intelectual
              asociada a {siteName}, o derivados de la misma, incluido el nombre
              "{siteName}" o el logotipo de {siteName} para cualquier otro
              propósito que no sea el expresamente permitido por estos Términos
              y Condiciones. La Compañía se reserva todos los derechos sobre la
              Propiedad Intelectual.
            </p>
            <p>
              3. Durante el uso de {siteName}, puede cargar datos, información
              de ubicación, texto, fotografías, videos u otra información a{" "}
              {siteName} (en adelante conjuntamente el "Contenido de Usuario")
              Usted es el único responsable de cualquier Contenido de Usuario
              que publique..
            </p>
            <p>
              4. Al aceptar los presentes Términos y Condiciones, otorga a{" "}
              {siteName} una licencia irrevocable, mundial, no exclusiva, libre
              de regalías para usar, reproducir, mostrar, distribuir y
              sublicenciar su Contenido de Usuario o sus derivados con el fin de
              proporcionar o mejorar {siteName} o los servicios de {siteName}.
              La Compañía no tiene la obligación de pagar por el Contenido del
              Usuario.
            </p>
            <h3>Limitación de daños e indemnización</h3>
            <p>
              1. Nuestras Herramientas, productos y servicios y todo el
              contenido incluido se proporcionan "tal cual" sin garantía de
              ningún tipo, ya sea expresa o implícita. La Compañía no asumirá
              ninguna responsabilidad por el contenido generado por usted o que
              cualquier otro usuario o tercero publique o transmita utilizando{" "}
              {siteName}.{" "}
            </p>
            <p>
              2. {siteName} no se hace responsable por la pérdida que pudiera
              surgir por basarse en información o materiales publicados en{" "}
              {siteName}, o de aquel contenido que sea o pueda ser proporcionada
              por sus filiales o un tercero, incluso en relación con cualquier
              imprecisión u omisión en el Sitio y Herramientas de {siteName}.{" "}
            </p>
            <p>
              3. En la medida que la ley lo permita, en ningún caso la Compañía
              o sus accionistas, directores, funcionarios, empleados o afiliados
              (en adelante conjuntamente las "Personas Relacionadas") serán
              responsables ante usted o cualquier tercero por los daños
              directos, indirectos, especiales, consecuenciales, incidentales,
              punitivos (incluyendo lucro cesante y daño moral), incluso, entre
              otros, los que resulten de la pérdida de ganancias, ingresos o
              ahorros, pérdida o daño de los datos o interrupción del negocio a
              causa del uso o la incapacidad de uso de {siteName}, sus
              Herramientas o de cualquier material, información, producto o
              servicio al que se acceda por medio de este Sitio. Aún en caso de
              haber sido advertido sobre la posibilidad de ocurrencia de tales
              daños.
            </p>
            <p>
              4. Sin limitar la generalidad de lo anterior, en ningún caso la
              responsabilidad total de la Compañía hacia usted excederá la suma
              total de sus pagos a la Compañía durante los 12 meses
              inmediatamente anteriores a cualquier reclamo. Usted acepta usar{" "}
              {siteName} bajo su propio riesgo.
            </p>
            <p>
              5. La Compañía hace esfuerzos comercialmente razonables para hacer
              que {siteName} esté disponible con la mayor cantidad de tiempo de
              actividad posible. Sin embargo, la Compañía no se hace responsable
              de las interrupciones, demoras o detenciones en la disponibilidad
              de cualquier Herramienta de {siteName}.{" "}
            </p>
            <p>
              6. Usted acepta indemnizar y mantener a la Compañía, sus
              subsidiarias, sociedad matriz y filiales, y sus propietarios,
              funcionarios, agentes, socios y empleados, indemnes ante cualquier
              pérdida, responsabilidad o reclamo, realizado por un tercero
              debido a su incumplimiento o incumplimiento de estos Términos y
              Condiciones, su uso de {siteName} y su violación de cualquier
              estatuto, reglamento, ordenanza, código o acuerdo.
            </p>
            <h3>Sin garantías</h3>
            <p>
              {siteName} no otorga garantía de ningún tipo, ya sea expresa o
              implícita, incluidas, entre otras, cualquier garantía implícita de
              comerciabilidad, idoneidad para un propósito particular, no
              infracción, integración, precisión o calidad. Usted reconoce y
              acepta que {siteName} puede no cumplir con sus requisitos o
              expectativas, puede que no se ejecute de manera adecuada o
              eficiente en su dispositivo, y puede no estar libre de errores o
              defectos.‍
            </p>
            <h3>Resolución de disputas</h3>
            <p>
              1. Cualquier controversia o reclamo que surja de o se relacione
              con estos Términos y Condiciones, o el incumplimiento de éstos, se
              resolverán mediante arbitraje, de acuerdo con el Reglamento de
              Arbitraje Comercial del Centro de Arbitraje y Mediación de la
              Cámara de Comercio de Santiago, vigente al momento de su inicio.
              Las Partes de común acuerdo designarán a un árbitro en cuanto al
              procedimiento, y de derecho en cuanto al fallo. La sede del
              arbitraje será Santiago de Chile y el arbitraje se realizará en
              idioma español. La ley aplicable al contrato será la ley
              sustantiva de Chile. En contra de las resoluciones del árbitro no
              procederá recurso alguno, excepto el de apelación y de casación.{" "}
            </p>
            <p>
              1. Sin perjuicio de los términos de esta Sección 7, cada parte se
              reserva el derecho de entablar una acción individual para buscar
              medidas cautelares o de otro tipo en un tribunal de jurisdicción
              competente en relación con la infracción de la Propiedad
              Intelectual.
            </p>
            <h3>Términos adicionales</h3>
            <p>
              A. La Compañía puede proporcionar mensajes promocionales,
              informativos o administrativos con respecto a la Compañía o{" "}
              {siteName}, por correo electrónico o SMS / mensaje de texto. Al
              aceptar estos Términos y Condiciones, usted consiente expresamente
              en recibir dichos mensajes y consiente que, al escribir su nombre
              al registrarse en {siteName}, haya firmado electrónicamente su
              nombre para aceptarlo.
            </p>
            <p>
              B. Estos Términos y Condiciones, junto con la Política de
              Privacidad incorporada aquí como referencia, comprenden el acuerdo
              completo entre usted y la Compañía con respecto al uso de{" "}
              {siteName} y cualquier servicio de la Compañía. Si alguna
              disposición de estos Términos y Condiciones se considera inválida
              por una Ley y resolución de autoridad, el resto de estos Términos
              y Condiciones continuará en pleno vigor y efecto en la medida
              máxima permitida por la ley.
            </p>
            <p>
              C. Ni el incumplimiento, ni el ejercicio único o parcial, ni el
              retraso de cualquiera de las partes en el ejercicio de cualquier
              derecho, poder o privilegio bajo estos Términos y Condiciones
              implicará renuncia alguna a dicho derecho, poder o privilegio, ni
              impedirá el ejercicio de cualquier otro derecho en virtud de los
              presentes Términos y Condiciones.
            </p>
            <p>
              D. Usted no puede ceder a un tercero los derechos y/u obligaciones
              que le otorgan o imponen estos Términos y Condiciones sin el
              permiso expreso por escrito de la Compañía. La Compañía puede
              ceder a un tercero cualquier derecho u obligación que exista entre
              la Compañía y usted en el caso de una fusión, venta o
              reorganización de la Compañía o sus activos.
            </p>
            <p>
              E. Agradecemos sus comentarios y preguntas. Puede contactar a la
              empresa por correo electrónico a contacto@bicci.cl
            </p>
            <p>
              F. Al aceptar los presentes términos y condiciones, se acepta
              también la Política de Privacidad de {siteName}.
            </p>
            <h3>INDEMNIZACIONES</h3>
            <p>
              Es obligación del cliente declarar en la orden de transporte
              (etiqueta) el contenido de su envío y el valor real del mismo.
              Respecto del contenido, se deberá indicar en “descripción del
              producto” cuál es el producto que se enviará sin entrar en mayor
              detalle. Sin perjuicio de lo anterior, en caso de extravíos,
              mermas o daños atribuibles, el remitente deberá en todo caso
              acreditar suficientemente el valor neto, ya sea mediante una orden
              de compra, una factura o boleta de compra o venta, guía de
              despacho, transacción transbank u orden de venta de su envío para
              efectos de la eventual indemnización.
            </p>
            <p>
              La documentación para proceder al proceso de indemnización debe
              ser enviada a más tardar 05 días hábiles, desde el aviso que el
              envío fue declarado extraviado, dañado u en proceso de
              investigación.
            </p>
            <p>
              Las aseguradoras de los operadores responsables de la
              indemnización solicitan la siguiente documentación la cual debe
              ser enviada siempre mediante vía ticket:
            </p>
            <div className="p_paddingleft">
              <p>
                1.- La acreditación del valor del envío, la cual puede ser por
                los documentos formales mencionados anteriormente. Cualquier
                otro documento o foto quedará inmediatamente invalido para la
                continuación del proceso.
              </p>

              <p>
                NOTA: Es importante que en la documentación debe estar explícito
                el número de envío que se ingresó al momento de crear el envío,
                el detalle de producto y valor declarado. Si no existe esa
                información en la documentación, podría ser eventualmente causa
                de invalidez de indemnización. Considerar que existen
                excepciones de algunos carriers que no requieren adjuntar boleta
                si el envío declarado corresponde a un valor comercial menor a
                CLP 50.000.
              </p>
              <p>
                2.- El manifiesto firmado por el Courier (transportista del
                operador logístico)
              </p>
            </div>
            <p>
              La indemnización corresponderá al valor costo neto del bien
              transportado, no incluyéndose el valor comercial o esperado del
              mismo, ni de ningún otro tipo. El monto máximo para indemnizar
              será determinado exclusivamente por el operador del servicio.
            </p>
            <p>
              Por otra parte, si luego producto llegase a aparecer, este se
              envía de vuelta a sus dependencias sin costo y el reembolso queda
              nulo.
            </p>
            <p>
              En caso de que la indemnización se haga efectiva y se solicite la
              devolución, el operador tiene toda la facultad de efectuar como
              también de no efectuar la devolución de ese producto.
            </p>
            <p>
              Se entiende que, si el envío ya está en manos del operador y se
              solicita cambio de dirección, este estará sujeto a la factibilidad
              del operador y sus procesos internos por lo que no está
              garantizado dado que los nombres y direcciones de los
              destinatarios son de exclusiva responsabilidad de quienes las
              emiten, por lo que ante cualquier error que deba solucionarse
              posterior a la entrega de los operadores, se desliga a Envíame y
              los proveedores de servicio de cualquier responsabilidad respecto
              la indemnización a pesar de haber hecho indicación del error si el
              producto no puede ser rescatado, o si se rescata y está dañado o
              mermado o faltante.
            </p>
            <p>
              Los envíos que son entregados conformes pero que el cliente indica
              algún daño, merma o faltante se contempla un periodo de 48 horas
              para hacer un reclamo formal (siempre vía ticket) Para
              desconocimientos de entrega se contemplan hasta 30 días
              posteriores a la entrega para efectuar reclamo, con excepción de
              algunos carriers que manejan un plazo de hasta 20 días.
            </p>
            <p>
              {siteName} se adjunta el derecho de no realizar envíos frágiles,
              por lo que no existirá tratos diferenciados o cuidadosos con los
              envíos. Dado esto el producto debe ser enviado con el empaque
              óptimo y embalaje correspondiente, así evitar problemas futuros.
              En caso de que el producto sea de carácter frágil, y llegue a
              destino en malas condiciones, Envíame no se hace responsable de su
              reembolso.
            </p>
            <p>
              El plazo de respuesta de la indemnización se inicia desde el
              momento que se ha adjuntado la documentación vía ticket. Luego el
              proceso de reclamo puede tardar 7 días hábiles, pero si se
              necesita más investigación asociada, se puede extender a 7 días
              hábiles más en el proceso de resolución de indemnización.{" "}
            </p>
            <p>
              La asignación de esta indemnización será mediante un avenimiento
              (pago de finiquito extra oficial) el cual se enviará dentro de los
              10 primeros días hábiles del mes a la confirmación de la
              indemnización.
            </p>
            <p>
              -Valor aprobado según las condiciones de servicio de cada Carrier.
            </p>
            <p>-Listado de envíos indemnizados dentro del período. </p>
            <p>
              La información será enviada a los mismos correos que tengan
              registrados para notificar la emisión de facturas. De igual
              manera, a través de los tickets de la plataforma queda registrado
              a quién de su organización se ha enviado el documento.{" "}
            </p>
            <p>
              Este finiquito es un crédito que puede ser utilizado para
              descontar una parte o la totalidad de una factura. Para usarlo es
              necesario enviar dicho documento firmado junto con la factura a
              descontar a nuestra área de facturación al correo
              contacto@bicci.cl{" "}
            </p>
            <p>
              En el caso que el crédito sea mayor a lo facturado por {siteName}{" "}
              el saldo restante quedará disponible para la próxima facturación.
              Este crédito no tiene fecha de vencimiento, se puede descontar a
              cualquier factura que esté vigente.
            </p>
          </div>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Tyc;
