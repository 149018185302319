import React from "react";
import { Box } from "@mui/material";

const LoadingSpinner = ({ transparent, textMessj }) => {
  return (
    <Box
      id={`${
        !transparent ? "smallspinnerloading" : "smallspinnerloadingtransparent"
      }`}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9000,
        backgroundColor: transparent ? "rgba(0, 0, 0, 0.5)" : "white",
      }}
    >
      <Box className="loading-container">
        <Box className="loading"></Box>
        <Box display={"inline-block"}>
          <Box className="loadingAbsolute">
            <img
              id="logoSpinnerLoading"
              alt="Cargando..."
              src={"/static/images/bicciexpress.png"}
            />
          </Box>
        </Box>
        <Box id="loading-text">
          {textMessj === "Cargando..." ? textMessj : textMessj}
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingSpinner;
