import React, { useEffect, useRef, useState, useCallback } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "@reach/combobox/styles.css";
import { LinearProgress } from "@mui/material";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

const SelectorGeoRecf = (props) => {
  const [center, setCenter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const mapRef = useRef(null);

  // 🛠️ 1️⃣ - Configurar el centro del mapa con los datos iniciales
  useEffect(() => {
    if (props.markersPending) {
      let lat = null;
      let lng = null;

      // 🔹 Verifica si existen las propiedades antes de parsear
      if (
        props.markersPending.latitude !== undefined &&
        props.markersPending.longitude !== undefined
      ) {
        lat = parseFloat(props.markersPending.latitude);
        lng = parseFloat(props.markersPending.longitude);
      } else if (
        props.markersPending._lat !== undefined &&
        props.markersPending._long !== undefined
      ) {
        lat = parseFloat(props.markersPending._lat);
        lng = parseFloat(props.markersPending._long);
      }

      // 🔹 Validación antes de actualizar el estado
      if (!isNaN(lat) && !isNaN(lng)) {
        setCenter({ lat, lng });
        setSelectedLocation({ lat, lng });
      } else {
        console.error(
          "❌ Valores inválidos en markersPending:",
          props.markersPending
        );
      }
    }
  }, [props.markersPending]);

  // 🛠️ 2️⃣ - Asegurar que el mapa está cargado antes de asignarlo a `mapRef`
  const onMapLoad = useCallback((map) => {
    if (map) {
      mapRef.current = map;
    }
  }, []);

  // 🛠️ 3️⃣ - Actualizar la ubicación cuando el usuario hace clic en el mapa
  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setSelectedLocation({ lat, lng });
    setCenter({ lat, lng });

    if (props.setNewLocation) {
      props.setNewLocation({ lat, lng });
    }
  };

  // 🛠️ 4️⃣ - Mover el mapa a una nueva ubicación
  const panTo = useCallback(({ lat, lng }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
  }, []);

  function Search() {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => -33.4489, lng: () => -70.6693 }, // Santiago, Chile
        radius: 10 * 1000,
        componentRestrictions: { country: "CL" },
      },
    });

    const handleInput = (e) => setValue(e.target.value);

    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();

      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);

        setSelectedLocation({ lat, lng });
        panTo({ lat, lng });

        if (props.setNewLocation) {
          props.setNewLocation({ lat, lng });
        }
      } catch (error) {
        console.error("Error obteniendo coordenadas:", error);
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-2"
            id="imputSearchFromTo"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Escribe una dirección de destino"
          />
          <ComboboxPopover
            className="comboboxList-routes-direcctiones"
            style={{ zIndex: 3000, backgroundColor: "#fff" }}
          >
            <ComboboxList>
              {status === "OK" &&
                data.map(({ place_id, description }) => (
                  <ComboboxOption
                    key={place_id}
                    value={description}
                    className="dropdown-item dropdown-ebiex"
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }

  return center && selectedLocation ? (
    <div>
      <div>
        <div>Buscar:</div>
        <Search id="imputSearchFromOrigen" />
      </div>
      <div className="mapControlStyle">
        <GoogleMap
          id="mapControl2"
          zoom={18}
          center={center}
          onLoad={onMapLoad}
          onClick={handleMapClick}
        >
          {/* 🛠️ 5️⃣ - Asegurar que `selectedLocation` tenga datos válidos antes de crear el `Marker` */}
          {selectedLocation && (
            <Marker
              key="selected-marker"
              position={{
                lat: parseFloat(selectedLocation.lat),
                lng: parseFloat(selectedLocation.lng),
              }}
            />
          )}
        </GoogleMap>
      </div>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default SelectorGeoRecf;
