import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";

const useRutasPorDia = (date, companiesData) => {
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      if (companiesData.length > 0) {
        const companiesIds = companiesData.reduce((acc, obj) => {
          if (obj.companyID) acc.push(obj.id);
          return acc;
        }, []);

        const searchdIds = companiesIds.includes("5NWyvfeZ2xir6SyUSDAV")
          ? [...companiesIds, "falabella"]
          : companiesIds;

        const db = getFirestore();
        const today = new Date(date);
        const todayLocal = today.toISOString().slice(0, 10).replace(/-/g, ""); // Formato YYYYMMDD

        const rutasQuery = query(
          collection(db, "xpress_deliveryPlans"),
          where("companyID", "in", searchdIds), // arrayCompanys[i].integrationServicesBicciID),
          where("dayStart", "==", todayLocal)
        );

        // Suscripción en tiempo real
        const unsubscribe = onSnapshot(rutasQuery, async (snapshot) => {
          const rutasData = await Promise.all(
            snapshot.docs.map(async (doc) => {
              const rutaData = { id: doc.id, ...doc.data() };

              // Obtener `waypoints` en tiempo real
              const waypointsRef = collection(doc.ref, "waypoints");
              const waypointsSnapshot = await getDocs(waypointsRef);
              const waypoints = waypointsSnapshot.docs.map((wp) => ({
                id: wp.id,
                ...wp.data(),
              }));

              // Obtener `observations` en tiempo real
              const observationsRef = collection(doc.ref, "observations");
              const observationsSnapshot = await getDocs(observationsRef);
              const observations = observationsSnapshot.docs.map((obs) => ({
                id: obs.id,
                ...obs.data(),
              }));

              return { ...rutaData, waypoints, observations };
            })
          );

          setRoutes(rutasData);
          setLoading(false);
        });

        return () => unsubscribe(); // Detener suscripción cuando el componente se desmonte
      }
    } catch (error) {
      console.log(error);
    }
  }, [date]); // Asegúrate de que 'date' esté cambiando correctamente

  return { routes, loading };
};

export default useRutasPorDia;
