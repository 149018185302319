import React, { useState } from "react";
import { Button, Typography, Box, Paper } from "@mui/material";
import SelectorGeoRecf from "../SelectorGeoRecf";

const AdjustGeoReferenceForm = ({ orderDetails, addNewGeoPoint }) => {
  const [newLocation, setNewLocation] = useState(null);

  const currentLat =
    orderDetails?.toAddressLocation?.latitude ??
    orderDetails?.toAddressLocation?.lat ??
    "N/A";
  const currentLng =
    orderDetails?.toAddressLocation?.longitude ??
    orderDetails?.toAddressLocation?.lng ??
    "N/A";

  const handleSave = () => {
    if (!newLocation) {
      alert("Por favor, seleccione una nueva ubicación antes de guardar.");
      return;
    }

    addNewGeoPoint(newLocation); // Llamamos la función de actualización
  };

  return (
    <Paper elevation={3} sx={{ padding: 3, backgroundColor: "#F8F8F8" }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Ajustar Geo referencia de entrega
      </Typography>

      <Typography variant="body1">
        <strong>Geo referencia actual:</strong>
        <br />
        Lat: {currentLat}, Lng: {currentLng}
      </Typography>

      {newLocation && newLocation.lat && (
        <Box mt={2}>
          <Typography variant="body1" fontWeight="bold">
            Nueva Geo referencia:
          </Typography>
          <Typography>
            Lat: {newLocation.lat.toString()}, Lng: {newLocation.lng.toString()}
          </Typography>

          <Button
            variant="contained"
            type="button"
            onClick={handleSave}
            sx={{
              mt: 2,
              backgroundColor: "#591E8F",
              color: "#fff",
              borderRadius: "5px",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#25b2f4",
                color: "#591e8f",
              },
            }}
          >
            Guardar Geo Referencia
          </Button>
        </Box>
      )}

      <Box mt={3}>
        {orderDetails && orderDetails.toAddressLocation && (
          <SelectorGeoRecf
            markersPending={orderDetails.toAddressLocation}
            setNewLocation={setNewLocation}
          />
        )}
      </Box>
    </Paper>
  );
};

export default AdjustGeoReferenceForm;
