import React, { useState } from "react";
import { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Logo from "../../components/header/logo/Logo";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CardMedia from "@mui/material/CardMedia";
import ExternalMapsTraking from "./ExternalMapsTraking";
import { useParams } from "react-router-dom";
import { getDataDispachst } from "../../redux/routesClientTraking";
import { LinearProgress } from "@mui/material";
import { format } from "date-fns";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Divider, Grid2 } from "@mui/material/";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PanToolIcon from "@mui/icons-material/PanTool";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
const External = (props) => {
  const { offocusid } = useParams();
  const dispacth = useDispatch();
  const [orderId, setOrderId] = useState(null);
  const [bicciOrderData, setBicciOrderData] = useState(null);
  const [bicciOrderDataClient, setBicciOrderDataClient] = useState(null);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const order = useSelector((store) => store.accionExternal);
  const translateStateBicciFalabella = {
    1058: "BICCI en camino a la tienda para retirar tu producto.",
    1059: "Tu compra está en preparacion para salir a ruta.",
    1060: "Tu compra está en camino a tu dirección.",
    1061: "Tu compra está en camino a tu dirección.",
    99: "Tu compra ha sido entregado.",
    1: "No había nadie disponible en la dirección de entrega.",
    1002: "Hay un error en la dirección de entrega.",
    1005: "Rechazaste el envío.",
    1006: "Rechazaste el envío.",
    1007: "Todavía no hemos recibido el producto para entregarte.",
    1008: "Tu compra ha sido devuelto a la tienda.",
    1009: "La ubicación de entrega estaba cerrada cuando llegamos.",
    1017: "Tu compra ha sido devuelto a la tienda.",
    1019: "La dirección de entrega está fuera de nuestra área de cobertura.",
    1022: "Tu compra ha sido devuelto a la tienda.",
    1023: "Tu compra ha sido devuelto a la tienda.",
    1027: "Todavía no hemos recibido el producto para entregarte.",
    1031: "Tu entrega está retrasada.",
    1030: "Tu compra está en nuestro centro de distribución. Estamos verificando su destino.",
    1032: "La información de entrega está incompleta.",
    1034: "Rechazaste el envío.",
    1036: "Hay un problema para acceder a la dirección de entrega.",
    1041: "Rechazaste el envío.",
    1029: "Tu compra ha sido devuelto a la tienda.",
    1081: "Tu compra ha sido devuelto a la tienda.",
    1038: "Tu compra está en nuestro centro de distribución. Estamos verificando su destino.",
    1046: "Tu compra está en nuestro centro de distribución. Estamos verificando su destino.",
  };

  const translateStateBicciFalabellaIcons = {
    1058: FactCheckIcon,
    1059: ScheduleIcon,
    1060: DirectionsBikeIcon,
    1061: DirectionsBikeIcon,
    99: CheckCircleIcon,
    1: CrisisAlertIcon,
    1002: CrisisAlertIcon,
    1005: PanToolIcon,
    1006: PanToolIcon,
    1007: CrisisAlertIcon,
    1008: RemoveCircleIcon,
    1009: CrisisAlertIcon,
    1017: RemoveCircleIcon,
    1019: CrisisAlertIcon,
    1022: RemoveCircleIcon,
    1023: RemoveCircleIcon,
    1027: ScheduleIcon,
    1031: ScheduleIcon,
    1030: MapsHomeWorkIcon,
    1032: CrisisAlertIcon,
    1034: PanToolIcon,
    1036: CrisisAlertIcon,
    1041: PanToolIcon,
    1029: RemoveCircleIcon,
    1081: RemoveCircleIcon,
    1038: MapsHomeWorkIcon,
    1046: MapsHomeWorkIcon,
  };
  const translateStateBicciFalabellaColors = {
    1058: "#0b8f1b",
    1059: "#0b8f1b",
    1060: "#0b8f1b",
    1061: "#0b8f1b",
    99: "#0b8f1b",
    1: "#c47b0e",
    1002: "#c47b0e",
    1005: "#c40e2c",
    1006: "#c40e2c",
    1007: "#c47b0e",
    1008: "#dedcdc",
    1009: "#c47b0e",
    1017: "#dedcdc",
    1019: "#c47b0e",
    1022: "#dedcdc",
    1023: "#dedcdc",
    1027: "#c47b0e",
    1031: "#c47b0e",
    1030: "#c47b0e",
    1032: "#c47b0e",
    1034: "#c40e2c",
    1036: "#c47b0e",
    1041: "#c40e2c",
    1029: "#c40e2c",
    1081: "#dedcdc",
    1038: "#c47b0e",
    1046: "#c47b0e",
  };
  useEffect(() => {
    if (props && props.setLandScrenMenu) {
      props.setLandScrenMenu(true);
    }
  }, []);

  React.useEffect(() => {
    if (offocusid) {
      setOrderId(offocusid);
    }
  }, [offocusid]);

  React.useEffect(() => {
    if (orderId) {
      dispacth(getDataDispachst(orderId));
    }
  }, [orderId]);

  React.useEffect(() => {
    if (order && order.bicciClientHistory) {
      setBicciOrderData(order.bicciorder);
      setBicciOrderDataClient(order.bicciClient);
      createSteps();
    }
  }, [order]);

  const createSteps = () => {
    let reverse = order.bicciClientHistory.reverse();
    let localsteps = [];

    reverse.map((item) =>
      localsteps.push({
        label: translateStateBicciFalabella[item.status],
        description: translateStateBicciFalabella[item.status],
        status: item.status,
        color: translateStateBicciFalabellaColors[item.status],
      })
    );

    setActiveStep(localsteps.length - 1);
    setSteps(localsteps);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function VerticalLinearStepper(activeStep) {
    return (
      <Box sx={{ maxWidth: 400, padding: "5px" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                // optional={
                //   index === 2 ? (
                //     <Typography variant="caption">Last step</Typography>
                //   ) : null
                // }
                StepIconComponent={
                  translateStateBicciFalabellaIcons[step.status]
                }
              >
                <ListItem
                  disablePadding
                  key={index}
                  sx={{
                    backgroundColor:
                      translateStateBicciFalabellaColors[step.status],
                    marginBottom: "5px",
                    color: "#f8f8f8",
                  }}
                >
                  <ListItemButton>
                    <ListItemText
                      primary={translateStateBicciFalabella[step.status]}
                    />
                  </ListItemButton>
                </ListItem>
              </StepLabel>
              <StepContent>
                {/* <Typography>{step.description}</Typography> */}
                {/* <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? "Finish" : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>

        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    );
  }

  return order && order.loading === false ? (
    <div>
      {props.landScrenMenu ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
            <Toolbar variant="dense" sx={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                color="inherit"
                sx={{ marginLeft: "40px" }}
                component="div"
              >
                <Logo></Logo>
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      ) : null}
      {!order.error && bicciOrderData ? (
        <div>
          {bicciOrderData && bicciOrderData[0].status > 7 ? (
            <div>
              {" "}
              <div>
                <Card
                  sx={{
                    maxWidth: 500,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "2em",
                  }}
                >
                  <CardHeader
                    title="ENTREGADO"
                    subheader={`Día de entrega ${format(
                      new Date(),
                      "dd-MM-yyyyy hh:mm:ss"
                    )}`}
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image="https://bicciexpress.cl/static/media/1.281088f583fcd5765041.jpg"
                    alt="bicciexpress"
                    sx={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <CardContent>
                    {order.bicciClientHistory ? (
                      <div>
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                          }}
                          aria-label="contacts"
                        >
                          {order.bicciClientHistory.map((item, index) => {
                            return (
                              <ListItem
                                disablePadding
                                key={index}
                                sx={{
                                  backgroundColor:
                                    translateStateBicciFalabellaColors[
                                      item.status
                                    ],
                                  marginBottom: "5px",
                                  color: "#f8f8f8",
                                }}
                              >
                                <ListItemButton>
                                  <ListItemIcon>
                                    <DirectionsBikeIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      translateStateBicciFalabella[item.status]
                                    }
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      </div>
                    ) : null}
                  </CardContent>
                  <CardActions disableSpacing></CardActions>
                </Card>
              </div>
            </div>
          ) : (
            <div>
              <div>
                {" "}
                <Grid2 id="GridRowcontainer">
                  <Grid2 className="cart-Grid-Row" id="GridRow-external">
                    <Grid2 className="GridColumnExternal">
                      <div className="letfbarnav">
                        <Card
                          sx={{
                            maxWidth: "99%",
                            margin: "5px",
                            borderRadius: "3px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <CardHeader
                            title={<h1>{bicciOrderData[0].id}</h1>}
                            subheader={
                              <h3>
                                {format(
                                  bicciOrderData[0].createdAt.seconds * 1000,
                                  "dd-MM-yyyy"
                                )}
                              </h3>
                            }
                          />
                          <CardContent>
                            <Typography variant="body1" color="text.primary">
                              Ten en cuenta que BICCI realizara otras entregas,
                              antes de llegar a tu destino.
                            </Typography>
                            <CardContent>
                              {order.bicciClientHistory ? (
                                <div>
                                  <List
                                    sx={{
                                      width: "100%",
                                      bgcolor: "background.paper",
                                    }}
                                    aria-label="contacts"
                                  >
                                    {order.bicciClientHistory.map(
                                      (item, index) => {
                                        if (
                                          index ===
                                          order.bicciClientHistory.length - 1
                                        ) {
                                          return (
                                            <ListItem
                                              disablePadding
                                              key={index}
                                            >
                                              <ListItemButton>
                                                <ListItemIcon>
                                                  <DirectionsBikeIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="En proceso de entrega" />
                                              </ListItemButton>
                                            </ListItem>
                                          );
                                        }
                                        if (index === 0) {
                                          return (
                                            <ListItem
                                              disablePadding
                                              key={index}
                                            >
                                              <ListItemButton>
                                                <ListItemIcon>
                                                  <DirectionsBikeIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Producto retirado en tienda" />
                                              </ListItemButton>
                                            </ListItem>
                                          );
                                        }
                                      }
                                    )}
                                  </List>
                                </div>
                              ) : null}
                            </CardContent>
                            <Typography variant="body1" color="text.primary">
                              {bicciOrderData[0].userName}
                            </Typography>
                            <Typography variant="body1" color="text.primary">
                              Te llamaremos al tel: +569
                              {bicciOrderData[0].userPhone}
                            </Typography>
                            <Typography variant="body1" color="text.primary">
                              {bicciOrderData[0].toAddress}
                            </Typography>
                            <Divider></Divider>
                            <Typography variant="body2" color="text.primary">
                              Observaciones
                            </Typography>
                            <Typography variant="body2" color="text.primary">
                              Es obligatorio, para el conductor tomará datos de
                              entrega, fotos y nombre de la persona que recibira
                              el producto.
                            </Typography>
                          </CardContent>
                          <CardActions disableSpacing>
                            <IconButton aria-label="share">
                              <ShareIcon />
                            </IconButton>
                          </CardActions>
                        </Card>

                        <Card
                          sx={{
                            maxWidth: "99%",
                            margin: "5px",
                            borderRadius: "3px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#591e8f",
                                  width: "100px",
                                  height: "100px",
                                }}
                                aria-label="recipe"
                                src={bicciOrderData[0].driverAvatar}
                              ></Avatar>
                            }
                            title={bicciOrderData[0].driverName}
                            subheader={format(
                              bicciOrderData[0].createdAt.seconds * 1000,
                              "dd-MM-yyyy"
                            )}
                          />
                        </Card>

                        <Card
                          sx={{
                            maxWidth: "99%",
                            margin: "5px",
                            borderRadius: "3px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <CardHeader title="Historial" />
                          {/* <CardContent>
                            {order.bicciClientHistory ? (
                              <div>
                                <List
                                  sx={{
                                    width: "100%",
                                    bgcolor: "background.paper",
                                  }}
                                  aria-label="contacts"
                                >
                                  {order.bicciClientHistory.map(
                                    (item, index) => {
                                      return (
                                        <ListItem
                                          disablePadding
                                          key={index}
                                          sx={{
                                            backgroundColor:
                                              translateStateBicciFalabellaColors[
                                                item.status
                                              ],
                                            marginBottom: "5px",
                                            color: "#f8f8f8",
                                          }}
                                        >
                                          <ListItemButton>
                                            <ListItemIcon>
                                              <DirectionsBikeIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={
                                                translateStateBicciFalabella[
                                                  item.status
                                                ]
                                              }
                                            />
                                          </ListItemButton>
                                        </ListItem>
                                      );
                                    }
                                  )}
                                </List>
                              </div>
                            ) : null}
                          </CardContent>

                          <CardActions disableSpacing></CardActions> */}
                          <div>Historial</div>
                          <div>{VerticalLinearStepper(activeStep)}</div>
                        </Card>
                      </div>
                    </Grid2>
                    <Grid2
                      computer={12}
                      mobile={16}
                      tablet={16}
                      className="GridColumnExternal"
                    >
                      <div className="rigthbarnav">
                        {bicciOrderData ? (
                          <ExternalMapsTraking
                            markersPending={bicciOrderData[0].toAddressLocation}
                            driverLocation={bicciOrderData[0].driverLocation}
                          ></ExternalMapsTraking>
                        ) : null}
                      </div>
                      <div>mapa</div>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Card
            sx={{
              maxWidth: 500,
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2em",
            }}
          >
            <CardHeader
              title="No se encontró una entrega asociada a este link"
              subheader={format(new Date(), "dd-MM-yyyyy hh:mm:ss")}
            />
            <CardMedia
              component="img"
              height="194"
              image="https://bicciexpress.cl/static/media/1.281088f583fcd5765041.jpg"
              alt="bicciexpress"
              sx={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                No se encontró, el link de tracking para este envío. Solicita
                más información, puede que la orden, no se envío a través de
                nuestros servicios.
              </Typography>
            </CardContent>
            <CardActions disableSpacing></CardActions>
          </Card>
        </div>
      )}
    </div>
  ) : (
    <>
      <LinearProgress></LinearProgress>
    </>
  );
};

export default External;
