import React, { useState, useEffect } from "react";
import Sidebar from "./Components/Sidebar";
import Content from "./Components/Content";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const DocumentsApi = () => {
  const [selectedContent, setSelectedContent] = useState("introduction");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleMenuClick = (content) => {
    setSelectedContent(content);
    setDrawerOpen(false);
  };

  const drawer = (
    <div style={{ paddingTop: "64px" }}>
      <List>
        <ListItemButton onClick={() => handleMenuClick("introduction")}>
          <ListItemText primary="Introduction" />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleMenuClick("integration-environment")}
        >
          <ListItemText primary="Integration Environment" />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleMenuClick("production-environment")}
        >
          <ListItemText primary="Production Environment" />
        </ListItemButton>
        <ListItemButton onClick={() => handleMenuClick("last-mile-api")}>
          <ListItemText primary="Last Mile API" />
        </ListItemButton>
        <ListItemButton onClick={() => handleMenuClick("create-api")}>
          <ListItemText primary="Create API" />
        </ListItemButton>
        <ListItemButton onClick={() => handleMenuClick("bulk-creation")}>
          <ListItemText primary="Bulk Creation" />
        </ListItemButton>
        <ListItemButton onClick={() => handleMenuClick("state-homologation")}>
          <ListItemText primary="State Homologation" />
        </ListItemButton>
        <ListItemButton onClick={() => handleMenuClick("create-api-variables")}>
          <ListItemText primary="Create API Variables" />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleMenuClick("bulk-creation-variables")}
        >
          <ListItemText primary="Bulk Creation Variables" />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <div className="documentsApiapp" id="DocumentsApi">
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
            style={{
              position: "fixed",
              top: 70,
              left: 10,
              backgroundColor: "#f8f8f8",
              borderRadius: "8px",
              padding: 3,
            }}
          >
            <MenuIcon />{" "}
            <Typography sx={{ fontSize: 12, color: "#030303" }}>
              Menu documentación
            </Typography>
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            {drawer}
          </Drawer>
        </>
      ) : (
        <Sidebar onSelect={setSelectedContent} />
      )}
      <div className="contentContainer">
        <Content selectedContent={selectedContent} />
      </div>
    </div>
  );
};

export default DocumentsApi;
