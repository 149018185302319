import { Timestamp } from "firebase/firestore";
import {
  createDocumentRoute,
  waypoint,
  waypointOutRute,
} from "./DocumentRoute";

export function buildRouteDocuments({
  routes,
  routeInfo,
  reqApiOptimizationData,
  routeGeometry,
  optimizedWaypoints,
  metricsRoutes,
  companyID = "demoCompany",
  userCreator = "demoUser",
  routeColors,
}) {
  const routeDocuments = [];

  function normalizeDistance(formattedDistance) {
    return formattedDistance
      ? Math.round(parseFloat(formattedDistance) * 1000)
      : 0;
  }

  function normalizeTime(formattedTime) {
    return formattedTime ? Math.round(parseFloat(formattedTime) * 60) : 0;
  }

  function formatTimestampToString(timestamp) {
    if (!timestamp) return "";

    let dateObj;
    if (
      timestamp?.seconds !== undefined &&
      timestamp?.nanoseconds !== undefined
    ) {
      dateObj = new Date(timestamp.seconds * 1000);
    } else if (timestamp instanceof Date) {
      dateObj = timestamp;
    } else {
      return "";
    }

    const date = dateObj.toISOString().slice(0, 10);
    const time = dateObj.toISOString().slice(11, 19);
    return `${date} ${time}`;
  }

  for (const item of routes) {
    const doc = createDocumentRoute();

    const dateStamp = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    const randomPart = Math.floor(Math.random() * 90000000 + 10000000);
    const nombreRuta = `${dateStamp}_${randomPart}_${item}`;

    doc.id = nombreRuta;
    doc.route = item;
    doc.companyID = companyID;
    doc.createdBy = userCreator;
    doc.packageCounts = routeInfo.packageCounts[item] || 0;
    doc.routeColor = routeColors[item];

    if (metricsRoutes[item]) {
      doc.totalDistance = metricsRoutes[item].totalDistance || "";
      doc.totalTime = metricsRoutes[item].totalTime || "";
    }

    const geometryMatch = routeGeometry.find((g) => g.route === item);
    doc.geometry =
      geometryMatch && Array.isArray(geometryMatch.geometry)
        ? Object.fromEntries(
            geometryMatch.geometry.map((point, index) => [index, point])
          )
        : {};

    doc.subRoutes = routes.filter((sub) => sub.startsWith(`${item}_sub`));

    const waypointsArray = optimizedWaypoints
      .filter((wp) => wp.routeName === item)
      .map((wp, index) => {
        const wpt = waypoint();
        wpt.id = `${item}_index_${index}`;
        wpt.routeColor = routeColors[item];
        wpt.ids = wp.ids || [];
        wpt.routeName = nombreRuta;
        wpt.name = wp.name || "";
        wpt.index = index;
        wpt.currentStatus = wp.status || 0;
        wpt.nonDeliveryStatus = wp.nonDeliveryStatus || "";
        wpt.location.lat = wp.lat || 0;
        wpt.location.log = wp.lon || 0;
        wpt.routingData.distanceMeters = normalizeDistance(wp.distancia || 0);
        wpt.routingData.formattedDistance = wp.distancia;
        wpt.routingData.estimatedTimeSeconds = normalizeTime(wp.tiempo || 0);
        wpt.routingData.formattedTime = wp.tiempo || 0;
        wpt.routingData.segment = wp.tramo || "";
        wpt.routingData.total = wp.total || 1;

        const orderData = routeInfo.ordersByRoute[item]?.find(
          (o) => o.id === wp.ids[0]
        );
        if (orderData?.createdAt) {
          wpt.trackingData.creationDateFormated = formatTimestampToString(
            orderData.createdAt
          );
          wpt.trackingData.creationTime = orderData.createdAt;
        } else {
          const nowTimestamp = Timestamp.now();
          wpt.trackingData.creationDateFormated =
            formatTimestampToString(nowTimestamp);
          wpt.trackingData.creationTime = nowTimestamp;
        }
        return wpt;
      });

    const waypointsOutRoute = optimizedWaypoints
      .filter((wp) => wp.routeName !== item && wp.nearRoute === item)
      .map((wp, index) => {
        const wptOut = waypointOutRute();
        wptOut.id = `${item}_index_${index}_${wp.ids?.[0] || "unknown"}`;
        wptOut.ids = wp.ids || [];
        wptOut.routeName = item;
        wptOut.name = wp.toAddress || "";
        wptOut.index = index;
        wptOut.currentStatus = wp.status || 0;
        wptOut.nonDeliveryStatus = wp.nonDeliveryStatus || "";
        wptOut.location.lat = wp.lat || 0;
        wptOut.location.log = wp.lon || 0;
        return wptOut;
      });

    const ordersArray = routeInfo.ordersByRoute[item] || [];
    doc.analytics.totalOrders = ordersArray.length;

    doc.dayStart = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    doc.initHour = "08:00";
    doc.endHour = "18:00";

    doc.isComplete = false;
    doc.isFinish = false;
    doc.isClosed = false;

    const now = new Date();
    doc.createAt = Timestamp.fromDate(now);
    doc.updateAt = Timestamp.fromDate(now);

    doc.subcollections = {
      waypoints: waypointsArray,
      waypointsOutRoute: waypointsOutRoute,
      observations: [
        {
          id: `${doc.id}_obs_1`,
          comment: "Ruta generada desde operaciones",
          createdAt: now,
          createdBy: userCreator,
        },
      ],
    };

    routeDocuments.push(doc);
  }

  return routeDocuments;
}
