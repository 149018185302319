import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import FrmRidersLastMille from "../../../components/formularios/FrmRidersLastMille/FrmRidersLastMille";
import FrmRidersRetail from "../../../components/formularios/FrmRidersRetail/FrmRidersRetail";
import { useParams } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";

const PostulacionesTypes = (props) => {
  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const { type } = useParams();

  const [fromType, setFromType] = React.useState();

  React.useEffect(() => {
    console.log(type);
    setFromType(type);
  }, [type]);
  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return (
    <Box sx={{ backgroundColor: "#f5f5f5", py: 4 }}>
      {/* Sección de Presentación */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          color="primary"
          sx={{
            fontSize: { xs: "1.8rem", sm: "2rem", md: "2.5rem" },
            px: 2,
          }}
        >
          {fromType === "last-mille"
            ? "Únete a la Flota de Última Milla de Bicci Supermercados o Farmacias"
            : fromType === "retail"
            ? "Únete a la Flota de Última Milla de Bicci Retail"
            : "Únete a la Flota de Bicci"}
        </Typography>

        <Typography variant="h6" color="textSecondary">
          Postula como BICCI-RIDER
        </Typography>
      </Box>

      {/* Sección de Imagen */}
      <Grid2 container justifyContent="center" sx={{ mb: 4 }}>
        <Grid2 item xs={12} sm={10} md={8} lg={6}>
          {fromType === "last-mille" && (
            <Box
              component="img"
              src="/static/images/asset/006_1.png"
              alt="Bicci Last Mille"
              sx={{
                width: "100%",
                maxWidth: 600,
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          )}
          {fromType === "retail" && (
            <Box
              component="img"
              src="/static/images/asset/005_1.png"
              alt="Bicci Retail"
              sx={{
                width: "100%",
                maxWidth: 600,
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          )}
        </Grid2>
      </Grid2>

      {/* Sección de Formulario */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            px: 2,
          }}
        >
          Contesta el formulario para asignarte la mejor zona de trabajo
        </Typography>

        <Typography variant="h6" color="textSecondary">
          ¡Inscríbite ahora!
        </Typography>
      </Box>

      {/* Formulario */}

      <Box>
        {fromType === "last-mille" && <FrmRidersLastMille />}
        {fromType === "retail" && <FrmRidersRetail />}
        {fromType !== "retail" && fromType !== "last-mille" && (
          <Typography color="error">
            No encontramos lo que estás buscando... Contáctanos.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PostulacionesTypes;
