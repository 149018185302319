import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { clientAddCartProduct } from "../../../redux/acccionesCart";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";
import { Tabs, Tab, Box, Grid, Button, Typography } from "@mui/material";

const SeleccionePlan = (props) => {
  const dispatch = useDispatch();
  const [productosActivios, setProductosActivios] = useState([]);
  const [value, setValue] = useState(0); // Estado para la pestaña seleccionada

  let userUid = "noregister";
  let userState = false;

  try {
    const user = useSelector((store) => store.usuario.user.uid);
    userUid = user;
    userState = true;
  } catch (error) {
    userState = false;
  }

  useEffect(() => {
    const obtenerTodosLosServicios = async () => {
      try {
        const producServices = await db
          .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
          .get();

        const servicios = producServices.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const dataBolsas = servicios
          .filter((item) => !item.isDelete)
          .filter((item) => item.isActive)
          .filter((item) => item.type !== "custom");

        setProductosActivios(dataBolsas);
      } catch (error) {
        console.error("Error obteniendo los servicios:", error);
      }
    };

    obtenerTodosLosServicios();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addToCart = (e) => {
    let obj = e.target.value;
    dispatch(clientAddCartProduct(obj, userUid));
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs de Material-UI */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Planes Disponibles" />
      </Tabs>

      {/* Contenido de cada Tab */}
      <TabPanel value={value} index={0}>
        <ContainerViewComponent>
          {productosActivios.map((element) => (
            <Grid container key={element.id} spacing={2} className="divmap">
              <Grid item xs={12} sm={6} className="dash-container-colmun">
                <Typography variant="h6">{element.planName}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addToCart}
                  value={element.id}
                >
                  Seleccionar
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} className="dash-container-colmun">
                <Typography variant="h6">Plan</Typography>
                <Typography variant="body2">{element.description}</Typography>
                {element.descriptorsWeb.length > 0 && (
                  <Typography variant="body2">Cuenta con:</Typography>
                )}
                <Typography variant="body2">
                  Precio: ${element.salePrice}
                </Typography>
                <Typography variant="body2">
                  Descuento: ${element.discountAmount}
                </Typography>
                <Typography variant="h6">
                  Total a pagar: <strong>${element.totalAmount}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} className="distproductosdescrip">
                <Typography variant="h6">Uso</Typography>
                <Typography variant="body2">
                  Se agregan mensualmente {element.ChargeShipments} envíos a su
                  cuenta, asociados a un tarifario de tipo{" "}
                  <strong>{element.rateEbiex}</strong>
                </Typography>
              </Grid>
            </Grid>
          ))}
        </ContainerViewComponent>
      </TabPanel>
    </Box>
  );
};

// Componente auxiliar para manejar el contenido de cada tab
const TabPanel = ({ children, value, index }) => {
  return (
    <Box sx={{ p: 3, display: value === index ? "block" : "none" }}>
      {children}
    </Box>
  );
};

export default SeleccionePlan;
