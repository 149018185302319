import React, { useState } from "react";
import { db } from "../../../firebase";
import {
  setDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Card,
  Divider,
  CardHeader,
  Box,
  Grid2,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FadeInOut from "../../../components/animations/FadeInOut";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import Chip from "@mui/material/Chip";
import DashIconsResumenVehicles from "../../../components/Dashboard/DashIconsResumenVehicles/DashIconsResumenVehicles";
import { DataGrid } from "@mui/x-data-grid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import moment from "moment";
import { LinearProgress } from "@mui/material";
import Popup from "../../../components/Popup";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import useGetVehiclesTypes from "../../../hooks/getVehiclesTypes";
import useGetTravelModels from "../../../hooks/getTravelModels";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const libraries = ["places"];

const Vehicles = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const userUid = useSelector((store) => store.usuario);
  const [companyId, setcompanyId] = useState("");
  const [isvalid, setIsValid] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [error, setError] = useState(null);

  const [vehiclesRows, setVehiclesRows] = React.useState("");

  const [directions, setDirectionsByVehicle] = React.useState("");

  const [showPopup, setshowPopup] = useState(false);
  const [typePop, setTypePop] = useState(null);
  const [cantListItems, setcantListItems] = React.useState({
    Disponibles: 0,
    Conectados: 0,
    Bloqueados: 0,
  });

  const [objRider, setobjRider] = React.useState({
    title: "showPopupWiewVehicles",
    texto: "Lorem ipsum dolor.",
  });

  const [newVehicle, setNewVehicle] = useState({
    createBy: userUid.user.uid,
    createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
    date: Date.now(),
    id: "",
    vehiclesTypes: "",
    travelMode: "",
    maxSpeed: "",
    maxTransportCapacityVol: "",
    minTransportCapacityVol: "",
    maximumCoverage: "",
    packetCapacity: "",
    requireMinPacketCapacity: "",
    cluster: companyId,
    origenAdrress: "",
    destinyAdrress: "",
    origen: "",
    destiny: "",
    workingDayMinHour: "08:00",
    workingDayMaxHour: "10:00",
    active: true,
    inRoute: false,
    isDelete: false,
    isBlocked: false,
    typePop: "vehicle",
  });
  const { userdata, data } = useObtenerDatosdeCliente(userUid.user.uid);

  const { vehiclesTypes } = useGetVehiclesTypes();
  const { travelModels } = useGetTravelModels();
  const { VehiclesDataByCompany, listVehiclesDataByCompany } =
    useGetDataVehiclesByCompany(companyId);
  const { ridersDataByCompanyActive } = useGetDataRiderByCompany(companyId);

  const listItems = [
    { id: "Conectados" },
    { id: "Bloqueados" },
    { id: "Disponibles" },
  ];

  useEffect(() => {
    //console.log(companyId)
    findAdressesCompany(companyId);
  }, [companyId]);

  useEffect(() => {
    //console.log(userdata)
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
      setNewVehicle({ ...newVehicle, cluster: userdata.userCompanyID });
    }
  }, [data, userdata]);

  useEffect(() => {
    if (VehiclesDataByCompany) {
      getCompanyVehicles();
      // console.log(VehiclesDataByCompany);
      setVehiclesRows(VehiclesDataByCompany);
      setcantListItems(listVehiclesDataByCompany);
    }
  }, [VehiclesDataByCompany && listVehiclesDataByCompany]);

  const findAdressesCompany = async (companyUid) => {
    //console.log(companyUid)
    try {
      const direccionCollectionRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${companyUid}/direcciones`
      );
      const addressesQuerySnapshot = await getDocs(direccionCollectionRef);
      // console.log(adressses.docs)
      const addresses = [];
      addressesQuerySnapshot.forEach((doc) => {
        // Agregamos los datos del documento a un array.
        addresses.push({ id: doc.id, ...doc.data() });
      });
      //console.log(directions)
      setDirectionsByVehicle(addresses);
    } catch (error) {
      // console.log(error)
    }
  };

  const togglePopup = () => {
    setshowPopup(!showPopup);
  };

  const toggleShowEditNew = () => {
    setShowEdit(!showEdit);
    //console.log('Abrir inviatcion')
  };

  const clearFrom = () => {
    setNewVehicle({
      createBy: userUid.user.uid,
      createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      date: Date.now(),
      id: "",
      vehiclesTypes: "",
      travelMode: "",
      maxSpeed: "",
      maxTransportCapacityVol: "",
      minTransportCapacityVol: "",
      maximumCoverage: "",
      packetCapacity: "",
      requireMinPacketCapacity: "",
      cluster: companyId,
      origenAdrress: "",
      destinyAdrress: "",
      origen: "",
      destiny: "",
      workingDayMinHour: "08:00",
      workingDayMaxHour: "10:00",
      active: true,
      inRoute: false,
      typePop: "vehicle",
      driverAssing: "",
    });
  };
  const saveNewVehicle = async (e) => {
    e.preventDefault();
    if (!newVehicle.id.trim()) {
      setError("Indique un identificador del vehiculo");
      setIsValid(false);
      return;
    }
    if (!newVehicle.vehiclesTypes.trim()) {
      setError("Seleccione un tipo valido de vehiculo");
      setIsValid(false);
      return;
    }
    if (!newVehicle.travelMode.trim()) {
      setError("Indique modo de viaje");
      setIsValid(false);
      return;
    }
    if (!newVehicle.maxSpeed.length > 0) {
      setError("Indique la maxima velocidad");
      setIsValid(false);
      return;
    }
    if (!newVehicle.maximumCoverage.length > 0) {
      setError("Indique la maximo de covertura");
      setIsValid(false);
      return;
    }
    if (!newVehicle.maxTransportCapacityVol.length > 0) {
      setError("Indique la maximo de volumen de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.minTransportCapacityVol.length > 0) {
      setError("Indique la minimo de volumen de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.packetCapacity.length > 0) {
      setError("Indique capacidad de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.requireMinPacketCapacity.length > 0) {
      setError("Indique minimo de carga");
      setIsValid(false);
      return;
    }
    if (!newVehicle.origen) {
      setError("Seleccione un origen");
      setIsValid(false);
      return;
    }
    if (!newVehicle.destiny) {
      setError("Seleccione un destino");
      setIsValid(false);
      return;
    }
    if (!newVehicle.workingDayMaxHour.length > 0) {
      setError("Indique hora de retorno");
      setIsValid(false);
      return;
    }
    if (!newVehicle.workingDayMinHour.length > 0) {
      setError("Indique hora de inicio");
      setIsValid(false);
      return;
    }
    setIsValid(true);
    try {
      const companyVehiclesCollectionRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
      );
      const vehicleDocRef = doc(companyVehiclesCollectionRef, newVehicle.id);
      await setDoc(vehicleDocRef, newVehicle);
    } catch (error) {
      //console.log(error)
    }
    updateRows();

    clearFrom();
    setShowEdit(false);
  };

  const updateRows = async () => {
    try {
      const companyVehiclesCollectionRef = collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
      );
      const companyVehiclesQuerySnapshot = await getDocs(
        companyVehiclesCollectionRef
      );
      const dataRows = [];
      companyVehiclesQuerySnapshot.forEach((doc) => {
        dataRows.push({ id: doc.id, ...doc.data() });
      });
      //console.log(dataRows)

      let dataRowsBeforeDelete = dataRows.filter(
        (item) => item.isDelete === false
      );

      let vehicleNoActive = dataRowsBeforeDelete.filter(
        (item) => item.isBlocked === true
      );
      let vehicleActive = dataRowsBeforeDelete.filter(
        (item) => item.isBlocked === false
      );
      let vehicleInRoute = dataRowsBeforeDelete.filter(
        (item) => item.inRoute === true
      );

      setcantListItems({
        Disponibles: vehicleActive.length,
        Conectados: vehicleInRoute.length,
        Bloqueados: vehicleNoActive.length,
      });

      setVehiclesRows(dataRowsBeforeDelete);
    } catch (error) {
      console.log(error);
    }
  };

  const columnsVehicles = [
    {
      field: "actions",
      headerName: "Acciones",
      width: 400,
      renderCell: (vehiclesRows) => {
        //      // console.log(ridersRows)
        return (
          <>
            {vehiclesRows.row.isBlocked === true ? (
              <Chip
                label={vehiclesRows.row.id}
                onClick={showPopupWiewVehicles}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                variant="outlined"
                icon={
                  <LocalShippingIcon
                    style={{
                      color: "#fff",
                      textAling: "left",
                    }}
                  />
                }
                style={{
                  color: "#fff",
                  backgroundColor: "grey",
                  maxWidth: "200px",
                  minWidth: "200px",
                }}
              />
            ) : (
              <Chip
                label={vehiclesRows.row.id}
                onClick={showPopupWiewVehicles}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                variant="outlined"
                icon={
                  <LocalShippingIcon
                    style={{
                      color: "#fff",
                      textAling: "left",
                    }}
                  />
                }
                style={{
                  color: "#fff",
                  backgroundColor: "#591e8f",
                  maxWidth: "200px",
                  minWidth: "200px",
                }}
              />
            )}

            {vehiclesRows.row.isBlocked === false ? (
              <Chip
                label="Desactivar"
                onClick={handleBloqPropsVehicleRows}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                icon={<BlockIcon />}
                variant="outlined"
                style={{
                  color: "#010101",
                  backgroundColor: "#f7e908",
                }}
              />
            ) : (
              <Chip
                label="Activar"
                onClick={handleUnBloqPropsVehicleRows}
                id={vehiclesRows.row.id}
                value={vehiclesRows.row.id}
                icon={
                  <BlockIcon
                    style={{
                      color: "#fff",
                    }}
                  />
                }
                variant="outlined"
                style={{
                  color: "#fff",
                  backgroundColor: "#0400ef",
                }}
              />
            )}

            <Chip
              label="Eliminar"
              onClick={handleDeletedPropsVehicleRows}
              id={vehiclesRows.row.id}
              value={vehiclesRows.row.id}
              deleteIcon={<DeleteIcon />}
              variant="outlined"
              style={{
                color: "#fff",
                backgroundColor: "#d5182b",
              }}
            />
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Estado",
      sortable: false,
      width: 250,
      renderCell: (vehiclesRows) => {
        // console.log(ridersRows.row.active)
        return (
          <>
            <Chip
              label={
                vehiclesRows.row.inRoute === true ? "On Route" : "OF Route"
              }
              icon={
                <AccountCircleIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              style={{
                color: "#010101",
                backgroundColor:
                  vehiclesRows.row.inRoute === true ? "#1ef708" : "No Activado",
              }}
            />
          </>
        );
      },
    },
  ];

  const showPopupWiewVehicles = (e) => {
    //console.log(e.currentTarget.id)
    var searchData = vehiclesRows.find(
      (item) => item.id === e.currentTarget.id
    );
    //  var searchData = ridersDataByCompany.find (item => item.email === search.email)

    setTypePop("vehicle");
    setobjRider({
      title: searchData.id,
      texto: "",
      data: searchData,
    });
    togglePopup();
  };

  const handleBloqPropsVehicleRows = async (e) => {
    // console.log('BLOQ ACTIVE ' + e.currentTarget.id )
    const companyVehiclesCollectionRef = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
    );
    const vehicleDocRef = doc(companyVehiclesCollectionRef, e.currentTarget.id);
    await updateDoc(vehicleDocRef, {
      isBlocked: true,
      active: false,
    });
    updateRows();
  };

  const handleUnBloqPropsVehicleRows = async (e) => {
    // console.log('BLOQ ACTIVE ' + e.currentTarget.id )
    const companyVehiclesCollectionRef = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
    );
    const vehicleDocRef = doc(companyVehiclesCollectionRef, e.currentTarget.id);
    await updateDoc(vehicleDocRef, {
      isBlocked: false,
      active: true,
    });
    updateRows();
  };

  const handleDeletedPropsVehicleRows = async (e) => {
    // console.log('DELETE ACTIVE' + e.currentTarget.id )
    const companyVehiclesCollectionRef = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${newVehicle.cluster}/companyvehicles`
    );
    const vehicleDocRef = doc(companyVehiclesCollectionRef, e.currentTarget.id);
    await updateDoc(vehicleDocRef, {
      isDelete: true,
      active: false,
    });
    updateRows();
  };

  const handleUnDeletedPropsRidersRows = async (e) => {
    //console.log('DELETE ACTIVE' + e.currentTarget.id )
    await db
      .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
      .doc(newVehicle.cluster)
      .collection("companyvehicles")
      .doc(e.currentTarget.id)
      .update({
        isDelete: false,
        active: true,
      });
  };

  const getCompanyVehicles = () => {
    // console.log(vehiclesTypes)
    //console.log(VehiclesDataByCompany)
  };

  const habdleOnChange = (e) => {
    // console.log(e.target.value)
    // console.log(e.target.name)
    setNewVehicle({ ...newVehicle, [e.target.name]: e.target.value });
  };

  const handleSelectDirection = (e) => {
    const name = e.target.name;
    const getpoint = async (address) => {
      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        let latlng = {
          lat: "",
          lng: "",
        };
        latlng.lat = lat;
        latlng.lng = lng;
        //console.log(latlng)
        setNewVehicle({ ...newVehicle, [name]: latlng });
      } catch (error) {}
    };
    getpoint(e.target.value);
  };
  //console.log(newVehicle);

  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return (
    <>
      <Grid2 celled="internally" id="expresscuentaContent">
        <h2 className="h2-withe">Mis Vehículos</h2>
        {error ? <span className="alert alert-danger m-2">{error}</span> : null}
        <Grid2>
          <Grid2
            computer={8}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms typeMiniformsdrivers typeMiniformsCard">
              <CardHeader title="Resumen" />
              <DashIconsResumenVehicles
                items={listItems}
                cant={cantListItems}
              ></DashIconsResumenVehicles>
            </Card>
            <Card className="typeMiniforms">
              <CardHeader
                subheader="Registra tus vehiculos. Es necesario completar los datos"
                title="Nuevo Vehículo"
              />
              {userdata && userdata.userCompanyID ? (
                <>
                  {showEdit === true ? (
                    <div>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "flex-center",
                          outline: "none",
                          m: 1,
                        }}
                        color="primary"
                        variant="contained"
                        onClick={toggleShowEditNew}
                      >
                        <HighlightOffIcon className="dashIcon-red" />
                        Cancelar
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        sx={{
                          backgroundColor: "#591E8F",
                          border: "none",
                          borderRadius: "3px",
                          color: "#fff",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "30px",
                          outline: "none",
                          padding: "5px",
                          minWidth: "50px",
                          fontWeight: 400,
                          margin: "0em 1em 0em 0em",
                          ":hover": {
                            backgroundColor: "#25b2f4 !important",
                            color: "#591e8f",
                          },
                        }}
                        color="primary"
                        variant="contained"
                        onClick={toggleShowEditNew}
                      >
                        <AddCircleIcon className="dashIcon-green" />
                        Agregar
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <> Debe registrar su empresa para agregar vehículos</>
              )}

              <Divider />
              <FadeInOut show={showEdit} duration={500}>
                <ContainerViewComponent
                  className="formcontent"
                  id="micuentafromIni"
                >
                  <hr></hr>

                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#eeeeee",
                      p: 3,
                      borderRadius: 2,
                    }}
                    component="form"
                    onSubmit={saveNewVehicle}
                  >
                    {/* Conductor Responsable */}
                    <Typography variant="h6">Conductor Responsable</Typography>
                    {ridersDataByCompanyActive ? (
                      <Select
                        fullWidth
                        name="driverAssing"
                        value={newVehicle.driverAssing}
                        onChange={(e) => habdleOnChange(e)}
                        sx={{ mt: 1, mb: 2 }}
                      >
                        <MenuItem value="">
                          Seleccione conductor de su empresa
                        </MenuItem>
                        {ridersDataByCompanyActive.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Typography>No data</Typography>
                    )}

                    {/* Información del vehículo */}
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <TextField
                        fullWidth
                        label="Company"
                        value={newVehicle.cluster}
                        disabled
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Placa / Matricula"
                        name="id"
                        value={newVehicle.id}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                    </Box>

                    {/* Tipo de vehículo y tipo de viaje */}
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      {vehiclesTypes ? (
                        <Select
                          fullWidth
                          name="vehiclesTypes"
                          value={newVehicle.vehiclesTypes}
                          onChange={habdleOnChange}
                        >
                          <MenuItem value="">
                            Seleccione tipo de vehículo
                          </MenuItem>
                          {vehiclesTypes.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography>No data</Typography>
                      )}

                      {travelModels ? (
                        <Select
                          fullWidth
                          name="travelMode"
                          value={newVehicle.travelMode}
                          onChange={habdleOnChange}
                        >
                          <MenuItem value="">Seleccione tipo de viaje</MenuItem>
                          {travelModels.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.id}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography>No data</Typography>
                      )}
                    </Box>

                    {/* Características del vehículo */}
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      <TextField
                        fullWidth
                        label="Velocidad Máxima (Km/h)"
                        name="maxSpeed"
                        type="number"
                        value={newVehicle.maxSpeed}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Cobertura (Km)"
                        name="maximumCoverage"
                        type="number"
                        value={newVehicle.maximumCoverage}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                    </Box>

                    {/* Capacidades de transporte */}
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      <TextField
                        fullWidth
                        label="Capacidad de Transporte Máx"
                        name="maxTransportCapacityVol"
                        type="number"
                        value={newVehicle.maxTransportCapacityVol}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Capacidad de Transporte Mín"
                        name="minTransportCapacityVol"
                        type="number"
                        value={newVehicle.minTransportCapacityVol}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                    </Box>

                    {/* Capacidad de paquetes */}
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      <TextField
                        fullWidth
                        label="Capacidad de Paquetes"
                        name="packetCapacity"
                        type="number"
                        value={newVehicle.packetCapacity}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Capacidad Mín de Paquetes"
                        name="requireMinPacketCapacity"
                        type="number"
                        value={newVehicle.requireMinPacketCapacity}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                    </Box>

                    {/* Dirección de sucursal */}
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Seleccione una dirección de sucursal
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                      {directions ? (
                        <Select
                          fullWidth
                          name="origen"
                          value={newVehicle.adressName}
                          onChange={handleSelectDirection}
                        >
                          <MenuItem value="">Origen</MenuItem>
                          {directions.map((item, index) => (
                            <MenuItem key={index} value={item.adressAddress}>
                              {item.adressName}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography>No data</Typography>
                      )}

                      {directions ? (
                        <Select
                          fullWidth
                          name="destiny"
                          value={newVehicle.adressName}
                          onChange={handleSelectDirection}
                        >
                          <MenuItem value="">Destino</MenuItem>
                          {directions.map((item, index) => (
                            <MenuItem key={index} value={item.adressAddress}>
                              {item.adressName}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography>No data</Typography>
                      )}
                    </Box>

                    {/* Horario de trabajo */}
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Horario de trabajo
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                      <TextField
                        fullWidth
                        label="Hora de Inicio"
                        type="time"
                        name="workingDayMinHour"
                        value={newVehicle.workingDayMinHour}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Hora de Final"
                        type="time"
                        name="workingDayMaxHour"
                        value={newVehicle.workingDayMaxHour}
                        onChange={habdleOnChange}
                        variant="outlined"
                      />
                    </Box>

                    {/* Botón de Guardar */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 3,
                      }}
                    >
                      <Button type="submit" variant="contained" color="primary">
                        Guardar
                      </Button>
                    </Box>
                  </Box>
                </ContainerViewComponent>
              </FadeInOut>

              <Divider />
            </Card>
          </Grid2>

          <Grid2
            computer={8}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms">
              <CardHeader
                subheader="Ver detalles, pausar actividad y eliminar vehículos."
                title="Vehículos registrados"
              />

              <Divider />
              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                <hr></hr>

                <div>
                  <div>
                    {" "}
                    <h3 className="m-1">Vehículos</h3>
                  </div>
                  <Box
                    sx={{
                      height: "500px",
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {vehiclesRows ? (
                      <DataGrid
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "bicciNumber", sort: "desc" }],
                          },
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={vehiclesRows}
                        columns={columnsVehicles}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        //onClick ={checkboxSelection}
                      />
                    ) : (
                      <>
                        {!vehiclesRows.length > 0 ? (
                          "no hay registros"
                        ) : (
                          <LinearProgress />
                        )}
                      </>
                    )}
                  </Box>
                </div>
              </ContainerViewComponent>

              <Divider />
            </Card>
            <div></div>
          </Grid2>
        </Grid2>
      </Grid2>
      {showPopup ? (
        <Popup
          contenido={objRider}
          togglePopup={togglePopup}
          type={typePop}
        ></Popup>
      ) : null}
    </>
  );
};

export default Vehicles;
