import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { FilterDialog } from "./dialogs";

const ButonHandleFilterDialog = ({
  dataMerge,
  availableStatuses,
  selectedStatuses,
  setSelectedStatuses,
  translateStatesfalabbela,
  translateStatesBicciFilter,
  selectedOrders,
  setSelectedOrders,
  setViewDataMerge,
  setLocalDataMerge,
  setSelectedRoutes,
  setTargetRoute,
}) => {
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  useEffect(() => {}, [openFilterDialog]);

  const handleOpenFilterDialog = async () => {
    setOpenFilterDialog(true);
  };
  const handleCancelFilter = async () => {
    setOpenFilterDialog(false);
  };

  const handleApplyFilter = () => {
    // Actualizar las órdenes en el estado

    if (selectedStatuses.length !== 0) {
      const filteredOrders = dataMerge.filter((order) =>
        selectedStatuses.includes(
          order.status
            .toString() //|| order.falabella_status || order.intState || "")
            .trim()
            .toLowerCase()
        )
      );

      setViewDataMerge(filteredOrders);

      setLocalDataMerge(filteredOrders);
    } else {
      setViewDataMerge(dataMerge);

      setLocalDataMerge(dataMerge);
    }

    // Limpiar selección de órdenes y rutas

    handleCancelFilter();
  };

  return (
    <>
      <Button onClick={() => handleOpenFilterDialog(setOpenFilterDialog)}>
        Filtrar por estados
      </Button>
      <FilterDialog
        openFilterDialog={openFilterDialog}
        handleCancelFilter={handleCancelFilter}
        availableStatuses={availableStatuses}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        translateStatesfalabbela={translateStatesfalabbela}
        translateStatesBicciFilter={translateStatesBicciFilter}
        handleApplyFilter={() => handleApplyFilter()}
      ></FilterDialog>
    </>
  );
};

export default ButonHandleFilterDialog;
