import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Button,
  Chip,
  LinearProgress,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  AccountCircle,
  CellTower,
  HighlightOff,
  AddCircle,
  Delete,
  Block,
  Phone,
  Email,
  Person,
  Business,
} from "@mui/icons-material";
import { collection, doc, updateDoc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import FadeInOut from "../../../components/animations/FadeInOut";
import useGetDataCollaboratorByCompany from "../../../hooks/useGetDataCollaboratorByCompany";
import DashIconsResumenUser from "../../../components/Dashboard/DashIconsResumenUser/DashIconsResumenUser";
import Popup from "../../../components/Popup";
import moment from "moment";
import { getRecibeRequestAccessToCompany } from "../../../utils/requestAccessToCompany";
import { UserContext } from "../../../context/CurrentUserProvider";
import ReusableVewData from "../../../components/ReusableVewData/ReusableVewData";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const AddUser = (props) => {
  const [companyId, setCompanyId] = useState("");
  const [error, setError] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [userInvited, setUserInvited] = useState({
    username: "",
    userLastName: "",
    companyName: "",
    phone: "",
    companyId: "",
    email: "",
  });
  const [isValid, setValid] = useState(null);
  const [usersRows, setUsersRows] = useState([]);
  const [requestDocs, setRequestDocs] = useState([]);

  const { userDataContext } = useContext(UserContext);
  const { userData, companyData } = userDataContext;

  const {
    collaboratorDataByCompany,
    listCollaboratorDataByCompany,
    loadCollaboratorData,
    errorCollaboratorData,
    refreshData,
  } = useGetDataCollaboratorByCompany(companyId);

  const [objUser, setObjUser] = useState({
    open: false,
    handleClose: () => {},
    formFields: [],
    initialValues: {},
  });
  const [showPopup, setShowPopup] = useState(false);
  const [typePop, setTypePop] = useState("usercompany");
  const [cantListItems, setCantListItems] = useState({
    Disponibles: 0,
    Invitados: 0,
    Bloqueados: 0,
  });

  const listItems = [
    { id: "Disponibles" },
    { id: "Invitados" },
    { id: "Bloqueados" },
  ];

  useEffect(() => {
    if (userData && companyData) {
      setUserInvited({
        ...userInvited,
        companyName: companyData.companyName,
        companyId: companyData.companyID,
      });

      setCompanyId(companyData.companyID);
      getRequest(companyData.companyID);
    }
  }, [companyData && userData]);

  const getRequest = async (companyID) => {
    const documents = await getRecibeRequestAccessToCompany(companyID);
    setRequestDocs(documents);
  };

  useEffect(() => {
    if (collaboratorDataByCompany && listCollaboratorDataByCompany) {
      updateRows();
    }
  }, [collaboratorDataByCompany, listCollaboratorDataByCompany]);

  const updateRows = () => {
    try {
      // Utilizar los datos ya disponibles en lugar de hacer una nueva consulta
      let dataRows = collaboratorDataByCompany.map((doc) => ({
        id: doc.id,
        ...doc,
      }));

      let dataRowsBeforeDelete = dataRows.filter(
        (item) => item.isDeleted === false
      );
      let usersActive = dataRowsBeforeDelete.filter(
        (item) => item.isblockedAgreement === true
      );
      let userInvite = dataRowsBeforeDelete.filter(
        (item) => item.isInvited === true
      );
      let userNoBloq = dataRowsBeforeDelete.filter(
        (item) => item.isblockedAgreement === false
      );

      setCantListItems({
        Disponibles: userNoBloq.length,
        Invitados: userInvite.length,
        Bloqueados: usersActive.length,
      });

      setUsersRows(dataRowsBeforeDelete);
    } catch (error) {
      console.error(error);
    }
  };

  const rejectRequest = async (request) => {
    try {
      const requestRef = doc(db, "xpress_userCompanies", request.id);
      await updateDoc(requestRef, { status: "Rechazado", isActive: false });

      setRequestDocs((prev) =>
        prev.map((doc) =>
          doc.id === request.id ? { ...doc, status: "Rechazado" } : doc
        )
      );
    } catch (error) {
      console.error("Error al rechazar la solicitud: ", error);
    }
  };

  const formFields = [
    { variable: "companyId", label: "ID de la Compañía", type: "string" },
    { variable: "companyName", label: "Nombre de la Compañía", type: "string" },
    { variable: "email", label: "Correo Electrónico", type: "string" },
    { variable: "role", label: "Rol", type: "string" },
    { variable: "isActive", label: "Usuario Activo", type: "boolean" },
    { variable: "isDeleted", label: "Eliminado", type: "boolean" },
    { variable: "isInvited", label: "Invitado", type: "boolean" },
    {
      variable: "isblockedAgreement",
      label: "Acuerdo Bloqueado",
      type: "boolean",
    },
  ];

  const columnsUsers = [
    {
      field: "username",
      headerName: "Nombre",
      sortable: false,
      width: 150,
    },
    {
      field: "email",
      headerName: "Rider Email",
      width: 200,
      editable: true,
    },
    {
      field: "estado",
      headerName: "Estado",
      sortable: false,
      width: 250,
      renderCell: (params) => (
        <>
          <Chip
            label={params.row.isActive === true ? "Operativo" : "No Activado"}
            icon={<AccountCircle />}
            style={{
              color: "#010101",
              backgroundColor:
                params.row.isActive === true ? "#1ef708" : "#dadbda",
            }}
          />
          <Chip
            label={
              params.row.isblockedAgreement === true ? "Pausado" : "Activo"
            }
            icon={<CellTower />}
            variant="outlined"
            style={{
              color: "#010101",
              backgroundColor:
                params.row.isblockedAgreement === true ? "#dadbda" : "#1ef708",
            }}
          />
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 300,
      renderCell: (params) => (
        <>
          <Chip
            label="Ver detalles"
            onClick={showPopupWiewUser}
            id={params.row.id}
            value={params.row.id}
            variant="outlined"
            style={{
              color: "#fff",
              backgroundColor: "#591e8f",
            }}
          />
          <Chip
            label={
              params.row.isblockedAgreement === true ? "Pausado" : "habilitado"
            }
            onClick={
              params.row.isblockedAgreement === true
                ? () => handleUnBloqPropsUserRows(params.row)
                : () => handleBloqPropsUserRows(params.row)
            }
            id={params.row.id}
            value={params.row.id}
            icon={params.row.isblockedAgreement === true ? <Block /> : null}
            variant="outlined"
            style={{
              color: "#010101",
              backgroundColor:
                params.row.isblockedAgreement === true ? "#edf708" : "#1ef708",
            }}
          />
          <Chip
            label="Eliminar"
            onClick={handleDeletedPropsUsersRows}
            id={params.row.id}
            value={params.row.id}
            deleteIcon={<Delete />}
            variant="outlined"
            style={{
              color: "#fff",
              backgroundColor: "#d5182b",
            }}
          />
        </>
      ),
    },
  ];

  const showPopupWiewUser = (e) => {
    var searchData = usersRows.find((item) => item.id === e.currentTarget.id);

    setObjUser({
      open: false,
      handleClose: togglePopup,
      formFields: formFields,
      initialValues: searchData,
    });
    togglePopup();
  };

  const togglePopup = () => {
    setObjUser((prevState) => ({
      ...prevState,
      open: !prevState.open,
    }));
  };

  const handleUnBloqPropsUserRows = async (row) => {
    // Actualizar el estado en la colección companyCollaborator
    const docRefUser = doc(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${userData.userCompanyID}/companyCollaborator`,
      row.id
    );
    await updateDoc(docRefUser, { isblockedAgreement: false });

    if (row.uid) {
      try {
        const userDocRef = doc(db, process.env.REACT_APP_COL_USERS, row.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userDataUpdate = userSnapshot.data();

          // Verificar si userDataUpdate está definido antes de proceder
          if (userDataUpdate) {
            let companyCollaborator = userDataUpdate.companyCollaborator || [];

            if (typeof companyCollaborator === "string") {
              // Si es un string, conviértelo a un array y conserva el valor anterior
              companyCollaborator = [companyCollaborator];
            }

            // Agregar el ID de la empresa si no está ya en el array
            if (!companyCollaborator.includes(userData.userCompanyID)) {
              companyCollaborator.push(userData.userCompanyID);
            }

            let roll =
              userDataUpdate.roll === "client"
                ? "company_ops"
                : userDataUpdate.roll;
            let userCompanyRoll =
              userDataUpdate.userCompanyRoll === "client" ||
              userDataUpdate.userCompanyRoll === undefined
                ? "company_ops"
                : userDataUpdate.userCompanyRoll;
            let userCompanyID =
              userDataUpdate.roll !== "company_admin"
                ? userData.userCompanyID
                : userDataUpdate.userCompanyID;

            console.log("Datos actualizados:", {
              companyCollaborator,
              roll,
              userCompanyRoll,
              userCompanyID,
            });
            // Actualizar el documento del usuario
            await updateDoc(userDocRef, {
              companyCollaborator,
              roll,
              userCompanyRoll,
              userCompanyID,
            });
          } else {
            console.error("userDataUpdate no está definido");
          }
        } else {
          console.error("El documento del usuario no existe");
        }
      } catch (error) {
        console.error("Error al actualizar companyCollaborator:", error);
      }
    }

    refreshData();
  };

  const handleBloqPropsUserRows = async (row) => {
    // Actualizar el estado en la colección companyCollaborator
    const docRefUser = doc(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${userData.userCompanyID}/companyCollaborator`,
      row.id
    );
    await updateDoc(docRefUser, { isblockedAgreement: true });

    if (row.uid) {
      try {
        const userDocRef = doc(db, process.env.REACT_APP_COL_USERS, row.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userDataUpdate = userSnapshot.data();
          let companyCollaborator = userDataUpdate.companyCollaborator || [];

          if (typeof companyCollaborator === "string") {
            // Si es un string, conviértelo a un array y conserva el valor anterior
            companyCollaborator = [companyCollaborator];
          }

          // Retirar el ID de la empresa si está en el array
          companyCollaborator = companyCollaborator.filter(
            (id) => id !== userData.userCompanyID
          );

          // Actualizar el documento del usuario
          await updateDoc(userDocRef, { companyCollaborator });
        }
      } catch (error) {
        console.error("Error al actualizar companyCollaborator:", error);
      }
    }

    refreshData();
  };

  const handleDeletedPropsUsersRows = async (e) => {
    const docRefUser = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${userData.userCompanyID}/companyCollaborator`
    );
    await docRefUser.doc(e.currentTarget.id).update({ isDeleted: true });
    updateRows();
  };

  const invitedAdmin = async (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setError("Ingrese un email valido");
      return;
    }

    const docRefCompany = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${userData.userCompanyID}/companyCollaborator`
    );
    const validate = await docRefCompany.doc(userInvited.email).get();

    if (validate.companyData()) {
      setError("El usuario ya posee una invitacion");
      return;
    } else {
      let companyCollaborator = {
        InvitedBy: props.usuarioStorage.uid,
        email: email,
        createdAt: Date.now(),
        createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
        companyId: companyId,
        company_roll: "company_ops",
        uid: "",
        acepteDate: "",
        acepteAdd: "",
        wasAcceptedAgreement: true,
        isblockedAgreement: false,
        requestIsActive: true,
        isActive: false,
        isDeleted: false,
        isInvited: true,
        username: userInvited.username,
        userLastName: userInvited.userLastName,
        companyName: userInvited.companyName,
        phone: userInvited.phone,
      };

      await docRefCompany
        .doc(companyCollaborator.email)
        .set(companyCollaborator);
      clearForm();
      refreshData();
    }
  };

  const acceptRequest = async (request) => {
    try {
      const requestRef = doc(db, "xpress_userCompanies", request.id);
      await updateDoc(requestRef, {
        status: "Aceptado",
        requestIsActive: false,
      });

      // Buscar el usuario en la colección de usuarios
      let userDocData = null;
      if (request.uid) {
        const userDocRef = doc(
          db,
          process.env.REACT_APP_COL_USERS,
          request.userId
        );
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          userDocData = userDocSnapshot.data();
        }
      }

      // Si no existe el uid o no se encontró el usuario, usar los datos de la solicitud
      const collaboratorData = {
        InvitedBy: request.uid || props.usuarioStorage.uid,
        email: request.email,
        createdAt: userDocData?.createdAt || Date.now(),
        createdateAdd:
          userDocData?.createdateAdd ||
          moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
        companyId: request.companyId,
        company_roll: request.role || "company_ops",
        uid: request.userId || userDocData?.uid || "",
        acepteDate: userDocData?.acepteDate || "",
        acepteAdd: userDocData?.acepteAdd || "",
        wasAcceptedAgreement: true,
        isblockedAgreement: false,
        isActive: true, // Activar al nuevo colaborador
        isDeleted: false,
        isInvited: true,
        username: userDocData?.username || request.username || "",
        userLastName: userDocData?.userLastName || request.userLastName || "",
        companyName: userDocData?.companyName || request.companyName || "",
        phone: userDocData?.phone || request.phone || "",
      };

      const collaboratorRef = doc(
        collection(
          db,
          `${process.env.REACT_APP_COL_COMPANIES}/${request.companyRut}/companyCollaborator`
        ),
        request.email
      );

      await setDoc(collaboratorRef, collaboratorData, { merge: true });

      setRequestDocs((prev) =>
        prev.map((doc) =>
          doc.id === request.id ? { ...doc, status: "Aceptado" } : doc
        )
      );
      refreshData();
    } catch (error) {
      console.error("Error al aceptar la solicitud: ", error);
    }
  };

  const clearForm = () => {
    setUserInvited({
      username: "",
      userLastName: "",
      companyName: "",
      phone: "",
      companyId: "",
      email: "",
    });
    setEmail("");
    setShowEdit(false);
  };

  const toggleShowEditNew = () => {
    setShowEdit(!showEdit);
  };

  // En tu JSX, añade el componente `ReusableVewData`

  const handlechage = (e) => {
    const validateEmail = (email) => {
      return email.match(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      );
    };

    const validate = (email) => {
      if (validateEmail(email)) {
        setError(null);
        return true;
      } else {
        setError("Ingrese un email valido");
        return false;
      }
    };

    let result = validate(e.target.value);
    if (e.target.value === "") {
      setError(null);
    }

    setValid(result);
    setEmail(e.target.value.trim());
    setUserInvited({ ...userInvited, email: e.target.value.trim() });
  };

  const handleChangeFields = (e) => {
    if (e.target.name === "username" || e.target.name === "userLastName") {
      const conditions = [
        {
          badPattern: /[^a-zA-Z0-9\s]+$/,
          errorMessage: "Solo letras y espacios",
        },
        {
          badPattern: /^(\w{0,5}|\w{13,})$/,
          errorMessage: "La longitud no cumple con el minimo",
        },
      ];

      const badPattern = conditions.find((condition) =>
        condition.badPattern.test(e.target.value)
      );

      if (badPattern) {
        setError(badPattern.errorMessage);
      } else {
        setError(null);
      }
    }

    if (e.target.name === "phone") {
      let codigo = "+56";
      if (!e.target.value.startsWith(codigo)) {
        setError("Ingrese el codigo del pais " + codigo);
      } else {
        setError(null);
      }
    }

    if (e.target.value === "") {
      setError(null);
    }

    setUserInvited({ ...userInvited, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Grid container spacing={2} id="expresscuentaContent">
        <Grid item xs={12}>
          <Typography variant="h4">Invitar equipo</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              subheader="Si el colaborador no está registrado, le enviaremos una invitación para activar su cuenta. Es necesario completar los datos del operador para su activación."
              title="Invitar nuevos usuarios"
            />
            <Divider />
            <Box sx={{ p: 2 }}>
              {showEdit === true ? (
                <Button
                  startIcon={<HighlightOff />}
                  variant="contained"
                  color="secondary"
                  onClick={toggleShowEditNew}
                >
                  Cancelar
                </Button>
              ) : (
                <Button
                  startIcon={<AddCircle />}
                  variant="contained"
                  color="primary"
                  onClick={toggleShowEditNew}
                >
                  Agregar usuarios
                </Button>
              )}

              <FadeInOut show={showEdit} duration={500} parentName="fade23">
                <ContainerViewComponent id="micuentafromIni">
                  {error && (
                    <Box sx={{ mb: 2 }}>
                      <Typography color="error">{error}</Typography>
                    </Box>
                  )}
                  <Divider sx={{ my: 2 }} />
                  {userInvited && (
                    <Box component="form" onSubmit={invitedAdmin}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            type="email"
                            placeholder="Email"
                            onChange={handlechage}
                            value={email}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Email />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            placeholder="Teléfono"
                            name="phone"
                            onChange={handleChangeFields}
                            value={userInvited.phone}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Phone />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            placeholder="Nombre"
                            name="username"
                            onChange={handleChangeFields}
                            value={userInvited.username}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            placeholder="Apellido"
                            name="userLastName"
                            onChange={handleChangeFields}
                            value={userInvited.userLastName}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            placeholder="Nombre de Empresa"
                            name="companyName"
                            disabled
                            onChange={handleChangeFields}
                            value={userInvited.companyName}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Business />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Enviar
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </ContainerViewComponent>
              </FadeInOut>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Resumen" />
            <Divider />
            <DashIconsResumenUser items={listItems} cant={cantListItems} />
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          {requestDocs?.length > 0 ? (
            <Box sx={{ m: 1 }}>
              <Typography variant="h6" color="#f8f8f8">
                Solicitudes Recibidas
              </Typography>
              <List>
                {requestDocs.map((request, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      backgroundColor: "#f8f8f8",
                      p: 1,
                      borderRadius: "8px",
                      m: 1,
                    }}
                  >
                    <ListItemText primary={request.email} sx={{ m: 1 }} />
                    <Chip
                      label={request.status}
                      color={
                        request.status === "Pendiente"
                          ? "warning"
                          : request.status === "Aceptado"
                          ? "success"
                          : "error"
                      }
                    />
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => acceptRequest(request)}
                      sx={{ ml: 1 }}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => rejectRequest(request)}
                      sx={{ ml: 1 }}
                    >
                      Rechazar
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              subheader="Usuarios Actuales"
              title="Usuarios Actuales"
            />
            <Divider />
            <Box
              sx={{
                height: "350px",
                width: "100%",
                backgroundColor: "#fafafa",
                p: 2,
              }}
            >
              {usersRows ? (
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "bicciNumber", sort: "desc" }],
                    },
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  rows={usersRows}
                  columns={columnsUsers}
                  pageSize={5}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>

      <ReusableVewData
        open={objUser.open}
        handleClose={objUser.handleClose}
        formFields={objUser.formFields}
        initialValues={objUser.initialValues}
      />
    </>
  );
};

export default AddUser;
