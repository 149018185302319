import React from "react";

import { db } from "../../../firebase";
import { Box, Button, Card, CardHeader, Divider, Grid2 } from "@mui/material";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

var estados;
var metaPalabras = [];
var metaCategorias = [];
var metaDesWeb = [];
class PlanesEbiex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      createBy: this.props.userUid,
      createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      isActive: false,
      isPost: false,
      isPublic: false,
      articleType: "packServicExpress", // tipo de articulo pack de Servico Express  ARTYPE
      planName: "",
      description: "",
      rateEbiex: "", // Tarifario Asociado
      rateEbiexBase: "", // Tarifario Asociado
      ChargeShipments: "", // Cantidad envios de plan
      salePrice: "", // precio de venta
      discountPrice: "", // precio Oferta
      discountAmount: "", // monto de descuento
      totalAmount: "", // total a pagar
      ChargeAmount: "", // Monto del plan Mensual
      stock_quantity: "", //timeOfBilling: {},
      paymentmethods: {},
      paymentoptions: {},
      billingDay: "", //fecha de facturacion
      activeBillingDay: true,
      bilingPeriod: "", // periodo de corte en dias
      activeBilingPeriod: false,
      slug: "", //
      type: "", // Custom - Basico - Pymes - Empresas
      metaWords: [],
      currentMetaWords: "",
      categories: [],
      currentCategories: "",
      descriptorsWeb: [],
      currentDescriptorsWeb: "",
      imagenes: {},
      position: 0,
      isDelete: false,
      availableservices: this.props.dataRates,
      dataComplete: null,
    };
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.changepaymentOption = this.changepaymentOption.bind(this);
    this.changepaymentMethods = this.changepaymentMethods.bind(this);
    this.handleSelectCurrentTask = this.handleSelectCurrentTask.bind(this);
    this.handleSelectBasicTask = this.handleSelectBasicTask.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleAddItemCat = this.handleAddItemCat.bind(this);
    this.handleAddItemDes = this.handleAddItemDes.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.validationState = this.validationState.bind(this);
    this.eliminarCategorias = this.eliminarCategorias.bind(this);
    this.eliminarMetadescriptores = this.eliminarMetadescriptores.bind(this);
    this.eliminarMetaWords = this.eliminarMetaWords.bind(this);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangeisPublic = this.handleChangeisPublic.bind(this);
    this.handleChangeisPost = this.handleChangeisPost.bind(this);
    this.handleAccion = this.handleAccion.bind(this);
    this.handleCall = this.handleCall.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChangeItemServ = this.handleChangeItemServ.bind(this);
  }

  // const crearServico=()=>{}
  // const limpiarFormularioa=()=>{}
  render() {
    return (
      <ContainerViewComponent className="dashcontent">
        <Grid2 celled="internally" id="servicioscontent">
          <div>
            <h2> Planes mensuales</h2>
          </div>
          <Grid2>
            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Crear nuevo Plan , este agrupa caracteristicas de Servicios, tipos de paquetes y tarifarios"
                title={
                  <>
                    <h3>Agregar nuevo plan</h3>
                  </>
                }
              />
              <Divider />
              {this.state.dataComplete ? (
                <div className="alert alert-danger">
                  {this.state.dataComplete}
                </div>
              ) : (
                <></>
              )}

              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                {/* <Form onSubmit={this.handleSubmit}>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      name="planName"
                      label="Nombre"
                      onChange={this.handleChange}
                      value={this.state.planName}
                    />
                    <Form.Field
                      control={Input}
                      label="description"
                      type="text"
                      name="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <select
                      id="custom-selectFrom"
                      label="Asocia el nuevo plan a un tarifario"
                      name={"rateEbiex"}
                      control="select"
                      onChange={(e) =>
                        this.handleSelectCurrentTask(e.target.value)
                      }
                    >
                      <option> Seleccione un tarifario. </option>
                      {this.props.tarifariosData.map((item) => (
                        <option key={item.id} value={item.rateId}>
                          {item.rateName}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <select
                      id="custom-selectFrom"
                      label="Asocia el nuevo plan a un tarifario"
                      name={"rateEbiexBase"}
                      control="select"
                      onChange={(e) =>
                        this.handleSelectBasicTask(e.target.value)
                      }
                    >
                      <option> Seleccione un tarifario Base. </option>
                      {this.props.tarifariosData.map((item) => (
                        <option key={item.id} value={item.rateId}>
                          {" "}
                          {item.rateName}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      type="number"
                      name="ChargeShipments"
                      label="Cantidad de envios"
                      onChange={this.handleChange}
                      value={this.state.ChargeShipments}
                    />
                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      label="Monto mensual"
                      type="number"
                      name="ChargeAmount"
                      onChange={this.handleChange}
                      value={this.state.ChargeAmount}
                    />

                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      type="number"
                      name="salePrice"
                      label="Precio de venta"
                      onChange={this.handleChange}
                      value={this.state.salePrice}
                    />
                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      label="Monto de descuento"
                      type="number"
                      name="discountAmount"
                      onChange={this.handleChange}
                      value={this.state.discountAmount}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      label="Precio en descuento"
                      type="number"
                      name="discountPrice"
                      onChange={this.handleChange}
                      value={this.state.discountPrice}
                    />

                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      label="Total precio de venta"
                      type="number"
                      name="totalAmount"
                      onChange={this.handleChange}
                      value={this.state.totalAmount}
                    />
                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      type="number"
                      label="Cantidad stock"
                      name="stock_quantity"
                      onChange={this.handleChange}
                      value={this.state.stock_quantity}
                    />
                    <Button
                      sx={{ margin: "2px" }}
                      color="primary"
                      variant="contained"
                      onClick={this.handleAccion}
                    >
                      Calcular
                    </Button>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Metodos de pago</th>
                          <th scope="col">Estatus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.paymentMethods.map((element) => (
                          <tr key={element.id}>
                            <td>{element.name}</td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  defaultChecked={element.isActive}
                                  id={element.id}
                                  name={element.name}
                                  onChange={this.changepaymentMethods}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Opciones de pago</th>
                          <th scope="col">Estatus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.paymentOptions.map((element) => (
                          <tr key={element.id}>
                            <td>{element.name}</td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  defaultChecked={element.isActive}
                                  id={element.id}
                                  name={element.name}
                                  onChange={this.changepaymentOption}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Form.Group>
                  <Form.Group widths="equal" className="centervertical">
                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      type="text"
                      placeholder="Día de facturación"
                      name="billingDay"
                      onChange={this.handleChange}
                      value={this.state.billingDay}
                    />

                    <label className="switch">
                      <input
                        type="Radio"
                        id="activeBilling"
                        name="activeBilling"
                        onClick={this.handleChangeRadio}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span> Día de facturación</span>
                  </Form.Group>
                  <Form.Group widths="equal" className="centervertical">
                    <Form.Field
                      className="formGroup4"
                      control={Input}
                      placeholder="Periodo de facturación"
                      type="text"
                      name="bilingPeriod"
                      onChange={this.handleChange}
                      value={this.state.bilingPeriod}
                    />
                    <label className="switch">
                      <input
                        type="Radio"
                        id="bilingPeriod"
                        name="activeBilling"
                        onClick={this.handleChangeRadio}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span> Perido de facturación en dias</span>
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field
                      className="formGroup2"
                      control={Input}
                      label="Slug"
                      type="text"
                      name="slug"
                      onChange={this.handleChange}
                      value={this.state.slug}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Field>
                      <label htmlFor="type" className="formGroup2">
                        Tipo de plan:
                        <select
                          name="type"
                          id="type"
                          onChange={this.handleChange}
                        >
                          <option value="basico">Basico</option>
                          <option value="pymes">Pymes</option>
                          <option value="empresas">Empresas</option>
                          <option value="custom">Personalizado</option>
                          <option value="web">Web</option>
                        </select>
                      </label>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      className="formGroup4"
                      label="currentMetaWords"
                      name="currentMetaWords"
                      value={this.state.currentMetaWords}
                      onChange={this.handleChange}
                    />
                    <Button
                      sx={{ margin: "1px" }}
                      color="primary"
                      id="metaWords"
                      onClick={this.handleAddItem}
                    >
                      Agregar
                    </Button>
                    {this.state.metaWords.length > 0 ? (
                      <button
                        className="btn btn-sm btn-danger float-right mx-2"
                        onClick={this.eliminarMetaWords}
                      >
                        Limpiar
                      </button>
                    ) : (
                      ""
                    )}
                    <span>
                      {this.state.metaWords.length > 0
                        ? this.state.metaWords.map((item) => (
                            <span key={Math.random().toString()}> {item} </span>
                          ))
                        : ""}
                    </span>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      className="formGroup4"
                      label="currentCategories"
                      name="currentCategories"
                      value={this.state.currentCategories}
                      onChange={this.handleChange}
                    />
                    <Button
                      sx={{ margin: "1px" }}
                      color="primary"
                      id="categories"
                      onClick={this.handleAddItemCat}
                    >
                      Agregar
                    </Button>
                    {this.state.categories.length > 0 ? (
                      <button
                        className="btn btn-sm btn-danger float-right mx-2"
                        onClick={this.eliminarCategorias}
                      >
                        Limpiar
                      </button>
                    ) : (
                      ""
                    )}
                    <span>
                      {this.state.categories.length > 0
                        ? this.state.categories.map((item) => (
                            <span key={Math.random().toString()}> {item} </span>
                          ))
                        : ""}
                    </span>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="text"
                      className="formGroup4"
                      label="currentDescriptorsWeb"
                      name="currentDescriptorsWeb"
                      value={this.state.currentDescriptorsWeb}
                      onChange={this.handleChange}
                    />
                    <Button
                      sx={{ margin: "1px" }}
                      color="primary"
                      onClick={this.handleAddItemDes}
                      id="descriptorsWeb"
                    >
                      Agregar
                    </Button>
                    {this.state.descriptorsWeb.length > 0 ? (
                      <button
                        className="btn btn-sm btn-danger float-right mx-2"
                        onClick={this.eliminarMetadescriptores}
                      >
                        Limpiar
                      </button>
                    ) : (
                      ""
                    )}
                    <span>
                      {this.state.descriptorsWeb.length > 0
                        ? this.state.descriptorsWeb.map((item) => (
                            <span key={Math.random().toString()}> {item} </span>
                          ))
                        : ""}
                    </span>
                  </Form.Group>
                  <Card className="typeMiniforms">
                    <CardHeader
                      subheader="Seleccione los servcios para este plan"
                      title={
                        <>
                          <h3>Servicios de para este Plan</h3>
                        </>
                      }
                    />
                    <Divider />
                    <ContainerViewComponent
                      className="formcontent"
                      id="micuentafromIni"
                    >
                      <div>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Nombre</th>
                              <th scope="col">Código</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.availableservices.map((element) => (
                              <tr key={element.id}>
                                <td>{element.name}</td>
                                <td>{element.id}</td>
                                <td>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      defaultChecked={element.isActive}
                                      id={element.id}
                                      name={element.name}
                                      onChange={this.handleChangeItemServ}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </ContainerViewComponent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                      }}
                    ></Box>
                  </Card>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      sx={{ margin: "2px" }}
                      type="sutmit"
                      color="primary"
                      variant="contained"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Form> */}
              </ContainerViewComponent>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>

            <Card className="typeMiniformsserv">
              <CardHeader
                subheader="Edita un plan"
                title={
                  <>
                    <h3>Activa o desactiva Planes</h3>
                  </>
                }
              />
              <Divider />

              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                {/* <Form>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Nombre de Plan</th>
                        <th scope="col">Status</th>
                        <th scope="col">Post Panel</th>
                        <th scope="col">Post Web</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.plansdata.map((element) => (
                        <tr key={Math.random().toString()}>
                          <td>
                            {(estados = element.isActive)}
                            {element.isActive ? (
                              <CheckCircleIcon color="success"></CheckCircleIcon>
                            ) : (
                              <IndeterminateCheckBoxIcon color="disabled"></IndeterminateCheckBoxIcon>
                            )}
                          </td>
                          <td>{element.planName}</td>

                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isActive}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeItem}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isPost}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeisPost}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                defaultChecked={element.isPublic}
                                id={element.id}
                                name={element.name}
                                onChange={this.handleChangeisPublic}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>Editar</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  ></Box>
                </Form> */}
              </ContainerViewComponent>

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              ></Box>
            </Card>
          </Grid2>
        </Grid2>
      </ContainerViewComponent>
    );
  }

  async handleSubmit(e) {
    e.preventDefault();

    // console.log("un clik");
    // console.log(this.state);
    await this.validationState();
    if (this.state.dataComplete === true) {
      // console.log('update TRUE')
      try {
        const accountants = await db
          .collection(`${process.env.REACT_APP_COL_ACCOUNTANS}`)
          .doc(`availableplans`)
          .get();
        const documenCont = accountants.data().count + 1;
        const planId = "PLAN" + documenCont;
        this.setState({ id: planId });
        await db
          .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
          .doc(planId)
          .set(this.state);
        await db
          .collection(`${process.env.REACT_APP_COL_ACCOUNTANS}`)
          .doc(`availableplans`)
          .set({ count: documenCont });
        this.handleClear();
      } catch (error) {
        // console.log(error);
      }
    } else {
      // console.log('update FALSE')
    }
  }

  validationState() {
    if (!this.state.planName.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el nombre del plan" });
      return;
    }
    if (!this.state.description.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique la descripción del plan" });
      return;
    }
    if (!this.state.rateEbiex.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el tarifario asociado del plan" });
      return;
    }
    if (!this.state.rateEbiexBase.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el tarifario base del plan" });
      return;
    }
    if (!this.state.ChargeShipments > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique catidad de envíos del plan" });
      return;
    }
    if (!this.state.salePrice > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Revise el calculo salePrice" });
      return;
    }
    if (!this.state.discountPrice > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Revise el calculo discountPrice" });
      return;
    }
    if (!this.state.totalAmount > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Revise el calculo totalAmount" });
      return;
    }

    if (!this.state.stock_quantity > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el stock del plan" });
      return;
    }
    if (!this.state.paymentmethods.length > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el medios de pago del plan" });
      return;
    }
    if (!this.state.paymentoptions.length > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el opciones de pago del plan" });
      return;
    }
    if (!this.state.billingDay.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique un dia de facturacion plan" });
      return;
    }
    if (!this.state.bilingPeriod.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el periodo del plan" });
      return;
    }

    if (!this.state.slug.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el link web del plan" });
      return;
    }
    if (!this.state.type.trim()) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique el tipo del plan" });
      return;
    }
    if (!this.state.metaWords.length > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique palablas clave del plan" });
      return;
    }
    if (!this.state.categories.length > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique las categorias del plan" });
      return;
    }

    if (!this.state.descriptorsWeb.length > 0) {
      // console.log('Error')
      this.setState({ dataComplete: "Indique descriptores web" });
      return;
    }

    this.setState({ dataComplete: true });
    // console.log('No errors')
  }

  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name === "type") {
      this.setState({ type: value });
    }
    if (name === "billingDay") {
      if (value <= 0 || value >= 5) {
        this.setState({ [name]: "" });
        return;
      }
    }
    if (name === "bilingPeriod") {
      if (value <= 0 || value >= 16) {
        this.setState({ [name]: "" });
        return;
      }
    }
    if (name === "ChargeShipments") {
      this.setState({ ChargeShipments: this.state.ChargeShipments });
    }

    this.setState({ [name]: value });
  }
  handleAccion(e) {
    e.preventDefault();
    this.handleCall(e);
  }

  handleCall(e) {
    var preciovalue = this.state.salePrice;
    var descuento = this.state.discountAmount;
    var subtotal = preciovalue - descuento;

    this.setState({ discountPrice: subtotal });
    this.setState({ totalAmount: subtotal });
    // console.log(subtotal)
  }

  handleChangeValue(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name === "type") {
      this.setState({ type: value });
    }
  }

  handleAddItem(e) {
    e.preventDefault();

    const target = e.target;
    var palabraAgr = target.id;
    var Words = this.state.currentMetaWords;
    metaPalabras.push(Words);
    if (palabraAgr === "metaWords") {
      this.setState({ metaWords: metaPalabras });
      this.setState({ currentMetaWords: "" });
      palabraAgr = "";
    }
    // console.log(this.state);
  }

  handleAddItemCat(e) {
    e.preventDefault();

    const target = e.target;
    var palabraAgr = target.id;
    var categorias = this.state.currentCategories;
    metaCategorias.push(categorias);
    if (palabraAgr === "categories") {
      this.setState({ categories: metaCategorias });
      this.setState({ currentCategories: "" });
      palabraAgr = "";
    }
    // console.log(this.state);
  }

  handleAddItemDes(e) {
    e.preventDefault();
    // console.log("Agregar palabra");
    const target = e.target;
    var palabraAgr = target.id;
    var desWeb = this.state.currentDescriptorsWeb;
    metaDesWeb.push(desWeb);
    if (palabraAgr === "descriptorsWeb") {
      this.setState({ descriptorsWeb: metaDesWeb });
      this.setState({ currentDescriptorsWeb: "" });
      palabraAgr = "";
    }
    // console.log(this.state);
  }

  eliminarMetaWords(e) {
    this.setState({ metaWords: [] });
    metaPalabras = [];
  }

  eliminarCategorias(e) {
    this.setState({ categories: [] });
    metaCategorias = [];
  }

  eliminarMetadescriptores(e) {
    this.setState({ descriptorsWeb: [] });
    metaDesWeb = [];
  }

  handleSelectCurrentTask(e) {
    this.setState({ rateEbiex: e });
  }

  handleSelectBasicTask(e) {
    this.setState({ rateEbiexBase: e });
  }

  handleClear(e) {
    var inicial = {
      id: "",
      createBy: this.props.userUid,
      createDate: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      isActive: false,
      isPost: false,
      isPublic: false,
      articleType: "packServicExpress", // tipo de articulo pack de Servico Express  ARTYPE
      planName: "",
      description: "",
      rateEbiex: "", // Tarifario Asociado
      rateEbiexBase: "", // Tarifario Asociado
      ChargeShipments: "", // Cantidad envios de plan
      salePrice: "", // precio de venta
      discountPrice: "", // precio Oferta
      discountAmount: "", // monto de descuento
      totalAmount: "", // total a pagar
      ChargeAmount: "", // Monto del plan Mensual
      stock_quantity: "", //timeOfBilling: {},
      paymentmethods: {},
      paymentoptions: {},
      billingDay: "", //fecha de facturacion
      activeBillingDay: true,
      bilingPeriod: "", // periodo de corte en dias
      activeBilingPeriod: false,
      slug: "", //
      type: "", // Custom - Basico - Pymes - Empresas
      metaWords: [],
      currentMetaWords: "",
      categories: [],
      currentCategories: "",
      descriptorsWeb: [],
      currentDescriptorsWeb: "",
      imagenes: {},
      position: 0,
      isDelete: false,
      availableservices: this.props.dataRates,
      dataComplete: null,
    };
    this.setState(inicial);
  }

  changepaymentMethods(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    var typeOfBilling = this.props.paymentMethods;
    // console.log(typeOfBilling);
    var elementType = [];
    for (let i = 0; i < typeOfBilling.length; i++) {
      if (typeOfBilling[i].id === id) {
        elementType.push((typeOfBilling[i].isActive = checked));
      }
    }
    this.setState({ paymentmethods: typeOfBilling });
    // console.log(this.state);
  }

  changepaymentOption(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    var optionOfBilling = this.props.paymentOptions;
    var elementOpt = [];
    for (let i = 0; i < optionOfBilling.length; i++) {
      if (optionOfBilling[i].id === id) {
        elementOpt.push((optionOfBilling[i].isActive = checked));
      }
    }
    this.setState({ paymentoptions: optionOfBilling });
    // console.log(this.state);
  }

  handleChangeRadio(e) {
    const target = e.target;
    const id = target.id;
    if (id === "activeBilling") {
      this.setState({ activeBillingDay: true });
      this.setState({ activeBilingPeriod: false });
    }
    if (id === "bilingPeriod") {
      this.setState({ activeBillingDay: false });
      this.setState({ activeBilingPeriod: true });
    }
    // console.log(this.state);
  }

  handleChangeItemServ(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    var model = this.props.dataRates;
    for (let i = 0; i < model.length; i++) {
      if (id === model[i].id) {
        // console.log('Update '+ id)
        model[i].isActive = checked;
      }
    }
    this.setState({ availableservices: model });
  }

  async handleChangeItem(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    // console.log(checked);
    // console.log(name);
    // console.log(value);
    // console.log(id);
    // console.log(e);
    await db
      .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
      .doc(id)
      .update({ isActive: checked });
  }

  async handleChangeisPublic(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    // console.log(checked);
    // console.log(name);
    // console.log(value);
    // console.log(id);
    // console.log(e);
    await db
      .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
      .doc(id)
      .update({ isPublic: checked });
  }

  async handleChangeisPost(e) {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    // console.log(checked);
    // console.log(name);
    // console.log(value);
    // console.log(id);
    // console.log(e);
    await db
      .collection(`${process.env.REACT_APP_COL_AVIABLEPLANS}`)
      .doc(id)
      .update({ isPost: checked });
  }
}

export default PlanesEbiex;
