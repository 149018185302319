import React from "react";

import FrmSoporte from "../../../components/formularios/FrmSoporte";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";
const ReportBug = () => {
  return (
    <div>
      <ContainerViewComponent className="dashcontent">
        <div>
          <FrmSoporte></FrmSoporte>
        </div>
      </ContainerViewComponent>
    </div>
  );
};

export default ReportBug;
