import { useEffect } from "react";
import {
  getFirestore,
  collectionGroup,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  Timestamp,
} from "firebase/firestore";

const FirestoreIndexTester = () => {
  useEffect(() => {
    const fetchData = async () => {
      const updateTodayRoutes = async (testOrderId) => {
        const db = getFirestore();
        const todayString = new Date()
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, ""); // "20250217"

        try {
          // 🔍 Paso 1: Buscar TODOS los `waypoints` donde `ids` contenga `testOrderId`
          const existingRouteQuery = query(
            collection(db, "xpress_deliveryPlans"),
            where("companyID", "==", "falabella"),
            where("driverID", "==", "YNxA4TjaZ8csDbbJX6LKWfDIkre2"),
            where("isActive", "==", true)
          );

          const snapshot = await getDocs(existingRouteQuery);

          console.log(
            "📌 Número de rutas activas encontradas:",
            snapshot.docs.length
          );

          if (snapshot.empty) {
            console.warn(
              "⚠️ No se encontraron rutas activas para este conductor."
            );
          }

          // 🔍 Paso 2: Contar rutas por fecha
          const dateStamp = new Date()
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, "");

          const routeCountQuery = query(
            collection(db, "xpress_deliveryPlans"),
            where("companyID", "==", "falabella"),
            where("dayStart", "==", dateStamp)
          );

          const snapshot2 = await getDocs(routeCountQuery);

          console.log(
            "📌 Total de rutas para la fecha",
            dateStamp,
            ":",
            snapshot2.docs.length
          );

          if (snapshot2.empty) {
            console.warn(
              "⚠️ No hay rutas registradas para la fecha:",
              dateStamp
            );
          }
        } catch (error) {
          console.error("❌ Error en la consulta Firestore:", error);
        }
      };

      // Ejecutar la función con una orden de prueba
      updateTodayRoutes("99M072330307CL");

      // try {
      //   const db = getFirestore(); // Instancia de Firestore

      //   // 🔍 Segunda consulta: Buscar `ids` con un valor exacto
      //   const waypointsQuerydirect = query(
      //     collectionGroup(db, "waypoints"),
      //     where("id", "==", "Ruta_5_index_0") // Usa un ID de prueba conocido
      //   );

      //   const snapshotDirect = await getDocs(waypointsQuerydirect);
      //   console.log(
      //     `✅ Consulta 2 ejecutada. Se encontraron ${snapshotDirect.docs.length} documentos.`
      //   );
      //   snapshotDirect.docs.forEach((doc) => {
      //     console.log(
      //       `📌 Documento encontrado en consulta directa:`,
      //       doc.id,
      //       doc.data()
      //     );
      //   });
      // } catch (error) {
      //   console.error("❌ Error en la consulta 2 Firestore:", error);
      // }
    };

    fetchData();
  }, []);

  return <div>Ejecutando consulta Firestore...</div>;
};

export default FirestoreIndexTester;
