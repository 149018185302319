import { useEffect, useState } from "react";
const useCreateSetResumenTable = (
  dataOrdes,
  selectedCompanies,
  filteredCompanies
) => {
  const [dataResumentables, setcantListItems] = useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const [nonDeliveryArrayState, setNonDeliveryArrayState] = useState();

  const falabellaExcepxions = [
    { intState: "1", name: "SIN MORADORES" },
    { intState: "1002", name: "DOMICILIO NO CORRESPONDE" },
    { intState: "1005", name: "PROD NO ES LO COMPRADO" },
    { intState: "1006", name: "PROD INCOMPLETO" },
    { intState: "1007", name: "PROD FALTANTE" },
    { intState: "1008", name: "PROD DAÑADO" },
    { intState: "1009", name: "FUERA DE HORARIO" },
    { intState: "1017", name: "NO RETIRA NO CUMPL FORMAT" },
    { intState: "1019", name: "FUERA DE COBERTURA" },
    { intState: "1022", name: "CLIENTE REALIZARA N/C" },
    { intState: "1023", name: "PRODUCTO ROBADO" },
    { intState: "1027", name: "NO RETIRADO" },
    { intState: "1029", name: "DEVOLUCION" },
    { intState: "1030", name: "INCIDENCIA EN LA CALLE" },
    { intState: "1031", name: "PROD N ENTREGADO X TIEMPO" },
    { intState: "1032", name: "FALTA DE INFORMACIÓN" },
    { intState: "1034", name: "PROD YA FUE RECIBIDO" },
    { intState: "1036", name: "DIFICULTAD ACCESO DESTINO" },
    { intState: "1038", name: "CLIENTE CAMBIA DIRECCIÓN" },
    { intState: "1041", name: "CLIENTE RECHAZA ENVÍO" },
    { intState: "1018", name: "NO CONOCEN AL CLIENTE" },
  ];

  useEffect(() => {
    if (!dataOrdes || dataOrdes.length === 0) {
      setcantListItems({
        Creadas: 0,
        Ruta: 0,
        Incidencias: 0,
        Finalizadas: 0,
        Total: 0,
      });
      return;
    }

    setResumenTable(dataOrdes);
  }, [dataOrdes]);

  useEffect(() => {
    if (selectedCompanies && filteredCompanies) {
      let allNonDeliveryCodes = new Set(
        falabellaExcepxions.map((item) => String(item.intState)) // 🔹 Convertir a `String`
      );

      if (selectedCompanies?.length > 0) {
        // 🔹 Extraer `nonDelivery` solo de empresas seleccionadas
        selectedCompanies.forEach((companyID) => {
          const company = filteredCompanies.find(
            (comp) => comp.id === companyID
          );
          if (company?.nonDelivery) {
            company.nonDelivery.forEach((code) =>
              allNonDeliveryCodes.add(String(code))
            );
          }
        });
      } else {
        // 🔹 Usar `nonDelivery` de todas las empresas
        filteredCompanies.forEach((company) => {
          if (company?.nonDelivery) {
            company.nonDelivery.forEach((code) =>
              allNonDeliveryCodes.add(String(code))
            );
          }
        });
      }

      const nonDeliveryArray = Array.from(allNonDeliveryCodes); // Convertir Set a Array

      setNonDeliveryArrayState(nonDeliveryArray);
    }
  }, [selectedCompanies, filteredCompanies]);

  const setResumenTable = (dataOrdes) => {
    if (!dataOrdes || dataOrdes.length === 0) return; // Evitar errores al acceder a `length`

    let incidencias = dataOrdes.filter((item) =>
      nonDeliveryArrayState
        ? nonDeliveryArrayState.includes(String(item.intState)) ||
          item.lostDelivery === true
        : []
    );

    const resumen = {
      Creadas: dataOrdes.filter(
        (item) => item.status === 0 || item.status === 1
      ).length,
      Ruta: dataOrdes.filter((item) => item.status >= 3 && item.status < 8)
        .length,
      Incidencias: incidencias.length,
      Finalizadas: dataOrdes.filter((item) => item.status === 8).length,
      Total: dataOrdes.length,
    };

    setcantListItems(resumen);
  };

  return { dataResumentables }; // Ahora devolvemos un objeto con la clave correcta
};

export default useCreateSetResumenTable;
