import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Badge,
  Button,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AnalyticsSummary from "./AnalyticsSummary";

const RouteNotifications = ({ routes, ridersNames }) => {
  // Estado para controlar la visibilidad del panel de notificaciones

  const [openDrawer, setOpenDrawer] = useState(false);
  const [page, setPage] = useState(0);
  const nuid = "Ybm}1+4@06gw";

  const totalRoutes = routes.length;
  const progress = routes.filter((route) => route.isComplete).length;
  const activeRoutes = routes.filter((route) => route.isActive).length;
  const closedRoutes = routes.filter((route) => route.isClosed).length;
  const totalDrivers = new Set(
    routes.flatMap((route) => Object.keys(route.analytics.team || {}))
  ).size;

  // Tamaño de la paginación
  const pageSize = 30;

  // Obtener todas las observaciones y ordenarlas de más recientes a más antiguas
  const allObservations = routes
    .flatMap(
      (route) =>
        route.observations?.map((obs) => ({
          id: obs.id,
          message: obs.comment,
          createdAt: obs.createdAt,
          createdBy: obs.createdBy || "System",
          driverID: obs.driverID || null,
        })) || []
    )
    .sort((a, b) => (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0));

  // Obtener el total de observaciones
  const totalObservations = allObservations.length;

  // Función para abrir/cerrar el panel de notificaciones
  const toggleDrawer = (open) => () => {
    setOpenDrawer(open);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <AnalyticsSummary
        progress={progress}
        activeRoutes={activeRoutes}
        closedRoutes={closedRoutes}
        totalRoutes={totalRoutes}
        totalDrivers={totalDrivers}
        totalObservations={totalObservations}
        handleNotificationsClick={toggleDrawer(true)}
        routes={routes}
        nuid={nuid}
        ridersNames={ridersNames}
      ></AnalyticsSummary>
    </Box>
  );
};

export default RouteNotifications;
