import React, { useState } from "react";
import {
  Card,
  Divider,
  CardHeader,
  Tooltip,
  IconButton,
  Box,
  TextField,
  Grid2,
} from "@mui/material";
import FadeInOut from "../../../components/animations/FadeInOut";
import { useGetOrders } from "../../../hooks/migration/useGetOrders";
import SplitPane from "react-split-pane";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const Dispatchs = () => {
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);

  const [searchParams, setSearchParams] = useState({
    companyIDs: ["FroY2JbeirD7B1MRYEXo"], // Incluye `null` para buscar sin `companyID`
    state: 8,
    dateField: "createdAt",
    startDate: new Date("2024-07-14"),
    endDate: new Date("2024-07-17"),
  });

  const { orders, ordersLoading, ordersError, ordersEmpty } =
    useGetOrders(searchParams);

  const handleLoadMore = () => {
    if (orders.length > 0) {
      setLastVisible(orders[orders.length - 1].id);
    }
  };

  const elementsMenu = (
    <>
      <Tooltip title="Ocultar menú">
        <IconButton sx={{ ml: 1, color: "#1e2158" }} onClick={toggleShowEdit}>
          <MenuOpenIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );

  const acctionsList = (
    <>
      <TextField type="checkbox" variant="outlined" size="small" />
    </>
  );

  const buscador = (
    <>
      <TextField
        fullWidth
        type="text"
        placeholder="Buscar..."
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </>
  );

  if (ordersLoading) return <p>Loading...</p>;
  if (ordersError) return <p>Error loading orders.</p>;
  if (ordersEmpty) return <p>No orders found.</p>;

  return (
    <Box sx={{ backgroundColor: "#8F8F8F", height: "100vh" }}>
      <Grid2 container sx={{ backgroundColor: "#F8F8F8", height: "90%" }}>
        <Grid2 xs={12}>
          <Card>
            <CardHeader subheader={buscador} action={elementsMenu} />
            <FadeInOut show={showEdit} duration={500}>
              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                <Divider />
              </ContainerViewComponent>
            </FadeInOut>
          </Card>
        </Grid2>

        <Grid2 xs={12} id="gridContainer">
          <Divider />
          <SplitPane split="vertical" minSize={200} defaultSize="50%">
            <div style={{ padding: "5px", height: "100%" }}>
              <Grid2 xs={12}>
                <Card>
                  <CardHeader
                    subheader={acctionsList}
                    title="Todos los envíos"
                  />
                  <FadeInOut show={showEdit} duration={500}>
                    <ContainerViewComponent
                      className="formcontent"
                      id="micuentafromIni"
                    >
                      <Divider />
                    </ContainerViewComponent>
                  </FadeInOut>
                </Card>
              </Grid2>
            </div>

            <div style={{ padding: "5px", height: "100%" }}>
              <Grid2 xs={12}>
                <Card>
                  <CardHeader
                    subheader={acctionsList}
                    title="Envíos por empresa"
                  />
                  <ContainerViewComponent
                    className="formcontent"
                    id="micuentafromIni"
                  >
                    <Divider />
                  </ContainerViewComponent>
                </Card>
              </Grid2>
            </div>
          </SplitPane>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Dispatchs;
