import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// 🏎️ Icono personalizado para el vehículo
const vehicleIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/3202/3202926.png", // Reemplázalo con tu icono
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});

const DriverTrackingMap = ({
  width = "100%",
  height = "500px",
  waypoints = [],
  vehicle = null,
  mapType = "google", // "google" o "grey"
  attribution = "BICCI Maps",
}) => {
  const [loading, setLoading] = useState(true);
  const [route, setRoute] = useState([]);
  const mapRef = useRef(null);

  // 🗺️ URLs de Mapas
  const googleMapsLayer = `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=TU_API_KEY`;
  const greyMapLayer =
    "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png";

  // 🔄 Función para cargar la ruta desde una API externa
  const fetchRouteData = async () => {
    setLoading(true);
    try {
      // Aquí debes hacer la consulta real a la API para obtener la ruta
      // Simulamos datos para ejemplo
      const response = await new Promise(
        (resolve) => setTimeout(() => resolve(waypoints), 2000) // Simula un delay de 2 segundos
      );
      setRoute(response);
    } catch (error) {
      console.error("Error cargando la ruta:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRouteData();
  }, [waypoints]); // 🔄 Se ejecuta cada vez que los waypoints cambian

  return (
    <div style={{ width, height, position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <span>Cargando ruta...</span>
        </div>
      )}

      <MapContainer
        center={waypoints.length ? waypoints[0] : [0, 0]}
        zoom={12}
        style={{ width: "100%", height: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          attribution={attribution}
          url={mapType === "google" ? googleMapsLayer : greyMapLayer}
        />

        {/* 🚗 Mostrar la ruta en el mapa */}
        {route.length > 1 && (
          <Polyline positions={route} color="blue" weight={4} />
        )}

        {/* 📍 Colocar los waypoints en el mapa */}
        {waypoints.map((point, index) => (
          <Marker key={index} position={point} />
        ))}

        {/* 🏎️ Mostrar el vehículo en la primera posición */}
        {vehicle && <Marker position={vehicle} icon={vehicleIcon} />}
      </MapContainer>
    </div>
  );
};

export default DriverTrackingMap;
