import React from "react";
import Bannerfooter from "../../../../../components/Bannerfooter/Bannerfooter";
import ReactPlayer from "react-player";
import useGlobalConfig from "../../../../../hooks/globalConfig";
import { Box, Grid2 } from "@mui/material";
const Pos4 = () => {
  const {
    ecotribicci,
    homeImgUrl,
    ecortribicci,
    ecortribicciR,
    boxbicciexpres,
    ecobicci2,
  } = useGlobalConfig();

  const footermensaje = {
    className: "resaltadoCurvo FloatRignt",
    title: "CIUDADES INTELIGENTES",
    paragramh:
      "La electromovilidad contribuye también al desarrollo de ciudades inteligentes o smart cities (en inglés), concepto que apunta al desarrollo urbano sostenible, dando respuesta a las necesidades de la sociedad, pero también de organizaciones y empresas, buscando la eficiencia energética y la reducción de emisiones de CO2, al mismo tiempo que el desarrollo económico y el cuidado del medioambiente.",
  };

  return (
    <div>
      <Grid2 celled="internally">
        <Grid2>
          <Grid2 computer={16} mobile={16} tablet={16}>
            <div className="text-center">
              <span className="abs-center">
                <h1 className="resaltadoCurvo">BICCI</h1>
              </span>
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo">
                  Somos BICCI, una Startup de última milla sustentable
                  desarrollada para empresas. Ofrecemos un novedoso servicio de
                  entrega a través de nuestros vehículos eléctricos. Brindando
                  un servicio de calidad para nuestros clientes y contribuyendo
                  de esta manera a la reducción de la huella de carbono en el
                  mundo.
                </h2>
              </span>
            </div>
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2 computer={16} mobile={16} tablet={6}>
            {/* <bgrvideo /> */}
            <div>
              <ReactPlayer
                url="https://youtu.be/IXBxkazfh_U"
                className="react-player"
                playing
                width="100%"
                height="650px"
                loop={true}
                config={{
                  youtube: {
                    playerVars: { origin: "https://www.youtube.com" },
                  },
                }}
              />
            </div>
          </Grid2>
        </Grid2>

        <Grid2>
          <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo">
                {" "}
                Bicicleta Eléctrica EcoBicci 2.0
              </h2>
              <h2 className="resaltadoCurvo">
                Nuestra EcoBicci posee diversas habilidades y entre ellas, en
                primer lugar nos enorgullece ayudar al medio ambiente y además
                es capaz de transportar tus productos de forma eficiente ya que
                cuenta con un marco de aluminio que la hace liviana para su uso
                y una autonomía que le permite llegar hasta 100 kms de
                recorrido, lo cual la hace estupenda para poder cubrir todas tus
                necesidades.
              </h2>
            </div>
          </Grid2>
          <Grid2 computer={8} mobile={16} tablet={6}>
            <Box
              component="img"
              src={homeImgUrl}
              alt=""
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2 computer={16} mobile={16} tablet={16}>
            <div className="text-center">
              <br></br>
              <span className="abs-center">
                <h2 className="resaltadoCurvo">
                  BENEFICIOS DE LA ELECTROMOVILIDAD.
                </h2>
              </span>
            </div>
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo">CALIDAD DEL AIRE:</h2>
              <p>
                Un vehículo eléctrico es CERO EMISIÓN, es decir, mientras está
                en movimiento no produce emisiones de gases ni partículas, lo
                que impacta directamente en mejorar la calidad de aire en las
                ciudades pues se disminuye la contaminación local. En Chile, 12
                ciudades han sido declaradas como zonas saturadas en material
                particulado, siendo el sector transporte una de las principales
                preocupaciones para las autoridades. Mejorando la calidad del
                aire, se generan beneficios directos en salud y enfermedades
                respiratorias, principalmente en la población infanto-juvenil y
                en adultos mayores.
              </p>
            </div>
          </Grid2>
          <Grid2 computer={8} mobile={16} tablet={6}>
            <Box
              component="img"
              src={ecotribicci}
              alt=""
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid2>
        </Grid2>

        <Grid2>
          <Grid2 computer={8} mobile={16} tablet={6}>
            <Box
              component="img"
              src={ecortribicciR}
              alt=""
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid2>
          <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo">CAMBIO CLIMÁTICO::</h2>
              <p>
                La electromovilidad utiliza como fuente energética la
                electricidad, es decir, no ocurre combustión fósil (gasolina,
                diésel, gas) y por tanto no se producen emisiones directas de
                CO2 mientras un vehículo eléctrico se mueve, contribuyendo así a
                combatir el cambio climático. Este beneficio se logra maximizar
                cuando la electricidad es generada también con tecnologías de
                cero emisión, como lo son las energías renovables.
              </p>
            </div>
          </Grid2>
        </Grid2>

        <Grid2>
          <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo">CONTAMINACIÓN ACÚSTICA::</h2>
              <p>
                Los vehículos eléctricos casi no emiten ruido, esto se hace más
                relevante en flotas numerosas de vehículos, en vehículos pesados
                con motores diesel o en vehículos operando a velocidades por
                sobre los 50 (km/h){" "}
              </p>
            </div>
          </Grid2>
          <Grid2 computer={8} mobile={16} tablet={6}>
            <Box
              component="img"
              src={ecortribicci}
              alt=""
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid2>
        </Grid2>

        <Grid2>
          <Grid2 computer={8} mobile={16} tablet={6}>
            <Box
              component="img"
              src={boxbicciexpres}
              alt=""
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid2>
          <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo">GESTIÓN DE RESIDUOS:</h2>
              <p>
                Los mecanismos y tecnologías de los vehículos eléctricos no
                requieren de lubricantes y filtros, lo que reduce el manejo de
                residuos peligrosos. Por otra parte, los sistemas de freno
                sufren menor desgaste dado que los vehículos eléctricos emplean
                un sistema de frenos regenerativos. Las baterías eléctricas,
                luego de cumplir su ciclo de vida en el vehículo (8 a 10 años)
                pueden seguir siendo usadas para aplicaciones estacionarias (10
                - 15 años) y posteriormente pueden ser recicladas.{" "}
              </p>
            </div>
          </Grid2>
        </Grid2>

        <Grid2>
          <Grid2 computer={8} mobile={16} tablet={6} id="paragraph2">
            <div>
              <h2 className="resaltadoCurvo">
                {" "}
                BENEFICIOS DE LA ELECTROMOVILIDAD
              </h2>
              <p>
                Cuando logramos transportar una determinada cantidad de
                kilómetros a personas o carga pero utilizando menos energía,
                entonces estamos haciendo eficiencia energética en el
                transporte. Un vehículo eléctrico gasta en torno a un quinto de
                la energía que un vehículo a combustión equivalente,
                principalmente porque las eficiencias de un motor de combustión
                interna no superan el 50% mientras que las de un motor eléctrico
                están por sobre un 95%. Además, los vehículos eléctricos tienen
                la ventaja de recuperar energía gracias a los sistemas de frenos
                regenerativos. Por lo tanto, para una misma distancia y una
                misma condición de carga o pasajeros transportados, esta
                eficiencia energética se traduce en un ahorro económico en la
                operación.
              </p>
            </div>
          </Grid2>
          <Grid2 computer={8} mobile={16} tablet={6}>
            <Box
              component="img"
              src={ecobicci2}
              alt=""
              sx={{ width: "100%", height: "auto" }}
            />
          </Grid2>
        </Grid2>

        <Grid2>
          <Grid2
            computer={16}
            mobile={16}
            tablet={6}
            className="paragraphtop"
            id="paragraph2"
          >
            <div>
              <h2 className="resaltadoCurvo">DIVERSIFICACIÓN ENERGÉTICA:</h2>
              <p>
                Chile ya inició el camino de la diversificación energética en el
                sector de generación eléctrica, donde las energías renovables
                vienen desplazando las centrales termoeléctricas convencionales
                y donde se espera que al 2050 la participación de la generación
                con energías renovables supere el 70% de la matriz.
              </p>
            </div>
          </Grid2>
        </Grid2>
      </Grid2>

      <Bannerfooter footermensaje={footermensaje} />
    </div>
  );
};

export default Pos4;
