import axios from "axios";
import { db } from "../firebase";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  where,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { order } from "../utils/objects/odersBody";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const dataInicial = {
  loading: false,
};

// types
const LOADING = "LOADING";
const CREATE_ORDERS_EXITO = "CREATE_ORDERS_EXITO";
const CREATE_ORDERS_ERROR = "CREATE_ORDERS_ERROR";

// reducer
export default function createOrdersBicciexpress(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case CREATE_ORDERS_EXITO:
      return { ...state, loading: false, error: action.error };
    case CREATE_ORDERS_ERROR:
      return { ...state, loading: false, susscces: action.msj };
    default:
      return { ...state };
  }
}

export const createOrderBicciexpressBySDD =
  (data, dataSistema) => async (dispatch) => {
    console.log(data, dataSistema);
    for (let i = 0; i < data.length; i++) {
      let time = ExcelDateToJSDate(data[i].RANGOFECHAPACTADA);
      let timeTravelDate = ExcelDateToJSDate(data[i].FECHAVIAJE);

      let toAddress = getToAdressDirection(
        data[i].DIRECCION1,
        data[i].DIRECCION2,
        data[i].DIRECCION3,
        data[i].COMUNA,
        data[i].COMUNA2
      );

      // const results = await getGeocode({ toAddress });
      // const { lat, lng } = getLatLng(results[0]);
      order.dataOrigin = "DATA";
      order.key = "F" + data[i].SOC;
      order.orderID = "F" + data[i].SOC.toString();
      order.id = data[i].SOC.toString();
      order.company = dataSistema.company;
      order.companyId = dataSistema.company;
      order.commerce = data[i].BODEGANOMBRE;
      order.int_warehouseCode = data[i].BODEGACODIGO;
      order.int_warehouseName = data[i].BODEGANOMBRE;
      order.int_placeName = data[i].BODEGANOMBRE;
      order.int_travelDate = timeTravelDate.fechaMasUnoFormat;
      order.int_f12 = data[i].F12;
      //order.int_travelNumber = data[i].NUMEROVIAJE;
      order.int_client_soc = data[i].SOC;
      order.int_lpn = data[i].LPN;
      order.int_serviceLevel = data[i].SERVICELEVEL;
      order.int_serviceType = data[i].TIPODELIVERY
        ? data[i].TIPODELIVERY
        : "SD";
      order.userPhone = data[i].CLIENTETELEFONO ? data[i].CLIENTETELEFONO : 0;
      order.clientNames = data[i].CLIENTENOMBRES;
      order.clientRut = data[i].CLIENTERUT;
      order.clientEmail = data[i].CLIENTECORREO
        ? data[i].CLIENTECORREO
        : "NO INFORMA";
      order.toAddress = toAddress.a1;
      order.direccion1 = data[i].DIRECCION1;
      order.direccion2 = data[i].DIRECCION2 ? data[i].DIRECCION2 : "";
      order.direccion3 = data[i].DIRECCION3 ? data[i].DIRECCION3 : "";
      order.commune = data[i].COMUNA;
      order.commune2 = data[i].COMUNA2 ? data[i].COMUNA2 : "";
      order.region = data[i].COMUNA ? data[i].COMUNA : "";
      order.serviceType = data[i].SERVICELEVEL;
      order.deliveryDate = timeTravelDate.fechaMasUnoFormat;
      order.timeStamp = time.fechaMasUno;
      order.createdAt = timeTravelDate.fechaMasUno;
      order.description = data[i].DESCRIPTION
        ? data[i].DESCRIPTION
        : "NO INFORMA";
      order.zonePoligon = "";
      //order.pickedUp = data[i].RECOGIDO;
      order.productLength = data[i].LARGO;
      order.productHeight = data[i].ALTO;
      order.productWidth = data[i].ANCHO;
      order.productWeight = data[i].PESO;
      order.productName = data[i].PRODUCTO ? data[i].PRODUCTO : "NO INFORMA";
      order.productSku = data[i].SKU ? data[i].SKU : "NO INFORMA";
      order.productSize = data[i].TAMANOPRODUCTO;
      order.productUnits = data[i].UNIDADES ? data[i].UNIDADES : 1;
      order.productValue = data[i].VALOR;
      order.productVolume = data[i].VOLUMEN;
      order.productMultiDelivery = data[i].MULTIBULTO;
      order.transportOrderSize = data[i].TAMANOPRODUCTO;
      // order.driverLicensePlate = data[i].CAMION;
      order.routeEstimatedDate = time.fechaMasUnoFormat;
      order.routeStartDate = time.fechaMasUnoFormat;
      order.routeStartTime = data[i].VENTANAINICIO;
      order.routeEndTime = data[i].VENTANATERMINO;
      order.routeLocality = data[i].COMUNA2;
      console.log(order.int_serviceType);
      try {
        console.log("CREACION DE ORDENES NUEVO METODO :", order.key);

        // console.log(order.key);
        // console.log(time.fechaMasUno);
        // console.log(order);
        let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, order.key);
        await setDoc(docRef, order);
        //await updateDoc(docRef, order);
      } catch (error) {
        console.log(order);
        console.log(error);
      }
      //console.log(data[i]);
    }
    //console.log(order);
  };

export const updateOriginalOrderBicciexpressBySDD =
  (data, dataSistema) => async (dispatch) => {
    for (let i = 0; i < data.length; i++) {
      let time = ExcelDateToJSDate(data[i].RANGOFECHAPACTADA);
      let timeTravelDate = ExcelDateToJSDate(data[i].FECHAVIAJE);

      let toAddress = getToAdressDirection(
        data[i].DIRECCION1,
        data[i].DIRECCION2,
        data[i].DIRECCION3,
        data[i].COMUNA,
        data[i].COMUNA2
      );

      let orderUpdate = {
        dataOrigin: "SDDATA",
        key: "F" + data[i].SOC,
        orderID: "F" + data[i].SOC.toString(),
        id: data[i].SOC.toString(),
        company: dataSistema.company,
        companyId: dataSistema.company,
        commerce: data[i].BODEGANOMBRE,
        int_warehouseCode: data[i].BODEGACODIGO,
        int_warehouseName: data[i].BODEGANOMBRE,
        int_placeName: data[i].BODEGANOMBRE,
        int_travelDate: timeTravelDate.fechaMasUnoFormat,
        int_f12: data[i].F12,
        int_serviceType: data[i].TIPODELIVERY
          ? data[i].TIPODELIVERY
          : data[i].int_serviceType,
        //int_travelNumber : data[i].NUMEROVIAJE;
        int_client_soc: data[i].SOC,
        int_lpn: data[i].LPN,
        int_serviceLevel: data[i].SERVICELEVEL,
        userPhone: data[i].CLIENTETELEFONO,
        clientNames: data[i].CLIENTENOMBRES,
        clientRut: data[i].CLIENTERUT,
        clientEmail: data[i].CLIENTECORREO
          ? data[i].CLIENTECORREOdata[i].CLIENTECORREO
          : "NO INFORMA",
        toAddress: toAddress.a1,
        direccion1: data[i].DIRECCION1,
        direccion2: data[i].DIRECCION2 ? data[i].DIRECCION2 : "",
        direccion3: data[i].DIRECCION3 ? data[i].DIRECCION3 : "",
        commune: data[i].COMUNA ? data[i].COMUNA : "",
        commune2: data[i].COMUNA2 ? data[i].COMUNA2 : "",
        region: data[i].COMUNA,
        serviceType: data[i].SERVICELEVEL,
        deliveryDate: timeTravelDate.fechaMasUnoFormat,
        timeStamp: time.fechaMasUno,
        description: data[i].DESCRIPTION ? data[i].DESCRIPTION : "NO INFORMA",
        pickedUp: data[i].RECOGIDO,
        productLength: data[i].LARGO,
        productHeight: data[i].ALTO,
        productWidth: data[i].ANCHO,
        productWeight: data[i].PESO,
        productName: data[i].PRODUCTO,
        productSku: data[i].SKU,
        productSize: data[i].TAMANOPRODUCTO,
        productUnits: data[i].UNIDADES,
        productValue: data[i].VALOR,
        productVolume: data[i].VOLUMEN,
        productMultiDelivery: data[i].MULTIBULTO,
        transportOrderSize: data[i].TAMANOPRODUCTO,
        // driverLicensePlate : data[i].CAMION;
        routeEstimatedDate: time.fechaMasUnoFormat,
        routeStartDate: time.fechaMasUnoFormat,
        routeStartTime: data[i].VENTANAINICIO,
        routeEndTime: data[i].VENTANATERMINO,
        routeLocality: data[i].COMUNA2,
      };
      try {
        console.log("Creaccion Nuevo metodo");

        // console.log(orderUpdate.key);
        // console.log(time.fechaMasUno);
        // console.log(orderUpdate);
        let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, orderUpdate.key);

        await updateDoc(docRef, orderUpdate);
        // console.log("Actualizacion de desarrollo");
      } catch (error) {
        console.log(orderUpdate);
        console.log(error);
      }
      //console.log(data[i]);
    }
    //console.log(order);
  };

export const updateOrderBicciExpressByPregrillaGeosort =
  (data, dataSistema, type) => async (dispatch) => {
    console.log(data, dataSistema);
    for (let i = 0; i < data.length; i++) {
      let orderKey = "F" + data[i].Suborden;
      let dataOrder = {
        dataOrigin: "GSDATA",
        key: "F" + data[i].Suborden,
        id: data[i].Suborden.toString(),
        clientRut: data[i].Rutcliente,
        routeId: data[i].Idruta,
        routePosition: data[i].Posruta,
        int_lpn: data[i].Lpn,
        driverLicensePlate: data[i].Patente,
      };

      try {
        console.count(orderKey);
        let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, orderKey);
        await updateDoc(docRef, dataOrder);
        // console.log("Orden UPDATE " + orderKey);
        // console.log(orderKey, dataOrder);
      } catch (error) {
        // console.log(order);
        // console.log(error);
        console.log(
          "Creando un registro nuevo no encontrado en el documento anterior SDD, Se crea registro forzado " +
            orderKey
        );
        dataOrder.deliveryDate = new Date();
        dataOrder.timeStamp = new Date();
        dataOrder.createdAt = new Date();
        let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, orderKey);
        await setDoc(docRef, dataOrder);
        try {
          let toAddress = getToAdressDirection(
            data[i].Dirección,
            data[i].Localidad
          );
          const fechaActual = new Date();
          const opcionesFormato = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          };

          const formatoDeseado = fechaActual.toLocaleString(
            undefined,
            opcionesFormato
          );

          let time = ExcelDateToJSDate(formatoDeseado);
          // const results = await getGeocode({ toAddress });
          // const { lat, lng } = getLatLng(results[0]);
          dataOrder.dataOrigin = "GSATA";
          dataOrder.key = "F" + data[i].Suborden;
          dataOrder.orderID = "F" + data[i].Suborden.toString();
          dataOrder.id = data[i].Suborden.toString();
          dataOrder.company = dataSistema.company;
          dataOrder.companyId = dataSistema.company;
          dataOrder.commerce = "FALABELLA RETAIL S.A.";

          dataOrder.int_warehouseName = "FALABELLA RETAIL S.A.";
          dataOrder.int_placeName = "FALABELLA RETAIL S.A.";
          dataOrder.int_travelDate = format(new Date(), "dd-MM-yyyyy hh:mm:ss");
          dataOrder.int_f12 = data[i].Do;
          //order.int_travelNumber = data[i].NUMEROVIAJE;
          dataOrder.int_client_soc = data[i].Suborden;
          dataOrder.int_lpn = data[i].Lpn;
          dataOrder.int_serviceLevel = "AD";
          dataOrder.clientNames = data[i].Nombrecliente;
          dataOrder.clientRut = data[i].Rutcliente;
          dataOrder.toAddress = toAddress;
          dataOrder.direccion1 = toAddress;
          // dataOrder.direccion2 = data[i].DIRECCION2 ? data[i].DIRECCION2 : "";
          // dataOrder.direccion3 = data[i].DIRECCION3 ? data[i].DIRECCION3 : "";
          dataOrder.commune = data[i].Localidad;
          // dataOrder.commune2 = data[i].COMUNA2;
          // dataOrder.region = data[i].COMUNA;
          dataOrder.serviceType = "ctt bicci hub";
          dataOrder.deliveryDate = format(new Date(), "dd-MM-yyyyy hh:mm:ss");
          dataOrder.timeStamp = time.fechaMasUno;
          dataOrder.createdAt = time.fechaMasUno;
          dataOrder.description = data[i].Producto
            ? data[i].Producto
            : "noRequire";
          dataOrder.zonePoligon = true;
          dataOrder.productLength = 0;

          dataOrder.productName = data[i].Producto;
          dataOrder.productSku = data[i].Sku;
          // order.driverLicensePlate = data[i].CAMION;
          //dataOrder.routeEstimatedDate = time.fechaMasUnoFormat;
          dataOrder.routeStartDate = "9:00";
          dataOrder.routeStartTime = "9:00";
          dataOrder.routeEndTime = "21:00";
          dataOrder.routeLocality = data[i].Localidad;
          let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, orderKey);
          await setDoc(docRef, dataOrder);
        } catch (error) {
          console.log(error);
        }
      }
      //console.log(data[i]);
    }
  };

export const createOrderBicciexpressByGS =
  (data, dataSistema) => async (dispatch) => {
    console.log(data, dataSistema);
    for (let index = 0; index < data.length; index++) {
      // if (data[index].Estado !== "Terminado") {
      console.count("Actualizar " + data[index].Suborden.toString());
      let updateID = "F" + data[index].Suborden.toString();

      const fechaActual = new Date();
      const opcionesFormato = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      const formatoDeseado = fechaActual.toLocaleString(
        undefined,
        opcionesFormato
      );

      let time = ExcelDateToJSDate(formatoDeseado);

      let Fechaentregareal = data[index].Fechaentregareal
        ? data[index].Fechaentregareal
        : "";

      // let Fechaestimada = ExcelDateToJSDate(
      //   data[index].Fechaestimada,
      //   data[index].Suborden.toString(),
      //   "Fechaestimada"
      // );

      let Deliverylongitude = data[index].Deliverylongitude.toString().replace(
        '"',
        ""
      );
      let Deliverylatitude = data[index].Deliverylatitude.toString().replace(
        '"',
        ""
      );
      let Pointlatitude = data[index].Pointlatitude.toString().replace('"', "");
      let Pointlongitude = data[index].Pointlongitude.toString().replace(
        '"',
        ""
      );
      let Distance_totalization = data[
        index
      ].Distance_totalization.toString().replace('"', "");

      let dataUpdate = {
        dataOrigin: "GSDATA",
        int_serviceType: "HD",
        int_placeName: "(Hub XD Midi-Mldi Ticket) FALABELLA RETAIL S.A.",
        orderID: "F" + data[index].Suborden.toString(),
        key: "F" + data[index].Suborden.toString(),
        int_client_soc: data[index].Suborden.toString(),
        id: "F" + data[index].Suborden.toString(),
        company: dataSistema.company,
        companyId: dataSistema.company,
        commerce: data[index].Commerce,
        int_simpliRouteId: data[index].Simpliroute_id
          ? data[index].Simpliroute_id
          : "",
        int_falabella_status: data[index].Estado,
        serviceType: "ctt bicci hub",
        toAddressLocation: {
          lat: parseFloat(Pointlatitude),
          lng: parseFloat(Pointlongitude),
        },
        //  direccion1: data[index].Dirección,
        driverEmail: data[index].Driveremail ? data[index].Driveremail : "",
        driverName: data[index].Drivername ? data[index].Drivername : "",
        driverRut: data[index].Driverrut ? data[index].Driverrut : "",
        routeRanceTotalization: parseFloat(Distance_totalization),
        //   routeEstimatedDate: Fechaestimada.fechaMasUnoFormat,
        // timeStamp: time.fechaMasUno,
        // upDate: Date.now(),
        // createdAt: format(new Date(), "dd-MM-yyyyy hh:mm:ss"),
        routeId: data[index].Idruta,
        //routePosition : data[index].Posicionruta,
        routeLocality: data[index].Localidad,
        deliveryMethod: data[index].Metodoentrega,
        deliveryPictures: data[index].Imageurl ? data[index].Imageurl : "",
        deliveryComment: data[index].Comentarionoentrega
          ? data[index].Comentarionoentrega
          : "",
        int_falabella_status_descriptor: data[index].Motivonoentrega
          ? data[index].Motivonoentrega
          : "",
        deliveryRealDeliveryDate: Fechaentregareal ? Fechaentregareal : "",
        deliveryReceptor: data[index].Paperlessreceptor
          ? data[index].Paperlessreceptor
          : "",
        deliveryRut: data[index].Paperlessrut ? data[index].Paperlessrut : "",
        deliveryGeoref: {
          lat: parseFloat(Deliverylongitude),
          lng: parseFloat(Deliverylatitude),
        },
        int_warehouseName: data[index].Ct,
        driverLicensePlate: data[index].Patente,
        // timeStamp: time.fechaMasUno,
        // createdAt: time.fechaMasUno,
      };

      try {
        let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, updateID);
        await updateDoc(docRef, dataUpdate);
        console.log("update OK");
      } catch (error) {
        console.log(dataUpdate);
        console.log(error);
        console.log(
          "Creando un registro nuevo no encontrado en el documento anterior SDD, Se crea registro forzado " +
            updateID
        );
        dataUpdate.timeStamp = time.fechaMasUno;
        dataUpdate.createdAt = time.fechaMasUno;
        try {
          let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, updateID);
          await setDoc(docRef, dataUpdate);
          console.log("update OK");
        } catch (error) {
          console.log(error);
        }
      }

      //  console.log(order);
      // } else {
      //   console.log("No actualizable estado terminado");
      // }
    }
  };

export const updateOrdersBicciexpressPayments =
  (data, res) => async (dispatch) => {
    // console.log(data);

    for (let n = 0; n < data.length; n++) {
      console.log(data[n]);
      const orderRef = doc(
        db,
        process.env.REACT_APP_COL_ORDERS,
        data[n].BICCIID.toString()
      );
      try {
        await updateDoc(orderRef, {
          billingPaymentValor: data[n].PaymentValor,
          billingPaymentType_service: data[n].PaymentType_service,
          billingPaymentProforma: data[n].PaymentProforma,
          billingCurrencyCode: "CLP",
          billingCurrencySymbol: "$",
        });
      } catch (error) {
        console.log("Manejar nueva solicitud:", data[n].BICCIID.toString());

        let queries = [];

        const collectionOrdersRef = collection(
          db,
          process.env.REACT_APP_COL_ORDERS
        );

        queries.push(
          query(collectionOrdersRef, where("orderID", "==", data[n].BICCIID))
        );
        queries.push(
          query(collectionOrdersRef, where("key", "==", data[n].BICCIID))
        );
        queries.push(
          query(collectionOrdersRef, where("int_lpn", "==", data[n].BICCIID))
        );
        queries.push(
          query(collectionOrdersRef, where("soc", "==", data[n].BICCIID))
        );

        let combinedResults = [];

        for (let q of queries) {
          try {
            let ordesBicciexpres = await getDocs(q);
            let dataOrdesBicciexpres = ordesBicciexpres.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            console.log(dataOrdesBicciexpres);
            combinedResults = combinedResults.concat(dataOrdesBicciexpres);
          } catch (error) {
            console.error("Error al ejecutar la consulta:", error);
            // Manejar el error o continuar con la siguiente consulta
          }
        }

        const uniqueResults = [
          ...new Map(combinedResults.map((item) => [item.id, item])).values(),
        ];

        console.log(uniqueResults);

        try {
          const orderRefq = doc(
            db,
            process.env.REACT_APP_COL_ORDERS,
            uniqueResults[0].id.toString()
          );

          await updateDoc(orderRefq, {
            billingPaymentValor: data[n].PaymentValor,
            billingPaymentType_service: data[n].PaymentType_service,
            billingPaymentProforma: data[n].PaymentProforma,
            billingCurrencyCode: "CLP",
            billingCurrencySymbol: "$",
          });
          console.log("Documento Actualizado");
        } catch (error) {
          console.log(error);
          console.log("No se encuentra ID");
        }
      }
    }
  };

export const updateOrdersBicciexpressEstatus = (data) => async (dispatch) => {
  // Validar porque no se actualizan los DF
  console.log("Recibe Documento");
  console.log(data);
  const orderRef = doc(db, process.env.REACT_APP_COL_ORDERS, data.id);
  try {
    await updateDoc(orderRef, {
      statusCreate: data.statusCreate,
      status: data.status,
      int_bicciStatus: data.status,
      driverName: data.driverName,
      driverID: data.driverID,
      // createdAt: data.createdAt ? data.createdAt : "",
      int_falabella_status: data.int_falabella_status,
      deliveryComment: `${
        data.observationReceptor ? data.observationReceptor : ""
      }  ${data.observationAdmin ? " " + data.observationAdmin : ""}`,
      deliveryRut: data.userDniReceptor ? data.userDniReceptor : "",
      deliveryReceptor: data.userNameReceptor ? data.userNameReceptor : "",
      deliveryRealDeliveryDate: data.updatedAt
        ? format(data.updatedAt.seconds * 1000, "dd/MM/yy hh:mm:ss").toString()
        : "",
    });
    console.log("Documento Actualizado", data.id);
  } catch (error) {
    console.log("error :", data.id);
    console.log(error);
  }
};

export const createOrderBicciexpressClientCompany =
  (data) => async (dispatch) => {
    console.log(data);
    //Consultar Contador
    try {
      console.log("CREACION DE ORDENES  METODO FORMULARIO :", data.key);
      let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, data.key);
      await setDoc(docRef, data);
    } catch (error) {
      console.log(error);
    }
  };

export const createOrderBicciClientCompanyBicciexpress =
  (data) => async (dispatch) => {
    console.log(data);

    const productsTransformed = data.products.map((product) => ({
      id: product.productSku, // Asumiendo que productSku es un identificador adecuado
      name: product.productName,
      description: product.description,
      quantity: parseInt(product.productUnits, 10),
      price: parseInt(product.productValue, 10), // Asegúrate de que el precio sea manejado correctamente
    }));

    const apiObject = {
      data: {
        companyID: data.int_bicciCompanys,
        userName: data.commerce,
        userEmail: data.clientEmailOrigin,
        userPhone: data.userPhone,
        couponID: "", // Asumiendo que no hay un equivalente directo en el objeto original
        groceries: productsTransformed,
        fromLocation: {
          latitude: data.addressGeoreft.lat,
          longitude: data.addressGeoreft.lng,
        },
        toLocation: {
          latitude: data.toAddressGeoreft.lat,
          longitude: data.toAddressGeoreft.lng,
        },
        fromAddress: `${data.direccion1Origin} ${data.direccion2Origin}`,
        toAddress: data.toAddress, // Asumiendo que es el formato completo deseado
      },
    };

    console.log(apiObject);

    try {
      console.log("TRANSFERENCIA DE ORDEN BICCI :", data.id);
      //let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, order.key);
      //await setDoc(docRef, order);
      const dataSend = JSON.stringify(apiObject);
      const response = await axios.post(
        process.env.REACT_APP_BICCI_API_PRO,
        dataSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 600000,
        }
      );
      /*
       "result": {
        "code": 200,
        "orderID": "1981185278",
        "message": "Orden creada con éxito"
    }
      */
      // console.log(response);
      if (response.data.result.code === 200) {
        let BICCIID = response.data.result.orderID;
        let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, data.key);
        await updateDoc(docRef, {
          orderID: BICCIID,
          deliveryType: data.int_serviceType,
        });
        let docBicciRef = doc(db, process.env.REACT_APP_COL_ORDERS, BICCIID); // DESARROLLO Sanear la ausencia del dato en la creacion BICCI
        await updateDoc(docBicciRef, {
          deliveryType: data.int_serviceType,
        });
      } else {
        console.log("error al crear documento");
      }
    } catch (error) {
      console.log("ERROR TRANSFERENCIA DE ORDEN A BICCI :", data.id);
      console.log(error);
    }
  };

export const createOrderBicciexpressClient =
  (data, res) => async (dispatch) => {
    order.billingCurrencyCode = "DATA GS";
    console.log(order);
  };

export const registerPickerActions = (data, user) => async (dispatch) => {
  let dataID = data.id || data.orderID;
  try {
    if (data && dataID) {
      // Referencia para el registro de picker
      const orderRefPiking = doc(
        db,
        process.env.REACT_APP_COL_PICKER_REGISTER,
        dataID.toString()
      );
      const docSnapshotPicking = await getDoc(orderRefPiking);

      // Verifica si ya existe el documento
      if (docSnapshotPicking.exists()) {
        // Si existe, agregamos un subdocumento con un nuevo escaneo
        const subdocumentId = Date.now().toString();
        const newScan = {
          [subdocumentId]: {
            id: dataID,
            date: new Date(),
            deliveryDate: data.deliveryDate ? data.deliveryDate : "null",
            driverLicensePlate: data.driverLicensePlate
              ? data.driverLicensePlate
              : "null",
            routePosition: data.routePosition ? data.routePosition : "null",
            user: user.uid ? user.uid : "",
            isPickedUp: true,
            companyId: user.company ? user.company : "",
          },
        };

        try {
          // Actualizamos el documento con el nuevo escaneo
          await updateDoc(orderRefPiking, newScan);
        } catch (error) {}
      } else {
        // Si no existe, creamos el documento
        try {
          await setDoc(orderRefPiking, {
            id: dataID,
            orderID: dataID,
            date: new Date(),
            deliveryDate: data.deliveryDate ? data.deliveryDate : "null",
            driverLicensePlate: data.driverLicensePlate
              ? data.driverLicensePlate
              : "null",
            routePosition: data.routePosition ? data.routePosition : "null",
            user: user.uid ? user.uid : "",
            timeStamp: new Date(),
            isPickedUp: true,
            companyId: user.company ? user.company : "",
          });
        } catch (error) {}
      }

      // Ahora verificamos si el documento existe en la colección `ORDERS`
      let companyIdOrder = dataID.toString();
      const orderRef = doc(
        db,
        process.env.REACT_APP_COL_ORDERS,
        companyIdOrder
      );
      const docSnapshot = await getDoc(orderRef);

      if (docSnapshot.exists()) {
        try {
          // Si el documento existe, lo actualizamos
          await updateDoc(orderRef, { isPickedUp: true });
        } catch (error) {}
      } else {
        // Si no existe, intentamos agregar un prefijo 'F' y buscar de nuevo
        let companyIdOrderF = "F" + dataID.toString();
        const orderRefF = doc(
          db,
          process.env.REACT_APP_COL_ORDERS,
          companyIdOrderF
        );
        const docSnapshotF = await getDoc(orderRefF);

        if (docSnapshotF.exists()) {
          try {
            await updateDoc(orderRefF, { isPickedUp: true });
          } catch (error) {}
        } else {
        }
      }
    } else {
    }
  } catch (error) {}
};

export const registerOrderIssures = (data, user) => async (dispatch) => {
  //Validar datos de Orden para verlos en resultados de Busqueda
  let companyIdOrder = data.id.toString();
  const orderRef = doc(db, process.env.REACT_APP_COL_ORDERS, companyIdOrder);

  const orderLogs = doc(
    db,
    process.env.REACT_APP_COL_XPRESSLOGS,
    companyIdOrder
  );

  const docSnapshot = await getDoc(orderRef);
  if (!docSnapshot.exists()) {
    async function validarFormato(x) {
      // Define una expresión regular para cada uno de los formatos
      const formato1 = /^F\d{12}$/;
      const formato2 = /^F\d{15}$/;
      // const formato3 = /^\d{18}$/;

      // Comprueba si la cadena coincide con alguno de los formatos
      if (formato1.test(x) || formato2.test(x)) {
        //|| formato3.test(x)) {
        return true; // La cadena cumple con alguno de los formatos
      } else {
        return false; // La cadena no cumple con ninguno de los formatos
      }
    }
    let format = await validarFormato(companyIdOrder);
    if (format) {
      console.log("Formato valido Crear en tabla orders");
      await setDoc(orderRef, {
        id: data.id,
        orderID: "F" + data.id,
        user: user.uid ? user.uid : "",
        date: new Date(),
        timeStamp: new Date(),
        companyId: user.company,
        isPickedUp: true,
        int_alert: "Discrepancia sobrante",
        int_placeName: "(Hub XD Midi-Mldi Ticket) FALABELLA RETAIL S.A.",
      });
      await setDoc(orderLogs, {
        id: companyIdOrder,
        date: new Date(),
        timeStamp: new Date(),
        userId: user.uid ? user.uid : "",
        companyId: user.company,
        isPickedUp: true,
        type: "issure",
        newValue: "Discrepancia: no se encuentra el producto en data",
      });
    } else {
      console.log("No es una Orden");
    }
  }
};

export const TempRegisterGeorefcExeptions = (data) => async (dispatch) => {
  // FALABELLA BICCI API
  const companyIdOrder = data.data.id;
  const xception = data.data.status;
  const location = data.riderDestailsLocation;

  try {
    const orderRef = doc(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS,
      companyIdOrder
    );
    const docSnapshot = await getDoc(orderRef);

    if (docSnapshot.exists()) {
      const data = {
        data: {
          orderID: companyIdOrder,
          exceptionID: xception.toString(),
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BICCI_API_PRO_SEND_EXCEPTION,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        console.log(response);
        try {
          await updateDoc(orderRef, {
            exceptionGeoRef: location ? location : "",
            intState: xception.toString(),
          });
          console.log("Georeferencia actualizada correctamente");
        } catch (updateError) {
          console.log("Error al actualizar la georeferencia: ", updateError);
        }
      } catch (error) {
        console.log("Error en la petición de axios:", error);
      }
    } else {
      console.log("Documento no encontrado");
    }
  } catch (error) {
    console.log("Error al obtener el documento:", error);
  }
};

export const SendExeptionsIntegrations = (dataReq) => async (dispatch) => {
  let senddata = { data: dataReq };

  console.log("Datos enviados:", JSON.stringify(senddata, null, 2)); // Validar la estructura antes de enviar

  if (!dataReq.id || !dataReq.status || !dataReq.reasonCode) {
    console.error("Error: El objeto dataReq está incompleto:", dataReq);
    return;
  }

  const companyIdOrder = dataReq.id;

  try {
    const orderRef = doc(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS,
      companyIdOrder
    );
    const docSnapshot = await getDoc(orderRef);

    if (docSnapshot.exists()) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://us-central1-bicci-7ed2f.cloudfunctions.net/ebiexSendSubStateUpdate",
        headers: {
          "Content-Type": "application/json",
        },
        data: senddata,
      };

      try {
        const response = await axios.request(config);
        console.log("Respuesta del servidor:", response.data);

        try {
          await updateDoc(orderRef, {
            exceptionGeoRef: docSnapshot.data().driverLocation || "",
          });
          console.log("Georeferencia actualizada correctamente");
        } catch (updateError) {
          console.error("Error al actualizar la georeferencia: ", updateError);
        }
      } catch (error) {
        console.error(
          "Error en la petición de axios:",
          error.response ? error.response.data : error.message
        );
      }
    } else {
      console.warn("Documento no encontrado en Firestore:", companyIdOrder);
    }
  } catch (error) {
    console.error("Error al obtener el documento de Firestore:", error);
  }
};

export const registerDonwLoads = (data, user) => async (dispatch) => {
  let idReferent = data.id.toString();
  const orderLogs = doc(db, process.env.REACT_APP_COL_XPRESSLOGS, idReferent);
  await setDoc(orderLogs, {
    id: idReferent,
    user: data.userUid,
    origin: data.appRoute,
    reportType: data.reportType,
    timeStamp: new Date(),
    companyId: data.companyId,
    descriptor: "Descaga de Repote en Sistema",
  });
};

export const updateSateBicciexpressOrdes = (data) => async () => {
  console.log(data);
  let companyIdOrderF = "F" + data.id.toString();
  let companyIdOrder = data.id.toString();

  const orderRef = doc(db, process.env.REACT_APP_COL_ORDERS, companyIdOrder);
  const docSnapshot = await getDoc(orderRef);

  const orderRef2 = doc(db, process.env.REACT_APP_COL_ORDERS, companyIdOrderF);
  const docSnapshot2 = await getDoc(orderRef2);

  //console.log(companyIdOrder);
  if (docSnapshot.exists()) {
    try {
      await updateDoc(orderRef, {
        status: data.selectedEstatus,
        int_falabella_status_descriptor: data.selectedEstatus,
      });
    } catch (error) {
      console.log(error);
    }
  }
  if (docSnapshot2.exists()) {
    try {
      await updateDoc(orderRef2, {
        status: data.selectedEstatus,
        int_falabella_status_descriptor: data.selectedEstatus,
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const createBicciexpressOrderIssures = (dataOrder) => async () => {
  console.log(dataOrder);

  let data = {
    date: new Date(),
    ticketId: dataOrder.row.id.toString(),
    orderId: dataOrder.row.id.toString(),
    lastValue: "0",
    newValue: "Issure",
    type: dataOrder.type,
    var: "ticket",
    userId: dataOrder.userUid,
    status: dataOrder.row.falabella_status
      ? dataOrder.row.falabella_status
      : dataOrder.row.int_falabella_status,
    observationAdmin: dataOrder.row.observationAdmin
      ? dataOrder.row.observationAdmin
      : "",
    ticketStatus: 0,
    ticketStatusDescriptor: "Create",
    companyId: dataOrder.companyId ? dataOrder.companyId : "",
  };

  try {
    const docRef = doc(
      db,
      dataOrder.type === "supportTicket" || dataOrder.type === "billingTicket"
        ? process.env.REACT_APP_COL_SUPPORT
        : process.env.REACT_APP_COL_XPRESSLOGS,
      dataOrder.row.id.toString()
    );

    const reponse = await setDoc(docRef, data);
  } catch (error) {
    console.log(error);
  }
};

export const createBicciexpressOrderIssuresDocument =
  (dataOrder) => async () => {
    let data = {
      date: new Date(),
      ticketId: dataOrder.dataFolder.ID.toString(),
      orderId: dataOrder.dataFolder.ID.toString(),
      lastValue: "0",
      newValue: "Issure",
      type: dataOrder.type,
      var: "ticket",
      dataOrder: {
        size: dataOrder.dataFolder.TALLA,
        alto: dataOrder.dataFolder.ALTO ? dataOrder.dataFolder.ALTO : "",
        largo: dataOrder.dataFolder.LARGO ? dataOrder.dataFolder.LARGO : "",
        vol: dataOrder.dataFolder.M3 ? dataOrder.dataFolder.M3 : "",
      },
      userId: dataOrder.userUid,
      status: dataOrder.dataFolder.ESTADO,
      observationAdmin: "Apelacion por volumen",
      ticketStatus: 0,
      ticketStatusDescriptor: "Create",
      companyId: dataOrder.companyId ? dataOrder.companyId : "",
    };

    try {
      const docRef = doc(
        db,
        dataOrder.type === "supportTicket" || dataOrder.type === "billingTicket"
          ? process.env.REACT_APP_COL_SUPPORT
          : process.env.REACT_APP_COL_XPRESSLOGS,
        dataOrder.dataFolder.ID.toString()
      );

      const reponse = await setDoc(docRef, data);
    } catch (error) {
      console.log(error);
    }
  };

export const verifiqueOrdesStatus = (data) => async (dispatch) => {};

function ExcelDateToJSDate(serial, id, typeDate) {
  try {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    //let fecha  = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    let fechaMasUno = new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
    fechaMasUno.setDate(fechaMasUno.getDate() + 1);
    let timestamp = fechaMasUno.getTime();
    //console.log(typeDate, id, fechaMasUno);
    let fechaMasUnoFormat = format(fechaMasUno, "dd/MM/yy HH:mm:ss", {
      locale: es,
    });

    //let fecha  = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    let fechaActual = new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    );
    fechaActual.setDate(fechaActual.getDate());

    return { fechaMasUnoFormat, fechaMasUno, fechaActual, timestamp };
  } catch (error) {
    let fechaMasUnoFormat = "";
    let fechaMasUno = "";
    let fechaActual = "";
    let timestamp = "";
    return { fechaMasUnoFormat, fechaMasUno, fechaActual };
  }
}

function getToAdressDirection(adress1, adress2, adress3, adress4, adress5) {
  let a1 = adress1 ? adress1.toString() : null;
  a1 += adress2 ? " " + adress2.toString() : "";
  a1 += adress3 ? ", " + adress3.toString() : "";
  a1 += adress4 ? ", " + adress4.toString() : "";
  a1 += adress5 ? ", " + adress5.toString() : "";

  return { a1 };
}

export const registerApelations = (data) => async (dispatch) => {
  let dataOreder = {
    type: "billingTicket",
    companyId: data.usuarioStorage.company,
    dataOrder: {
      alto: parseFloat(data.localSize.productLength),
      largo: parseFloat(data.localSize.productHeight),
      ancho: parseFloat(data.localSize.productLength),
      size: data.localSize.productSize,
      vol: parseFloat(data.localSize.productVolume),
    },
    date: new Date(),
    lastValue: "0",
    newValue: "Issure",
    observationAdmin: "Apelacion por volumen",
    orderId: data.dataOrder.key,
    status: "APELACION",
    ticketId: data.dataOrder.id,
    ticketStatus: 0,
    ticketStatusDescriptor: "Create",
    type: "billingTicket",
    userId: data.usuarioStorage.uid,
    var: "ticket",
    picsApelations: data.foto,
    int_alert: true,
  };
  console.log(dataOreder);
  const docRef = doc(
    db,
    process.env.REACT_APP_COL_SUPPORT,
    data.dataOrder.id.toString()
  );
  try {
    await setDoc(docRef, dataOreder);
  } catch (error) {
    console.log(error);
  }
};
