import React, { useEffect, useState } from "react";
import Login from "./components/Login/Login";
import Navbar from "./components/header/Navbar/Navbar";
import { onAuthStateChanged } from "firebase/auth";
import Nosotros from "./pages/Web/nosotros";
import Inicio from "./pages/Web/inicio";
import Servicios from "./pages/Web/servicios";
import Info from "./pages/Web/info";
import Contacto from "./pages/Web/contacto";
import Page404 from "./pages/Page404";
import Footer from "./components/Footer";
import Registro from "./components/Login/Registro";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Micuenta from "./pages/Dashboard/Micuenta";
import Tablero from "./pages/Dashboard/Tablero";
import Mensajes from "./pages/Dashboard/Mensajes";
import Soporte from "./pages/Dashboard/Soporte";
import Express from "./pages/Dashboard/Express";
import MiniCart from "./components/Cart/MiniCart";
import Direcciones from "./pages/Dashboard/Direcciones";
import AddUser from "./pages/Dashboard/AddUser";
import Companies from "./pages/Dashboard/Companies";
import BranchOffc from "./pages/Dashboard/BranchOffc";
import Users from "./pages/Dashboard/Users";
import Maps from "./pages/Dashboard/Maps";
import Drivers from "./pages/Dashboard/Drivers";
import Dispatchs from "./pages/Dashboard/Dispatchs";
import Guias from "./pages/Dashboard/Guias";
import GuiasFalabella from "./pages/Dashboard/GuiasFalabella";
import Rutas from "./pages/Dashboard/Rutas";
import Serviciosdash from "./pages/Dashboard/Serviciosdash";
import Seleccioneplan from "./pages/Dashboard/Serviciosdash/Seleccioneplan.jsx";
import Configuraciones from "./pages/Dashboard/Configuraciones";
import Seguridad from "./pages/Dashboard/Seguridad";
import ReportBug from "./pages/Dashboard/ReportBug";
import Ecobicci from "./pages/Web/ecobicci/Ecobicci";
import Traking from "./pages/Dashboard/Traking";
import Dispatchdetails from "./components/Dispatchdetails";
import EditDispatchdetails from "./components/Dispatchdetails/EditDispatchdetails.jsx";
import ScrollToTop from "./components/animations/ScrollToTop";
import useTitle from "./components/Seo/useTitle";
import useSeo from "./components/Seo/useSeo";
import Recuperarcuenta from "./components/Login/Recuperarcuenta";
import Products from "./components/Products/Products";
import Productdetails from "./components/Productdetails";
import Checkout from "./components/Checkout";
import useGlobalConfig from "./hooks/globalConfig";
import Seleccionepack from "./pages/Dashboard/Serviciosdash/Seleccionepack";
import Cart from "./components/Cart/Cart";
import DashDispatchsAdd from "./components/ManagementRoutes/DashDispatchs/DashDispatchsAdd/DashDispatchsAdd";
import DashDispatchs from "./components/ManagementRoutes/DashDispatchs/DashDispatchs";
import DashDispatchsEdit from "./components/ManagementRoutes/DashDispatchs/DashDispatchsEdit/DashDispatchsEdit";
import DashDispatchsNew from "./components/ManagementRoutes/DashDispatchs/DashDispatchsNew/DashDispatchsNew";
import DashRoutesAdd from "./components/ManagementRoutes/DashRoutes/DashRoutesAdd/DashRoutesAdd";
import DashRoutesNew from "./components/ManagementRoutes/DashRoutes/DashRoutesNew/DashRoutesNew";
import DashRoutesEdit from "./components/ManagementRoutes/DashRoutes/DashRoutesEdit/DashRoutesEdit";
import DashRoutes from "./components/ManagementRoutes/DashRoutes/DashRoutes";
import Fleet from "./pages/Dashboard/Fleet/Fleet";
import Planner from "./pages/Dashboard/Planner/Planner";
import { Box } from "@mui/material";
import ScreenSizeContext from "./context/ScreenSizeProvider";
import PlannerDetailsId from "./pages/Dashboard/Planner/PlannerDetailsId";
// import DashPlannerEdit from "./pages/Dashboard/Planner/PlannerEdit/DashPlannerEdit";
// import DashPlannerAdd from "./pages/Dashboard/Planner/PlannerNew/DashPlannerAdd";
import GuiasByFalabellaMonitorExcepcions from "./pages/Dashboard/Guias/GuiasByFalabellaMonitorExcepcions";
import GuiasByFalabellaMonitor from "./pages/Dashboard/Guias/GuiasByFalabellaMonitor";
import GuiasByCompanyBilling from "./pages/Dashboard/Guias/GuiasByCompanyBilling";
import Postulaciones from "./pages/Web/Postulaciones";
import PostulacionesTypes from "./pages/Web/Postulaciones/PostulacionesTypes";
import RutaPrivada from "./utils/RutaPrivada";
import { auth } from "./firebase";
import { cerrarSesionAccion } from "./redux/usuarioDucks";
import { useSelector, useDispatch } from "react-redux";
import Dashboard from "./pages/Dashboard/Dashboard";
import RutaProtegida from "./utils/RutaProtegida";
import Vehicles from "./pages/Dashboard/Vehicles/Vehicles";
import LoadingSpiner from "./components/LoadingSpinner/LoadingSpiner";
import PinPassAdmin from "./utils/PinPassAdmin";
import PinPass from "./utils/PinPass";
import DevelopTests from "./components/DevelopTests/DevelopTests";
import DynamicRouteBicci from "./components/DynamicRouteBicci/DynamicRouteBicci";
import DynamicRouteBicciDistribution from "./components/DynamicRouteBicci/DynamicRouteBicciDistribution";
import DynamicRouteBicciDistributionByReport from "./components/DynamicRouteBicci/DynamicRouteBicciDistributionByReport";
import { EmailMokups } from "./components/EmailMokups/EmailMokups";
import External from "./pages/External/External";
import SearchDoc from "./components/SearchDoc/SearchDoc";
import EagleEye from "./pages/Dashboard/EagleEye/EagleEye";
import ScannerDistribution from "./pages/ScannerDistribution/ScannerDistribution";
import GuiasFalabellaGeosort from "./pages/Dashboard/Guias/GuiasFalabellaGeosort";
import DriversDetails from "./pages/Dashboard/DriversDetails/DriversDetails";
import ScannerDistributionV2 from "./pages/ScannerDistribution/ScannerDistributionV2";
import GuiasFalabellaHD from "./pages/Dashboard/GuiasFalabellaHD";
import GuiasFalabellaSD from "./pages/Dashboard/GuiasFalabellaSD/GuiasFalabellaSD.jsx";
import GuiasFacturacion from "./pages/Dashboard/GuiasFacturacion/GuiasFacturacion.jsx";
import PinPassAdminBilling from "./utils/PinPassAdminBilling.jsx";
import AdminsCompanies from "./pages/Dashboard/Management/AdminsCompanies/AdminsCompanies.jsx";
import Permissions from "./pages/Dashboard/Management/Permissions/Permissions.jsx";
import Roles from "./pages/Dashboard/Management/Roles/Roles.jsx";
import ServicesActivations from "./pages/Dashboard/Management/ServicesActivations/ServicesActivations.jsx";
import Administrators from "./pages/Dashboard/Management/Administrators/Administrators.jsx";
import EagleEyeGoogleMaps from "./pages/Dashboard/EagleEye/EagleEyeGoogleMaps.jsx";
import AdminCompanies from "./pages/Dashboard/Management/AdminCompanies/AdminCompanies.jsx";
import RolesCapacidades from "./pages/Dashboard/Management/RolesCapacidades/RolesCapacidades.jsx";
import CompaniesSingleView from "./pages/Dashboard/Management/CompaniesSingleView/CompaniesSingleView.jsx";
import SupportTicket from "./pages/Dashboard/Management/SupportTicket/SupportTicket.jsx";
import ShippingPrint from "./pages/Dashboard/ShippingPrint/ShippingPrint.jsx";
import DistributionsGoogleMapsCompany from "./pages/Dashboard/DistributionsGoogleMapsCompany/DistributionsGoogleMapsCompany.jsx";
import Terminosycondiciones from "./pages/Web/Terminosycondiciones/Terminosycondiciones.jsx";
import ScannerApelations from "./pages/ScannerApelations/ScannerApelations.jsx";
import SupportTicketDetalis from "./pages/Dashboard/Management/SupportTicketDetalis/SupportTicketDetalis.jsx";
import PaymentsRiders from "./pages/Dashboard/PaymentsRiders/PaymentsRiders.jsx";
import DocumentsApi from "./pages/DocumentsApi/DocumentsApi.jsx";
import RutaUnBlock from "./utils/RutaUnBlock.jsx";
import ChannelToDrivers from "./pages/ChannelToDrivers/ChannelToDrivers.jsx";
import TvScrems from "./pages/TvScrems/TvScrems.jsx";
import PlannerManagerRoutes from "./pages/Dashboard/Rutas/Rutas.jsx";
import FleetCompany from "./pages/Dashboard/FleetCompany/FleetCompany.jsx";
import Reports from "./pages/Dashboard/Reports/Reports.jsx";
import ScannerAsignacionV1 from "./pages/ScannerDistribution/ScannerAsignacionV1.jsx";
import AdminsCompaniesEdith from "./pages/Dashboard/Management/AdminsCompanies/AdminsCompaniesEdith.jsx";
import ScannerDevoluciones from "./pages/ScannerDevoluciones/ScannerDevoluciones.jsx";
import DistributionsGoogleMapsPlans from "./pages/Dashboard/DistributionsGoogleMapsCompany/DistributionsGoogleMapsPlans.jsx";
import PlanningMap from "./components/MapsLeaflet/PlanningMap/PlanningMap.jsx";
import RouteEditorMap from "./components/MapsLeaflet/RouteEditorMap/RouteEditorMap.jsx";
import LiveOperationsMap from "./components/MapsLeaflet/LiveOperationsMap/LiveOperationsMap.jsx";
import PerformanceAnalysisMap from "./components/MapsLeaflet/PerformanceAnalysisMap/PerformanceAnalysisMap.jsx";
import DriverTrackingMap from "./components/MapsLeaflet/DriverTrackingMap/DriverTrackingMap.jsx";
import PageMantenimiento from "./pages/PageMantenimiento.jsx";

function App() {
  const dispatch = useDispatch();
  const [landScrenMenu, setLandScrenMenu] = useState(false);
  const { siteName } = useGlobalConfig();
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [showMinicart, setShowMinicart] = useState(false);
  // const [displayMount, setDisplayMount] = useState(true);
  // const [checkErrorInternet, setCheckErrorInternet] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [isSlow, setIsSlow] = useState(false);
  // const [speedCheckInterval, setSpeedCheckInterval] = useState(null);

  useTitle({ title: `${siteName} CHILE` });
  useSeo({
    description:
      "Empresa de logística y transporte comprometida con el desarrollo social en chile, que busca ayudar al medio ambiente por medio de la tecnología y la electromovilidad de nuestros vehículos, reduciendo la huella de carbono en la ciudad,Aumenta tus ventas a través de diferentes canales de delivery con ubicaciones estratégicas. Haz que tu negocio venda más por delivery apps, sin tiendas físicas en todas las comunas",
  });

  // useEffect(() => {
  //   const handleOnline = () => setIsOnline(true);
  //   const handleOffline = () => setIsOnline(false);

  //   window.addEventListener("online", handleOnline);
  //   window.addEventListener("offline", handleOffline);

  //   return () => {
  //     window.removeEventListener("online", handleOnline);
  //     window.removeEventListener("offline", handleOffline);
  //   };
  // }, []);

  // useEffect(() => {
  //   let intervalId;
  //   console.log("checkNetworkSpeed");
  //   const checkNetworkSpeed = () => {
  //     const startTime = new Date().getTime();
  //     fetch("/static/antenna-svgrepo-com.svg")
  //       .then(() => {
  //         const endTime = new Date().getTime();
  //         const duration = endTime - startTime;
  //         setIsSlow(duration > 1000);
  //       })
  //       .catch(() => {
  //         setIsSlow(true); // In case of error, assume the connection is slow
  //       });
  //   };

  //   const setCheckInterval = () => {
  //     let intervalTime = 1800000; //1800000; // 30 minutes
  //     if (!isOnline) intervalTime = 1000; // 1 second
  //     else if (isSlow) intervalTime = 30000; // 30 seconds

  //     intervalId = setInterval(() => {
  //       checkNetworkSpeed();
  //     }, intervalTime);
  //   };

  //   setCheckInterval();

  //   return () => {
  //     if (intervalId) clearInterval(intervalId);
  //   };
  // }, [isOnline, isSlow]);

  // const handleCheckConnection = () => {
  //   setCheckErrorInternet(true);
  // };

  // const getStatusColor = () => {
  //   if (!isOnline) return "red";
  //   if (isSlow) return "yellow";
  //   return "#15d715";
  // };

  //const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));

  var store = useSelector((store) => store);
  const usuarioStorage = store.usuario.user;
  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = () => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setFirebaseUser(user);
      } else {
        dispatch(cerrarSesionAccion());
        setFirebaseUser(false);
      }
      setIsLoading(false);
    });
    return unsubscribe;
  };

  if (isLoading) {
    //console.log(isLoading);
    return <LoadingSpiner></LoadingSpiner>;
  }

  const handleShowMinicart = () => {
    setShowMinicart(!showMinicart);
  };
  const handleclosedminicart = () => {
    setShowMinicart(!showMinicart);
  };

  const expamAdmin = () => {
    //console.log('expam Admin')
    setLandScrenMenu(!landScrenMenu);
  };

  return (
    <Router>
      <ScreenSizeContext>
        {/* <Box
          sx={{
            zIndex: 900,
            position: "fixed",
            display: "flex",
            float: "left",
            bottom: "5px",
            marginBottom: "5px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <IconButton
              onClick={() => handleCheckConnection()}
              style={{ color: getStatusColor(), m: 1 }}
              sx={{ fontSize: "13px" }}
            >
              <SvgIcon style={{ color: getStatusColor(), fontSize: 18 }}>
                {isOnline ? (
                  isSlow ? (
                    <WifiTetheringErrorRoundedIcon />
                  ) : (
                    <WifiTetheringIcon />
                  )
                ) : (
                  <WifiTetheringOffIcon />
                )}
              </SvgIcon>
              <Typography variant="body" style={{ color: getStatusColor() }}>
                {isOnline
                  ? isSlow
                    ? "Conexión lenta"
                    : "Online"
                  : "Desconectado de la red"}
              </Typography>
            </IconButton>
          </Box>
        </Box> */}
        <ScrollToTop />
        <div>
          <Box
            sx={{ width: "100%" }}
            className={
              landScrenMenu === true
                ? "landScreend-topcontainer"
                : "topbar topcontainer"
            }
          >
            {/* <MemoryMonitor></MemoryMonitor> */}
            <Navbar
              user={firebaseUser}
              handleshowminicart={handleShowMinicart}
            />
          </Box>
          {/* <span id="expanlayoutMenu">
              <Tooltip title="Extender Dashboard">
                <IconButton sx={{ ml: 1 }} onClick={expamAdmin}>
                  <GridViewIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </span> */}

          <div
            className={
              landScrenMenu === true
                ? "landScreend-bodycontainer"
                : "bodycontainer bodycontainerdahs"
            }
          >
            {showMinicart ? (
              <MiniCart handleclosedminicart={handleclosedminicart} />
            ) : (
              <></>
            )}

            <Routes>
              <Route element={<Inicio />} path="/" />
              {/* <Route component={Installer/>} path="/installer" exact={true}/>  */}
              <Route element={<Inicio />} path="/inicio" exact={true} />
              <Route element={<Traking />} path="/tracking" exact={true} />
              <Route element={<Nosotros />} path="/nosotros" />
              <Route
                element={<Traking />}
                path="/consulta-envio"
                exact={true}
              />
              <Route element={<Ecobicci />} path="/eco-bicci" exact={true} />
              <Route
                element={<Products />}
                user={firebaseUser}
                path="/productos"
                exact={true}
              />
              <Route element={<Productdetails />} path="/productos/:slugs" />
              <Route
                element={<Cart />}
                user={firebaseUser}
                path="/cart"
                exact={true}
              />
              <Route element={<Products />} path="/cart" exact={true} />
              <Route element={<Servicios />} path="/servicios" exact={true} />
              <Route element={<Info />} path="/info" exact={true} />
              <Route element={<Contacto />} path="/contacto" exact={true} />
              <Route element={<Postulaciones />} path="/flota" exact={true} />
              <Route
                element={<PostulacionesTypes />}
                path="/flota/:type"
                exact={true}
              />
              <Route element={<Login />} path="/login" exact={true} />
              <Route
                element={<Recuperarcuenta />}
                path="/recuperar-cuenta"
                exact={true}
              />
              <Route element={<Registro />} path="/registro" exact={true} />

              <Route
                element={<Terminosycondiciones />}
                path="/terminos"
                exact={true}
              />
              <Route
                path="/external/:offocusid"
                element={
                  <External
                    setLandScrenMenu={setLandScrenMenu}
                    landScrenMenu={landScrenMenu}
                  />
                }
                exact={true}
              />

              <Route
                path="/developers"
                element={
                  <DocumentsApi
                    setLandScrenMenu={setLandScrenMenu}
                    landScrenMenu={landScrenMenu}
                  />
                }
                exact={true}
              ></Route>

              <Route
                path="/tv"
                element={
                  <TvScrems
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                    setLandScrenMenu={setLandScrenMenu}
                    landScrenMenu={landScrenMenu}
                  />
                }
              >
                <Route
                  element={
                    <RutaProtegida
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />
                  }
                >
                  <Route
                    element={
                      <ChannelToDrivers
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="channel-hub"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <ChannelToDrivers
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="channel-to-drivers"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <ChannelToDrivers
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="distribution-maps"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    path="scannerdistribution-Piking"
                    element={
                      <ScannerDistributionV2
                        firebaseUser={firebaseUser}
                        usuarioStorage={usuarioStorage}
                        expamAdmin={expamAdmin}
                      />
                    }
                    exact={true}
                  />
                </Route>
              </Route>

              <Route
                path="/dashboard"
                element={
                  <Dashboard
                    setLandScrenMenu={setLandScrenMenu}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                    expamAdmin={expamAdmin}
                  />
                }
              >
                <Route
                  element={
                    <RutaProtegida
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />
                  }
                >
                  <Route
                    element={
                      <RutaUnBlock serviceId="whLnMDpa97">
                        <Administrators
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                        />
                      </RutaUnBlock>
                    }
                    path="management-administrators"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />

                  <Route
                    exact={true}
                    path="tablero"
                    element={
                      // <Tablero
                      //   usuarioStorage={usuarioStorage}
                      //   firebaseUser={firebaseUser}
                      // ></Tablero>
                      <PageMantenimiento></PageMantenimiento>
                    }
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    path="scannerdistribution/:id"
                    element={
                      <ScannerDistribution
                        setLandScrenMenu={setLandScrenMenu}
                        landScrenMenu={landScrenMenu}
                        firebaseUser={firebaseUser}
                        usuarioStorage={usuarioStorage}
                      />
                    }
                    exact={true}
                  />
                  <Route
                    path="scannerdistribution-Piking"
                    element={
                      <ScannerDistributionV2
                        setLandScrenMenu={setLandScrenMenu}
                        landScrenMenu={landScrenMenu}
                        firebaseUser={firebaseUser}
                        usuarioStorage={usuarioStorage}
                      />
                    }
                    exact={true}
                  />
                  <Route
                    path="incidencia-apelacion"
                    element={
                      <ScannerApelations
                        setLandScrenMenu={setLandScrenMenu}
                        landScrenMenu={landScrenMenu}
                        firebaseUser={firebaseUser}
                        usuarioStorage={usuarioStorage}
                      />
                    }
                    exact={true}
                  />
                  <Route
                    path="scannerdistribution-v2"
                    element={
                      <ScannerDistributionV2
                        setLandScrenMenu={setLandScrenMenu}
                        landScrenMenu={landScrenMenu}
                        firebaseUser={firebaseUser}
                        usuarioStorage={usuarioStorage}
                      />
                    }
                    exact={true}
                  />
                  <Route
                    path="scanner-asignacion"
                    element={
                      <ScannerAsignacionV1
                        setLandScrenMenu={setLandScrenMenu}
                        landScrenMenu={landScrenMenu}
                        firebaseUser={firebaseUser}
                        usuarioStorage={usuarioStorage}
                      />
                    }
                    exact={true}
                  />
                  <Route
                    path="gestion-devoluciones"
                    element={
                      <ScannerDevoluciones
                        setLandScrenMenu={setLandScrenMenu}
                        landScrenMenu={landScrenMenu}
                        firebaseUser={firebaseUser}
                        usuarioStorage={usuarioStorage}
                      />
                    }
                    exact={true}
                  />
                  <Route
                    element={
                      <DynamicRouteBicciDistributionByReport
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                        setLandScrenMenu={setLandScrenMenu}
                      />
                    }
                    path="route/manifest/:idRider/:idRoute"
                    exact={true}
                  />
                  <Route
                    element={
                      <PinPass
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                  >
                    <Route
                      element={
                        <DynamicRouteBicciDistribution
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                          setLandScrenMenu={setLandScrenMenu}
                        />
                      }
                      path="route/live/:idRider"
                      exact={true}
                    />
                    <Route
                      element={
                        <DynamicRouteBicci
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                          setLandScrenMenu={setLandScrenMenu}
                        />
                      }
                      path="route/live/:idRider/:idRoute"
                      exact={true}
                    />
                  </Route>
                  <Route
                    exact={true}
                    path="companies"
                    element={<Companies usuarioStorage={usuarioStorage} />}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    exact={true}
                    path="search"
                    element={
                      <SearchDoc
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      ></SearchDoc>
                    }
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Micuenta
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="mi-cuenta"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Direcciones
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="direcciones"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <AddUser
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="usuarios"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <BranchOffc
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="stores"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <RutaUnBlock serviceId="bDFs7Matzo">
                        <AdminsCompanies
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                        />
                      </RutaUnBlock>
                    }
                    path="management-admins-companies"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <RutaUnBlock serviceId="bDFs7Matzo">
                        <AdminsCompaniesEdith
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                        />
                      </RutaUnBlock>
                    }
                    path="management-admins-companies/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <AdminCompanies
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="management-companies"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <CompaniesSingleView
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="management-companies/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Roles
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="management-roles"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <RolesCapacidades
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="roles-permisos"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Permissions
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="management-permisos"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <ServicesActivations
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="management-services"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Users
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="users"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DevelopTests
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="develop-test-2024"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Maps
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="maps"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Drivers
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="drivers"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DriversDetails
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="drivers/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Drivers
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="drivers/:id/orders"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Vehicles
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="vehicles"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Dispatchs
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="creartedispatch"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <EagleEye
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="eagleeye" // previous
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <EagleEyeGoogleMaps
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="view-operations" // mapa para ordenes falabella
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DistributionsGoogleMapsCompany
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="view-plannig" // Es igual al = /dashboard/distribucion sustituir por planificacion-de-operaciones <PlanningMap>
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Fleet
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="fleet"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Planner
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="planner"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* <Route
                    element={
                      <DashPlannerEdit
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="planner/:id/edit"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DashPlannerAdd
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="planner/:id/dispatches"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  /> */}
                  <Route
                    element={
                      <PlannerDetailsId
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="planner/details/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Dispatchs
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="dispatchs"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Dispatchdetails
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="dispatch/details/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <EmailMokups
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="dispatch/details/:id/prewiew_email"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <PinPassAdmin
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                  >
                    <Route
                      path="dispatch/edithDetails/:id"
                      element={
                        <EditDispatchdetails
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                        />
                      }
                    />
                  </Route>

                  {/* <Route
                    element={
                      <ContextOrderCompany
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                  > */}
                  <Route
                    element={
                      <Guias
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Guias
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias/*"
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <ShippingPrint
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="impresion-de-etiquetas"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* <Route
                      element={
                        <TestingRouting
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                        />
                      }
                      path="TestingRouting"
                      exact={true}
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    /> */}

                  <Route
                    element={
                      <FleetCompany // Integrar a LiveOperationsMap
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="company-fleet" // integrar a monitoreo-de-operaciones -> Elininar -> Elininar Menus
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <PlannerManagerRoutes // sustituir por <RouteEditorMap
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="asignación-y-ruteo" // sustituir por /dashboard/asignacion-y-ajustes-de-rutas -> Elininar Menus
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Reports
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="reportes"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />

                  {/* Nuevos mapas */}
                  <Route
                    element={
                      <PlanningMap
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="planificacion-de-operaciones"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <RouteEditorMap
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="asignacion-y-ajustes-de-rutas"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <LiveOperationsMap
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="monitoreo-de-operaciones"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <PerformanceAnalysisMap
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="evaluacion-de-resultados"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DriverTrackingMap
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="ruta-en-vivo/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* Fin Nuevos mapas */}
                  {/* urls y modulos editor una ruta */}
                  <Route
                    element={
                      <Rutas
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="routes"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DashRoutes
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="route/details/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DashRoutesEdit
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="route/:id/edit"
                    exact={true}
                  />
                  <Route
                    element={
                      <DashRoutesNew
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="routes/new"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DashRoutesAdd
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="route/:id/dispatches"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* fin urls y modulos editor una ruta */}

                  {/* Mapas removidos 31012025 nueva api de ruteo   */}
                  <Route
                    element={
                      <DistributionsGoogleMapsPlans
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="map-plans" // todas las empresas con filtros
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />

                  <Route
                    element={
                      <DistributionsGoogleMapsCompany
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="distribucion" // Es igual al = /dashboard/view-plannig sustituir por planificacion-de-operaciones <PlanningMap>
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* Mapas removidos 31012025 nueva api de ruteo   */}
                  <Route
                    element={
                      <Express
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias/envios-express"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Express
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias/envios-importacion"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <SupportTicket
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="support-ticket"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <SupportTicketDetalis
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="support-ticket/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <GuiasFalabella
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guiasfalabella"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <GuiasFalabella
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guiasfalabella/list"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <GuiasFalabellaSD
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guiasfalabella-same-day"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <GuiasFalabellaHD
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guiasfalabella-home-delivery"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* <Route
                    element={
                      <GuiasFalabellaGeosort
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guiasfalabella-geosort"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  /> */}

                  {/* mover a menus administradores bicci */}
                  <Route
                    element={
                      <GuiasByFalabellaMonitor
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="metricsfalabella"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <GuiasByFalabellaMonitorExcepcions
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="excepcionesfalabella"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <PinPassAdminBilling
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                  >
                    {/* Menus Administracion y finanzas bicci */}
                    <Route
                      element={
                        <PaymentsRiders
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                        />
                      }
                      path="administracion"
                      exact={true}
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />

                    <Route
                      element={
                        <GuiasByCompanyBilling
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                        />
                      }
                      path="guias-facturacion"
                      exact={true}
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />
                    <Route
                      element={
                        <GuiasFacturacion
                          usuarioStorage={usuarioStorage}
                          firebaseUser={firebaseUser}
                        />
                      }
                      path="facturacion"
                      exact={true}
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />
                  </Route>

                  {/* Fin Menus Administracion y finanzas bicci */}

                  {/* Contratacion de servicios en desarllo , Automatizar la incorporacion de nuevas empresas */}
                  <Route
                    element={
                      <Serviciosdash
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="servicios"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Seleccionepack
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="servicios/seleccione_pack"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Seleccioneplan
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="servicios/seleccione_plan"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* Contratacion de servicios en desarllo  */}

                  {/* Menus a borrar */}
                  <Route
                    element={
                      <DashDispatchs
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias/details/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />

                  <Route
                    element={
                      <DashDispatchsEdit
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias/:id/edit"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DashDispatchsNew
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias/new"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <DashDispatchsAdd
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="guias/add"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />

                  <Route
                    element={
                      <Dispatchdetails
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path=":idcompanies/dispatchs/"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Dispatchdetails
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path=":idcompanies/dispatch/details/:id"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/* fins Menus a borrar */}

                  {/* Desarrollo Configuraciones de usuarios y empresas , administracion de usuarios , servicios y empresas */}
                  <Route
                    element={
                      <Configuraciones
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="settings"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />

                  <Route
                    element={
                      <Seguridad
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="seguridad"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  {/*fin Configuraciones */}

                  {/**  Central de mensaje , CMR , Herramientas Soporte , Agente IA */}
                  <Route
                    element={
                      <ReportBug
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="report-bugs"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <ReportBug
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="manager-bug"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Mensajes
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="manager-mensajes"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Soporte
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="soporte"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Soporte
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="manager-soporte"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                  <Route
                    element={
                      <Mensajes
                        usuarioStorage={usuarioStorage}
                        firebaseUser={firebaseUser}
                      />
                    }
                    path="mensajes"
                    exact={true}
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                </Route>
                {/**  Central de mensaje , CMR , Herramientas Soporte , Agente IA */}
                <Route element={<Page404 />} path="*"></Route>
              </Route>

              {/**  Pasarela de pagos , Clientes prepago , regitro de pagos y facturacion al cliente  */}
              <Route
                element={
                  <RutaPrivada
                    usuarioStorage={usuarioStorage}
                    firebaseUser={firebaseUser}
                  />
                }
              >
                <Route
                  element={
                    <Checkout
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />
                  }
                  path="/checkout/login"
                  exact={true}
                />
                <Route
                  element={
                    <Checkout
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />
                  }
                  path="/checkout/payments"
                  exact={true}
                  firebaseUser={firebaseUser}
                />
                <Route
                  element={
                    <Checkout
                      usuarioStorage={usuarioStorage}
                      firebaseUser={firebaseUser}
                    />
                  }
                  path="/checkout/desktopSingleReview"
                  exact={true}
                />
                <Route element={<Page404 />} path="*"></Route>
                {/**  Fin Pasarela de pagos , Clientes prepago , regitro de pagos y facturacion al cliente  */}
              </Route>

              <Route element={<Page404 />} path="*"></Route>
            </Routes>
          </div>
        </div>
        <Footer />
      </ScreenSizeContext>
    </Router>
  );
}

export default App;
