import React, { useState } from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import BtnAceceder from "../../Login/buttons/BtnAceceder";
import { ingresoUsuarioLogin } from "../../../redux/usuarioDucks";

const Fromloging = ({ state, user, dispatch, resperror }) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setError("Ingrese Email");
      return;
    }
    if (!pass.trim()) {
      setError("Ingrese un Password");
      return;
    }

    try {
      dispatch(ingresoUsuarioLogin(email, pass));
      state(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {state ? (
        <Box className="color-box ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16">
          <Typography variant="h5">
            Estas conectado con la cuenta: {user.email}
          </Typography>
        </Box>
      ) : (
        <Box className="alert alert-danger ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16">
          <Box className="ui-pdp-container__row ui-pdp-container__row--seller-info">
            <Box className="ui-box-component ui-box-component-pdp__visible--desktop">
              <Typography variant="h6">
                <InfoIcon color="success" /> Se requiere inicio de sesión:
              </Typography>

              <form onSubmit={handleSubmit}>
                {success && <Alert severity="success">{success}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
                {resperror && <Alert severity="error">{resperror}</Alert>}

                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                  <TextField
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{ startAdornment: <InfoIcon /> }}
                  />
                  <TextField
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    fullWidth
                    name="pass"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    InputProps={{ startAdornment: <InfoIcon /> }}
                  />
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Entrar
                </Button>

                <Box mt={2}>
                  <BtnAceceder />
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Fromloging;
