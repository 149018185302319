import React from "react";
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";
import useGlobalConfig from "../../../../../hooks/globalConfig";
import FrmContacto from "../../../../../components/formularios/FrmContacto/FrmContacto";

const Pos3 = () => {
  const { img4, img15, img2, img19, img16 } = useGlobalConfig();

  const services = [
    {
      title: "Despacho Punto a Punto",
      description:
        "Se realizan despachos de mercadería entre dos puntos dentro de la ciudad. Retiro de punto “A” con destino punto “B”.",
      img: img4,
    },
    {
      title: "Next Day Delivery",
      description: "Despachos en 24 horas desde la compra de tu cliente.",
      img: img15,
    },
    {
      title: "Same Day Delivery",
      description:
        "Entregas en el mismo día dentro de la ciudad con tiempos rápidos y eficientes.",
      img: img2,
    },
    {
      title: "Logística Reversa",
      description:
        "Retiramos las devoluciones y las llevamos a la tienda o centro de distribución.",
      img: img19,
    },
    {
      title: "Soluciones a Medida",
      description:
        "Cuéntanos tus necesidades y buscamos una solución en conjunto.",
      img: img16,
    },
  ];

  return (
    <Container maxWidth="lg">
      {/* Banner Principal */}
      <Box
        component="img"
        src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2F001Bannerhome.jpg?alt=media&token=f25440e1-4912-4f84-9a4d-53cad593054d"
        alt="Banner Bicci"
        sx={{
          width: "100%",
          height: "auto",
          borderRadius: 2,
          boxShadow: 3,
          mb: 4,
        }}
      />

      {/* Introducción */}
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" fontWeight="bold" color="primary">
          Somos una empresa comprometida con el desarrollo social
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Reducimos la huella de carbono en la ciudad con nuestra logística
          sustentable.
        </Typography>
      </Box>

      {/* Sección de Servicios */}
      <Grid container spacing={3} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: "100%", boxShadow: 4, borderRadius: 3 }}>
              <CardActionArea>
                <Box
                  component="img"
                  src={service.img}
                  alt={service.title}
                  sx={{
                    width: "100%",
                    minHeight: 200,
                    objectFit: "cover",
                    borderRadius: "3px 3px 0 0",
                  }}
                />
                <CardContent sx={{ textAlign: "center", px: 2 }}>
                  <Typography variant="h6" fontWeight="bold" color="primary">
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ padding: 1 }}>
        <FrmContacto></FrmContacto>
      </Box>
    </Container>
  );
};

export default Pos3;
