import React from "react";
import { useSelector } from "react-redux";

import LoginEmail from "../LoginEmail/LoginEmail";
import useTitle from "../Seo/useTitle";
import useGlobalConfig from "../../hooks/globalConfig";
import { useNavigate } from "react-router-dom";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";

const Login = () => {
  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} login` });
  const activo = useSelector((store) => store.usuario.activo);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (activo) {
      navigate("/dashboard/tablero");
    } else {
      navigate("/login");
    }
  }, [activo, navigate]);

  return (
    <ContainerViewComponent className="bodyFroms" id="bodyFroms">
      <div className="mt-5 text-center"></div>
      <LoginEmail />
    </ContainerViewComponent>
  );
};

export default Login;
