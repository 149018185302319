import React from "react";
import useGlobalConfig from "../../../hooks/globalConfig";
import MenuGeneralEmpresa from "./IntegratiosMenus/MenuGeneralEmpresa";
import MenuGeneralOperacion from "./IntegratiosMenus/MenuGeneralOperacion";
import MenuGeneralBodega from "./IntegratiosMenus/MenuGeneralBodega";
import useTitle from "../../Seo/useTitle";
import MenuGeneralMyAcount from "./IntegratiosMenus/MenuGeneralMyAcount";
import MenuFalabella from "./IntegratiosMenus/MenuFalabella";
import { Box } from "@mui/material";

export const DashboardSidebarAdminBicciExpress = (props) => {
  const { img5 } = useGlobalConfig();

  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} Dashboard` });

  return (
    <div className="sidebar-body">
      <div id="brandsLogo">
        <Box
          component="img"
          src={img5}
          alt="Banner Footer"
          sx={{
            height: "auto",
            maxWidth: "100px",
            backgroundColor: "#fff",
            borderRadius: "100%",
          }}
        />
      </div>
      <h3 className="btn-sidebarLink alignItemscentertitle">
        Administrador Bicci
      </h3>
      <hr className="sidebar-hr"></hr>

      <MenuGeneralEmpresa onNavigate={props.onNavigate}></MenuGeneralEmpresa>

      <MenuGeneralOperacion
        onNavigate={props.onNavigate}
      ></MenuGeneralOperacion>

      <MenuGeneralBodega onNavigate={props.onNavigate}></MenuGeneralBodega>

      <MenuFalabella onNavigate={props.onNavigate}> </MenuFalabella>

      <MenuGeneralMyAcount
        onNavigate={props.handleCloseDrawer}
      ></MenuGeneralMyAcount>
    </div>
  );
};
