import React from "react";

import Pos3 from "../postcontent/inicio/Pos3/Pos3";
import useTitle from "../../../components/Seo/useTitle";
import useSeo from "../../../components/Seo/useSeo";
import useGlobalConfig from "../../../hooks/globalConfig";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";
const Servicios = () => {
  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} Servicios` });
  useSeo({
    description:
      "Empresa de logística y transporte Somos una empresa comprometida con el desarrollo social y con la reducción de la huella de carbono en la ciudad.",
  });

  return (
    <div>
      <ContainerViewComponent className="bodycontent">
        <div>
          <Pos3 />
        </div>
      </ContainerViewComponent>
    </div>
  );
};

export default Servicios;
