import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { collection, doc, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { db } from "../../firebase";
import { registrarCambioEnOrden } from "../../redux/accionsRegister";

const RUTReceptorForm = ({
  orderDetails,
  orderID,
  usuarioStorage,
  setOrderDetails,
}) => {
  const dispatch = useDispatch();
  const [userDniReceptor, setUserDniReceptor] = useState("");
  const [hasTypedDni, setHasTypedDni] = useState(false);
  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  const saveUserDniReceptor = async (e) => {
    e.preventDefault();
    let userDniReceptorClear = userDniReceptor.trim();

    let userDniReceptorClearRemove = userDniReceptorClear
      .replace(/\s+/g, "") // Elimina espacios
      .replace(/[^\w]/gi, "") // Elimina caracteres especiales
      .replace(/K/gi, "0") // Reemplaza "K" o "k" por cero
      .slice(0, 9); // Limita a 9 caracteres

    let data = {
      date: new Date(),
      lastValue: orderDetails.userDniReceptor,
      newValue: userDniReceptorClearRemove,
      orderId: orderID,
      type: "infoAdd",
      userId: usuarioStorage.uid,
      var: "userDniReceptor",
    };
    dispatch(registrarCambioEnOrden(data));

    const orderRef3 = doc(collectionRef, `${orderID}`);
    try {
      await updateDoc(orderRef3, {
        userDniReceptor: userDniReceptorClearRemove,
      });
      setOrderDetails((prev) => ({
        ...prev,
        userDniReceptor: userDniReceptorClearRemove,
      }));
      setUserDniReceptor("");
      setHasTypedDni(false);
    } catch (error) {
      console.log("Error guardando userDniReceptor", error);
    }
  };

  return orderDetails ? (
    <Box
      component="form"
      onSubmit={saveUserDniReceptor}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 1,
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold">
        RUT del Receptor: {orderDetails.userDniReceptor}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          value={userDniReceptor}
          onChange={(e) => {
            setUserDniReceptor(e.target.value);
            setHasTypedDni(true);
          }}
          placeholder="Ingrese RUT"
          variant="outlined"
          sx={{ mt: 1, width: "50%" }}
          inputProps={{ maxLength: 12 }} // Opcional si quieres limitar desde el input también
        />

        <Button
          variant="contained"
          sx={{
            mt: 1,
            backgroundColor: "#591E8F",
            color: "#fff",
            width: "50%",
          }}
          type="submit"
          disabled={!hasTypedDni}
        >
          Guardar RUT
        </Button>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default RUTReceptorForm;
