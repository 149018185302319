import React from "react";
import { Box, Button, Card, CardHeader, Divider } from "@mui/material";
import { NavLink } from "react-router-dom";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import ContainerViewComponent from "../../ContainerViewComponent/ContainerViewComponent";
import FormComponent from "./FormComponent";

const FrmRidersRetail = () => {
  const dispatch = useDispatch();

  // States
  const resperror = null;
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [warning, setWarning] = React.useState("");

  // User Typing
  const [rutvalida, setrutvalida] = React.useState("");
  const [driverDocument, setDriverDocument] = React.useState("");
  const [validatorAlert, setValidatorAlert] = React.useState("");
  const [validatorSuccess, setValidatorSuccess] = React.useState("");

  const [assigned, setAssigned] = React.useState("");
  const [propietary, setPropietary] = React.useState("");
  const [driverRut, setDriverRut] = React.useState("");
  const [hasLicence, setHasLicence] = React.useState(false);
  const [driverAddress, setDriverAddress] = React.useState("");
  const [driverNumAddress, setDriverNumAddress] = React.useState("");
  const [driverRegion, setDriverRegion] = React.useState("");
  const [driverCity, setDriverCity] = React.useState("");
  const [driverComuna, setDriverComuna] = React.useState("");
  const [driverCountry, setDriverCountry] = React.useState("CHILE");
  const [driverCountryCode, setDriverCountryCode] = React.useState("CL");
  const [driverAdressLat, setDriverAdressLat] = React.useState("");
  const [driverAdressLng, setDriverAdressLng] = React.useState("");
  const [driverAdressNumAddressTemp, setDriverAdressNumAddressTemp] =
    React.useState("");
  const [VehicleType, setVehicleType] = React.useState("");
  const [driverCodeFolder, setDriverCodeFolder] = React.useState("");
  const [driverContacName, setDriverContacName] = React.useState("");
  const [driverContacEmail, setDriverContacEmail] = React.useState("");
  const [driverAdressPhone, setDriverAdressPhone] = React.useState("+56");
  const [typeDocumentSelected, setTypeDocumentSelected] = React.useState("");
  const [driverCountryOrigen, setDriverCountryOrigen] = React.useState("");
  const [blockBtn, setBlockBtn] = React.useState(null);
  const [thanksyou, setthanksyou] = React.useState(null);
  const typeDocument = [
    { key: "af", value: "rut", text: "Rut Chileno" },
    { key: "ax", value: "passport", text: "Pasaporte" },
    { key: "al", value: "document", text: "Otro Documento" },
  ];
  const licenciaConducir = [
    { key: "af", value: "true", text: "Si Poseo licencia de conducir chilena" },
    { key: "ax", value: "false", text: "No, poseo licencia" },
  ];
  const tipoDeVehiculo = [
    { value: "Bicicleta Normal", text: "Bicicleta Normal" },
    { value: "Bicicleta - Motor Mosquito", text: "Bicicleta - Motor Mosquito" },
    { value: "Bicicleta Eléctrica", text: "Bicicleta Eléctrica" },
    { value: "Bici-Moto", text: "Bici-Moto" },
    { value: "Scooter Eléctrico", text: "Scooter Eléctrico" },
    { value: "Motocicleta Normal", text: "Motocicleta Normal" },
    { value: "Motocicleta Eléctrica", text: "Motocicleta Eléctrica" },
    { value: "Auto", text: "Auto" },
    { value: "Otro", text: "Otro" },
  ];

  var typeDocumentRef = React.useRef();
  const enviarRespuesta = (e) => {
    e.preventDefault();
    if (!driverContacName.trim()) {
      setError("Ingrese su nombre ");
      return;
    }
    if (!driverAdressPhone.trim()) {
      setError("Ingrese su telefono ");
      return;
    }
    if (driverAdressPhone.length < 12) {
      // console.log('En el campo teléfono faltan numeros')
      setError("En el campo teléfono faltan numeros");
      return;
    }
    if (driverAdressPhone.length > 12) {
      // console.log('En el campo teléfono existen numeros extra')
      setError("El campo teléfono existen numeros extra");
      return;
    }
    if (!typeDocumentSelected.trim()) {
      setError("Seleccione el tipo de documento de identidad");
      return;
    }
    if (typeDocumentSelected === "rut") {
      if (!rutvalida.trim()) {
        setError("Ingrese Rut valido");
        return;
      }
    } else {
      if (!driverDocument.trim()) {
        setError("Ingrese numero de documento de identidad");
        return;
      }
    }

    if (!driverAddress.trim()) {
      setError("Ingrese su dirección");
      return;
    }
    if (!driverNumAddress.trim()) {
      setError("Ingrese numero de dirección si no aplica escriba NA");
      return;
    }
    if (!driverRegion.trim()) {
      setError("Ingrese region de empresa");
      return;
    }
    if (!driverCity.trim()) {
      setError("Ingrese ciudad donde se ubica la empresa");
      return;
    }
    if (!driverComuna.trim()) {
      setError("Ingrese comuna donde se ubica  empresa");
      return;
    }
    if (!driverRegion.trim()) {
      setError("Ingrese region donde se ubica  empresa");
      return;
    }
    if (!driverCountry.trim()) {
      setError("Ingrese país donde se ubica  empresa");
      return;
    }
    if (hasLicence === undefined) {
      setError("Seleccione si tiene o no lecencia de conducir chilena");
      return;
    }

    if (!VehicleType.trim()) {
      setError("Seleccione tipo de vehículo");
      return;
    }

    setSuccess("Guardando Datos...");
    guardarNewContactRider();
  };

  //const guardarNewContactRider = () => {}
  React.useEffect(() => {}, [thanksyou]);

  React.useEffect(() => {}, [typeDocumentSelected]);

  const guardarNewContactRider = React.useCallback(async () => {
    const data = {
      propietary: "ebiex",
      driverDniType: typeDocumentSelected,
      driverRut: rutvalida,
      driverDocument: driverDocument,
      driverCodefolder: driverCodeFolder,
      driverAddress: driverAddress,
      driverNumAddress: driverNumAddress,
      driverRegion: driverRegion,
      driverCity: driverCity,
      driverComuna: driverComuna,
      driverCountry: driverCountry,
      driverCountryCode: driverCountryCode,
      driverContacName: driverContacName,
      driverContacEmail: driverContacEmail,
      driverAdressPhone: driverAdressPhone,
      adressGeoNumAddressLat: driverAdressLat,
      adressGeoNumAddressLng: driverAdressLng,
      driverAcuerdo: "",
      driverImgRut: "",
      driverImgDocumentos: "",
      driverImgPatente: "",
      driverImgDocSii: "",
      stausDocument: "",
      isActive: false,
      suportTikets: [],
      createdate: Date.now(),
      createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
      cuntryOrigen: "",
      hasLicence: hasLicence,
      driverCountryOrigen: driverCountryOrigen,
      VehicleType: VehicleType,
    };
    setError(null);

    var dataFroms = {
      fecha: data.createdateAdd,
      nombre: data.driverContacName,
      email: data.driverContacEmail,
      licencia: data.hasLicence,
      rut: data.driverRut,
      telefono: data.driverAdressPhone,
      documento: data.driverDocument,
      tipoDocumento: data.driverDniType,
      nacionalidad: data.driverCountryOrigen,
      dirección: data.driverAddress,
      comuna: data.driverComuna,
      paisResidencia: data.driverCountry,
      vehiculo: data.VehicleType,
      modalidad: "Retail",
    };

    try {
      let formData = new FormData();
      for (var key in dataFroms) {
        formData.append(key, dataFroms[key]);
      }

      // console.log("fetch");
      // console.log(data);
      fetch(`${process.env.REACT_APP_BICCIEXPRESS_API_SHEET_RIDERS}`, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      })
        //   .then((response) => console.log("Success!", response))
        .catch((error) => console.error("Error!", error.message));

      // dispatch(createNewContactRider(data));
      // dispatch(wirteDocument(data)); //excell
      // dispatch(setEmailConfirm(data)); //Nodemailer
      // dispatch(setEmailAlert(data)); //pushOterPage
      // pushthanksyou;
      setthanksyou("Gracias hemos recibido tu registro");
    } catch (error) {
      // console.log(error);
    }
  });

  const handleTypeDocument = (e) => {
    console.log(e);
    switch (e) {
      case "document":
        setTypeDocumentSelected("document");
        setDriverDocument("");
        break;
      case "passport":
        setTypeDocumentSelected("passport");
        setDriverDocument("");
        break;
      case "rut":
        setTypeDocumentSelected("rut");
        setDriverDocument("");
        break;
      default:
        break;
    }
  };

  const handleLicenseResponse = (e) => {
    if (e === "true") {
      setHasLicence(true);
    }
    if (e === "false") {
      setHasLicence(false);
    }
  };

  const handleVehicleResponse = (e) => {
    setVehicleType(e);
  };

  function rutvalidator(e) {
    var valor;
    var cuerpo;
    var dv;
    var rut;
    if (e.length <= 0) {
      setValidatorAlert("Campo es obligatorio");
      setValidatorSuccess(null);
      setrutvalida(e);
      setDriverDocument(e);
      return;
    }
    valor = clean(e);
    cuerpo = valor.slice(0, -1);
    dv = valor.slice(-1).toUpperCase();

    rut = format(e);
    if (cuerpo.length < 7) {
      setValidatorAlert("Campo debe ser mayor a 7 Caracteres");
      setValidatorSuccess(null);
      setrutvalida(rut);
      setDriverDocument(rut);
      return;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    var dvEsperado;
    dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    if (dvEsperado != dv) {
      setValidatorAlert("Rut incorrecto");
      setValidatorSuccess(null);
    } else {
      setValidatorSuccess("Rut correcto");
      setValidatorAlert(null);
      function delay() {
        setValidatorSuccess(null);
      }
      setTimeout(delay, 5000);
    }

    setrutvalida(rut);

    var clear = replaceAll(rut, ".", "");
    setDriverCodeFolder(clear);
    setDriverDocument(clear);
    return;
  }

  function ottterDocumento(e) {
    let valor;
    if (e.length <= 0) {
      setValidatorAlert("Campo es obligatorio");
      setValidatorSuccess(null);
      setrutvalida(e);
      setDriverDocument(e);
      return;
    }

    valor = clean(e);

    if (valor.length < 7) {
      setValidatorAlert("Campo debe ser mayor a 7 Caracteres");
      setValidatorSuccess(null);
      setDriverDocument(valor);
      return;
    }

    console.log(valor);
    setValidatorAlert(null);
    setDriverCodeFolder(valor + "-" + typeDocumentSelected);
    setDriverDocument(valor);
    return;
  }

  function replaceAll(text, busca, reemplaza) {
    while (text.toString().indexOf(busca) != -1)
      text = text.toString().replace(busca, reemplaza);
    return text;
  }
  function format(rut) {
    rut = clean(rut);
    var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (var i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "" + result;
    }
    return result;
  }

  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  return (
    <div>
      {thanksyou === null ? (
        <ContainerViewComponent>
          <Card className="typeMiniformsCard">
            <CardHeader
              subheader={
                <div>
                  <span> </span>
                </div>
              }
              title={
                <div>
                  <h3>formulario</h3>
                </div>
              }
            />
            <Divider />

            <FormComponent
              enviarRespuesta={enviarRespuesta}
              success={success}
              error={error}
              warning={warning}
              resperror={resperror}
              validatorSuccess={validatorSuccess}
              validatorAlert={validatorAlert}
              setDriverContacName={setDriverContacName}
              driverContacName={driverContacName}
              setDriverAdressPhone={setDriverAdressPhone}
              driverAdressPhone={driverAdressPhone}
              setDriverContacEmail={setDriverContacEmail}
              driverContacEmail={driverContacEmail}
              handleTypeDocument={handleTypeDocument}
              typeDocument={typeDocument}
              typeDocumentSelected={typeDocumentSelected}
              driverDocument={driverDocument}
              rutvalidator={rutvalidator}
              ottterDocumento={ottterDocumento}
              setDriverCountryOrigen={setDriverCountryOrigen}
              driverCountryOrigen={driverCountryOrigen}
              setDriverNumAddress={setDriverNumAddress}
              driverNumAddress={driverNumAddress}
              setDriverRegion={setDriverRegion}
              driverRegion={driverRegion}
              setDriverCity={setDriverCity}
              driverCity={driverCity}
              setDriverComuna={setDriverComuna}
              driverComuna={driverComuna}
              setDriverCountry={setDriverCountry}
              driverCountry={driverCountry}
              handleLicenseResponse={handleLicenseResponse}
              licenciaConducir={licenciaConducir}
              handleVehicleResponse={handleVehicleResponse}
              tipoDeVehiculo={tipoDeVehiculo}
              driverAddress={driverAddress}
              setDriverAddress={setDriverAddress}
              driverAdressNumAddressTemp={driverAdressNumAddressTemp}
              setDriverAdressNumAddressTemp={setDriverAdressNumAddressTemp}
              blockBtn={blockBtn}
            ></FormComponent>
            <Divider />
          </Card>
        </ContainerViewComponent>
      ) : (
        <ContainerViewComponent>
          <Card className="typeMiniformsCard">
            <div className="alert alert-success m-2">{thanksyou}</div>
            <NavLink className="btn btn-ebiex-dark m-2" to="/flota">
              {" "}
              ¡Regresar!
            </NavLink>
          </Card>
        </ContainerViewComponent>
      )}
    </div>
  );
  function Search({
    setDriverAddress,
    setDriverNumAddress,
    setDriverRegion,
    setDriverCity,
    setDriverComuna,
    setDriverCountry,
    setDriverCountryCode,
    setDriverAdressLat,
    setDriverAdressLng,
    setDriverAdressNumAddressTemp,
  }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
      try {
        const results = await getGeocode({ address });
        for (let i = 0; i < results[0].address_components.length; i++) {
          let elementAdress = results[0].address_components[i];
          switch (elementAdress["types"][0]) {
            case "street_number":
              setDriverAdressNumAddressTemp(elementAdress.long_name);
              break;
            case "route":
              setDriverAddress(elementAdress.long_name);
              break;
            case "locality":
              setDriverComuna(elementAdress.long_name);
              break;
            case "administrative_area_level_3":
              break;
            case "administrative_area_level_2":
              setDriverCity(elementAdress.long_name);
              break;
            case "administrative_area_level_1":
              setDriverRegion(elementAdress.long_name);
              break;
            case "country":
              setDriverCountry(elementAdress.long_name);
              break;
            case "postal_code":
              break;
            default:
              break;
          }
        }
        const { lat, lng } = await getLatLng(results[0]);
        setDriverAdressLat(lat);
        setDriverAdressLng(lng);
      } catch (error) {
        // // console.log("😱 Error: ", error);
        // // console.log(element)
      }
    };

    return (
      <div className="search-box-ebiexCurve">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="formGroupStyleCurve"
            id="imputSearchFromToCurve"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Busca tu ubicación"
          />

          <ComboboxPopover className="comboboxList-ebiex-from">
            <ComboboxList key={Math.random().toString()}>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                    className="dropdown-item dropdown-ebiex"
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
};

export default FrmRidersRetail;
