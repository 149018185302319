import React from "react";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const Configuraciones = () => {
  return (
    <div>
      <ContainerViewComponent className="dashcontent">
        <div>
          Estamos mejorando el sitio en este momento.
          <h1>Managenement Configuraciones </h1>
        </div>
      </ContainerViewComponent>
    </div>
  );
};

export default Configuraciones;
