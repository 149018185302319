import React, { useState } from "react";
import { db } from "../../../firebase";
import { useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import {
  Card,
  Divider,
  CardHeader,
  Box,
  Grid2,
  Button,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FadeInOut from "../../../components/animations/FadeInOut";
import useGetDataCollaboratorByCompany from "../../../hooks/useGetDataCollaboratorByCompany";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import Chip from "@mui/material/Chip";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CellTowerIcon from "@mui/icons-material/CellTower";
import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashIconsResumenUser from "../../../components/Dashboard/DashIconsResumenUser/DashIconsResumenUser";
import Popup from "../../../components/Popup";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const Users = (props) => {
  const [companyId, setcompanyId] = useState("");
  const [error, setError] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [userInvited, setUserInvited] = useState({
    username: "",
    userLastName: "",
    companyName: "",
    phone: "",
    companyId: "",
    email: "",
  });
  const [isvalid, setValid] = useState(null);

  const [usersRows, setUsersRows] = useState("");

  const { userdata, data, loadingDatosdeCliente, errorDatosdeCliente } =
    useObtenerDatosdeCliente(props.usuarioStorage.uid);

  const { collaboratorDataByCompany, listCollaboratorDataByCompany } =
    useGetDataCollaboratorByCompany(companyId);

  const [objUser, setobjUser] = React.useState({
    title: "showPopupWiewuser",
    texto: "Lorem ipsum dolor.",
  });

  const [showPopup, setshowPopup] = useState(false);

  const [typePop, setTypePop] = useState("usercompany");

  const [cantListItems, setcantListItems] = React.useState({
    Disponibles: 0,
    Invitados: 0,
    Bloqueados: 0,
  });

  const listItems = [
    { id: "Disponibles" },
    { id: "Invitados" },
    { id: "Bloqueados" },
  ];

  useEffect(() => {
    console.log("data Usuarios");
    console.log(data);
    console.log(userdata);
    if (userdata) {
      setUserInvited({
        ...userInvited,
        companyName: data.companyName,
        companyId: data.companyID,
      });
      setcompanyId(data.companyID);
    }
  }, [data && userdata]);

  useEffect(() => {
    if (collaboratorDataByCompany && listCollaboratorDataByCompany) {
      getBicciUsersData();
    }
  }, [collaboratorDataByCompany && listCollaboratorDataByCompany]);

  const updateRows = async () => {
    try {
      const dataUserRows = await getDocs(
        collection(
          db,
          `${process.env.REACT_APP_COL_COMPANIES}/${userdata.userCompanyID}/companyCollaborator`
        )
      );

      let dataRows = dataUserRows.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(dataRows);

      let dataRowsBeforeDelete = dataRows.filter(
        (item) => item.isDeleted === false
      );
      let usersActive = dataRowsBeforeDelete.filter(
        (item) => item.isblockedAgreement === true
      );
      let userImvite = dataRowsBeforeDelete.filter(
        (item) => item.isInvited === true
      );
      let userNoBloq = dataRowsBeforeDelete.filter(
        (item) => item.isblockedAgreement === false
      );

      setcantListItems({
        Disponibles: userNoBloq.length,
        Invitados: userImvite.length,
        Bloqueados: usersActive.length,
      });

      setUsersRows(dataRowsBeforeDelete);
    } catch (error) {
      //console.log(error)
    }
  };

  const columnsUsers = [
    {
      field: "username",
      headerName: "Nombre",
      sortable: false,
      width: 150,
    },
    {
      field: "email",
      headerName: "Rider Email",
      width: 200,
      editable: true,
    },
    {
      field: "estado",
      headerName: "Estado",
      sortable: false,
      width: 250,
      renderCell: (usersRows) => {
        // //console.log(usersRows.row.active)
        return (
          <>
            <Chip
              label={
                usersRows.row.isActive === true ? "Operativo   " : "No Activado"
              }
              icon={
                <AccountCircleIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              style={{
                color: "#010101",
                backgroundColor:
                  usersRows.row.isActive === true ? "#1ef708" : "No Activado",
              }}
            />
            <Chip
              label={
                usersRows.row.isblockedAgreement === true
                  ? "Activo "
                  : "Pausado"
              }
              icon={
                <CellTowerIcon
                  style={{
                    color: "#fff",
                  }}
                />
              }
              variant="outlined"
              style={{
                color: "#010101",
                backgroundColor:
                  usersRows.row.isblockedAgreement === true
                    ? "#1ef708"
                    : "#dadbda",
              }}
            />
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 300,
      renderCell: (usersRows) => {
        //      // //console.log(usersRows)
        return (
          <>
            <Chip
              label="Ver detalles"
              onClick={showPopupWiewUser}
              id={usersRows.row.id}
              value={usersRows.row.id}
              variant="outlined"
              style={{
                color: "#fff",
                backgroundColor: "#591e8f",
              }}
            />
            <Chip
              label={
                usersRows.row.isblockedAgreement === true
                  ? "Pausar"
                  : "habilitar"
              }
              onClick={
                usersRows.row.isblockedAgreement === true
                  ? handleUnBloqPropsUserRows
                  : handleBloqPropsUserRows
              }
              id={usersRows.row.id}
              value={usersRows.row.id}
              icon={<BlockIcon />}
              variant="outlined"
              style={{
                color: "#010101",
                backgroundColor:
                  usersRows.row.isblockedAgreement === true
                    ? "#edf708"
                    : "#1ef708",
              }}
            />
            <Chip
              label="Eliminar"
              onClick={handleDeletedPropsUsersRows}
              id={usersRows.row.id}
              value={usersRows.row.id}
              deleteIcon={<DeleteIcon />}
              variant="outlined"
              style={{
                color: "#fff",
                backgroundColor: "#d5182b",
              }}
            />
          </>
        );
      },
    },
  ];

  const showPopupWiewUser = (e) => {
    //console.log('WIEW ' + e.currentTarget.id )
    var searchData = usersRows.find((item) => item.id === e.currentTarget.id);

    //setTypePop('usercompany')
    setobjUser({
      title: searchData.id,
      texto: "",
      data: searchData,
    });
    togglePopup();
  };

  const handleUnBloqPropsUserRows = (e) => {
    //console.log('BLOQ ' + e.currentTarget.id )
    const docRefUser = db
      .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
      .doc(userdata.userCompanyID)
      .collection("companyCollaborator");
    docRefUser.doc(e.currentTarget.id).update({ isblockedAgreement: false });
    updateRows();
  };

  const handleBloqPropsUserRows = (e) => {
    //console.log('BLOQ ' + e.currentTarget.id )
    const docRefUser = db
      .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
      .doc(userdata.userCompanyID)
      .collection("companyCollaborator");
    docRefUser.doc(e.currentTarget.id).update({ isblockedAgreement: true });
    updateRows();
  };

  const handleDeletedPropsUsersRows = (e) => {
    //console.log('DELETE ' + e.currentTarget.id )
    const docRefUser = db
      .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
      .doc(userdata.userCompanyID)
      .collection("companyCollaborator");
    docRefUser.doc(e.currentTarget.id).update({ isDeleted: true });
    updateRows();
  };

  const getBicciUsersData = () => {
    console.log(collaboratorDataByCompany);
    console.log(listCollaboratorDataByCompany);
    setcantListItems(listCollaboratorDataByCompany);
    setUsersRows(collaboratorDataByCompany);
  };

  const invitedAdmin = async (e) => {
    e.preventDefault();
    //console.log('Agregar inviatcion')
    if (!email.trim()) {
      setError("Ingrese un email valido");
      return;
    }

    let internalValid = {
      validacion: "",
      get validacionGet() {
        return this.validacion;
      },
      set validacionSet(value) {
        this.validacion = value;
      },
    };
    // Solo con respuesta de usuario.
    const docRefUser = db
      .collection(`${process.env.REACT_APP_COL_USERS}`)
      .doc(userdata.userCompanyID)
      .collection("companyCollaborator");

    const docRefCompany = db
      .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
      .doc(userdata.userCompanyID)
      .collection("companyCollaborator");

    const notificationUser = db
      .collection(`${process.env.REACT_APP_COL_MSJ}`)
      .doc(userInvited.email)
      .collection("system");
    const notificationAmin = db
      .collection(`${process.env.REACT_APP_COL_MSJ}`)
      .doc(userdata.userCompanyID)
      .collection("system");

    const AdminSendUserInvitacion = db
      .collection(`${process.env.REACT_APP_COL_MSJ}`)
      .doc(userInvited.email)
      .collection(userdata.userCompanyID);

    // const docRefMsjUnveted =  db.collection(`${process.env.REACT_APP_COL_MSJ}`).doc(userInvitedData.userUid).collection('system')

    let userInvitedData = {
      data: {
        email: email,
        createdAt: Date.now(),
        createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
        acepteDate: "", //response
        acepteAdd: "", //response
        userUid: "", //response
        wasAcceptedAgreement: false, //response
        isblockedAgreement: false, //config
        isDeleted: false,
        isInvited: true,
      },
      get dataUserInvited() {
        return this.data;
      },
      get email() {
        return this.data.email;
      },
      get userUid() {
        return this.data.userUid;
      },
      set email(email) {
        this.data.email = email;
      },
      set userUid(id) {
        this.data.userUid = id;
      },
    };

    let companyCollaborator = {
      data: {
        InvitedBy: props.usuarioStorage.uid,
        email: email,
        createdAt: Date.now(),
        createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
        companyId: companyId,
        company_roll: "company_ops",
        uid: "", //response
        acepteDate: "", //response
        acepteAdd: "", //response
        wasAcceptedAgreement: true, //response
        isblockedAgreement: false, //config
        isActive: false, //config
        isDeleted: false,
        isInvited: true,
        username: userInvited.username,
        userLastName: userInvited.userLastName,
        companyName: userInvited.companyName,
        phone: userInvited.phone,
      },
      get datacompanyCollaborator() {
        return this.data;
      },
      get email() {
        return this.data.email;
      },
      get companyid() {
        return this.data.companyId;
      },
      set email(email) {
        this.data.email = email;
      },
      set companyid(id) {
        this.data.companyId = id;
      },
    };

    let validate = await docRefCompany.doc(userInvited.email).get();
    if (validate.data()) {
      //console.log('el usuario fue invitado')
      setError("El usuario ya posee una invitacion");
      return;
    } else {
      //console.log('se puede invitar')
      addUserByAdmin(
        userInvitedData.data,
        companyCollaborator.data,
        userInvited,
        docRefCompany
      );
      createmensajeByAdmin(
        userInvitedData.data,
        companyCollaborator.data,
        userInvited,
        AdminSendUserInvitacion
      );
      createmensajeBySystem(
        userInvitedData.data,
        companyCollaborator.data,
        userInvited,
        notificationUser,
        notificationAmin
      );
      clearFrom();
    }
  };

  //amedina@bicciexpress.cl
  const addUserByAdmin = async (
    invitacionData,
    companyCollaborator,
    userInvited,
    docRefCompany
  ) => {
    try {
      await docRefCompany
        .doc(companyCollaborator.email)
        .set(companyCollaborator);
    } catch (error) {
      //console.log(error)
    }
  };

  const createmensajeByAdmin = async (
    invitacionData,
    companyCollaborator,
    userInvited,
    AdminSendUserInvitacion
  ) => {
    //console.log('createmensajeByAdmin')
    //console.log(invitacionData)
    //console.log(companyCollaborator)
    //console.log(userInvited)
    try {
      let mensaje = {
        from: companyCollaborator.InvitedBy,
        to: userInvited.email,
        displayName: userInvited.companyName,
        subject: "Te invitamos a ser colaborador de nuestra empresa",
        text: "Al aceptar tu perfil tendrá disponible operar con nosotros.",
        send: true,
        delivery: false,
        read: false,
        sendDateText: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
        dateSend: Date.now(),
        dateDelivery: "",
        dateRead: "",
        type: "userInvited",
        bonding: "",
        document: invitacionData,
      };
      await AdminSendUserInvitacion.doc().set(mensaje);
    } catch (error) {}
  };
  const createmensajeBySystem = async (
    invitacionData,
    companyCollaborator,
    userInvited,
    notificationUser,
    notificationAmin
  ) => {
    //console.log('createmensajeBySystem')
    //console.log(invitacionData)
    //console.log(companyCollaborator)
    //console.log(userInvited)
    try {
      let mensaje = {
        from: "system",
        to: userInvited.email,
        subject: "Te invitaron a ser colaborador de una empresa",
        text: "Tu perfil se configurara con la nueva empresa al aceptar, la invitacion.",
        send: true,
        delivery: false,
        read: false,
        sendDateText: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
        dateSend: Date.now(),
        dateDelivery: "",
        dateRead: "",
        companyId: userInvited.companyId,
        type: "system",
        bonding: "",
      };
      await notificationUser.doc().set(mensaje);
    } catch (error) {}
    try {
      let mensaje = {
        from: "system",
        to: companyCollaborator.InvitedBy,
        subject: "Has enviado una invitación de colaborador de tu empresa",
        text: `Se invitó a ${userInvited.email} como operador, puedes revertir la accion eliminando el usuario`,
        send: true,
        delivery: false,
        read: false,
        sendDateText: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
        dateSend: Date.now(),
        dateDelivery: "",
        dateRead: "",
        companyId: userInvited.companyId,
        type: "system",
        bonding: "",
      };
      await notificationAmin.doc().set(mensaje);
    } catch (error) {}
  };

  const toggleShowEditNew = (e) => {
    setShowEdit(!showEdit);
  };

  const togglePopup = () => {
    setshowPopup(!showPopup);
  };

  const handlechage = (e) => {
    const validateEmail = (email) => {
      return email.match(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      );
    };

    const validate = (email) => {
      if (validateEmail(email)) {
        setError(null);
        return true;
      } else {
        setError("Ingrese un email valido");
        return false;
      }
    };

    let result = validate(e.target.value);
    if (e.target.value === "") {
      setError(null);
    }

    setValid(result);
    setEmail(e.target.value.trim());
    setUserInvited({ ...userInvited, email: e.target.value.trim() });
  };

  const handleChangeFields = (e) => {
    //console.log(e.target.value)
    //console.log(e.target.name)
    if (e.target.name === "username" || e.target.name === "userLastName") {
      function valida_usuario(name) {
        const conditions = [
          {
            badPattern: /[^a-zA-Z0-9\s]+$/,
            errorMessage: "Solo letras y espacios",
          },
          {
            badPattern: /^(\w{0,5}|\w{13,})$/,
            errorMessage: "La longitud no cumple con el minimo",
          },
        ];

        const badPattern = conditions.find((condition) =>
          condition.badPattern.test(name)
        );

        if (badPattern) {
          setError(badPattern.errorMessage);
        } else {
          setError(null);
        }

        return !badPattern;
      }

      let result = valida_usuario(e.target.value);

      //console.log(result)
    }

    if (e.target.name === "phone") {
      let codigo = "+56";
      if (!e.target.value.startsWith(codigo)) {
        setError("Ingrese el codigo del pais " + codigo);
      } else {
        setError(null);
      }
    }

    if (e.target.value === "") {
      setError(null);
    }

    setUserInvited({ ...userInvited, [e.target.name]: e.target.value });
  };

  const clearFrom = (e) => {
    setUserInvited({
      ...userInvited,
      username: "",
      userLastName: "",
      companyName: "",
      phone: "",
      companyId: "",
      email: "",
    });
    setEmail("");
    setShowEdit(false);
  };

  return (
    <>
      <Grid2 celled="internally" id="expresscuentaContent">
        <h2 className="h2-withe">Gestion de usuarios Super Admin </h2>

        <Grid2>
          <Grid2
            computer={8}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms">
              <CardHeader
                subheader="Si el colaborador no está registrado, le enviaremos una invitación para activar su cuenta. Es necesario completar los datos del operador para su activación"
                title="Invitar nuevos usuarios"
              />
              {showEdit === true ? (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={toggleShowEditNew}
                  >
                    <HighlightOffIcon className="dashIcon-red" />
                    Cancelar
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "flex-center",
                      outline: "none",
                      m: 1,
                    }}
                    color="primary"
                    variant="contained"
                    onClick={toggleShowEditNew}
                  >
                    <AddCircleIcon className="dashIcon-green" />
                    Agregar usuarios
                  </Button>
                </div>
              )}
              <Divider />
              <FadeInOut show={showEdit} duration={500}>
                <ContainerViewComponent
                  className="formcontent"
                  id="micuentafromIni"
                >
                  {error ? (
                    <div>
                      <span className="alert alert-warning m-2">{error}</span>
                    </div>
                  ) : null}
                  <hr></hr>
                  {userInvited ? (
                    <Box
                      component="form"
                      onSubmit={invitedAdmin}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: 3,
                      }}
                    >
                      {/* Email y Teléfono */}
                      <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Correo Electrónico"
                            type="email"
                            name="email"
                            onChange={handlechage}
                            value={email}
                            variant="outlined"
                          />
                        </Grid2>
                        <Grid2 xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Teléfono"
                            type="text"
                            name="phone"
                            onChange={handleChangeFields}
                            value={userInvited.phone}
                            variant="outlined"
                          />
                        </Grid2>
                      </Grid2>

                      {/* Nombre y Apellido */}
                      <Grid2 container spacing={2}>
                        <Grid2 xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Nombre"
                            type="text"
                            name="username"
                            onChange={handleChangeFields}
                            value={userInvited.username}
                            variant="outlined"
                          />
                        </Grid2>
                        <Grid2 xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Apellido"
                            type="text"
                            name="userLastName"
                            onChange={handleChangeFields}
                            value={userInvited.userLastName}
                            variant="outlined"
                          />
                        </Grid2>
                      </Grid2>

                      {/* Nombre de Empresa (Solo lectura) */}
                      <TextField
                        fullWidth
                        label="Nombre de Empresa"
                        type="text"
                        name="companyName"
                        value={userInvited.companyName}
                        variant="outlined"
                        disabled
                      />

                      {/* Botón de envío */}
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Enviar
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </ContainerViewComponent>
              </FadeInOut>

              <Divider />
            </Card>
          </Grid2>

          <Grid2
            computer={8}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms typeMiniformsdrivers">
              <CardHeader title="Resumen" />
              <DashIconsResumenUser
                items={listItems}
                cant={cantListItems}
              ></DashIconsResumenUser>
            </Card>
          </Grid2>

          <Grid2
            computer={16}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms">
              <CardHeader subheader="subheader" title="Usuarios Actuales" />

              <Divider />
              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                <hr></hr>

                <div>
                  <div>
                    {" "}
                    <h3 className="m-1">Usuarios</h3>
                  </div>
                  <Box
                    sx={{
                      height: "350px",
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {usersRows ? (
                      <DataGrid
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "bicciNumber", sort: "desc" }],
                          },
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={usersRows}
                        columns={columnsUsers}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        //onClick ={checkboxSelection}
                      />
                    ) : (
                      <>
                        <LinearProgress />
                      </>
                    )}
                  </Box>
                </div>
              </ContainerViewComponent>

              <Divider />
            </Card>
            <div></div>
          </Grid2>
        </Grid2>
      </Grid2>
      {showPopup ? (
        <Popup
          contenido={objUser}
          togglePopup={togglePopup}
          type={typePop}
        ></Popup>
      ) : null}
    </>
  );
};

export default Users;
