import * as turf from "@turf/turf";

export const assignOrdersToPolygons = async (
  orders = [],
  polygonsData = {}, // Ahora recibimos el archivo completo, no solo el array de polígonos
  optimizeOptions = {}
) => {
  // 📌 Validación de datos de entrada
  const missingFields = [];

  if (!Array.isArray(orders)) {
    missingFields.push("orders (array de órdenes)");
  }
  if (
    !polygonsData ||
    typeof polygonsData !== "object" ||
    !Array.isArray(polygonsData.features)
  ) {
    missingFields.push(
      "polygonsData (objeto con la propiedad 'features' como array)"
    );
  }
  if (!optimizeOptions || typeof optimizeOptions !== "object") {
    missingFields.push("optimizeOptions (objeto con opciones de optimización)");
  }

  if (missingFields.length > 0) {
    console.error("⛔ Faltan los siguientes datos de entrada:", missingFields);
    console.error("🔹 Estructura esperada:", {
      orders: [
        {
          id: "string",
          toAddressLocation: { _lat: "número", _long: "número" },
        },
      ],
      polygonsData: {
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [0, 0],
                  [1, 1],
                  [1, 0],
                  [0, 0],
                ],
              ],
            },
            properties: { Name: "string" },
          },
        ],
      },
      optimizeOptions: {
        applySectorization: "booleano",
        respectZones: "booleano",
      },
    });
    return {};
  }

  const ordersByPolygon = {};

  // 📌 Extraer polígonos del archivo recibido
  const polygons = polygonsData.features;

  // 📌 Si no hay polígonos y `applySectorization` está **desactivado**, agrupamos todo en "General"
  if (polygons.length === 0 || !optimizeOptions.applySectorization) {
    console.warn(
      "⚠️ No hay polígonos válidos o la sectorización está desactivada. Se agrupan todas las órdenes."
    );
    ordersByPolygon["General"] = orders; // Todas las órdenes quedan en "General"
    return ordersByPolygon;
  }

  // 📌 Asignación de órdenes a polígonos
  orders.forEach((order) => {
    if (
      !order.toAddressLocation ||
      typeof order.toAddressLocation._lat !== "number" ||
      typeof order.toAddressLocation._long !== "number"
    ) {
      console.warn(
        `⚠️ Orden con ID ${
          order.id || "desconocido"
        } tiene una ubicación inválida, omitiendo.`
      );
      return;
    }

    let assigned = false;

    for (const feature of polygons) {
      const { geometry, properties } = feature;

      // 📌 Validamos que sea un polígono con coordenadas
      if (
        geometry &&
        geometry.type === "Polygon" &&
        Array.isArray(geometry.coordinates)
      ) {
        const polygon = turf.polygon(geometry.coordinates);
        const point = turf.point([
          order.toAddressLocation._long,
          order.toAddressLocation._lat,
        ]);

        if (turf.booleanPointInPolygon(point, polygon)) {
          const polygonName =
            properties?.Name?.trim() || "Polígono Desconocido";

          if (!ordersByPolygon[polygonName]) ordersByPolygon[polygonName] = [];
          ordersByPolygon[polygonName].push(order);

          assigned = true;
          break; // Si ya asignó la orden, no sigue iterando
        }
      }
    }

    // 📌 Manejo de órdenes fuera de polígonos según `respectZones`
    if (!assigned) {
      if (optimizeOptions.respectZones) {
        console.warn(
          `❌ Orden ${order.id} descartada por no estar en un polígono.`
        );
      } else {
        if (!ordersByPolygon["General"]) ordersByPolygon["General"] = [];
        ordersByPolygon["General"].push(order);
      }
    }
  });

  return ordersByPolygon;
};
