import React, { useEffect, useState, useRef, useContext } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import * as XLSX from "xlsx";
import { db } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Avatar,
  Grid,
  LinearProgress,
  Switch,
  Typography,
  SvgIcon,
  CircularProgress,
  Chip,
  Select,
  MenuItem,
  Snackbar,
  Button,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { MapVectoriales } from "../../utils/Polygons/VectorialSatelite";
import DownloadIcon from "@mui/icons-material/Download";
import { registerPickerActions } from "../../redux/accionesOrders";
import { useOrders } from "../../hooks/useOrdersProviders/OrdersContext";
import Moment from "moment";
import {
  globalsStatesCompanys,
  translateStatesBicciFilter,
} from "../../utils/objects/translateStates";
import { format } from "date-fns";
import useGetDataRidersActive from "../../hooks/getDataRidersActive";
import useGetDataOrdersByRiderID from "../../hooks/getDataOrdersByRiderID";
import { addDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { assingMultipleDriver } from "../../redux/accionesDrivers";
import MuiAlert from "@mui/material/Alert";
import { Opacity } from "@mui/icons-material";
import useGetDataRiderByCompany from "../../hooks/getDataRiderByCompany";

const isTestMode = false;
const userRider = false;
const companyId = "77528497-8";

const ScannerAsignacionV1 = (props) => {
  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const response = useSelector((store) => store.usuarioDriver.response);
  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const { companiesData, mergedOrders } = useOrders({
    initDateInitial,
    endDateInitial,
  });

  const { listRiderData, loadRidersData, errorRidersData } =
    useGetDataRidersActive({
      initDateInitial,
    });

  const { ridersDataByCompany } = useGetDataRiderByCompany(
    companyId,
    userRider
  ); // companiesData.id

  const [listRiderCompany, setListRiderCompany] = useState([]);

  // console.log(listRiderData, loadRidersData, errorRidersData);
  const [selectedRiderData, setSelectedRiderData] = useState("");
  const [selectedRider, setSelectedRider] = useState("");
  const [ridersData, setRidersData] = useState([]);

  const { dataResult } = useGetDataOrdersByRiderID(selectedRider);

  const dispacth = useDispatch();

  const [barcodeDataImput, setBarcodeDataInput] = useState("");
  const [serahrCodeDataImput, setSearchBarcodeDataInput] = useState("");
  const [waitingForScan, setWaitingForScan] = useState(false);
  const [dataSearch, setDataSearch] = React.useState([]);
  const [inputDataMethod, setInputDataMethod] = useState(true);
  const [inputDataMethodAsign, setInputDataMethodAsign] = useState(true);
  const [progressValue, setProgressValue] = React.useState(0);
  const [prefixes, setPrefixes] = React.useState([]);
  const [bicciZone, setBicciZone] = React.useState("No registra");
  const [selectedCompanyPrefix, setSelectedCompanyPrefix] =
    React.useState(null);

  const [orderList, setOrderList] = useState([]);
  const [listId, setListId] = useState("");
  const [localSize, setLocalSize] = React.useState({
    productSize: "",
    productHeight: "",
    productLength: "",
    productWidth: "",
  });

  const collRegidterRef = collection(
    db,
    process.env.REACT_APP_COL_PICKER_REGISTER
  );

  const input1Ref = useRef(null);

  useEffect(() => {
    if (companiesData) {
      const prefixes = companiesData
        .filter((item) => item.prefix)
        .map((item) => item.prefix);
      setPrefixes(prefixes);
    }
  }, [companiesData]);

  useEffect(() => {
    if (selectedRiderData && selectedRiderData.bicciNumber) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses son 0-indexados
      const day = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}${month}${day}`;

      setListId(`${formattedDate}${selectedRiderData.bicciNumber}`);
    }
  }, [selectedRiderData]);

  useEffect(() => {
    if (serahrCodeDataImput.length > 3 && listId && prefixes.length > 0) {
      //   console.log(serahrCodeDataImput);
      findOrdesrDispachtScanner(serahrCodeDataImput, listId, prefixes);
    }
  }, [serahrCodeDataImput]);

  useEffect(() => {
    function isPointInPolygon(point, polygon) {
      var x = point[0],
        y = point[1];
      var inside = false;
      for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0],
          yi = polygon[i][1];
        var xj = polygon[j][0],
          yj = polygon[j][1];

        var intersect =
          yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    }

    async function findPolygonForPoint(point, geoJson) {
      //console.log("findPolygonForPoint", point);
      for (var feature of geoJson.features) {
        if (isPointInPolygon(point, feature.geometry.coordinates[0])) {
          //   console.log(feature.properties.Name);
          dataSearch.BicciZone = feature.properties.Name;
          setBicciZone(feature.properties.Name);
        }
      }
      return null; // No contiene ningún polígono
    }

    var geoJson = MapVectoriales;

    if (dataSearch) {
      //  console.log(dataSearch);

      if (dataSearch.toAddressLocation) {
        let point = [
          dataSearch.toAddressLocation._long
            ? dataSearch.toAddressLocation._long
            : dataSearch.toAddressLocation.latitude,
          dataSearch.toAddressLocation._lat
            ? dataSearch.toAddressLocation._lat
            : dataSearch.toAddressLocation.latitude,
        ];
        findPolygonForPoint(point, geoJson);

        setLocalSize({
          ...localSize,
          productHeight: dataSearch.productHeight,
          productLength: dataSearch.productLength,
          productWidth: dataSearch.productWidth,
          productSize: dataSearch.productSize,
          productVolume: dataSearch.productVolume,
        });

        if (props.usuarioStorage) {
          if (!isTestMode) {
            //console.log(" OK -> Accion Assing");
            if (inputDataMethodAsign) {
              //console.log(" dispacht Assing");
              let ordersForAccions = [dataSearch.id];
              var dataAsid = {
                data: {
                  driverID: selectedRider,
                  orderIDs: ordersForAccions,
                },
              };

              dispacth(assingMultipleDriver(dataAsid));
            } else {
              //  console.log(" Esperando presione el boton");
            }
          } else {
            //  console.log("Simulando registerPickerActions ok");
          }
        }

        let updateDocData = {
          //   id: idWithF,
          orderID: dataSearch.id,
          sobrante: false,
          listId: listId,
          deleted: false,
          BicciZone: dataSearch.BicciZone ? dataSearch.BicciZone : "",
          date: new Date(),
        };
        // llamar a funcion que no retrase el flujo de la interfaz

        if (props.usuarioStorage) {
          let data = { id: serahrCodeDataImput };
        }
      } else {
        if (!isTestMode) {
          if (dataSearch.sobrante) {
            updateTodayPicking(dataSearch);
            //   console.log("registrar sobrante fuera de picking");
          }
        } else {
          //   console.log("Simulando updateTodayPicking ok");
        }
      }
    }
  }, [dataSearch]);

  useEffect(() => {
    if (ridersDataByCompany) {
      const combinedArray = [...listRiderData, ...ridersDataByCompany].reduce(
        (acc, current) => {
          const foundIndex = acc.findIndex((item) => item.id === current.id);
          if (foundIndex === -1) {
            // Si no existe en el acumulador, agregarlo
            acc.push(current);
          }
          return acc;
        },
        []
      );
      setListRiderCompany(combinedArray);
    }
  }, [listRiderData, ridersDataByCompany]);

  useEffect(() => {
    let riderData = listRiderCompany.filter(
      (item) => item.id === selectedRider
    );
    setSelectedRiderData(riderData[0]);
  }, [selectedRider]);

  useEffect(() => {
    // console.log(dataResult);
  }, [dataResult]);

  useEffect(() => {
    // Enfocar el campo de entrada al cargar la página
    setWaitingForScan(true);
    input1Ref.current.focus();
    // Agregar un manejador de eventos para hacer clic en el cuerpo
    document.body.addEventListener("click", handleBodyClick);

    // Limpia el manejador de eventos al desmontar el componente
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    if (response) {
      try {
        if (response.result) {
          console.log(response.result);
          if (response.result.status === "success") {
            setMessage(response.result.message);
            setSeverity("success");
          } else {
            // // En caso de que 'response' contenga un objeto de error de axios
            // const errorMessage =
            //   response.response.data?.message ||
            //   response.message ||
            //   "Error desconocido";
            // setMessage(`Error en la asignación: ${errorMessage}`);
            // setSeverity("error");
            setMessage(`Error en la asignación.: ${response.error.message}`);
            setSeverity("error");
          }
        } else {
          setMessage(`Error en la asignación.: ${response.error.message}`);
          setSeverity("error");
        }
        setOpen(true);
      } catch (error) {
        console.log(error);
      }
    }
  }, [response]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const AsignarmeFolio = () => {
    let ordersForAccions = [dataSearch.id];
    var dataAsid = {
      data: {
        driverID: selectedRider,
        orderIDs: ordersForAccions,
      },
    };

    dispacth(assingMultipleDriver(dataAsid));
  };

  const handleFocus = () => {
    setWaitingForScan(true);
  };

  const handleBlur = () => {
    setWaitingForScan(false);
  };

  const findOrdesrDispachtScanner = async (
    serahrCodeDataImput,
    listId,
    prefixes
  ) => {
    setDataSearch(null);

    const collections = [
      process.env.REACT_APP_COL_USERS_ORDERS,
      // process.env.REACT_APP_COL_ORDERS,
      // process.env.REACT_APP_COL_FALABELLA_ORDERS,
    ];

    //let idWithF = "R" + serahrCodeDataImput;

    const tryGetDoc = async (collection, id) => {
      const orderRef = doc(db, collection, id);
      const docSnapshot = await getDoc(orderRef);
      return docSnapshot.exists() ? docSnapshot.data() : null;
    };

    const tryQueryByAttributes = async (collectionName, attribute, value) => {
      const colRef = collection(db, collectionName);
      const q = query(colRef, where(attribute, "==", value));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      }
      return null;
    };

    try {
      setProgressValue(10);
      let foundDocumentData = null;
      let orderId = serahrCodeDataImput;

      // Si hay un prefijo seleccionado, lo agregamos al ID de búsqueda
      if (selectedCompanyPrefix) {
        orderId = selectedCompanyPrefix + serahrCodeDataImput;
      }

      // Buscar en mergedOrders con el ID (posiblemente con prefijo)
      foundDocumentData = mergedOrders.find((order) => order.id === orderId);

      // Si no se encontró y hay prefijos, intentar con cada uno
      if (!foundDocumentData) {
        for (let prefix of prefixes) {
          orderId = prefix + serahrCodeDataImput;

          // Intentar encontrar por order.id
          foundDocumentData = mergedOrders.find(
            (order) => order.id === orderId
          );

          if (foundDocumentData) {
            setSelectedCompanyPrefix(prefix);
            break;
          }

          // Si no se encontró por order.id, intentar por internalReference
          if (!foundDocumentData) {
            foundDocumentData = mergedOrders.find((order) => {
              if (order.internalReference) {
                // Obtener la primera parte de internalReference antes del guión
                let internalRefFirstPart =
                  order.internalReference.split("-")[0];
                return (
                  internalRefFirstPart === serahrCodeDataImput ||
                  internalRefFirstPart === prefix + serahrCodeDataImput
                );
              }
              return false;
            });

            if (foundDocumentData) {
              setSelectedCompanyPrefix(prefix);
              break;
            }
          }
        }
      }

      // Si aún no se encuentra, buscar sin prefijo en internalReference
      if (!foundDocumentData) {
        foundDocumentData = mergedOrders.find((order) => {
          if (order.internalReference) {
            let internalRefFirstPart = order.internalReference.split("-")[0];
            return internalRefFirstPart === serahrCodeDataImput;
          }
          return false;
        });
      }

      // Si se encontró en el contexto, actualizamos y salimos de la función
      if (foundDocumentData) {
        setDataSearch(foundDocumentData);
        setProgressValue(100);
        return;
      }

      // Primero intentar con el prefijo seleccionado por el usuario
      if (selectedCompanyPrefix) {
        let idWithPrefix = selectedCompanyPrefix + serahrCodeDataImput;

        for (let collection of collections) {
          foundDocumentData = await tryGetDoc(collection, idWithPrefix);
          if (foundDocumentData) {
            break;
          }
          setProgressValue(20);
        }

        if (foundDocumentData) {
          setDataSearch(foundDocumentData);

          setProgressValue(100);
          return;
        }
      }

      for (let collection of collections) {
        for (let prefix of prefixes) {
          let idWithPrefix = prefix + serahrCodeDataImput;

          // Busca el documento con el prefijo correspondiente
          let documentData = await tryGetDoc(collection, idWithPrefix);
          if (documentData) {
            setSelectedCompanyPrefix(prefix);
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(20);

          // Si no se encuentra, busca sin el prefijo
          documentData = await tryGetDoc(collection, serahrCodeDataImput);
          if (documentData) {
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(30);

          documentData = await tryQueryByAttributes(
            collection,
            "id",
            serahrCodeDataImput
          );
          if (documentData) {
            setSelectedCompanyPrefix(prefix);
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(40);

          // documentData = await tryQueryByAttributes(
          //   collection,
          //   "int_client_soc",
          //   serahrCodeDataImput
          // );
          // if (documentData) {
          //   foundDocumentData = documentData;
          //   break;
          // }

          // documentData = await tryQueryByAttributes(
          //   collection,
          //   "int_f12",
          //   serahrCodeDataImput
          // );
          // if (documentData) {
          //   foundDocumentData = documentData;
          //   break;
          // }

          // documentData = await tryQueryByAttributes(
          //   collection,
          //   "int_lpn",
          //   serahrCodeDataImput
          // );
          if (documentData) {
            foundDocumentData = documentData;
            break;
          }
        }

        if (foundDocumentData) {
          setDataSearch(foundDocumentData);

          setProgressValue(100);
          break;
        }
      }

      if (!foundDocumentData) {
        // No se encontró el documento en ninguna colección ni por los atributos específicos.
        let updateDocData = {
          orderID: serahrCodeDataImput,
          sobrante: true,
          listId: listId,
          deleted: false,
          BicciZone: "Data no registrada",
          date: new Date(),
        };
        setDataSearch(updateDocData);

        setProgressValue(100);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateTodayPicking = async (updateDocData) => {
    try {
      let docRef;
      if (!isTestMode) {
        docRef = await addDoc(collRegidterRef, updateDocData);
        await setDoc(
          docRef,
          {
            ...updateDocData,
            id: docRef.id,
            BicciZone: updateDocData.BicciZone,
          },
          { merge: true }
        );
      } else {
        //  console.log("Simulando updateTodayPicking", updateDocData);
      }
      if (!isTestMode) {
        setOrderList((prevOrderList) => {
          const updatedOrderList = [
            { id: docRef.id, ...updateDocData },
            ...prevOrderList,
          ];
          const sortedOrderList = updatedOrderList.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          return sortedOrderList;
        });
      }
      const orderRef = doc(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS,
        updateDocData.orderID
      );
      const docSnapshot = await getDoc(orderRef);

      if (docSnapshot.exists()) {
        try {
          if (!isTestMode) {
            await updateDoc(orderRef, { isPickedUp: true });
          } else {
            // console.log("Simulando updateTodayPicking updateDocData");
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const listenscanner = () => {
    input1Ref.current.focus();
  };

  const handleBodyClick = (e) => {
    // Verifica si el clic se realizó fuera del campo de entrada
    if (!input1Ref.current.contains(e.target)) {
      // Si el clic fue fuera del campo, vuelve a enfocar el campo de entrada
      input1Ref.current.focus();
    }
  };

  const typingTimer = useRef(null);

  const doneTypingInterval = 700; // tiempo en milisegundos para esperar después de la última entrada

  const handleBarcodeScan = (data) => {
    setBarcodeDataInput(data);

    clearTimeout(typingTimer.current); // Reinicia el temporizador cada vez que se detecta una nueva entrada
    typingTimer.current = setTimeout(() => {
      setSearchBarcodeDataInput(data); // Solo se ejecuta si no hay nuevas entradas después de 500ms
    }, doneTypingInterval);

    if (inputDataMethod === true) {
      setTimeout(() => {
        //console.log("Procesando...");
        setBarcodeDataInput("");
        input1Ref.current.focus();
      }, 500);
    }
  };

  const handleChange = (event) => {
    setInputDataMethod(event.target.checked);
    setBarcodeDataInput("");
    setSearchBarcodeDataInput("");
  };

  const handleChangeMethodAsign = (event) => {
    setInputDataMethodAsign(event.target.checked);
    setBarcodeDataInput("");
    setSearchBarcodeDataInput("");
  };

  const handleClikManifest = () => {
    // console.log("descargar reporte");
    // console.log(orderList);
    const matrizReporte = [
      ["Código", "sobrante", "Lista", "Fecha"],
      ...orderList.map((dato) => [
        dato.orderID,
        dato.sobrante,
        dato.listId,
        format(dato.date.seconds * 1000, "dd-MM-yyyy hh:mm:ss"),
      ]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(libro, `Picking ${listId}.xlsx`);
  };

  const handleClikDowLoadCircuit = () => {};

  const handleClearCacheAndReload = () => {
    window.location.reload();
  };

  const handleCangeRider = () => {};

  const styleBox = {
    border: "1px solid #591e8f",
    p: 1,
    borderRadius: "3px",
  };

  return (
    <Grid sx={{ width: "100%", flex: "row" }}>
      <Grid sx={{ width: "100%", backgroundColor: "#f8f8f8", display: "flex" }}>
        <Box
          sx={{
            objectFit: "contain",
            width: "30%",
            height: "100%",
            padding: "1em",
            marginBottom: "0px",
          }}
        >
          <Card
            sx={{
              maxWidth: 900,
              height: "90%",
              maxHeight: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2px",
              marginBottom: "5%",
            }}
          >
            <Box sx={{ display: "flex", backgroundColor: "#F8F8F8" }}>
              {companiesData ? (
                <>
                  <Select
                    value={selectedCompanyPrefix}
                    onChange={(e) => setSelectedCompanyPrefix(e.target.value)}
                    displayEmpty
                    sx={{ width: "90%", m: 1 }}
                  >
                    <MenuItem value="">
                      <em>Seleccionar Empresa</em>
                    </MenuItem>
                    {companiesData.map((company) => {
                      if (company.name) {
                        return (
                          <MenuItem key={company.prefix} value={company.prefix}>
                            {company.name}{" "}
                            {<strong> - {company.prefix}</strong>}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <IconButton onClick={handleClearCacheAndReload}>
                    <RefreshIcon />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ display: "flex", backgroundColor: "#F8F8F8" }}>
              {listRiderData ? (
                <>
                  <Select
                    value={selectedRider}
                    onChange={(e) => setSelectedRider(e.target.value)}
                    displayEmpty
                    sx={{ width: "90%", m: 1 }}
                  >
                    <MenuItem value="">
                      <em>Seleccionar Conductor</em>
                    </MenuItem>

                    {listRiderData && listRiderData.length > 0
                      ? listRiderData.map((rider) => {
                          if (rider.name && rider.bicciNumber) {
                            return (
                              <MenuItem
                                key={rider.id}
                                value={rider.id}
                                sx={{ backgroundColor: "#b0f1d7" }}
                              >
                                {rider.dni} <strong> - {rider.name}</strong>
                              </MenuItem>
                            );
                          }
                          return null; // Por si algún rider no tiene nombre
                        })
                      : null}

                    {ridersDataByCompany && ridersDataByCompany.length > 0
                      ? ridersDataByCompany.map((rider, index) => {
                          if (rider.name && rider.bicciNumber) {
                            return (
                              <MenuItem
                                key={index}
                                value={rider.id}
                                sx={{ background: "#dadaff" }}
                              >
                                {rider.dni} <strong> - {rider.name}</strong>
                              </MenuItem>
                            );
                          }
                          return null; // Por si algún rider no tiene nombre
                        })
                      : null}
                  </Select>
                </>
              ) : (
                <></>
              )}
            </Box>

            <CardHeader
              title={
                <Box>
                  <Box
                    sx={{
                      borderRadius: "3px",
                      border: "1px solid grey",
                      p: 1,
                      m: 1,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {selectedRiderData ? (
                      <>
                        {" "}
                        <Box>
                          <Avatar
                            alt=""
                            src={
                              selectedRiderData && selectedRiderData.avatar
                                ? selectedRiderData.avatar
                                : ""
                            }
                          ></Avatar>
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: 700 }}
                          >
                            {selectedRiderData && selectedRiderData.bicciNumber
                              ? selectedRiderData.bicciNumber
                              : ""}
                          </Typography>
                        </Box>
                        <Box>
                          {" "}
                          <div>
                            <Switch
                              checked={inputDataMethodAsign}
                              onChange={handleChangeMethodAsign}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                color: waitingForScan ? "#aef3c3" : "#e58888",
                              }}
                            />
                            <Typography
                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                              color="textPrimary"
                              variant="body"
                            >
                              {inputDataMethodAsign ? (
                                <span>Autoasignación</span>
                              ) : (
                                <span>Con Confirmación</span>
                              )}
                            </Typography>
                          </div>
                        </Box>
                      </>
                    ) : (
                      <span>No hay coductor seleccionado</span>
                    )}
                  </Box>
                </Box>
              }
              subheader={<></>}
            ></CardHeader>
            <CardContent
              sx={{
                p: 1,
                m: 1,
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              <Box>
                <Box sx={styleBox}>
                  <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                    Folios asignados actualmente a tu usuario :
                  </Typography>
                  <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
                    {dataResult ? dataResult.length : 0} Folios
                  </Typography>
                </Box>
                {console.log(selectedRider)}
                {selectedRider !== "9aYi0DWl4wNW2m4QevqNrvyIjVJ2" &&
                selectedRider !== "" ? (
                  <>
                    <Box sx={styleBox}>
                      <IconButton
                        onClick={() => handleClikManifest()}
                        style={{ m: 1 }}
                        sx={{ fontSize: "13px", borderRadius: "3px" }}
                      >
                        <SvgIcon style={{ fontSize: 18, color: "blue" }}>
                          <DownloadIcon />
                        </SvgIcon>

                        <Typography variant="body">
                          Imprimir Manifiresto
                        </Typography>
                      </IconButton>
                    </Box>
                    <Box sx={styleBox}>
                      <IconButton
                        onClick={() => handleClikDowLoadCircuit()}
                        style={{ m: 1 }}
                        sx={{ fontSize: "13px", borderRadius: "3px" }}
                      >
                        <SvgIcon style={{ fontSize: 18, color: "blue" }}>
                          <DownloadIcon />
                        </SvgIcon>

                        <Typography variant="body">Exportar Circuit</Typography>
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
              <Box sx={styleBox}>
                {selectedRider !== "9aYi0DWl4wNW2m4QevqNrvyIjVJ2" ? (
                  <>
                    {dataResult ? (
                      <>
                        {dataResult.map((item) => (
                          <Card
                            key={item.id}
                            sx={{
                              marginTop: "10px",
                              padding: "10px",
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <span className="bigText">{item.id}</span>
                              </Box>
                              <Box sx={{ ml: 1, color: "blie" }}>
                                {item.status <= 6 ? "Pendiente" : "Excepción"}
                              </Box>
                            </Box>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <>
                        <CircularProgress></CircularProgress>
                        <Typography>
                          En espera de seleccion de conductor{" "}
                        </Typography>
                      </>
                    )}
                  </>
                ) : null}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box
          alt="bicciexpress"
          sx={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            //backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
            //backgroundSize: "100% auto", // Escala horizontal completa
            //backgroundRepeat: "no-repeat", // No se repite
            // backgroundColor: "#e58888", //"#aef3c3",
            padding: "1em",
            marginBottom: "0px",
          }}
        >
          <Card
            sx={{
              height: "90%",
              maxHeight: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2px",
              marginBottom: "5%",
            }}
          >
            <CardHeader
              title={
                <Typography
                  sx={{
                    fontSize: "2em",
                    fontWeight: 600,
                    p: 1,
                    textTransform: "uppercase", // Convierte el texto a mayúsculas
                    fontStyle: "italic", // Aplica el estilo cursiva
                  }}
                >
                  Asignador V1
                </Typography>
              }
              subheader={
                <div>
                  <Switch
                    checked={inputDataMethod}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      color: waitingForScan ? "#aef3c3" : "#e58888",
                    }}
                  />
                  <Typography
                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                    color="textPrimary"
                    variant="body"
                  >
                    {inputDataMethod ? (
                      <span>Escaner seleccionado</span>
                    ) : (
                      <span>Teclado seleccionado</span>
                    )}
                  </Typography>
                </div>
              }
              sx={{ padding: "1px", margin: "0px" }}
            />
            {/* <CardMedia
          component="img"
          height="100"
          image="/static/images/asset/entradateclado.png"
          alt="bicciexpress"
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "50%",
            backgroundColor: "#aef3c3",
          }}
        /> */}
            <CardContent sx={{ padding: "1px" }}>
              <Box
                sx={{
                  objectFit: "contain",
                  width: "100%",
                  height: "99%",
                  //   backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
                  //   backgroundSize: "100% auto", // Escala horizontal completa
                  //   backgroundRepeat: "no-repeat", // No se repite
                  backgroundColor: waitingForScan ? "#aef3c3" : "#f1eeee", //,
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: "space-between",
                    padding: "1px",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "30%",
                      },

                      overflowY: "auto",
                      maxHeight: "100vh",
                      padding: "2px",
                      maxWidth: "100%",
                    }}
                  >
                    <AppBar
                      position="static"
                      sx={{
                        color: "#8F8F8",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        },
                        backgroundColor: "#e3e3e3",
                        overflowY: "auto",
                        maxHeight: "100vh",
                      }}
                    >
                      <Toolbar variant="dense" disableGutters>
                        {/* Título */}
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textPrimary"
                          variant="body"
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: "1rem",
                              md: "1rem",
                              lg: "1rem",
                            },
                            flexGrow: 1,
                            color: "#591e8f",
                          }}
                        >
                          {inputDataMethod ? "Modo Escaner" : "Modo Teclado"}
                        </Typography>

                        {/* Avatar e ícono de estado */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: 2,
                          }}
                        >
                          {waitingForScan ? (
                            <ChangeCircleIcon
                              sx={{ color: green[500], marginRight: 1 }}
                            />
                          ) : (
                            <CachedIcon
                              sx={{ color: "#591e8f", marginRight: 1 }}
                            />
                          )}
                        </Box>
                      </Toolbar>
                    </AppBar>
                    {dataSearch ? (
                      <>
                        <Box>
                          <Card
                            sx={{
                              margin: "2px",
                            }}
                          >
                            <CardHeader title={<>Información</>} />
                            <Box>
                              {inputDataMethodAsign ? (
                                <></>
                              ) : (
                                <>
                                  <Button
                                    onClick={() => AsignarmeFolio()}
                                    sx={{
                                      backgroundColor: "green",
                                      m: 1,
                                      p: 1,
                                      color: "#FFF",
                                      "&hover": {
                                        opacity: "0.8",
                                        backgroundColor: "green",
                                      },
                                    }}
                                  >
                                    {`<<<`} ASIGNAR FOLIO
                                  </Button>
                                </>
                              )}
                            </Box>
                            <CardContent>
                              {selectedRiderData ? (
                                <Box>
                                  <Avatar
                                    alt=""
                                    src={
                                      selectedRiderData &&
                                      selectedRiderData.avatar
                                        ? selectedRiderData.avatar
                                        : ""
                                    }
                                    sx={{
                                      width: "100%",
                                      height: "auto",
                                      borderRadius: "8px",
                                    }}
                                  ></Avatar>
                                  <Typography
                                    variant="body1"
                                    sx={{ margin: "10px", fontWeight: 600 }}
                                  >
                                    {selectedRiderData.name}
                                  </Typography>
                                </Box>
                              ) : null}

                              {dataSearch.status ? (
                                <>
                                  <Typography
                                    variant="body1"
                                    style={{ margin: "10px" }}
                                  >
                                    Orden Estatus: {dataSearch.status}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{ margin: "10px" }}
                                  >
                                    {
                                      translateStatesBicciFilter[
                                        dataSearch.status
                                      ]
                                    }
                                  </Typography>
                                </>
                              ) : null}

                              {dataSearch.intStatus ? (
                                <Typography
                                  variant="body1"
                                  style={{ margin: "10px" }}
                                >
                                  Estatus:
                                  {dataSearch.intStatus}
                                  <br></br>{" "}
                                  {globalsStatesCompanys[selectedCompanyPrefix][
                                    dataSearch.intStatus
                                  ]
                                    ? globalsStatesCompanys[
                                        selectedCompanyPrefix
                                      ][dataSearch.intStatus]
                                    : "Desconocido"}
                                </Typography>
                              ) : null}
                              {dataSearch.instucctions ? (
                                <Typography
                                  variant="body1"
                                  style={{ margin: "10px" }}
                                >
                                  Instrucciones:
                                  <br></br> {dataSearch.instucctions}
                                </Typography>
                              ) : null}
                              {dataSearch.placeName ? (
                                <Typography
                                  variant="body1"
                                  style={{ margin: "10px" }}
                                >
                                  Origen de Carga :<br></br>
                                  {dataSearch.placeName}
                                </Typography>
                              ) : null}
                            </CardContent>
                          </Card>
                        </Box>
                      </>
                    ) : null}

                    <Card
                      style={{
                        margin: "2px",
                      }}
                    >
                      <div
                        style={{
                          margin: "2px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productHeight}
                        </Typography>
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productLength}
                        </Typography>
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productWidth}
                        </Typography>
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productVolume
                            ? `Vol: ${localSize.productVolume}`
                            : null}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "70%",
                        padding: "1px",
                      },
                      height: "100%",
                      bottom: "0px",
                    }}
                  >
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      color="textPrimary"
                      variant="body"
                    >
                      {waitingForScan ? (
                        <div style={{ padding: "5px" }}>
                          Esperando{" "}
                          {inputDataMethod
                            ? "lecturas por escanner..."
                            : "digitación de codigo..."}{" "}
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: "5px",
                            backgroundColor: "red", //"#e58888",
                            color: "#fff",
                          }}
                        >
                          {inputDataMethod
                            ? "Mantenga la pagina activa para escanear..."
                            : "Mantenga la pagina activa para buscar..."}
                        </div>
                      )}
                    </Typography>
                    <LinearProgress value={progressValue} variant="determinate">
                      {" "}
                    </LinearProgress>
                    <div className="searchtiketcontainers">
                      <Card
                        sx={{
                          maxWidth: "450px",
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          padding: "1px",
                          textAlign: "center",
                        }}
                      >
                        <div className="inputcode">
                          <input
                            type="text"
                            id="codigoBarras"
                            className={
                              inputDataMethod
                                ? "barcode-input"
                                : "barcode-input-keyboar"
                            }
                            ref={input1Ref}
                            autoFocus
                            placeholder={
                              inputDataMethod
                                ? `${
                                    selectedCompanyPrefix
                                      ? selectedCompanyPrefix
                                      : "|"
                                  }|||||||| ||| |||| |||||`
                                : "Digite el codígo abuscar"
                            }
                            value={barcodeDataImput}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={(e) => handleBarcodeScan(e.target.value)}

                            //   sx={{
                            //     ...styles.inputBase,
                            //     "::before": {
                            //       ...styles.beforeContent,
                            //     },
                            //   }}
                          />

                          {/* Resto de tu interfaz y lógica aquí */}
                        </div>
                        {serahrCodeDataImput ? (
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            variant="h2"
                          >
                            {serahrCodeDataImput}
                          </Typography>
                        ) : null}
                      </Card>

                      {dataSearch ? (
                        <div className="tiketcontainers">
                          <div>
                            {dataSearch &&
                            dataSearch.length !== 0 &&
                            !dataSearch.sobrante ? (
                              <div className="tiketcontainers">
                                <Card
                                  className="m-4 b-1"
                                  key={dataSearch.id}
                                  sx={{
                                    boxShadow: "#F8F8F8",
                                  }}
                                >
                                  <CardHeader
                                    title={
                                      <div>
                                        <div>
                                          {dataSearch.dataOrigin === "SDDATA"
                                            ? "Same Day ( SD )"
                                            : "Home Delivery ( HD )"}
                                        </div>
                                        <div>{dataSearch.id}</div>
                                      </div>
                                    }
                                  />
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      {dataSearch.int_alert ? (
                                        <Typography
                                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                                          color="textPrimary"
                                          variant="h4"
                                          sx={{
                                            padding: "2px",
                                          }}
                                        >
                                          <span className="alertRed bigText">
                                            DISCREPANCIA REGISTRADA
                                          </span>
                                        </Typography>
                                      ) : null}
                                      {/*                                       
                                      <Typography>RIDER</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="body"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div>
                                          {!dataSearch.driverName ||
                                          dataSearch.driverName ===
                                            "isRequire" ||
                                          dataSearch.driverName === "" ? (
                                            <span className="alertRed bigText">
                                              No asignado
                                            </span>
                                          ) : (
                                            <span className="alertcolor ">
                                              {dataSearch.driverName}
                                            </span>
                                          )}
                                        </div>
                                      </Typography> */}
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={3}
                                      >
                                        <Grid item xs>
                                          <Box>
                                            <Typography>
                                              VEHÍCULO SIMPLI
                                            </Typography>
                                            <Typography
                                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                                              color="textPrimary"
                                              variant="h4"
                                              sx={{
                                                padding: "2px",
                                              }}
                                            >
                                              <div>
                                                {!dataSearch.driverLicensePlate ||
                                                dataSearch.driverLicensePlate ===
                                                  "isRequire" ||
                                                dataSearch.driverLicensePlate ===
                                                  "null" ? (
                                                  <span className="alertRed bigText">
                                                    -
                                                  </span>
                                                ) : (
                                                  <span className="alertcolor bigText">
                                                    {
                                                      dataSearch.driverLicensePlate
                                                    }
                                                  </span>
                                                )}
                                              </div>
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        <Grid item xs>
                                          <Box>
                                            <Typography>
                                              Ruta / ZONA BICCI
                                            </Typography>
                                            <Typography
                                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                                              color="textPrimary"
                                              variant="h4"
                                              sx={{
                                                padding: "2px",
                                              }}
                                            >
                                              <div>
                                                {bicciZone
                                                  ? bicciZone
                                                  : "No se Encuentra georeferencia"}
                                              </div>
                                            </Typography>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Typography>DESTINO:</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="h4"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div>
                                          {dataSearch.routeLocality
                                            ? dataSearch.routeLocality
                                            : dataSearch.toAddress}
                                        </div>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Typography>PUNTO RUTA</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="h4"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div> {dataSearch.orderNumber}</div>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Card>
                              </div>
                            ) : (
                              <Card
                                sx={{
                                  marginTop: "50px",
                                  padding: "20px",
                                  marginLeft: "20px",
                                  marginRight: "20px",
                                }}
                              >
                                <div>
                                  <span className="bigText">
                                    Sin resultados, ingrese nuevos datos para
                                    iniciar la busqueda.
                                  </span>
                                </div>
                                {dataSearch.sobrante ? (
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "red" }}
                                  >
                                    "SOBRANTE REGISTRADO"
                                  </Typography>
                                ) : null}
                              </Card>
                            )}{" "}
                          </div>
                        </div>
                      ) : (
                        <Card
                          sx={{
                            marginTop: "50px",
                            padding: "20px",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <div className="bigText"> "buscando..."</div>
                        </Card>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{
              top: "30%",
              right: "0",
              left: "auto",
              transform: "none", // Importante para evitar transformaciones predeterminadas
            }}
          >
            <MuiAlert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </MuiAlert>
          </Snackbar>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScannerAsignacionV1;
