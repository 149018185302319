import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  CardHeader,
  Box,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { assingMultipleDriver } from "../../redux/accionesDrivers";
import { customLocaleText } from "../../utils/objects/CustomLocaleTextTables";

const PopUpAsignament = ({
  ordersForAccions,
  ridersDataByCompany,
  opemPopUpAsignament,
  setOpemPopUpAsignament,
  handleUpdate,
}) => {
  const response = useSelector((store) => store.usuarioDriver.response);

  const [selectedRider, setSelectedRider] = useState(null);
  const [apiResponses, setApiResponses] = useState("");
  const [apiError, setApiError] = useState("");
  const [enabledRidersDataByCompany, setEnabledRidersDataByCompany] = useState(
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (ridersDataByCompany.length > 0) {
      let enableRiders = ridersDataByCompany.filter(
        (rider) => !rider.hiddenListMenus
      );
      setEnabledRidersDataByCompany(enableRiders);
    }
  }, [ridersDataByCompany]);

  useEffect(() => {
    if (response && response.result) {
      console.log(response);
      try {
        if (response.result.status === "success") {
          setApiResponses(response.result.message);
          if (handleUpdate) {
            handleUpdate();
          }
          handleClose();
        } else {
          setApiError(`Error en la asignación.`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [response]);

  useEffect(() => {}, [opemPopUpAsignament]);

  // Función para dividir el array en lotes de tamaño 'size'
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const asignarConductor = async () => {
    console.log("asignarConductor", ordersForAccions);
    if (selectedRider) {
      // Dividir ordersForAccions en lotes de 20
      const batches = chunkArray(ordersForAccions, 20);

      try {
        // Procesar cada lote secuencialmente
        for (const batch of batches) {
          var dataAsid = {
            data: {
              driverID: selectedRider.id,
              orderIDs: batch,
            },
          };
          console.log(dataAsid);

          dispatch(assingMultipleDriver(dataAsid));
        }

        // Aquí puedes mostrar las respuestas en el popup actualizando el estado
        // El popup permanecerá abierto para que el usuario pueda ver las respuestas
      } catch (error) {
        console.error("Error al asignar conductor:", error);
        setApiError(error.message || "Error desconocido");
      }
    }
  };

  const handleClose = () => setOpemPopUpAsignament(false);

  const handleRowClick = (params) => {
    setSelectedRider({
      id: params.id,
      name: params.row.name,
      avatar: params.row.avatar,
    });
  };

  const columnsRiders = [
    {
      field: "conductor",
      headerName: "Seleccione un conductor",
      flex: 1, // 🔥 Hace que la columna se expanda automáticamente según el ancho disponible
      minWidth: 350, // 🔥 Asegura que la columna tenga suficiente espacio
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // 🔥 Alinear verticalmente al centro
            justifyContent: "start", // 🔥 Alinear elementos a la izquierda
            width: "100%", // 🔥 Asegura que ocupe todo el ancho disponible

            // 🔥 Añadir un poco de espacio interno
          }}
        >
          {/* 📌 Avatar con un tamaño adecuado */}
          <Box>
            <Avatar
              src={params.row.avatar}
              sx={{ width: 45, height: 45, ml: 1 }}
            />
          </Box>
          {/* 📌 Contenedor para el nombre y el número de conductor */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {params.row.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {params.row.bicciNumber}
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  // console.log(ordersForAccions);
  return (
    <Dialog
      open={opemPopUpAsignament}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: { xs: "100%", sm: "100%", md: "700px" },
          maxWidth: "700px",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">Asignar conductor</DialogTitle>
      <DialogContent>
        <Box
          sx={{ width: "100%", height: 40, p: 1, backgroundColor: "#fafafa" }}
        >
          Envíos seleccionados para asignar : {ordersForAccions.length}
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            backgroundColor: "#fafafa",
            minHeight: 350,
          }}
        >
          {enabledRidersDataByCompany && (
            <DataGrid
              sx={{ minHeight: "150px", width: "750px" }} // 🔹 Asegurar que ocupe todo el ancho disponible
              rows={enabledRidersDataByCompany}
              columns={columnsRiders}
              pageSize={500}
              rowsPerPage={[5, 100, 500]}
              pagination
              localeText={customLocaleText}
              groupingModel={["name"]}
              onRowClick={handleRowClick}
            />
          )}
        </Box>

        {selectedRider && (
          <CardHeader
            avatar={<Avatar src={selectedRider.avatar} />}
            title={selectedRider.name}
          />
        )}

        {/**
        {apiResponses.length > 0 && (
          <div>
            <Typography variant="h6" gutterBottom>
              Respuestas de la API:
            </Typography>
            <Alert severity={response ? "success" : "error"} sx={{ mb: 2 }}>
              <AlertTitle>Lote </AlertTitle>
              {response || "Operación realizada."}
            
            </Alert>
          </div>
        )}

        {apiError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {apiError}
          </Alert>
        )}
 */}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        {ordersForAccions.length > 0 ? (
          <Button onClick={asignarConductor} color="primary">
            Asignar conductor
          </Button>
        ) : (
          <Button color="primary">0 envíos seleccionados</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PopUpAsignament;
