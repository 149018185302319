import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  Divider,
  CardHeader,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";
import FadeInOut from "../../animations/FadeInOut";
import moment from "moment";
import GooglePlacesAutocomplete from "../../../utils/objects/GooglePlacesAutocomplete";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import UserIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import GlobeIcon from "@mui/icons-material/Public";
import MarkerIcon from "@mui/icons-material/Place";
import NumbersIcon from "@mui/icons-material/Numbers";
import { db } from "../../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore"; // Asegúrate de importar correctamente estas funciones de Firestore
import {
  createNewCompany,
  actualizarNewCompany,
} from "../../../redux/accionesCompanies";
import { updateDoc } from "firebase/firestore";

const Fromcompanynew = ({ company, userUid }) => {
  const dispatch = useDispatch();

  // Estados
  const [errorCritical, setErrorCritical] = useState("");
  const [error, setError] = useState([]);
  const [success, setSuccess] = useState("");
  const [validatorAlert, setValidatorAlert] = useState("");
  const [companyCodeFolder, setCompanyCodeFolder] = useState("");
  const [validatorSuccess, setValidatorSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [isDepartamento, setIsDepartamento] = useState(false);
  const [rutvalida, setrutvalida] = React.useState("");

  const [companyData, setCompanyData] = useState({
    uid: userUid || "",
    propietary: userUid || "",
    companyID: "",
    companyName: "",
    companyRut: "",
    companyAddress: "",
    companyNumAddress: "",
    companyRegion: "",
    companyCity: "",
    companyComuna: "",
    companyCountry: "",
    companyCountryCode: "",
    companyActividad: "",
    companyContacName: "",
    companyContacEmail: "",
    companyAdressPhone: "",
    addressGeoreft: {},
    companyCodeFolder: "",
    companyAcuerdo: "",
    companyImgRut: "",
    companyImgDocumentos: "",
    companyImgPatente: "",
    companyImgDocSii: "",
    stausDocument: "",
    estatusCRM: "",
    isActive: false,
    servicie: "",
    servicetype: "",
    stausService: false,
    currentPayMethods: [],
    payPendings: 0,
    suportTikets: [],
    createdate: Date.now(),
    createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
  });

  // Animaciones
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  useEffect(() => {
    if (company) {
      setCompanyData(company);
    }
  }, [company]);

  const crearEmpresa = async () => {
    const errores = [];
    const requiredFields = [
      "companyName",
      "companyActividad",
      "companyAddress",
      "companyNumAddress",
      "companyRegion",
      "companyCity",
      "companyComuna",
      "companyCountry",
      "companyContacName",
      "companyAdressPhone",
    ];

    requiredFields.forEach((field) => {
      if (!companyData[field].trim()) {
        errores.push(
          `Ingrese ${field
            .replace("company", "")
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()}`
        );
      }
    });

    if (companyData.companyAdressPhone.length < 12) {
      errores.push("En el campo teléfono faltan números");
    } else if (companyData.companyAdressPhone.length > 12) {
      errores.push("El campo teléfono contiene números extra");
    }

    if (errores.length > 0) {
      setError(errores);
    } else {
      setSuccess("Guardando Datos...");
      setError([]);

      if (company) {
        actualizarCompany();
        console.log("Actualizar", companyData);
      } else {
        guardarNewCompany();
        console.log("Guardar", companyData);
      }
    }
  };

  const guardarDireccion = (e) => {
    e.preventDefault();
    crearEmpresa();
  };

  const guardarNewCompany = async () => {
    try {
      const docRef = doc(
        db,
        process.env.REACT_APP_COL_COMPANIES,
        companyCodeFolder
      );
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setError([
          `La empresa con el RUT ${companyData.companyRut} ya está registrada con otro usuario.`,
        ]);
      } else {
        const newCompanyData = {
          ...companyData,
          companyID: companyCodeFolder,
          companyCodeFolder: companyCodeFolder,
        };

        await setDoc(docRef, newCompanyData);

        const userDocRef = doc(db, process.env.REACT_APP_COL_USERS, userUid);
        await updateDoc(userDocRef, {
          userCompanyID: companyCodeFolder,
          userCompanyRoll: "company_admin",
          userType: "empresa",
          userActive: true,
          companyCollaborator: companyCodeFolder,
        });

        toggleShowEdit();
        setSuccess(null);
      }
    } catch (error) {
      setError(["Error al guardar la empresa"]);
    }
  };

  const actualizarCompany = async () => {
    try {
      const docRef = doc(
        db,
        process.env.REACT_APP_COL_COMPANIES,
        companyCodeFolder
      );
      await setDoc(docRef, companyData, { merge: true });
      // dispatch(actualizarNewCompany(companyData));
      toggleShowEdit();
      setSuccess(null);
    } catch (error) {
      setError(["Error al actualizar la empresa"]);
    }
  };

  function rutvalidator(e) {
    var valor;
    var cuerpo;
    var dv;
    var rut;
    if (e.length <= 0) {
      setValidatorAlert("Campo es obligatorio");
      setValidatorSuccess(null);
      setrutvalida(e);
      setCompanyCodeFolder(e);
      return;
    }
    valor = clean(e);

    cuerpo = valor.slice(0, -1);
    dv = valor.slice(-1).toUpperCase();

    rut = format(e);

    if (cuerpo.length < 7) {
      setValidatorAlert("Campo debe ser mayor a 7 Caracteres");
      setValidatorSuccess(null);
      setrutvalida(rut);
      setCompanyCodeFolder(rut);
      return;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    var dvEsperado;
    dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    if (dvEsperado != dv) {
      setValidatorAlert("Rut incorrecto");
      setValidatorSuccess(null);
    } else {
      setValidatorSuccess("Rut correcto");
      setValidatorAlert(null);
      function delay() {
        setValidatorSuccess(null);
      }
      setTimeout(delay, 5000);
    }

    setrutvalida(rut);
    setCompanyCodeFolder(rut);
    var clear = replaceAll(rut, ".", "");
    return;
  }
  function replaceAll(text, busca, reemplaza) {
    while (text.toString().indexOf(busca) != -1)
      text = text.toString().replace(busca, reemplaza);
    return text;
  }
  function format(rut) {
    rut = clean(rut);
    var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (var i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "" + result;
    }
    return result;
  }
  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  const handleSelections = (item) => {
    if (item && item.address) {
      setCompanyData((prevState) => ({
        ...prevState,
        companyAddress: item.address,
        companyNumAddress: item.direccion2,
        direccion1: item.direccion1,
        direccion2: item.direccion2,
        companyRegion: item.region,
        companyCity: item.commune,
        companyComuna: item.commune2,
        companyCountry: item.country,
        companyCountryCode: item.zipCode,
        addressGeoreft: item.addressGeoreft,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "companyRut") {
      rutvalidator(value);
    }
    setCompanyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeDirection3 = (e) => {
    const value = e.target.checked ? "" : "N/A";
    setIsDepartamento(e.target.checked);
    setCompanyData((prevState) => ({
      ...prevState,
      direccion3: value,
    }));
  };

  const limpiarFormulario = () => {
    setError([]);
    setSuccess(null);
    setWarning(null);
    setCompanyData({
      ...companyData,
      companyName: "",
      companyRut: "",
      companyContacName: "",
      companyAdressPhone: "",
      companyActividad: "",
      companyContacEmail: "",
      int_placeName: "",
      int_warehouseCode: "",
      int_warehouseName: "",
      companyAddress: "",
      companyNumAddress: "",
      direccion1: "",
      direccion2: "",
      direccion3: "",
      companyComuna: "",
      companyCity: "",
      companyRegion: "",
      companyCountry: "",
    });
  };

  return (
    <Grid sx={{ padding: "1px", width: "100%" }}>
      {errorCritical && (
        <span className="alert alert-danger">{errorCritical}</span>
      )}
      <Grid
        container
        sx={{
          backgroundColor:
            "radial-gradient(circle, rgba(80,95,167,1) 0%, rgba(80,95,168,1) 55%, rgba(80,95,169,1) 100%)",
          width: "100%",
        }}
      >
        <Card sx={{ padding: "20px", width: "100%", height: "auto" }}>
          <CardHeader
            subheader={"Sucursal Principal o Casa Matriz"}
            title={"Datos de Empresa"}
          />
          <Divider />
          <FadeInOut show={showEdit} duration={500} parentName="fade17">
            <Box id="micuentafromIni" sx={{ padding: 3 }}>
              <hr />
              {success && (
                <Typography variant="body1" className="alert alert-success">
                  {success}
                </Typography>
              )}
              {error.length > 0 && (
                <div className="alert alert-danger">
                  {error.map((err, index) => (
                    <Typography key={index} variant="body1">
                      {err}
                    </Typography>
                  ))}
                </div>
              )}
              <Box
                component="form"
                onSubmit={guardarDireccion}
                noValidate
                sx={{ mt: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      label="Nombre de la Empresa"
                      placeholder="Nombre de la Empresa"
                      name="companyName"
                      value={companyData.companyName || ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HomeWorkIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: "50%" }}>
                    <TextField
                      disabled={!!company}
                      fullWidth
                      label="RUT"
                      placeholder="RUT"
                      name="companyRut"
                      value={companyData.companyRut || ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <UserIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                    />
                    <Typography sx={{ color: "red" }}>
                      {validatorAlert}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      label="Nombre"
                      placeholder="Nombre de Contacto"
                      name="companyContacName"
                      value={companyData.companyContacName || ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <UserIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      label="Teléfono"
                      placeholder="Teléfono"
                      name="companyAdressPhone"
                      value={companyData.companyAdressPhone || ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      label="Actividad económica"
                      placeholder="Actividad económica"
                      name="companyActividad"
                      value={companyData.companyActividad || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      label="Contacto Email"
                      placeholder="Contacto Email"
                      name="companyContacEmail"
                      value={companyData.companyContacEmail || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: "33%" }}>
                    <TextField
                      fullWidth
                      label="Nombre referencial (Opcional)"
                      placeholder="Nombre referencial (Opcional)"
                      name="int_placeName"
                      value={companyData.int_placeName || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: "33%" }}>
                    <TextField
                      fullWidth
                      label="Código de Bodega (Opcional)"
                      placeholder="Código de Bodega (Opcional)"
                      name="int_warehouseCode"
                      value={companyData.int_warehouseCode || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: "33%" }}>
                    <TextField
                      fullWidth
                      label="Nombre de Bodega (Opcional)"
                      placeholder="Nombre de Bodega (Opcional)"
                      name="int_warehouseName"
                      value={companyData.int_warehouseName || ""}
                      onChange={handleChange}
                    />
                  </Grid>

                  {!companyData.companyAddress ? (
                    <Grid item sx={{ width: "100%" }}>
                      <GooglePlacesAutocomplete
                        adress={companyData}
                        handleSelections={handleSelections}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item sx={{ width: "100%" }}>
                        <TextField
                          fullWidth
                          placeholder="Dirección"
                          name="companyAddress"
                          value={companyData.companyAddress || ""}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sx={{ width: "50%" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isDepartamento}
                              onChange={handleChangeDirection3}
                              color="primary"
                            />
                          }
                          label="Local / Oficinas"
                        />
                      </Grid>
                      <Grid item sx={{ width: "40%" }}>
                        <TextField
                          fullWidth
                          label="Calle"
                          placeholder="Calle"
                          name="direccion1"
                          value={companyData.direccion1 || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MarkerIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sx={{ width: "30%" }}>
                        <TextField
                          fullWidth
                          label="Número"
                          placeholder="Número"
                          name="direccion2"
                          value={companyData.direccion2 || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <NumbersIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sx={{ width: "30%" }}>
                        <TextField
                          fullWidth
                          label="Local / Oficinas"
                          placeholder="Local / Oficinas"
                          name="direccion3"
                          value={companyData.direccion3 || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <NumbersIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sx={{ width: "50%" }}>
                        <TextField
                          fullWidth
                          label="Comuna"
                          placeholder="Comuna"
                          name="companyComuna"
                          value={companyData.companyComuna || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <HomeWorkIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sx={{ width: "50%" }}>
                        <TextField
                          fullWidth
                          label="Ciudad"
                          placeholder="Ciudad"
                          name="companyCity"
                          value={companyData.companyCity || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MarkerIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sx={{ width: "50%" }}>
                        <TextField
                          fullWidth
                          label="Región"
                          placeholder="Región"
                          name="companyRegion"
                          value={companyData.companyRegion || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MarkerIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item sx={{ width: "50%" }}>
                        <TextField
                          fullWidth
                          label="País"
                          placeholder="País"
                          name="companyCountry"
                          value={companyData.companyCountry || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <GlobeIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleChange}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      label="Comentario (Opcional)"
                      placeholder="Comentario breve o referencia"
                      multiline
                      name="adressOriginComent"
                      rows={4}
                      onChange={handleChange}
                      value={companyData.adressOriginComent || ""}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#591E8F",
                      color: "#fff",
                      marginTop: "30px",
                      marginRight: 2,
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      ":hover": {
                        backgroundColor: "#25b2f4",
                        color: "#591e8f",
                      },
                    }}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CleaningServicesIcon />}
                    onClick={limpiarFormulario}
                    sx={{
                      backgroundColor: "#db2828",
                      color: "#fff",
                      marginTop: "30px",
                      padding: "5px",
                      minWidth: "50px",
                      fontWeight: 400,
                      ":hover": { opacity: 0.8 },
                    }}
                  >
                    Limpiar
                  </Button>
                </Box>
              </Box>
            </Box>
          </FadeInOut>
          <Divider />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Fromcompanynew;
