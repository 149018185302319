import React, { useState } from "react";
import { Button, TextField, Box } from "@mui/material";

const CreateEmailConfirmationForm = ({ createEmailclient }) => {
  const [confirmDireccion, setConfirmDireccion] = useState("");

  return (
    <form onSubmit={createEmailclient}>
      {/* Campo oculto para el ID único */}
      <input type="hidden" name="uniqueId" value="confirmacion1" />

      {/* Campo de texto opcional para dirección */}
      <TextField
        fullWidth
        label="Dirección en el cuerpo del mensaje"
        placeholder="Ingrese la dirección..."
        variant="outlined"
        margin="normal"
        value={confirmDireccion}
        onChange={(e) => setConfirmDireccion(e.target.value)}
        InputProps={{
          startAdornment: <span style={{ marginRight: "8px" }}>📍</span>,
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          type="submit" // Se corrigió 'sutmit' a 'submit'
          sx={{
            backgroundColor: "#591E8F",
            color: "#fff",
            borderRadius: "5px",
            padding: "10px 20px",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#25b2f4",
              color: "#591e8f",
            },
          }}
        >
          Crear email de confirmación de nuevo destino
        </Button>
      </Box>
    </form>
  );
};

export default CreateEmailConfirmationForm;
