import React, { useEffect, useState } from "react";
import { SavePlanDialog } from "./dialogs";
import { Button } from "@mui/material";
import axios from "axios";
import * as XLSX from "xlsx";

const ButonHandleOpenSaveDialog = ({
  documentData,
  setDocumentData,
  routes,
  ridersDataByCompany,
  routeInfo,
  companyID,
  documentsToSend,
  setLoading,
  contextReq,
}) => {
  const [documentsTs, setDocumentsTs] = useState([]);

  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  // ⚠️ Corrección: Sincronizar documentsTs cuando documentsToSend cambia
  useEffect(() => {
    if (documentsToSend && documentsToSend.length > 0) {
      setDocumentsTs(documentsToSend);
    }
  }, [documentsToSend]);

  const handleOpenSaveDialog = () => {
    setOpenSaveDialog(true);
  };

  const handleSaveDocument = async () => {
    setLoading(true);

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BICCIEXPRESS_CREATE_ROUTE,
        headers: {
          companyID: "FroY2JbeirD7B1MRYEXo", //contextReq.companyID,
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJCSUNDSUNMT1VEIiwibmFtZSI6IkJJQ0NJRVhQUkVTUyIsImNvbXBhbnlJRCI6IkZyb1kySmJlaXJEN0IxTVJZRVhvIiwiZW52aXJvbm1lbnQiOiJwcm9kdWN0aW9uIiwiaWF0IjoxNzIwOTU5MTk3fQ.mOLiF57-r4lX48OnT4nxsjyG6Tw0MWiKEC1_56CxmWE`, //${contextReq.token},
        },
        data: { data: documentsTs }, // Enviar documentos en el body
      };

      const response = await axios(config);

      if (response.status === 200) {
        alert("Documento guardado correctamente.");
      } else {
        console.warn("⚠️ Respuesta inesperada del servidor:", response.status);
        alert("Hubo un problema al guardar el documento.");
      }
    } catch (error) {
      alert("Error al guardar el documento. Por favor, inténtelo nuevamente.");
    }

    setOpenSaveDialog(false);
    setLoading(false);
  };

  const handleCancelSave = () => {
    setOpenSaveDialog(false);
  };

  const changeDataValues = (route, newDate) => {
    setDocumentsTs((prev) =>
      prev.map((doc) =>
        doc.route === route
          ? {
              ...doc,
              id: `${newDate.replace(/-/g, "")}_${doc.id
                .split("_")
                .slice(1)
                .join("_")}`, // Actualiza el ID con la nueva fecha
              dayStart: newDate,
            }
          : doc
      )
    );
  };

  const exportarHojaRutasActualizaciones = (data) => {
    if (!data || !data.ordersByRoute) {
      console.error("❌ No hay datos para exportar");
      return;
    }

    let datosExportar = [];

    // Recorrer cada ruta en el objeto ordersByRoute
    Object.entries(data.ordersByRoute).forEach(([ruta, orders]) => {
      orders.forEach((order, index) => {
        datosExportar.push({
          driverLicensePlate: ruta, // Nombre de la ruta
          orderNumber: index + 1, // Número de orden (index basado en array)
          PREFIX: order.prefix || "", // Puede estar vacío si no existe
          BICCIID: order.id, // ID de la orden
        });
      });
    });

    if (datosExportar.length === 0) {
      console.warn("⚠️ No se encontraron órdenes para exportar.");
      return;
    }

    // Definir los encabezados de la hoja de Excel
    const encabezados = [
      "driverLicensePlate",
      "orderNumber",
      "PREFIX",
      "BICCIID",
    ];

    // Crear la hoja de trabajo
    const hojaDeTrabajo = XLSX.utils.json_to_sheet(datosExportar, {
      header: encabezados,
    });

    // Crear el libro de Excel
    const libroDeTrabajo = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      libroDeTrabajo,
      hojaDeTrabajo,
      "OrdenesPorRuta"
    );

    // Descargar el archivo
    const fecha = new Date().toISOString().split("T")[0]; // Fecha actual YYYY-MM-DD
    XLSX.writeFile(libroDeTrabajo, `Ruta_rutas_${fecha}.xlsx`);
  };

  return (
    <>
      <Button onClick={handleOpenSaveDialog}>Guardar Documentos</Button>
      <SavePlanDialog
        openSaveDialog={openSaveDialog}
        handleCancelSave={handleCancelSave}
        handleSaveDocument={handleSaveDocument}
        documentData={documentData}
        setDocumentData={setDocumentData}
        routes={routes}
        ridersDataByCompany={ridersDataByCompany}
        exportarHojaRutasActualizaciones={exportarHojaRutasActualizaciones}
        routeInfo={routeInfo}
        companyID={companyID}
        documentsToSend={documentsTs} // ⚠️ Ahora `documentsTs` se pasa correctamente al hijo
        changeDataValues={changeDataValues}
      />
    </>
  );
};

export default ButonHandleOpenSaveDialog;
