import React from "react";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import useObtenerProductos from "../../hooks/obtenerProductos";
import { useDispatch, useSelector } from "react-redux";
import { clientAddCartProduct } from "../../redux/acccionesCart";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";
var userUid = "noregister";
var userState = false;
const Products = (props) => {
  const dispatch = useDispatch();
  const { plans, products, loadingProductos, errorProductos } =
    useObtenerProductos();
  const acciones = useSelector((store) => store.cartAccion);
  //const user = useSelector((store) => store.usuario.user.uid);
  //console.log(acciones)
  try {
    const user = useSelector((store) => store.usuario.user.uid);
    userUid = user;
    userState = true;
  } catch (error) {
    userState = false;
  }

  //console.log(plans)
  //console.log(user)

  const addToCard = (e) => {
    let obj = e.target.value;
    //console.log(obj, userUid)
    dispatch(clientAddCartProduct(obj, userUid));
    // props.history.push(`/checkout/`)
  };
  const viewDetail = (e) => {
    let obj = e.target.value;
    //console.log(obj, userUid)
    props.history.push(`/productos/${obj}`);
    // dispatch(clientAddCartProduct(obj,userUid));
  };

  return (
    <ContainerViewComponent className="bodycontent">
      <div className="bodycontent-pricing">
        <div>
          <div className="section-title">
            <h2>Planes y precios</h2>
            <h3>
              Revisa el plan que se ajusta a <span>tu comercio</span>
            </h3>
            <p>
              según la cantidad de envíos mensuales, ajusta tu plan mensual y
              acumula los envios no realizados
            </p>
          </div>

          <div className="row rowproduct">
            {products ? (
              <>
                {products.map((item) => (
                  <Card className="cardproduct" key={item.id}>
                    <div className="header-info" role="alert">
                      {item.productName}
                    </div>
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "left",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        ></Typography>
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textPrimary"
                          gutterBottom
                          variant="div"
                        >
                          {item.discount > 0 ? (
                            <>
                              <h4 className="price-size-minimal price-size-tach">
                                Precio <sup>$</sup>
                                {item.articlePrice}
                                <span> / mes</span>
                              </h4>
                              <h4 className="price-size-mediun">
                                Descuento <sup>$</sup>
                                {item.discount}
                                <span> / mes</span>
                              </h4>
                              <h4 className="price-size">
                                TOTAL <sup>$</sup>
                                {item.total}
                                <span> / mes</span>
                              </h4>
                            </>
                          ) : (
                            <>
                              <h4 className="price-size-minimal price-size-tach"></h4>
                              <h4 className="price-size-mediun"></h4>
                              <h4 className="price-size">
                                TOTAL <sup>$</sup>
                                {item.total}
                                <span> / mes</span>
                              </h4>
                            </>
                          )}
                        </Typography>
                        <Divider />
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textSecondary"
                          variant="body2"
                        >
                          <li className="ro">
                            Agrege {item.ChargeShipments} envíos por mes a su
                            cuenta
                          </li>
                        </Typography>
                        <Divider />
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textSecondary"
                          variant="body"
                        >
                          {item.description}
                        </Typography>
                        {item.availableservices ? (
                          <>
                            {item.availableservices
                              .filter((itemServ) => itemServ.isActive === true)
                              .map((element) => (
                                <div key={element.id}>
                                  <Divider />
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textSecondary"
                                    variant="body"
                                  >
                                    {element.name}
                                  </Typography>
                                </div>
                              ))}
                          </>
                        ) : (
                          <></>
                        )}
                        <Divider />
                      </Box>
                    </CardContent>
                    <Divider />
                    <CardActions className="backgroundfooter">
                      <div className="btn-wrap">
                        <button
                          onClick={addToCard}
                          value={item.id}
                          className="btn-buy scrollto m-1"
                        >
                          Contratar
                        </button>
                        <button
                          onClick={viewDetail}
                          value={item.slug}
                          className="btn-buy scrollto m-1"
                        >
                          ver detallles
                        </button>
                      </div>
                    </CardActions>
                  </Card>
                ))}
              </>
            ) : (
              <>Cargando</>
            )}
          </div>
          <div className="row rowproduct">
            {plans ? (
              <>
                {plans.map((item) => (
                  <Card className="cardproduct" key={item.id}>
                    <div className="header-info" role="alert">
                      {item.planName}
                    </div>
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "left",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        ></Typography>
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textPrimary"
                          gutterBottom
                          variant="div"
                        >
                          {item.discountAmount > 0 ? (
                            <>
                              <h4 className="price-size-minimal price-size-tach">
                                Precio <sup>$</sup>
                                {item.salePrice}
                                <span> / mes</span>
                              </h4>
                              <h4 className="price-size-mediun">
                                Descuento <sup>$</sup>
                                {item.discountPrice}
                                <span> / mes</span>
                              </h4>
                              <h4 className="price-size">
                                TOTAL <sup>$</sup>
                                {item.totalAmount}
                                <span> / mes</span>
                              </h4>
                            </>
                          ) : (
                            <>
                              <h4 className="price-size-minimal price-size-tach"></h4>
                              <h4 className="price-size-mediun"></h4>
                              <h4 className="price-size">
                                TOTAL <sup>$</sup>
                                {item.totalAmount}
                                <span> / mes</span>
                              </h4>
                            </>
                          )}
                        </Typography>
                        <Divider />
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textSecondary"
                          variant="body2"
                        >
                          <li className="ro">
                            Agrege {item.ChargeShipments} envíos por mes a su
                            cuenta
                          </li>
                        </Typography>
                        <Divider />
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textSecondary"
                          variant="body"
                        >
                          {item.description}
                        </Typography>
                        {item.availableservices ? (
                          <>
                            {item.availableservices
                              .filter((itemServ) => itemServ.isActive === true)
                              .map((element) => (
                                <div key={element.id}>
                                  <Divider />
                                  <Typography
                                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                                    color="textSecondary"
                                    variant="body"
                                  >
                                    {element.name}
                                  </Typography>
                                </div>
                              ))}
                          </>
                        ) : (
                          <></>
                        )}
                        <Divider />
                      </Box>
                    </CardContent>
                    <Divider />
                    <CardActions className="backgroundfooter">
                      <div className="btn-wrap">
                        <button
                          onClick={addToCard}
                          value={item.id}
                          className="btn-buy scrollto m-1"
                        >
                          Contratar
                        </button>
                        <button
                          onClick={viewDetail}
                          value={item.slug}
                          className="btn-buy scrollto m-1"
                        >
                          ver detallles
                        </button>
                      </div>
                    </CardActions>
                  </Card>
                ))}
              </>
            ) : (
              <>Cargando</>
            )}
          </div>
        </div>
      </div>
    </ContainerViewComponent>
  );
};

export default Products;
