import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Importa los iconos que necesites
import { StyledCard, StyledTextField, StyledSelect } from "./FromStyles";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  Divider,
} from "@mui/material";
import { OutlinedInput } from "@mui/material";

const FromShippingCarga = ({ onChange, data }) => {
  const [selection, setSelectedOption] = useState("SD");
  const [formState, setFormState] = useState({
    address: "",
    city: "",
    postalCode: "",
  });

  useEffect(() => {
    let data = { int_serviceType: selection };
    onChange(data);
  }, [selection]);

  useEffect(() => {
    if (data) {
      setFormState(data);
    }
  }, [data]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardHeader
        // title="Carga"
        sx={{
          color: "#fff", // Texto blanco
          borderRadius: "10px 10px 0 0",
          padding: "1px",
          paddingTop: "1px",
        }}
      />
      <CardContent sx={{ padding: "1px !important" }}>
        <Typography sx={{ color: "#fff" }}> {"Tipo de Servicio"}</Typography>
        <FormControl fullWidth margin="dense">
          <Select
            sx={{
              backgroundColor: "#fff", // Aplica fondo blanco al select
              ".MuiOutlinedInput-input": {
                borderRadius: "10px",
                padding: "10px",
                fontSize: "1rem",
                color: "#333",
              },
            }}
            labelId="Seleccione Tipo de sevico"
            id="medidas"
            value={selection}
            onChange={(e) => handleChange(e)}
            label=""
            input={
              <OutlinedInput
                notched
                // startAdornment={<LocationOnIcon color="secondary" />}
              />
            }
          >
            <MenuItem key="SD" value="SD">
              {"Same Day"}
            </MenuItem>
            <MenuItem key="HD" value="HD">
              {"Home Delivery"}
            </MenuItem>
            <MenuItem key="EX" value="EX">
              {"Express"}
            </MenuItem>
            <MenuItem key="ND" value="ND">
              {"Next Day"}
            </MenuItem>
            <MenuItem key="VIP" value="VIP">
              {"VIP"}
            </MenuItem>
            <MenuItem key="UM" value="UM">
              {"UM"}
            </MenuItem>
          </Select>
        </FormControl>
        <Divider></Divider>
        <Typography sx={{ color: "#fff" }}></Typography>
        <FormControlLabel
          control={
            <Switch
              checked={false}
              onChange={(e) => console.log("field.variable, e.target.checked")}
              name={"seguro"}
              color="primary"
            />
          }
          label={"Activación Seguro"}
        />
      </CardContent>
    </StyledCard>
  );
};

export default FromShippingCarga;
