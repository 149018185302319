import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const MetricsSummary = ({ metrics }) => {
  if (!metrics) return <Typography variant="h6">Cargando datos...</Typography>;

  return (
    <Box sx={{ width: "100%", padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Resumen de Operaciones en Rango de Fecha
      </Typography>

      {/* Tabla principal de métricas */}
      <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f1f1f1" }}>
              <TableCell align="center">
                <b>Métrica</b>
              </TableCell>
              <TableCell align="center">
                <b>Valor</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">Total Riders</TableCell>
              <TableCell align="center">{metrics.totalRider}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Total Asignados</TableCell>
              <TableCell align="center">{metrics.totalAssing}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Total Entregados</TableCell>
              <TableCell align="center">{metrics.totalDelivery}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Total No Entregas</TableCell>
              <TableCell align="center">{metrics.totalPending}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Éxito (%)</TableCell>
              <TableCell align="center">{metrics.ntsPerformanse}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Zonas Visitadas</TableCell>
              <TableCell align="center">{metrics.cantTotalZones}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Tabla de vehículos */}
      <Typography variant="h6" gutterBottom>
        Vehículos Utilizados
      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f1f1f1" }}>
              <TableCell align="center">
                <b>Vehículo</b>
              </TableCell>
              <TableCell align="center">
                <b>Cantidad</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metrics.totalVehicles.map((vehicle, index) => (
              <TableRow key={index}>
                <TableCell align="center">{vehicle.name}</TableCell>
                <TableCell align="center">{vehicle.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Tabla de comunas */}
      <Typography variant="h6" gutterBottom>
        Paquetes por Comuna
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f1f1f1" }}>
              <TableCell align="center">
                <b>Comuna</b>
              </TableCell>
              <TableCell align="center">
                <b>Cantidad de Paquetes</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(metrics.totalZones).map(([zone, count], index) => (
              <TableRow key={index}>
                <TableCell align="center">{zone}</TableCell>
                <TableCell align="center">{count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MetricsSummary;
