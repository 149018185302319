import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import PinpassFrom from "../components/formularios/PinpassFrom/PinpassFrom";
import { collection, getDoc, doc } from "firebase/firestore";
import { LinearProgress, Typography } from "@mui/material";
var CryptoJS = require("crypto-js");

const PinPass = ({
  component: Component,
  usuarioStorage,
  firebaseUser,
  ...rest
}) => {
  const { idRider, idRoute } = useParams();
  const [infoFrom, setInfoFrom] = useState(null);
  const [notFound, setNotFound] = useState(true);
  const [pin, setPin] = useState(null);
  const [userRider, setUserRider] = useState(null);
  const [autorize, setAutorize] = useState(null);
  const [render, setRender] = useState(false);

  const [tokenUserBicci, settokenUserBicci] = useState(
    JSON.parse(localStorage.getItem("tokenUserBicci"))
  );

  useEffect(() => {
    if (idRider) {
      findRider(idRider);
    }
  }, [idRider]);

  useEffect(() => {
    if (userRider && pin) {
      let dni_sin_guion = userRider.dni.replace(/-/g, "");
      let ultimos_cuatro_digitos = dni_sin_guion.slice(-4);

      if (
        pin === ultimos_cuatro_digitos ||
        pin === process.env.REACT_APP_ADMINPASS ||
        pin === process.env.REACT_APP_OPSPASS
      ) {
        setAutorize(true);
        let newToken = {
          idRoute: idRoute,
          userRider: idRider,
          userPin: pin,
          date: Date.now(),
        };

        var toket = JSON.stringify(newToken);
        var encryptData = CryptoJS.AES.encrypt(
          toket,
          `${process.env.REACT_APP_SECRETECRIPT_WEB}`
        ).toString();

        localStorage.setItem("tokenUserBicci", JSON.stringify(encryptData));
      }
    }
  }, [userRider && pin]);

  useEffect(() => {}, [autorize, render]);
  useEffect(() => {
    if (!tokenUserBicci) {
      setAutorize(false);
    } else {
      if (userRider && tokenUserBicci) {
        try {
          let dni_sin_guion = userRider.dni.replace(/-/g, "");
          let ultimos_cuatro_digitos = dni_sin_guion.slice(-4);
          var bytes = CryptoJS.AES.decrypt(
            tokenUserBicci,
            `${process.env.REACT_APP_SECRETECRIPT_WEB}`
          );
          var sessionData = bytes.toString(CryptoJS.enc.Utf8);
          let sessionDataObject = JSON.parse(sessionData);
          if (sessionDataObject) {
            let fechaActual = Date.now();

            let diferencia = fechaActual - sessionDataObject.date;

            let diferenciaEnHoras = diferencia / (1000 * 60 * 60);

            if (diferenciaEnHoras <= 6) {
              if (
                sessionDataObject.userPin === ultimos_cuatro_digitos ||
                sessionDataObject.userPin === process.env.REACT_APP_ADMINPASS ||
                sessionDataObject.userPin === process.env.REACT_APP_OPSPASS
              ) {
                setAutorize(true);
              } else {
                localStorage.removeItem("tokenUserBicci");
                setAutorize(false);
                setRender(!render);
              }
            } else {
              localStorage.removeItem("tokenUserBicci");
              setAutorize(false);
              setRender(!render);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [userRider && tokenUserBicci]);

  const findRider = async (idRider) => {
    const driveCollectionRef = collection(
      db,
      process.env.REACT_APP_COL_USERS_DRIVERS
    );
    const docRef = doc(driveCollectionRef, idRider);

    try {
      const userRiderSnapshot = await getDoc(docRef);
      if (userRiderSnapshot.exists()) {
        setUserRider(userRiderSnapshot.data());
      } else {
        setNotFound(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (autorize !== null) {
    if (autorize === true) {
      return <Outlet userRider={userRider} />;
    } else {
      return (
        <PinpassFrom
          title={
            <Typography sx={{ fontSize: "2em", color: "#030303" }}>
              Ingresa el PIN para ver tu Ruta.
            </Typography>
          }
          setPin={setPin}
          info={infoFrom}
          notFound={notFound}
        ></PinpassFrom>
      );
    }
  } else {
    return <LinearProgress></LinearProgress>;
  }
};

export default PinPass;
