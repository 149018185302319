import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { useSelector } from "react-redux";

import {
  Tabs,
  Tab,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Container,
  IconButton,
} from "@mui/material";

import NotificationsIcon from "@mui/icons-material/Notifications";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MessageIcon from "@mui/icons-material/Message";
import StarIcon from "@mui/icons-material/Star";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import useGetMessagesByCompanyId from "../../../hooks/getMessagesByCompanyId";
import useGetMessagesBySystem from "../../../hooks/getMessagesByUserSystem";
import useGetComentsWeb from "../../../hooks/getComentsWeb";

const Mensajes = (props) => {
  const userUid = useSelector((store) => store.usuario);
  const [activeTab, setActiveTab] = useState(0);
  const [currentMsjList, setCurrentMsjList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [objUser, setObjUser] = useState("");

  const [typePop, setTypePop] = useState("");

  const { userdata } = useObtenerDatosdeCliente(userUid?.user?.uid);
  const { bicciMessagesByCompanyId } = useGetMessagesByCompanyId(
    userdata?.userCompanyID,
    userdata?.userCompanyRoll
  );
  const { bicciMessagesBySystem } = useGetMessagesBySystem(userdata?.email);
  const { bicciComentsWeb } = useGetComentsWeb(userdata?.email);
  const { bicciMessagesSupport } = useGetComentsWeb(userdata?.email);

  useEffect(() => {
    if (bicciMessagesByCompanyId) {
      bicciMessagesByCompanyId.sort((a, b) => (a.id < b.id ? 1 : -1));
      setCurrentMsjList(bicciMessagesByCompanyId);
    }
  }, [bicciMessagesByCompanyId]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    switch (newValue) {
      case 0:
        setCurrentMsjList(bicciMessagesByCompanyId);
        break;
      case 1:
        setCurrentMsjList(bicciMessagesSupport);
        break;
      case 2:
        setCurrentMsjList(bicciMessagesBySystem);
        break;
      case 3:
        setCurrentMsjList(bicciComentsWeb);
        break;
      default:
        setCurrentMsjList([]);
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const viewNotification = (id) => {
    const searchData = currentMsjList.find((item) => item.id === id);
    setTypePop("notifications");
    setObjUser({
      title: "Notificación de Empresa",
      texto: searchData.subject,
      data: searchData,
    });

    db.collection(`${process.env.REACT_APP_COL_MSJ}`)
      .doc(userdata.userCompanyID)
      .collection("system")
      .doc(searchData.id)
      .update({ read: true });

    togglePopup();
  };

  return (
    <Container>
      <Paper sx={{ padding: 2, marginBottom: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Notificaciones" icon={<NotificationsIcon />} />
          <Tab label="Soporte" icon={<SupportAgentIcon />} />
          <Tab label="Sistema" icon={<MessageIcon />} />
          {userdata?.mensajesWeb && (
            <Tab label="Mensajes Web" icon={<MessageIcon />} />
          )}
        </Tabs>
      </Paper>

      <ContainerViewComponent>
        <List>
          {currentMsjList.map((msg) => (
            <ListItemButton
              key={msg.id}
              onClick={() => viewNotification(msg.id)}
            >
              <ListItemIcon>
                {msg.outstanding ? (
                  <StarIcon color="primary" />
                ) : (
                  <StarOutlineIcon color="disabled" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={msg.subject}
                secondary={`De: ${msg.from} - ${msg.sendDateText}`}
              />
              <IconButton onClick={() => console.log("delete")}>
                <DeleteForeverIcon color="error" />
              </IconButton>
              <IconButton>
                {msg.bookmark ? (
                  <BookmarkIcon color="primary" />
                ) : (
                  <BookmarkBorderIcon color="disabled" />
                )}
              </IconButton>
            </ListItemButton>
          ))}
        </List>
      </ContainerViewComponent>

      {showPopup && (
        <PopupMessage
          contenido={objUser}
          togglePopup={togglePopup}
          type={typePop}
        />
      )}
    </Container>
  );
};

export default Mensajes;
