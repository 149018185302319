export const handleClosePlansDialog = (setOpenPlansDialog) => {
  setOpenPlansDialog(false);
};

export const handleSelectPlan = (plan) => {
  console.log("Plan seleccionado:", plan);
};

export const handlePrevPage = (setCurrentPage) => {
  setCurrentPage((prev) => Math.max(prev - 1, 0));
};

export const handleNextPage = (setCurrentPage) => {
  setCurrentPage((prev) => prev + 1);
};

export const handleOpenFilterDialog = (setOpenFilterDialog) => {
  setOpenFilterDialog(true);
};

export const handleCancelFilter = (setOpenFilterDialog) => {
  setOpenFilterDialog(false);
};

export const handleApplyFilter = (selectedStatuses) => {
  console.log("Aplicando filtro con estados:", selectedStatuses);
};

export const handleOpenOptimizeDialog = (setOpenOptimizeDialog) => {
  setOpenOptimizeDialog(true);
};

export const handleCancelOptimize = (setOpenOptimizeDialog) => {
  setOpenOptimizeDialog(false);
};

export const handleApplyOptimize = (optimizeOptions) => {
  console.log("Optimización aplicada con opciones:", optimizeOptions);
};

export const handleOpenSaveDialog = (setOpenSaveDialog) => {
  setOpenSaveDialog(true);
};

export const handleCancelSave = (setOpenSaveDialog) => {
  setOpenSaveDialog(false);
};

export const handleSaveDocument = (documentData, companyID) => {
  console.log(
    "Guardando documento con datos:",
    documentData,
    "para empresa:",
    companyID
  );
};
