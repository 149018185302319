import React, { useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  getFirestore,
  collection,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BasicListElements from "../../../../components/BasicListElements/BasicListElements";
import { Box, Typography } from "@mui/material";
import {
  fetcherRoles,
  fetcherUsers,
} from "../../../../utils/FectcherData/FectcherUser";
import { DataGrid } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ReusableVewData from "../../../../components/ReusableVewData/ReusableVewData";
import ReusableFormEdit from "../../../../components/ReusableFormEdit/ReusableFormEdit";
import { db } from "../../../../firebase";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import generateReportByData from "../../../../utils/Reports/generateReportByData";
import { useSelector } from "react-redux";
import {
  fetcherSingleUserSuportTickets,
  fetcherUserSuportTickets,
} from "../../../../utils/FectcherData/FectcherData";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { format } from "date-fns";
import { fDate, fDateTime } from "../../../../utils/objects/FormatTime";
//import { FectcherOrders, endpoints } from "../../../Utils/FectcherOrders";
const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});
const SupportTicket = () => {
  const companyId = useSelector((store) => store.usuario.user.company);
  const [bodyItems, setBodyItems] = useState([]);
  const [allDatabodyItems, setAlDataBodyItems] = useState([]);
  const [rolesOptiones, setRolesOptiones] = useState([]);
  const [render, setRender] = useState(false);
  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [statusActive, setStatusActive] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (companyId) {
      getData(companyId);
    }
  }, [render, companyId]);

  const getData = async (companyId) => {
    const tickets = await fetcherUserSuportTickets(companyId);
    // console.log(tickets);
    setBodyItems(tickets);
    setAlDataBodyItems(tickets);
  };

  useEffect(() => {
    upDateTable();
  }, [bodyItems]);

  const handleView = (row) => {
    setInitialValues(row);
    setOpen(true);
  };
  const handleEdit = (row) => {
    console.log("Editar", row);
    setInitialValues(row);
    setOpenEdit(true);
  };
  const handleActivate = async (row) => {
    row.isActive = true;
    setStatusActive(true);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_SUPPORT);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { isActive: true });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeactivate = async (row) => {
    row.isActive = false;
    setStatusActive(false);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_SUPPORT);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { isActive: false });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };

  const statusOptions = [
    { value: "Created", label: "Created" },
    { value: "Open", label: "Open" },
    { value: "Progress", label: "Progress" },
    { value: "Pending", label: "Pending" },
    { value: "Resolved", label: "Resolved" },
    { value: "Closed", label: "Closed" },
  ];
  const statusOptionsValues = [
    { Created: 0 },
    { Open: 1 },
    { Progress: 2 },
    { Pending: 3 },
    { Resolved: 4 },
    { Closed: 5 },
  ];

  const upDateTable = () => {
    const renderActionButtons = (params) => (
      <Box sx={{ minWidth: "200px" }}>
        {/* Botón Ver */}
        <IconButton
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
          aria-label="ver"
          onClick={() => handleView(params.row)}
        >
          <VisibilityIcon />
        </IconButton>

        {/* Botón Editar */}
        <IconButton
          aria-label="editar"
          onClick={() => handleEdit(params.row)}
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
        >
          <EditIcon />
        </IconButton>

        {/* Botón Activar/Desactivar */}
        {params.row.isActive ? (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#21ba45 !important",
            }}
            aria-label="desactivar"
            onClick={() => handleDeactivate(params.row)}
          >
            <ToggleOffIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#1e2158 !important",
            }}
            aria-label="activar"
            onClick={() => handleActivate(params.row)}
          >
            <ToggleOnIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );

    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: true,
      },
      {
        field: "userId",
        headerName: "userId",
        width: 200,
        editable: true,
      },
      {
        field: "type",
        headerName: "type",
        width: 100,
      },
      {
        field: "date",
        headerName: "date",
        width: 100,
      },
      {
        field: "orderId",
        headerName: "orderId",
        width: 200,
        editable: true,
      },
      {
        field: "ticketStatus",
        headerName: "ticketStatus",
        width: 200,
        editable: true,
      },
      {
        field: "ticketStatusDescriptor",
        headerName: "ticketStatusDescriptor",
        width: 150,
        editable: true,
      },
      {
        field: "observationAdmin",
        headerName: "observationAdmin",
        width: 150,
        editable: true,
      },
      {
        field: "actions",
        headerName: "Acciones",
        width: 300,
        renderCell: renderActionButtons,
        sortable: false,
        filterable: false,
      },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < bodyItems.length; i++) {
      rows.push({
        id: bodyItems[i].id,
        userId: bodyItems[i].userId,
        type: bodyItems[i].type,
        orderId: bodyItems[i].orderId,
        ticketStatus: bodyItems[i].ticketStatus,
        ticketStatusDescriptor: bodyItems[i].ticketStatusDescriptor,
        observationAdmin: bodyItems[i].observationAdmin,
        date: fDate(bodyItems[i].date.seconds * 1000),
      });
    }
    rows.sort((a, b) => new Date(b.date) - new Date(a.date));
    setRows(rows);
  };

  // Formolario Nuevo Item
  const formFields = [
    { variable: "id", label: "id", type: "string" },
    { variable: "userId", label: "userId", type: "string" },
    { variable: "date", label: "date", type: "Timestamp" },
    { variable: "type", label: "type", type: "string" },
    { variable: "orderId", label: "orderId", type: "string" },
    {
      variable: "ticketStatus",
      label: "ticketStatus",
      type: "string",
    },
    {
      variable: "ticketStatusDescriptor",
      label: "ticketStatusDescriptor",
      type: "string",
    },
    {
      variable: "observationAdmin",
      label: "observationAdmin",
      type: "array",
    },
  ];

  // Formolario Nuevo Item
  const formFieldsEdit = [
    { variable: "id", label: "id", type: "string" },
    { variable: "userId", label: "userId", type: "string" },
    { variable: "type", label: "type", type: "string" },
    {
      variable: "ticketStatusDescriptor",
      label: "ticketStatusDescriptor",
      type: "select",
      options: statusOptions,
    },
  ];

  const handleAddItem = (formData) => {
    const itemIndex = rows.findIndex((item) => item.id === formData.id);

    if (itemIndex > -1) {
      const updatedRows = rows.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            ...formData,
          };
        }
        return item;
      });
      updatedRows.sort((a, b) => new Date(b.date) - new Date(a.date));
      setRows(updatedRows);
    } else {
      const newItem = {
        id: formData.id || rows.length + 1,
        ...formData,
      };
      setRows([...rows, newItem]);
    }
  };

  const handleSave = async (formData) => {
    console.log(formData);

    const caracteresValidos =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const generarCadenaAleatoria = (longitud) => {
      let resultado = "";
      for (let i = 0; i < longitud; i++) {
        const indice = Math.floor(Math.random() * caracteresValidos.length);
        resultado += caracteresValidos.charAt(indice);
      }
      return resultado;
    };

    // Función para crear o actualizar un único documento
    const upsertDocument = async (docData) => {
      console.log(docData);

      let id = docData.id || generarCadenaAleatoria(10);
      if (!docData.id || docData.id === "") {
        docData.id = id;
      }

      // Encuentra el valor del estado basado en el descriptor.
      const statusObj = statusOptionsValues.find((status) =>
        status.hasOwnProperty(docData.ticketStatusDescriptor)
      );
      const statusValue = statusObj
        ? statusObj[docData.ticketStatusDescriptor]
        : undefined;

      if (statusValue !== undefined) {
        docData.ticketStatus = statusValue;
      } else {
        console.error(
          "No se encontró un valor de estado válido para el descriptor:",
          docData.ticketStatusDescriptor
        );
        docData.ticketStatus = 0;
        // Manejar el error según sea necesario, como asignar un valor predeterminado o lanzar un error.
      }

      const collRef = collection(db, process.env.REACT_APP_COL_SUPPORT);
      const docRef = doc(collRef, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, docData);
        console.log("Documento actualizado con éxito.");
      } else {
        await setDoc(docRef, docData);
        console.log("Documento creado con éxito.");
      }

      handleAddItem(docData); // Asumiendo que esto actualiza la UI adecuadamente
    };

    if (Array.isArray(formData)) {
      // Si formData es un arreglo, procesa cada objeto individualmente
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
    setOpenEdit(false);
  };

  const createReportByData = () => {
    console.log("Crear reporte");
    generateReportByData(bodyItems);
  };

  const filterView = (typeTickets) => {
    let filterData = allDatabodyItems.filter(
      (item) => item.type === typeTickets
    );
    setBodyItems(filterData);
  };

  return (
    <div>
      <Typography variant={"h2"} sx={{ color: "#F8F8F8" }}>
        Gestión Soporte
      </Typography>
      <Button
        variant="outline"
        startIcon={<DownloadIcon />}
        onClick={() => createReportByData(true)}
        sx={{ mb: 2, color: "#f8f8f8" }} // margen inferior para separar del DataGrid
      >
        Exportar
      </Button>
      <Button
        variant="outline"
        startIcon={<ConfirmationNumberIcon />}
        onClick={() => filterView("billingTicket")}
        sx={{ mb: 2, color: "#f8f8f8" }} // margen inferior para separar del DataGrid
      >
        Facturación
      </Button>
      <Button
        variant="outline"
        startIcon={<ConfirmationNumberIcon />}
        onClick={() => filterView("supportTicket")}
        sx={{ mb: 2, color: "#f8f8f8" }} // margen inferior para separar del DataGrid
      >
        Operación
      </Button>
      <ReusableVewData
        open={open}
        handleClose={() => setOpen(false)}
        formFields={formFields}
        initialValues={initialValues}
      />
      {/* // Formolario view Item */}
      <ReusableFormEdit
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        formFields={formFieldsEdit}
        handleSave={handleSave}
        initialValues={initialValues}
      />
      {/* // Formolario View Item */}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        className={classes.root}
        groupingModel={["date"]}
      ></DataGrid>
    </div>
  );
};

export default SupportTicket;
