import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Grid2,
  Typography,
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetcherOrdersUserdriver,
  fetcherUserdriver,
  fetcherRoutes,
} from "../../../utils/FectcherData/FectcherUser";
import Rendimiento from "./Components/Rendimiento";
import Historial from "./Components/Historial";
import Trayectos from "./Components/Trayectos";
import Pagos from "./Components/Pagos";

const DriversDetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUserData] = useState(null);
  const [driverOperationData, setDriverOperationData] = useState(null);
  const [activeComponent, setActiveComponent] = useState("Historial");
  const [dailyTrips, setDailyTrips] = useState(null);
  const [driverOperationDataAgroup, setDriverOperationDataAgroup] =
    useState(null);

  useEffect(() => {
    if (id) {
      buscarConductor(id);
    }
  }, [id]);

  useEffect(() => {
    if (driverOperationData) {
      const groupedOrders = groupOrdersByDate(driverOperationData);
      setDriverOperationDataAgroup(groupedOrders);
    }
  }, [driverOperationData]);

  const buscarConductor = async (id) => {
    let userData = await fetcherUserdriver(id);
    let Orders = await fetcherOrdersUserdriver(id);
    let routes = await fetcherRoutes(id);
    setDriverOperationData(Orders);
    setUserData(userData);
    setDailyTrips(routes);
  };

  function timestampToDate(timestamp) {
    const date = new Date(timestamp.seconds * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function groupOrdersByDate(orders) {
    // Agrupar las órdenes por fecha
    const groupedOrders = orders.reduce((acc, order) => {
      const date = timestampToDate(order.createdAt); // Suponiendo que esta función convierte timestamps a fechas
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(order);
      return acc;
    }, {});

    // Ordenar las claves (fechas) de más nuevo a más viejo
    const sortedGroupedOrders = Object.keys(groupedOrders)
      .sort((a, b) => new Date(b) - new Date(a)) // Convertir las fechas a objetos Date y ordenarlas
      .reduce((acc, date) => {
        acc[date] = groupedOrders[date];
        return acc;
      }, {});

    return sortedGroupedOrders;
  }

  function printOrdersCountByDate(groupedOrders) {
    const elements = [];
    for (const [date, orders] of Object.entries(groupedOrders)) {
      elements.push(
        <div
          key={date}
        >{`${date}        =>   Ordenes asignadas : ${orders.length} `}</div>
      );
    }
    return elements;
  }

  const HistorialDriver = () => (
    <Historial driverOperationData={driverOperationData} />
  );
  const RendimientoDriver = () => (
    <Rendimiento driverOperationData={driverOperationData} />
  );
  const TrayectosDriver = () => (
    <Trayectos
      dailyTrips={dailyTrips}
      driverOperationData={driverOperationData}
    />
  );
  const PagosDriver = () => <Pagos driverOperationData={driverOperationData} />;

  const renderComponent = () => {
    switch (activeComponent) {
      case "HistorialDriver":
        return <HistorialDriver />;
      case "RendimientoDriver":
        return <RendimientoDriver />;
      case "TrayectosDriver":
        return <TrayectosDriver />;
      case "PagosDriver":
        return <PagosDriver />;
      default:
        return <HistorialDriver />;
    }
  };

  return user ? (
    <Grid2 sx={{ m: 1 }}>
      <Grid2 item>
        <h2 className="h2-withe">Detalles de : {id}</h2>
      </Grid2>
      <Grid2 container sx={{ minHeight: "100%" }}>
        <Grid2 xs={12} md={4} xl={3}>
          {/* id="mi-cuentaContainer"> */}
          <Card sx={{ m: 1 }}>
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  src={user.avatar}
                  sx={{
                    height: 100,
                    mb: 2,
                    width: 100,
                  }}
                />
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {user.name}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {user.dni}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.bicciNumber}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.phone}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  {`${user.email}`}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  {user.Address}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  {user.Address}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  Total entregas
                  {
                    driverOperationData.filter((item) => item.status === 8)
                      .length
                  }
                </Typography>
              </Box>
            </CardContent>
            <Divider />

            <CardActions>
              <Box>
                <List>
                  <ListItem
                    sx={{
                      border: "1px solid #E6E8F0",
                      overflow: "hidden",
                      borderRadius: "15px",
                      padding: "5px",
                      backgroundColor: "#5e72b9",
                      color: "#f8f8f8",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                      m: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => setActiveComponent("HistorialDriver")}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "1.2rem", // Aumenta el tamaño del texto
                        fontWeight: 600, // Negrita
                      }}
                      primary="Historial"
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      border: "1px solid #E6E8F0",
                      overflow: "hidden",
                      borderRadius: "15px",
                      padding: "5px",
                      backgroundColor: "#5e72b9",
                      color: "#f8f8f8",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                      m: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => setActiveComponent("RendimientoDriver")}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "1.2rem", // Aumenta el tamaño del texto
                        fontWeight: 600, // Negrita
                      }}
                      primary="Rendimiento"
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      border: "1px solid #E6E8F0",
                      overflow: "hidden",
                      borderRadius: "15px",
                      padding: "5px",
                      backgroundColor: "#5e72b9",
                      color: "#f8f8f8",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                      m: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => setActiveComponent("TrayectosDriver")}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "1.2rem", // Aumenta el tamaño del texto
                        fontWeight: 600, // Negrita
                      }}
                      primary="Trayectos"
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      border: "1px solid #E6E8F0",
                      overflow: "hidden",
                      borderRadius: "15px",
                      padding: "5px",
                      backgroundColor: "#5e72b9",
                      color: "#f8f8f8",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                      m: 1,
                      cursor: "pointer",
                    }}
                    onClick={() => setActiveComponent("PagosDriver")}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "1.2rem", // Aumenta el tamaño del texto
                        fontWeight: 600, // Negrita
                      }}
                      primary="Pagos"
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      border: "1px solid #E6E8F0",
                      overflow: "hidden",
                      borderRadius: "15px",
                      padding: "5px",
                      backgroundColor: "#5e72b9",
                      color: "#f8f8f8",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                      m: 1,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        `https://bicci-7ed2f.firebaseapp.com/management/drivers/${id}/summary`,
                        "_blank" // Abre en una nueva pestaña
                      )
                    }
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: "1.2rem", // Aumenta el tamaño del texto
                        fontWeight: 600, // Negrita
                      }}
                      primary="Administración de conductores"
                    />
                  </ListItem>
                </List>
              </Box>
            </CardActions>
          </Card>
          <br></br>
          <Card sx={{ m: 1 }}>
            {/* className="typeMiniforms"> */}
            <CardContent>
              <h3>Resumen General</h3>
              <div>Totales : {driverOperationData.length}</div>
              <div>
                Entregadas :{" "}
                {driverOperationData.filter((item) => item.status === 8).length}
              </div>
              <div>
                No entregadas :{" "}
                {driverOperationData.filter((item) => item.status !== 8).length}
              </div>
            </CardContent>
            <Divider />
          </Card>
        </Grid2>

        <Grid2 xs={12} md={8} xl={9}>
          <Card sx={{ m: 1, width: "100%", minHeight: "100%" }}>
            <CardContent>
              {/* {driverOperationDataAgroup ? (
                <>{printOrdersCountByDate(driverOperationDataAgroup)}</>
              ) : null} */}
              <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <>{renderComponent()}</>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Grid2>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default DriversDetails;
