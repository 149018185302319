import { collection, getDocs, query, where } from "firebase/firestore";
import { useMapMenuStates } from "./states";
import { db } from "../../../firebase";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { PlansDialog } from "./dialogs";
import { handleClosePlansDialog } from "./handlers";

const ButtonHandleOpenPlansDialog = ({ companyID, handleSelectPlan }) => {
  const statesRoute = useMapMenuStates();
  const [openPlansDialog, setOpenPlansDialog] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {}, [openPlansDialog]);

  const HandleOpenPlansDialog = async () => {
    console.log("HandleOpenPlansDialog");

    // Cargar los datos
    const plansQuery = query(
      collection(db, "xpress_deliveryPlans"),
      where("propietary", "==", companyID)
    );

    const querySnapshot = await getDocs(plansQuery);
    const loadedPlans = [];
    querySnapshot.forEach((doc) => {
      loadedPlans.push({ id: doc.id, ...doc.data() });
    });

    console.log(loadedPlans);
    setPlans(loadedPlans);
    statesRoute.setCurrentPage(0);
    setOpenPlansDialog(true);
  };

  return (
    <>
      <Button onClick={HandleOpenPlansDialog}>Ver planes guardados</Button>

      <PlansDialog
        open={openPlansDialog}
        handleClose={() => handleClosePlansDialog(setOpenPlansDialog)}
        handleSelectPlan={handleSelectPlan}
        plans={plans}
      />
    </>
  );
};

export default ButtonHandleOpenPlansDialog;
