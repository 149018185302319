// ReusableForm.js

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
} from "@mui/material";
import { translateStatesSendsFalabbelaSelector } from "../../utils/objects/translateStates";
import { FormControl, MenuItem } from "@mui/material";
import {
  SendExeptionsIntegrations,
  TempRegisterGeorefcExeptions,
} from "../../redux/accionesOrders";
import { useDispatch } from "react-redux";

const ReusableFormExceptions = ({
  compyOrder,
  open,
  handleClose,
  orderID,
  riderDestails,
  onSave,
}) => {
  const dispatch = useDispatch();
  const [data, setFormData] = useState({
    id: orderID,
    status: "",
  });

  const handleSave = async (data) => {
    // console.log(
    //   "Guardando excepción con datos:",
    //   JSON.stringify(data, null, 2)
    // );

    if (!data.id || !data.status) {
      console.error("Error: La excepción no tiene un ID o estado válido.");
      return;
    }

    if (compyOrder.prefix === "F") {
      let requets = {
        data: data,
        riderDestailsLocation: riderDestails ? riderDestails : null,
      };

      dispatch(TempRegisterGeorefcExeptions(requets));
      onSave();
    } else {
      const foundObject = compyOrder.nonDelivery.find(
        (item) => item.reasonCode === data.status
      );

      if (!foundObject) {
        console.error(
          "Error: No se encontró el objeto correspondiente en nonDelivery para el status:",
          data.status
        );
        return;
      }

      let requets = {
        id: data.id,
        status: foundObject.intState,
        date: new Date(),
        reason: foundObject.name,
        reasonCode: foundObject.reasonCode,
      };

      dispatch(SendExeptionsIntegrations(requets));
      onSave();
    }
  };

  const handleChange = (value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        id: orderID,
        status: value,
      };
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: 500,
          height: 400,
        },
      }}
    >
      <DialogTitle sx={{ color: "#030303" }} id="form-dialog-title">
        Enviar Exception (Motivo de no entrega)
      </DialogTitle>
      <DialogContent>
        {" "}
        <FormControl fullWidth key={"index"} margin="dense">
          <InputLabel id="enviarExceptionLabel">
            {"Seleccione Exception"}
          </InputLabel>
          {compyOrder.prefix === "F" ? (
            <Select
              labelId="enviarExceptionLabel"
              id="enviarExceptionSelect"
              value={data.status || ""}
              onChange={(e) => handleChange(e.target.value)}
              label="Seleccione Exception"
            >
              {translateStatesSendsFalabbelaSelector.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="enviarExceptionLabel"
              id="enviarExceptionSelect"
              value={data.status || ""}
              onChange={(e) => handleChange(e.target.value)}
              label="Seleccione Exception"
            >
              {compyOrder.nonDelivery.map((option, index) => (
                <MenuItem key={index} value={option.reasonCode}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleSave(data)} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormExceptions;
