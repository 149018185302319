import { useState } from "react";

export const useMapMenuStates = () => {
  const [isChangeMode, setIsChangeMode] = useState(false);
  const [openPlansDialog, setOpenPlansDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openOptimizeDialog, setOpenOptimizeDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [plans, setPlans] = useState([]);
  const [currentPlans, setCurrentPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [optimizeOptions, setOptimizeOptions] = useState({
    retornable: false,
    provider: "",
  });

  const [documentData, setDocumentData] = useState({
    name: "",
    date: "",
    autoAssignDrivers: false,
    routeDrivers: {},
  });

  return {
    isChangeMode,
    setIsChangeMode,
    openPlansDialog,
    setOpenPlansDialog,
    openFilterDialog,
    setOpenFilterDialog,
    openOptimizeDialog,
    setOpenOptimizeDialog,
    openSaveDialog,
    setOpenSaveDialog,
    plans,
    setPlans,
    currentPlans,
    setCurrentPlans,
    currentPage,
    setCurrentPage,
    endIndex,
    setEndIndex,
    availableStatuses,
    setAvailableStatuses,
    selectedStatuses,
    setSelectedStatuses,
    optimizeOptions,
    setOptimizeOptions,
    documentData,
    setDocumentData,
  };
};
