import React from "react";
import Bannerfooter from "../../../../../components/Bannerfooter/Bannerfooter";
import useGlobalConfig from "../../../../../hooks/globalConfig";
import { Grid2, Box, Typography } from "@mui/material";
import MapaCobertura from "../../MapaCobertura";
const Pos2 = () => {
  const { cobertura } = useGlobalConfig();
  const footermensaje = {
    className: "",
    title: "",
    paragramh: "",
  };
  return (
    <div>
      <Box sx={{ maxWidth: "1200px", mx: "auto", py: 6, px: 3 }}>
        {/* Título principal */}
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          sx={{ mb: 4, fontSize: { xs: "2rem", md: "3rem", color: "#030303" } }}
        >
          Acerca de Nosotros
        </Typography>

        {/* Sección 1: Quiénes Somos */}
        <Grid2 container spacing={4} alignItems="center">
          <Grid2 item xs={12} md={6}>
            <Box
              component="img"
              src={cobertura}
              alt="Cobertura"
              sx={{
                width: "100%",
                height: "auto",
                maxWidth: "500px",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid2>

          <Grid2 item xs={12} md={6}>
            {/* Separador */}
            <Box
              sx={{
                my: 6,
                borderBottom: "3px solid #5de7f7",
                width: "50%",
                mx: "auto",
              }}
            />
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: "#591e8f" }}
            >
              ¿QUIÉNES SOMOS?
            </Typography>
            <Typography sx={{ mt: 2, fontSize: "1.2rem", color: "#030303" }}>
              Somos una empresa que nace desde la necesidad de ayudar a la
              comunidad y al medio ambiente. Promovemos la electromovilidad con
              nuestros vehículos. Para el año 2020 formamos Bicci Delivery para
              dar un valor adicional al repartidor y al comercio, haciendo más
              eco-sustentable el despacho.
            </Typography>
            <Typography sx={{ mt: 2, fontSize: "1.2em", color: "#030303" }}>
              Nuestra constante innovación nos hizo dar un paso más con{" "}
              <strong>BICCI EXPRESS</strong>, donde, con tecnología innovadora y
              más vehículos eco-sustentables, buscamos ofrecer una experiencia
              única y eficiente para nuestros clientes.
            </Typography>
          </Grid2>
        </Grid2>
        {/* Separador */}
        <Box
          sx={{
            my: 6,
            borderBottom: "3px solid #5de7f7",
            width: "50%",
            mx: "auto",
          }}
        />
        <Box x={{ p: "10px" }}>
          <Typography variant="h5" fontWeight="bold" sx={{ color: "#591e8f" }}>
            NUESTRAS ENTREGAS
          </Typography>
          <Typography sx={{ mt: 2, fontSize: "1.2rem", color: "#591e8f" }}>
            Región Metropolitana de Santiago
          </Typography>
          <MapaCobertura></MapaCobertura>
        </Box>

        {/* Separador */}
        <Box
          sx={{
            my: 6,
            borderBottom: "3px solid #5de7f7",
            width: "50%",
            mx: "auto",
          }}
        />

        {/* Sección 2: Nuestro Equipo */}
        <Grid2 container spacing={4} alignItems="center">
          <Grid2 item xs={12} md={6}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: "#591e8f" }}
            >
              NUESTRO EQUIPO
            </Typography>
            <Typography sx={{ mt: 2, fontSize: "1.2rem", color: "#030303" }}>
              Nuestro equipo de reparto está conformado por personal calificado
              y capacitado para realizar el despacho de tus productos con un
              alto nivel de compromiso y profesionalismo.
            </Typography>
          </Grid2>
          <Grid2 item xs={12} md={6}>
            <Box
              component="img"
              src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fphoto5%20copia.png?alt=media&token=1b557802-4dea-42b9-85af-97a58e3a0f2e"
              alt="Nuestro Equipo"
              sx={{
                width: "100%",
                height: "auto",
                maxWidth: "500px",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid2>
        </Grid2>
      </Box>
      <Bannerfooter />
    </div>
  );
};

export default Pos2;
