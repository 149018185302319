import React, { createRef, useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FadeInOut from "../../../components/animations/FadeInOut";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import moment from "moment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Popup from "../../../components/Popup";
import {
  Card,
  Divider,
  CardHeader,
  LinearProgress,
  Box,
  IconButton,
  Button,
  Switch,
  Grid2,
} from "@mui/material";
import { customLocaleText } from "../../../utils/objects/CustomLocaleTextTables";
import { translateStatesBicci } from "../../../utils/objects/translateStates";
import { useNavigate } from "react-router-dom";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const Drivers = (props) => {
  const navigate = useNavigate();
  const developIDs = ["LVY9zIL1OAbep6OvrrjEovLsKRD2"];
  const userUid = useSelector((store) => store.usuario);

  //var companyId=null
  //const {userBalance  } = useObtenerBalance(userCompanyID)
  const [companyId, setcompanyId] = useState("");
  const [emailRider, setEmailRider] = useState("");
  const [isvalid, setIsValid] = useState("");
  const [userRider, setUserRiderr] = React.useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [error, setError] = useState(null);
  const [ridersRows, setRidersRows] = React.useState("");
  const [ridersRowsInactive, setRidersRowsInactive] = React.useState("");

  const [showPopup, setshowPopup] = useState(false);

  const [typePop, setTypePop] = useState(null);

  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);

  const [cantListItems, setcantListItems] = React.useState({
    Activos: 0,
    Conectados: 0,
    Invitados: 0,
    Inactivos: 0,
  });

  const [objRider, setobjRider] = React.useState({
    title: "showPopupWiewRiders",
    texto: "Lorem ipsum dolor.",
  });

  const [toggle, setToggle] = useState(false);

  const { userdata, data } = useObtenerDatosdeCliente(userUid.user.uid);

  const { ridersDataByCompany, listRidersDataByCompany } =
    useGetDataRiderByCompany(companyId);

  const listItems = [
    { id: "Activos" },
    { id: "Conectados" },
    { id: "Invitados" },
    { id: "Inactivos" },
  ];

  useEffect(() => {}, [toggle]);

  useEffect(() => {
    //console.log(companyId)
  }, [companyId]);

  useEffect(() => {
    //console.log(userdata)
    if (userdata) {
      setcompanyId(userdata.userCompanyID);
    }
  }, [data, userdata]);

  useEffect(() => {
    // getBicciDriversData();
    if (ridersDataByCompany && ridersDataByCompany.length > 0) {
      const filteredRiders = ridersDataByCompany.filter(
        (rider) => !developIDs.includes(rider.id)
      );
      upDateTable(filteredRiders);
    }
  }, [ridersDataByCompany]);

  const togglePopup = () => {
    setshowPopup(!showPopup);
  };

  const toggleShowEditNew = () => {
    setShowEdit(!showEdit);
    //console.log('Abrir inviatcion')
  };

  const invitarUsuariosRider = async (e) => {
    if (!emailRider.trim()) {
      setError("Ingrese un email valido");
      setIsValid(false);
      return;
    }

    if (isvalid === true) {
      let internalValid = {
        validacion: "",
        get validacionGet() {
          return this.validacion;
        },
        set validacionSet(value) {
          this.validacion = value;
        },
      };
      let rider = {
        data: {
          invitedAt: "",
          email: emailRider,
          createdAt: Date.now(),
          createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
          id: "",
          bicciNumber: "",
          isblocked: false,
          isDeleted: false,
          isInvited: true,
          wasAccepted: false,
        },
        get dataRider() {
          return this.data;
        },
        get email() {
          return this.data.email;
        },
        get id() {
          return this.data.id;
        },
        set invitedAt(invitedAt) {
          this.data.invitedAt = invitedAt;
        },
        set email(email) {
          this.data.email = email;
        },
        set createdAt(createdAt) {
          this.data.createdAt = createdAt;
        },
        set id(id) {
          this.data.id = id;
        },
        set bicciNumber(bicciNumber) {
          this.data.bicciNumber = bicciNumber;
        },
      };

      const collectionRef = collection(db, process.env.REACT_APP_COL_COMPANIES);
      const docRef = doc(collectionRef, userdata.userCompanyID);
      const collectionRidersCompany = collection(docRef, "companydrivers");

      try {
        const driversCollectionRef = collection(
          db,
          `${process.env.REACT_APP_COL_DRIVERS}`
        );

        const q = query(driversCollectionRef, where("email", "==", emailRider));
        const driverCompanyQuerySnapshot = await getDocs(q);
        const driverCompanytemp = [];
        driverCompanyQuerySnapshot.forEach((doc) => {
          // Agregamos los datos del documento a un array.
          driverCompanytemp.push({ id: doc.id, ...doc.data() });
        });

        if (driverCompanytemp.length > 0) {
          setError("Ya se envío una invitación a este usuario ");
          internalValid.validacionSet = false;
          setShowEdit(true);
          throw e;
        } else {
          internalValid.validacionSet = true;
          const riderDocRef = doc(collectionRidersCompany, rider.email);

          await setDoc(riderDocRef, rider.dataRider);
          toggleShowEditNew();
        }
      } catch (error) {
        console.log(error);
      }

      if (internalValid.validacion === true) {
        try {
          const driversCollectionRef = collection(
            db,
            `${process.env.REACT_APP_COL_USERS_DRIVERS}`
          );
          const q = query(
            driversCollectionRef,
            where("email", "==", emailRider.trim())
          );
          const driverCompanyQuerySnapshot = await getDocs(q);
          const response = driverCompanyQuerySnapshot.docs[0].data();

          if (response) {
            rider.email = response.email;
            rider.id = response.id;
            rider.invitedAt = moment(Date.now()).format(
              "DD MMMM YYYY, h:mm:ss a"
            );
            rider.bicciNumber = response.bicciNumber;
            const riderDocRef = doc(db, docRef.path, rider.email);
            await updateDoc(riderDocRef, rider.dataRider);
            setShowEdit(false);
            setEmailRider("");
          } else {
            setShowEdit(false);
            setEmailRider("");
          }
          //var driverCompanyBxResult = driverCompanyBX.map(doc => ({id: doc.id, ...doc.data()}))
          //console.log(driverCompanyBxResult)
        } catch (error) {
          //console.log(error)
        }

        var array = ridersRowsInactive.map((item) => item);
        array.push({
          id: Math.floor(Math.random() * 10000 + 1).toString(),
          name: "---",
          email: rider.email,
          bicciNumber: "No Asignado",
          estado: "Invitación enviada",
        });
        setRidersRowsInactive(array);
        //console.log(ridersRowsInactive)
      }
    }
  };

  const handlechage = (e) => {
    const validateEmail = (email) => {
      return email.match(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
      );
    };
    const validate = (email) => {
      if (validateEmail(email)) {
        setError(null);
        return true;
      } else {
        setError("Ingrese un email valido");
        return false;
      }
    };
    let result = validate(e.target.value);
    if (e.target.value === "") {
      setError(null);
    }
    setIsValid(result);
    setEmailRider(e.target.value);
  };

  const handleTrackingChange = async (id, newValue) => {
    // Aquí actualizas el valor de `requireTracking` en Firebase
    console.log(`Cambiando tracking para el rider con ID ${id} a ${newValue}`);

    try {
      if (newValue === true) {
        // Inavilitar desactivacion
        let colletionRef = collection(db, "drivers");
        let docReft = doc(colletionRef, id);
        await updateDoc(docReft, {
          requireTracking: newValue,
        });
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id ? { ...row, requireTracking: newValue } : row
          )
        );
        console.log("Tracking actualizado en Firebase");
      }
    } catch (error) {
      console.error("Error actualizando tracking: ", error);
    }
  };

  const handleListMuenusChange = async (id, newValue) => {
    // Aquí actualizas el valor de `requireTracking` en Firebase
    console.log(`Cambiando tracking para el rider con ID ${id} a ${newValue}`);

    try {
      let colletionRef = collection(db, "drivers");
      let docReft = doc(colletionRef, id);

      await updateDoc(docReft, {
        hiddenListMenus: newValue,
      });
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, hiddenListMenus: newValue } : row
        )
      );
      console.log("hiddenListMenus actualizado en Firebase");
    } catch (error) {
      console.error("Error actualizando tracking: ", error);
    }
  };

  const viewDriverPerfil = (id) => {
    navigate(`/dashboard/drivers/${id}`);
  };

  const HandleRemove = (id) => {
    console.log(id);
  };

  const upDateTable = (ridersView) => {
    const renderActionButtons = (params) => {
      if (params.row.id !== undefined && params.row.id !== "") {
        return (
          <Box
            sx={{
              display: "flex", // Arrange the buttons in a row
              flexDirection: "row",
              justifyContent: "space-between", // Ensure there's space between buttons
              gap: "8px", // Add some space between buttons
            }}
          >
            <Box>
              <Switch
                checked={params.row.hiddenListMenus || false} // valor actual del switch
                onChange={(event) =>
                  handleListMuenusChange(params.row.id, event.target.checked)
                }
                color="primary"
              />
              {params.row.hiddenListMenus ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </Box>

            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="Editar"
              onClick={() => viewDriverPerfil(params.row.id)} //handleEdithNavigateRows(params.row)}
            >
              <ModeEditIcon />
            </IconButton>
          </Box>
        );
      }
    };

    const renderActivateTracking = (params) => {
      return (
        <Switch
          checked={params.row.requireTracking || false} // valor actual del switch
          onChange={(event) =>
            handleTrackingChange(params.row.id, event.target.checked)
          }
          color="primary"
        />
      );
    };

    const renderDeleted = (params) => {
      return (
        <IconButton
          sx={{
            width: "30px !important",
            color: "#1e2158 !important",
          }}
          aria-label="Editar"
          onClick={() =>
            console.log("button eliminar  conductor de una empresa")
          } //handleEdithNavigateRows(params.row)}
        >
          <DeleteForeverIcon sx={{ color: "red" }} />
        </IconButton>
      );
    };

    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
      },
      {
        field: "name",
        headerName: "NOMBRE",
        width: 200,
      },
      {
        field: "email",
        headerName: "CORREO",
        width: 200,
      },
      {
        field: "phone",
        headerName: "TELÉFONO",
        width: 150,
      },
      {
        field: "bicciNumber",
        headerName: "BICCI NÚMERO",
        width: 100,
      },

      // {
      //   field: "rate",
      //   headerName: "CALIFICACIÓN",
      //   width: 100,
      // },
      {
        field: "active",
        headerName: "ACTIVO",
        width: 50,
        renderCell: (params) => (params.value ? "Sí" : "No"),
      },
      {
        field: "online",
        headerName: "EN LÍNEA",
        width: 50,
        renderCell: (params) => (params.value ? "Sí" : "No"),
      },
      {
        field: "requireTracking",
        headerName: "TRACKING",
        width: 100,
        renderCell: renderActivateTracking,
      },
      {
        field: "appVersion",
        headerName: "APP VER",
        width: 100,
      },
      {
        field: "vehicleID",
        headerName: "ID VEHÍCULO",
        width: 200,
      },
      {
        field: "actions",
        headerName: "ACCIONES",
        width: 150,
        renderCell: renderActionButtons,
      },
      // {
      //   field: "remove",
      //   headerName: "REMOVER",
      //   width: 50,
      //   renderCell: renderDeleted,
      // },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < ridersView.length; i++) {
      //rows.push(ridersView[i]);
      rows.push({
        id: ridersView[i].id,
        name: ridersView[i].name || "", // Ajuste del nombre
        email: ridersView[i].email || "", // Añadido campo email
        phone: ridersView[i].phone, // Añadido campo teléfono [7].phone
        bicciNumber: ridersView[i].bicciNumber || "", // Añadido campo bicciNumber
        active: ridersView[i].active || false, // Añadido campo de estado activo
        online: ridersView[i].online || false, // Añadido campo de estado online
        requireTracking: ridersView[i].requireTracking || false,
        appVersion: ridersView[i].appVersion || "", // Añadido campo dirección
        vehicleID: ridersView[i].vehicleID || "", // Añadido campo ID del vehículo
        hiddenListMenus: ridersView[i].hiddenListMenus || false,
      });
    }

    setRows(rows);
  };

  const HandleSeleccions = (ids) => {
    console.log(ids);
    // const selectedRowsData = ids.map((id) =>
    //   rows.find((row) => row.id === id)
    // );
    // setSelectedData(selectedRowsData);
    // setSelectedIDs(ids);
    // UpdateOrderForAcctions(ids);
    // if (ids.length > 0) {
    //   handleViewTools(true);
    // } else {
    //   handleViewTools(false);
    // }
  };

  return (
    <>
      <Grid2 celled="internally" id="expresscuentaContent">
        <h2 className="h2-withe">Mis Riders</h2>

        <Grid2>
          <Grid2
            computer={16}
            mobile={16}
            tablet={16}
            className="dash-container-colmun"
          >
            <Card className="typeMiniforms">
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Card sx={{ maxWidth: "350px", left: "0px" }}>
                  <CardHeader
                    subheader="Si el colaborador no está registrado, le enviaremos una invitación activar su cuenta. Es necesario completar los datos del conductor para su activación"
                    title="Invitar nuevos usuarios"
                  />
                  {userdata && userdata.userCompanyID ? (
                    <>
                      {showEdit === true ? (
                        <div>
                          <Button
                            sx={{
                              display: "flex",
                              justifyContent: "flex-center",
                              outline: "none",
                              m: 1,
                            }}
                            color="primary"
                            variant="contained"
                            onClick={toggleShowEditNew}
                          >
                            <HighlightOffIcon className="dashIcon-red" />
                            Cancelar
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            sx={{
                              display: "flex",
                              justifyContent: "flex-center",
                              outline: "none",
                              m: 1,
                            }}
                            color="primary"
                            variant="contained"
                            onClick={toggleShowEditNew}
                          >
                            <AddCircleIcon className="dashIcon-green" />
                            Agregar conductor
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <> Debe registrar su empresa para agregar usuarios</>
                  )}

                  <Divider />
                  <FadeInOut show={showEdit} duration={500}>
                    <ContainerViewComponent
                      className="formcontent"
                      id="micuentafromIni"
                    >
                      <hr></hr>
                      <Form onSubmit={invitarUsuariosRider}>
                        <InputGroup className="mb-3">
                          <Form.Control
                            className={
                              isvalid === false
                                ? "email-novalid"
                                : "email-isvalid"
                            }
                            placeholder="email"
                            aria-label="email"
                            aria-describedby="basic-addon2"
                            type="email"
                            onChange={handlechage}
                            value={emailRider}
                          />
                          <Button
                            variant=" btn-primary"
                            id="button-addon2"
                            onClick={invitarUsuariosRider}
                          >
                            enviar
                          </Button>
                        </InputGroup>
                        {error ? (
                          <span className="alert alert-warning m-1">
                            {error}
                          </span>
                        ) : null}
                      </Form>
                    </ContainerViewComponent>
                  </FadeInOut>

                  <Divider />
                </Card>
              </Box>
              <CardHeader
                subheader="Usuarios conductores vinculados con tu empresa"
                title="Conductores"
              />

              <Divider />
              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                <hr></hr>

                <div>
                  {/* 
                  <Box
                    sx={{
                      height: "350px",
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {ridersRows ? (
                      <DataGrid
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "bicciNumber", sort: "desc" }],
                          },
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={ridersRows}
                        columns={columnsRiders}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        //onClick ={checkboxSelection}
                      />
                    ) : (
                      <>
                        <LinearProgress />
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      height: "350px",
                      width: "100%",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    {ridersRowsInactive ? (
                      <DataGrid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        rows={ridersRowsInactive}
                        columns={columnsRidersInactive}
                        pageSize={5}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        //onClick ={checkboxSelection}
                      />
                    ) : (
                      <>
                        <LinearProgress />
                      </>
                    )}
                  </Box> */}
                  {ridersDataByCompany ? (
                    //   <UserListViewDrivers
                    //   ridersDataByCompany={ridersDataByCompany}
                    // ></UserListViewDrivers>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSize={1000}
                      rowsPerPage={[50, 100, 1000]}
                      pagination
                      localeText={customLocaleText} // Aquí se aplica la localización
                      slots={{
                        toolbar: GridToolbar, // Incluye la barra de herramientas para opciones adicionales
                      }}
                      groupingModel={["name"]}
                      checkboxSelection
                      onRowSelectionModelChange={(items) =>
                        HandleSeleccions(items)
                      }
                      rowSelectionModel={selectedIDs}
                    ></DataGrid>
                  ) : (
                    <LinearProgress></LinearProgress>
                  )}
                </div>
              </ContainerViewComponent>

              <Divider />
            </Card>
          </Grid2>
        </Grid2>
      </Grid2>
      {showPopup ? (
        <Popup
          contenido={objRider}
          togglePopup={togglePopup}
          type={typePop}
        ></Popup>
      ) : null}
    </>
  );
};

export default Drivers;
