import React from "react";
import {
  Card,
  Divider,
  CardHeader,
  LinearProgress,
  Box,
  Button,
} from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { DirectionsBike } from "@mui/icons-material";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { Avatar } from "@mui/material";
import ValidationsSize from "../../../../utils/ValidationsSize";
import ValidationsPayments from "../../../../utils/ValidationsPayments";
import {
  stlyleClassStates,
  styleState,
} from "../../../../utils/objects/stylesStates";
import {
  translateStatesBicci,
  translateStatesfalabbela,
} from "../../../../utils/objects/translateStates.jsx";

const ToolDashOrdersContainerBilling = (props) => {
  console.log(props);

  React.useEffect(() => {}, [props]);
  const optionStatus = (e) => {
    console.log(e);
  };

  if (props.movil === true) {
    return (
      <Card className="card-ultimasOrdenes typeMiniforms">
        <CardHeader title="Últimas Solicitudes" />

        {props.userOrders ? (
          <div className="table-card">
            {props.userOrders.map((element, index) => (
              <div key={element.id} className="card">
                <div className="card-header">
                  <input
                    className="fontsize25"
                    type="checkbox"
                    defaultChecked={element.setectedItem}
                    id={element.id.toString()}
                    name={element.dispachtCode}
                    onChange={props.handleOrdersForAccions}
                  />
                  <span className="code">{`F${element.id}`}</span>
                </div>
                <div className="card-body">
                  <div className="card-info">
                    <div>
                      <span>Origen:</span> {element.placeName}
                    </div>
                    <div>
                      <span>Fecha de envío:</span>
                      {"Create At "}
                      {/* {format(element.createdAt.seconds * 1000, "dd/MM/yy")} */}
                    </div>
                    <div>
                      <span>SOC:</span> {element.soc}
                    </div>
                    <div>
                      <span>Zonas:</span> {element.commune2}
                    </div>
                    <div>
                      <span>F. Creado:</span>{" "}
                      {format(
                        element.timeStamp.seconds * 1000,
                        "dd/MM/yy HH:mm:ss"
                      )}
                    </div>
                    <div>
                      <span>Ultimo Registro:</span>
                      {" date ... "}
                      {/* {format(
                        element.updatedAt.seconds * 1000,
                        "dd/MM/yy HH:mm:ss"
                      )} */}
                    </div>
                    <div>
                      <span>Estatus:</span>{" "}
                      {element.falabella_status ? (
                        <>{element.falabella_status}</>
                      ) : (
                        <NewReleasesIcon className="pinEstatusCancel" />
                      )}
                    </div>
                    <div>
                      <span>Detalle:</span>{" "}
                      <NavLink
                        to={{
                          pathname: `/dashboard/dispatch/details/F${element.id}`,
                        }}
                        element={element}
                        color="primary"
                        variant="contained"
                      >
                        ver
                      </NavLink>
                    </div>
                  </div>
                  <div className="card-driver">
                    <Avatar
                      src={element.driverAvatar}
                      sx={{ bgcolor: "#f2f2f2" }}
                      aria-label="recipe"
                    />
                    <div>{element.driverName}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <LinearProgress />
        )}
      </Card>
    );
  }
  /*
  if (props.movil === false) {
    return (
      <div>
        {" "}
        <Card className="card-ultimasOrdenes typeMiniforms">
          <CardHeader title="Resumen de despachos" />

          <Box sx={{ minWidth: 800 }}>
            <table className="table table-card">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    {" "}
                    <input
                      className="fontsize25"
                      type="checkbox"
                      defaultChecked={false}
                      id={"AllorderID"}
                      name={"selectAllorderID"}
                      onChange={props.handleAllSelectorderID}
                    />{" "}
                  </th>
                  <th scope="col">Código</th>
                  <th scope="col">F12</th>
                  <th scope="col">Conductor</th>
                  <th scope="col">Tienda Origen</th>
                  <th scope="col">Origen Data</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Comuna</th>
                  <th scope="col">Fecha Pactada</th>
                  <th scope="col">Estatus</th>
                  <th scope="col">Bicci Estatus</th>
                  <th scope="col">Facturación</th>
                  <th scope="col">Medidas</th>
                  <th scope="col">Pago Estimado</th>
                  <th scope="col">Doc</th>
                </tr>
              </thead>
              <tbody>
                {props.userOrders &&
                  props.userOrders.map((element, index) => (
                    <tr key={element.id}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          className="fontsize25"
                          type="checkbox"
                          defaultChecked={element.setectedItem}
                          id={element.orderID}
                          name={element.dispachtCode}
                          onChange={props.handleOrdersForAccions}
                        />
                      </td>
                      <td>
                        <span className="fontsize18">{element.orderID}</span>
                      </td>
                      <td>{element.F12 ? element.F12 : element.int_f12}</td>
                      <td>
                        {" "}
                        {element.driverName ? (
                          <>
                            {element.driverName !== "isRequire" &&
                            element.driverName !== " " ? (
                              element.driverName
                            ) : (
                              <NewReleasesIcon className="pinEstatusCancel" />
                            )}
                          </>
                        ) : (
                          <NewReleasesIcon className="pinEstatusCancel" />
                        )}
                      </td>
                      <td>
                        {element.BODEGANOMBRE
                          ? element.BODEGANOMBRE
                          : element.int_warehouseName}
                      </td>
                      <td>
                        {element.dataOrigin ? element.dataOrigin : "SDD-OLD"}
                      </td>
                      <td>
                        {element.DIRECCION1 ? (
                          <>
                            {" "}
                            {element.DIRECCION1},{" "}
                            {element.DIRECCION2 !== "isRequire"
                              ? element.DIRECCION2
                              : ""}
                          </>
                        ) : (
                          <>
                            {" "}
                            {element.direccion1},{" "}
                            {element.direccion2 !== "isRequire"
                              ? element.direccion2
                              : ""}
                          </>
                        )}
                      </td>
                      <td>
                        {element.COMUNA2 ? element.COMUNA2 : element.commune2}
                      </td>

                      <td>
                        {element.timeStamp
                          ? format(element.timeStamp.seconds * 1000, "dd/MM/yy")
                          : "No creado"}
                      </td>
                      <td>
                        <span className={styleState[element.statusCreate]}>
                          {element.statusCreate ? (
                            <>
                              {props.containerHistory &&
                              Object.keys(props.containerHistory).length !==
                                0 &&
                              props.containerHistory[element.id] ? (
                                <span>
                                  historial:
                                  <ul className="list-dots mb-0">
                                    {props.containerHistory[element.id].map(
                                      (item) => (
                                        <li key={`id${item.id}`}>
                                          {
                                            props.translateStateBicciFalabella[
                                              item.status
                                            ]
                                          }
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  <Button
                                    sx={{
                                      padding: "0px",
                                      width: "fit-content",
                                      height: "fit-content",
                                      minWidth: "fit-content",
                                      minHeight: "fit-content",
                                    }}
                                    key={element.id}
                                    onClick={(e) => optionStatus(e)}
                                  >
                                    {" "}
                                    <span>
                                      {element.statusCreate === "isRequire" ? (
                                        <span
                                          className={
                                            stlyleClassStates[
                                              element.int_falabella_status
                                            ]
                                          }
                                        >
                                          {element.int_falabella_status
                                            ? translateStatesfalabbela[
                                                element.int_falabella_status
                                              ]
                                            : "No creado"}
                                        </span>
                                      ) : (
                                        "Creado"
                                      )}
                                    </span>
                                  </Button>
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              <span className="pinEstatusCancel">No Info </span>
                            </>
                          )}
                        </span>
                      </td>

                      <td>
                        {element.int_bicciStatus ? (
                          <span
                            className={
                              stlyleClassStates[element.int_bicciStatus]
                            }
                          >
                            {translateStatesBicci[element.int_bicciStatus]}
                          </span>
                        ) : (
                          "null"
                        )}
                      </td>

                      <td>
                        {element.PaymentProforma ? (
                          <>
                            {element.PaymentProforma ? (
                              <span className="pinEstatusDelivery">OK</span>
                            ) : (
                              "-"
                            )}
                          </>
                        ) : (
                          <>
                            {element.billingPaymentProforma &&
                            element.billingPaymentProforma !== "isRequire" ? (
                              <span className="pinEstatusDelivery">OK</span>
                            ) : (
                              "-"
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {props.userOrders ? (
                          <>
                            {element.VOLUMEN ? (
                              <ValidationsSize
                                id={element.orderID}
                                weight={element.PESO}
                                multibulk={element.MULTIBULTO}
                                length={element.LARGO}
                                height={element.ALTO}
                                width={element.ANCHO}
                                units={element.UNIDADES}
                                volume={element.VOLUMEN}
                                packeSize={element.TAMANOPRODUCTO}
                              ></ValidationsSize>
                            ) : (
                              <ValidationsSize
                                id={element.orderID}
                                weight={element.productWeight}
                                multibulk={element.productMultiDelivery}
                                length={element.productLength}
                                height={element.productHeight}
                                width={element.productWidth}
                                units={element.productUnits}
                                volume={element.productVolume}
                                packeSize={element.transportOrderSize}
                              ></ValidationsSize>
                            )}
                          </>
                        ) : (
                          <>Espere</>
                        )}
                      </td>
                      <td>
                        {props.userOrders && element.PaymentValor ? (
                          <ValidationsPayments
                            id={element.orderID}
                            paymentvalor={element.PaymentValor}
                            weight={element.PESO}
                            multibulk={element.MULTIBULTO}
                            length={element.LARGO}
                            height={element.ALTO}
                            width={element.ANCHO}
                            units={element.UNIDADES}
                            volume={element.VOLUMEN}
                            packeSize={element.TAMANOPRODUCTO}
                          ></ValidationsPayments>
                        ) : (
                          <>
                            {element.billingPaymentValor !== "isRequire" ? (
                              <ValidationsPayments
                                id={element.orderID}
                                paymentvalor={element.billingPaymentValor}
                                weight={element.productWeight}
                                multibulk={element.productMultiDelivery}
                                length={element.productLength}
                                height={element.productHeight}
                                width={element.productWidth}
                                units={element.productUnits}
                                volume={element.productVolume}
                                packeSize={element.transportOrderSize}
                              ></ValidationsPayments>
                            ) : (
                              "-"
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {" "}
                        {element.createdAt ? (
                          <>
                            {element.PaymentProforma ? (
                              <>
                                {element.PaymentProforma ? (
                                  <strong>{element.PaymentProforma}</strong>
                                ) : (
                                  <span className="pinEstatusInRoute">
                                    Pendiente
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {element.billingPaymentProforma &&
                                element.billingPaymentProforma !==
                                  "isRequire" ? (
                                  <strong>
                                    {element.billingPaymentProforma}
                                  </strong>
                                ) : (
                                  <span className="pinEstatusInRoute">
                                    Pending
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <span className="pinEstatusCancel">--- </span>
                          </>
                        )}
                      </td>
                      <td></td>

                      {/* <td ><NavLink to={{
                     pathname: `/dashboard/dispatch/details/${element.id}`}} element={element} color="primary" variant="contained"> VER </NavLink></td>
                    
                    </tr>
                  ))}
              </tbody>
            </table>
          </Box>
        </Card>
      </div>
    );
  }
*/
  return null;
};

export default ToolDashOrdersContainerBilling;
