import React, { useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { createNewCompany } from "../../../redux/accionesCompanies";
import { db } from "../../../firebase";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import FadeInOut from "../../animations/FadeInOut";
import moment from "moment";
import ContainerViewComponent from "../../ContainerViewComponent/ContainerViewComponent";

const libraries = ["places"];
const Fromwiewcompany = (props) => {
  const userUid = useSelector((store) => store.usuario.user.uid);

  const companyData = {
    uid: "",
    propietary: "",
    companyName: "",
    companyRut: "",
    companyAddress: "",
    companyNumAddress: "",
    companyRegion: "",
    companyCity: "",
    companyComuna: "",
    companyCountry: "",
    companyCountryCode: "",
    companyActividad: "",
    companyContacName: "",
    companyContacEmail: "",

    companyAdressPhone: "",
    companyImgRut: "",
    companyImgDocumentos: "",
    companyImgPatente: "",
    companyImgDocSii: "",
    stausDocument: "",
    adressGeoNumAddressLat: "",
    adressGeoNumAddressLng: "",
    estatusCRM: "",
    isActive: "",
    servicie: "",
    servicetype: "",
    stausService: "",
    CurrentPayMethods: "",
    payPendings: "",
    suportTikets: "",
  };

  const dispatch = useDispatch();
  // States
  const resperror = useSelector((store) => store.usuario.error);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [warning, setWarning] = React.useState("");
  // User Typing
  const [rutvalida, setrutvalida] = React.useState("");
  const [validatorAlert, setValidatorAlert] = React.useState("");
  const [validatorSuccess, setValidatorSuccess] = React.useState("");

  const [assigned, setAssigned] = React.useState("");
  const [propietary, setPropietary] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [companyRut, setCompanyRut] = React.useState("");

  const [companyAddress, setCompanyAddress] = React.useState("");
  const [companyNumAddress, setCompanyNumAddress] = React.useState("");
  const [companyRegion, setCompanyRegion] = React.useState("");
  const [companyCity, setCompanyCity] = React.useState("");
  const [companyComuna, setCompanyComuna] = React.useState("");
  const [companyCountry, setCompanyCountry] = React.useState("CHILE");
  const [companyCountryCode, setCompanyCountryCode] = React.useState("CL");
  const [companyAdressLat, setCompanyAdressLat] = React.useState("");
  const [companyAdressLng, setCompanyAdressLng] = React.useState("");
  const [companyAdressNumAddressTemp, setCompanyAdressNumAddressTemp] =
    React.useState("");

  const [companyCodeFolder, setCompanyCodeFolder] = React.useState("");

  const [companyActividad, setCompanyActividad] = React.useState("");
  const [companyContacName, setCompanyContacName] = React.useState("");
  const [companyContacEmail, setCompanyContacEmail] = React.useState("");

  const [companyAdressPhone, setCompanyAdressPhone] = React.useState("+56");

  //Animaciones
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  const crearEmpresa = (e) => {
    e.preventDefault();
    if (!companyName.trim()) {
      setError("Ingrese Nombre de empresa");
      return;
    }
    if (!rutvalida.trim()) {
      setError("Ingrese Rut de empresa");
      return;
    }
    if (!companyActividad.trim()) {
      setError("Ingrese actividad economica de empresa");
      return;
    }
    if (!companyAddress.trim()) {
      setError("Ingrese dirección de empresa");
      return;
    }
    if (!companyNumAddress.trim()) {
      setError("Ingrese numero de dirección si no aplica escriba NA");
      return;
    }
    if (!companyRegion.trim()) {
      setError("Ingrese region de empresa");
      return;
    }
    if (!companyCity.trim()) {
      setError("Ingrese ciudad donde se ubica la empresa");
      return;
    }
    if (!companyComuna.trim()) {
      setError("Ingrese comuna donde se ubica  empresa");
      return;
    }
    if (!companyRegion.trim()) {
      setError("Ingrese region donde se ubica  empresa");
      return;
    }
    if (!companyCountry.trim()) {
      setError("Ingrese país donde se ubica  empresa");
      return;
    }

    if (!companyContacName.trim()) {
      setError("Ingrese nombre de contacto de la empresa");
      return;
    }
    if (!companyAdressPhone.trim()) {
      setError("Ingrese telefono de contacto de la  empresa");
      return;
    }
    if (companyAdressPhone.length < 12) {
      // console.log('En el campo teléfono faltan numeros')
      setError("En el campo teléfono faltan numeros");
      return;
    }
    if (companyAdressPhone.length > 12) {
      // console.log('En el campo teléfono existen numeros extra')
      setError("El campo teléfono existen numeros extra");
      return;
    }
    setSuccess("Guardando Datos...");
    guardarNewCompany();
  };

  const limpiarFormulario = (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setWarning(null);
    return;
  };

  const guardarNewCompany = React.useCallback(async () => {
    const data = {
      uid: userUid,
      propietary: userUid,
      companyID: companyCodeFolder,
      companyName: companyName,
      companyRut: rutvalida,
      companyAddress: companyAddress,
      companyNumAddress: companyNumAddress,
      companyRegion: companyRegion,
      companyCity: companyCity,
      companyComuna: companyComuna,
      companyCountry: companyCountry,
      companyCountryCode: companyCountryCode,
      companyActividad: companyActividad,
      companyContacName: companyContacName,
      companyContacEmail: companyContacEmail,
      companyAdressPhone: companyAdressPhone,
      adressGeoNumAddressLat: companyAdressLat,
      adressGeoNumAddressLng: companyAdressLng,
      companyCodeFolder: companyCodeFolder,
      companyAcuerdo: "",
      companyImgRut: "",
      companyImgDocumentos: "",
      companyImgPatente: "",
      companyImgDocSii: "",
      stausDocument: "",
      estatusCRM: "",
      isActive: false,
      servicie: "",
      servicetype: "",
      stausService: false,
      currentPayMethods: [],
      payPendings: 0,
      suportTikets: [],
      createdate: Date.now(),
      createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
    };
    setError(null);
    try {
      dispatch(createNewCompany(data));
      function delay() {
        dispatch(toggleShowEdit);
        dispatch(props.toggleChildTrigres);
        //  limpiarFormulario()
        setSuccess(null);
      }
      setTimeout(delay, 1000);
    } catch (error) {
      //console.log(error)
    }
  });

  // const actualizarData = React.useCallback(async()=>{
  //console.log('is Actualizar')
  //const data = {

  //   }
  // })

  async function rutvalidator(e) {
    var valor;
    var cuerpo;
    var dv;
    var rut;
    if (e.length <= 0) {
      setValidatorAlert("Campo es obligatorio");
      setValidatorSuccess(null);
      setrutvalida(e);
      return;
    }
    valor = clean(e);

    // console.log(e)

    cuerpo = valor.slice(0, -1);
    dv = valor.slice(-1).toUpperCase();

    rut = format(e);

    // console.log(rut)

    if (cuerpo.length < 7) {
      setValidatorAlert("Campo debe ser mayor a 7 Caracteres");
      setValidatorSuccess(null);
      setrutvalida(rut);
      return;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    var dvEsperado;
    dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    if (dvEsperado != dv) {
      // console.log('Rut incorrecto')
      setValidatorAlert("Rut incorrecto");
      setValidatorSuccess(null);
      //  return false;
    } else {
      // console.log('Rut correcto')
      setValidatorSuccess("Rut correcto");
      setValidatorAlert(null);
      function delay() {
        setValidatorSuccess(null);
      }
      setTimeout(delay, 5000);
      //   return true;
    }

    setrutvalida(rut);
    var clear = replaceAll(rut, ".", "");

    try {
      const company = await db
        .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
        .doc(clear)
        .get();
      var data = company.data();
      //console.log(data.companyID)
      if (clear === data.companyID) {
        setValidatorAlert(
          "Este rut ya está registrado, si es propietario de este nombre o razón social comuníquese con el administrador."
        );
        setValidatorSuccess(null);
      }
    } catch (error) {
      //console.log(error)
    }

    //checkData(clear)

    setCompanyCodeFolder(clear);
    //console.log(clear)
    return;
  }
  function replaceAll(text, busca, reemplaza) {
    while (text.toString().indexOf(busca) != -1)
      text = text.toString().replace(busca, reemplaza);
    return text;
  }
  function format(rut) {
    rut = clean(rut);
    var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (var i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "." + result;
    }
    return result;
  }
  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  return (
    <>
      <div>
        <div className="color-box ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
          <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
            <h3 className="alert alert-sussces">Datos de facturacion </h3>

            <FadeInOut
              show={showEdit}
              duration={500}
              parentName="from view company"
            >
              <ContainerViewComponent
                className="formcontent-checkout"
                id="micuentafromIni"
              >
                <hr></hr>
                <Box
                  component="form"
                  onSubmit={crearEmpresa}
                  sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 2,
                  }}
                >
                  {/* Mensajes de alerta */}
                  {success && <Alert severity="success">{success}</Alert>}
                  {error && <Alert severity="error">{error}</Alert>}
                  {warning && <Alert severity="warning">{warning}</Alert>}
                  {resperror && <Alert severity="error">{resperror}</Alert>}
                  {validatorSuccess && (
                    <Alert severity="success">{validatorSuccess}</Alert>
                  )}
                  {validatorAlert && (
                    <Alert severity="error">{validatorAlert}</Alert>
                  )}

                  {/* Datos de empresa */}
                  <Box
                    sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}
                  >
                    <TextField
                      fullWidth
                      label="Nombre o Razón social"
                      placeholder="Razón social o nombre de Empresa"
                      type="text"
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                    />
                    <TextField
                      fullWidth
                      label="Rut"
                      placeholder="Rut de la empresa"
                      type="text"
                      onChange={(e) => rutvalidator(e.target.value)}
                      value={rutvalida}
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      label="Actividad económica"
                      placeholder="Actividad económica"
                      multiline
                      onChange={(e) => setCompanyActividad(e.target.value)}
                      value={companyActividad}
                    />
                  </Box>

                  <Divider sx={{ my: 2 }} />

                  {/* Dirección */}
                  <Search
                    setCompanyAddress={setCompanyAddress}
                    setCompanyNumAddress={setCompanyNumAddress}
                    setCompanyRegion={setCompanyRegion}
                    setCompanyCity={setCompanyCity}
                    setCompanyComuna={setCompanyComuna}
                    setCompanyCountry={setCompanyCountry}
                    setCompanyCountryCode={setCompanyCountryCode}
                    setCompanyAdressLat={setCompanyAdressLat}
                    setCompanyAdressLng={setCompanyAdressLng}
                    setCompanyAdressNumAddressTemp={
                      setCompanyAdressNumAddressTemp
                    }
                    id="imputSearchFromOrigen"
                  />

                  <Box
                    sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}
                  >
                    <TextField
                      fullWidth
                      label="Número"
                      placeholder="Número"
                      type="text"
                      onChange={(e) => setCompanyNumAddress(e.target.value)}
                      value={companyNumAddress}
                    />
                    <TextField
                      fullWidth
                      label="Región"
                      placeholder="Región"
                      type="text"
                      onChange={(e) => setCompanyRegion(e.target.value)}
                      value={companyRegion}
                    />
                    <TextField
                      fullWidth
                      label="Ciudad"
                      placeholder="Ciudad"
                      type="text"
                      onChange={(e) => setCompanyCity(e.target.value)}
                      value={companyCity}
                    />
                  </Box>

                  <Box
                    sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}
                  >
                    <TextField
                      fullWidth
                      label="Comuna"
                      placeholder="Comuna"
                      type="text"
                      onChange={(e) => setCompanyComuna(e.target.value)}
                      value={companyComuna}
                    />
                    <TextField
                      fullWidth
                      disabled
                      label="País"
                      placeholder="País"
                      type="text"
                      onChange={(e) => setCompanyCountry(e.target.value)}
                      value={companyCountry}
                    />
                  </Box>

                  <Typography variant="h6" sx={{ mt: 3 }}>
                    Contacto
                  </Typography>

                  {/* Contacto */}
                  <Box
                    sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}
                  >
                    <TextField
                      fullWidth
                      label="Nombre de Contacto"
                      placeholder="Nombre de Contacto"
                      type="text"
                      onChange={(e) => setCompanyContacName(e.target.value)}
                      value={companyContacName}
                    />
                    <TextField
                      fullWidth
                      label="Teléfono de Contacto"
                      placeholder="Teléfono de Contacto"
                      type="text"
                      onChange={(e) => setCompanyAdressPhone(e.target.value)}
                      value={companyAdressPhone}
                    />
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      label="Email de Contacto"
                      placeholder="Email de Contacto"
                      type="email"
                      onChange={(e) => setCompanyContacEmail(e.target.value)}
                      value={companyContacEmail}
                    />
                  </Box>

                  {/* Campo oculto */}
                  <TextField
                    type="text"
                    id="ocultarImput"
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    value={companyAddress + " " + companyAdressNumAddressTemp}
                    sx={{ display: "none" }}
                  />

                  {/* Botón de Envío */}
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Continuar
                    </Button>
                  </Box>
                </Box>
              </ContainerViewComponent>
            </FadeInOut>

            <Divider />
          </div>
        </div>
      </div>
    </>
  );
};

function Search({
  setCompanyAddress,
  setCompanyNumAddress,
  setCompanyRegion,
  setCompanyCity,
  setCompanyComuna,
  setCompanyCountry,
  setCompanyCountryCode,
  setCompanyAdressLat,
  setCompanyAdressLng,
  setCompanyAdressNumAddressTemp,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    // console.log(address)
    setValue(address, false);
    clearSuggestions();
    let element = [];
    try {
      const results = await getGeocode({ address });
      for (let i = 0; i < results[0].address_components.length; i++) {
        let elementAdress = results[0].address_components[i];
        // console.log(elementAdress['types'][0])
        switch (elementAdress["types"][0]) {
          case "street_number":
            //// console.log(elementAdress.long_name)
            setCompanyAdressNumAddressTemp(elementAdress.long_name);
            break;
          case "route":
            //// console.log(elementAdress.long_name)
            setCompanyAddress(elementAdress.long_name);
            break;
          case "locality":
            //// console.log(elementAdress.long_name)
            setCompanyComuna(elementAdress.long_name);
            break;
          case "administrative_area_level_3":
            //// console.log(elementAdress.long_name)
            break;
          case "administrative_area_level_2":
            //// console.log(elementAdress.long_name)
            setCompanyCity(elementAdress.long_name);
            break;
          case "administrative_area_level_1":
            //// console.log(elementAdress.long_name)
            setCompanyRegion(elementAdress.long_name);
            break;
          case "country":
            //// console.log(elementAdress.long_name)
            setCompanyCountry(elementAdress.long_name);
            break;
          case "postal_code":
            // // console.log(elementAdress.long_name)
            break;

          default:
            break;
        }
      }

      const { lat, lng } = await getLatLng(results[0]);
      // console.log(lat);
      // console.log(lng);
      setCompanyAdressLat(lat);
      setCompanyAdressLng(lng);
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element)
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo2"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Busca tu ubicación"
        />

        <ComboboxPopover className="comboboxList-ebiex">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default Fromwiewcompany;
