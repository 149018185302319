import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actualizarPassPorEnvioEmail } from "../../redux/acccionesClientes";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";

const data = {
  email: "",
};
const Recuperarcuenta = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userPassReset, setUserPassReset] = React.useState("");
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);

  const actualizarPassPorEmail = (e) => {
    e.preventDefault();

    if (!userPassReset.trim()) {
      console.log("Ingrese Email");
      setError("Ingrese Email");
      return;
    }
    data.email = userPassReset;

    setSuccess("El mensaje fue enviado a tu correo electrónico...");
    dispatch(actualizarPassPorEnvioEmail(data));
    function delay() {
      setError(null);
      setSuccess(null);
      //console.log('Navigate login')
      navigate(`/login`);
    }
    setTimeout(delay, 1000);
  };

  return (
    <ContainerViewComponent className="bodyFroms" id="bodyFroms">
      <div className="mt-5 text-center">
        <ContainerViewComponent className="formcontent " id="ajustefromIni">
          Recuperar cuenta
          {error ? (
            <div className="alert alert-danger topalerts">{error}</div>
          ) : null}
          <form onSubmit={actualizarPassPorEmail}>
            {success && (
              <Typography variant="body1" color="success.main" sx={{ mb: 2 }}>
                {success}
              </Typography>
            )}

            <TextField
              fullWidth
              type="email"
              label="Recibirás un correo para establecer una nueva contraseña"
              variant="outlined"
              value={userPassReset}
              onChange={(e) => setUserPassReset(e.target.value)}
              InputProps={{
                startAdornment: <span>@</span>,
              }}
              sx={{ mt: 2 }}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#591E8F",
                  color: "#fff",
                  borderRadius: "3px",
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: 400,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#25b2f4",
                    color: "#591e8f",
                  },
                }}
              >
                Enviar email
              </Button>
            </Box>
          </form>
        </ContainerViewComponent>
      </div>
    </ContainerViewComponent>
  );
};

export default Recuperarcuenta;
