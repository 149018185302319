import React, { useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItemButton,
  Collapse,
  IconButton,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ExpandLess, ExpandMore, Edit as EditIcon } from "@mui/icons-material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const MapPanelViewRoutes = ({
  // Funciones para rutas
  handleAddRoute,
  handleChangeRoute,
  handleRemoveFromRoute,
  targetRoute,
  setTargetRoute,

  // Lista de rutas
  routes = [],

  // Búsqueda
  searchQuery,
  setSearchQuery,

  // Switch de modo filtro
  isFilterMode,
  setIsFilterMode,

  // Información agrupada de paquetes/pedidos por ruta
  routeInfo,
  expandedRoutes,
  handleRouteExpansion,

  // metricas
  metricsRoutes,

  // Rutas seleccionadas
  selectedRoutes,
  handleRouteSelection,
  setSelectedRoutes,

  // Colores de rutas
  routeColors,

  // Función para filtrar órdenes
  filterOrders,

  // Órdenes seleccionadas
  selectedOrders,
  handleOrderDoubleClick,
  handleOrderSelection,
  handleOrderMouseEnter,
  handleOrderMouseLeave,
  handleOpenEditDialog,

  // Traductor de estados
  translateStatesfalabbela,
  translateStatesBicciFilter,
  setSelectedOrders,
  openChangeRouteDialog,
  handleToggleRouteSelection,
  handleOpenChangeRouteDialog,
  handleChangeRouteCancel,
  handleChangeRouteConfirm,
  setOpenChangeRouteDialog,

  setSelectedViewRoutes,
  selectedViewRoutes,
  optimizedWaypoints,
  handleRemoveRoute,
  handleRemoveSelectedFromRoute,
}) => {
  // Estado para Tabs (Rutas | Órdenes con errores)
  const [activeTab, setActiveTab] = useState(0);

  const filteredRoutes = ordenarRutas(
    routes
      .map((route) => {
        // Si hay una búsqueda activa, filtramos solo rutas con órdenes coincidentes
        if (searchQuery.length > 0) {
          const filteredOrders = filterOrders(
            routeInfo.ordersByRoute[route] || []
          );
          return filteredOrders.length > 0
            ? { route, orders: filteredOrders }
            : null;
        }

        // Si no hay búsqueda, mostramos todas las rutas con sus órdenes
        const ordersInRoute = routeInfo.ordersByRoute[route] || [];

        return { route, orders: ordersInRoute }; // No excluimos rutas sin órdenes
      })
      .filter(Boolean)
  ); // Eliminamos las rutas que no cumplen la condición en el filtro activo

  const handleToggleVisibility = (route) => {
    setSelectedViewRoutes((prevRoutes) => {
      // Si la ruta ya está en la lista, la eliminamos (desactivamos la visibilidad)
      if (prevRoutes.includes(route)) {
        return prevRoutes.filter((r) => r !== route);
      } else {
        // Si la ruta no está en la lista, la agregamos (activamos la visibilidad)
        return [...prevRoutes, route];
      }
    });
  };

  const handleRemoveRoutes = () => {
    selectedRoutes.map((route) => {
      handleRemoveRoute(route);
    });
  };

  function ordenarRutas(rutas) {
    return rutas.sort((a, b) => {
      const regex = /(\d+)(?:_(\d+))?/; // Captura "Ruta_X" y "Ruta_X_Y"
      const matchA = a.route.match(regex);
      const matchB = b.route.match(regex);

      if (!matchA || !matchB) return 0; // Si no matchea, no se reordena

      const numA = parseInt(matchA[1], 10); // Número de la ruta principal
      const subA = matchA[2] ? parseInt(matchA[2], 10) : 0; // Subruta (si existe)

      const numB = parseInt(matchB[1], 10);
      const subB = matchB[2] ? parseInt(matchB[2], 10) : 0;

      return numA - numB || subA - subB; // Ordena primero por numA, luego por subA
    });
  }

  return (
    <>
      <Box
        width={350}
        overflow="auto"
        p={1}
        bgcolor="background.paper"
        borderRight={1}
        borderColor="divider"
      >
        {/* Botones arriba de "Rutas" */}
        <Box mb={2}>
          <ButtonGroup variant="contained" color="primary" fullWidth>
            <Button sx={{ fontSize: "0.8em" }} onClick={handleAddRoute}>
              + Agregar Ruta
            </Button>
            <Button onClick={handleChangeRoute}>Cambiar a</Button>
          </ButtonGroup>

          {/* Campo de búsqueda */}
          <TextField
            label="Buscar"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
        {/* Switch + Tabs */}
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={isFilterMode}
                onChange={(e) => setIsFilterMode(e.target.checked)}
                color="primary"
              />
            }
            label={isFilterMode ? "Filtrar paradas" : "Seleccionar paradas"}
          />

          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab label={`Rutas (${filteredRoutes.length})`} />
            <Tab label="Órdenes con Errores" />
          </Tabs>
        </Box>
        <Divider />
        {/* Contenido de cada Tab */}

        {activeTab === 0 && (
          <Box sx={{ overflowY: "auto", height: "calc(100vh - 380px)" }}>
            <List>
              {filteredRoutes.map(({ route, orders }) => {
                let infoMetrics = metricsRoutes[route];
                let distance = "";
                let time = "";
                if (infoMetrics) {
                  distance = infoMetrics.totalDistance
                    ? infoMetrics.totalDistance
                    : "";
                  time = infoMetrics.totalTime ? infoMetrics.totalTime : "";
                }

                const labelId = `checkbox-list-label-${route}`;

                const isExpanded = expandedRoutes.includes(route);
                const allSelected = orders.every((order) =>
                  selectedOrders.includes(order.id)
                );
                const someSelected = orders.some((order) =>
                  selectedOrders.includes(order.id)
                );

                return (
                  <React.Fragment key={route}>
                    <ListItemButton
                      onClick={() => handleRouteExpansion(route)}
                      sx={{ padding: 0, m: 0 }}
                    >
                      <ListItemIcon sx={{ padding: 0, m: 0 }}>
                        <Checkbox
                          edge="start"
                          sx={{ padding: 0, m: 0 }}
                          checked={allSelected}
                          indeterminate={someSelected && !allSelected}
                          tabIndex={-1}
                          disableRipple
                          onClick={(e) => {
                            e.stopPropagation();
                            handleToggleRouteSelection(route, orders);
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`${labelId}`}
                        primary={
                          <>
                            <Typography color={"#030303"}>
                              <strong>{route}</strong>
                            </Typography>
                            <Typography>{`
                            ${distance ? `| ${distance}` : ""} 

                            ${time ? `| ${time}` : ""}`}</Typography>
                            <Typography color={"#030303"}>
                              {" "}
                              {orders.length} Paradas
                            </Typography>
                          </>
                        }
                        style={{ color: routeColors[route] }}
                      />

                      {/* Botón de visibilidad */}

                      {orders.length <= 0 ? (
                        <IconButton onClick={() => handleRemoveRoute(route)}>
                          <DeleteForeverIcon sx={{ color: "red" }} />
                        </IconButton>
                      ) : null}

                      {optimizedWaypoints ? (
                        <Tooltip
                          title={
                            selectedViewRoutes.includes(route)
                              ? "Mostrar ruta"
                              : "Ocultar ruta"
                          }
                        >
                          <IconButton
                            onClick={() => handleToggleVisibility(route)}
                          >
                            {selectedViewRoutes.includes(route) ? (
                              <VisibilityIcon
                                sx={{
                                  color: "#591e8f",
                                  boxShadow: "0px 0px 8px 2px green", // Sombra verde
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      ) : null}

                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    {/* Pedidos dentro de la ruta */}
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {orders
                          .slice() // Clonamos para no modificar el array original
                          .sort(
                            (a, b) =>
                              (a.orderNumber || 0) - (b.orderNumber || 0)
                          ) // Ordena por orderNumber, usa 0 si no existe
                          .map((order, index) => {
                            const isSelected = selectedOrders.includes(
                              order.id
                            );
                            return (
                              <ListItem
                                key={index}
                                sx={{
                                  paddingLeft: 1,
                                  borderBottom: "solid 1px grey",
                                  backgroundColor: isSelected
                                    ? "rgba(0, 0, 255, 0.2)"
                                    : "transparent",
                                  transition: "background-color 0.2s",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 255, 0.1)",
                                  },
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOrderSelection(order.id);
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography sx={{ color: "#030303" }}>
                                        <strong>Posición:</strong>{" "}
                                        {order.orderNumber || 0} |
                                        <strong>ID:</strong>{" "}
                                        {order.id || "Sin ID"}
                                      </Typography>

                                      <IconButton
                                        edge="end"
                                        aria-label="edit"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleOpenEditDialog(order);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Box>
                                  }
                                  secondary={`Dirección: ${
                                    order.toAddress
                                      ? order.toAddress.toLowerCase()
                                      : "sin dirección"
                                  } - Estado: ${
                                    translateStatesfalabbela[
                                      order.falabella_status
                                    ] ||
                                    translateStatesBicciFilter[order.status] ||
                                    "Sin estado"
                                  }`}
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              })}
            </List>
          </Box>
        )}
        {activeTab === 1 && (
          <Box sx={{ padding: 2 }}>
            <Typography variant="body1">
              Lista de órdenes con errores en dirección.
            </Typography>
          </Box>
        )}
      </Box>

      {selectedOrders.length > 0 ? (
        <Box
          sx={{
            width: "50%",
            height: 50,
            overflow: "auto",
            p: 1, // Padding de 8px
            bgcolor: "#1e2158", // Fondo oscuro
            borderRight: 1,
            borderColor: "#E6E8F0",
            position: "absolute",
            zIndex: 9000,
            right: "0px",
            bottom: "0px",
            display: "flex", // Alinea los elementos horizontalmente
            justifyContent: "space-between", // Espaciado entre los elementos
            alignItems: "center", // Alinea los elementos verticalmente
          }}
        >
          {/* Sección izquierda */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2, // Espaciado entre elementos
            }}
          >
            {selectedOrders.length > 0 && (
              <>
                <Typography sx={{ color: "#fff" }}>
                  {`${selectedOrders.length} Seleccionados`}
                </Typography>
                <Button
                  sx={{ color: "#fff" }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setSelectedOrders([]);
                  }}
                >
                  <CleaningServicesIcon sx={{ fontSize: "0.8em" }} />
                  Limpiar
                </Button>
              </>
            )}
          </Box>

          {/* Sección derecha */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2, // Espaciado entre botones
            }}
          >
            <Button
              sx={{ color: "#990606", backgroundColor: "#fff" }}
              variant="outlined"
              size="small"
              onClick={() => {
                handleRemoveRoutes();
              }}
            >
              {"Eliminar ruta ( Liberar paradas )"}
            </Button>

            <Button
              sx={{ color: "#990606", backgroundColor: "#fff" }}
              variant="outlined"
              size="small"
              onClick={() => {
                handleRemoveSelectedFromRoute();
              }}
            >
              {"Sacar seleccionadas de ruta ( Liberar )"}
            </Button>
          </Box>
        </Box>
      ) : null}

      <Dialog open={openChangeRouteDialog} onClose={handleChangeRouteCancel}>
        <Box sx={{ padding: "20px" }}>
          <DialogTitle>Mover elementos</DialogTitle>
          <FormControl fullWidth variant="outlined" size="small" margin="dense">
            <InputLabel id="select-target-route-label">Ruta destino</InputLabel>
            <Select
              labelId="select-target-route-label"
              value={targetRoute}
              onChange={(e) => setTargetRoute(e.target.value)}
              label="Ruta de destino"
            >
              {routes.map((route) => (
                <MenuItem key={route} value={route}>
                  {route}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogActions>
            <Button onClick={handleChangeRouteCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleChangeRouteConfirm} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default MapPanelViewRoutes;
