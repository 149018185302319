import React, { useCallback, useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { Box, Button, Divider, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit"; // Ícono de edición para
import PrintIcon from "@mui/icons-material/Print";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Grid2,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import EditOrderFrom from "./EditOrderFrom";
import ReusableFormExceptions from "../ReusableFormExceptions/ReusableFormExceptions";
import { useOrders } from "../../hooks/useOrdersProviders/OrdersContext";
import Moment from "moment";
import { getDocumentAndHistory } from "../../utils/UtilsOrders/getDocumentAndHistory";
import useGetDataRiderByCompany from "../../hooks/getDataRiderByCompany";
import {
  translateStatesBicciFilter,
  translateStatesfalabbela,
  translateStatesSendsFalabbelaSelector,
} from "../../utils/objects/translateStates";
import EditRoutePosition from "../EditRoutePosition/EditRoutePosition";
import PopUpAsignament from "../PopUpAsignament/PopUpAsignament";
import LiberarOrden from "../Dashboard/LiberarOrden/LiberarOrden";
import {
  stlyleClassStates,
  styleState,
} from "../../utils/objects/stylesStates";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";
import MapDeliveryPosition from "./MapDeliveryPosition";
import HandlePrintBiccShipping from "../../utils/HandlePrintBiccShipping/HandlePrintBiccShipping";

var history = [];

const companyId = "77528497-8";

const EditDispatchdetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  function handleClick() {
    navigate(-1);
  }
  // Estados principales
  const [orderId, setOrderId] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderDetailsCliente, setOrderDetailsCliente] = useState(null);
  const [dispachtHistory, setDispachtHistory] = useState([]);
  const [compyOrder, setCompyOrder] = useState({});
  const [dataOrdesBicciExpress, setDataOrdesBicciExpress] = useState([]);

  // Estados de soporte
  const [loadAwait, setLoadAwait] = useState(false);
  const [findOrder, setFindOrder] = useState(null);
  const [routesOrdersForAccions, setRoutesOrdersForAccions] = useState([]);
  const [prefixes, setPrefixes] = useState([]);
  const [orderPrefix, setOrderPrefix] = useState(null);
  const orderPrefixRef = useRef(null);

  // Comentarios
  const [userAdminComent, setUserAdminComent] = useState([]);
  const [newComent, setNewComent] = useState("");

  // Asignar conductor / Excepciones
  const [openEdit, setOpenEdit] = useState(false);
  const [opemPopUpAsignament, setOpemPopUpAsignament] = useState(false);

  // Datos de rider
  const [riderDestails, setRiderDetails] = useState({});

  // Fechas iniciales
  const initDateInitial = Moment().format("YYYY-MM-DD");
  const endDateInitial = Moment().format("YYYY-MM-DD");

  // Hooks personalizados
  const { companiesData } = useOrders({ initDateInitial, endDateInitial });
  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  // Cargar prefijos de las compañías
  useEffect(() => {
    if (companiesData.length > 0) {
      const extractedPrefixes = companiesData
        .filter((item) => item.prefix)
        .map((item) => item.prefix);
      setPrefixes(extractedPrefixes);
    }
  }, [companiesData]);

  // Actualizar el ID de la orden cuando cambia el parámetro de URL
  useEffect(() => {
    if (id) {
      const trimmedId = id.trim();
      findOrderData(id);
      setOrderId(trimmedId);
      setRoutesOrdersForAccions((prevOrders) =>
        prevOrders.includes(trimmedId) ? prevOrders : [trimmedId]
      );
    }
  }, [id]);

  // Buscar orden cuando el ID o los prefijos cambian
  useEffect(() => {
    if (orderId && prefixes.length > 0) {
      findOrderDispatch(orderId, prefixes);
    }
  }, [orderId, prefixes]);

  // Buscar detalles del rider cuando cambian los detalles de la orden
  useEffect(() => {
    const fetchRiderDetails = async (driverId) => {
      try {
        const docRef = doc(
          db,
          process.env.REACT_APP_COL_USERS_DRIVERS,
          driverId
        );
        const riderRef = await getDoc(docRef);
        setRiderDetails(riderRef.data() || {});
      } catch (error) {
        console.error("Error al obtener detalles del rider:", error);
      }
    };

    if (orderDetails?.driverID) {
      fetchRiderDetails(orderDetails.driverID);
      const trimmedId = orderDetails.id.trim();
      setRoutesOrdersForAccions((prevOrders) =>
        prevOrders.includes(trimmedId) ? prevOrders : [trimmedId]
      );
    }
  }, [orderDetails]);

  const findOrderData = async (id) => {
    try {
      const orderCollection = collection(db, "orders");
      const orderRef = doc(orderCollection, id);
      const docSnapshot = await getDoc(orderRef);
      if (docSnapshot.exists()) {
        setOrderDetails(docSnapshot.data());
        setOrderDetailsCliente(docSnapshot.data());
      }
      try {
        const statusDocs = collection(orderRef, "statuses");
        const querySnapshot = await getDocs(statusDocs);
        let history = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds ? 1 : -1));

        setDispachtHistory(history);
        setLoadAwait(false);
        setFindOrder(false);
      } catch (error) {
        console.error(`Error obteniendo historial de ${id}:`, error);
      }
    } catch (error) {
      console.error(`Error obteniendo documento ${id}:`, error);
    }
  };

  // Buscar orden con su prefijo correspondiente
  const findOrderDispatch = useCallback(
    async (id, prefixes) => {
      if (!id || prefixes.length === 0) {
        console.warn("No se puede buscar, ID o Prefijos no disponibles.");
        return;
      }

      setLoadAwait(true);

      const collections = [process.env.REACT_APP_COL_USERS_ORDERS];
      const { foundDocumentData, documentHistory, prefix } =
        await getDocumentAndHistory(id, prefixes, collections);

      if (prefix) {
        setOrderPrefix(prefix);
        orderPrefixRef.current = prefix;
      } else {
        console.warn("No se detectó prefijo en la búsqueda.");
      }

      const dataCompany = companiesData.find((item) => item.prefix === prefix);

      if (dataCompany) {
        if (dataCompany.companyID === "90749000-9") {
          dataCompany.nonDelivery = translateStatesSendsFalabbelaSelector;
          setCompyOrder(dataCompany);
        } else {
          setCompyOrder(dataCompany);
        }
      }

      if (foundDocumentData && foundDocumentData.id) {
        setLoadAwait(false);
        setFindOrder(true);
        setOrderDetails(foundDocumentData);
        setOrderDetailsCliente(foundDocumentData);
        setDispachtHistory(documentHistory);
      } else {
        console.warn("No se encontró la orden.");
        setOrderDetails(null);
        setOrderDetailsCliente([]);
        setDispachtHistory([]);
        setLoadAwait(false);
        setFindOrder(false);
      }
    },
    [companiesData]
  );

  // Validar recolección de orden
  const saveValidatePickup = async () => {
    if (!orderDetails.isPickedUp) {
      try {
        const orderRef3 = doc(
          db,
          process.env.REACT_APP_COL_USERS_ORDERS,
          orderId
        );
        await updateDoc(orderRef3, { isPickedUp: true });

        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          isPickedUp: true,
        }));
      } catch (error) {
        console.log("Error al actualizar el documento:", error);
      }
    }
  };

  // Guardar comentarios administrativos
  // Guardar comentarios administrativos
  const saveComentAdmin = async (e) => {
    e.preventDefault();

    if (!newComent.trim()) return;

    let mergeComents = [...userAdminComent, newComent];
    const orderRef3 = doc(db, process.env.REACT_APP_COL_USERS_ORDERS, orderId);

    await updateDoc(orderRef3, { observationAdmin: mergeComents });
    orderDetails.observationAdmin = mergeComents;
    setUserAdminComent(mergeComents);
    setNewComent("");
  };

  // Guardar cambios y volver a buscar la orden
  // Guardar cambios y re-buscar la orden
  const onSave = async () => {
    // console.log("Guardando con prefijo:", orderPrefixRef.current);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    //findOrdesrDispacht(orderId, prefixes);
    findOrderDispatch(orderId, prefixes); // Reviasar esta funcion
    setOpenEdit(false);
  };

  const handleUpdate = async () => {
    findOrderDispatch(orderId, prefixes);
  };

  let latDriver = null;
  return (
    <div>
      {orderDetails ? (
        <>
          {" "}
          <div id="containerDetails">
            {ridersDataByCompany ? (
              <PopUpAsignament
                opemPopUpAsignament={opemPopUpAsignament}
                setOpemPopUpAsignament={setOpemPopUpAsignament}
                ordersForAccions={routesOrdersForAccions}
                ridersDataByCompany={ridersDataByCompany}
                handleUpdate={handleUpdate}
              ></PopUpAsignament>
            ) : null}

            <Grid2>
              <Grid2 container spacing={2} sx={{ p: 1 }}>
                <Grid2 sx={{ width: { xs: "100%", sm: "100%", md: "65%" } }}>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader
                      title={
                        <Box
                          sx={{
                            width: "100%",
                            p: 1,
                            align: "left",
                            justifyContent: "start",
                          }}
                        >
                          <Typography
                            sx={{ color: "#030303", fontSize: "1.5em" }}
                          >
                            Editar Orden
                          </Typography>
                          <Divider></Divider>

                          <Typography
                            variant="h4"
                            align="left"
                            sx={{ mb: 2, fontWeight: "bold" }}
                          >
                            {orderDetails.id}
                          </Typography>

                          <Grid2
                            container
                            spacing={2}
                            alignItems="left"
                            justifyContent="start"
                          >
                            {/* Estado BICCI */}
                            <Grid2 xs={12} sm={6} md={4} textAlign="center">
                              {orderDetails && (
                                <Typography
                                  variant="h6"
                                  sx={{
                                    backgroundColor: "#F3F3F3",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    padding: "5px",
                                    color: "#333",
                                  }}
                                  className={
                                    stlyleClassStates[orderDetails.status]
                                  }
                                >
                                  {
                                    translateStatesBicciFilter[
                                      orderDetails.status
                                    ]
                                  }
                                </Typography>
                              )}
                            </Grid2>

                            {orderDetails.status !== 8 ? (
                              <>
                                {/* Estado Falabella */}
                                <Grid2 xs={12} sm={6} md={4} textAlign="center">
                                  {orderDetails && (
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        backgroundColor: "#F3F3F3",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        fontWeight: "bold",
                                        color: "#333",
                                      }}
                                      className={
                                        styleState[orderDetails.intState]
                                      }
                                    >
                                      {
                                        translateStatesfalabbela[
                                          orderDetails.intState
                                        ]
                                      }
                                    </Typography>
                                  )}
                                </Grid2>

                                {/* Botón de Validación */}
                                {orderDetails && (
                                  <Grid2 xs={12} md={4} textAlign="center">
                                    <Button
                                      fullWidth
                                      variant="contained"
                                      onClick={() => {
                                        const confirmAction = window.confirm(
                                          "¿Estás seguro de que quieres validar que posees el producto físico?"
                                        );
                                        if (confirmAction) {
                                          saveValidatePickup();
                                        }
                                      }}
                                      disabled={orderDetails.isPickedUp}
                                      sx={{
                                        backgroundColor: orderDetails.isPickedUp
                                          ? "green"
                                          : "orange",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: "1rem",
                                        padding: "10px",
                                        "&:hover": {
                                          backgroundColor:
                                            orderDetails.isPickedUp
                                              ? "darkgreen"
                                              : "darkorange",
                                        },
                                        "&.Mui-disabled": {
                                          backgroundColor:
                                            orderDetails.isPickedUp
                                              ? "green"
                                              : "orange",
                                          color: "white",
                                          opacity: 1,
                                        },
                                      }}
                                    >
                                      {orderDetails.isPickedUp
                                        ? "Validación Picked: físico ok"
                                        : "Requiere validar físico"}
                                    </Button>
                                  </Grid2>
                                )}
                                {orderDetails && orderDetailsCliente ? (
                                  <Grid2>
                                    <Button
                                      onClick={() =>
                                        HandlePrintBiccShipping(
                                          orderDetails,
                                          orderDetailsCliente
                                        )
                                      }
                                      sx={{ width: "100%", fontSize: "1em" }}
                                    >
                                      {" "}
                                      <PrintIcon fontSize="medium" />
                                      Imprimir etiqueta
                                    </Button>
                                  </Grid2>
                                ) : null}
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid2>
                        </Box>
                      }
                    />

                    <CardContent>
                      <Box sx={{ textAlign: "left" }}>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color="primary"
                        >
                          Resumen
                        </Typography>
                        <Box>
                          <ContainerViewComponent className="formcontent">
                            <EditOrderFrom
                              orderDetails={orderDetails}
                              usuarioStorage={props.usuarioStorage}
                              orderDetailsCliente={orderDetailsCliente}
                              dataOrdesBicciExpress={dataOrdesBicciExpress}
                              orderid={orderId}
                              setOrderDetails={setOrderDetails}
                            />
                          </ContainerViewComponent>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid2>

                <Grid2 sx={{ width: { xs: "100%", sm: "100%", md: "32%" } }}>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader
                      title={
                        <h3>Comentarios visibles en manifiestos de rutas</h3>
                      }
                    />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "150",
                        }}
                      >
                        <ContainerViewComponent className="formcontent">
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Rider:{" "}
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {orderDetails.observationReceptor ? (
                              <div className="color-blue">
                                {orderDetails.observationReceptor}
                              </div>
                            ) : (
                              "Sin Comentarios"
                            )}
                          </Typography>
                          <hr></hr>
                          Admin:
                          {orderDetails.observationAdmin &&
                          orderDetails.observationAdmin.length > 0
                            ? orderDetails.observationAdmin.map(
                                (item, index) => (
                                  <div key={index} className="color-blue">
                                    <hr></hr>
                                    <div>{item}</div>
                                  </div>
                                )
                              )
                            : " Sin Comentarios"}
                          <hr></hr>
                          Agregar comentario a la Orden
                          <div>
                            <Box
                              component="form"
                              onSubmit={saveComentAdmin}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                              }}
                            >
                              <TextField
                                placeholder="Agregar comentario"
                                variant="outlined"
                                type="text"
                                fullWidth
                                onChange={(e) => setNewComent(e.target.value)}
                                value={newComent}
                                InputProps={{ startAdornment: <EditIcon /> }} // Agrega el icono
                              />
                              <Button
                                sx={{
                                  backgroundColor: "#591E8F",
                                  color: "#fff",
                                  marginTop: "30px",
                                  ":hover": {
                                    backgroundColor: "#25b2f4 !important",
                                    color: "#591e8f",
                                  },
                                }}
                                color="primary"
                                variant="contained"
                                type="submit"
                              >
                                Guardar Nota de Orden
                              </Button>
                            </Box>
                          </div>
                          <hr></hr>
                          <Box sx={{ display: "flex" }}>
                            <Button
                              sx={{
                                backgroundColor: "#591E8F",
                                border: "none",
                                borderRadius: "3px",
                                color: "#fff",
                                cursor: "pointer",
                                fontSize: "14px",
                                marginTop: "30px",
                                outline: "none",
                                padding: "5px",
                                minWidth: "50px",
                                fontWeight: 400,
                                margin: "0em 1em 0em 0em",
                                ":hover": {
                                  backgroundColor: "#25b2f4 !important",
                                  color: "#591e8f",
                                },
                              }}
                              onClick={() => setOpenEdit(true)}
                              color="primary"
                              variant="contained"
                              type="sutmit"
                            >
                              {compyOrder && compyOrder.nonDelivery ? (
                                "Enviar Excepción"
                              ) : (
                                <>
                                  <CircularProgress
                                    size={12}
                                    sx={{ color: "#fff" }}
                                  />
                                  Cargando
                                </>
                              )}
                            </Button>
                            <Button
                              sx={{
                                backgroundColor: "#591E8F",
                                border: "none",
                                borderRadius: "3px",
                                color: "#fff",
                                cursor: "pointer",
                                fontSize: "14px",
                                marginTop: "30px",
                                outline: "none",
                                padding: "5px",
                                minWidth: "50px",
                                fontWeight: 400,
                                margin: "0em 1em 0em 0em",
                                ":hover": {
                                  backgroundColor: "#25b2f4 !important",
                                  color: "#591e8f",
                                },
                              }}
                              onClick={() => setOpemPopUpAsignament(true)}
                              color="primary"
                              variant="contained"
                              type="sutmit"
                            >
                              Asignar conductor
                            </Button>
                            <EditRoutePosition
                              orderID={orderDetails.id}
                            ></EditRoutePosition>
                            {compyOrder && compyOrder.nonDelivery ? (
                              <ReusableFormExceptions
                                compyOrder={compyOrder}
                                open={openEdit}
                                handleClose={() => setOpenEdit(false)}
                                onSave={() => onSave()}
                                orderID={orderDetails.id}
                                riderDestails={
                                  riderDestails?.driverLocation
                                    ? riderDestails.driverLocation
                                    : null
                                }
                              ></ReusableFormExceptions>
                            ) : (
                              <></>
                            )}
                          </Box>
                          <hr></hr>
                          <Box sx={{ mt: 1 }}>
                            <LiberarOrden
                              orderID={orderDetails.id}
                              userID={props.usuarioStorage.uid}
                            ></LiberarOrden>
                          </Box>
                        </ContainerViewComponent>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card
                    className="card-ultimasOrdenes typeMiniforms"
                    sx={{ mt: 2 }}
                  >
                    <CardHeader title={<h3>Geo referencia</h3>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "350",
                        }}
                      >
                        <MapDeliveryPosition
                          markersPending={orderDetails}
                          dispachtHistory={dispachtHistory}
                          riderDestails={riderDestails}
                        ></MapDeliveryPosition>
                      </Box>
                    </CardContent>
                  </Card>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader title={<h2>Historial</h2>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <ContainerViewComponent
                          className="formcontent"
                          id="micuentafromIni"
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Conductor</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Integracion</TableCell>
                              </TableRow>
                            </TableHead>

                            {dispachtHistory.length > 0 ? (
                              <TableBody>
                                {dispachtHistory.map((item, index) => {
                                  // Buscar el conductor en el array de conductores por su ID

                                  const driver = ridersDataByCompany.find(
                                    (driver) => driver.id === item.driverID
                                  );
                                  if (driver) {
                                    latDriver = driver.name;
                                  }

                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        {format(
                                          item.createdAt.seconds * 1000,
                                          "dd/MM/yy HH:mm:ss"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {/* Mostrar el nombre del conductor si se encuentra, de lo contrario mostrar "Desconocido" */}
                                        {driver
                                          ? driver.name
                                          : "Información de Sistema"}
                                      </TableCell>
                                      <TableCell>
                                        {
                                          translateStatesBicciFilter[
                                            item.status
                                          ]
                                        }
                                      </TableCell>
                                      <TableCell>
                                        {translateStatesfalabbela[item.status]}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            ) : (
                              <TableBody>
                                <TableRow>
                                  <TableCell colSpan={3} align="center">
                                    No hay historial disponible
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </Table>
                        </ContainerViewComponent>
                      </Box>
                    </CardContent>
                  </Card>
                  {/* 
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader title={<h2>Facturación</h2>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "350",
                        }}
                      >
                        <ContainerViewComponent className="formcontent">
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            ID:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {orderDetails.id}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Nombre servicio:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {" "}
                            {orderDetails.deliveryType}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Costo de servicio:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            _________
                          </Typography>
                          <hr></hr>
                        </ContainerViewComponent>
                      </Box>
                    </CardContent>
                  </Card> */}
                </Grid2>
              </Grid2>
            </Grid2>
          </div>
        </>
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Esperando información</h5>
              {loadAwait ? <CircularProgress></CircularProgress> : null}
              {findOrder ? (
                "No se encontro información..."
              ) : (
                <>
                  {" "}
                  {findOrder === false ? (
                    <>
                      {" "}
                      <p className="card-text">
                        No se encontraron resultados{" "}
                        <span
                          role="img"
                          aria-label="Nerd face"
                          className="bigFace"
                        >
                          😕
                        </span>{" "}
                        que coicidan con su busqueda, verifique el codigo. o
                        cunsulte si la orden fue creada correctamente <br />
                        <span
                          role="img"
                          aria-label="Nerd face"
                          className="bigFace"
                        >
                          🤓
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <img
                        src="/static/images/asset/enapuros.webp"
                        alt="Buscando..."
                        width="70"
                      />
                      "Buscando..."
                    </>
                  )}
                </>
              )}
              <hr></hr>
              <button
                type="button"
                className="btn btn-ebiex m-1"
                onClick={handleClick}
              >
                Regresar
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditDispatchdetails;
