import React, { useState } from "react";
import {
  Container,
  TextField,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Grid,
} from "@mui/material";

const FormComponent = ({
  enviarRespuesta,
  success,
  error,
  warning,
  resperror,
  validatorSuccess,
  validatorAlert,
  setDriverContacName,
  driverContacName,
  setDriverAdressPhone,
  driverAdressPhone,
  setDriverContacEmail,
  driverContacEmail,
  handleTypeDocument,
  typeDocument,
  typeDocumentSelected,
  driverDocument,
  rutvalidator,
  ottterDocumento,
  setDriverCountryOrigen,
  driverCountryOrigen,
  setDriverNumAddress,
  driverNumAddress,
  setDriverRegion,
  driverRegion,
  setDriverCity,
  driverCity,
  setDriverComuna,
  driverComuna,
  setDriverCountry,
  driverCountry,
  handleLicenseResponse,
  licenciaConducir,
  handleVehicleResponse,
  tipoDeVehiculo,
  driverAddress,
  setDriverAddress,
  driverAdressNumAddressTemp,
  setDriverAdressNumAddressTemp,
  blockBtn,
}) => {
  return (
    <Container maxWidth="md">
      <Box sx={{ p: 3, bgcolor: "#fff", borderRadius: 2, boxShadow: 3 }}>
        <form onSubmit={enviarRespuesta}>
          {/* Mensajes de estado */}
          {success && <Alert severity="success">{success}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
          {warning && <Alert severity="warning">{warning}</Alert>}
          {resperror && <Alert severity="error">{resperror}</Alert>}
          {validatorSuccess && (
            <Alert severity="success">{validatorSuccess}</Alert>
          )}
          {validatorAlert && <Alert severity="error">{validatorAlert}</Alert>}

          <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
            Contacto
          </Typography>

          <Grid container spacing={2}>
            {/* Nombre de Contacto */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nombre de Contacto"
                variant="outlined"
                value={driverContacName}
                onChange={(e) => setDriverContacName(e.target.value)}
              />
            </Grid>

            {/* Teléfono de Contacto */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Teléfono de Contacto"
                variant="outlined"
                value={driverAdressPhone}
                onChange={(e) => setDriverAdressPhone(e.target.value)}
              />
            </Grid>

            {/* Email de Contacto */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Correo Electrónico"
                variant="outlined"
                value={driverContacEmail}
                onChange={(e) => setDriverContacEmail(e.target.value)}
              />
            </Grid>

            {/* Tipo de Documento */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Tipo de Documento</InputLabel>
                <Select
                  value={typeDocumentSelected || ""}
                  onChange={(e) => handleTypeDocument(e.target.value)}
                >
                  <MenuItem value="">Seleccione...</MenuItem>
                  {typeDocument.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Número de Documento */}
            {typeDocumentSelected && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={
                    typeDocumentSelected === "rut"
                      ? "Rut"
                      : typeDocumentSelected === "document"
                      ? "DNI o Cédula"
                      : "Pasaporte"
                  }
                  variant="outlined"
                  value={driverDocument}
                  onChange={(e) =>
                    typeDocumentSelected === "rut"
                      ? rutvalidator(e.target.value)
                      : ottterDocumento(e.target.value)
                  }
                />
              </Grid>
            )}

            {/* Nacionalidad */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nacionalidad"
                variant="outlined"
                value={driverCountryOrigen}
                onChange={(e) => setDriverCountryOrigen(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Sección de búsqueda de dirección */}
              {/* Aquí puedes insertar tu componente de búsqueda si es necesario */}
              <TextField
                fullWidth
                label="Calle"
                variant="outlined"
                value={driverAddress}
                onChange={(e) => setDriverAddress(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              {/* Sección de búsqueda de dirección */}
              {/* Aquí puedes insertar tu componente de búsqueda si es necesario */}
              <TextField
                fullWidth
                label="Número de Calle"
                variant="outlined"
                value={driverAdressNumAddressTemp}
                onChange={(e) => setDriverAdressNumAddressTemp(e.target.value)}
              />
            </Grid>

            {/* Dirección */}
            <Grid item xs={12} md={3}>
              {/* Sección de búsqueda de dirección */}
              {/* Aquí puedes insertar tu componente de búsqueda si es necesario */}
              <TextField
                fullWidth
                label="Número de Casa"
                variant="outlined"
                value={driverNumAddress}
                onChange={(e) => setDriverNumAddress(e.target.value)}
              />
            </Grid>

            {/* Región, Ciudad y Comuna */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Región"
                variant="outlined"
                value={driverRegion}
                onChange={(e) => setDriverRegion(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Ciudad"
                variant="outlined"
                value={driverCity}
                onChange={(e) => setDriverCity(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Comuna"
                variant="outlined"
                value={driverComuna}
                onChange={(e) => setDriverComuna(e.target.value)}
              />
            </Grid>

            {/* País */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="País"
                variant="outlined"
                value={driverCountry}
                onChange={(e) => setDriverCountry(e.target.value)}
              />
            </Grid>

            {/* Licencia de Conducir */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>¿Posee licencia de conducir chilena?</InputLabel>
                <Select onChange={(e) => handleLicenseResponse(e.target.value)}>
                  <MenuItem value="">Seleccione...</MenuItem>
                  {licenciaConducir.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Tipo de Vehículo */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>¿Con qué tipo de vehículo trabajas?</InputLabel>
                <Select onChange={(e) => handleVehicleResponse(e.target.value)}>
                  <MenuItem value="">Seleccione...</MenuItem>
                  {tipoDeVehiculo.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Botón de Guardar */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            {!blockBtn && (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: "#591E8F",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#25b2f4", color: "#591e8f" },
                }}
              >
                Guardar
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default FormComponent;
