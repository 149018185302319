import React from "react";

import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Grid2,
} from "@mui/material";
import Logo from "../header/logo/Logo";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";

const EmailmodelConfirm2 = (props) => {
  return (
    <div id="containerDetails">
      <div>
        <h2 className="h2-withe">Vista previa email Emailmodel1</h2>
      </div>
      <Grid2>
        <Grid2>
          <Grid2 computer={16} mobile={16} tablet={16}>
            <div className="contariner-view-email m-2">
              <div>
                Asunto : Autorizacion de cambio de dirección, de la dirección de
                entrega {props.emailData.data.id}
              </div>
              <hr></hr>
              <Card
                sx={{
                  margin: "20px",
                  backgroundColor: "#F8E8FD",
                }}
                className="card-ultimasOrdenes typeMiniforms"
              >
                <CardHeader
                  title={
                    <div>
                      <div>
                        <Logo></Logo>
                      </div>
                      <h1>¡Hola {props.emailData.data.userName}! </h1>
                    </div>
                  }
                />

                <CardContent>
                  <Box
                    sx={{
                      alignItems: "left",
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "350",
                    }}
                  >
                    <div></div>
                    <ContainerViewComponent className="formcontent-email">
                      <div className="emailbodycontainer">
                        <div className="emailbodycontainer-mensaje">
                          <p>
                            Estimado cliente, {props.emailData.data.userName}{" "}
                          </p>
                          <p>
                            Nos dirigimos a usted para que realice la
                            Autorizacion de cambio de dirección, mediante este
                            medio, la dirección indicada por contacto telefónico
                            para la orden
                          </p>{" "}
                          <p>{props.emailData.data.id} </p>
                          <p>
                            la nueva dirección es: {props.emailData.dirección}
                          </p>
                          <h3>
                            Por favor nos responde esta Auroizacion para
                            realizar la entrega en este nuevo destino.
                          </h3>
                          <p>
                            Saludos cordiales, Quedamos atentos a sus
                            comentarios...
                          </p>
                        </div>
                        <div className="emailbodycontainer-mensaje">
                          <h3>Datos Destino en paquete</h3>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            Nombre:
                          </Typography>
                          {"    "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data ? (
                              <>{props.emailData.data.userName}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            Destino:
                          </Typography>
                          {"    "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data.toAddress}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            Email Cliente:
                          </Typography>
                          {"    "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data.userEmail}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            Teléfono:
                          </Typography>{" "}
                          +569
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data.userPhone} |
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            Localidad:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data2 ? (
                              <>{props.emailData.data2.commune1}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            SOC:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data2 ? (
                              <>{props.emailData.data2.soc}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          {props.emailData.data3.length > 0 ? (
                            <>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body1"
                              >
                                F12:
                              </Typography>{" "}
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body1"
                              >
                                {props.emailData.data3[0].F12}
                              </Typography>
                            </>
                          ) : (
                            "Sin F12 Registrado"
                          )}
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            Tamaño de Paquete:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data2 ? (
                              <>{props.emailData.data2.size}</>
                            ) : null}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            Medidas:
                          </Typography>{" "}
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {props.emailData.data2 ? (
                              <>
                                {" "}
                                height: {props.emailData.data2.height}| weight:{" "}
                                {props.emailData.data2.weight} | width:{" "}
                                {props.emailData.data2.width}
                              </>
                            ) : null}
                          </Typography>
                        </div>
                        <div></div>
                      </div>
                    </ContainerViewComponent>
                  </Box>
                </CardContent>
              </Card>
              -<hr></hr>
              <div>Botones Acciones = Enviar</div>
            </div>
          </Grid2>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default EmailmodelConfirm2;
