import React, { useEffect } from "react";
import { Box, Grid2, Card, Typography } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const DashIconsResumenIncidencias = ({ datailInsures }) => {
  useEffect(() => {}, [datailInsures]);

  const COLOR = "#FFA500"; // Naranja

  // Filtrar los estados 99, 1058 y 1060
  const filteredInsures = datailInsures.filter(
    (item) => !["99", "1058", "1060", "1061"].includes(item.id)
  );

  return filteredInsures ? (
    <Box sx={{ p: 2 }}>
      <Grid2 container spacing={2} sx={{ justifyContent: "end" }}>
        {filteredInsures.map((item, index) => (
          <Grid2
            item
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "auto",
              maxWidth: "150px",
            }}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                borderRadius: 1,
                boxShadow: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  backgroundColor: "#999",
                  backgroundImage: "linear-gradient(60deg, #c8c6c3, #c8b49c)",
                  boxShadow:
                    "0 4px 20px 0 rgba(0,0,0,0.14), 0 7px 10px -5px rgba(252,252,251,0.4)",
                  mb: 1,
                  textAlign: "left",
                  fontSize: "5px",
                }}
              >
                <ReportProblemIcon sx={{ color: COLOR, fontSize: 20 }} />
              </Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "18px", fontWeight: "500" }}
              >
                {item.cant}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: COLOR,
                  fontSize: "12px",
                  textAlign: "center",
                  textTransform: "lowercase",
                  fontWeight: "500",
                  minHeight: "50px",
                }}
              >
                {item.name}
              </Typography>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  ) : null;
};

export default DashIconsResumenIncidencias;
