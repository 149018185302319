import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

export const getDocumentAndHistory = async (
  id,
  prefixes,
  collections,
  orderPrefix = ""
) => {
  let foundDocumentData = null;
  let documentHistory = [];
  let detectedPrefix = "";
  console.log(orderPrefix);

  try {
    for (let collectionName of collections) {
      const matchedPrefix = prefixes.find((prefix) => id.startsWith(prefix));
      let searchId = id;

      if (!matchedPrefix) {
        // Si no tiene prefijo, prueba con cada uno
        for (let currentPrefix of prefixes) {
          let idWithPrefix = currentPrefix + id;
          let documentData = await tryGetDoc(collectionName, idWithPrefix);

          if (documentData) {
            foundDocumentData = documentData;
            detectedPrefix = currentPrefix;
            searchId = idWithPrefix;
            break;
          }
        }
      } else {
        detectedPrefix = matchedPrefix;
        searchId = id;
        foundDocumentData = await tryGetDoc(collectionName, searchId);
      }

      if (foundDocumentData) {
        // Obtiene historial en paralelo
        const [history, alternativeData, alternativeHistory] =
          await Promise.all([
            tryGetDocHistory(collectionName, searchId),
            detectedPrefix === "F"
              ? tryGetAlternativeDoc(searchId.replace(/^F/, ""))
              : null,
            detectedPrefix === "F"
              ? tryGetAlternativeHistory(searchId.replace(/^F/, ""))
              : [],
          ]);

        if (alternativeData) {
          foundDocumentData = mergeDocuments(
            foundDocumentData,
            alternativeData
          );
        }

        documentHistory = mergeHistories(history, alternativeHistory);
        break;
      }
    }
  } catch (error) {
    console.error("Error obteniendo documento o historial:", error);
  }

  return { foundDocumentData, documentHistory, prefix: detectedPrefix };
};

// 🔹 Obtener documento con error handling
const tryGetDoc = async (collectionName, id) => {
  try {
    const orderRef = doc(db, collectionName, id);
    const docSnapshot = await getDoc(orderRef);
    return docSnapshot.exists() ? docSnapshot.data() : null;
  } catch (error) {
    console.error(`Error obteniendo documento ${id}:`, error);
    return null;
  }
};

// 🔹 Obtener historial con limitación de datos
const tryGetDocHistory = async (collectionSearch, id) => {
  try {
    const orderRef = doc(db, collectionSearch, id);
    const statusDocs = collection(orderRef, "statuses");
    const querySnapshot = await getDocs(statusDocs);
    return querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds ? 1 : -1));
  } catch (error) {
    console.error(`Error obteniendo historial de ${id}:`, error);
    return [];
  }
};

// 🔹 Obtener documento alternativo
const tryGetAlternativeDoc = async (id) => {
  try {
    const alternativeCollection = process.env.REACT_APP_COL_FALABELLA_ORDERS;
    const orderRef = doc(db, alternativeCollection, id);
    const docSnapshot = await getDoc(orderRef);
    return docSnapshot.exists() ? docSnapshot.data() : null;
  } catch (error) {
    console.error(`Error obteniendo documento alternativo ${id}:`, error);
    return null;
  }
};

// 🔹 Obtener historial alternativo con limitación
const tryGetAlternativeHistory = async (id) => {
  try {
    const alternativeCollection = process.env.REACT_APP_COL_FALABELLA_ORDERS;
    const orderRef = doc(db, alternativeCollection, id);
    const statusDocs = collection(orderRef, "status");
    const querySnapshot = await getDocs(statusDocs);
    return querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds ? 1 : -1));
  } catch (error) {
    console.error(`Error obteniendo historial alternativo ${id}:`, error);
    return [];
  }
};

// 🔹 Fusionar documentos
const mergeDocuments = (mainDoc, alternativeDoc) => ({
  ...alternativeDoc,
  ...mainDoc,
});

// 🔹 Fusionar y ordenar historiales
const mergeHistories = (history1, history2) =>
  [...history1, ...history2].sort((a, b) =>
    a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
  );
