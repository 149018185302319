import React, { useState, useContext, useMemo, useRef, useEffect } from "react";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import {
  Card,
  Divider,
  LinearProgress,
  Box,
  IconButton,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { FormControl, InputLabel, Select, Stack, Chip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { db } from "../../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import Moment from "moment";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import generarReporteTurnoCierrediario from "../../../utils/Reports/generarReporteTurno";
import {
  createOrderBicciClientCompanyBicciexpress,
  updateOrdersBicciexpressEstatus,
} from "../../../redux/accionesOrders";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useDispatch } from "react-redux";
import generarReporteProformas from "../../../utils/Reports/generarReporteProformas";
import SendIcon from "@mui/icons-material/Send";
import { pdf } from "@react-pdf/renderer";
import DocumentoPDFShippingLabelPrint from "../../../components/DocumentoPDFShippingLabelPrint/DocumentoPDFShippingLabelPrint";
import { generateBarcodeBase64 } from "../../../components/DocumentoPDFShippingLabelPrint/generateBarcodeBase64";
import { generateQRBase64 } from "../../../components/DocumentoPDFShippingLabelPrint/generateQRBase64";
import { useDateRangePicker } from "../../../components/CustomDateRangePicker";
import DocumentoPDFShippingLabelPrintMultiple from "../../../components/DocumentoPDFShippingLabelPrint/DocumentoPDFShippingLabelPrintMultiple";
import { customLocaleText } from "../../../utils/objects/CustomLocaleTextTables";
import { format } from "date-fns";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys";
import ToolFiltersComponent from "../../../components/Dashboard/dashboard/ToolFiltersComponent/ToolFiltersComponent";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

var ordersForAccions = [];
var dataOrdersForAccions = [];
var listadeItemsNoMach = [];

const GuiasByCompanyForPrint = ({
  userDataContext,
  mergedOrders,
  companiesData,
  setInitDate,
  setEndDate,
  initDate,
}) => {
  const dispacth = useDispatch();

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const { widthHeight, movilActive } = useContext(ScreenSizeContext);

  const companyId = useMemo(() => {
    if (userDataContext) {
      return userDataContext.companyID;
    }
    return "";
  }, [userDataContext]);

  const companyData = useMemo(() => {
    if (userDataContext) {
      return userDataContext.companyData;
    }
  }, [userDataContext]);

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  const [toolsData, setToolsData] = React.useState(null);

  const [dataType, setDataType] = React.useState("request");

  const [documentData, setDocumentData] = React.useState(null);

  const [progress, setProgres] = React.useState(0);

  const [CheckedChangeOE, setCheckedChangeOE] = React.useState(false);

  const [remder, setRender] = React.useState(true);

  const [alertMensajes, setAlertMensajes] = React.useState(null);

  const [columns, setColum] = useState([]);

  const [rows, setRows] = useState([]);

  const [dataCurrentMohstDelivery, setCurrentMohstDelivery] =
    React.useState(null);

  const [staticDateBillingFilter, setStaticDateBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [typeRequets, setTypeRequets] = React.useState("typeRequetsCreate");

  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  const [companiesOrigin, setCompaniesOrigin] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const [selectedOrigins, setSelectedOrigins] = React.useState([]);

  const [filteredCompanies, setFilteredCompanies] = React.useState([]);

  const [filteredOrigins, setFilteredOrigins] = React.useState([]);

  // const { data, error } = useGetProducts(
  //   initDate,
  //   endDate,
  //   typeRequets,
  //   companyId
  // );

  // console.log("useGetProducts data", data);
  // console.log("useGetProducts error", error);

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  const [Buttondisable, setButtondisable] = React.useState(true);

  const [selectedData, setSelectedData] = useState([]);

  const [dateMonths, setDateMonths] = React.useState(
    Moment(Date.now()).format("YYYY-MM")
  );

  // React.useEffect(() => {
  //   if (viewMergedOrders && viewMergedOrders.length) {
  //     upDateTable();
  //   }
  // }, [viewMergedOrders]);

  useEffect(() => {
    if (mergedOrders) {
      //setViewMergedOrders(mergedOrders);
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  React.useEffect(() => {}, [progress]);

  // React.useEffect(() => {
  //   handleViewAll(mergedOrders);
  // }, [mergedOrders]);

  useEffect(() => {
    if (viewMergedOrders) {
      upDateTable(viewMergedOrders);
      createSelectorOrigins(viewMergedOrders);
      // setResumenTable(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    if (companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [companiesData]);

  useEffect(() => {
    if (companiesOrigin.length > 0) {
      setFilteredOrigins(companiesOrigin);
    }
  }, [companiesOrigin]);

  // const handleViewAll = () => {
  //   function flattenData(data) {
  //     let flattenedArray = [];
  //     for (const key in data) {
  //       flattenedArray = flattenedArray.concat(data[key]);
  //     }
  //     return flattenedArray;
  //   }
  //   const combinedArray = flattenData(mergedOrders);
  //   setDataOrdersFilter(combinedArray);
  // };

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "firo"];

    let tempView = mergedOrders;

    // Filtro para las compañías seleccionadas
    if (selectedCompanies.length > 0) {
      // Crear una lista temporal que combine los resultados de las diferentes compañías seleccionadas
      let filteredByCompanies = [];

      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            let tempOrigins = OriginsCompanys[companyID];
            let companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Si la compañía no está en ExternalIntegrationsOrigins, filtrar por companyID
          let companyFiltered = tempView.filter((items) => {
            return items.companyID === companyID;
          });
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });

      // Actualizamos tempView con el conjunto de resultados acumulados
      tempView = filteredByCompanies;
    }

    // Aplicar filtro por origen si hay alguno seleccionado
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    // Actualizar las vistas con los resultados filtrados
    setViewMergedOrders(tempView);
    //setResumenTable(tempView);
  };

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });

    setCompaniesOrigin(placeNames);
  };

  async function verifiqueOrdesStatus(userOrders) {
    var tempUpdate = userOrders;

    const totalIterations = tempUpdate.length;

    const allOrdersRefBicci = collection(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS
    );

    const allOrdersRefFalabella = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );

    const ordersStatusBicci = async (orderid) => {
      const q = query(allOrdersRefBicci, where("id", "==", orderid));
      let ordesBicciexpres = await getDocs(q);
      const dataOrdesBicciexpres = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      var order = dataOrdesBicciexpres[0];

      if (order) {
        let bicciStatus = order.status;
        let statusCreate = true;
        let driverName = order.driverName ? order.driverName : "";
        let driverID = order.driverID ? order.driverID : "";
        let userDniReceptor = order.userDniReceptor
          ? order.userDniReceptor
          : "";
        let userNameReceptor = order.userNameReceptor
          ? order.userNameReceptor
          : "";
        let observationReceptor = order.observationReceptor
          ? order.observationReceptor
          : "";
        let observationAdmin = order.observationAdmin
          ? order.observationAdmin
          : "";

        return {
          statusCreate,
          bicciStatus,
          driverName,
          driverID,
          userDniReceptor,
          userNameReceptor,
          observationReceptor,
          observationAdmin,
        };
      } else {
        let bicciStatus = false;
        let statusCreate = false;
        let driverName = "";
        let driverID = "";
        let userDniReceptor = "";
        let userNameReceptor = "";
        let observationReceptor = "";
        let observationAdmin = "";
        return {
          statusCreate,
          bicciStatus,
          driverName,
          driverID,
          userDniReceptor,
          userNameReceptor,
          observationReceptor,
          observationAdmin,
        };
      }
    };

    const ordersStatusFalabella = async (orderid) => {
      const ordesFalabella = query(
        allOrdersRefFalabella,
        where("id", "==", orderid.toString())
      );
      let ordesFalabellaDoc = await getDocs(ordesFalabella);
      const dataOrdesFalabella = ordesFalabellaDoc.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      var orderF = dataOrdesFalabella[0];
      if (orderF) {
        let createdAtFalabella = orderF.createdAt;
        let falabella_status = orderF.falabella_status
          ? orderF.falabella_status
          : orderF.int_falabella_status;
        let updatedAt = orderF.updatedAt;
        return { createdAtFalabella, falabella_status, updatedAt };
      } else {
        let createdAtFalabella = false;
        let falabella_status = false;
        let updatedAt = false;
        return { createdAtFalabella, falabella_status, updatedAt };
      }
    };

    async function undateMergeDataEbiex(
      id,
      statusCreate,
      status,
      driverName,
      driverID,
      int_client_soc,
      createdAt,
      int_falabella_status,
      observationReceptor,
      observationAdmin,
      userDniReceptor,
      userNameReceptor,
      updatedAt
    ) {
      try {
        let data = {
          id,
          statusCreate,
          status,
          driverName,
          driverID,
          int_client_soc,
          createdAt,
          int_falabella_status,
          observationReceptor,
          observationAdmin,
          userDniReceptor,
          userNameReceptor,
          updatedAt,
        };

        dispacth(updateOrdersBicciexpressEstatus(data));
      } catch (error) {
        console.log("error no encontrado");
        // console.log(error);
      }
    }

    return new Promise(async (resolve, reject) => {
      let completedIterations = 0;

      for (let index = 0; index < tempUpdate.length; index++) {
        //if ((tempUpdate[index].bicciStatus = 8)) {
        let resp = [];
        let resp2 = [];
        completedIterations++;

        try {
          resp = await ordersStatusBicci(tempUpdate[index].orderID);
        } catch (error) {
          // console.log(error);
        }

        try {
          resp2 = await ordersStatusFalabella(tempUpdate[index].SOC);
        } catch (error) {
          // console.log(error);
        }

        if (resp.bicciStatus !== false) {
          tempUpdate[index].statusCreate = resp.statusCreate;
          tempUpdate[index].bicciStatus = resp.bicciStatus;
          tempUpdate[index].driverName = resp.driverName;
          tempUpdate[index].driverID = resp.driverID;
          tempUpdate[index].createdAt = resp2.createdAtFalabella;
          tempUpdate[index].falabella_status = resp2.falabella_status;
          tempUpdate[index].observationReceptor = resp.observationReceptor;
          tempUpdate[index].observationAdmin = resp.observationAdmin;
          tempUpdate[index].userDniReceptor = resp.userDniReceptor;
          tempUpdate[index].userNameReceptor = resp.userNameReceptor;
          tempUpdate[index].updatedAt = resp2.updatedAt;

          try {
            await undateMergeDataEbiex(
              tempUpdate[index].key,
              tempUpdate[index].statusCreate,
              tempUpdate[index].bicciStatus,
              tempUpdate[index].driverName,
              tempUpdate[index].driverID,
              tempUpdate[index].orderID,
              tempUpdate[index].createdAt,
              tempUpdate[index].falabella_status,
              tempUpdate[index].observationReceptor,
              tempUpdate[index].observationAdmin,
              tempUpdate[index].userDniReceptor,
              tempUpdate[index].userNameReceptor,
              tempUpdate[index].updatedAt
            );
          } catch (error) {
            //console.log(error);
          }
        }

        setProgres((completedIterations / totalIterations) * 100);
        // } else {
        //   console.log(
        //     "Nada que actualizar int_bicciStatus >=( " +
        //       tempUpdate[index].orderID
        //   );
        //   console.log(tempUpdate[index].bicciStatus);
        //   console.log(tempUpdate[index].int_bicciStatus);
        //   completedIterations++;
        //   setProgres((completedIterations / totalIterations) * 100);
        // }

        if (
          tempUpdate[index].int_bicciStatus ||
          tempUpdate[index].int_bicciStatus === "isRequire"
        ) {
          let resp = [];
          let resp2 = [];
          completedIterations++;

          try {
            resp = await ordersStatusBicci(tempUpdate[index].orderID);
          } catch (error) {
            //console.log(error);
          }

          try {
            resp2 = await ordersStatusFalabella(
              tempUpdate[index].SOC
                ? tempUpdate[index].SOC
                : tempUpdate[index].int_client_soc
            );
          } catch (error) {
            //console.log(error);
          }
          if (resp.bicciStatus !== false) {
            tempUpdate[index].statusCreate = resp.statusCreate;
            tempUpdate[index].int_bicciStatus = resp.bicciStatus;
            tempUpdate[index].driverName = resp.driverName;
            tempUpdate[index].driverID = resp.driverID;
            tempUpdate[index].createdAt = resp2.createdAtFalabella;
            tempUpdate[index].int_falabella_status = resp2.falabella_status;
            tempUpdate[index].userDniReceptor = resp.userDniReceptor;
            tempUpdate[index].userNameReceptor = resp.userNameReceptor;
            tempUpdate[index].observationReceptor = resp.observationReceptor;
            tempUpdate[index].observationAdmin = resp.observationAdmin;
            tempUpdate[index].updatedAt = resp2.updatedAt;
            try {
              await undateMergeDataEbiex(
                tempUpdate[index].key,
                tempUpdate[index].statusCreate,
                tempUpdate[index].int_bicciStatus,
                tempUpdate[index].driverName,
                tempUpdate[index].driverID,
                tempUpdate[index].orderID,
                tempUpdate[index].createdAt,
                tempUpdate[index].int_falabella_status,
                tempUpdate[index].observationReceptor,
                tempUpdate[index].observationAdmin,
                tempUpdate[index].userDniReceptor,
                tempUpdate[index].userNameReceptor,
                tempUpdate[index].updatedAt
              );
            } catch (error) {
              // console.log(error);
            }
          } else {
            console.log(
              "Nada que actualizar Geosort Data" + tempUpdate[index].orderID
            );
          }

          setProgres((completedIterations / totalIterations) * 100);
        } else {
          if (tempUpdate[index].int_bicciStatus < 8) {
            console.log(
              "Nada que actualizar int_bicciStatus no Require " +
                tempUpdate[index].orderID
            );
          }

          completedIterations++;
          setProgres((completedIterations / totalIterations) * 100);
        }
      }

      setRender(!remder);
    });
  }

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const onChageRange = (e) => {
    if (e.target.name === "staticDateBillingFilter") {
      setInitDate(e.target.value);
      setEndDate(e.target.value);
      setStaticDateBillingFilter(e.target.value);
    }
    if (e.target.name === "rangoInicio") {
      setInitDate(e.target.value);
    }
    if (e.target.name === "rangoFinal") {
      setEndDate(e.target.value);
    }
  };

  const onChageRangeMonths = (e) => {
    setDateMonths(e.target.value);
  };

  const findOrdesrDispachtMonths = async (companyId, initDate) => {
    const selectedDate = new Date(dateMonths);
    const startOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      1
    );

    // Obtenemos el último día del mes seleccionado
    const endOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 2,
      0
    );

    // Imprimimos el rango de fechas en la consola

    const ordersDispachtRef = collection(db, process.env.REACT_APP_COL_ORDERS);
    const q = query(
      ordersDispachtRef,
      where("companyId", "==", companyId),
      where("timeStamp", ">=", startOfMonth),
      where("timeStamp", "<=", endOfMonth)
    );

    const querySnapshot = await getDocs(q);

    const ordersPropietary = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const uniqueObjects = {};

    for (const obj of ordersPropietary) {
      if (!uniqueObjects[obj.orderID]) {
        uniqueObjects[obj.orderID] = obj;
      }
    }

    const filteredArray = Object.values(uniqueObjects);

    ordersPropietary.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

    const groupedData = {};

    filteredArray.forEach((item) => {
      const date = new Date(item.timeStamp.seconds * 1000)
        .toISOString()
        .substring(0, 10);
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
      groupedData[date].push(item);
    });

    const dates = Object.keys(groupedData);

    const resultCurrentDelivery = [];

    dates.forEach((date) => {
      const orders = groupedData[date];
      const numOrders = orders.length;
      const dateObj = new Date(date);
      const monthNum = dateObj.getMonth() + 1;
      const year = dateObj.getFullYear();
      const month = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(dateObj);
      resultCurrentDelivery.push({
        id: date,
        type: "currentDelivery",
        monthNum: monthNum,
        cant: numOrders,
        year: year,
        month: month,
      });
    });

    setCurrentMohstDelivery(resultCurrentDelivery);
  };

  const generarReporteTurno = () => {
    generarReporteTurnoCierrediario(
      viewMergedOrders,
      ridersDataByCompany,
      initDate
    );
  };

  const deletedItems = async (data) => {
    for (let index = 0; index < data.length; index++) {
      let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, data[index].id);
      await deleteDoc(docRef)
        .then(() => {
          console.log("Documento borrado exitosamente." + data[index].key);
        })
        .catch((error) => {
          console.error("Error al borrar el documento:", error);
        });
    }
  };

  const exportartReportePagos = (userOrders) => {
    console.log(userOrders);
    generarReporteProformas(userOrders);
  };

  const handleCreate = (order) => {
    let orderSelect = viewMergedOrders.filter((item) => item.id === order.id);
    dispacth(createOrderBicciClientCompanyBicciexpress(orderSelect[0]));
  };
  const handlePrint = async (order) => {
    let orderSelect = viewMergedOrders.filter((item) => item.id === order.id);

    const barcodeImage = await generateBarcodeBase64(orderSelect[0].orderID);

    const barcodeImageLpn = orderSelect[0].int_lpn
      ? await generateBarcodeBase64(orderSelect[0].int_lpn)
      : "";

    const QRImage = await generateQRBase64(orderSelect[0].orderID);

    console.log(barcodeImage);
    console.log(barcodeImageLpn);
    console.log(QRImage);

    const blob = await pdf(
      <DocumentoPDFShippingLabelPrint
        order={orderSelect[0]}
        barcodeImage={barcodeImage}
        barcodeImageLpn={barcodeImageLpn}
        qrImage={QRImage}
      />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handlePrintMultiple = async (selectedData) => {
    const ordersData = await Promise.all(
      selectedData.map(async (order) => {
        const barcodeImage = await generateBarcodeBase64(order.id);
        const barcodeImageLpn = <order className="id"></order> ? (
          await generateBarcodeBase64(order.int_lpn ? order.int_lpn : order.id)
        ) : (
          ""
        );
        const QRImage = await generateQRBase64(order.id);

        return {
          order,
          barcodeImage,
          barcodeImageLpn,
          qrImage: QRImage,
        };
      })
    );

    const blob = await pdf(
      <DocumentoPDFShippingLabelPrintMultiple ordersData={ordersData} />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleAssingDriver = (order) => {
    console.log(order);
    let orderSelect = viewMergedOrders.filter((item) => item.id === order.id);
    // dispacth(createOrderBicciClientCompanyBicciexpress(orderSelect[0]));
  };

  const upDateTable = () => {
    const renderActionButtons = (params) => {
      if (params.row.orderID === undefined || params.row.orderID === "") {
        return (
          <Box sx={{ minWidth: "200px" }}>
            <IconButton
              sx={{ width: "30px !important", color: "#1e2158 !important" }}
              aria-label="Crear Orden"
              onClick={() => handleCreate(params.row)}
            >
              <SendIcon />
            </IconButton>{" "}
            Solcitar Retiro
          </Box>
        );
      }

      if (params.row.orderID !== undefined && params.row.orderID !== "") {
        return (
          <>
            <Box sx={{ minWidth: "200px" }}>
              <IconButton
                sx={{ width: "30px !important", color: "#1e2158 !important" }}
                aria-label="Crear Orden"
                onClick={() => handlePrint(params.row)}
              >
                <QrCode2Icon />{" "}
              </IconButton>
              Iprimir Etiqueta
            </Box>
          </>
        );
      }
    };
    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: true,
      },

      {
        field: "placeName",
        headerName: "ORIGEN",
        width: 250,
        editable: true,
      },
      {
        field: "destino",
        headerName: "DESTINO",
        width: 200,
        editable: true,
      },
      {
        field: "createdAt",
        headerName: "CREADO",
        width: 200,
        editable: true,
      },
      {
        field: "isPickedUp",
        headerName: "PICKING",
        width: 200,
        editable: true,
      },
      // {
      //   field: "actions",
      //   headerName: "Acciones",
      //   width: 200,
      //   renderCell: renderActionButtons,
      //   sortable: false,
      //   filterable: false,
      // },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < viewMergedOrders.length; i++) {
      rows.push({
        id: viewMergedOrders[i].id,
        placeName: viewMergedOrders[i].placeName,
        createdAt: format(
          viewMergedOrders[i].createdAt.seconds * 1000,
          "dd/MM/yy HH:mm:ss"
        ),
        destino: viewMergedOrders[i].toAddress,
        deliveryType: viewMergedOrders[i].deliveryType,
        isPickedUp: viewMergedOrders[i].isPickedUp,
        userPhone: viewMergedOrders[i].userPhone,
        userName: viewMergedOrders[i].userName,
        toAddress: viewMergedOrders[i].toAddress,
        deliveryType: viewMergedOrders[i].deliveryType,
      });
    }
    setRows(rows);
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  const HandleSeleccions = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedData(selectedRowsData);
  };
  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    setSelectedOrigins(event.target.value);
  };

  return (
    <Grid>
      <Card>
        <ToolFiltersComponent
          selectedCompanies={selectedCompanies}
          selectedOrigins={selectedOrigins}
          filteredCompanies={filteredCompanies}
          filteredOrigins={filteredOrigins}
          handleChange={handleChange}
          handleOrigins={handleOrigins}
          rangeInputPicker={rangeInputPicker}
          setNewDate={setNewDate}
          viewRangeInputPicker={true}
        ></ToolFiltersComponent>
        <Box sx={{ textAlign: "right" }}>
          <Button
            sx={{ color: "#030303" }}
            onClick={() => handlePrintMultiple(selectedData)}
          >
            Etiquetas seleccionadas : {selectedData.length}
          </Button>
        </Box>
        <Box sx={{ textAlign: "left" }}>
          <Typography sx={{ fontSize: "1.5em", p: 1 }}>
            Impresióm de etiquetas:
          </Typography>
        </Box>
        {!viewMergedOrders ? <></> : null}
        <Divider />

        {progress !== 0 && progress < 100 ? (
          <div className="progressTextPorcentBig color-calipso">
            {progress.toFixed(1)}%{" "}
            {progress > 95 ? "Completado..." : "Cargando..."}
          </div>
        ) : null}
        <Box sx={{ width: "100%", p: 1 }}>
          <div>{alertMensajes}</div>

          <Grid>
            {viewMergedOrders && widthHeight.width > 950 ? (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={1000}
                rowsPerPage={[50, 100, 1000]}
                pagination
                localeText={customLocaleText}
                groupingModel={["int_serviceType"]}
                checkboxSelection
                onRowSelectionModelChange={(items) => HandleSeleccions(items)}
              ></DataGrid>
            ) : (
              <LinearProgress></LinearProgress>
            )}
          </Grid>
        </Box>
        <ContainerViewComponent></ContainerViewComponent>
        <Divider />
      </Card>
    </Grid>
  );
};

export default GuiasByCompanyForPrint;
