import React from "react";
import { Box } from "@mui/material";
import useGlobalConfig from "../../hooks/globalConfig";
const Bannerhome = () => {
  const { logoOficialUrl } = useGlobalConfig;
  return (
    <div className="containerBanner">
      <Box
        component="img"
        src={logoOficialUrl}
        alt="Banner Footer"
        sx={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export default Bannerhome;
