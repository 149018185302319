import { Chip } from "@mui/material";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import FadeInOut from "../../../animations/FadeInOut";
import PaymentIcon from "@mui/icons-material/Payment";
import PaidIcon from "@mui/icons-material/Paid";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const MenuAdministartosFinance = ({ companyCollaborator }) => {
  const [showIntF, setShowIntF] = useState(false);

  const opctionIntMenu = [
    "guias-facturacion",
    "guiasfalabella-geosort",
    "administracion",
  ];

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <div>
      {" "}
      {companyCollaborator === "falabella" ? (
        <div>
          <Chip
            icon={<AccountBalanceIcon />}
            label="Administ.. y Finanzas"
            variant="outlined"
            color="primary"
            onClick={() => setShowIntF(!showIntF)}
            sx={{
              color: "#F8F8F8",
              width: "100%",
              fontSize: "1em",
              padding: "2px",
              marginBottom: "5px",
              backgroundColor: opctionIntMenu.includes(splitLocation[2])
                ? "#07b107"
                : "transparent",
            }}
          />

          <FadeInOut show={showIntF} duration={500} parentName="fade5">
            <div className="btn-sidebarLink">Opciones:</div>

            <NavLink to="/dashboard/administracion" className="btn-sidebarLink">
              <div
                className={
                  splitLocation[2] === "administracion"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <PaidIcon fontSize="medium" />
                <span className="btn-title">Pay Riders </span>
              </div>
            </NavLink>
            <NavLink
              to="/dashboard/guias-facturacion"
              className="btn-sidebarLink"
            >
              <div
                className={
                  splitLocation[2] === "guias-facturacion"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <PaymentIcon fontSize="medium" />
                <span className="btn-title">Gestión registos</span>
              </div>
            </NavLink>
            <NavLink to="/dashboard/facturacion" className="btn-sidebarLink">
              <div
                className={
                  splitLocation[2] === "facturacion"
                    ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                    : "btn btn-lg btn-block btn-sidebar"
                }
              >
                <PaymentIcon fontSize="medium" />
                <span className="btn-title">Facturación</span>
              </div>
            </NavLink>
          </FadeInOut>
        </div>
      ) : null}
    </div>
  );
};

export default MenuAdministartosFinance;
