import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  CircleMarker,
  Tooltip,
  GeoJSON,
} from "react-leaflet";
import L from "leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import { Button } from "@mui/material";

const MapWithRoute = () => {
  const [optimizedWaypoints, setOptimizedWaypoints] = useState([]); // Waypoints optimizados
  const [routeGeometry, setRouteGeometry] = useState([]); // Geometría de la ruta
  const tuToken = process.env.REACT_APP_ROUTES_BICCI_TOKEN;

  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const startIcon = new L.Icon({
    iconUrl: "/static/images/casitaNaranja.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const stopIcon = new L.Icon({
    iconUrl: "/static/images/casitaAzul.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const waypointIcon = new L.Icon({
    iconUrl: "/static/images/casita.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const geoJsonStyle = {
    color: "red", // Borde del polígono
    weight: 2, // Grosor del borde
    fillColor: "blue", // Color de relleno
    fillOpacity: 0.5, // Transparencia del relleno
  };

  const geoJsonData = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: { name: "Zona Especial" },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-73.95, 40.75],
              [-73.87, 40.75],
              [-73.87, 40.71],
              [-73.95, 40.71],
              [-73.95, 40.75],
            ],
          ],
        },
      },
    ],
  };

  const masiveData = {
    vehicle: "driving",
    waypoints: [
      { id: "origen", name: "", lon: -33.4346112, lat: -70.6776554 },
      {
        id: "530000000000044325",
        name: "",
        lon: -33.4405686,
        lat: -70.65867159999999,
      },
      {
        id: "900600000001145637",
        name: "",
        lon: -33.4387864,
        lat: -70.6456153,
      },
      { id: "140111000005807837", name: "", lon: -33.431413, lat: -70.632323 },
      {
        id: "140111000005804898",
        name: "",
        lon: -33.4494072,
        lat: -70.6528078,
      },
      {
        id: "destino",
        name: "",
        lon: -33.4483087,
        lat: -70.6455527,
      },
    ],
  };

  useEffect(() => {
    if (masiveData) {
      fetchOptimizedWaypoints(masiveData);
    }
  }, []);

  useEffect(() => {}, [optimizedWaypoints && routeGeometry]);

  const urlPro = `http://services.bicci.cl:5550/api/optimizeRoute`;

  const fetchOptimizedWaypoints = async (masiveData) => {
    console.log("Consulta  de OSRM /trip:");
    try {
      const response = await axios.post(
        urlPro,
        masiveData, // ✅ Aquí va el cuerpo de la petición
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tuToken}`, // ✅ Esto sí es correcto
          },
          credentials: "include",
        }
      );

      console.log("Respuesta de OSRM /trip:");
      console.log(response);

      if (response.status === 200) {
        const route = response.data; // ✅ Aquí usas response.data, no response
        setRouteGeometry(route.geometry);
        setOptimizedWaypoints(route.waypoints);
      }
    } catch (error) {
      console.error("Error en la solicitud a OSRM /optimizeRoute:", error);
      console.error("Error menssaje:", error.message);
      setRouteGeometry([]);
      setOptimizedWaypoints([]);
    }
  };

  // preferido
  const attributionOSM = `
  <div style="display: flex; align-items: center;">
    &copy Open Street Map 
    <img src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00" width="90" height="50" style="margin-right: 5px;" alt="BICCI Logo"/>
  </div>
`;
  const urlGrey =
    "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png";

  //google maps
  const attributionBICCI = `
  <div style="display: flex; align-items: center;">
    <img src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00" width="90" height="40" style="margin-right: 5px;" alt="BICCI Logo"/>
  </div>
`;
  //  &copy; BICCI
  const googleMapsLayer = `https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_APIGOOGLEMAPS_MATRIX}`;

  return (
    <div>
      <Button onClick={() => window.location.reload()}>Reset</Button>
      <MapContainer
        center={[-33.45, -70.64]}
        zoom={13}
        style={{ width: "100vh", height: "50vh" }}
      >
        <TileLayer attribution={attributionBICCI} url={urlGrey} />

        {/* Dibujar la ruta */}
        {routeGeometry && routeGeometry.length > 0 && (
          <Polyline
            positions={routeGeometry.map(([lon, lat]) => [lat, lon])}
            color="blue"
          />
        )}

        {masiveData.waypoints.map((wp, index) => (
          <>
            {wp.id === "origen" ? null : (
              <Marker position={[wp.lon, wp.lat]} key={index}>
                <Popup>{`ID ${wp.id}`}</Popup>
              </Marker>
            )}
          </>
        ))}

        {/* Dibujar los marcadores en el orden optimizado */}
        {/*  */}
        {optimizedWaypoints.map((wp, index) => (
          <CircleMarker
            key={index}
            center={[wp.lat, wp.lon]}
            radius={0} // Hace que el marcador sea invisible
          >
            <Tooltip permanent direction="top">
              {index === 0
                ? "Inicio"
                : index === optimizedWaypoints.length - 1
                ? "Destino"
                : `${wp.index} : ${wp.ids.length > 1 ? wp.ids.length : "1"} 📦`}
            </Tooltip>
          </CircleMarker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapWithRoute;
