import {
  Box,
  Typography,
  IconButton,
  Badge,
  CircularProgress,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ToolDasthRouteNotifications from "../ToolDasthRouteNotifications/ToolDasthRouteNotifications";

const AnalyticsSummary = ({
  activeRoutes,
  closedRoutes,
  totalRoutes,
  totalDrivers,
  totalObservations,
  handleNotificationsClick,
  routes,
  progress,
  nuid,
  ridersNames,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "white",
        p: 2,
        borderRadius: 2,
        boxShadow: 2,
        flexWrap: "wrap",
        gap: 2,
        padding: "5px",
      }}
    >
      {/* Información de analíticas con progress circulares mejorados */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr 1fr", sm: "1fr 1fr 1fr 1fr" }, // Adaptable
          gap: 2,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {[
          {
            label: "% Finalizadas",
            value: progress,
            color: "success",
          },
          { label: "Activas", value: activeRoutes, color: "success" },
          //   { label: "Cerradas", value: closedRoutes, color: "error" },
          { label: "Total", value: totalRoutes, color: "primary" },
          { label: "Conductores", value: totalDrivers, color: "secondary" },
        ].map(({ label, value, color }, index) => (
          <Box
            key={index}
            sx={{ position: "relative", display: "inline-flex" }}
          >
            <Typography> </Typography>
            <CircularProgress
              variant="determinate"
              value={(value / totalRoutes) * 100}
              color={color}
              size={60}
              thickness={5}
              sx={{
                position: "absolute",
                zIndex: 1,
              }}
            />
            <CircularProgress
              variant="determinate"
              value={100}
              size={60}
              thickness={5}
              sx={{
                color: "#e0e0e0", // Fondo gris claro para la sección vacía
              }}
            />
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translate(0, )",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: 50, color: "#030303" }}
              >
                {value}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ marginTop: 7 }}>
              {label}
            </Typography>
          </Box>
        ))}
      </Box>

      <ToolDasthRouteNotifications
        routes={routes}
        nuid={nuid}
        ridersNames={ridersNames}
      ></ToolDasthRouteNotifications>
    </Box>
  );
};

export default AnalyticsSummary;
