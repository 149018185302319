import React from "react";
import Boxinfoservices from "../../../components/Micuentacardboxs/Boxinfoservices";
import Boxdireccion from "../../../components/Micuentacardboxs/Boxdireccion";
import Boxdispach from "../../../components/Micuentacardboxs/Boxdispach";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";
import { Box, Card } from "@mui/material";

export const OpcionesLaterales = (props) => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    if (props) {
      setUser(props.useStorage);
    }
  }, [props]);

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", mt: 4, minWidth: "300px" }}>
      <Card sx={{ mb: 3, p: 2, boxShadow: 3 }}>
        <Boxinfoservices
          serviciosAct={props.serviciosAct}
          props={props}
        ></Boxinfoservices>

        <Boxdireccion props={props}></Boxdireccion>

        <Boxdispach props={props} usuarioStorage={user}></Boxdispach>
      </Card>
    </Box>
  );
};
