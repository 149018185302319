import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  CardHeader,
  Box,
  Alert,
  AlertTitle,
  Typography,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { assingMultipleDriver } from "../../redux/accionesDrivers";

const PopUpAsignamentRoute = ({
  ridersDataByCompany,
  opemPopUpAsignamentRoute,
  setOpenPopUpAsignamentRoute,
  routeToAsing,
  mergedOrders,
}) => {
  const dispatch = useDispatch();
  const response = useSelector((store) => store.usuarioDriver.response);
  const usuarioDriver = useSelector((store) => store.usuarioDriver);

  const [selectedRider, setSelectedRider] = useState(null);
  const [apiResponses, setApiResponses] = useState("");
  const [apiError, setApiError] = useState("");

  const [enabledRidersDataByCompany, setEnabledRidersDataByCompany] = useState(
    []
  );
  const [ordersForAccions, setOrdersForAccions] = useState([]);

  // Estado para mostrar la interfaz de espera
  const [loading, setLoading] = useState(false);

  /**
   * Extrae las IDs de las órdenes de los waypoints,
   * filtrando "origen" y "destino".
   */
  const extractOptimizedWaypoints = (ruta) => {
    let ordersList = [];
    if (!ruta?.waypoints) return ordersList; // si no hay waypoints, regresa []

    ruta.waypoints.forEach((wp) => {
      if (wp.ids && Array.isArray(wp.ids)) {
        ordersList.push(...wp.ids);
      }
    });
    return ordersList.filter((id) => id !== "origen" && id !== "destino");
  };

  /**
   * Dividir un array en subarrays (batches) de tamaño 'size'
   */
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  /**
   * useEffect para preparar las orders (ordersForAccions)
   * a partir de la ruta seleccionada (routeToAsing).
   */
  useEffect(() => {
    if (routeToAsing?.waypoints?.length > 0) {
      const orders = extractOptimizedWaypoints(routeToAsing);

      setOrdersForAccions(orders);
    }
  }, [routeToAsing]);

  /**
   * Filtra conductores que NO tengan 'hiddenListMenus'
   */
  useEffect(() => {
    if (ridersDataByCompany.length > 0) {
      let enableRiders = ridersDataByCompany.filter(
        (rider) => !rider.hiddenListMenus
      );
      setEnabledRidersDataByCompany(enableRiders);
    }
  }, [ridersDataByCompany]);

  /**
   * Monitorea cambios en "response" (del Redux),
   * y maneja mensajes de éxito/error.
   */

  useEffect(() => {
    if (response && response.result) {
      // Ejemplo de estructura que estás recibiendo
      // if (response.result.status === "success") ...
      console.log(response);
      try {
        if (response.result.status === "success") {
          setApiResponses(response.result.message);
          // Podrías cerrar el popup aquí si quieres
          handleClose();
        } else {
          setApiError(`Error en la asignación: ${response.error.message}`);
          setLoading(false);
          handleClose();
        }
      } catch (error) {
        console.log("Error en useEffect de response:", error);
      }
      // Cuando termine de procesar la response, se quita la carga
      setLoading(false);
    }
  }, [response]);

  /**
   * Asigna conductor a las órdenes
   */
  const asignarConductor = async () => {
    console.log("asignarConductor", ordersForAccions);
    // tengo que verificar el usuario actual  assingMultipleDriver falla si el conductor ya tienen uno de los pedidos asignado previamente

    if (!selectedRider) return;
    setLoading(true); // Inicia la "interfaz de espera"
    setApiError("");
    setApiResponses("");

    // 🔥 1️⃣ Filtrar órdenes ya asignadas al conductor seleccionado
    // 🔥 Verificar la estructura de mergedOrders

    // 🔹 Filtrar órdenes asignadas al conductor seleccionado
    const assignedOrders = mergedOrders
      .filter((order) => {
        const driver = order.driverID ? order.driverID.trim() : "";
        // console.log(`🔍 Revisando orden: ${order.id} - Driver: ${driver}`);

        return driver === selectedRider.id;
      })
      .map((order) => order.id);

    // 🚛 Log de órdenes encontradas

    // 🔥 2️⃣ Filtrar solo las órdenes que el conductor NO tiene asignadas
    const posibleAssign = ordersForAccions.filter(
      (order) => !assignedOrders.includes(order)
    );

    if (posibleAssign.length === 0) {
      console.log("Ya asignado al usuario");
      setLoading(false);
      handleClose();
      return;
    }
    // Dividir ordersForAccions en lotes de 20
    const batches = chunkArray(posibleAssign, 20);

    try {
      for (const batch of batches) {
        const dataAsid = {
          data: {
            driverID: selectedRider.id,
            orderIDs: batch,
          },
        };

        // Despachas la acción de Redux
        // Nota: Esto dispara el "response" en el store => Se ve en el useEffect
        dispatch(assingMultipleDriver(dataAsid));
        console.log(" dispatch(assingMultipleDriver(dataAsid));", dataAsid);

        // En muchos casos, la dispatch es asíncrona pero no "awaitable" directamente.
        // Si tu acción retorna una promesa, podrías hacer: await dispatch(assingMultipleDriver(dataAsid));
      }
    } catch (error) {
      console.error("Error al asignar conductor:", error);
      setApiError(error.message || "Error desconocido");
      setLoading(false);
    }
  };

  /**
   * Cerrar el popup
   */
  const handleClose = () => {
    setOpenPopUpAsignamentRoute(false);
  };

  /**
   * Click en la fila de DataGrid
   */
  const handleRowClick = (params) => {
    setSelectedRider({
      id: params.id,
      name: params.row.name,
      avatar: params.row.avatar,
    });
  };

  const columnsRiders = [
    {
      field: "conductor",
      headerName: "Seleccione un conductor",
      width: 300,
      renderCell: (params) => (
        <CardHeader
          avatar={<Avatar src={params.row.avatar} />}
          title={params.row.name}
          subheader={params.row.bicciNumber}
        />
      ),
    },
  ];

  return (
    <Dialog
      open={opemPopUpAsignamentRoute}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: { xs: "100%", sm: "100%", md: "700px" },
          maxWidth: "700px",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        Asignar conductor a ruta: {routeToAsing?.route}
      </DialogTitle>

      <DialogContent>
        {/* Interfaz de espera: si loading, mostramos un LinearProgress arriba */}
        {loading && (
          <Box sx={{ width: "100%", mb: 1 }}>
            <LinearProgress />
            <Typography variant="body2">Asignando órdenes...</Typography>
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            height: 40,
            p: 1,
            backgroundColor: "#fafafa",
            mb: 2,
          }}
        >
          Envíos seleccionados para asignar: {ordersForAccions.length}
        </Box>

        <Box sx={{ width: "100%", height: 350, backgroundColor: "#fafafa" }}>
          {enabledRidersDataByCompany && (
            <DataGrid
              rows={enabledRidersDataByCompany}
              columns={columnsRiders}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={handleRowClick}
              rowHeight={100}
            />
          )}
        </Box>

        {selectedRider && (
          <CardHeader
            avatar={<Avatar src={selectedRider.avatar} />}
            title={selectedRider.name}
            subheader={`ID: ${selectedRider.id}`}
          />
        )}

        {/* Mostramos éxito o error si existen */}
        {apiResponses && !apiError && (
          <Alert severity="success" sx={{ mt: 2 }}>
            <AlertTitle>Asignación Exitosa</AlertTitle>
            {apiResponses}
          </Alert>
        )}
        {apiError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <AlertTitle>Error</AlertTitle>
            {apiError}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={loading}>
          Cancelar
        </Button>
        {ordersForAccions.length > 0 ? (
          <Button onClick={asignarConductor} color="primary" disabled={loading}>
            Asignar conductor
          </Button>
        ) : (
          <Button color="primary" disabled>
            0 envíos seleccionados
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PopUpAsignamentRoute;
