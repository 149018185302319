import { db } from "../../../../firebase"; // Importa tu instancia de Firestore
import { writeBatch, doc } from "firebase/firestore";

/**
 * 📌 Actualiza solo `routingData` en la subcolección `waypoints` de una ruta
 * @param {string} routeID - ID de la ruta en Firestore
 * @param {Object[]} updatedWaypoints - Array de objetos con `id` del waypoint y `routingData` actualizado
 */
export const updateRouteWaypointsRoutingData = async (
  routeID,
  updatedWaypoints
) => {
  if (!updatedWaypoints || updatedWaypoints.length === 0) {
    console.warn(`⚠️ No hay waypoints actualizados para la ruta ${routeID}`);
    return false;
  }

  const batch = writeBatch(db); // ✅ Corrección para Firebase v9+

  for (const updatedWp of updatedWaypoints) {
    const wpRef = doc(
      db,
      "xpress_deliveryPlans",
      routeID,
      "waypoints",
      updatedWp.id
    ); // ✅ Forma correcta de referenciar subcolecciones

    batch.update(wpRef, {
      index: updatedWp.index,
      "routingData.distanceMeters": updatedWp.routingData.distanceMeters,
      "routingData.formattedDistance": updatedWp.routingData.formattedDistance,
      "routingData.estimatedTimeSeconds":
        updatedWp.routingData.estimatedTimeSeconds,
      "routingData.formattedTime": updatedWp.routingData.formattedTime,
      "routingData.segment": updatedWp.routingData.segment,
    });
  }

  try {
    await batch.commit();

    return true;
  } catch (error) {
    console.error(
      `❌ Error actualizando waypoints en la ruta ${routeID}:`,
      error
    );
    return false;
  }
};
