import React from "react";
import {
  AppBar,
  Toolbar,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FilterAlt, ClearAll } from "@mui/icons-material";

export default function ControlPanel({
  activeSimulationsOps,
  setActiveSimulationsOps,
  orderSistem,
  setRemoveOrderSystem,
  driverFilter,
  setDriverFilter,
  memoizedData,
  vertical,
  handleChangeService,
  polygonsType,
  handleTypePoligons,
  driver,
  handleChangeDriver,
  viewDrivers,
  setViewDrivers,
  viewPendings,
  setViewPendings,
  viewDeliverys,
  setViewDeliverys,
  viewExepcions,
  setViewViewExepcions,
  viewPoligons,
  setViewPoligons,
}) {
  return (
    <AppBar
      position="static"
      sx={{ background: "#fff", boxShadow: "none", padding: "4px" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        {/* 🔄 Modo de Simulación */}
        <FormControlLabel
          control={
            <Switch
              checked={activeSimulationsOps}
              onChange={() => setActiveSimulationsOps(!activeSimulationsOps)}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": { color: "#591e8f" },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#591e8f",
                },
              }}
            />
          }
          label={
            <Typography
              sx={{ color: activeSimulationsOps ? "#591e8f" : "#000" }}
            >
              Modo
            </Typography>
          }
        />

        {/* 📌 Selector de Servicio */}
        <Select
          value={vertical}
          onChange={handleChangeService}
          size="small"
          sx={{ backgroundColor: "#f8f8f8", minWidth: "120px" }}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="SD">Same Day</MenuItem>
          <MenuItem value="HD">Home Delivery</MenuItem>
        </Select>

        {/* 📌 Selector de Polígonos */}
        <Select
          value={polygonsType}
          onChange={handleTypePoligons}
          size="small"
          sx={{ backgroundColor: "#f8f8f8", minWidth: "140px" }}
        >
          <MenuItem value="redpoligonos">Polígonos Bicci</MenuItem>
          <MenuItem value="comunaspoligonos">Por Comunas</MenuItem>
        </Select>

        {/* 📌 Selector de Conductores */}
        <Select
          value={driver}
          onChange={handleChangeDriver}
          size="small"
          sx={{ backgroundColor: "#f8f8f8", minWidth: "140px" }}
        >
          {memoizedData?.metricsOps?.map((item, index) => (
            <MenuItem key={index} value={item.driverID}>
              {item.driverName}
            </MenuItem>
          ))}
        </Select>

        {/* 🧹 Botón para limpiar filtros */}
        <Tooltip title="Limpiar Filtros">
          <IconButton onClick={() => setDriverFilter([])}>
            <ClearAll />
          </IconButton>
        </Tooltip>

        {/* 🔄 Swiches de Vista */}
        {[
          { label: "Riders", state: viewDrivers, setter: setViewDrivers },
          { label: "Pendientes", state: viewPendings, setter: setViewPendings },
          {
            label: "Entregados",
            state: viewDeliverys,
            setter: setViewDeliverys,
          },
          {
            label: "Excepciones",
            state: viewExepcions,
            setter: setViewViewExepcions,
          },
          { label: "Polígonos", state: viewPoligons, setter: setViewPoligons },
        ].map((item, index) => (
          <Tooltip title={item.label} key={index}>
            <FormControlLabel
              control={
                <Switch
                  checked={item.state}
                  onChange={() => item.setter(!item.state)}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": { color: "#591e8f" },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#591e8f",
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ color: item.state ? "#591e8f" : "#000" }}>
                  {item.label}
                </Typography>
              }
            />
          </Tooltip>
        ))}
      </Toolbar>
    </AppBar>
  );
}
