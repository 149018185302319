export const calculateRouteCentroids = (routeInfo) => {
  const routeCentroids = {};
  Object.entries(routeInfo.ordersByRoute).forEach(
    ([routeName, routeOrders]) => {
      const validOrders = routeOrders.filter(
        (o) =>
          o.toAddressLocation &&
          typeof o.toAddressLocation._lat === "number" &&
          typeof o.toAddressLocation._long === "number"
      );

      if (validOrders.length > 0) {
        const sumLat = validOrders.reduce(
          (acc, o) => acc + o.toAddressLocation._lat,
          0
        );
        const sumLng = validOrders.reduce(
          (acc, o) => acc + o.toAddressLocation._long,
          0
        );

        routeCentroids[routeName] = {
          lat: sumLat / validOrders.length,
          lng: sumLng / validOrders.length,
        };
      }
    }
  );
  return routeCentroids;
};
