import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import SecurityIcon from "@mui/icons-material/Security";
import { db } from "../../firebase";
import Fromloging from "./Fromloging/Fromloging";
import Fromsession from "./Fromsession";
import Fromnewcompany from "./Fromsession/Fromnewcompany";
import { purchaseOrderEbiex } from "../../redux/accionesCompanies";
import Fromwiewcompany from "./Fromsession/Fromwiewcompany";
import Newaddressbilling from "./Newaddressbilling/Newaddressbilling";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
var newPurchase;
const Checkout = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lastItem = useSelector((store) => store.clienteEbiex);
  const resperror = useSelector((store) => store.usuario.error);
  var resperrorPurcharse = useSelector(
    (store) => store.clienteCompany.errorPurcharse
  );
  var respsPurcharse = useSelector(
    (store) => store.clienteCompany.succesPurcharse
  );
  const cart = useSelector((store) => store.cartAccion.cartContent);
  const itemsCart = useSelector((store) => store.cartAccion.cart);

  //var item = JSON.parse(localStorage.getItem("itemCard"));
  let carro = JSON.parse(localStorage.getItem("itemCard"));
  if (carro) {
    localStorage.removeItem("itemCard");
  }
  const loginNow = useSelector((store) => store.usuario.user);

  var currentUser = JSON.parse(localStorage.getItem("usuario"));

  // console.log(props.match.params.slugs);
  const [ready, setReady] = React.useState("");
  const [checkoutState, setCheckoutState] = React.useState([]);

  const [isCarContent, setIsCarContent] = React.useState(false);
  const [isUser, setIsUser] = React.useState(false);
  const [isSession, setIsSession] = React.useState(false);
  const [isDeliveryMode, setIsDeliveryMode] = React.useState(true);
  const [isProductoDetails, setIsProductoDetails] = React.useState(false);
  const [isBillimgAdress, setIsBillimgAdress] = React.useState(false);
  const [isAssignCompany, setIsAssignCompany] = React.useState(false);
  const [isPayMethods, setIsPayMethods] = React.useState(false);

  const [isReadyToPay, setReadyToPay] = React.useState(false);

  const [closedCarContent, setClosedCarContent] = React.useState(true);
  const [closedUser, setClosedUser] = React.useState(true);
  const [closedSession, setClosedSession] = React.useState(true);
  const [closedDeliveryMode, setClosedDeliveryMode] = React.useState(true);
  const [closedProductoDetails, setClosedProductoDetails] =
    React.useState(true);
  const [closedBillimgAdress, setClosedBillimgAdress] = React.useState(false);
  const [closedAssignCompany, setClosedAssignCompany] = React.useState(true);
  const [closedPayMethods, setClosedPayMethods] = React.useState(true);
  const [closedReadyToPay, setClosedReadyToPay] = React.useState(false);

  const [carContent, setCarContent] = React.useState("");
  const [user, setUser] = React.useState("");
  const [session, setSession] = React.useState("");
  const [assignCompany, setAssignCompany] = React.useState("");
  //  const [deliveryMode, setDeliveryMode] = React.useState("");
  const [productoDetails, setProductoDetails] = React.useState("");

  const [typebilling, setTypebilling] = React.useState("");

  const [billimgAdress, setBillimgAdress] = React.useState("");
  // const [shippingAdress, setShippingAdress] = React.useState("");
  const [payMethods, setPayMethods] = React.useState("");
  const [payMethodsId, setPayMethodsId] = React.useState("");
  //const [payCards, setpayCards] = React.useState("");
  const [errorSelect2, setErrorSelect2] = React.useState("");
  const [errorSelect5, setErrorSelect5] = React.useState("");
  const [errorSelect7, setErrorSelect7] = React.useState("");

  const [ChildTrigres, setChildTrigres] = React.useState(true);

  const store = useSelector((store) => store.clienteCompany);
  // console.log(store)
  const toggleChildTrigres = () => {
    setChildTrigres(!ChildTrigres);
  };

  React.useEffect(() => {
    if (currentUser) {
      obtenerDatosdeCliente(currentUser.uid);
    }
  }, []);

  React.useEffect(() => {
    if (currentUser) {
      obtenerDatosdeCliente(currentUser.uid);
    }
  }, [ChildTrigres]);

  React.useEffect(() => {
    setIsUser(true);
    setUser(loginNow);
    aoutoAvance();
  }, [loginNow]);

  React.useEffect(() => {
    setProductoDetails(lastItem.itemid.product);
    setCarContent(lastItem.itemid.plan);
    aoutoAvance();
  }, [lastItem]);

  React.useEffect(() => {
    if (currentUser) {
      setIsUser(true);
      setUser(currentUser);
      // getCompany(currentUser.uid)
    } else {
      setIsUser(false);
    }

    if (cart) {
      setIsCarContent(true);
      setIsProductoDetails(true);
      setProductoDetails(cart.article);
      //setCarContent(item.itemCard.plan);
    } else {
      setIsCarContent(false);
      setIsProductoDetails(false);
    }

    //setIsCarContent(true); ok
    // setIsUser(false); ok
    // setIsSession(true);
    // setIsDeliveryMode(true);
    //setIsProductoDetails(true); ok
    // setIsBillimgAdress(true);
    // setIsAssignCompany(true);
    // setIsPayMethods(true);
    aoutoAvance();
  }, [ready]);

  const obtenerDatosdeCliente = async (userUid) => {
    try {
      const accountants = await db
        .collection(`${process.env.REACT_APP_COL_USERS}`)
        .doc(userUid)
        .get();
      var data = accountants.data();
      setSession(data);
      setIsSession(true);
      // setUserCompany(data.userCompany)
      // setUserCompanyID(data.userCompanyID)
      // setUserCompanyRoll(data.userCompanyRoll)

      const companiesaccountants = await db
        .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
        .doc(data.userCompanyID)
        .get();
      var dataComp = companiesaccountants.data();
      //console.log(dataComp);
      if (dataComp) {
        setIsAssignCompany(true);
        setAssignCompany(dataComp);
      } else {
        setIsAssignCompany(false);
        setAssignCompany("");
      }
    } catch (error) {}
  };

  const printEstatus = () => {
    console.info("--------");

    // console.log("Entrada: " + ready);
    // console.log("isReadyToPay: " + isReadyToPay);
    // console.log("isPayMethods: " + isPayMethods);
    // console.log("isAssignCompany: " + isAssignCompany);
    // console.log("isBillimgAdress: " + isBillimgAdress);
    // console.log("isProductoDetails: " + isProductoDetails);
    // console.log("isDeliveryMode: " + isDeliveryMode);
    // console.log("isSession: " + isSession);
    // console.log("isUser: " + isUser);
    // console.log("isCarContent: " + isCarContent);
    // console.info('->');
    // console.log('closedCarContent ' +closedCarContent);
    // console.log('closedUser ' +closedUser);
    // console.log('closedSession ' +closedSession);
    // console.log('closedDeliveryMode ' +closedDeliveryMode);
    // console.log('closedProductoDetails ' +closedProductoDetails);
    // console.log('closedBillimgAdress ' +closedBillimgAdress);
    // console.log('closedAssignCompany ' +closedAssignCompany);
    // console.log('closedPayMethods ' +closedPayMethods);
    // console.log('closedReadyToPay ' +closedReadyToPay);
    // console.info('->');
    // console.log("Entrada: " + ready);
    // console.log("PayMethods: " + payMethods);
    // console.log("AssignCompany: " + assignCompany);
    // console.log("BillimgAdress: " + billimgAdress);
    // console.log("ProductoDetails: " + productoDetails);
    // console.log("Session: " + session);
    // console.log("User: " + user);
    // console.log("CarContent: " + carContent);
    // console.info('--------');
  };

  const aoutoAvance = async () => {
    var avancePoint;

    if (isReadyToPay === false) {
      closedAll();
      avancePoint = 8;
      setClosedReadyToPay(false);
    }
    if (isPayMethods === false) {
      closedAll();
      avancePoint = 7;
      setClosedPayMethods(false);
    }
    if (isAssignCompany === false) {
      closedAll();
      avancePoint = 6;
      setClosedAssignCompany(false);
    }
    if (isBillimgAdress === false) {
      closedAll();
      avancePoint = 5;
      setClosedBillimgAdress(false);
    }
    if (isProductoDetails === false) {
      closedAll();
      avancePoint = 4;
      setClosedProductoDetails(false);
    }
    if (isDeliveryMode === false) {
      closedAll();
      avancePoint = 3;
      setClosedDeliveryMode(false);
    }
    if (isSession === false) {
      closedAll();
      avancePoint = 2;
      setClosedSession(false);
    }
    if (isUser === false) {
      closedAll();
      avancePoint = 1;
      setClosedUser(false);
    }
    if (isCarContent === false) {
      closedAll();
      avancePoint = 0;
      setClosedCarContent(false);
    }
    if (ready === 8) {
      setReadyToPay(true);
      avancePoint = 0;
    }
    setReady(avancePoint);
    toggleChildTrigres();

    // console.log("Salida: " + ready);
    //  printEstatus()
  };

  const closedAll = () => {
    setClosedCarContent(true);
    setClosedUser(true);
    setClosedSession(true);
    setClosedDeliveryMode(true);
    setClosedProductoDetails(true);
    setClosedBillimgAdress(true);
    setClosedAssignCompany(true);
    setClosedPayMethods(true);
    setClosedReadyToPay(true);
  };

  const handleClick0 = async (e) => {
    setIsCarContent(true);
    await aoutoAvance();
  };
  const handleClick1 = async (e) => {
    setIsUser(true);
    await aoutoAvance();
  };
  const handleClick2 = async (e) => {
    if (typebilling) {
      setIsSession(true);
    } else {
      setIsSession(false);
      setErrorSelect2("Debe seleccionar Boleta o Factura");
    }
    await aoutoAvance();
  };

  const handleback2 = async (e) => {
    setReadyToPay(false);
    setReady(2);
    setIsPayMethods(false);
    setIsAssignCompany(false);
    setIsBillimgAdress(false);
    setIsProductoDetails(true);
    setIsDeliveryMode(true);
    setIsSession(false);
    setIsUser(true);
    setIsCarContent(true);
    setPayMethods("");
    setBillimgAdress("");
    closedAll();
    setClosedSession(true);
    toggleChildTrigres();
  };
  const handleback5 = async (e) => {
    setReadyToPay(false);
    setReady(5);
    setIsPayMethods(false);
    setIsAssignCompany(true);
    setIsBillimgAdress(false);
    setIsProductoDetails(true);
    setIsDeliveryMode(true);
    setIsSession(false);
    setIsUser(true);
    setIsCarContent(true);
    setPayMethods("");
    setBillimgAdress("");
    closedAll();
    setClosedBillimgAdress(false);
    toggleChildTrigres();
  };

  const handleClick3 = async (e) => {
    setIsDeliveryMode(true);
    aoutoAvance();
  };
  const handleClick4 = async (e) => {
    setIsProductoDetails(true);
    await aoutoAvance();
  };
  const handleClick5 = async (e) => {
    if (billimgAdress) {
      setIsBillimgAdress(true);
      await aoutoAvance();
      setClosedPayMethods(false);
    } else {
      setIsBillimgAdress(false);
      setErrorSelect5("Debe seleccionar una opcion");
    }
  };
  const handleClick6 = async (e) => {
    setIsAssignCompany(true);
    await aoutoAvance();
  };
  const handleClick7 = async (e) => {
    if (payMethods && payMethods !== " ") {
      setIsPayMethods(true);
      await aoutoAvance();
    } else {
      setIsPayMethods(false);
      setErrorSelect7("Debe seleccionar una opcion");
    }
  };

  const handleSelectTypebilling = (e) => {
    const target = e.target;
    const id = target.id;
    const checked = target.checked;
    const value = target.value;
    //console.log(id)
    //console.log(value);
    setTypebilling(value);
  };
  const handleSelectBillimgAdress = (e) => {
    const target = e.target;
    const value = target.value;
    if (value === "equalAddressBilling") {
      //console.log('subir misma dirección');
      setBillimgAdress(value);
    } else {
      //console.log('subir otra dirección');
      setBillimgAdress(value);
    }
    // envia dirección
  };
  const handleSelectPayMethods = (e) => {
    const target = e.target;
    const id = target.id;
    const value = target.value;
    setPayMethods(value);
    setPayMethodsId(id);
  };

  const purchaseOrder = () => {
    //console.log('purchaseOrder')
    newPurchase = {
      shippingNotes: "",
      carContent: cart,
      user: user,
      session: session,
      assignCompany: assignCompany,
      companyID: assignCompany.companyID,
      productoDetails: productoDetails,
      typebilling: typebilling,
      billimgAdress: billimgAdress,
      payMethodsId: payMethodsId,
      payMethods: payMethods,
      status: "100",
      statusLabel: "Pendiente de Pago",
      createdate: Date.now(),
      createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
    };
    //console.log(newPurchase)
    dispatch(purchaseOrderEbiex(newPurchase));
  };

  if (respsPurcharse) {
    function delay() {
      navigate(
        `/billing/${assignCompany.companyID}/${respsPurcharse.purchaseId}`
      );
    }
    setTimeout(delay, 3000);
  }

  // console.log(session);
  // console.log(assignCompany);
  //printEstatus()
  return (
    <div className="bg-container">
      {/*}
      <div className="ui-vpp-store__logo-container developerControlBox">
        <h5>Desarrollo UX Control de Estados de compra : ESTATUS {ready}</h5>
        {user ? <h5>ROLL:{user.roll}</h5> : <>'No registrado'</>}
        <br></br>
        {session ? (
          <>
            {session.userCompanyID ? (
              <h5>ID COMP:{session.userCompanyID}</h5>
            ) : (
              <>
                'No userCompanyID Asignado '<br></br>
              </>
            )}
          </>
        ) : (
          <>No existe variables sesión</>
        )}
        <br></br>
        {session ? (
          <>
            {session.userCompanyRoll ? (
              <h5>ROLL COMP:{session.userCompanyRoll}</h5>
            ) : (
              <>
                'No userCompanyRoll Asignado'<br></br>
              </>
            )}
          </>
        ) : (
          <>No existe variables user</>
        )}
        <br></br>
        {assignCompany ? (
          <>
            {assignCompany.companyID ? (
              <h5>OK:{assignCompany.companyID}</h5>
            ) : (
              <>
                'No existe Company en sistema'<br></br>
              </>
            )}
          </>
        ) : (
          <>No existe Documento de Company</>
        )}
        <br></br>
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <th> Contenido del carro: </th>
              {isCarContent === true ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedCarContent === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick0}> Change</button>
                </th>
              }
            </tr>

            <tr>
              <th>Usuario: </th>
              {isUser ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedUser === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick1}> Change</button>
                </th>
              }
            </tr>

            <tr>
              <th> sesión iniciada: </th>
              {isSession ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedSession === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick2}> Change</button>
                </th>
              }
            </tr>
            <tr>
              <th> Dirección isDeliveryMode: </th>
              {isDeliveryMode ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedDeliveryMode === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick3}> Change</button>
                </th>
              }
            </tr>
            <tr>
              <th> Detalles de Producto: </th>
              {isProductoDetails ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedProductoDetails === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick4}> Change</button>
                </th>
              }
            </tr>
            <tr>
              <th> Dirección de envío: </th>
              {isBillimgAdress ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedBillimgAdress === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick5}> Change</button>
                </th>
              }
            </tr>
            <tr>
              <th> posee compañia: </th>
              {isAssignCompany === true ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedAssignCompany === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick6}> Change</button>
                </th>
              }
            </tr>
            <tr>
              <th> selecciono un metodo de pago: </th>
              {isPayMethods ? (
                <th>
                  {" "}
                  <span className="alert alert-success">True</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedPayMethods === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
              {
                <th>
                  {" "}
                  <button onClick={handleClick7}> Change</button>
                </th>
              }
            </tr>

            <tr>
              <th> Habilitar pago: </th>
              {isReadyToPay ? (
                <th>
                  {" "}
                  <span className="alert alert-success">BOTON PAGO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-danger">False</span>
                </th>
              )}
              {closedReadyToPay === true ? (
                <th>
                  {" "}
                  <span className="alert alert-secondary">OCULTO</span>
                </th>
              ) : (
                <th>
                  {" "}
                  <span className="alert alert-success">ACTIVO</span>
                </th>
              )}
            </tr>
          </tbody>
        </table>
      </div>
*/}
      <div>
        {isCarContent === true ? (
          <div className="bg-containercolor">
            <div id="bodycontent-prodtucto">
              <div className="ui-vpp-store__logo-container"></div>
              <div>
                <div className="breadcrumbebiex">
                  <div className="breadcrumbebiex-container">
                    {/* <h1>Pagina de pago</h1>
                    <NavLink to="/productos/">Volver a productos</NavLink> */}
                  </div>
                  <div className="bodycontent-prodtucto">
                    <div className="container-producto-check">
                      <section className="section-content padding-y bg">
                        <div className="container">
                          <article className="card">
                            <div className="card-body card-body-checout">
                              <div className="row">
                                <aside className="col-md-7">
                                  <Fromloging
                                    accion={handleClick1}
                                    dispatch={dispatch}
                                    resperror={resperror}
                                    state={isUser}
                                    setUserAcive={setUser}
                                    user={user}
                                  ></Fromloging>
                                  {user ? (
                                    <Fromsession
                                      state={isSession}
                                      setIsSession={setIsSession}
                                      setIsAssignCompany={setIsAssignCompany}
                                      accion={handleClick2}
                                      session={session}
                                      setSession={setSession}
                                      dispatch={dispatch}
                                      isAssignCompany={isAssignCompany}
                                      company={assignCompany}
                                      toggleChildTrigres={toggleChildTrigres}
                                    ></Fromsession>
                                  ) : (
                                    <>Debe iniciar sesión </>
                                  )}
                                  {user ? <></> : <></>}

                                  {assignCompany ? (
                                    <>
                                      {assignCompany.companyID ? (
                                        <div>
                                          {user ? (
                                            <>
                                              {session.userCompanyRoll ? (
                                                <>
                                                  {closedSession === true ? (
                                                    <>
                                                      <div className="color-box box-sussces ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                                        <span className="title-h3">
                                                          La {typebilling} se
                                                          emitira a{" "}
                                                          {
                                                            assignCompany.companyName
                                                          }
                                                        </span>
                                                        <span
                                                          className="span-float-rigth resalt"
                                                          onClick={handleback2}
                                                        >
                                                          Modificar
                                                        </span>
                                                        <div className="ui-seller-info"></div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div>
                                                        <Fromwiewcompany
                                                          state={isSession}
                                                          setIsSession={
                                                            setIsSession
                                                          }
                                                          setIsAssignCompany={
                                                            setIsAssignCompany
                                                          }
                                                          accion={handleClick1}
                                                          handleSelectTypebilling={
                                                            handleSelectTypebilling
                                                          }
                                                          session={session}
                                                          setSession={
                                                            setSession
                                                          }
                                                          dispatch={dispatch}
                                                          isAssignCompany={
                                                            isAssignCompany
                                                          }
                                                          company={
                                                            assignCompany
                                                          }
                                                          toggleChildTrigres={
                                                            toggleChildTrigres
                                                          }
                                                        ></Fromwiewcompany>
                                                        <div className="btn-rigth">
                                                          {errorSelect2 ? (
                                                            <span className="alert alert-danger microAlert">
                                                              {" "}
                                                              {
                                                                errorSelect2
                                                              }{" "}
                                                              <InfoIcon></InfoIcon>
                                                            </span>
                                                          ) : (
                                                            <></>
                                                          )}

                                                          <button
                                                            onClick={
                                                              handleClick2
                                                            }
                                                            className="btn btn-ebiex btn-globalhover"
                                                          >
                                                            Continuar
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  No tiene permisos delegados
                                                  <Fromwiewcompany
                                                    state={isSession}
                                                    setIsSession={setIsSession}
                                                    setIsAssignCompany={
                                                      setIsAssignCompany
                                                    }
                                                    accion={handleClick1}
                                                    handleSelectTypebilling={
                                                      handleSelectTypebilling
                                                    }
                                                    session={session}
                                                    setSession={setSession}
                                                    dispatch={dispatch}
                                                    isAssignCompany={
                                                      isAssignCompany
                                                    }
                                                    company={assignCompany}
                                                    toggleChildTrigres={
                                                      toggleChildTrigres
                                                    }
                                                  ></Fromwiewcompany>
                                                  <div className="btn-rigth">
                                                    {errorSelect2 ? (
                                                      <span className="alert alert-danger microAlert">
                                                        {" "}
                                                        {errorSelect2}{" "}
                                                        <InfoIcon></InfoIcon>
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <button
                                                      onClick={handleClick2}
                                                      className="btn btn-ebiex btn-globalhover"
                                                    >
                                                      Continuar
                                                    </button>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>No existe variables user</>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          'No existe Company en sistema'
                                          <br></br>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {user ? (
                                    <>
                                      {session.userCompanyRoll ? (
                                        <></>
                                      ) : (
                                        <>
                                          <Fromnewcompany
                                            state={isSession}
                                            setIsSession={setIsSession}
                                            setIsAssignCompany={
                                              setIsAssignCompany
                                            }
                                            accion={handleClick2}
                                            session={session}
                                            setSession={setSession}
                                            dispatch={dispatch}
                                            isAssignCompany={isAssignCompany}
                                            company={assignCompany}
                                            toggleChildTrigres={
                                              toggleChildTrigres
                                            }
                                          ></Fromnewcompany>

                                          {/* <div className='btn-rigth'>
                                           {
                                           errorSelect2?(<span className="alert alert-danger microAlert">  {errorSelect2} <InfoIcon></InfoIcon></span>):(<></>)
                                           }  
                                             
                                             <button onClick={handleClick2} className='btn btn-ebiex btn-globalhover'>Continuar</button></div>   */}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* {
                                    user?(<><Fromnewcompany 
                                      state={isSession}
                                      setIsSession={setIsSession}
                                      setIsAssignCompany={setIsAssignCompany} 
                                      accion={handleClick2}
                                      session={session}
                                      setSession={setSession}
                                      dispatch={dispatch}
                                      isAssignCompany={isAssignCompany}
                                      company={assignCompany}
                                      toggleChildTrigres={toggleChildTrigres}
                                     ></Fromnewcompany></>):(<></>)
                                  } */}

                                  {assignCompany ? (
                                    <>
                                      {closedBillimgAdress === true ? (
                                        <>
                                          {/* <h5>ventana cerrada BillimgAdress</h5> */}
                                        </>
                                      ) : (
                                        <>
                                          {isBillimgAdress === true ? (
                                            <>
                                              <div className="color-box box-sussces ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                                <span className="title-h3">
                                                  Dirección Seleccionada.
                                                </span>
                                                <span className="title-h3"></span>
                                                <span
                                                  className="span-float-rigth resalt"
                                                  onClick={handleback5}
                                                >
                                                  Modificar
                                                </span>

                                                <div className="ui-seller-info"></div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div>
                                                <div className="usa-fieldset">
                                                  <div>
                                                    {" "}
                                                    <Newaddressbilling
                                                      handleSelectBillimgAdress={
                                                        handleSelectBillimgAdress
                                                      }
                                                    ></Newaddressbilling>
                                                  </div>

                                                  <div className="btn-rigth">
                                                    {errorSelect5 ? (
                                                      <span className="alert alert-danger microAlert">
                                                        {" "}
                                                        {errorSelect5}{" "}
                                                        <InfoIcon></InfoIcon>
                                                      </span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <button
                                                      onClick={handleClick5}
                                                      className="btn btn-ebiex btn-globalhover"
                                                    >
                                                      Continuar
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {assignCompany ? (
                                    <>
                                      {isDeliveryMode === true ? (
                                        <></>
                                      ) : (
                                        // <div className="alert alert-success ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                        //   <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                                        //     <div className="ui-box-component ui-box-component-pdp__visible--desktop">
                                        //       <h3 className="title">
                                        //         Dirección de envío Seleccionada
                                        //       </h3>
                                        //       <div className="ui-seller-info"></div>
                                        //     </div>
                                        //   </div>
                                        // </div>
                                        <div className="usa-fieldset">
                                          <h2>
                                            Seleccion dirección de envío (NO
                                            APLICA)
                                          </h2>
                                          <div className="usa-radio">
                                            <input
                                              className="usa-radio__input usa-radio__input--tile"
                                              type="radio"
                                              id="getcode001"
                                              name="addressShipping"
                                              value="sojourner-truth"
                                            />
                                            <label
                                              className="usa-radio__label"
                                              htmlFor="getcode001"
                                            >
                                              Mi Oficina
                                            </label>
                                          </div>
                                          <div className="usa-fieldset">
                                            <div className="usa-radio">
                                              <input
                                                className="usa-radio__input usa-radio__input--tile"
                                                type="radio"
                                                id="getcode002"
                                                name="addressShipping"
                                                value="sojourner-truth"
                                              />
                                              <label
                                                className="usa-radio__label"
                                                htmlFor="getcode002"
                                              >
                                                Mi casa
                                              </label>
                                            </div>
                                          </div>
                                          <div className="btn-rigth">
                                            <button className="btn btn-ebiex btn-globalhover">
                                              Continuar
                                            </button>
                                          </div>
                                        </div>
                                      )}

                                      {closedPayMethods === true ? (
                                        <h5>
                                          {/* ventana cerrada PayMethods */}
                                        </h5>
                                      ) : (
                                        <>
                                          {isPayMethods === true ? (
                                            <div className="color-box box-sussces ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                              <span className="title-h3">
                                                La su metodo de pago es{" "}
                                                {payMethods}
                                              </span>
                                              <span
                                                className="span-float-rigth resalt"
                                                onClick={handleback2}
                                              >
                                                Modificar
                                              </span>
                                              <div className="ui-seller-info"></div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="usa-fieldset">
                                                <h2>
                                                  Seleccion metodo de pago
                                                </h2>
                                                <div className="usa-radio">
                                                  <input
                                                    className="usa-radio__input usa-radio__input--tile"
                                                    type="radio"
                                                    id="001"
                                                    name="paymentmethods"
                                                    value="Transferencia"
                                                    onClick={
                                                      handleSelectPayMethods
                                                    }
                                                  />
                                                  <label
                                                    className="usa-radio__label"
                                                    htmlFor="001"
                                                  >
                                                    Transferencia Bancaria
                                                  </label>
                                                </div>

                                                <div className="usa-fieldset">
                                                  <div className="usa-radio">
                                                    <input
                                                      className="usa-radio__input usa-radio__input--tile"
                                                      type="radio"
                                                      id="002"
                                                      name="paymentmethods"
                                                      value="Mercadopago"
                                                      onClick={
                                                        handleSelectPayMethods
                                                      }
                                                    />
                                                    <label
                                                      className="usa-radio__label"
                                                      htmlFor="002"
                                                    >
                                                      Mercadopago
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="btn-rigth">
                                                {errorSelect7 ? (
                                                  <span className="alert alert-danger microAlert">
                                                    {" "}
                                                    {errorSelect7}{" "}
                                                    <InfoIcon></InfoIcon>
                                                  </span>
                                                ) : (
                                                  <></>
                                                )}
                                                <button
                                                  onClick={handleClick7}
                                                  className="btn btn-ebiex btn-globalhover"
                                                >
                                                  Continuar
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}

                                      {isPayMethods === true ? (
                                        <></>
                                      ) : (
                                        // <div className="alert alert-success ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                        //   <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                                        //     <div className="ui-box-component ui-box-component-pdp__visible--desktop">
                                        //       <h3 className="title">
                                        //         Medio de pago seleccionado
                                        //         (True)
                                        //       </h3>
                                        //       <div className="ui-seller-info"></div>
                                        //     </div>
                                        //   </div>
                                        // </div>
                                        <></>
                                        // <div className="usa-fieldset">
                                        //   <h2>
                                        //     Seleccione Medio de pago (NO APLICA)
                                        //   </h2>
                                        //   <div className="usa-radio">
                                        //     <input
                                        //       className="usa-radio__input usa-radio__input--tile"
                                        //       type="radio"
                                        //       id="pay001"
                                        //       name="paymentcards"
                                        //       value="sojourner-truth"
                                        //     />
                                        //     <label
                                        //       className="usa-radio__label"
                                        //       htmlFor="pay001"
                                        //     >
                                        //       Tarjeta Visa **005
                                        //     </label>
                                        //   </div>
                                        //   <div className="usa-fieldset">
                                        //     <div className="usa-radio">
                                        //       <input
                                        //         className="usa-radio__input usa-radio__input--tile"
                                        //         type="radio"
                                        //         id="pay002"
                                        //         name="paymentcards"
                                        //         value="sojourner-truth"
                                        //       />
                                        //       <label
                                        //         className="usa-radio__label"
                                        //         htmlFor="pay002"
                                        //       >
                                        //         Tarjeta Masterd Card **005
                                        //       </label>
                                        //     </div>
                                        //   </div>
                                        //   <div className="usa-fieldset">
                                        //     <div className="usa-radio">
                                        //       <input
                                        //         className="usa-radio__input usa-radio__input--tile"
                                        //         type="radio"
                                        //         id="pay003"
                                        //         name="paymentcards"
                                        //         value="sojourner-truth"
                                        //       />
                                        //       <label
                                        //         className="usa-radio__label"
                                        //         htmlFor="pay003"
                                        //       >
                                        //         Pagar con WebPay
                                        //       </label>
                                        //     </div>
                                        //   </div>
                                        //   <div className='btn-rigth'><button className='btn btn-ebiex btn-globalhover'>Continuar</button></div>
                                        // </div>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {isReadyToPay === true ? (
                                    <>
                                      <hr />
                                      <div className="color-box ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                        <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                                          <Box
                                            className="formcontent-checkout"
                                            id="micuentafromIni"
                                          >
                                            <div>
                                              <div>
                                                <h5>Datos de la empresa</h5>
                                                <table className="table">
                                                  <tbody>
                                                    <tr>
                                                      <td>Nombre: </td>
                                                      <td>
                                                        {" "}
                                                        {
                                                          assignCompany.companyName
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Rut: </td>
                                                      <td>
                                                        {" "}
                                                        {
                                                          assignCompany.companyRut
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Actividad: </td>
                                                      <td>
                                                        {" "}
                                                        {
                                                          assignCompany.companyActividad
                                                        }
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                <div></div>
                                              </div>
                                              <div>
                                                <hr></hr>
                                                <h5>Dirección</h5>

                                                <div>
                                                  {assignCompany.companyAddress}
                                                  ,
                                                  {
                                                    assignCompany.companyNumAddress
                                                  }{" "}
                                                </div>
                                                <div></div>
                                                <div>
                                                  {assignCompany.companyComuna},{" "}
                                                  {assignCompany.companyCity}
                                                </div>
                                                <div></div>
                                                <div>
                                                  {assignCompany.companyRegion}
                                                </div>
                                                <div>
                                                  {assignCompany.companyCountry}
                                                </div>
                                                {/* <div>{  assignCompany.companyCountryCode}</div> */}
                                              </div>
                                              <div>
                                                <hr></hr>
                                                <h5>Contacto</h5>
                                                <div>
                                                  {
                                                    assignCompany.companyContacName
                                                  }
                                                </div>
                                                <div>
                                                  {
                                                    assignCompany.companyContacEmail
                                                  }
                                                </div>
                                                <div>
                                                  {
                                                    assignCompany.companyAdressPhone
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </Box>
                                        </div>
                                      </div>
                                      <hr />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </aside>

                                <main className="col-md-5 col-md-5-checout">
                                  <div className="ui-pdp--sticky-wrapper">
                                    <article className="cardarticle cardarticle-checout">
                                      <h2>Resumen de compra</h2>
                                      <hr />
                                      <Box
                                        component="img"
                                        src={
                                          "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FIconos-03.png?alt=media&token=bdff97b1-1471-4c59-a142-327e59c0b9ff"
                                        }
                                        className={"checkoutImages"}
                                        alt="Banner Footer"
                                        sx={{ width: "100%", height: "auto" }}
                                      />
                                      <div className="title">
                                        {/* Detalle de producto: */}
                                        <h3>{productoDetails.productName}</h3>
                                      </div>
                                      <hr></hr>
                                      <div className="row-tab-checkout">
                                        <div>Precio normal</div>
                                        <div className="row-tab-checkout-letf">
                                          {Intl.NumberFormat("es-CL", {
                                            style: "currency",
                                            currency: "CLP",
                                            minimumFractionDigits: 0,
                                          }).format(cart.cartArticlePrice)}
                                        </div>
                                      </div>
                                      <hr></hr>
                                      <div className="row-tab-checkout">
                                        <div>Pagas</div>
                                        <div className="row-tab-checkout-letf">
                                          <span className="h3">
                                            {Intl.NumberFormat("es-CL", {
                                              style: "currency",
                                              currency: "CLP",
                                              minimumFractionDigits: 0,
                                            }).format(cart.totalAmount)}{" "}
                                          </span>
                                        </div>
                                      </div>
                                      <div>
                                        <hr></hr>
                                        <div className="row-tab-checkout">
                                          <div>Descuento</div>
                                          <div className="row-tab-checkout-letf">
                                            {" "}
                                            {Intl.NumberFormat("es-CL", {
                                              style: "currency",
                                              currency: "CLP",
                                              minimumFractionDigits: 0,
                                            }).format(cart.totalDiscount)}
                                          </div>
                                        </div>
                                      </div>
                                      <hr></hr>
                                      {/* <div>Monto mensual $ {carContent.ChargeAmount}</div> */}
                                      <h3>
                                        {" "}
                                        Cantidad:{" "}
                                        <span className="h3">
                                          {cart.TotalChargeShipments} envíos
                                        </span>
                                      </h3>
                                      Articulos Incluidos:
                                      <ul className="list-dots mb-0">
                                        {cart.article.map((item) => (
                                          <li key={`id${item.id}`}>
                                            {" "}
                                            {item.productName}{" "}
                                          </li>
                                        ))}
                                      </ul>
                                      <hr />
                                      {isReadyToPay === true ? (
                                        <>
                                          {respsPurcharse ? (
                                            <span>
                                              {respsPurcharse.succesPurcharse}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          {resperrorPurcharse ? (
                                            <span>{resperrorPurcharse}</span>
                                          ) : (
                                            <></>
                                          )}
                                          <button
                                            onClick={purchaseOrder}
                                            className="btn btn-ebiex-greem"
                                          >
                                            PAGAR
                                          </button>{" "}
                                          <hr />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {/* <div className="mb-3">
                                      <h6>Detalle de pago</h6>

                                      <ul className="list-dots mb-0">
                                        <li>
                                          Precio:
                                          <span className="price h4">
                                            ${productoDetails.descriptorsWEB3}
                                          </span>
                                        </li>
                                        <li>
                                          Descuento:
                                          <span className="monthly">
                                            ${productoDetails.descriptorsWEB3}
                                          </span>
                                        </li>
                                        <li>
                                          Subtotal:
                                          <span className="monthly">
                                            ${productoDetails.descriptorsWEB3}
                                          </span>
                                        </li>
                                        <li>
                                          Recargo:
                                          <span className="monthly">
                                            ${productoDetails.descriptorsWEB3}
                                          </span>
                                        </li>
                                        <hr />
                                        <li>
                                          Total:
                                          <span className="price h4">
                                            ${productoDetails.descriptorsWEB3}
                                          </span>
                                        </li>
                                      </ul>
                                    </div> */}
                                      <div className="mb-4">
                                        {/* <button
                                        onClick={addToCard}
                                        className="ui positive button"
                                      >
                                        PAGAR
                                      </button> */}
                                      </div>
                                    </article>
                                    <br></br>
                                    <div className="ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                      <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                                        <div className="ui-box-component ui-box-component-pdp__visible--desktop">
                                          <h3 className="title">
                                            Medios de pago
                                          </h3>
                                          <div className="ui-seller-info">
                                            <div className="seller_header">
                                              <div className="ui-pdp-seller__header__image-container"></div>
                                              <div className="ui-pdp-seller__header__info-container">
                                                <div className="ui-pdp-seller__header__title">
                                                  Transferencia
                                                </div>
                                                <p className="ui-pdp-color--GRAY ui-pdp-size--XSMALL ui-pdp-family--REGULAR ui-pdp-seller__header__subtitle">
                                                  <SecurityIcon color="success"></SecurityIcon>
                                                  Pagos seguros:<br></br>
                                                  todos los pagos por
                                                  transferencias son recibidos a
                                                  nuestra cuenta empresa, no
                                                  utilizamos cuentas de
                                                  terceros.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
                                      <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
                                        <div className="ui-box-component ui-box-component-pdp__visible--desktop">
                                          <h3 className="title">
                                            {isUser === false ? (
                                              <div className="section-title-non">
                                                <h2>No a iniciado sesión</h2>
                                              </div>
                                            ) : (
                                              <div className="section-title">
                                                {/* <h2>Bienvenido</h2> */}
                                              </div>
                                            )}
                                          </h3>
                                          <div className="ui-seller-info">
                                            <div className="seller_header">
                                              <div className="ui-pdp-seller__header__image-container">
                                                <Box
                                                  component="img"
                                                  className="logo-infoStore"
                                                  src="https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Ficonografiabicci%2Ficono%20(24).svg?alt=media&token=ef6abc30-ba25-4422-bd45-5821ae5dc2cf"
                                                  alt="bicciexpress"
                                                  sx={{
                                                    width: "100%",
                                                    height: "auto",
                                                  }}
                                                />
                                              </div>
                                              <div className="ui-pdp-seller__header__info-container">
                                                <div className="ui-pdp-seller__header__title">
                                                  BICCI EXPRESS
                                                </div>
                                                <p className="ui-pdp-color--GRAY ui-pdp-size--XSMALL ui-pdp-family--REGULAR ui-pdp-seller__header__subtitle">
                                                  Tienda oficial.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </main>
                              </div>
                            </div>
                          </article>
                          <article className="card mt-5">
                            <div className="card-body">
                              <div className="row">[ Otros productos ]</div>
                              <hr />
                              <p>
                                Referencia a terminos y condiciones, consectetur
                                adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </p>
                            </div>
                          </article>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {" "}
            <article className="card mt-5">
              <div className="card-body">
                <div className="row">
                  [ NO HAY PODUCTO EN EL CARRO DE COMPRAS ]
                </div>
                <hr />
                <p>Referencia a PRODUCTOS DISPONIBLES</p>
              </div>
            </article>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
