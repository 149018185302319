import React, { useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { generateManualRoutingInverseData } from "../../MapsLeaflet/MapsUtils/UtilsOptimize/generateManualRoutingInverseData";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import { fetchOptimizedWaypoints } from "../../MapsLeaflet/MapsUtils/UtilsOptimize/fetchOptimizedWaypoints";
import { kmeans } from "../../MapsLeaflet/MapsUtils/UtilsOptimize/kmeans";
import { updateRouteInFirebaseDoc } from "../../MapsLeaflet/MapsUtils/UtilsOptimize/updateRouteInFirebaseDoc";
import { updateRouteWaypointsRoutingData } from "../../MapsLeaflet/MapsUtils/UtilsOptimize/updateRouteWaypointsRoutingData";

const RecalculateRouteDialog = ({ open, onClose, ruta, viewMergedOrders }) => {
  const [origenOption, setOrigenOption] = useState("predefinido");
  const [destinoOption, setDestinoOption] = useState("predefinido");

  // Geocodificación origen
  const [addressOrigin, setAddressOrigin] = useState("");
  const [origenGeoCode, setOrigenGeoCode] = useState(null);

  // Geocodificación destino
  const [addressDestino, setAddressDestino] = useState("");
  const [destinoGeoCode, setDestinoGeoCode] = useState(null);

  // 🔹 Función auxiliar para dividir rutas grandes requiere ajuste
  const divideLargeRoute = (route) => {
    const origen = route.waypoints.find((wp) => wp.id === "origen");
    const destino = route.waypoints.find((wp) => wp.id === "destino");

    let selectedOrders = route.waypoints.filter(
      (order) => order.id !== "origen" && order.id !== "destino"
    );
    const selectedOrderIds = selectedOrders.map((order) => order.id);

    const ordersToOptimize = viewMergedOrders.filter((order) =>
      selectedOrderIds.includes(order.id)
    );
    const dataToSend = ordersToOptimize
      .filter(
        (o) =>
          o.toAddressLocation &&
          typeof o.toAddressLocation._lat === "number" &&
          typeof o.toAddressLocation._long === "number"
      )
      .map((order) => ({
        id: order.id,
        lat: parseFloat(order.toAddressLocation._lat),
        lng: parseFloat(order.toAddressLocation._long),
      }));

    let numberOfRoutes = Math.ceil(dataToSend.length / 100);
    const { assignments } = kmeans(dataToSend, numberOfRoutes, 100);

    let clusters = Array.from({ length: numberOfRoutes }, () => []);
    assignments.forEach((clusterIndex, i) => {
      clusters[clusterIndex].push(dataToSend[i].id);
    });

    return clusters.map((cluster, index) => {
      const subRouteName = `${route.route}_sub${index + 1}`;

      return {
        route: subRouteName,
        vehicle: "driving",
        waypoints: [
          { id: origen.id, lon: origen.lon, lat: origen.lat, name: "" },
          ...cluster
            .map((id) => {
              let ord = viewMergedOrders.find((o) => o.id === id);
              return ord
                ? {
                    id: ord.id,
                    lat: ord.toAddressLocation._long || 0,
                    lon: ord.toAddressLocation._lat || 0,
                    name: "",
                  }
                : null;
            })
            .filter(Boolean),
          { id: destino.id, lon: destino.lon, lat: destino.lat, name: "" },
        ],
      };
    });
  };

  const handleConfirm = async () => {
    let origen = null;
    let destino = null;

    // Configurar origen
    if (origenOption === "predefinido") {
      // Se tomará automáticamente del `fromAddressLocation`
      origen = null;
    } else if (origenOption === "manual" && origenGeoCode) {
      // Usamos la geocodificación capturada
      origen = {
        lat: origenGeoCode.lat,
        lng: origenGeoCode.lng,
        address: addressOrigin,
      };
    }

    // Configurar destino
    if (destinoOption === "predefinido") {
      destino = null; // Retornar al origen
    } else if (destinoOption === "lejano") {
      destino = "far"; // Indica que se calculará el punto más lejano
    } else if (destinoOption === "manual" && destinoGeoCode) {
      destino = {
        lat: destinoGeoCode.lat,
        lng: destinoGeoCode.lng,
        address: addressDestino,
      };
    }

    // Llamar a la función de optimización
    const optimizationManualData = await generateManualRoutingInverseData(
      ruta,
      viewMergedOrders,
      origen,
      destino
    );

    let allOptimizedRoutes = [];
    let allOptimizedWaypoints = [];
    let allMetrics = {};

    for (let route of optimizationManualData) {
      if (!route.waypoints || route.waypoints.length < 3) {
        console.warn(
          `⚠️ Ruta ${route.route} tiene muy pocos waypoints, se omitirá.`
        );
        continue;
      }

      if (route.waypoints.length > 99) {
        // 🔹 PROCESAR RUTAS GRANDES (100+ PUNTOS)
        let optimizationDataSubRoutes = divideLargeRoute(route);

        for (let subRoute of optimizationDataSubRoutes) {
          let optimizedRoute = await fetchOptimizedWaypoints(subRoute);

          if (!optimizedRoute) {
            console.warn("⚠️ No se pudo optimizar la sub-ruta:", subRoute);
            continue;
          }
        }
      } else {
        // 🔹 PROCESAR RUTAS PEQUEÑAS (<= 99 PUNTOS)
        let optimizedRoute = await fetchOptimizedWaypoints(route);

        if (!optimizedRoute) {
          console.warn("⚠️ No se pudo optimizar la ruta:", route);
          continue;
        }

        function buildOptimizedRouteData(apiResponse) {
          if (!apiResponse || !apiResponse.geometry) {
            console.warn(
              "⚠️ No hay datos de `geometry` en la respuesta de la API."
            );
            return null;
          }

          // console.log("📌 Datos de API Response:", apiResponse); // 🔹 Verifica qué devuelve la API

          let Docgeometry = [];

          if (Array.isArray(apiResponse.geometry)) {
            Docgeometry = apiResponse.geometry
              .filter((point) => {
                if (!Array.isArray(point) || point.length !== 2) {
                  console.warn("⚠️ Punto inválido en geometry:", point);
                  return false;
                }
                return true;
              })
              .map(([lon, lat]) => ({ lon, lat }));
          } else {
            console.warn(
              "⚠️ `geometry` no es un array en la respuesta de la API."
            );
          }

          if (Docgeometry.length === 0) {
            console.warn("⚠️ No se encontraron puntos válidos en `geometry`.");
          }

          return {
            totalDistance: apiResponse.totalDistance || "0.00 km",
            totalTime: apiResponse.totalTime || "0.00 min",
            geometry: Docgeometry, // ✅ Se guarda correctamente como [{ lon, lat }]
            subRoutes: [],
          };
        }

        function buildUpdatedWaypoints(apiResponse, route) {
          if (!apiResponse || !apiResponse.waypoints) {
            //  console.warn("⚠️ No hay waypoints optimizados disponibles.");
            return [];
          }

          // console.log("📌 Datos de API Response:", apiResponse);

          return apiResponse.waypoints.flatMap((wp) => {
            // 🔹 Filtrar IDs para evitar crear waypoints para "origen" y "destino"
            const validIds = wp.ids.filter(
              (id) => id !== "origen" && id !== "destino"
            );

            return validIds.map((id, idIndex) => {
              // 📌 Buscar el tramo correcto en `legs` comparando coordenadas `lat` y `lon`
              const matchingLegIndex = apiResponse.legs.findIndex(
                (leg, legIndex) => {
                  const nextWp = apiResponse.waypoints[legIndex + 1];
                  return (
                    nextWp && nextWp.lat === wp.lat && nextWp.lon === wp.lon
                  );
                }
              );

              const matchingLeg =
                matchingLegIndex !== -1
                  ? apiResponse.legs[matchingLegIndex]
                  : null;

              return {
                id: id, // 🔹 Cada ID se convierte en un waypoint único
                routeName: route || "Ruta_Desconocida",
                index: matchingLegIndex, // 📌 Guardamos el índice del tramo
                ids: [id], // 🔹 Solo contiene su ID individual
                name: wp.name || "",
                total: wp.total || 1,
                waypointIndex: apiResponse.waypoints.indexOf(wp), // 📌 Índice real dentro de waypoints
                location: {
                  lat: wp.lat || 0,
                  lon: wp.lon || 0,
                },

                routingData: {
                  // 🔹 Solo el primer ID tiene `routingData`
                  distanceMeters: matchingLeg
                    ? parseFloat(matchingLeg.distancia) * 1000
                    : 0,
                  estimatedTimeSeconds: matchingLeg
                    ? parseFloat(matchingLeg.tiempo) * 60
                    : 0,
                  formattedDistance: matchingLeg
                    ? matchingLeg.distancia
                    : "0.00 km",
                  formattedTime: matchingLeg ? matchingLeg.tiempo : "0.00 min",
                  segment: matchingLeg
                    ? matchingLeg.tramo
                    : "Tramo Desconocido",
                },

                extraPoint: idIndex !== 0, // 🔹 Solo el primer punto tiene valores reales, los demás son extras
              };
            });
          });
        }

        let routeID = route.route;

        let updateDoc = buildOptimizedRouteData(optimizedRoute);
        let updatedWaypoints = buildUpdatedWaypoints(optimizedRoute, routeID);

        async function updateFullRoute(
          routeID,
          newRouteData,
          updatedWaypoints
        ) {
          await updateRouteInFirebaseDoc(routeID, newRouteData); // Actualiza la ruta principal
          await updateRouteWaypointsRoutingData(routeID, updatedWaypoints); // Actualiza solo los waypoints
        }

        // Llamar la función de actualización completa
        updateFullRoute(routeID, updateDoc, updatedWaypoints);
      }
    }

    // console.log(allOptimizedRoutes);
    // console.log(allOptimizedWaypoints);
    // console.log(allMetrics);

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Configurar Recalculo de Ruta</DialogTitle>
      <DialogContent>
        {/* Opciones de Origen */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend">Seleccionar Origen</FormLabel>
          <RadioGroup
            value={origenOption}
            onChange={(e) => setOrigenOption(e.target.value)}
          >
            <FormControlLabel
              value="predefinido"
              control={<Radio />}
              label="Predefinido (Tomar de las órdenes)"
            />
            <FormControlLabel
              value="manual"
              control={<Radio />}
              label="Establecer Origen (Ingresar dirección)"
            />
          </RadioGroup>

          {origenOption === "manual" && (
            <SearchOrigin
              setAddressOrigin={setAddressOrigin}
              setOrigenGeoCode={setOrigenGeoCode}
            />
          )}
        </FormControl>

        {/* Opciones de Destino */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend">Seleccionar Destino</FormLabel>
          <RadioGroup
            value={destinoOption}
            onChange={(e) => setDestinoOption(e.target.value)}
          >
            <FormControlLabel
              value="predefinido"
              control={<Radio />}
              label="Retornar a origen"
            />
            <FormControlLabel
              value="lejano"
              control={<Radio />}
              label="Tomar destino más lejano"
            />
            <FormControlLabel
              value="manual"
              control={<Radio />}
              label="Establecer Destino (Ingresar dirección)"
            />
          </RadioGroup>

          {destinoOption === "manual" && (
            <SearchDestiny
              setAddressDestino={setAddressDestino}
              setDestinoGeoCode={setDestinoGeoCode}
            />
          )}
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirmar y Recalcular
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * Componente de Autocompletado para el Origen
 */
function SearchOrigin({ setAddressOrigin, setOrigenGeoCode }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // Ajustar con la ubicación central y radio si es necesario
      location: { lat: () => -33.4489, lng: () => -70.6693 }, // Ej: Santiago, CL
      radius: 100 * 1000,
      componentRestrictions: {
        country: "CL", // Chile
      },
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      setAddressOrigin(address);

      const { lat, lng } = await getLatLng(results[0]);
      setOrigenGeoCode({ lon: lat, lat: lng });
    } catch (error) {
      console.error("Error geocoding origen:", error);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Buscar Origen..."
        />
        {status === "OK" && data.length > 0 && (
          <ComboboxPopover
            style={{
              position: "absolute",
              zIndex: 3000, // Importante para sobrepasar el Dialog de MUI
              backgroundColor: "#fff",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
              marginTop: "4px",
              width: "auto",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            <ComboboxList>
              {data.map((suggestion) => (
                <ComboboxOption
                  key={suggestion.place_id}
                  value={suggestion.description}
                />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </div>
  );
}

/**
 * Componente de Autocompletado para el Destino
 */

function SearchDestiny({ setAddressDestino, setDestinoGeoCode }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // Según documentación de Google, "location" y "radius"
      // están en vía de deprecación (mayo 2023).
      // Más adelante podrías usar "locationBias".
      location: { lat: () => -33.4489, lng: () => -70.6693 },
      radius: 100 * 1000,
      componentRestrictions: {
        country: "CL",
      },
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      setAddressDestino(address);

      const { lat, lng } = await getLatLng(results[0]);
      setDestinoGeoCode({ lon: lat, lat: lng });
    } catch (error) {
      console.error("Error geocoding destino:", error);
    }
  };

  // Puedes usar este console.log para ver las sugerencias en la consola

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Buscar Destino..."
        />
        {/* Muestra el popover solo si hay resultados */}
        {status === "OK" && data.length > 0 && (
          <ComboboxPopover
            style={{
              position: "absolute",
              zIndex: 3000, // Importante para sobrepasar el Dialog de MUI
              backgroundColor: "#fff",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
              marginTop: "4px",
              width: "auto",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            <ComboboxList>
              {data.map((suggestion) => (
                <ComboboxOption
                  key={suggestion.place_id}
                  value={suggestion.description}
                />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </div>
  );
}

export default RecalculateRouteDialog;
