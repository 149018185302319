export const getViewRouteDataResult = async (
  routesData,
  ridersDataByCompany,
  viewMergedOrders
) => {
  if (!routesData || routesData.length === 0) {
    // console.warn("⚠️ No hay datos para exportar.");
    return {};
  }

  // Mapeo de conductores
  const driversMap = Object.fromEntries(
    ridersDataByCompany.map((rider) => [rider.id, rider.name])
  );

  // Mapeo de órdenes
  const ordersMap = Object.fromEntries(
    viewMergedOrders.map((order) => [
      order.id,
      {
        intState: order.intState,
        toAddress: order.toAddress,
        region: order.region,
        userNameReceptor: order.userNameReceptor,
        userDniReceptor: order.userDniReceptor,
      },
    ])
  );

  // Función para calcular diferencia en minutos entre timestamps
  const getMinutesDiff = (startTimestamp, endTimestamp) => {
    if (!startTimestamp || !endTimestamp) return "N/A";

    const toDate = (ts) => {
      if (
        ts &&
        typeof ts === "object" &&
        "seconds" in ts &&
        "nanoseconds" in ts
      ) {
        return new Date(ts.seconds * 1000 + ts.nanoseconds / 1000000);
      }
      return null;
    };

    const startDate = toDate(startTimestamp);
    const endDate = toDate(endTimestamp);

    if (!startDate || !endDate) return "N/A";

    const diffMs = endDate - startDate;
    if (isNaN(diffMs) || diffMs < 0) return "N/A";

    return Math.floor(diffMs / 1000 / 60);
  };

  const results = {};

  routesData.forEach((route) => {
    route.waypoints.forEach((waypoint) => {
      const validIds = waypoint.ids.filter(
        (orderId) =>
          orderId.toLowerCase() !== "origen" &&
          orderId.toLowerCase() !== "destino"
      );

      validIds.forEach((orderId) => {
        const orderData = ordersMap[orderId] || {};
        const td = waypoint.trackingData || {}; // Asegurar que trackingData exista

        // Cálculos de SLA y Tiempos
        const tiempoEsperaOrigen = getMinutesDiff(
          td.pickedUpTime ? td.pickedUpTime : td.creationTime,
          td.onTheWayToClientTime
        );
        const tiempoTransito = getMinutesDiff(
          td.pickedUpTime ? td.pickedUpTime : td.creationTime,
          td.visitedTime
        );

        const tiempoDeEntrega = getMinutesDiff(td.visitedTime, td.deliveryTime);
        const slaOperacion = getMinutesDiff(
          td.onTheWayToClientTime ? td.onTheWayToClientTime : td.creationTime,
          td.deliveryTime
        );
        const slaBulto = getMinutesDiff(td.creationTime, td.deliveryTime);

        if (!results[orderId]) {
          results[orderId] = {
            "Ruta ID": route.id,
            "Nombre Conductor": driversMap[waypoint.driverID] || "Desconocido",
            Distancia: waypoint.routingData?.formattedDistance || "N/A",
            "Tiempo Estimado": waypoint.routingData?.formattedTime || "N/A",
            "Dirección de Entrega": orderData.toAddress || "N/A",
            Región: orderData.region || "N/A",
            "Nombre Receptor": orderData.userNameReceptor || "N/A",
            "DNI Receptor": orderData.userDniReceptor || "N/A",
            "Total de Paquetes": 0,

            // Agregando las métricas SLA
            "Tiempo Espera Origen (Min)": tiempoEsperaOrigen,
            "Tiempo Espera Destino (Min)": tiempoDeEntrega,
            "Tiempo Tránsito (Min)": tiempoTransito,
            "SLA Operación (Min)": slaOperacion,
            "SLA Bulto (Min)": slaBulto,
          };
        }
        results[orderId]["Total de Paquetes"] += 1;
      });
    });
  });

  return results;
};
