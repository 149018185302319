import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../../firebase"; // Importa tu instancia de Firestore
import { colorPalette } from "../../../../utils/objects/stylesStates";

/**
 * @param {string} routeID - ID de la ruta en Firestore
 * @param {Object} newRouteData - Datos a actualizar en la ruta
 */
export async function updateRouteInFirebaseDoc(routeID, newRouteData) {
  function getRandomColor() {
    return colorPalette[Math.floor(Math.random() * colorPalette.length)];
  }

  try {
    // 1️⃣ Referencia al documento en Firestore
    const routeRef = doc(db, "xpress_deliveryPlans", routeID);

    // 2️⃣ Obtener el documento actual
    const routeSnap = await getDoc(routeRef);
    if (!routeSnap.exists()) {
      console.error("⚠️ La ruta no existe en Firebase:", routeID);
      return false;
    }

    const existingData = routeSnap.data();

    // 3️⃣ Construir la actualización (solo los campos permitidos)
    const updateFields = {
      totalDistance: newRouteData.totalDistance || existingData.totalDistance,
      totalTime: newRouteData.totalTime || existingData.totalTime,
      geometry: newRouteData.geometry,
      routeColor: getRandomColor(),
      subRoutes: newRouteData.subRoutes || existingData.subRoutes,
      updateAt: serverTimestamp(), // Registrar actualización
      geometry: newRouteData.geometry,
    };

    // 4️⃣ Ejecutar la actualización
    await updateDoc(routeRef, updateFields);

    return true;
  } catch (error) {
    console.error("❌ Error al actualizar la ruta:", error);
    return false;
  }
}
