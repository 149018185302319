import * as XLSX from "xlsx";
import {
  globalsStatesCompanys,
  translateStatesBicci,
  translateStatesfalabbela,
} from "../../utils/objects/translateStates";

/**
 * Convierte un timestamp de Firestore a Date y devuelve la diferencia en minutos.
 * Si algo sale mal, devuelve "N/A" para evitar que se rompa la exportación.
 */
function getMinutesDiff(startTimestamp, endTimestamp) {
  if (!startTimestamp || !endTimestamp) {
    console.warn("⚠️ Uno de los timestamps es inválido:", {
      startTimestamp,
      endTimestamp,
    });
    return "N/A";
  }

  // 🔹 Función interna para convertir un timestamp Firestore a Date
  const toDate = (ts) => {
    if (ts && typeof ts === "object") {
      if ("seconds" in ts && "nanoseconds" in ts) {
        return new Date(ts.seconds * 1000 + ts.nanoseconds / 1000000);
      } else if ("seconds" in ts) {
        return new Date(ts.seconds * 1000); // Caso en el que solo tiene `seconds`
      } else {
        console.warn("⚠️ Formato de timestamp inesperado:", ts);
        return null;
      }
    }
    return null;
  };

  const startDate = toDate(startTimestamp);
  const endDate = toDate(endTimestamp);

  if (!startDate || !endDate) {
    console.warn("⚠️ No se pudieron convertir los timestamps:", {
      startDate,
      endDate,
    });
    return "N/A";
  }

  // 🔹 Diferencia en milisegundos
  const diffMs = endDate.getTime() - startDate.getTime();

  if (isNaN(diffMs) || diffMs < 0) {
    console.warn("⚠️ Diferencia de tiempo inválida:", {
      startDate,
      endDate,
      diffMs,
    });
    return "N/A"; // Evitar valores negativos o NaN
  }

  // 🔹 Convertir a minutos y retornar
  return Math.floor(diffMs / 1000 / 60);
}

const exportRoutesToExcel = (
  routesData,
  ridersDataByCompany,
  viewMergedOrders
) => {
  if (!routesData || routesData.length === 0) {
    console.warn("⚠️ No hay datos para exportar.");
    return;
  }

  // 📌 Crear la estructura de los datos para la hoja de cálculo
  const exportData = [];

  // Mapear los conductores
  const driversMap = Object.fromEntries(
    ridersDataByCompany.map((rider) => [rider.id, rider.name])
  );
  const driversMapVehicleID = Object.fromEntries(
    ridersDataByCompany.map((rider) => [rider.id, rider.vehicleID])
  );
  const driversMapDni = Object.fromEntries(
    ridersDataByCompany.map((rider) => [rider.id, rider.dni])
  );
  const driversMapBicciNumber = Object.fromEntries(
    ridersDataByCompany.map((rider) => [rider.id, rider.bicciNumber])
  );

  // Mapear datos de las órdenes
  const ordersMap = Object.fromEntries(
    viewMergedOrders.map((order) => [
      order.id,
      {
        intState: order.intState,
        createdAt: order.createdAt,
        updatedAt: order.updatedAt,
        toAddress: order.toAddress,
        region: order.region,
        userNameReceptor: order.userNameReceptor,
        userDniReceptor: order.userDniReceptor,
        pics: order.pics,
        internalReference: order.internalReference,
      },
    ])
  );

  // Función para convertir timestamp de Firebase a fecha/hora legible
  const firebaseTimestampToDateTimeString = (timestamp) => {
    if (
      timestamp &&
      typeof timestamp === "object" &&
      "seconds" in timestamp &&
      "nanoseconds" in timestamp
    ) {
      const date = new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      );
      return date.toLocaleString(); // Formato legible con fecha y hora
    }
    return "Desconocida";
  };

  routesData.forEach((route) => {
    route.waypoints.forEach((waypoint) => {
      // Filtrar IDs para eliminar origen y destino
      const validIds = waypoint.ids.filter(
        (orderId) =>
          orderId.toLowerCase() !== "origen" &&
          orderId.toLowerCase() !== "destino"
      );

      validIds.forEach((orderId) => {
        const orderData = ordersMap[orderId] || {};

        // Asegurarnos de que waypoint.trackingData exista
        const td = waypoint.trackingData || {};

        // Cálculos de SLA y Tiempos
        const tiempoEsperaOrigen = getMinutesDiff(
          td.pickedUpTime ? td.pickedUpTime : td.creationTime,
          td.onTheWayToClientTime
        );
        const tiempoDeEntrega = getMinutesDiff(td.visitedTime, td.deliveryTime);
        const tiempoTransito = getMinutesDiff(
          td.pickedUpTime ? td.pickedUpTime : td.creationTime,
          td.visitedTime
        );
        const slaOperacion = getMinutesDiff(
          td.onTheWayToClientTime ? td.onTheWayToClientTime : td.creationTime,
          td.deliveryTime
        );
        const slaBulto = getMinutesDiff(td.creationTime, td.deliveryTime);

        exportData.push({
          "Ruta ID": route.id,
          "Empresa ID": route.companyID,
          "Fecha Inicio": route.dayStart,
          "Hora Inicio": route.initHour,
          "Hora Fin": route.endHour,
          "Color Ruta": route.routeColor,
          "Distancia Total": route.totalDistance,
          "Tiempo Total": route.totalTime,
          "Cantidad Paquetes": validIds.length,
          "Waypoint ID": waypoint.id,
          "Estado Actual":
            translateStatesBicci[waypoint.currentStatus] || "N/A",
          "Estado Integración":
            translateStatesfalabbela[orderData.intState] || "N/A",
          "Orden ID": orderId,
          "Internal ID": orderData.internalReference,
          "Nombre Conductor": driversMap[waypoint.driverID] || "Desconocido",
          "Ubicación Latitud": waypoint.location?.lat || "N/A",
          "Ubicación Longitud": waypoint.location?.lon || "N/A",
          "Distancia (m)": waypoint.routingData?.distanceMeters || 0,
          "Tiempo Estimado (s)":
            waypoint.routingData?.estimatedTimeSeconds || 0,
          "Distancia Formateada":
            waypoint.routingData?.formattedDistance || "N/A",
          "Tiempo Formateado": waypoint.routingData?.formattedTime || "N/A",
          Parada: waypoint.routingData?.segment || "N/A",

          // Fechas y horas relevantes
          "Fecha Creación Orden": firebaseTimestampToDateTimeString(
            orderData.createdAt
          ),
          "Fecha Entrega (tracking)": td.deliveryDateFormated || "N/A",
          "Hora Creación Orden": firebaseTimestampToDateTimeString(
            orderData.createdAt
          ),
          "Hora Actualización": firebaseTimestampToDateTimeString(
            orderData.updatedAt
          ),
          "Dirección de Entrega": orderData.toAddress || "N/A",
          Región: orderData.region || "N/A",
          "Nombre Receptor": orderData.userNameReceptor || "N/A",
          "DNI Receptor": orderData.userDniReceptor || "N/A",
          Fotos: Array.isArray(orderData.pics)
            ? orderData.pics.join(", ")
            : "N/A",
          "ID Vehículo": driversMapVehicleID[waypoint.driverID] || "N/A",
          "DNI Conductor": driversMapDni[waypoint.driverID] || "N/A",
          "COD Conductor": driversMapBicciNumber[waypoint.driverID] || "N/A",

          // Nuevas columnas de SLA/Tiempos
          "Tiempo Espera Origen (Min)": tiempoEsperaOrigen,
          "Tiempo Espera Destino (Min)": tiempoDeEntrega,
          "Tiempo Tránsito (Min)": tiempoTransito,
          "SLA Operación (Min)": slaOperacion,
          "SLA Bulto (Min)": slaBulto,
        });
      });
    });
  });

  // 📌 Crear un libro de Excel
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(exportData);
  XLSX.utils.book_append_sheet(workbook, worksheet, "DATA");

  // 📌 Generar el archivo Excel y descargarlo
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const fileName = `Reporte_Rutas_${new Date()
    .toISOString()
    .slice(0, 10)}.xlsx`;
  const link = document.createElement("a");
  link.href = URL.createObjectURL(data);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default exportRoutesToExcel;
