import React from "react";
import { registrarComentarios } from "../../../redux/accionesCRM";
import { useDispatch, useSelector } from "react-redux";
import ContainerViewComponent from "../../ContainerViewComponent/ContainerViewComponent";
import {
  Box,
  Grid,
  TextField,
  Button,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

const FrmSoporte = () => {
  const dispatch = useDispatch();
  const resperror = useSelector((store) => store.usuario.error);
  const usuarioActual = useSelector((store) => store.usuario);
  const [success, setSuccess] = React.useState(null);
  const mensjaEnviado = useSelector((store) => store.mensajes.mjsstatus);
  const [username, setName] = React.useState();
  const [companyName, setCompanyName] = React.useState("");
  const [email, setEmail] = React.useState(usuarioActual.user.email);
  const [phone, setPhone] = React.useState("+56");
  const [userPreferenCont, setUserPreferenCont] = React.useState("email");
  const [textComents, setTextComents] = React.useState("");
  const [error, setError] = React.useState(null);
  const [userType, setUserType] = React.useState("empresa");
  const [checked, setChecked] = React.useState(false);
  const registrarComentario = (e) => {
    e.preventDefault();

    if (!username.trim()) {
      setError("El campo nombre es obligatorio");
      return;
    }
    if (!companyName.trim()) {
      setError("El campo nombre de **Empresa** es obligatorio");
      return;
    }
    if (!email.trim()) {
      setError("Ingrese Email");
      return;
    }
    if (!phone.trim()) {
      setError("Ingrese un numero telefonico");
      return;
    }
    if (!textComents.trim()) {
      setError("Ingrese Mensaje");
      return;
    }
    if (textComents.length < 8) {
      setError("Mensaje es muy corto");
      return;
    }
    if (!userPreferenCont.trim()) {
      setError("Seleccione un medio de contacto");
      return;
    }
    if (!userType.trim()) {
      setError("Seleccione un tipo de usuario");
      return;
    }
    if (!resperror === null) {
      setError("Error de acceso");
      return;
    }
    setError(null);
    cometar();
  };

  const handleChange = () => {
    setChecked(!checked);
    //   console.log (checked)
  };
  if (checked === true) {
    //    console.log ('Acepta terminos y condiciones')
  }

  const cometar = React.useCallback(async () => {
    //console.log('Esta Comentando')
    try {
      const userData = {
        userName: username,
        userCompany: companyName,
        userType: userType,
        email: email,
        phone: phone,
        mensaje: textComents,
        userPreferenCont: userPreferenCont,
        checked: checked,
      };
      dispatch(registrarComentarios(userData));
      setSuccess("Gracias. Mensaje enviado...");
    } catch (error) {
      //console.log(error)
    }
  });

  return (
    <ContainerViewComponent className="formcontent" id="">
      <hr></hr>

      <Box component="form" onSubmit={registrarComentario} sx={{ p: 3 }}>
        {mensjaEnviado && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {resperror && <Alert severity="error">{resperror}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{email}</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre de contacto"
              variant="outlined"
              value={username}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Teléfono"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Compañía"
              variant="outlined"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Preferencia de contacto</InputLabel>
              <Select
                onChange={(e) => setUserPreferenCont(e.target.value)}
                defaultValue=""
              >
                <MenuItem value="telefono">Teléfono</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="presencial">Presencial</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Tipo de usuario</InputLabel>
              <Select
                onChange={(e) => setUserType(e.target.value)}
                defaultValue=""
              >
                <MenuItem value="empresa">Empresa</MenuItem>
                <MenuItem value="repartidor">Repartidor</MenuItem>
                <MenuItem value="integracion">Integración</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Mensaje"
              placeholder="¿Cómo podemos ayudarte?"
              variant="outlined"
              multiline
              rows={3}
              value={textComents}
              onChange={(e) => setTextComents(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <hr></hr>
    </ContainerViewComponent>
  );
};

export default FrmSoporte;
