import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Importa los iconos que necesites
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { Box, Button } from "@mui/material";
import { StyledCard, StyledTextField, StyledSelect } from "./FromStyles";
import {
  FormControl,
  Divider,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { OutlinedInput } from "@mui/material";

const FromShippingContainer = ({ onChange, order }) => {
  const [selection, setSelectedOption] = useState("S");
  const [formState, setFormState] = useState({
    ancho: "",
    largo: "",
    peso: "",
    alto: "",
    volumen: "",
    fragil: "",
  });

  // DESARROLLO IMPLEMENTA   FUNCIONES DE BOTONES LIMPIAR CREAR GUARDAR

  useEffect(() => {
    if (order) {
      setFormState(order);
      callPaquet();
    }
  }, [order]);

  useEffect(() => {
    let data = { transportOrderSize: selection };
    onChange(data);
  }, [selection]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const callPaquet = () => {
    const products = order.products;
    let totalAncho = 0;
    let totalLargo = 0;
    let totalPeso = 0;
    let totalAlto = 0;
    let totalVolumen = 0;
    let totalFragil = 0;

    if (products) {
      // Iterar sobre cada producto y sumar los valores
      for (let i = 0; i < products.length; i++) {
        totalAncho += parseFloat(products[i].productLength || 0);
        totalLargo += parseFloat(products[i].productHeight || 0);
        totalAlto += parseFloat(products[i].productWidth || 0);
        totalPeso += parseFloat(products[i].productWeight || 0);
        totalVolumen += parseFloat(products[i].productVolume || 0);
      }
    }

    // Actualizar el estado formState con los totales
    setFormState({
      ancho: totalAncho.toString(),
      largo: totalLargo.toString(),
      peso: totalPeso.toString(),
      alto: totalAlto.toString(),
      volumen: totalVolumen.toString(),
    });
  };

  const SendSmallPackage = (e) => {
    e.preventDefault();
    setFormState({
      ancho: "0.4",
      largo: "20",
      alto: "16",
      volumen: "0.015",
    });
  };
  const SendSmallBox = (e) => {
    e.preventDefault();
    setFormState({
      ancho: "40",
      largo: "40",
      alto: "20",
      volumen: "0.035",
    });
  };
  const SendMediunBox = (e) => {
    e.preventDefault();
    setFormState({
      ancho: "30",
      largo: "30",
      alto: "40",
      volumen: "0.045",
    });
  };
  const SendBigBox = (e) => {
    e.preventDefault();
    setFormState({
      ancho: "40",
      largo: "40",
      alto: "40",
      volumen: "0.055",
    });
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardHeader
        title="Bulto o Empaque"
        sx={{
          color: "#fff", // Texto blanco
          borderRadius: "10px 10px 0 0",
          padding: "16px",
        }}
      />
      <CardContent sx={{ padding: "1px" }}>
        <Typography sx={{ color: "#fff" }}>
          {" "}
          {"Seleccione una medida"}
        </Typography>
        <FormControl fullWidth margin="dense">
          <Select
            sx={{
              backgroundColor: "#fff",
              ".MuiOutlinedInput-input": {
                borderRadius: "10px",
                padding: "10px",
                fontSize: "1rem",
                color: "#333",
              },
            }}
            labelId="Seleccione una medida"
            id="medidas"
            value={selection}
            onChange={(e) => handleChange(e)}
            label=""
            input={<OutlinedInput notched />}
          >
            <MenuItem key="XSS" value="XSS">
              {"XSS"}
            </MenuItem>
            <MenuItem key="XS" value="XS">
              {"XS"}
            </MenuItem>
            <MenuItem key="S" value="S">
              {"S"}
            </MenuItem>
            <MenuItem key="M" value="M">
              {"M"}
            </MenuItem>
            <MenuItem key="L" value="L">
              {"L"}
            </MenuItem>
            <MenuItem key="XL" value="XL">
              {"XL"}
            </MenuItem>
            <MenuItem key="XXL" value="XXL">
              {"XXL"}
            </MenuItem>
          </Select>
        </FormControl>
        <Divider></Divider>
        <Typography sx={{ color: "#fff" }}> {"Medidas de empaque"}</Typography>
        <Box sx={{ maxWidth: "100%" }}>
          <Button
            className="buttonpackage"
            onClick={SendSmallPackage}
            sx={{ maxWidth: "25%", padding: "3px" }}
          >
            <LocalPostOfficeIcon
              sx={{ fontSize: 40 }}
              color="success"
            ></LocalPostOfficeIcon>
          </Button>
          <Button
            className="buttonpackage"
            onClick={SendSmallBox}
            sx={{ maxWidth: "25%", padding: "3px" }}
          >
            <ViewInArIcon sx={{ fontSize: 50 }} color="success"></ViewInArIcon>
          </Button>
          <Button
            className="buttonpackage"
            onClick={SendMediunBox}
            sx={{ maxWidth: "25%", padding: "3px" }}
          >
            <ViewInArIcon sx={{ fontSize: 60 }} color="success"></ViewInArIcon>
          </Button>
          <Button
            className="buttonpackage"
            onClick={SendBigBox}
            sx={{ maxWidth: "25%", padding: "3px" }}
          >
            <ViewInArIcon sx={{ fontSize: 70 }} color="success"></ViewInArIcon>
          </Button>
        </Box>

        {/* <StyledTextField
          name="ancho"
          value={formState.city}
          onChange={handleChange}
          placeholder="acbho"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />
        <StyledTextField
          name="largo"
          value={formState.city}
          onChange={handleChange}
          placeholder="Largo"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />
        <StyledTextField
          name="peso"
          value={formState.city}
          onChange={handleChange}
          placeholder="Peso"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />
        <StyledTextField
          name="alto"
          value={formState.city}
          onChange={handleChange}
          placeholder="alto"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />
        <StyledTextField
          name="volumen"
          value={formState.city}
          onChange={handleChange}
          placeholder="volumen"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        />
        <StyledTextField
          name="fragil"
          value={formState.city}
          onChange={handleChange}
          placeholder="fragil"
          fullWidth
          margin="normal"
          sx={{ marginTop: "3px", marginBottom: "3px" }}
        /> */}
      </CardContent>
      <Divider></Divider>
      <CardContent
        sx={{
          padding: "1px",
          backgroundColor: "#fff",
          padding: "15px",
          borderRadius: "15px",
        }}
      >
        <Typography>Ancho: {formState.ancho} </Typography>
        <Typography>Largo: {formState.largo}</Typography>
        <Typography>Peso: {formState.peso}</Typography>
        <Typography>Alto: {formState.alto}</Typography>
        <Typography>Volumen: {formState.volumen}</Typography>
      </CardContent>
    </StyledCard>
  );
};

export default FromShippingContainer;
