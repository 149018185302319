import React from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid2,
  Typography,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const Postulaciones = () => {
  const slides = [
    {
      image: "/static/images/asset/imagen_rider.png",
      title: "Beneficios para conductores y repartidores",
      content: (
        <List>
          <ListItem>
            <ListItemIcon>
              <AccessTimeIcon color="success" />
            </ListItemIcon>
            <ListItemText primary="Horarios flexibles" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AssignmentTurnedInIcon color="success" />
            </ListItemIcon>
            <ListItemText primary="Sin experiencia previa: Te brindamos capacitación para que comiences con éxito." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TrendingUpIcon color="success" />
            </ListItemIcon>
            <ListItemText primary="Oportunidades de crecimiento: Forma parte de un mercado en constante expansión." />
          </ListItem>
        </List>
      ),
    },
    {
      image: "/static/images/asset/requisitos.png",
      title: "Requisitos básicos para unirte",
      content: (
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon color="info" />
            </ListItemIcon>
            <ListItemText primary="Ser mayor de edad." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DirectionsBikeIcon color="info" />
            </ListItemIcon>
            <ListItemText primary="Licencia de conducir (en caso de moto o auto)." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AssignmentTurnedInIcon color="info" />
            </ListItemIcon>
            <ListItemText primary="Documentos personales en regla." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneAndroidIcon color="info" />
            </ListItemIcon>
            <ListItemText primary="Teléfono Android con conexión a internet." />
          </ListItem>
        </List>
      ),
    },
    {
      title: "📲 ¿Cómo empezar?",
      content: (
        <List>
          <ListItem>
            <ListItemText primary="1️⃣ Regístrate." />
          </ListItem>
          <ListItem>
            <ListItemText primary="1️⃣ Te llamaremos para entrevista, y solicitaremos tu documentación." />
          </ListItem>
          <ListItem>
            <ListItemText primary="2️⃣ Espera la validación y activa tu cuenta." />
          </ListItem>
        </List>
      ),
      // public\static\images\imagen_rider.png
    },
  ];

  return (
    <Box sx={{ backgroundColor: "#f5f5f5" }}>
      {/* Sección superior con título */}

      <Grid2>
        <Box
          sx={{
            backgroundColor: "#741aaf",
            display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
            flexDirection: "row",
            height: "70vh",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "100%", lg: "50%" },
              display: "flex",
              flexDirection: "row",
              backgroundColor: "transparent",
              p: 1,
            }}
          >
            <Box
              sx={{
                width: { xs: "none", sm: "none", md: "none", lg: "50%" },
                backgroundColor: "transparent",
              }}
            ></Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "100%", lg: "50%" },
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                  p: { xs: 1, sm: 1, md: 3, lg: 3 },
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="primary"
                  sx={{
                    mt: 3,
                    fontSize: {
                      xs: "2em",
                      sm: "2em",
                      md: "2.0em",
                      lg: "2.5em",
                    },
                    color: "#fff",
                    fontWeight: 400,
                  }}
                >
                  ¿TE QUIERES UNIR A NUESTRO EQUIPO?
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="primary"
                  sx={{
                    mt: 3,
                    fontSize: {
                      xs: "1.5em",
                      sm: "1.5em",
                      md: "1.5em",
                      lg: "1.5em",
                    },
                    color: "#fff",
                    fontWeight: 200,
                  }}
                >
                  Haz entregas y genera ingresos a tu manera. Los repartidores
                  de última milla conectan comercios con clientes, asegurando
                  que cada pedido llegue de manera rápida y eficiente.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "50%" } }}
          >
            <img
              src="/static/images/asset/004_1.png"
              alt="Bicci Rider"
              style={{ width: "100%", maxWidth: 600, height: "auto" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
            p: { xs: 2, sm: 2, md: 2, lg: 2 },
            pt: { xs: 12, sm: 10, md: 2, lg: 2 },
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#741aaf",
              width: "#80%",
              padding: 1,
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="body1"
              fontWeight="bold"
              color="primary"
              sx={{ mt: 4, fontSize: "2em", color: "#f8f8f8" }}
            >
              🎯 Toma el control de tu tiempo y tu economía. ¡Únete ahora y
              empieza a entregar con Bicci!
            </Typography>
          </Box>
        </Box>
      </Grid2>

      <Box sx={{ mb: 4, pb: 4 }}>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Grid2 container spacing={4} justifyContent="center">
            {/* Card - Last Mille */}
            <Grid2 item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  textAlign: "center",
                  p: 2,
                  boxShadow: 3,
                  maxWidth: "350px",
                }}
              >
                <CardHeader
                  title="RIDER"
                  subheader="LAST MILLE"
                  titleTypographyProps={{
                    fontWeight: "bold",
                    color: "primary",
                  }}
                  subheaderTypographyProps={{ fontSize: 18 }}
                />
                <Divider />
                <CardContent>
                  <Typography variant="body1" color="textSecondary">
                    Lleva productos desde supermercados y tiendas al cliente
                    final
                  </Typography>
                  <img
                    src="/static/images/asset/entrega-01.svg"
                    alt="Last Mille"
                    style={{ width: 120, marginTop: 10 }}
                  />
                </CardContent>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <Button
                    component={NavLink}
                    to="/flota/last-mille"
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      px: 3,
                      borderRadius: 2,
                    }}
                  >
                    POSTÚLATE
                  </Button>
                </Box>
              </Card>
            </Grid2>

            {/* Card - Retail */}
            <Grid2 item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  textAlign: "center",
                  p: 2,
                  boxShadow: 3,
                  maxWidth: "350px",
                }}
              >
                <CardHeader
                  title="RIDER"
                  subheader="RETAIL"
                  titleTypographyProps={{
                    fontWeight: "bold",
                    color: "primary",
                  }}
                  subheaderTypographyProps={{ fontSize: 18 }}
                />
                <Divider />
                <CardContent>
                  <Typography variant="body1" color="textSecondary">
                    Lleva paquetes desde bodegas o tiendas retail al cliente
                    final
                  </Typography>
                  <img
                    src="/static/images/asset/entrega-02.svg"
                    alt="Retail"
                    style={{ width: 120, marginTop: 10 }}
                  />
                </CardContent>
                <Divider />
                <Box sx={{ p: 2 }}>
                  <Button
                    component={NavLink}
                    to="/flota/retail"
                    variant="contained"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      px: 3,
                      borderRadius: 2,
                    }}
                  >
                    POSTÚLATE
                  </Button>
                </Box>
              </Card>
            </Grid2>
          </Grid2>
        </Container>
      </Box>

      <Grid2>
        <Container maxWidth="lg" sx={{ pb: 4 }}>
          {slides.map((slide, index) => (
            <Box
              key={index}
              sx={{
                py: 8,
                px: 4,
                textAlign: "center",
                bgcolor: index % 2 === 0 ? "#741aaf" : "white",
                mb: 1,
                boxShadow: "-9px 2px 29px -11px rgba(0,0,0,0.75)",
                borderRadius: "8px",
              }}
            >
              <Card
                elevation={3}
                sx={{
                  maxWidth: "900px",
                  margin: "auto",
                  p: 4,
                  textAlign: "center",
                  bgcolor: "white",
                }}
              >
                <CardContent>
                  <Box sx={{ display: "flex" }}>
                    {slide.image ? (
                      <>
                        <img
                          src={slide.image}
                          alt="Bicci Rider"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </>
                    ) : null}

                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      color="primary"
                      sx={{
                        fontSize: {
                          xs: "2em",
                          sm: "2em",
                          md: "2.0em",
                          lg: "2.5em",
                        },
                      }}
                    >
                      {slide.title}
                    </Typography>
                  </Box>
                  {slide.subtitle && (
                    <Typography variant="h5" color="textSecondary" gutterBottom>
                      {slide.subtitle}
                    </Typography>
                  )}
                  <Typography variant="h6" color="textPrimary">
                    {slide.content}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Container>
      </Grid2>

      {/* Sección de Cards */}
    </Box>
  );
};

export default Postulaciones;
