import PropTypes from "prop-types";
import { useState } from "react";
import { DatePicker, DateCalendar, PickersDay } from "@mui/x-date-pickers";
import {
  Paper,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormHelperText,
} from "@mui/material";
import LocalizationProviderContext from "../../context/LocalizationProviderContext.jsx";
import { format } from "date-fns";

// 🎨 Componente personalizado para resaltar el rango de fechas
const CustomPickersDay = ({
  day,
  selectedStartDate,
  selectedEndDate,
  ...otherProps
}) => {
  const formattedDay = format(day, "yyyy-MM-dd");
  const formattedStart = selectedStartDate
    ? format(selectedStartDate, "yyyy-MM-dd")
    : null;
  const formattedEnd = selectedEndDate
    ? format(selectedEndDate, "yyyy-MM-dd")
    : null;

  const isInRange =
    selectedStartDate &&
    selectedEndDate &&
    formattedDay > formattedStart &&
    formattedDay < formattedEnd;

  return (
    <PickersDay
      {...otherProps} // ⚠️ Solo pasar props válidos aquí
      day={day}
      sx={{
        backgroundColor: isInRange ? "#e3f2fd" : "inherit",
        borderRadius: "50%",
        padding: "5px",
      }}
    />
  );
};

export default function CustomDateRangePicker({
  title = "Seleccione la fecha o rango de su búsqueda",
  variant,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  open,
  onClose,
  error,
  setNewDate,
  singleDateSelection = false,
}) {
  const isCalendarView = variant === "calendar";
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const handleDateSelection = (date) => {
    if (singleDateSelection) {
      setSelectedStartDate(date);
      setSelectedEndDate(date);
      onChangeStartDate(date);
      onChangeEndDate(date);
    } else {
      if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
        setSelectedStartDate(date);
        setSelectedEndDate(null);
        onChangeStartDate(date);
        onChangeEndDate(null);
      } else {
        if (date >= selectedStartDate) {
          setSelectedEndDate(date);
          onChangeEndDate(date);
        } else {
          setSelectedStartDate(date);
          setSelectedEndDate(null);
          onChangeStartDate(date);
          onChangeEndDate(null);
        }
      }
    }
  };

  return (
    <LocalizationProviderContext>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

        <DialogContent>
          <Stack
            justifyContent="center"
            spacing={2}
            direction="column"
            sx={{ pt: 1 }}
          >
            {isCalendarView ? (
              <>
                <Paper
                  variant="outlined"
                  sx={{ borderRadius: 2, borderColor: "divider" }}
                >
                  <DateCalendar
                    value={selectedStartDate}
                    onChange={handleDateSelection}
                    slots={{
                      day: (props) => (
                        <CustomPickersDay
                          {...props}
                          selectedStartDate={selectedStartDate}
                          selectedEndDate={selectedEndDate}
                        />
                      ),
                    }}
                  />
                </Paper>

                {!singleDateSelection && (
                  <Paper
                    variant="outlined"
                    sx={{ borderRadius: 2, borderColor: "divider" }}
                  >
                    <DateCalendar
                      value={selectedEndDate}
                      onChange={handleDateSelection}
                    />
                  </Paper>
                )}
              </>
            ) : (
              <>
                <DatePicker
                  label="Fecha inicio"
                  value={selectedStartDate}
                  onChange={handleDateSelection}
                />
                {!singleDateSelection && (
                  <DatePicker
                    label="Fecha fin"
                    value={selectedEndDate}
                    onChange={handleDateSelection}
                  />
                )}
              </>
            )}
          </Stack>

          {error && (
            <FormHelperText error sx={{ px: 2 }}>
              La fecha de finalización debe ser posterior a la de inicio.
            </FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            disabled={error}
            variant="contained"
            onClick={() => setNewDate(selectedStartDate, selectedEndDate)}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProviderContext>
  );
}

CustomDateRangePicker.propTypes = {
  error: PropTypes.bool,
  onChangeEndDate: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  setNewDate: PropTypes.func,
  singleDateSelection: PropTypes.bool,
};
