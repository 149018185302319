import React from "react";
import { Typography, Box } from "@mui/material";

const Introduction = () => {
  return (
    <div id="introduction">
      <Box p={3} sx={{ color: "#030303" }}>
        <Typography variant="h4" gutterBottom>
          Información
        </Typography>
        <Typography variant="body1" paragraph>
          Recibirás un correo con la KEY en el ambiente de desarrollo. Toda la
          documentación necesaria y los datos de prueba están incluidos en el
          documento PDF adjunto.
        </Typography>
        <Typography variant="body1" paragraph>
          El flujo básico consiste en la creación de una orden. Debes facilitar
          un endpoint documentado para enviar los estados homologados a su
          sistema. La especificación técnica y ejemplos se encuentran en la
          documentación.
        </Typography>
        <Typography variant="body1" paragraph>
          Disponemos de ejemplos fáciles de implementar. El resultado de este
          paso es que logres crear la orden. La key que recibas para el
          desarrollo no será la misma API key de producción.
        </Typography>
        <Typography variant="body1" paragraph>
          Para iniciar, tu empresa debe estar registrada y creada en nuestro
          sistema según los acuerdos comerciales previos. Esta acción nos
          permite generar una key para tu empresa, la cual utilizarás para
          generar tokens API y deberá ser incluida en algunas solicitudes según
          el API a consultar.
        </Typography>
      </Box>
    </div>
  );
};

export default Introduction;
