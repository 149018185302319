import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid2,
  Box,
  Divider,
  Chip,
  List,
  ListItem,
  Avatar,
  Tab,
  Tabs,
} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  obtnerCambioEnOrden,
  registrarCambioEnOrden,
} from "../../redux/accionsRegister";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, updateDoc } from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";
import { db } from "../../firebase";
import { translateStatesfalabbela } from "../../utils/objects/translateStates";
import CreateEmailClientForm from "./FormsEdithOrders/CreateEmailClientForm";
import CreateEmailSecondAttemptForm from "./FormsEdithOrders/CreateEmailSecondAttemptForm";
import CreateEmailThirdAttemptForm from "./FormsEdithOrders/CreateEmailThirdAttemptForm";
import CreateEmailConfirmationForm from "./FormsEdithOrders/CreateEmailConfirmationForm";
import CreateEmailAuthorizationForm from "./FormsEdithOrders/CreateEmailAuthorizationForm";
import AdjustGeoReferenceForm from "./FormsEdithOrders/AdjustGeoReferenceForm";
import AddNewAddressForm from "./FormsEdithOrders/AddNewAddressForm";
import TicketSupportForm from "./FormsEdithOrders/TicketSupportForm";

var data = "Sin contenido";
var dataContenido = "No se muestran datos";
var dataDriver = "No se muestran datos";
var actionsOrders = "no se muestran datos";
var logDeCambios = "no se muestran datos";

const TabSecondary = ({
  orderDetails,
  orderDetailsCliente,
  dataOrdesBicciExpress,
  usuarioStorage,
  bloqueaGoogle,
  setOrderDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storePayload = useSelector((store) => store.accionsRegisterLogs);
  const [foto, setFoto] = useState([]);
  const [date, setDate] = useState([]);
  const [comment, setComment] = useState("");
  const [picsTaken, setPicsTaken] = useState(false);
  const [confirmDireccion, setConfirmDireccion] = useState("");
  const [newLocation, setNewLocation] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  useEffect(() => {}, [foto, newLocation]);

  useEffect(() => {
    if (orderDetails.pics) {
      var arraysPics = [];
      for (let i = 0; i < orderDetails.pics.length; i++) {
        arraysPics.push(orderDetails.pics[i]);
      }
      setFoto(arraysPics);
    }
    if (
      orderDetails.deliveryPictures &&
      orderDetails.deliveryPictures !== "isRequire"
    ) {
      var arraysPics = [];

      arraysPics.push(orderDetails.deliveryPictures);

      setFoto(arraysPics);
      setPicsTaken(arraysPics.length > 0 ? true : false);
    }

    if (
      orderDetails &&
      orderDetails.date &&
      orderDetailsCliente &&
      orderDetailsCliente.updatedAt
    ) {
      setDate(
        format(
          orderDetailsCliente.updatedAt.seconds * 1000,
          "dd/MM/yy HH:mm:ss"
        ).toString()
      );
    }
    if (orderDetails.id) {
      dispatch(obtnerCambioEnOrden(orderDetails.id));
    }
  }, [orderDetails && orderDetailsCliente]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const panes = [
    { label: "Datos de Orden", content: data },
    { label: "Contenido de Orden", content: dataContenido },
    { label: "Datos de Driver", content: dataDriver },
    { label: "Acciones", content: actionsOrders },
    { label: "Registro de Acciones", content: logDeCambios },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "Entregado":
      case "Finalizado":
        return "success";
      case "En Proceso":
      case "En ruta":
        return "warning";
      case "Pendiente":
      case "Requiere Actualización":
        return "error";
      default:
        return "default";
    }
  };

  const addNewGeoPoint = async (newLocation) => {
    let newGeo = new GeoPoint(
      parseFloat(newLocation.lat),
      parseFloat(newLocation.lng)
    );
    let data = {
      date: new Date(),
      lastValue: orderDetails.toAddressLocation,
      newValue: newGeo,
      orderId: orderDetails.id,
      type: "infoAdd",
      userId: usuarioStorage.uid,
      var: "toAddressLocation",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${orderDetails.id}`);
    try {
      await updateDoc(orderRef1, {
        toAddressLocation: newGeo,
      });

      setOrderDetails((prev) => ({
        ...prev,
        toAddressLocation: newGeo,
      }));
    } catch (error) {}

    setNewLocation(null);
  };

  const createEmailclient = (e) => {
    e.preventDefault();
    const uniqueId = e.target.elements.uniqueId.value;

    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: "send",
      orderId: orderDetails.id,
      type: uniqueId,
      userId: usuarioStorage.uid,
      var: "email",
    };

    dispatch(registrarCambioEnOrden(data));

    const propsData = {
      acction: uniqueId,
      dirección: confirmDireccion,
      data: orderDetails,
      data2: orderDetailsCliente,
      data3: dataOrdesBicciExpress || [],
    };
    navigate(`/dashboard/dispatch/details/${orderDetails.id}/prewiew_email`, {
      state: propsData,
    });
  };

  const createTicketSuport = (e) => {
    e.preventDefault();
    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: comment,
      orderId: orderDetails.id,
      type: "ticket",
      userId: usuarioStorage.uid,
      var: "ticket",
    };

    dispatch(registrarCambioEnOrden(data));
  };

  data = (
    <Card
      sx={{
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: "background.paper",
        textAlign: "left",
      }}
    >
      <CardContent>
        {/* Datos de Origen */}
        <Typography variant="h5" fontWeight="bold" color="primary">
          Datos Origen
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box>
          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Nombre de Usuario:
            </Typography>
            <Typography variant="body2">
              {orderDetails.placeName ||
                orderDetails.commerce ||
                "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Dirección:
            </Typography>
            <Typography variant="body2">
              {orderDetails.fromAddress &&
              orderDetails.fromAddress !== "isRequire"
                ? orderDetails.fromAddress
                : orderDetails.int_warehouseName || "No disponible"}
            </Typography>
          </Grid2>
        </Box>

        <Divider sx={{ my: 1 }} />
        <Box
          id="colums"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* Datos de Destino */}

          <Box>
            <Typography variant="h5" fontWeight="bold" color="primary">
              Datos Destino
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid2 xs={12} sm={"100%"} lg={"100%"}>
              <Typography variant="subtitle2" fontWeight="bold">
                Nombre:
              </Typography>
              <Typography variant="body2">
                {orderDetails.userName && orderDetails.userName !== "isRequire"
                  ? orderDetails.userName
                  : orderDetails.clientNames || "No disponible"}
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6} lg={12}>
              <Typography variant="subtitle2" fontWeight="bold">
                Destino:
              </Typography>
              <Typography variant="body2">
                {orderDetails.toAddress || "No disponible"}
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                Email Cliente:
              </Typography>
              <Typography variant="body2">
                {orderDetails.userEmail &&
                orderDetails.userEmail !== "isRequire"
                  ? orderDetails.userEmail
                  : orderDetails.clientEmail || "No disponible"}
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                Teléfono:
              </Typography>
              <Typography variant="body2">
                +569 {orderDetails.userPhone} |
                <a
                  href={`callto:+569${orderDetails.userPhone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Llamar
                </a>
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                Localidad:
              </Typography>
              <Typography variant="body2">
                {String(
                  orderDetailsCliente?.commune1 ??
                    orderDetails.commune2 ??
                    "No disponible"
                )}
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                Locación destino:
              </Typography>
              <Typography variant="body2">
                {orderDetails.toAddressLocation ? (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${
                      orderDetails.toAddressLocation.latitude ||
                      orderDetails.toAddressLocation.lat
                    },${
                      orderDetails.toAddressLocation.longitude ||
                      orderDetails.toAddressLocation.lng
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver en mapa
                  </a>
                ) : (
                  "No disponible"
                )}
              </Typography>
            </Grid2>
          </Box>

          <Box>
            {/* Datos Adicionales */}
            <Typography variant="h5" fontWeight="bold" color="primary">
              Datos Adicionales
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                SOC:
              </Typography>
              <Typography variant="body2">
                {orderDetailsCliente?.soc ||
                  orderDetails.orderID ||
                  "No disponible"}
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                F12:
              </Typography>
              <Typography variant="body2">
                {dataOrdesBicciExpress?.[0]?.F12 ||
                  dataOrdesBicciExpress?.[0]?.int_f12 ||
                  "Sin F12 Registrado"}
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                Tamaño de Paquete:
              </Typography>
              <Typography variant="body2">
                {orderDetailsCliente?.size || "No disponible"}
              </Typography>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                Medidas:
              </Typography>
              <Typography variant="body2">
                {`Altura: ${
                  orderDetailsCliente?.height ||
                  orderDetails.productHeight ||
                  "?"
                } | `}
                {`Peso: ${
                  orderDetailsCliente?.weight ||
                  orderDetails.productLength ||
                  "?"
                } | `}
                {`Ancho: ${
                  orderDetailsCliente?.width || orderDetails.productWidth || "?"
                }`}
              </Typography>
            </Grid2>
          </Box>
        </Box>
        <Divider sx={{ my: 1 }} />

        {/* Información de entrega */}
        <Typography variant="h5" fontWeight="bold" color="primary">
          Datos de Entrega
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box>
          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Foto tomada:
            </Typography>
            <Typography variant="body2">
              {orderDetails.picsTaken || (foto.length > 0 ? "Sí" : "No")}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Nombre Receptor:
            </Typography>
            <Typography variant="body2">
              {orderDetails.userNameReceptor ||
                orderDetails.deliveryReceptor ||
                "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              RUT:
            </Typography>
            <Typography variant="body2">
              {orderDetails.userDniReceptor ||
                orderDetails.deliveryRut ||
                "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Última actualización:
            </Typography>
            <Typography variant="body2">{date || "No disponible"}</Typography>
          </Grid2>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* Foto */}
        <Typography variant="h5" fontWeight="bold" color="primary">
          Foto
        </Typography>
        {foto.length > 0 ? (
          <Box sx={{ mt: 1, textAlign: "center" }}>
            {foto.map((item, index) => (
              <img
                key={index}
                src={item}
                alt="Foto"
                width="150px"
                style={{ borderRadius: "8px", m: 1 }}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body2" color="error">
            Sin Foto
          </Typography>
        )}

        {foto.length > 0 && (
          <Box sx={{ mt: 1, textAlign: "left" }}>
            {foto.map((item, index) => (
              <Typography key={index}>{item}</Typography>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );

  dataContenido = (
    <Box
      sx={{
        p: 3,
        boxShadow: 2,
        borderRadius: 2,
        bgcolor: "background.paper",
        textAlign: "left",
      }}
    >
      {/* Estado */}
      <Typography variant="h6" fontWeight="bold">
        Estatus:
      </Typography>
      {orderDetailsCliente?.falabella_status && (
        <Chip
          label={translateStatesfalabbela[orderDetailsCliente.falabella_status]}
          color={getStatusColor(orderDetailsCliente.falabella_status)}
          sx={{ mt: 1, fontSize: "0.9rem", fontWeight: "bold" }}
        />
      )}

      <Divider sx={{ my: 2 }} />

      {/* Curier */}
      <Typography variant="h6" fontWeight="bold">
        Curier:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        BICCI
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Contenido */}
      <Typography variant="h6" fontWeight="bold">
        Contenido:
      </Typography>
      {dataOrdesBicciExpress?.length > 0 ? (
        <List>
          {dataOrdesBicciExpress.map((item, index) => (
            <ListItem key={index} sx={{ p: 0 }}>
              <Typography variant="body1" color="textSecondary">
                {item.PRODUCTO || item.productName} | SKU{" "}
                {item.SKU || item.productSku}
              </Typography>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No data...
        </Typography>
      )}

      {dataOrdesBicciExpress?.length > 0 && (
        <>
          <Divider sx={{ my: 2 }} />

          {/* Código Producto */}
          <Typography variant="h6" fontWeight="bold">
            Código Producto:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {dataOrdesBicciExpress[0].SKU ||
              dataOrdesBicciExpress[0].productSku}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Descripción */}
          <Typography variant="h6" fontWeight="bold">
            Descripción:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {dataOrdesBicciExpress[0].PRODUCTO ||
              dataOrdesBicciExpress[0].productName}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {/* Valor */}
          <Typography variant="h6" fontWeight="bold">
            Valor: $
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {dataOrdesBicciExpress[0].VALOR ||
              dataOrdesBicciExpress[0].productValue}
          </Typography>
        </>
      )}

      <Divider sx={{ my: 2 }} />

      {/* Cantidad de Bultos */}
      <Typography variant="h6" fontWeight="bold">
        Cantidad de Bultos:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {orderDetailsCliente?.quantity_packages || "N/A"}
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Cantidad de Unidades */}
      <Typography variant="h6" fontWeight="bold">
        Cantidad Unidades:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {orderDetailsCliente?.quantity || "N/A"}
      </Typography>
    </Box>
  );

  dataDriver = (
    <Box
      sx={{
        p: 3,
        boxShadow: 2,
        borderRadius: 2,
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        textAlign: "left",
      }}
    >
      {/* Avatar */}

      <Avatar
        src={orderDetails.driverAvatar || ""}
        alt="Foto del conductor"
        sx={{ width: 100, height: 100, mb: 2 }}
      />

      {/* ID */}
      <Typography variant="h6" fontWeight="bold">
        ID:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {orderDetails.driverID && orderDetails.driverID !== "isRequire"
          ? orderDetails.driverID
          : orderDetails.driverRut}
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Nombre */}
      <Typography variant="h6" fontWeight="bold">
        Nombre:
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {orderDetails.driverName || "No disponible"}
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Correo */}
      {orderDetails.email && (
        <>
          <Typography variant="h6" fontWeight="bold">
            Correo Electrónico:
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {orderDetails.email}
          </Typography>
          <Divider sx={{ my: 2 }} />
        </>
      )}

      {/* Teléfono */}
      <Typography variant="h6" fontWeight="bold">
        Teléfono:
      </Typography>
      {orderDetails.driverPhone ? (
        <Typography variant="body1" color="textSecondary">
          <a
            href={`tel:+56${orderDetails.driverPhone}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {orderDetails.driverPhone}
          </a>
        </Typography>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No disponible
        </Typography>
      )}
    </Box>
  );

  actionsOrders = (
    <Box sx={{ textAlign: "left" }}>
      <Box
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        variant="body"
      >
        Crear un registro interno de soporte, Casos de error sistema,
        incumplimentos de protocolos o reclamos del cliente.
        <TicketSupportForm
          createTicketSuport={createTicketSuport}
        ></TicketSupportForm>
      </Box>{" "}
      <hr></hr>
      <Box
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        variant="body"
      >
        Generar maqueta email de primer intento fallido solicitud de contacto.
        <div>
          <CreateEmailClientForm
            createEmailclient={createEmailclient}
          ></CreateEmailClientForm>
        </div>
      </Box>{" "}
      <hr></hr>
      <Box
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        variant="body"
      >
        Generar maqueta email de segundo intento fallido solicitud de contacto.
        <div>
          <CreateEmailSecondAttemptForm
            createEmailclient={createEmailclient}
          ></CreateEmailSecondAttemptForm>
        </div>
      </Box>
      <hr></hr>
      <Box
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        variant="body"
      >
        Generar maqueta email de tercer intento fallido solicitud de contacto.
        <div>
          <CreateEmailThirdAttemptForm
            createEmailclient={createEmailclient}
          ></CreateEmailThirdAttemptForm>
        </div>
      </Box>
      <hr></hr>
      <Box
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        variant="body"
      >
        Generar maqueta email de confirmación de dirección destino - Paquetes
        con dirección no legible.
        <div>
          <CreateEmailConfirmationForm
            createEmailclient={createEmailclient}
          ></CreateEmailConfirmationForm>
        </div>
      </Box>{" "}
      <hr></hr>
      <Box
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        variant="body"
      >
        Generar Autorización de cambio de domicilio.
        <div>
          <CreateEmailAuthorizationForm
            createEmailclient={createEmailclient}
          ></CreateEmailAuthorizationForm>
        </div>
      </Box>{" "}
      <hr></hr>
      {!orderDetails.int_falabella_status ? (
        <Box
          fontFamily="Lato, Arial, Helvetica, sans-serif"
          color="textPrimary"
          variant="body"
        >
          Editar dirección con error.
          <div>Dirección actual:</div>
          <div>{orderDetails.toAddress}</div>
          <AddNewAddressForm
            setOrderDetails={setOrderDetails}
            orderDetails={orderDetails}
            usuarioStorage={usuarioStorage}
            registrarCambioEnOrden={registrarCambioEnOrden}
            dispatch={dispatch}
            collectionRef={collectionRef}
          ></AddNewAddressForm>
        </Box>
      ) : null}
      <hr></hr>
      {bloqueaGoogle !== true ? (
        <AdjustGeoReferenceForm
          orderDetails={orderDetails}
          addNewGeoPoint={addNewGeoPoint}
        ></AdjustGeoReferenceForm>
      ) : null}
      <hr></hr>
    </Box>
  );

  logDeCambios = (
    <Box sx={{ textAlign: "left" }}>
      {storePayload && storePayload.upload === true ? (
        <>
          {storePayload.logs.map((item, index) => (
            <Card
              key={index}
              sx={{ margin: "3px", padding: "3px" }}
              variant="outlined"
            >
              <div>{format(item.date.seconds * 1000, "dd/MM/yy HH:mm:ss")}</div>
              <div>Usuario </div>
              <div>{item.userId}</div>
              <div>Accion: {item.var}</div>
              <div>
                {typeof item.lastValue === "object" &&
                item.lastValue !== null ? (
                  <>Geopunto</>
                ) : (
                  <>
                    {" "}
                    <div>valor anterior: {item.lastValue} </div>
                    <div>nuevo valor {item.newValue}</div>
                  </>
                )}
              </div>
            </Card>
          ))}
        </>
      ) : (
        <>Sin cambios</>
      )}
    </Box>
  );

  return (
    <Box sx={{ width: "100%" }}>
      {/* Contenedor de Tabs */}
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        {panes.map((pane, index) => (
          <Tab key={index} label={pane.label} />
        ))}
      </Tabs>

      {/* Contenido de cada pestaña */}
      {panes.map((pane, index) => (
        <TabPanel key={index} value={activeTab} index={index}>
          <Box>{pane.content}</Box>
        </TabPanel>
      ))}
    </Box>
  );
};

// Componente auxiliar para mostrar el contenido de cada pestaña
const TabPanel = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      sx={{ p: 2, display: value === index ? "block" : "none" }}
    >
      <Card sx={{ p: 2, boxShadow: 2 }}>
        <CardContent>
          <Box>{children}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TabSecondary;
