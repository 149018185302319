import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";

const TicketSupportForm = ({ createTicketSuport }) => {
  const [comment, setComment] = useState("");

  return (
    <form onSubmit={createTicketSuport}>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <TextField
          fullWidth
          label="Agregar descripción del caso"
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "#591E8F",
            color: "#fff",
            borderRadius: "5px",
            padding: "10px 20px",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#25b2f4",
              color: "#591e8f",
            },
          }}
        >
          Agregar nota de seguimiento
        </Button>
      </Box>
    </form>
  );
};

export default TicketSupportForm;
