import React, { useEffect, useState } from "react";
import FromShippingWiew from "../../../Seccions/FromShipping/FromShippingWiew";
import {
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  CardContent,
} from "@mui/material";

const Express = ({ userDataContext, companiesData }) => {
  const [prefix, setPrefix] = useState("");
  const [companyData, setCompanyData] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null); // Cambié el estado a singular para manejar un solo objeto

  useEffect(() => {
    if (userDataContext && companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [userDataContext, companiesData]); // Separé las dependencias en el useEffect

  const handleChange = (e) => {
    const companyDataReq = filteredCompanies.find(
      (company) => company.integrationServicesBicciID === e.target.value
    );
    if (companyDataReq && companyDataReq.prefix) {
      setCompanyData(companyDataReq);
      setSelectedCompany(companyDataReq); // Guardamos el objeto completo
      setPrefix(companyDataReq.prefix);
    } else {
      console.log("No se encuentra empresa o prefijo", companyDataReq);
    }
  };

  return companiesData ? (
    <Box>
      <Grid
        container
        spacing={1}
        sx={{
          backgroundColor: "#F9FAFC",
        }}
        id="filtrosEmpresas"
      >
        <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%", p: 1 }}>
            <Typography variant="body" sx={{ margin: "10px" }}>
              Búsqueda de empresas
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: "5px",
              }}
            >
              <Grid>
                {filteredCompanies ? (
                  <FormControl
                    fullWidth
                    sx={{
                      minWidth: "350px",
                      maxWidth: "49%",
                      margin: "3px",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <InputLabel
                      id="company-select-label"
                      sx={{
                        backgroundColor: "#FFF",
                        padding: "0px",
                      }}
                    >
                      Empresas
                    </InputLabel>
                    <Select
                      labelId="company-select-label"
                      id="company-select"
                      value={
                        selectedCompany
                          ? selectedCompany.integrationServicesBicciID
                          : ""
                      } // Se asegura de que el valor esté correctamente ligado
                      onChange={handleChange}
                      renderValue={(selected) =>
                        selectedCompany ? (
                          <Chip label={selectedCompany.name} />
                        ) : (
                          "Seleccione una empresa"
                        )
                      }
                    >
                      {filteredCompanies
                        .filter(
                          (company) =>
                            company.integrationServicesBicciID !== undefined
                        )
                        .map((company) => (
                          <MenuItem
                            key={company.integrationServicesBicciID}
                            value={company.integrationServicesBicciID}
                          >
                            {company.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : null}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {companyData && prefix ? (
        <>
          {companyData.token ? (
            <FromShippingWiew
              userDataContext={userDataContext}
              prefix={prefix}
              companyData={companyData}
              dataOrigin={"DATA"}
            />
          ) : (
            <Card>
              <CardContent>
                No se ha encontrado un token válido para la{" "}
                <strong>creación de órdenes express</strong>. Puede intentar
                crear la orden a través de un documento de importación o
                contactar al soporte para validar el estado de su integración.
              </CardContent>
            </Card>
          )}
        </>
      ) : (
        <Card>
          <CardContent>"Seleccione empresa para continuar"</CardContent>
        </Card>
      )}
    </Box>
  ) : (
    <>
      <h2>Esperando perfil</h2>
      <LinearProgress />
    </>
  );
};

export default Express;
