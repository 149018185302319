import  { useEffect, useState } from 'react'
import {db, firebase} from '../firebase'


function useGetDataDispachstGroupByVehicle (companyId,userRoll,OrdersGroup){



    const [bicciOrderExpressOutGroup, setBicciOrders] = useState(null)
    const [loadOrderExpressGroup, setLoadOrders] = useState(false)
    const [errorOrderExpressGroup, setErrorOrders] = useState(null)    
 

    useEffect(()=>{
    //console.log(OrdersGroup)
      fetchData(companyId)
    },[companyId,userRoll,OrdersGroup])
  
    const fetchData = async (companyId) => {
  
      const AplyFiltersByAdmin = (dataallOrderID,OrdersGroup)=>{
              //  console.log(dataallOrderID)
              //  console.log(OrdersGroup)
               if(OrdersGroup){
               let order = OrdersGroup.trim().toLowerCase() 
               dataallOrderID = dataallOrderID.filter(item => item.id.toLowerCase().includes(order));
               dataallOrderID = dataallOrderID.filter(item => item.id.isActive === true);
               dataallOrderID = dataallOrderID.filter(item => item.id.isDelete === false);

               setBicciOrders(dataallOrderID)
               }else{
                setBicciOrders(dataallOrderID)}
              // console.log(dataallOrderID)
               
      }

      try {
        setLoadOrders(true)
        let dataSearch = Object.values(OrdersGroup); 
        if(userRoll === process.env.REACT_APP_ADMIN){
          //filtro opcional Compañia

          if(companyId && OrdersGroup){
            const allOrdersRef = db.collection('orders')
            const dataallOrdersfind = await allOrdersRef.where(firebase.firestore.FieldPath.documentId(), "in", dataSearch)
            .get();
            var dataOrdes = dataallOrdersfind.docs.map(doc => ({id: doc.id, ...doc.data()}))
            setBicciOrders(dataOrdes)
          }else{
            //Permiso de Admin
            const allOrdersRef = db.collection('orders')
            const dataallOrdersfind3 = await allOrdersRef.where(firebase.firestore.FieldPath.documentId(), "in", dataSearch)
            .get();
            var dataOrdes = dataallOrdersfind3.docs.map(doc => ({id: doc.id, ...doc.data()}))
           
            setBicciOrders(dataOrdes)
          }
        }else{
              //filtro Obligatorio Compañia
          if(companyId){
            const allOrdersRef = db.collection('orders')
            const dataallOrdersfind5 = await allOrdersRef.where(firebase.firestore.FieldPath.documentId(), "in", dataSearch)
            .get();
            var dataOrdes = dataallOrdersfind5.docs.map(doc => ({id: doc.id, ...doc.data()}))
            setBicciOrders(dataOrdes)
          }else{
              //Bloqueo de consulta
            setBicciOrders(null)
          }
        }

        setLoadOrders(false)
      } catch (error) {
        //console.log(error)
        setErrorOrders(true)
        setBicciOrders(null)
      }
    }  
    return {bicciOrderExpressOutGroup, loadOrderExpressGroup, errorOrderExpressGroup}
}

export default useGetDataDispachstGroupByVehicle