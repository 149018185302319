import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Circle,
  CircleMarker,
  Polyline,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Avatar, Box, Typography } from "@mui/material"; // ✅ Importa Typography correctamente
import "leaflet-draw/dist/leaflet.draw.css";
import * as routeManager from "../MapPanelViewRoutes/RouteManager";
import { convertCompilerOptionsFromJson } from "typescript";
import { colorByStatus } from "../../utils/objects/stylesStates";

const MapComponentMonitorStatusLeaflet = ({
  selectedInfoRoute,
  dataMerge,
  viewDrivers,
  routeGeometryRider,
  selectedViewLinesRider,
  selectedViewRouteRider,
  selectedViewRouteRiderData,
  ridersDataByCompany = [],
  selectedDriver,
}) => {
  const [center] = useState([-33.448891, -70.669266]);
  const [zoom] = useState(13);
  const mapRef = useRef(null);

  const [selectedMarkers, setSelectedMarkers] = useState([]); // ✅ Estado para marcadores seleccionados
  const [hoveredWaypoint, setHoveredWaypoint] = useState(null); // ✅ Estado para waypoint en hover

  const [viewDataMerge, setViewDataMerge] = useState([]);

  const customIcon = new L.Icon({
    iconUrl: "/static/images/conductor.png", // 📌 Asegúrate de que esta imagen existe en "public/static/images"
    iconSize: [32, 32], // 🔍 Tamaño del icono
    iconAnchor: [16, 32], // 📍 Punto de anclaje (centro inferior)
    popupAnchor: [0, -32], // 🗨️ Posición del popup
  });

  useEffect(() => {
    console.count("actualizar");
  }, [ridersDataByCompany]);

  useEffect(() => {}, [viewDataMerge, selectedDriver]);

  useEffect(() => {
    setViewDataMerge(dataMerge);
  }, [dataMerge]);

  const homeIcon = new L.divIcon({
    className: "custom-icon",
    html: "🏠", // Usa un emoji de casa o cambia por un icono SVG
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box flexGrow={1}>
        <MapContainer
          center={center}
          zoom={zoom}
          style={{ height: "100vh", width: "100%" }}
          ref={mapRef}
        >
          <TileLayer
            attribution={routeManager.attributionBICCI}
            url={routeManager.urlGrey}
          />

          {/* 🔹 Renderizar los Waypoints */}
          {viewDataMerge.map((order, index) => {
            if (
              !order.toAddressLocation ||
              typeof order.toAddressLocation !== "object"
            )
              return null;

            const lat = parseFloat(order.toAddressLocation._lat);
            const lon = parseFloat(order.toAddressLocation._long);

            if (isNaN(lat) || isNaN(lon)) return null;

            const color = order.intState
              ? "red"
              : colorByStatus[order.status] || "#999";
            const position = [lat, lon];
            const route = order.driverLicensePlate || "Sin Ruta";
            const isSelected = selectedMarkers.includes(order.id);
            const orderNumber = order.orderNumber || "Sin Ruta";

            return (
              <Marker
                key={index}
                position={position}
                icon={routeManager.createSVGIcon(
                  isSelected
                    ? "white"
                    : hoveredWaypoint === order.id
                    ? "#121828"
                    : color,
                  hoveredWaypoint === order.id ? 20 : 9
                )}
                eventHandlers={{
                  click: () => {
                    setSelectedMarkers((prev) =>
                      prev.includes(order.id)
                        ? prev.filter((id) => id !== order.id)
                        : [...prev, order.id]
                    );
                  },
                  mouseover: () => setHoveredWaypoint(order.id),
                  mouseout: () => setHoveredWaypoint(null),
                }}
              >
                <Popup>
                  <Typography variant="caption" display="block">
                    <strong>Orden ID:</strong> {order.id} <br />
                    <strong>Ruta:</strong> {route} | Parada:{" "}
                    {orderNumber ? orderNumber : "| ⚠️ Up"}
                  </Typography>
                </Popup>
              </Marker>
            );
          })}

          {/* Dibujar las rutas con las nuevas variables */}
          {selectedViewLinesRider && selectedViewLinesRider.length > 0 ? (
            <>
              {selectedViewLinesRider
                .filter((route) => routeGeometryRider.includes(route.id)) // 🔹 Filtrar rutas seleccionadas
                .map((route, index) => {
                  const routeName = route.id || `Ruta_${index + 1}`;
                  const color = route.routeColor || "blue"; // 🔹 Definir el color desde la ruta

                  // 🔹 Extraer `geometry` como un array válido
                  // 🔹 Verificar y transformar `geometry`
                  let geometryArray = [];

                  if (Array.isArray(route.geometry)) {
                    if (
                      route.geometry.every(
                        (point) =>
                          typeof point === "object" &&
                          "lon" in point &&
                          "lat" in point
                      )
                    ) {
                      geometryArray = route.geometry.map(({ lon, lat }) => [
                        lat,
                        lon,
                      ]);
                    } else if (
                      route.geometry.every(
                        (point) => Array.isArray(point) && point.length === 2
                      )
                    ) {
                      geometryArray = route.geometry.map(([lon, lat]) => [
                        lat,
                        lon,
                      ]);
                    }
                  } else if (
                    typeof route.geometry === "object" &&
                    !Array.isArray(route.geometry)
                  ) {
                    geometryArray = Object.values(route.geometry)
                      .filter(
                        (point) => Array.isArray(point) && point.length === 2
                      )
                      .map(([lon, lat]) => [lat, lon]);
                  }

                  // ✅ Revisar resultado final
                  // ✅ Revisar resultado final

                  return geometryArray.length > 0 ? (
                    <Polyline
                      key={`${routeName}-${index}`}
                      positions={geometryArray} // ✅ Mantiene el formato correcto [[lat, lon], [lat, lon]]
                      color={color}
                      weight={3}
                      opacity={0.7}
                      lineCap="round"
                      stroke={true}
                      fillColor={color}
                      fillOpacity={1}
                    >
                      <Popup>
                        <Typography variant="caption" display="block">
                          <strong>Ruta:</strong> {routeName} <br />
                        </Typography>
                      </Popup>
                    </Polyline>
                  ) : null;
                })}
            </>
          ) : null}

          {selectedViewRouteRiderData &&
            selectedViewRouteRiderData
              .filter((route) => selectedViewRouteRider.includes(route.id)) // Filtrar solo rutas seleccionadas
              .flatMap((route) =>
                route.routeData.map((wp, index) => {
                  const routeName = wp.routeName || "Sin Ruta";
                  const orderNumber = wp.index || "#";

                  try {
                    const lat = parseFloat(wp.location?.lat);
                    const lon = parseFloat(
                      wp.location?.lon || wp.location?.log
                    );

                    if (isNaN(lat) || isNaN(lon)) {
                      console.warn(
                        `⚠️ Coordenadas inválidas para ${wp.id}:`,
                        wp.location
                      );
                      return null;
                    }

                    return index === 0 ? (
                      <Marker
                        key={`home-${index}`}
                        position={[lat, lon]}
                        icon={homeIcon}
                      >
                        <Tooltip permanent direction="top">
                          🏡 Origen
                        </Tooltip>
                      </Marker>
                    ) : (
                      <CircleMarker
                        key={wp.id}
                        center={[lat, lon]}
                        radius={6}
                        fillColor="blue"
                        fillOpacity={0.7}
                      >
                        <Tooltip permanent direction="top">
                          {index === route.routeData.length - 1
                            ? "Destino"
                            : `P:${orderNumber ?? "⚠️"} x ${
                                Array.isArray(wp.ids) ? wp.ids.length : "⚠️"
                              } 📦`}
                        </Tooltip>
                      </CircleMarker>
                    );
                  } catch (error) {
                    console.error("Error en Marker:", error);
                    return null;
                  }
                })
              )}

          {viewDrivers &&
            ridersDataByCompany &&
            ridersDataByCompany
              .filter((driver) => driver.online === true)
              .map((driver, index) => {
                const position = [
                  driver.driverLocation.latitude,
                  driver.driverLocation.longitude,
                ];

                if (selectedDriver === "all") {
                  return (
                    <React.Fragment key={`driver-${index}`}>
                      {/* 📍 Marcador del Conductor */}
                      <Marker position={position} icon={customIcon}>
                        <Popup>
                          <Typography variant="caption" display="block">
                            <Avatar src={driver.avatar || ""}></Avatar>
                            <strong>Driver:</strong> {driver.name} <br />
                          </Typography>
                        </Popup>
                      </Marker>

                      {/* 🔵 Círculo de 100 metros alrededor del conductor */}
                      <Circle
                        center={position}
                        radius={100} // 100 metros
                        color={"#2db6e2"}
                        fillColor="rgba(0, 0, 255, 0.2)" // Color azul con opacidad
                        fillOpacity={0.3}
                        weight={2} // Grosor del borde
                      />
                    </React.Fragment>
                  );
                }
                if (selectedDriver === driver.id) {
                  console.log(driver.name);
                  return (
                    <React.Fragment key={`driver-${index}`}>
                      {/* 📍 Marcador del Conductor */}
                      <Marker position={position} icon={customIcon}>
                        <Popup>
                          <Typography variant="caption" display="block">
                            <Avatar src={driver.avatar || ""}></Avatar>
                            <strong>Driver:</strong> {driver.name} <br />
                          </Typography>
                        </Popup>
                      </Marker>

                      {/* 🔵 Círculo de 100 metros alrededor del conductor */}
                      <Circle
                        center={position}
                        radius={100} // 100 metros
                        color={"#591e8f"}
                        fillColor="rgba(0, 0, 255, 0.2)" // Color azul con opacidad
                        fillOpacity={0.3}
                        weight={2} // Grosor del borde
                      />
                    </React.Fragment>
                  );
                }
              })}
        </MapContainer>
      </Box>
      ;
    </Box>
  );
};

export default MapComponentMonitorStatusLeaflet;
