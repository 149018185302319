import { Button } from "@mui/material";
import React from "react";
import { calculateRouteCentroids } from "../../MapsLeaflet/MapsUtils/utilsDistribution.jsx/calculateRouteCentroids";
import { assignOrdersToRoutes } from "../../MapsLeaflet/MapsUtils/utilsDistribution.jsx/assignOrdersToRoutes";

const ButonhandleCombineWithRoutes = ({
  dataMerge,
  selectedOrders,
  routeInfo,
  setSelectedOrders,
  setSelectedRoutes,
  setTargetRoute,
  setViewDataMerge,
  setLocalDataMerge,
}) => {
  // Maneja la combinación de órdenes con rutas
  const handleCombineWithRoutes = () => {
    if (!selectedOrders || selectedOrders.length === 0) return;

    // Calcular centroides de las rutas
    const routeCentroids = calculateRouteCentroids(routeInfo);

    const ordersToUpdate = selectedOrders
      .map((id) => dataMerge.find((order) => order.id === id))
      .filter(Boolean);

    console.log(ordersToUpdate);
    // Asignar las órdenes a rutas
    const updatedOrders = assignOrdersToRoutes(ordersToUpdate, routeCentroids);

    // Actualizar las órdenes en el estado
    setViewDataMerge((prev) =>
      prev.map((order) =>
        selectedOrders.includes(order.id)
          ? updatedOrders.find((updated) => updated.id === order.id) || order
          : order
      )
    );

    setLocalDataMerge((prev) =>
      prev.map((order) =>
        selectedOrders.includes(order.id)
          ? updatedOrders.find((updated) => updated.id === order.id) || order
          : order
      )
    );

    // Limpiar selección de órdenes y rutas
    setSelectedOrders([]);
    setSelectedRoutes([]);
    setTargetRoute("");
  };

  return (
    <Button
      disabled={selectedOrders.length === 0}
      onClick={handleCombineWithRoutes}
    >
      Combinar con rutas cercanas
    </Button>
  );
};

export default ButonhandleCombineWithRoutes;
