import axios from "axios";
const urlPro = process.env.REACT_APP_BICCI_ROUTES_OPTIMIZE;

export const fetchOptimizedWaypoints = async (data) => {
  try {
    const response = await axios.post(urlPro, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_ROUTES_BICCI_TOKEN}`,
      },
      credentials: "include",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
