import React from "react";
import useGlobalConfig from "../../../hooks/globalConfig";
import MenuFalabella from "./IntegratiosMenus/MenuFalabella";
import MenuGeneralOperacion from "./IntegratiosMenus/MenuGeneralOperacion";
import MenuGeneralBodega from "./IntegratiosMenus/MenuGeneralBodega";
import MenuGeneralMyAcount from "./IntegratiosMenus/MenuGeneralMyAcount";
import useTitle from "../../Seo/useTitle";
import DashboardSidebarContact from "./DashboardSidebarContact";
import { Box } from "@mui/material";

export const DashboardSidebarCompanyOperator = (props) => {
  const { img5 } = useGlobalConfig();

  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} Dashboard` });

  return (
    <div className="sidebar-body">
      <div id="brandsLogo">
        <Box
          component="img"
          src={img5}
          alt=""
          sx={{
            height: "auto",
            maxWidth: "100px",
            backgroundColor: "#fff",
            borderRadius: "100%",
          }}
        />
      </div>
      <h3 className="btn-sidebarLink alignItemscentertitle">
        Administrador de empresa
      </h3>

      <MenuGeneralOperacion
        onNavigate={props.handleCloseDrawer}
      ></MenuGeneralOperacion>
      <MenuGeneralBodega
        onNavigate={props.handleCloseDrawer}
      ></MenuGeneralBodega>
      {props.companyCollaborator ? (
        <>
          {Array.isArray(props.companyCollaborator) &&
          props.companyCollaborator.length > 0
            ? props.companyCollaborator.map((item) => {
                if (item === "falabella") {
                  return (
                    <MenuFalabella
                      key={item}
                      companyCollaborator={item}
                      onNavigate={props.handleCloseDrawer}
                    ></MenuFalabella>
                  );
                }
                return null;
              })
            : props.companyCollaborator === "falabella" && (
                <MenuFalabella
                  key={props.companyCollaborator}
                  companyCollaborator={props.companyCollaborator}
                  onNavigate={props.handleCloseDrawer}
                ></MenuFalabella>
              )}
        </>
      ) : null}
      <MenuGeneralMyAcount
        onNavigate={props.handleCloseDrawer}
      ></MenuGeneralMyAcount>
      <DashboardSidebarContact props={props}></DashboardSidebarContact>
    </div>
  );
};
