import React from "react";
import { Button, Box } from "@mui/material";

const CreateEmailClientForm = ({ createEmailclient }) => {
  return (
    <form onSubmit={createEmailclient}>
      {/* Campo oculto para el ID único */}
      <input type="hidden" name="uniqueId" value="primerintento" />

      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          variant="contained"
          type="submit" // Se corrigió 'sutmit' a 'submit'
          sx={{
            backgroundColor: "#591E8F",
            color: "#fff",
            borderRadius: "5px",
            padding: "10px 20px",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#25b2f4",
              color: "#591e8f",
            },
          }}
        >
          Crear email de primer intento
        </Button>
      </Box>
    </form>
  );
};

export default CreateEmailClientForm;
