export const createDocumentRoute = () => ({
  id: "", // fecha company user ruta , es el codigo de la ruta  20250208_26456087_Ruta_1
  routeColor: "",
  packageCounts: "",
  route: "", // nombre de la ruta -> puede repetirse Ruta_1 por eso el identificador es el id
  subRoutes: [], // ejemplo Ruta_1_sub1
  companyID: "", // empresa que creo la ruta
  totalDistance: "", // distancia total estimado
  totalTime: "", // tiempo total estimado
  dayStart: "", // dia de ruta
  initHour: "", // hora de inicio sistematico
  endHour: "", // hora de cierra sistematico
  isComplete: false, // todos los datos iniciales cargados , No datos de traking
  isActive: true, // ruta esta activa
  isFinish: false,
  // ruta esta completada , todos los folios entregados cambia el valor de isClosed a true ,
  // pending: 0, debe ser cero
  // y la suma de delivered failed issues cancel debe ser igual = totalOrders lo que indica que todos fueron gestionados
  isClosed: false, // ruta cerrada , cierre diario -> no deberia permitir ediciones solo vista
  createAt: "", // Cuando se creo la ruta
  updateAt: "", // Cuando se actualizo
  createdBy: "", // Usuario que la creo
  // waypoints: [], // Cambio a subcolecion en firebase Paradas en ruta
  // waypointsOutRoute: [], // Cambio a subcolecion en firebase  Entregas en ruta que coiciden en ruta pero no fueron planeadas
  geometry: [], // trayecto de ruta
  observations: [], // Observaciones  de ruta
  // datos actualización desde funciones
  analytics: {
    totalOrders: 0, // todos los pedidos de esta ruta
    progress: 0, // entregados vs asignados
    delivered: 0, // entregados
    pending: 0, // pendientes en ruta
    failed: 0, // Sin Moradores , No gestionados al cerrarse la ruta,
    sla: 0, // promedio de slaOps de cada folio del conductor en esta ruta.
    routeTime: "", // tiempo total de ruta ->  hora inicio sistematico hasta hora en que cambia isClosed: true, // ruta cerrada , cierre diario
    team: {},
  },
});

export const drivers = () => ({
  id: "", // ide del conductor para facilitar la busqueda
  // valores recalculan dependiendo de cada entrega y los folios que posee en conductor en esta ruta
  total: 0, // todos los pedidos de este conductor en esta ruta
  progress: 0, // entregados vs asignados
  pending: 0, // pendientes en ruta
  delivered: 0, // entregados
  failed: 0, // Sin Moradores , No gestionados al cerrarse la ruta,
  cancel: 0, // cancelado por el cliente de desde origen
  sla: 0, // promedio de slaOps de cada folio del conductor en esta ruta.
}); // entregados vs asignados en porcentaje

export const waypoint = () => ({
  // Objetos anidados en collecion waypoints
  id: "", //"Ruta_1_index_2", // id: "{ruta}_{index}",
  ids: [], // ["99M026594845CL"],
  routeName: "", //"Ruta_1",
  name: "", //"Exequiel Fernández",
  index: 0,
  currentStatus: "",
  nonDeliveryStatus: "",
  location: {
    lat: 0, // lat: -33.458433,
    log: 0, // lon: -70.599922,
  },
  routingData: {
    // Datos de estimación
    distanceMeters: 0, // 32363, // dato invalido recalcular
    formattedDistance: "", //"0.69 km",
    estimatedTimeSeconds: 0, //60,
    formattedTime: "", //1.00 min",
    segment: "", //"Tramo 2",
    total: 1,
  },
  trackingData: {
    // datos actualización desde funciones
    creationDateFormated: "", // creado staus 0
    creationTime: "", // creado  staus 0
    creationConfirmDateFormated: "", // creado staus 1
    creationConfirmTime: "", // creado  staus 1
    onTheWayDateFormated: "", // en cambio de estado en ruta 4
    onTheWayTime: "", // en cambio de estado en ruta 4
    deliveryDateFormated: "", // en cambio de estado entregado 8
    deliveryTime: "", // en cambio de estado entregado 8
    lastUpdateDateFormated: "", // ultimo cambio de estado
    lastUpdateTime: "", // ultimo cambio de estado
    slafolder: "", // tiempo de creacion a tiempo de entrega
    slaOps: "", // tiempo de en ruta a tiempo de entrega
  },
});

export const waypointOutRute = () => ({
  // Objetos anidados en collencion waypointsOutRoute
  id: "", //"Ruta_1_index_2", // id: "{Ruta_1}_{index}_{id}",
  ids: [], // ["99M026594845CL"],
  routeName: "", // "Ruta_1", // Ruta donde se encontro el folio , si lo encuentra por algun motivo
  name: "", // Vacio o toAdress
  index: 0,
  currentStatus: "",
  nonDeliveryStatus: "",
  location: {
    lat: 0, // lat: -33.458433,
    log: 0, // lon: -70.599922,
  },
  routingData: {
    // Sin datos de estimación
    distanceMeters: 0, // 32363, // dato invalido recalcular
    formattedDistance: "", //"0.69 km",
    estimatedTimeSeconds: 0, //60,
    formattedTime: "", //1.00 min",
    segment: "", //"Tramo 2",
    total: 1,
  },
  trackingData: {
    // datos actualización
    creationDate: "",
    creationTime: "",
    lastUpdateDate: "",
    lastUpdateTime: "",
    onTheWayDate: "",
    onTheWayTime: "",
    deliveryDate: "",
    deliveryTime: "",
    slafolder: "", // tiempo de creacion a tiempo de entrega
    slaOps: "", // tiempo de en ruta a tiempo de entrega
  },
});
