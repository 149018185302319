import React from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Alert,
  Divider,
} from "@mui/material";
import Fade from "@mui/material/Fade";

const EmpresaForm = ({
  showEdit,
  success,
  error,
  resperror,
  validatorSuccess,
  validatorAlert,
  crearEmpresa,
  companyName,
  setCompanyName,
  companyData,
  rutvalidator,
  companyActividad,
  setCompanyActividad,
  companyAddress,
  setCompanyAddress,
  companyNumAddress,
  setCompanyNumAddress,
  companyRegion,
  setCompanyRegion,
  companyCity,
  setCompanyCity,
  companyComuna,
  setCompanyComuna,
  companyCountry,
  setCompanyCountry,
  companyAdressNumAddressTemp,
  companyContacName,
  setCompanyContacName,
  companyAdressPhone,
  setCompanyAdressPhone,
  companyContacEmail,
  setCompanyContacEmail,
}) => {
  return (
    <Fade in={!showEdit} timeout={500}>
      <Box
        component="form"
        onSubmit={crearEmpresa}
        sx={{
          p: 3,
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: "800px",
          mx: "auto",
        }}
      >
        {/* Mensajes de alerta */}
        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {resperror && <Alert severity="error">{resperror}</Alert>}
        {validatorSuccess && (
          <Alert severity="success">{validatorSuccess}</Alert>
        )}
        {validatorAlert && <Alert severity="error">{validatorAlert}</Alert>}

        {/* Información de la empresa */}
        <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
          Información de la Empresa
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Razón Social o Nombre de Empresa"
              variant="outlined"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="RUT de la empresa"
              variant="outlined"
              value={companyData["companyRut"]}
              onChange={(e) => rutvalidator(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Actividad Económica"
              variant="outlined"
              multiline
              rows={2}
              value={companyActividad}
              onChange={(e) => setCompanyActividad(e.target.value)}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* Dirección */}
        <Typography variant="h6" fontWeight="bold">
          Dirección
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Aquí va el componente de búsqueda de direcciones */}
            {/* <Search /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Número"
              variant="outlined"
              value={companyNumAddress}
              onChange={(e) => setCompanyNumAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Comuna"
              variant="outlined"
              value={companyComuna}
              onChange={(e) => setCompanyComuna(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Región"
              variant="outlined"
              value={companyRegion}
              onChange={(e) => setCompanyRegion(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Ciudad"
              variant="outlined"
              value={companyCity}
              onChange={(e) => setCompanyCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="País"
              variant="outlined"
              value={companyCountry}
              onChange={(e) => setCompanyCountry(e.target.value)}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {/* Contacto */}
        <Typography variant="h6" fontWeight="bold">
          Contacto
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nombre de Contacto"
              variant="outlined"
              value={companyContacName}
              onChange={(e) => setCompanyContacName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Teléfono de Contacto"
              variant="outlined"
              value={companyAdressPhone}
              onChange={(e) => setCompanyAdressPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Correo Electrónico"
              variant="outlined"
              value={companyContacEmail}
              onChange={(e) => setCompanyContacEmail(e.target.value)}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3,
          }}
        >
          <Button type="submit" variant="contained" color="primary">
            Guardar
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

export default EmpresaForm;
