import React from "react";

import useTitle from "../../../components/Seo/useTitle";
import useSeo from "../../../components/Seo/useSeo";
import useGlobalConfig from "../../../hooks/globalConfig";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";
const Info = () => {
  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} información` });
  useSeo({
    description:
      "Nuestro equipo de reparto está conformado con personal calificado y capacitado para realizar el despacho de tus productos, con un alto nivel de compromiso y profesionalismo.",
  });

  return (
    <ContainerViewComponent className="bodycontent">
      <div>Info</div>
    </ContainerViewComponent>
  );
};

export default Info;
