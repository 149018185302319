import React from "react";

import { db } from "../../../firebase";
import { useSelector } from "react-redux";
import Serviciosmanagement from "./Serviciosmanagement";
import Serviciositems from "./Serviciositems";
import useObtenerBalance from "../../../hooks/obtenerBalance";
import useObtenerResumendeCompras from "../../../hooks/obtenerResumendeCompras";
import Pos3 from "../../Web/postcontent/inicio/Pos3/Pos3";
const Serviciosdash = (props) => {
  const userUid = useSelector((store) => store.usuario.user.uid);
  const [serviciosActivos, setAerviciosActivios] = React.useState([]);
  var hasCompany = false;
  const [userCompany, setUserCompany] = React.useState("");
  const [userCompanyID, setUserCompanyID] = React.useState("");
  const [userCompanyRoll, setUserCompanyRoll] = React.useState("");
  const [userRoll, setUserRoll] = React.useState("");
  const [authorized, setAuthorized] = React.useState(false);
  const [avatar, setAvatar] = React.useState("");
  const { userBalance } = useObtenerBalance(userCompanyID);
  const { companyBilling } = useObtenerResumendeCompras(userCompanyID);
  const companyFind = {
    companyId: "",
    createAdd: "",
    propietary: "",
    companyName: "",
    companyRut: "",
    companyAddress: "",
    companyNumAddress: "",
    companyRegion: "",
    companyCity: "",
    companyComuna: "",
    companyCountry: "",
    companyCountryCode: "",
    companyActividad: "",
    companyContacName: "",
    companyContacEmail: "",
    companyAdressPhone: "",
    statusDocsRut: "",
    statusDocsComercio: "",
    statusDocsDocsii: "",
    statusDocsAcuerdo: "",
    statusDocsPatente: "",
    statusDocsLogo: "",
    stausDocument: "",
    adressGeoNumAddressLat: "",
    adressGeoNumAddressLng: "",
    estatusCRM: "",
    relateCRM: "",
    isActive: "",
    servicie: "",
    servicetype: "",
    stausService: "",
    CurrentPayMethods: "",
    payPendings: "",
    suportTikets: "",
  };

  const obtenerDatosdeCliente = async (userUid) => {
    try {
      const accountants = await db
        .collection(`${process.env.REACT_APP_COL_USERS}`)
        .doc(userUid)
        .get();
      var data = accountants.data();
      // console.log(data)
      setUserRoll(data.roll);
      setUserCompany(data.userCompany);
      setUserCompanyID(data.userCompanyID);
      setUserCompanyRoll(data.userCompanyRoll);

      const companiesaccountants = await db
        .collection(`${process.env.REACT_APP_COL_COMPANIES}`)
        .doc(data.userCompanyID)
        .get();
      var dataComp = companiesaccountants.data();
      // console.log(dataComp)
      companyFind.companyId = dataComp.companyID;
      companyFind.createbyAdd = dataComp.createByUid;
      companyFind.createdate = dataComp.createdate;
      companyFind.createdateAdd = dataComp.createdateAdd;
      companyFind.propietary = dataComp.propietary;
      companyFind.companyName = dataComp.companyName;
      companyFind.companyRut = dataComp.companyRut;
      companyFind.companyAddress = dataComp.companyAddress;
      companyFind.companyNumAddress = dataComp.companyNumAddress;
      companyFind.companyRegion = dataComp.companyRegion;
      companyFind.companyCity = dataComp.companyCity;
      companyFind.companyComuna = dataComp.companyComuna;
      companyFind.companyCountry = dataComp.companyCountry;
      companyFind.companyCountryCode = dataComp.companyCountryCode;
      companyFind.companyActividad = dataComp.companyActividad;
      companyFind.companyContacName = dataComp.companyContacName;
      companyFind.companyContacEmail = dataComp.companyContacEmail;
      companyFind.companyAdressPhone = dataComp.companyAdressPhone;
      companyFind.statusDocsRut = dataComp.companyImgRut;
      companyFind.statusDocsComercio = dataComp.companyImgDocumentos;
      companyFind.statusDocsDocsii = dataComp.companyImgDocSii;
      companyFind.statusDocsAcuerdo = dataComp.statusDocsAcuerdo;
      companyFind.statusDocsPatente = dataComp.companyImgPatente;
      companyFind.statusDocsLogo = dataComp.statusDocsLogo;
      companyFind.stausDocument = dataComp.stausDocument;
      companyFind.adressGeoNumAddressLat = dataComp.adressGeoNumAddressLat;
      companyFind.adressGeoNumAddressLng = dataComp.adressGeoNumAddressLng;
      companyFind.estatusCRM = dataComp.estatusCRM;
      companyFind.relateCRM = dataComp.relateCRM;
      companyFind.isActive = dataComp.isActive;
      companyFind.servicie = dataComp.servicie;
      companyFind.servicetype = dataComp.servicetype;
      companyFind.stausService = dataComp.stausService;
      companyFind.currentPayMethods = data.currentPayMethods;
      companyFind.payPendings = dataComp.payPendings;
      companyFind.suportTikets = dataComp.suportTikets;
      companyFind.companyCodeFolder = dataComp.companyCodeFolder;

      if (data.userCompany) {
        hasCompany = true;
      }

      if (
        data.userCompanyRoll === "company_admin" &&
        data.propietari === process.env.REACT_APP_COMPANY_PROP
      ) {
        console.log(data);
        setAuthorized(true);
        // console.log(authorized)
        // console.log(userRoll)
        // console.log(process.env.REACT_APP_ADMIN)
      }
      setAvatar(data.avatar);
    } catch (error) {}
  };
  obtenerDatosdeCliente(userUid);
  // // console.log(companyBilling)
  const tableroServicios = (rol, propietari) => {
    // if (
    //   rol === process.env.REACT_APP_ADMIN &&
    //   propietari === process.env.REACT_APP_COMPANY_PROP
    // ) {
    //   return <Serviciosmanagement props={props} userUid={userUid} />;
    // }
    // if (
    //   rol === process.env.REACT_APP_ADMIN_XPRESS &&
    //   propietari === process.env.REACT_APP_COMPANY_PROP
    // ) {
    //   return <Serviciosmanagement props={props} userUid={userUid} />;
    // }
    if (propietari === process.env.REACT_APP_COMPANY_PROP) {
      return <Serviciosmanagement props={props} userUid={userUid} />;
    } else {
      return (
        <Serviciositems
          props={props}
          userUid={userUid}
          userBalance={userBalance}
          companyBilling={companyBilling}
        />
      );
    }
    // if (rol === process.env.REACT_APP_CLIENT) {
    //   return (

    // }
  };
  return (
    <div className="backcgroundcolorDev">
      {/* <div></div>
      <div>
        {userRoll === process.env.REACT_APP_ADMIN ? (
          <div>
            <span>'Eliminar Código company_admin'</span>
            <Serviciosmanagement props={props} userUid={userUid} />
          </div>
        ) : (
          <div></div>
        )}
        {userRoll === process.env.REACT_APP_ADMIN ||
        userRoll === process.env.REACT_APP_ADMIN_XPRESS ? (
          <div>
            <span>'Eliminar Código client'</span>
            <Serviciositems
              props={props}
              userUid={userUid}
              userBalance={userBalance}
              companyBilling={companyBilling}
            />{" "}
          </div>
        ) : (
          <div></div>
        )}

        {tableroServicios(userRoll, props.propietari)}
      </div> */}
      <Pos3></Pos3>
    </div>
  );
};

export default Serviciosdash;
