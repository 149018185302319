import React from "react";
import { NavLink } from "react-router-dom";
import { ingresoUsuarioLogin } from "../../redux/usuarioDucks";
import { useDispatch, useSelector } from "react-redux";
import BtnAceceder from "../Login/buttons/BtnAceceder";
import BtnRegistrarme from "../Login/buttons/BtnRegistrarme";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";
import { TextField, Button, Box, Typography } from "@mui/material";

const FormLogin = (props) => {
  const dispatch = useDispatch();

  const resperror = useSelector((store) => store.usuario.error);
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");

  const [error, setError] = React.useState(null);

  const [success, setSuccess] = React.useState(null);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      //    console.log('Ingrese Email')
      setError("Ingrese Email");
      return;
    }
    if (!pass.trim()) {
      //    console.log('Ingrese Contraseña')
      setError("Ingrese Contraseña");
      return;
    }
    if (pass.length < 5) {
      //     console.log('Contraseña mayor a 6 caracteres')
      setError("Contraseña mayor a 6 caracteres");
      return;
    }

    if (!resperror === null) {
      //   console.log('Error de acceso')
      setError("Error de acceso");
      return;
    }

    //   console.log('Validando usuario...')
    setError(null);
    Login();
  };

  const Login = React.useCallback(async () => {
    function delay() {
      setSuccess(null);
      setError(null);
    }
    setTimeout(delay, 1000);
    try {
      dispatch(ingresoUsuarioLogin(email, pass));
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <ContainerViewComponent className="formcontent " id="ajustefromIni">
      <form onSubmit={procesarDatos}>
        {success && (
          <Typography variant="body1" color="success.main" sx={{ mb: 2 }}>
            {success}
          </Typography>
        )}

        {error && (
          <Typography variant="body1" color="error.main" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {resperror && (
          <Typography variant="body1" color="error.main" sx={{ mb: 2 }}>
            {resperror}
          </Typography>
        )}

        <TextField
          fullWidth
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Contraseña"
          type="password"
          variant="outlined"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          sx={{ mb: 2 }}
        />

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#591E8F",
              color: "#fff",
              borderRadius: "5px",
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#25b2f4",
                color: "#591e8f",
              },
            }}
          >
            Entrar
          </Button>
        </Box>
      </form>
      <div>
        <BtnAceceder></BtnAceceder>
        <div>
          <button className="btn btn-nostyle ">
            <NavLink to="/recuperar-cuenta" exact="true">
              Recuperar contraseña
            </NavLink>
          </button>
          <BtnRegistrarme></BtnRegistrarme>
        </div>
      </div>
    </ContainerViewComponent>
  );
};

export default FormLogin;
