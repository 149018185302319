import React from "react";

import { useDispatch } from "react-redux";
import { Box, Button, Card, CardHeader, Divider, Stack } from "@mui/material";
import { Switch, FormControlLabel, Typography } from "@mui/material";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FadeInOut from "../animations/FadeInOut";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncIcon from "@mui/icons-material/Sync";
import SwipeableDrawerControlOrders from "../SwipeableDrawerControlOrders/SwipeableDrawerControlOrders";
import { useEffect } from "react";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";

const FormRouteInfoBicci = (props) => {
  const dispatch = useDispatch();
  const mapRef = React.useRef();

  const [sortedArray, setSortedArray] = React.useState(null);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const [showEditO, setShowEditO] = React.useState(false);
  const toggleShowEditO = () => setShowEditO(!showEditO);

  const [reiniciandoStateServicie, setReiniciandoStateServicie] =
    React.useState(false);

  const [showEditD, setShowEditD] = React.useState(false);
  const toggleShowEditD = () => setShowEditD(!showEditD);

  const [showEditOpcions, setShowEditOpcions] = React.useState(false);
  const toggleShowEditOpctions = () => setShowEditOpcions(!showEditOpcions);

  const [realTimeisActive, setRealTimeisActive] = React.useState(false);

  const [originAdress, setoriginadress] = React.useState(false);

  const [origin, setorigin] = React.useState(false);

  const [destination, setDestination] = React.useState(false);

  const [destinationAdress, setDestinationAdress] = React.useState(false);

  const [localRouteConfig, setRouteConfig] = React.useState({
    upDateByRiderPosition: props.viewConfig.upDateByRiderPosition,
    viewPositionsLabels: props.viewConfig.viewPositionsLabels,
    viewIds: props.viewConfig.viewIds,
    nextStops: props.viewConfig.nextStops,
    alertsEmojis: props.viewConfig.alertsEmojis,
    alertProxmitiPoints: props.viewConfig.alertProxmitiPoints,
    maspStyleUserPreferent: props.viewConfig.maspStyleUserPreferent,
  });

  useEffect(() => {
    if (localRouteConfig) {
      if (localRouteConfig.upDateByRiderPosition === false) {
        props.updateState("origin", { lat: -33.459119, lng: -70.598923 });
      }
      props.setApliConfig(localRouteConfig);
    }
  }, [localRouteConfig]);

  useEffect(() => {
    setSortedArray(props.apiResponseOrderLabelsMenu);
  }, [props && props.apiResponseOrderLabelsMenu]);

  useEffect(() => {}, [localRouteConfig]);

  const handleSubmitFrom = () => {
    props.updateState("origin", origin);
    props.updateState("originAdress", originAdress);
  };
  const handleSubmitFromD = () => {
    props.updateState("destination", destination);
    props.updateState("destinationAdress", destinationAdress);
  };

  // const callReorderLabels = async (
  //   apiResponseOrderLabels,
  //   currentRuteDataDataOrdesBicciByRiderGroups
  // ) => {
  //   let newDataOrdesBicciByRiderGroups = [];
  //   for (let n = 0; n < apiResponseOrderLabels.length; n++) {
  //     let elementsNewOrder =
  //       apiResponseOrderLabels[n].routes[0].waypoint_order;
  //     let DataChange = currentRuteDataDataOrdesBicciByRiderGroups[n];

  //     for (let i = 0; i < elementsNewOrder.length; i++) {
  //       let value = elementsNewOrder.indexOf(i);
  //       DataChange[i].position = value;
  //     }

  //     DataChange.sort((a, b) => a.position - b.position);

  //     newDataOrdesBicciByRiderGroups.push(DataChange);
  //   }

  //   let newDataOrdesBicciByRider = [];
  //   let cont = 1;

  //   for (let x = 0; x < newDataOrdesBicciByRiderGroups.length; x++) {
  //     let grupsElement = newDataOrdesBicciByRiderGroups[x];

  //     for (let y = 0; y < grupsElement.length; y++) {
  //       grupsElement[y].position = cont++;
  //       newDataOrdesBicciByRider.push(grupsElement[y]);
  //     }
  //   }

  //   newDataOrdesBicciByRider.sort((a, b) => a.position - b.position);

  //   setdataOrdesBicciByRider(newDataOrdesBicciByRider);
  //   setApiResponseOrderLabelsMenu(newDataOrdesBicciByRider);
  // };

  const handleSubmitFromOptiosn = () => {
    //console.log("handleSubmitFromOptiosn");
  };

  const Cargando = () => {
    return (
      <div>
        {/* <CircularProgress /> */}

        <ContainerViewComponent id="smallspiner" className="row">
          <div className="center-smallspiner">
            <img
              alt=""
              src="/ebiexlogosvg_animated.svg"
              className="smallspiner rotating"
            />
            <h3>Enviando...</h3>
          </div>
        </ContainerViewComponent>
      </div>
    );
  };

  const changeOption = (e) => {
    // e.preventDefault();
    console.log(e.target.name);

    switch (e.target.name) {
      case "upDateByRiderPosition":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "viewPositionsLabels":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "viewIds":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "nextStops":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "alertsEmojis":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "alertProxmitiPoints":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      case "maspStyleUserPreferent":
        setRouteConfig({
          ...localRouteConfig,
          [e.target.name]: !localRouteConfig[e.target.name],
        });
        break;
      default:
        break;
    }
  };

  // console.log(localRouteConfig);
  return reiniciandoStateServicie !== true ? (
    <ContainerViewComponent id="-top" className="-top-routes">
      <Card
        className="typeMiniforms-routes"
        sx={{
          margintop: "5px",
          borderTopLeftRadius: "3px",
          borderTopRightRadius: "3px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          backgroundColor: "transparent",
          background: "transparent",
        }}
      >
        import {(Box, Button, Stack)} from "@mui/material"; import SyncIcon from
        "@mui/icons-material/Sync"; import SettingsIcon from
        "@mui/icons-material/Settings"; import ModeEditIcon from
        "@mui/icons-material/ModeEdit"; import CheckCircleOutlineIcon from
        "@mui/icons-material/CheckCircleOutline"; import
        SwipeableDrawerControlOrders from "./SwipeableDrawerControlOrders"; //
        Asegúrate de importar correctamente este componente
        <Box component="form">
          <Stack
            id="topControlRoutes"
            direction="row"
            spacing={0}
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            {/* Botón para recargar */}
            <Button
              className="btn-control-route"
              color={props.originadress ? "success" : "secondary"}
              variant="contained"
              onClick={props.handleReloadClick}
              sx={{
                flex: 1,
                minHeight: "50px",
                borderRadius: 0,
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              <SyncIcon />
            </Button>

            {/* Botón de configuración/edición */}
            <Button
              className="btn-control-route"
              color={props.originadress ? "success" : "secondary"}
              variant="contained"
              onClick={toggleShowEditOpctions}
              sx={{
                flex: 1,
                minHeight: "50px",
                borderRadius: 0,
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              {props.originadress ? <SettingsIcon /> : <ModeEditIcon />}
            </Button>

            {/* Botón de edición de origen */}
            <Button
              className="btn-control-route"
              color={props.originadress ? "success" : "secondary"}
              variant="contained"
              onClick={toggleShowEditO}
              sx={{
                flex: 1,
                minHeight: "50px",
                borderRadius: 0,
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              {props.originadress ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ModeEditIcon />
              )}
              Origen
            </Button>

            {/* Botón de edición de destino */}
            <Button
              className="btn-control-route"
              color={props.destinationadress ? "success" : "secondary"}
              onClick={toggleShowEditD}
              variant="contained"
              sx={{
                flex: 1,
                minHeight: "50px",
                borderRadius: 0,
                backgroundColor: "#1e2158",
                fontSize: "0.7em",
              }}
            >
              {props.destinationadress ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ModeEditIcon />
              )}
              Destino
            </Button>

            {/* Control de órdenes */}
            <SwipeableDrawerControlOrders
              dataordesbiccibyrider={props.dataOrdesBicciByRider}
              originadress={props.originadress}
              destinationadress={props.destinationadress}
              dataordersexcepcions={props.dataordersexcepcions}
              dataOrderDeliverySusses={props.dataOrderDeliverySusses}
            />
          </Stack>
        </Box>
        <FadeInOut show={showEditOpcions} duration={500} parentName="fade13">
          <Card>
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Seleccione configuraciones de vistas.</>}
              title={<div>Ajustes personales</div>}
            />
            <Divider />

            <ContainerViewComponent
              className="formcontent routesfromIni"
              id="containerroutesfromIni"
            >
              <Box
                component="form"
                onSubmit={handleSubmitFromOptiosn}
                sx={{ p: 2 }}
              >
                {localRouteConfig && (
                  <>
                    <Stack spacing={2}>
                      {/* Opciones principales */}
                      <Box>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                          Opciones de Configuración
                        </Typography>
                        <Stack spacing={1}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="upDateByRiderPosition"
                                name="upDateByRiderPosition"
                                checked={localRouteConfig.upDateByRiderPosition}
                                onChange={changeOption}
                              />
                            }
                            label="Actualizar desde mi posición"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                id="viewPositionsLabels"
                                name="viewPositionsLabels"
                                checked={localRouteConfig.viewPositionsLabels}
                                onChange={changeOption}
                              />
                            }
                            label="Ver Posiciones"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                id="viewIds"
                                name="viewIds"
                                checked={localRouteConfig.viewIds}
                                onChange={changeOption}
                              />
                            }
                            label="Ver Id de paquete"
                          />
                        </Stack>
                      </Box>

                      {/* Opciones adicionales */}
                      <Box>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                          Configuración Avanzada
                        </Typography>
                        <Stack spacing={1}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="nextStops"
                                name="nextStops"
                                checked={localRouteConfig.nextStops}
                                onChange={changeOption}
                              />
                            }
                            label="Ver sugerencias de próxima parada"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                id="alertsEmojis"
                                name="alertsEmojis"
                                checked={localRouteConfig.alertsEmojis}
                                onChange={changeOption}
                              />
                            }
                            label="Habilitar alertas, proximidad a punto de entrega"
                          />
                        </Stack>
                      </Box>

                      {/* Botones de acción */}
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                        sx={{ mt: 2 }}
                      >
                        <Button
                          sx={{
                            borderRadius: "3px",
                            backgroundColor: "#591e8f",
                            color: "#fff",
                            height: "30px",
                          }}
                          type="submit"
                          variant="contained"
                          onClick={() => setShowEditOpcions(false)}
                        >
                          Guardar!
                        </Button>
                        <Button
                          sx={{ color: "#F33107", height: "30px" }}
                          onClick={() => setShowEditOpcions(false)}
                        >
                          Cancelar
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                )}
              </Box>
            </ContainerViewComponent>

            <Divider />
          </Card>
        </FadeInOut>
        <FadeInOut show={showEditO} duration={500} parentName="fade14">
          <Card>
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Origen actual.</>}
              title={<div>{props.originadress} </div>}
            />
            <Divider />

            <ContainerViewComponent
              className="formcontent routesfromIni"
              id="containerroutesfromIni"
            >
              <hr></hr>

              <Box component="form" onSubmit={handleSubmitFrom} sx={{ p: 2 }}>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    Usar mi posición actual
                  </Typography>

                  {/* Campo de búsqueda */}
                  <SearchOrigin
                    panTo={panTo}
                    setoriginadress={setoriginadress}
                    setorigin={setorigin}
                    dispatch={dispatch}
                    props={props}
                    id="imputSearchFromOrigen"
                  />

                  {/* Botones de acción */}
                  <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button
                      sx={{
                        borderRadius: "3px",
                        backgroundColor: "#591e8f",
                        color: "#fff",
                      }}
                      type="submit"
                      variant="contained"
                      onClick={() => setShowEditO(false)}
                    >
                      Confirmar Origen!
                    </Button>
                    <Button
                      sx={{ color: "#F33107" }}
                      onClick={() => setShowEditO(false)}
                    >
                      Cancelar
                    </Button>
                  </Stack>
                </Stack>

                {/* Espacio adicional */}
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
                />
              </Box>
            </ContainerViewComponent>

            <Divider />
          </Card>
        </FadeInOut>
        <FadeInOut show={showEditD} duration={500} parentName="fade15">
          <Card
            className="typeMiniforms-routes"
            sx={{
              margintop: "5px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <CardHeader
              sx={{
                padding: "5px",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
              subheader={<>Destino actual.</>}
              title={<div>{props.destinationadress} </div>}
            />
            <Divider />

            <ContainerViewComponent
              className="formcontent  routesfromIni"
              id="containerroutesfromIorig"
            >
              <hr></hr>

              <Box component="form" onSubmit={handleSubmitFromD} sx={{ p: 2 }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  {/* Campo de búsqueda */}
                  <Search
                    panTo={panTo}
                    dispatch={dispatch}
                    props={props}
                    setDestination={setDestination}
                    setDestinationAdress={setDestinationAdress}
                    id="handleSubmitFromD"
                  />

                  {/* Botón de confirmar */}
                  <Button
                    sx={{
                      borderRadius: "3px",
                      backgroundColor: "#591e8f",
                      color: "#fff",
                    }}
                    type="submit"
                    variant="contained"
                    onClick={() => setShowEditD(false)}
                  >
                    Confirmar destino!
                  </Button>

                  {/* Botón de cancelar */}
                  <Button
                    sx={{ color: "#F33107" }}
                    variant="text"
                    onClick={() => setShowEditD(false)}
                  >
                    Cancelar
                  </Button>
                </Stack>

                {/* Espacio adicional si se necesita */}
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
                />
              </Box>
            </ContainerViewComponent>

            <Divider />
          </Card>
        </FadeInOut>
        <Card
          className="typeMiniforms-routes"
          sx={{
            margintop: "5px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          {localRouteConfig.nextStops === true ? (
            <>
              <CardHeader
                sx={{
                  padding: "5px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                }}
                subheader={<>Sugerencia de proxima parada.</>}
                title={
                  <div>
                    {" "}
                    {sortedArray && sortedArray.length > 0 ? (
                      <div>
                        <div>{sortedArray[0].id}</div>
                        <div>{sortedArray[0].toAddress}</div>
                      </div>
                    ) : null}
                  </div>
                }
              />
              <Divider />

              <ContainerViewComponent sx={{ maxHeight: "50px" }}>
                <hr></hr>
              </ContainerViewComponent>

              <Divider />
            </>
          ) : null}
        </Card>
      </Card>
    </ContainerViewComponent>
  ) : (
    <div>
      <Cargando />
    </div>
  );
};

function Search({
  panTo,
  dispatch,
  props,
  setDestination,
  setDestinationAdress,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      setDestinationAdress(address);

      const { lat, lng } = await getLatLng(results[0]);
      setDestination({ lat: lat, lng: lng });
      //panTo({ lat: lat, lng: lng });
    } catch (error) {
      console.log("😱 Error: ", error);
      // console.log(element);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Escribe una dirección de destino"
        />

        <ComboboxPopover className="comboboxList-routes-direcctiones">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
function SearchOrigin({ panTo, dispatch, props, setorigin, setoriginadress }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      setoriginadress(address);
      const { lat, lng } = await getLatLng(results[0]);
      setorigin({ lat: lat, lng: lng });
      //panTo({ lat: lat, lng: lng });
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element);
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Escribe una dirección de origen"
        />

        <ComboboxPopover className="comboboxList-routes-direcctiones">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default FormRouteInfoBicci;
