import { getFarthestPoint } from "./getFarthestPoint";

export const generateManualRoutingData = async (
  manualRoutes,
  dataMerge,
  origen,
  destino
) => {
  if (!Array.isArray(manualRoutes) || manualRoutes.length === 0) {
    console.error("⛔ No se han asignado rutas manualmente.");
    return [];
  }
  if (!Array.isArray(dataMerge) || dataMerge.length === 0) {
    console.error("⛔ No hay órdenes disponibles para asignar.");
    return [];
  }

  const reqApiOptimizationData = [];

  manualRoutes.forEach((routeName) => {
    // Filtramos las órdenes asignadas manualmente a cada ruta
    const assignedOrders = dataMerge.filter(
      (order) => order.driverLicensePlate === routeName
    );

    if (assignedOrders.length === 0) {
      console.warn(`⚠️ La ruta ${routeName} no tiene órdenes asignadas.`);
      return;
    }

    // Validamos el origen
    let validOrigen =
      origen && typeof origen.lat === "number" && typeof origen.lng === "number"
        ? origen
        : {
            id: `origen`,
            name: "",
            lat: assignedOrders[0]?.fromAddressLocation?._long || 0,
            lon: assignedOrders[0]?.fromAddressLocation?._lat || 0,
          };

    // Validamos el destino
    let validDestino;
    if (
      destino &&
      typeof destino.lat === "number" &&
      typeof destino.lng === "number"
    ) {
      validDestino = {
        id: `destino`,
        name: "",
        lat: destino.lat,
        lng: destino.lng,
      };
    } else {
      //let determineDestini = getFarthestPoint(validOrigen, assignedOrders);

      validDestino = {
        id: `destino`,
        name: "",
        lat: validOrigen.lat, // ✅ Se usa `lon`, no `lng`
        lon: validOrigen.lon,
      };
    }

    // console.log("validDestino", validDestino);

    // console.log("determineDestini", validDestino);

    // Definir la estructura de los waypoints
    const waypoints = assignedOrders.map((order, index) => ({
      id: order.id,
      name: "",
      lat: order.toAddressLocation?._long || 0,
      lon: order.toAddressLocation?._lat || 0,
    }));

    // Generar el payload de optimización basado en la ruta manual
    const routeOptimizationPayload = {
      vehicle: "driving",
      route: routeName,
      waypoints: [validOrigen, ...waypoints, validDestino], // Se agregan origen y destino
    };

    reqApiOptimizationData.push(routeOptimizationPayload);
  });

  return reqApiOptimizationData;
};
