import React, { useContext, useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  Box,
  Card,
  Grid2,
  CardHeader,
  Typography,
  CardActions,
  Button,
  Stack,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  Chip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import RouteIcon from "@mui/icons-material/Route";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../components/CustomDateRangePicker";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext";
import Moment from "moment";
import EventIcon from "@mui/icons-material/Event";
import { collection, getDocs, query, where } from "firebase/firestore";
import { fDate } from "../../../utils/objects/FormatTime";
import { OriginsCompanys } from "../../../utils/objects/OriginsCompanys";
import generarReporteBicciDetalle from "../../../utils/Reports/generarReporteBicciDetalle";
import generarReporteTurnoConductoresUtils from "../../../utils/Reports/generarReporteTurnoConductoresUtils";
import generarReporteTurnoRutasUtils from "../../../utils/Reports/generarReporteTurnoRutasUtils";
import generarReportePickingUtils from "../../../utils/Reports/generarReportePickingUtils";
import generarReporteTurnoPdfUtils from "../../../utils/Reports/generarReporteTurnoPdfUtils";
import generarReporteTurnoIncidenciasUtils from "../../../utils/Reports/generarReporteTurnoIncidenciasUtils";
import FleetResumenTable from "../Fleet/FleetResumenTable";
import CierreOpsPdf from "../Guias/CierreOpsPdf";
import { PDFViewer } from "@react-pdf/renderer";
import * as utils from "../../../utils/objects/BicciExpressOrderStatuses";
import useGlobalConfig from "../../../hooks/globalConfig";
import { UserContext } from "../../../context/CurrentUserProvider";
import { CustomDateRangePickerStyle } from "../../../components/CustomDateRangePicker/CustomDateRangePickerStyle";
import ToolFiltersComponent from "../../../components/Dashboard/dashboard/ToolFiltersComponent/ToolFiltersComponent";

const Reports = () => {
  const { userDataContext } = useContext(UserContext);

  //-- Nuevas Variables

  const { VehiclesNames } = useGetVehiclesNames();
  const [progressValue, setProgressValue] = useState(0);
  const [messajeAdmin, setMenssajeAdmin] = useState("");
  const [metricsOps, setMetricsOps] = useState([]);
  const [metricsOpsRoutes, setMetricsOpsRoutes] = useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);
  const [ridersDataByCompany, setRidersDataByCompany] = useState([]);
  const [localMetrcis, setLocalMetrics] = React.useState(null);

  const [companiesOrigin, setCompaniesOrigin] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = React.useState([]);

  const [selectedOrigins, setSelectedOrigins] = React.useState([]);

  const [filteredCompanies, setFilteredCompanies] = React.useState([]);

  const [filteredOrigins, setFilteredOrigins] = React.useState([]);

  const [viewMergedOrders, setViewMergedOrders] = useState([]);

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  //---

  const listItems = utils.listItems;

  const { rosettaVehicles, translateStateBicciFalabella, falabellaExcepcions } =
    useGlobalConfig();

  const [cantListItems, setcantListItems] = useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
    listenStart,
    listenStop,
    setShouldSubscribe,
    excludePendings,
    setExcludePendings,
  } = useOrders({ initDateInitial, endDateInitial });

  useEffect(() => {
    setShouldSubscribe(true);
  }, []);

  useEffect(() => {
    //console.log(mergedOrders);
    if (mergedOrders) {
      //setViewMergedOrders(mergedOrders);
      applyFilters(selectedCompanies, selectedOrigins);
    }
  }, [mergedOrders, selectedCompanies, selectedOrigins]);

  useEffect(() => {
    if (viewMergedOrders) {
      createSelectorOrigins(viewMergedOrders);
    }
  }, [viewMergedOrders]);

  useEffect(() => {
    if (companiesData) {
      setFilteredCompanies(companiesData);
    }
  }, [companiesData]);

  useEffect(() => {
    if (companiesOrigin.length > 0) {
      setFilteredOrigins(companiesOrigin);
    }
  }, [companiesOrigin]);

  useEffect(() => {}, [excludePendings]);

  useEffect(() => {
    if (mergedOrders && mergedOrders.length) {
      // Asegúrate de que los driverID sean únicos
      const uniqueDriverIDs = Array.from(
        new Set(
          mergedOrders
            .filter((order) => order.driverID && order.driverID.trim() !== "")
            .map((order) => order.driverID)
        )
      );

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };

      const driverIDChunks = chunkArray(uniqueDriverIDs, 10);

      const fetchDriverData = async () => {
        let dataResult = [];

        for (let chunk of driverIDChunks) {
          const querySnapshot = await getDocs(
            query(
              collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`),
              where("id", "in", chunk)
            )
          );

          querySnapshot.forEach((doc) => {
            const driverData = { id: doc.id, ...doc.data() };
            // Agregar solo si no está ya presente
            if (!dataResult.some((driver) => driver.id === doc.id)) {
              dataResult.push(driverData);
            }
          });
        }

        setRidersDataByCompany(dataResult);
      };

      fetchDriverData();
    }
  }, [mergedOrders]);

  useEffect(() => {
    if (mergedOrders && ridersDataByCompany && VehiclesNames) {
      getResumenOps(mergedOrders);
      getResumenRoutes(mergedOrders);
    }
  }, [ridersDataByCompany]);

  useEffect(() => {
    if (metricsOps) {
      callMetrics(metricsOps);
    }
  }, [metricsOps]);

  const getResumenOps = async (dataMerge) => {
    setProgressValue(75);
    setMenssajeAdmin(
      <span className="alert alert-info">Creando resumen de operación... </span>
    );

    if (!dataMerge || dataMerge.length === 0) {
      setProgressValue(100);
      setMetricsOps([]);
      setMetricsOpsStores([]);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "NO se encuentran envíos para la fecha Solicitada"
        </span>
      );
      return;
    }

    try {
      // Extraer ridersDataByCompany sin duplicados y sin driverID vacío o indefinido
      const listRiders = [...new Set(dataMerge.map((order) => order.driverID))];
      const ListStores = [
        ...new Set(dataMerge.map((order) => order.placeName)),
      ];

      // Esta función agrupa las órdenes por tienda y calcula métricas
      const getOrdersByLocal = (data, ListStores) => {
        setProgressValue(55);
        const orders = data.filter(
          (order) => order.falabella_status === "99" && order.status !== "99"
        );

        const listStoreOriging = orders.reduce((acc, order) => {
          const local = order.placeName;
          if (!acc[local]) {
            acc[local] = {
              localName: ListStores.includes(local) ? local : null,
              delivered: 0,
              withIssues: 0,
            };
          }
          if (order.status === 8) {
            acc[local].withIssues++;
          } else {
            acc[local].delivered++;
          }
          return acc;
        }, {});

        return Object.values(listStoreOriging);
      };

      const listStoreOriging = getOrdersByLocal(dataMerge, ListStores);

      const riderMap = ridersDataByCompany.reduce((acc, rider) => {
        acc[rider.id] = rider.vehicleID;
        return acc;
      }, {});

      const vehicleNameMap = VehiclesNames.reduce((acc, vehicle) => {
        acc[vehicle.id] = vehicle.name;
        return acc;
      }, {});

      const vehicleRidersMap = Object.keys(riderMap).reduce((acc, driverID) => {
        const vehicleID = riderMap[driverID];
        const rider = ridersDataByCompany.find(
          (rider) => rider.id === driverID
        );
        if (rider && vehicleNameMap[vehicleID]) {
          acc[rider.id] = vehicleNameMap[vehicleID];
        }
        return acc;
      }, {});

      const listRidersJsonString = dataMerge.reduce((acc, order) => {
        const driverID = order.driverID || "sinAsignacion";

        if (!acc[driverID]) {
          acc[driverID] = {
            driverName: order.driverName || "sinAsignacion",
            driverID: order.driverID || "sinAsignacion",
            email: order.email || "",
            driverAvatar: order.driverAvatar || "sinAsignacion",
            asignados: 0,
            delivery: 0,
            issures: 0,
            vehicleDetailsType: vehicleRidersMap[driverID] || "No registrado",
            vehicleDetailsID: vehicleRidersMap[driverID] || "No registrado",
            locality: [], // Puedes omitir esto si `commune2` no existe
            countersListOrders: [],
            countersIssuresListOrders: [],
            listOrdersData: { data: [] },
            listOrdersIssuresData: { data: [] },
          };
        }

        acc[driverID].asignados++;
        acc[driverID].countersListOrders.push(order.id);
        acc[driverID].listOrdersData.data.push(order);
        if (order.status === 8) {
          acc[driverID].delivery++;
        } else {
          acc[driverID].issures++;
          acc[driverID].countersIssuresListOrders.push(order.id);
          acc[driverID].listOrdersIssuresData.data.push(order);
        }

        // Omitir lógica de `commune2` si no existe
        if (order.commune2) {
          const comunaIndex = acc[driverID].locality.findIndex(
            (comuna) => Object.keys(comuna)[0] === order.commune2
          );

          if (comunaIndex >= 0) {
            acc[driverID].locality[comunaIndex][order.commune2]++;
          } else {
            const newComuna = { [order.commune2]: 1 };
            acc[driverID].locality.push(newComuna);
          }
        }

        return acc;
      }, {});

      setMetricsOps(Object.values(listRidersJsonString));
      setMetricsOpsStores(listStoreOriging);
      setProgressValue(100);
      setMenssajeAdmin(null);
    } catch (error) {
      console.log(error);
      setProgressValue(100);
      setMenssajeAdmin(
        <span className="alert alert-danger">
          "Error al crear el resumen de operación"
        </span>
      );
    }
  };

  const getResumenRoutes = async (dataMerge) => {
    if (!dataMerge || dataMerge.length === 0) {
      setMetricsOpsRoutes([]);
      return;
    }

    try {
      // Agrupación de órdenes por ruta
      const getOrdersByRoute = (data) => {
        return data.reduce((acc, order) => {
          // Obtener la ruta (placa del vehículo o asignar 'noRoute' si no hay)
          const route = order.driverLicensePlate || "noRoute";

          // Inicializamos las métricas de la ruta si no existe en el acumulador
          if (!acc[route]) {
            acc[route] = {
              route,
              delivered: 0, // Órdenes entregadas con éxito
              withIssues: 0, // Órdenes con incidencias
              assigned: 0, // Órdenes asignadas
              pending: 0, // Órdenes pendientes
              finalized: 0, // Órdenes finalizadas
              NS: 0, // No Show
              comunas: {}, // Comunas relacionadas
              countersListOrders: [], // Lista de órdenes asignadas
              countersIssuresListOrders: [], // Órdenes con incidencias
              listOrdersData: { data: [] }, // Detalles de órdenes entregadas
              listOrdersIssuresData: { data: [] }, // Detalles de incidencias
            };
          }

          // Incrementar el total de órdenes asignadas
          acc[route].assigned++;

          // Clasificación de las órdenes según su estado
          if (order.status === 8) {
            // Si el estado es 8, se considera una entrega exitosa
            acc[route].delivered++; // Incrementar entregados
            acc[route].listOrdersData.data.push(order); // Agregar a entregados
          } else {
            // Si no es 8, se considera que tiene incidencias
            acc[route].withIssues++; // Incrementar incidencias
            acc[route].listOrdersIssuresData.data.push(order); // Agregar a la lista de incidencias
          }

          // Estado específico según `falabella_status` para pendientes, finalizados, y NS
          if (order.falabella_status === "some_pending_status_code") {
            acc[route].pending++;
          } else if (order.falabella_status === "some_finalized_status_code") {
            acc[route].finalized++;
          } else if (order.falabella_status === "some_ns_status_code") {
            acc[route].NS++;
          }

          // Manejo de comunas (si `commune2` está disponible)
          const comuna = order.commune2;
          if (comuna) {
            if (!acc[route].comunas[comuna]) {
              acc[route].comunas[comuna] = 1;
            } else {
              acc[route].comunas[comuna]++;
            }
          }

          // Agregar la orden a los contadores
          acc[route].countersListOrders.push(order.id);
          if (order.status !== 8) {
            acc[route].countersIssuresListOrders.push(order.id);
          }

          return acc;
        }, {});
      };

      // Obtener las métricas agrupadas por rutas
      const listRoutesData = getOrdersByRoute(dataMerge);

      // Establecer las métricas de las rutas en el estado
      setMetricsOpsRoutes(Object.values(listRoutesData));
    } catch (error) {
      console.log(error);
    }
  };

  const callMetrics = (data) => {
    var totalRider = 0;
    var totalVehicles = [];
    var totalAssing = 0;
    var totalDelivery = 0;
    var totalPending = 0;
    var ntsPerformanse = 0;
    var totalZones = [];
    var listOfRiders = [];

    // Itera sobre los datos
    for (var i = 0; i < data.length; i++) {
      if (data[i].driverName !== "") {
        totalRider++;
        totalAssing += data[i].asignados;
        totalDelivery += data[i].delivery;
        totalPending += data[i].issures;
        listOfRiders.push(data[i].driverName);
      } else {
        totalPending += data[i].issures;
      }

      totalVehicles.push(data[i].vehicleDetailsType);

      // Asegúrate de que data[i].locality esté definido antes de empujarlo a totalZones
      if (data[i].locality) {
        totalZones.push(data[i].locality);
      }
    }

    ntsPerformanse = ((totalDelivery * 100) / totalAssing).toFixed(2);

    var allVehiclesNames = [];
    var allVehiclesConter = {};

    // Procesa los vehículos
    for (let a = 0; a < totalVehicles.length; a++) {
      if (!allVehiclesNames.includes(totalVehicles[a])) {
        allVehiclesNames.push(totalVehicles[a]);
        allVehiclesConter[totalVehicles[a]] = { cant: 0 };
      }
    }

    for (let a = 0; a < allVehiclesNames.length; a++) {
      for (let q = 0; q < totalVehicles.length; q++) {
        if (allVehiclesNames[a] === totalVehicles[q]) {
          allVehiclesConter[totalVehicles[q]].cant += 1;
        }
      }
    }

    var allZonesNames = [];
    var conterAllZonesGrousp = {};

    // Procesa las zonas
    for (let x = 0; x < totalZones.length; x++) {
      // Asegúrate de que totalZones[x] es un array antes de iterar sobre él
      if (Array.isArray(totalZones[x])) {
        for (let n = 0; n < totalZones[x].length; n++) {
          var tempData = Object.keys(totalZones[x][n]);

          if (!allZonesNames.includes(tempData[0])) {
            allZonesNames.push(tempData[0]);
            let zoneValue = Object.values(totalZones[x][n]);
            conterAllZonesGrousp[Object.keys(totalZones[x][n])] = parseInt(
              zoneValue[0]
            );
          } else {
            if (conterAllZonesGrousp[tempData[0]]) {
              let subTotalZoneValue = conterAllZonesGrousp[tempData[0]];
              let zoneValue = Object.values(totalZones[x][n]);
              conterAllZonesGrousp[tempData[0]] =
                parseInt(subTotalZoneValue) + parseInt(zoneValue[0]);
            }
          }
        }
      }
    }

    let cantTotalVehicles = Object.values(allVehiclesConter);
    let cantTotalZones = allZonesNames.length;

    let tempNames = Object.keys(allVehiclesConter);
    let tempValues = Object.values(allVehiclesConter);
    let tempArry = [];
    for (let y = 0; y < tempNames.length; y++) {
      let obj = { name: tempNames[y], value: tempValues[y] };
      tempArry.push(obj);
    }

    //setTotalArrayVehicles(tempArry);

    let tempNamesComunas = Object.keys(conterAllZonesGrousp);
    let tempValuesComunas = Object.values(conterAllZonesGrousp);
    let tempArrayZones = [];
    for (let y = 0; y < tempNamesComunas.length; y++) {
      let obj = { name: tempNamesComunas[y], value: tempValuesComunas[y] };
      tempArrayZones.push(obj);
    }

    //setTotalArrayComunas(tempArrayZones);

    let objeMetric = {
      totalRider: totalRider,
      totalVehicles: tempArry,
      cantTotalVehicles: cantTotalVehicles.length,
      totalAssing: totalAssing,
      totalDelivery: totalDelivery,
      totalPending: totalPending,
      ntsPerformanse: ntsPerformanse,
      totalZones: conterAllZonesGrousp,
      cantTotalZones: cantTotalZones,
      listOfRiders: listOfRiders,
    };

    let renderData = Object.entries(objeMetric);

    setLocalMetrics(objeMetric);
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    onChageRangePiker(createDate, FinalDate);
  };

  if (
    mergedOrders.length > 0 &&
    metricsOps.length > 0 &&
    localMetrcis &&
    ridersDataByCompany.length > 0 &&
    VehiclesNames.length > 0
  ) {
    // console.log(rangeInputPicker);
    // console.log(mergedOrders.length);
    // console.log(metricsOps);
    // console.log(metricsOpsRoutes);
    // console.log(localMetrcis);
    // console.log(ridersDataByCompany.length);
    // console.log(VehiclesNames);
  }

  const handleChange = (event) => {
    setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    setSelectedOrigins(event.target.value);
  };

  const createSelectorOrigins = (dataOrdes) => {
    let placeNames = [];
    mergedOrders.forEach((items) => {
      if (!placeNames.some((place) => place.placeName === items.placeName)) {
        placeNames.push({ placeName: items.placeName });
      }
    });

    setCompaniesOrigin(placeNames);
  };

  const applyFilters = (selectedCompanies, selectedOrigin) => {
    const ExternalIntegrationsOrigins = ["falabella", "firo"];

    let tempView = mergedOrders;

    // Filtro para las compañías seleccionadas
    if (selectedCompanies.length > 0) {
      // Crear una lista temporal que combine los resultados de las diferentes compañías seleccionadas
      let filteredByCompanies = [];

      selectedCompanies.forEach((companyID) => {
        // Si la compañía está en ExternalIntegrationsOrigins, filtrar por orígenes
        if (ExternalIntegrationsOrigins.includes(companyID)) {
          if (OriginsCompanys[companyID]) {
            let tempOrigins = OriginsCompanys[companyID];
            let companyFiltered = tempView.filter((items) =>
              tempOrigins.includes(items.placeName)
            );
            filteredByCompanies = filteredByCompanies.concat(companyFiltered);
          }
        } else {
          // Si la compañía no está en ExternalIntegrationsOrigins, filtrar por companyID
          let companyFiltered = tempView.filter((items) => {
            return items.companyID === companyID;
          });
          filteredByCompanies = filteredByCompanies.concat(companyFiltered);
        }
      });

      // Actualizamos tempView con el conjunto de resultados acumulados
      tempView = filteredByCompanies;
    }

    // Aplicar filtro por origen si hay alguno seleccionado
    if (selectedOrigin.length > 0) {
      tempView = tempView.filter((items) =>
        selectedOrigin.includes(items.placeName)
      );
    }

    // Actualizar las vistas con los resultados filtrados
    setViewMergedOrders(tempView);
  };

  const setViewFilterMergedOrdersOrigins = (selectedOrigin) => {
    applyFilters(selectedCompanies, selectedOrigin);
  };

  // Reportes:

  const generarReporteTurno = () => {
    generarReporteBicciDetalle(viewMergedOrders, ridersDataByCompany, initDate);
  };

  const generarReporteTurnoConductores = () => {
    generarReporteTurnoConductoresUtils(metricsOps, ridersDataByCompany);
  };

  const generarReporteTurnoRutas = () => {
    generarReporteTurnoRutasUtils(metricsOpsRoutes, ridersDataByCompany);
  };

  const exportartPicking = () => {
    generarReportePickingUtils(initDate, endDate);
  };

  const exportartReporteTurnos = () => {
    generarReporteTurnoPdfUtils(
      viewMergedOrders,
      ridersDataByCompany,
      initDate
    );
  };

  const exportartReporteInxidencias = () => {
    generarReporteTurnoIncidenciasUtils(metricsOps, ridersDataByCompany);
  };

  return (
    <Box sx={{ backgroundColor: "#f8f8f8", minHeight: "90vh" }}>
      <Grid2
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
          backgroundColor: "#FFF",
          padding: "10px",
        }}
      >
        <Grid2>
          <ToolFiltersComponent
            selectedCompanies={selectedCompanies}
            selectedOrigins={selectedOrigins}
            filteredCompanies={filteredCompanies}
            filteredOrigins={filteredOrigins}
            handleChange={handleChange}
            handleOrigins={handleOrigins}
            rangeInputPicker={rangeInputPicker}
            setNewDate={setNewDate}
            viewRangeInputPicker={true}
          ></ToolFiltersComponent>
        </Grid2>
        <Grid2 xs={12}>
          <Grid2 xs={12} sx={{ textAlign: "right", m: 1 }}>
            <Typography
              variant="body"
              sx={{ color: "#030303", fontSize: "1en", fontWeight: 500 }}
            >
              {viewMergedOrders.length} en consulta actual
            </Typography>
          </Grid2>
          <Grid2 xs={12}>
            <Typography
              variant="body"
              sx={{ color: "#030303", fontSize: "18px", fontWeight: 500, m: 1 }}
            >
              Reportes :
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2
          container
          item
          xs={12}
          spacing={2}
          justifyContent="center"
          sx={{
            width: "100%",
            backgroundColor: "#FFF",
          }}
        >
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ width: "350px", maxWidth: "450px", m: 1 }}>
              <CardHeader
                title={
                  <>
                    <FactCheckIcon /> Reporte de detallado
                  </>
                }
              />
              <CardContent>
                Muestra un informe detallado con información completa sobre las
                operaciones realizadas por la empresa. Permite descargar el
                reporte para análisis adicional.
              </CardContent>
              <CardActions>
                <Button onClick={() => generarReporteTurno()}>Descargar</Button>
              </CardActions>
            </Card>
          </Grid2>
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ width: "350px", maxWidth: "450px", m: 1 }}>
              <CardHeader
                title={
                  <>
                    <DirectionsBikeIcon /> Reporte de Conductores
                  </>
                }
              />
              <CardContent>
                Proporciona un resumen o informe sobre los conductores
                asignados, con detalles de sus actividades durante el período
                seleccionado. Disponible para descarga.
              </CardContent>
              <CardActions>
                <Button onClick={() => generarReporteTurnoConductores()}>
                  Descargar
                </Button>
              </CardActions>
            </Card>
          </Grid2>
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ width: "350px", maxWidth: "450px", m: 1 }}>
              <CardHeader
                title={
                  <>
                    <RouteIcon /> Reporte de Rutas
                  </>
                }
              />
              <CardContent>
                Incluye un análisis de las rutas realizadas, con información de
                los trayectos y destinos cubiertos por los conductores. Opción
                para descargar el informe.
              </CardContent>
              <CardActions>
                <Button onClick={() => generarReporteTurnoRutas()}>
                  Descargar
                </Button>
              </CardActions>
            </Card>
          </Grid2>
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ width: "350px", maxWidth: "450px", m: 1 }}>
              <CardHeader
                title={
                  <>
                    <QrCodeScannerIcon /> Reporte de Picking
                  </>
                }
              />
              <CardContent>
                Ofrece un reporte relacionado con las actividades de picking,
                detallando los elementos seleccionados y preparados para el
                envío. El informe está disponible para descarga.
              </CardContent>
              <CardActions>
                <Button onClick={() => exportartPicking()}>Descargar</Button>
              </CardActions>
            </Card>
          </Grid2>
          {/* <Grid2 xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ width: "350px", maxWidth: "450px", m: 1 }}>
              <CardHeader
                title={
                  <>
                    <WorkHistoryIcon /> Reporte turno
                  </>
                }
              />
              <CardContent>
                Proporciona un resumen de los turnos realizados, con detalles
                sobre el personal y las horas trabajadas. Se puede descargar el
                informe.
              </CardContent>
              <CardActions>
                <Button onClick={() => setWiewPrePdf(!wiewPrePdf)}>
                  Ver documento
                </Button>
              </CardActions>
            </Card>
          </Grid2> */}
          <Grid2 xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ width: "350px", maxWidth: "450px", m: 1 }}>
              <CardHeader
                title={
                  <>
                    <WorkHistoryIcon /> Reporte Incidencias
                  </>
                }
              />
              <CardContent>
                Informa sobre las incidencias o problemas reportados durante las
                operaciones, ofreciendo detalles relevantes. El informe está
                disponible para su descarga.
              </CardContent>
              <CardActions>
                <Button onClick={() => exportartReporteInxidencias()}>
                  Descargar
                </Button>
              </CardActions>
            </Card>
          </Grid2>
        </Grid2>
      </Grid2>
      {wiewPrePdf && metricsOps && metricsOpsStores ? (
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          <CierreOpsPdf
            items={listItems}
            cant={cantListItems}
            metricsOps={metricsOps}
            metricsOpsStores={metricsOpsStores}
            rosettaVehicles={rosettaVehicles}
            userUid={userDataContext.userUid}
            initDate={initDate}
            endDate={endDate}
            typeReport={"HD"}
          ></CierreOpsPdf>
        </PDFViewer>
      ) : null}
    </Box>
  );
};

export default Reports;
