import React, { useState } from "react";
import {
  Paper,
  Typography,
  ButtonGroup,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";

import ButtonHandleOpenPlansDialog from "./ButtonHandleOpenPlansDialog";
import ButonhandleCombineWithRoutes from "./ButonhandleCombineWithRoutes";
import ButonHandleFilterDialog from "./ButonHandleFilterDialog";
import ButonOptimizeDialog from "./ButonOptimizeDialog";
import ButonHandleOpenSaveDialog from "./ButonHandleOpenSaveDialog";
import ButonRutearDialog from "./ButonRutearDialog";

const MapMenuFlotante = ({
  //data
  dataMerge,
  viewDataMerge,
  polygons,
  //Combinador
  setViewDataMerge,
  setLocalDataMerge,
  setTargetRoute,
  setSelectedRoutes,
  setSelectedOrders,
  //otros

  routes,
  ridersDataByCompany,
  exportarHojaRutasActualizaciones,
  companyID,
  isChangeMode,
  setIsChangeMode,
  handleClosePlansDialog,
  handleSelectPlan,
  handlePrevPage,
  handleNextPage,
  handleOpenFilterDialog,
  handleCancelFilter,
  handleOpenOptimizeDialog,
  handleCancelOptimize,
  handleApplyOptimize,
  handleOpenSaveDialog,
  handleCancelSave,
  handleSaveDocument,

  selectedOrders,

  plans,
  openPlansDialog,
  currentPlans,
  currentPage,
  endIndex,
  availableStatuses,
  selectedStatuses,
  setSelectedStatuses,
  translateStatesfalabbela,
  translateStatesBicciFilter,
  setOpenOptimizeDialog,
  setOpenRuoterDialog,
  openRuoterDialog,
  openFilterDialog,
  openOptimizeDialog,
  documentData,
  setDocumentData,
  setOpenPlansDialog,
  setOpenSaveDialog,
  setOpenFilterDialog,
  routeInfo,
  loading,
  setLoading,
  // REQ API
  reqApiOptimizationData,
  setReqApiOptimizationData,
  setOptimizedWaypoints,
  setRouteGeometry,
  // detalles de ruta
  routeDetails,
  setRouteDetails,
  setMetricsByRoutes,
  documentsToSend,
  contextReq,
}) => {
  // optimizedWaypoints,
  // routeGeometry

  const [optimizeOptions, setOptimizeOptions] = useState({
    // SECCIÓN: SECTORIZAR
    applySectorization: false, // Aplicar sectorización por polígonos
    keepSectorsSeparated: false, // Mantener polígonos separados
    returnToStore: false, // Retornar a tienda origen en cada vuelta
    inputOrigin: [-33.4592446, -70.6014753],
    destinationType: "mas_lejano", // Opciones: "mas_lejano", "destino", "retorno"

    // SECCIÓN: OPTIMIZACIÓN
    respectZones: false, // Respetar comunas o polígonos creados
    provider: "Bicci", // Proveedor de optimización
    selector: "Todos", // Objetos a optimizar: "Todos" o "Seleccionados"
    createRequireVehicles: true, // Crear vehículos automáticamente
    vehicleCount: 1, // Cantidad de vehículos (si no se crean automáticamente)
    createRequireAgrupations: true, // Crear agrupaciones automáticamente
    agrupationCount: 1, // Máximo de carga de cada viaje (si no se crean automáticamente)

    // SECCIÓN: RESTRICCIONES
    maxKilometers: 10, // Máximo de kilómetros recorridos (opcional)
    maxTime: 60, // Máximo de tiempo recorrido en minutos (opcional)
  });

  return (
    <Paper
      sx={{
        position: "absolute",
        top: "30%",
        right: 0,
        width: 200,
        padding: 2,
        zIndex: 900,
      }}
      elevation={3}
    >
      <Typography variant="subtitle1" gutterBottom>
        Acciones
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={isChangeMode}
            onChange={(e) => setIsChangeMode(e.target.checked)}
            color="primary"
          />
        }
        label={isChangeMode ? "Ver mapa estandar" : "Ver mapa despejado"}
      />
      <ButtonGroup
        orientation="vertical"
        color="primary"
        variant="contained"
        fullWidth
      >
        <ButtonHandleOpenPlansDialog
          companyID={companyID}
          handleSelectPlan={handleSelectPlan}
        ></ButtonHandleOpenPlansDialog>
        <ButonhandleCombineWithRoutes
          dataMerge={dataMerge}
          routeInfo={routeInfo}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          setViewDataMerge={setViewDataMerge}
          setLocalDataMerge={setLocalDataMerge}
          setSelectedRoutes={setSelectedRoutes}
          setTargetRoute={setTargetRoute}
        ></ButonhandleCombineWithRoutes>

        <ButonHandleFilterDialog
          dataMerge={dataMerge}
          availableStatuses={availableStatuses}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          translateStatesfalabbela={translateStatesfalabbela}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          setViewDataMerge={setViewDataMerge}
          setLocalDataMerge={setLocalDataMerge}
          setSelectedRoutes={setSelectedRoutes}
          setTargetRoute={setTargetRoute}
          translateStatesBicciFilter={translateStatesBicciFilter}
        ></ButonHandleFilterDialog>

        <ButonOptimizeDialog
          setOpenOptimizeDialog={setOpenOptimizeDialog}
          openOptimizeDialog={openOptimizeDialog}
          dataMerge={viewDataMerge}
          polygons={polygons}
          loading={loading}
          setLoading={setLoading}
          selectedOrders={selectedOrders}
          setViewDataMerge={setViewDataMerge}
          setLocalDataMerge={setLocalDataMerge}
          routes={routes}
          optimizeOptions={optimizeOptions}
          setOptimizeOptions={setOptimizeOptions}
          setReqApiOptimizationData={setReqApiOptimizationData}
          setRouteGeometry={setRouteGeometry}
          setOptimizedWaypoints={setOptimizedWaypoints}
          setRouteDetails={setRouteDetails}
          setMetricsByRoutes={setMetricsByRoutes}
        ></ButonOptimizeDialog>

        <ButonRutearDialog
          setOpenRuoterDialog={setOpenRuoterDialog}
          openRuoterDialog={openRuoterDialog}
          dataMerge={viewDataMerge}
          selectedOrders={selectedOrders}
          setViewDataMerge={setViewDataMerge}
          setLocalDataMerge={setLocalDataMerge}
          loading={loading}
          setLoading={setLoading}
          routes={routes}
          optimizeOptions={optimizeOptions}
          setOptimizeOptions={setOptimizeOptions}
          reqApiOptimizationData={reqApiOptimizationData}
          routeInfo={routeInfo}
          // PARADAS Y LINEAS
          // optimizedWaypoints,
          setOptimizedWaypoints={setOptimizedWaypoints}
          // routeGeometry
          setRouteGeometry={setRouteGeometry}
          setRouteDetails={setRouteDetails}
          setMetricsByRoutes={setMetricsByRoutes}
          setReqApiOptimizationData={setReqApiOptimizationData}
        ></ButonRutearDialog>

        <ButonHandleOpenSaveDialog
          setOpenSaveDialog={setOpenSaveDialog}
          handleCancelSave={handleCancelSave}
          handleSaveDocument={handleSaveDocument}
          documentData={documentData}
          setDocumentData={setDocumentData}
          routes={routes}
          ridersDataByCompany={ridersDataByCompany}
          exportarHojaRutasActualizaciones={exportarHojaRutasActualizaciones}
          routeInfo={routeInfo}
          companyID={companyID}
          documentsToSend={documentsToSend}
          setLoading={setLoading}
          contextReq={contextReq}
        ></ButonHandleOpenSaveDialog>
      </ButtonGroup>
    </Paper>
  );
};

export default MapMenuFlotante;
