export const calculateNumberOfRoutes = (totalOrders, optimizeOptions) => {
  if (optimizeOptions.createRequireAgrupations) {
    if (optimizeOptions.createRequireVehicles) {
      return Math.ceil(totalOrders / 80);
    } else {
      return parseInt(optimizeOptions.vehicleCount);
    }
  } else {
    if (optimizeOptions.createRequireVehicles) {
      return Math.ceil(totalOrders / parseInt(optimizeOptions.agrupationCount));
    } else {
      return Math.ceil(totalOrders / 60);
    }
  }
};

export const generateUpdatedOrders = (ordersByPolygon, optimizeOptions) => {
  let updatedOrders = [];
  let routeCounter = 1;
  let ordersByRoute = {};

  Object.entries(ordersByPolygon).forEach(([polygonName, orders]) => {
    if (optimizeOptions.keepSectorsSeparated) {
      const routeName = `Ruta_${routeCounter++}`;
      orders.forEach((order) => {
        updatedOrders.push({ ...order, driverLicensePlate: routeName });
      });
      ordersByRoute[routeName] = orders;
    } else {
      const routeName = `Ruta_${routeCounter++}`;
      orders.forEach((order) => {
        updatedOrders.push({ ...order, driverLicensePlate: routeName });
      });
      ordersByRoute[routeName] = orders;
    }
  });

  return updatedOrders;
};

export const assignClustersToOrders = (assignments, dataToSend, dataMerge) => {
  const clusters = Array.from({ length: assignments.length }, () => []);

  assignments.forEach((clusterIndex, i) => {
    clusters[clusterIndex].push(dataToSend[i].id);
  });

  return dataMerge.map((order) => {
    const foundCluster = clusters.findIndex((cluster) =>
      cluster.includes(order.id)
    );
    return foundCluster >= 0
      ? { ...order, driverLicensePlate: `Ruta_${foundCluster + 1}` }
      : order;
  });
};
