import React, { useState } from "react";

//import NextLink from 'next/link';
import { NavLink, useLocation } from "react-router-dom";
import { Chip } from "@mui/material";
import FadeInOut from "../../../animations/FadeInOut";
import { User as UserIcon } from "../../../../icons/User";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import PaymentIcon from "@mui/icons-material/Payment";

const MenuGeneralMyAcount = ({ onNavigate }) => {
  const [showMyAcount, setShowsMyAcount] = useState(false);

  const opctionMyAcconut = ["mi-cuenta", "envios-express", "direcciones"];

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <>
      <Chip
        icon={<UserIcon />}
        label=" Gestión de Cuenta"
        variant="outlined"
        clickable
        color="primary"
        onClick={() => setShowsMyAcount(!showMyAcount)}
        sx={{
          color: "#F8F8F8",
          width: "100%",
          fontSize: "1em",
          padding: "2px",
          backgroundColor: opctionMyAcconut.includes(splitLocation[2])
            ? "#07b107"
            : "transparent",
        }}
      />
      <FadeInOut show={showMyAcount} duration={500} parentName="fade10">
        <NavLink
          to="/dashboard/mi-cuenta"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "mi-cuenta"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <UserIcon fontSize="medium" />
            <span className="btn-title">Mi Cuenta</span>
          </div>
        </NavLink>
        <hr></hr>
        {/* <NavLink
          to="/dashboard/envios-express"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "envios-express"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <DeliveryDiningIcon fontSize="medium" />
            <span className="btn-title">Envío Express</span>
          </div>
        </NavLink> */}
        {/* <hr></hr>
        <NavLink to="/dashboard/direcciones" onClick={onNavigate}>
          <div
            className={
              splitLocation[2] === "direcciones"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <AddLocationAltIcon fontSize="medium" />
            <span className="btn-title">Mis direcciones</span>
          </div>
        </NavLink> */}
        <hr></hr>
        {/* <NavLink
          to="/dashboard/company-billing"
          onClick={onNavigate}
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "company-billing"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <PaymentIcon fontSize="medium" />
            <span className="btn-title">Facturacíon</span>
          </div>
        </NavLink> */}
        <hr></hr>
        <hr></hr>
      </FadeInOut>
    </>
  );
};

export default MenuGeneralMyAcount;
