import * as XLSX from "xlsx";
import Moment from "moment";
import { format } from "date-fns";
import { translateStatesfalabbela } from "../objects/translateStates";

const generarReporteExcepciones = async (
  data,
  ridersDataByCompany,
  initDate
) => {
  //console.log(data, ridersDataByCompany, initDate);
  async function addDataRidersDataByCompany(data, ridersDataByCompany) {
    // Realizar una copia profunda de data para no modificar el original

    const filteredData = data.filter(
      (dato) =>
        !(
          dato.falabella_status === "99" ||
          dato.falabella_status === "1058" ||
          dato.falabella_status === "1060" ||
          dato.falabella_status === "1061" ||
          dato.int_falabella_status === "Terminado"
        )
    );
    console.log(filteredData);
    // falabella_status !=== 99 || int_falabella_status !== "Terminado"
    //console.log(ridersDataByCompany);
    const newData = JSON.parse(JSON.stringify(filteredData));

    let currentDate = new Date(initDate);

    currentDate.setDate(currentDate.getDate()); // es Cero - Desarrollo -6
    let year = currentDate.getFullYear().toString().slice(-2); // Extract the last two digits of the year
    let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with leading zero if necessary
    let day = String(currentDate.getDate()).padStart(2, "0"); // Pad with leading zero if necessary
    let formattedDate = year + month + day;

    // for (let index = 0; index < newData.length; index++) {
    //   if (newData[index].id === "149116694843") {
    //     console.log(newData[index]);
    //   }

    //   const rider = ridersDataByCompany.find(
    //     (element) => element.id === newData[index].driverID
    //   );

    //   if (rider) {
    //     newData[index].ppu = rider.bicciNumber;
    //     newData[index].idRoute = rider.bicciNumber + formattedDate.toString();
    //   } else {
    //     console.log("no se encuentra rider");
    //   }
    // }
    const matrizReporte = [
      [
        "LPN",
        "Suborden",
        "F12",
        "PPU",
        "Origen de Carga",
        "Tipo de Servicio",
        "ID Ruta/Viaje/Shipment",
        "Fecha de recepción",
        "Fecha de carga",
        "Actualizacíon",
        "conductor",
        "Estado",
        "Motivo de nontrega",
        "Receptor",
        "RUT Receptor",
        "Observación",
        "fotos",
      ],
      ...newData.map((dato) => [
        dato.lpn ? dato.lpn : dato.int_lpn,
        dato.id,
        dato.do ? dato.do : dato.int_f12,
        dato.ppu ? dato.ppu : dato.driverLicensePlate,
        dato.local_provider ? dato.local_provider : dato.int_warehouseName,
        dato.local_provider || dato.dataOrigin === "SDDATA"
          ? "PPF"
          : "CTT BICCI",
        dato.idRoute ? dato.idRoute : dato.routeId,
        dato.date
          ? format(dato.date.seconds * 1000, "dd/MM/yy hh:mm:ss").toString()
          : dato.routeStartDate,
        dato.date
          ? format(dato.date.seconds * 1000, "dd/MM/yy hh:mm:ss").toString()
          : dato.routeEstimatedDate,

        //Fecha de entrega
        dato.deliveryRealDeliveryDate
          ? dato.deliveryRealDeliveryDate
          : dato.upDatedAtText
          ? dato.upDatedAtText
          : "",
        dato.driverName || "",
        dato.falabella_status
          ? translateStatesfalabbela[dato.falabella_status]
          : dato.int_falabella_status === "Terminado" ||
            dato.int_bicciStatus === 8
          ? "Entregado"
          : "Pendiente",

        dato.falabella_status
          ? dato.falabella_status === "99" &&
            dato.falabella_status !== "isRequire"
            ? ""
            : translateStatesfalabbela[dato.falabella_status]
          : dato.int_falabella_status === "Terminado" ||
            dato.int_falabella_status === "99" ||
            dato.int_bicciStatus === 8
          ? ""
          : dato.int_falabella_status_descriptor !== "isRequire"
          ? dato.int_falabella_status_descriptor
          : translateStatesfalabbela[dato.int_falabella_status],

        dato.userNameReceptor ? dato.userNameReceptor : dato.deliveryReceptor,
        dato.userDniReceptor ? dato.userDniReceptor : dato.deliveryRut,
        dato.observationReceptor ||
        dato.observationAdmin ||
        dato.deliveryComment
          ? `${
              dato.observationAdmin
                ? `- Operador:  ${dato.observationAdmin[0]} `
                : ""
            }${
              dato.observationReceptor
                ? `- Conductor: ${dato.observationReceptor}`
                : ""
            }${dato.deliveryComment ? `- ${dato.deliveryComment}` : ""}`
          : null,
        dato.pics && dato.pics.length > 0 ? dato.pics.join("\n") : "",
      ]),
    ];
    //149116694843

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

    XLSX.writeFile(
      libro,
      `Reporte de Excepciones Pendiente ${Moment(Date.now()).format(
        "YYYY-MM-DD:hh:mm:ss"
      )}.xlsx`
    );
  }

  await addDataRidersDataByCompany(data, ridersDataByCompany);
};

export default generarReporteExcepciones;
