import React, { useState, useEffect } from "react";
import { GeoPoint } from "firebase/firestore";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Grid2,
  Button,
  Divider,
  Select,
  TextField,
  Avatar,
  CardContent,
} from "@mui/material";
import { format } from "date-fns";
import { Card, Tab, Tabs } from "@mui/material";
import Resizer from "react-image-file-resizer";
import { collection, doc, updateDoc, getDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { LinearProgress } from "@mui/material";
import { registrarCambioEnOrden } from "../../redux/accionsRegister";
import { useLoadScript } from "@react-google-maps/api";
import AddNewAddressForm from "./FormsEdithOrders/AddNewAddressForm";
import AdjustGeoReferenceForm from "./FormsEdithOrders/AdjustGeoReferenceForm";
import CreateEmailAuthorizationForm from "./FormsEdithOrders/CreateEmailAuthorizationForm";
import TicketSupportForm from "./FormsEdithOrders/TicketSupportForm";
import CreateEmailClientForm from "./FormsEdithOrders/CreateEmailClientForm";
import CreateEmailSecondAttemptForm from "./FormsEdithOrders/CreateEmailSecondAttemptForm";
import CreateEmailThirdAttemptForm from "./FormsEdithOrders/CreateEmailThirdAttemptForm";
import CreateEmailConfirmationForm from "./FormsEdithOrders/CreateEmailConfirmationForm";
import RUTReceptorForm from "./RUTReceptorForm";
import NombreReceptorForm from "./NombreReceptorForm";
import UploadFotoEntrega from "./UploadFotoEntrega";
var data = <>"Sin contenido";</>;
var dataContenido = <>"No se muestran datos"</>;
var dataDriver = <>"No se muestran datos"</>;
var actionsOrders = <>"no se muestran datos"</>;
var logDeCambios = <>"no se muestran datos"</>;

// console.log(orderDetails)
const translateStateBicciFalabella = {
  1: "SIN MORADORES",
  99: "Entregado",
  1058: "Creado",
  1059: "Sin Asignar",
  1060: "En Ruta",
  1061: "En Ruta",
  1002: "DOMICILIO NO CORRESPONDE",
  1005: "PROD NO ES LO COMPRADO",
  1006: "PROD INCOMPLETO",
  1007: "PROD FALTANTE",
  1008: "PROD DANADO",
  1009: "FUERA DE HORARIO",
  1017: "NO RETIRA NO CUMPL FORMAT",
  1019: "FUERA DE COBERTURA",
  1022: "CLIENTE REALIZARA N/C",
  1023: "PRODUCTO ROBADO",
  1027: "NO RETIRADO",
  1029: "DEVOLUCION",
  1031: "PROD N ENTREGADO X TIEMPO",
  1030: "INCIDENCIA EN LA CALLE",
  1032: "FALTA DE INFORMACIÓN",
  1034: "PROD YA FUE RECIBIDO",
  1036: "DIFICULTAD ACCESO DESTINO",
  1038: "CLIENTE CAMBIA DIRCCION",
  1041: "CLIENTE RECHAZA ENVIO",
  1018: "NO CONOCEN AL CLIENTE",
};

const libraries = ["geometry", "places"];

const EditOrderFrom = ({
  orderDetails,
  orderDetailsCliente,
  dataOrdesBicciExpress,
  usuarioStorage,
  bloqueaGoogle,
  setOrderDetails,
  orderid,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const stateTransitions = {
    0: "creado",
    1: "creado",
    2: "Confirmado",
    3: "Asignado",
    4: "Por recolectar",
    6: "En ruta en Destino al cliente",
    7: "Visitado",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storePayload = useSelector((store) => store.accionsRegisterLogs);

  const [activeTab, setActiveTab] = useState(0);
  const [orderID, setOrderId] = useState(orderid);

  // Estado para fotos
  const [foto, setFoto] = useState([]);
  const [tokensPics, setTokensPics] = useState([]);
  const [picsTaken, setPicsTaken] = useState(false);

  // Estado para la UI
  const [estatus, setEstatus] = useState(orderDetails.status);
  const [date, setDate] = useState([]);
  const [comment, setComment] = useState("");
  const [confirmDireccion, setConfirmDirection] = useState("");
  const [newLocation, setNewLocation] = useState(null);

  // Colección en Firestore
  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  useEffect(() => {}, [foto, orderID]);

  // Efecto para setear orderID
  useEffect(() => {
    setOrderId(orderid);
  }, [orderid]);

  // Efecto: Cargar datos iniciales de la orden
  useEffect(() => {
    if (orderDetails.pics) {
      const arraysPics = [...orderDetails.pics];
      setFoto(arraysPics);
      setPicsTaken(arraysPics.length > 0);
    }
    if (orderDetails.date && orderDetailsCliente?.updatedAt) {
      setDate(
        format(
          orderDetailsCliente.updatedAt.seconds * 1000,
          "dd/MM/yy HH:mm:ss"
        )
      );
    }
    if (orderDetails.status) {
      setEstatus(orderDetails.status);
    }
  }, [orderDetails, orderDetailsCliente]);

  const panes = [
    { label: "Datos de Orden", content: data },
    { label: "Contenido de Orden", content: dataContenido },
    { label: "Datos de Driver", content: dataDriver },
    { label: "Acciones", content: actionsOrders },
    { label: "Registro de Acciones", content: logDeCambios },
  ];

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // >>>>>>>>>>>>>>>>>>>> FUNCIÓN: GUARDAR DNI

  const handleDeleteImage = async (index) => {
    const updatedFotos = foto.filter((_, i) => i !== index);
    let orderRef = doc(collectionRef, orderID);
    if (!orderID) {
      console.error(
        "⚠️ No se puede actualizar Firestore: orderID no es válido."
      );
      return;
    }

    try {
      // 🔹 Actualizar Firestore con la nueva lista de imágenes
      await updateDoc(orderRef, {
        pics: updatedFotos.length > 0 ? updatedFotos : [], // Asegurar un array válido
        picsTaken: updatedFotos.length > 0, // picsTaken será `false` si no quedan imágenes
      });

      // 🔹 Actualizar estado local
      setFoto(updatedFotos);
    } catch (error) {
      console.error("❌ Error al actualizar Firestore:", error);
    }
  };

  const createTicketSuport = (e) => {
    e.preventDefault();
    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: comment,
      orderId: orderDetails.id,
      type: "ticket",
      userId: usuarioStorage.uid,
      var: "ticket",
    };

    dispatch(registrarCambioEnOrden(data));
  };

  const createEmailclient = (e) => {
    e.preventDefault();
    const uniqueId = e.target.elements.uniqueId.value;

    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: "send",
      orderId: orderDetails.id,
      type: uniqueId,
      userId: usuarioStorage.uid,
      var: "email",
    };

    dispatch(registrarCambioEnOrden(data));

    const propsData = {
      acction: uniqueId,
      dirección: confirmDireccion,
      data: orderDetails,
      data2: orderDetailsCliente,
      data3: dataOrdesBicciExpress || [],
    };
    navigate(`/dashboard/dispatch/details/${orderDetails.id}/prewiew_email`, {
      state: propsData,
    });
  };

  const addNewGeoPoint = async (newLocation) => {
    let newGeo = new GeoPoint(
      parseFloat(newLocation.lat),
      parseFloat(newLocation.lng)
    );
    let data = {
      date: new Date(),
      lastValue: orderDetails.toAddressLocation,
      newValue: newGeo,
      orderId: orderDetails.id,
      type: "infoAdd",
      userId: usuarioStorage.uid,
      var: "toAddressLocation",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${orderDetails.id}`);
    try {
      await updateDoc(orderRef1, {
        toAddressLocation: newGeo,
      });

      setOrderDetails((prev) => ({
        ...prev,
        toAddressLocation: newGeo,
      }));
    } catch (error) {}

    setNewLocation(null);
  };

  // >>>>>>>>>>>>>>>>>>>> FUNCIÓN: UPDATE ESTADO ORDEN
  const updateStatus = async (newStatus) => {
    if (newStatus === orderDetails.status) return; // Evita actualizaciones innecesarias

    const orderRef2 = doc(collectionRef, `${orderDetails.id}`);
    const data = {
      date: new Date(),
      lastValue: estatus,
      newValue: newStatus,
      orderId: orderDetails.id,
      type: "infoAdd",
      userId: usuarioStorage.uid,
      var: "status",
    };

    try {
      dispatch(registrarCambioEnOrden(data));
      await updateDoc(orderRef2, { status: newStatus });
      setOrderDetails((prev) => ({
        ...prev,
        status: newStatus,
      }));
    } catch (error) {
      console.log("Error actualizando estado", error);
    }
  };

  data = (
    <Box
      sx={{ p: 3, bgcolor: "background.paper", borderRadius: 2, boxShadow: 3 }}
    >
      {/* Sección: Datos Origen */}
      <Typography variant="h6" color="primary" gutterBottom>
        Datos Origen
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box spacing={2}>
        <Grid2 xs={12} md={6}>
          <Typography variant="subtitle1" fontWeight="bold">
            Nombre de Usuario:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.placeName}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Typography variant="subtitle1" fontWeight="bold">
            Dirección:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.fromAddress}
          </Typography>
        </Grid2>
      </Box>

      <Divider sx={{ my: 1 }} />

      {/* Sección: Datos Destino */}
      <Typography variant="h6" color="primary" gutterBottom>
        Datos Destino
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box spacing={2}>
        <Grid2 xs={12} md={6}>
          <Typography variant="subtitle1" fontWeight="bold">
            Nombre:
          </Typography>
          <Typography variant="body2">
            {orderDetails.userName || "N/A"}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Typography variant="subtitle1" fontWeight="bold">
            Destino:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.toAddress}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Typography variant="subtitle1" fontWeight="bold">
            Email Cliente:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.userEmail}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Typography variant="subtitle1" fontWeight="bold">
            Teléfono:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.userPhone} |{" "}
            <a href={`tel:${orderDetails.userPhone}`}>Llamar</a>
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="subtitle1" fontWeight="bold">
            Locación Destino:
          </Typography>
          {orderDetails.toAddressLocation ? (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${orderDetails.toAddressLocation.latitude},${orderDetails.toAddressLocation.longitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver en mapa
            </a>
          ) : (
            "N/A"
          )}
        </Grid2>
      </Box>

      <Divider sx={{ my: 1 }} />
      <Box
        id="colums"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* Datos de Destino */}

        <Box>
          <Typography variant="h5" fontWeight="bold" color="primary">
            Datos Destino
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Grid2 xs={12} sm={"100%"} lg={"100%"}>
            <Typography variant="subtitle2" fontWeight="bold">
              Nombre:
            </Typography>
            <Typography variant="body2" component="div">
              {orderDetails.userName && orderDetails.userName !== "isRequire"
                ? orderDetails.userName
                : orderDetails.clientNames || "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6} lg={12}>
            <Typography variant="subtitle2" fontWeight="bold">
              Destino:
            </Typography>
            <Typography variant="body2" component="div">
              {orderDetails.toAddress || "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Email Cliente:
            </Typography>
            <Typography variant="body2" component="div">
              {orderDetails.userEmail && orderDetails.userEmail !== "isRequire"
                ? orderDetails.userEmail
                : orderDetails.clientEmail || "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Teléfono:
            </Typography>
            <Typography variant="body2" component="div">
              +569 {orderDetails.userPhone} |
              <a
                href={`callto:+569${orderDetails.userPhone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Llamar
              </a>
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Localidad:
            </Typography>
            <Typography variant="body2" component="div">
              {String(
                orderDetailsCliente?.commune1 ??
                  orderDetails.commune2 ??
                  "No disponible"
              )}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Locación destino:
            </Typography>
            <Typography variant="body2" component="div">
              {orderDetails.toAddressLocation ? (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${
                    orderDetails.toAddressLocation.latitude ||
                    orderDetails.toAddressLocation.lat
                  },${
                    orderDetails.toAddressLocation.longitude ||
                    orderDetails.toAddressLocation.lng
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver en mapa
                </a>
              ) : (
                "No disponible"
              )}
            </Typography>
          </Grid2>
        </Box>

        <Box>
          {/* Datos Adicionales */}
          <Typography variant="h5" fontWeight="bold" color="primary">
            Datos Adicionales
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              SOC:
            </Typography>
            <Typography variant="body2" component="div">
              {orderDetailsCliente?.soc ||
                orderDetails.orderID ||
                "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              F12:
            </Typography>
            <Typography variant="body2" component="div">
              {dataOrdesBicciExpress?.[0]?.F12 ||
                dataOrdesBicciExpress?.[0]?.int_f12 ||
                "Sin F12 Registrado"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Tamaño de Paquete:
            </Typography>
            <Typography variant="body2" component="div">
              {orderDetailsCliente?.size || "No disponible"}
            </Typography>
          </Grid2>

          <Grid2 xs={12} sm={6}>
            <Typography variant="subtitle2" fontWeight="bold">
              Medidas:
            </Typography>
            <Typography variant="body2" component="div">
              {`Altura: ${
                orderDetailsCliente?.height || orderDetails.productHeight || "?"
              } | `}
              {`Peso: ${
                orderDetailsCliente?.weight || orderDetails.productLength || "?"
              } | `}
              {`Ancho: ${
                orderDetailsCliente?.width || orderDetails.productWidth || "?"
              }`}
            </Typography>
          </Grid2>
        </Box>
      </Box>

      <Divider sx={{ my: 1 }} />
      {/* cambio de estados */}

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="status-select-label">Estado</InputLabel>
        <Box sx={{ display: "flex", width: "100%", gap: 1 }}>
          <Select
            labelId="status-select-label"
            value={estatus}
            onChange={(e) => updateStatus(e.target.value)}
            sx={{ flexGrow: 1 }} // Hace que el Select ocupe todo el espacio disponible
          >
            {Object.entries(stateTransitions).map(([key, label]) => (
              <MenuItem key={key} value={Number(key)}>
                {label}
              </MenuItem>
            ))}
          </Select>

          <Button
            sx={{
              backgroundColor: "#591E8F",
              color: "#fff",
              whiteSpace: "nowrap", // Evita que el texto se desborde
              "&:hover": { backgroundColor: "#25b2f4", color: "#591e8f" },
            }}
            variant="contained"
            onClick={() => updateStatus(estatus)}
          >
            Guardar Estado
          </Button>
        </Box>
      </FormControl>

      {/* Información de entrega */}
      <Typography variant="h5" fontWeight="bold" color="primary">
        Datos de Entrega
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Box>
        <Grid2 xs={12} sm={6}>
          <Typography variant="subtitle2" fontWeight="bold">
            Foto tomada:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.picsTaken || (foto.length > 0 ? "Sí" : "No")}
          </Typography>
        </Grid2>

        <Grid2 xs={12} sm={6}>
          <Typography variant="subtitle2" fontWeight="bold">
            Nombre Receptor:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.userNameReceptor ||
              orderDetails.deliveryReceptor ||
              "No disponible"}
          </Typography>
        </Grid2>

        <Grid2 xs={12} sm={6}>
          <Typography variant="subtitle2" fontWeight="bold">
            RUT:
          </Typography>
          <Typography variant="body2" component="div">
            {orderDetails.userDniReceptor ||
              orderDetails.deliveryRut ||
              "No disponible"}
          </Typography>
        </Grid2>

        <Grid2 xs={12} sm={6}>
          <Typography variant="subtitle2" fontWeight="bold">
            Última actualización:
          </Typography>
          <Typography variant="body2" component="div">
            {date || "No disponible"}
          </Typography>
        </Grid2>
      </Box>
      <Divider sx={{ mb: 2 }} />

      <Grid2 spacing={2}>
        {/* Nombre del Receptor */}
        <NombreReceptorForm
          orderDetails={orderDetails}
          orderID={orderID}
          usuarioStorage={usuarioStorage}
          setOrderDetails={setOrderDetails}
        />

        <RUTReceptorForm
          orderDetails={orderDetails}
          orderID={orderID}
          usuarioStorage={usuarioStorage}
          setOrderDetails={setOrderDetails}
        ></RUTReceptorForm>
      </Grid2>

      <UploadFotoEntrega
        foto={foto}
        setFoto={setFoto}
        setTokensPics={setTokensPics}
        storage={storage}
        orderID={orderID}
        collectionRef={collectionRef}
        dispatch={dispatch}
        registrarCambioEnOrden={registrarCambioEnOrden}
        usuarioStorage={usuarioStorage}
        handleDeleteImage={handleDeleteImage}
      />

      {/* Botón Finalizar */}
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Button
          sx={{
            backgroundColor: "green",
            color: "#fff",
            "&:hover": { opacity: 0.8 },
            width: "45%",
            m: 1,
          }}
          variant="contained"
          disabled={orderDetails.status === 8 ? true : false}
          onClick={() => updateStatus(8)}
        >
          {orderDetails.status !== 8
            ? "Finalizar Orden"
            : "Finalizado entregado"}
        </Button>
        <Button
          sx={{
            backgroundColor: "red",
            color: "#fff",
            "&:hover": { opacity: 0.8 },
            width: "45%",
            m: 1,
          }}
          variant="contained"
          disabled={orderDetails.status !== -1 ? false : true}
          onClick={() => updateStatus(-1)}
        >
          {orderDetails.status !== -1 ? "Cancelar Orden" : "Cancelado"}
        </Button>
      </Box>
    </Box>
  );

  dataContenido = (
    <>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Estatus:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {orderDetailsCliente ? (
          <span>
            {translateStateBicciFalabella[orderDetailsCliente.falabella_status]}
          </span>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Curier:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        BICCI
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Contenido:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {dataOrdesBicciExpress ? (
          <>
            <ul>
              {dataOrdesBicciExpress.map((item, index) => (
                <li key={index}>
                  {" "}
                  {item.PRODUCTO ? item.PRODUCTO : item.productName} | SKU{" "}
                  {item.SKU ? item.SKU : item.productSku}{" "}
                </li>
              ))}
            </ul>
          </>
        ) : (
          "No data"
        )}
      </Typography>
      {dataOrdesBicciExpress.length > 0 ? (
        <>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Código Producto:
          </Typography>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {dataOrdesBicciExpress[0].SKU
              ? dataOrdesBicciExpress[0].SKU
              : dataOrdesBicciExpress[0].productSku}
          </Typography>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Descripción:
          </Typography>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {dataOrdesBicciExpress[0].PRODUCTO
              ? dataOrdesBicciExpress[0].PRODUCTO
              : dataOrdesBicciExpress[0].productName}
          </Typography>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Valor : ${" "}
          </Typography>

          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {dataOrdesBicciExpress[0].VALOR
              ? dataOrdesBicciExpress[0].VALOR
              : dataOrdesBicciExpress[0].productValue}
          </Typography>
        </>
      ) : (
        <>No data...</>
      )}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Cantidad:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {orderDetailsCliente ? orderDetailsCliente.quantity_packages : null}
      </Typography>
    </>
  );

  dataDriver = (
    <>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        ID:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {orderDetails.driverID}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Foto:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        <img id="imgavatar" src={orderDetails.driverAvatar} alt={""}></img>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {orderDetails.driverName}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Teléfono:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {orderDetails.driverPhone}
      </Typography>
    </>
  );

  actionsOrders = (
    <Box sx={{ textAlign: "left" }}>
      {/* 📝 Mensaje de introducción */}
      {/* <Typography component="div">
        Crear un registro interno de soporte, Casos de error sistema,
        incumplimientos de protocolos o reclamos del cliente.
      </Typography>

      <Box>
        <TicketSupportForm createTicketSuport={createTicketSuport} />
      </Box> */}

      {/* 📝 Primer intento fallido */}
      <Typography component="div">
        Generar maqueta email de primer intento fallido solicitud de contacto.
      </Typography>
      <CreateEmailClientForm createEmailclient={createEmailclient} />

      <hr />

      {/* 📝 Segundo intento fallido */}
      <Typography component="div">
        Generar maqueta email de segundo intento fallido solicitud de contacto.
      </Typography>
      <CreateEmailSecondAttemptForm createEmailclient={createEmailclient} />

      <hr />

      {/* 📝 Tercer intento fallido */}
      <Typography component="div">
        Generar maqueta email de tercer intento fallido solicitud de contacto.
      </Typography>
      <CreateEmailThirdAttemptForm createEmailclient={createEmailclient} />

      <hr />

      {/* 📝 Confirmación de dirección */}
      <Typography component="div">
        Generar maqueta email de confirmación de dirección destino - Paquetes
        con dirección no legible.
      </Typography>
      <CreateEmailConfirmationForm createEmailclient={createEmailclient} />

      <hr />

      {/* 📝 Autorización de cambio de domicilio */}
      <Typography component="div">
        Generar Autorización de cambio de domicilio.
      </Typography>
      <CreateEmailAuthorizationForm createEmailclient={createEmailclient} />

      <hr />

      {/* 📝 Edición de dirección con error */}
      {!orderDetails.int_falabella_status && (
        <>
          <Typography component="div">Editar dirección con error.</Typography>
          <Typography component="div">
            Dirección actual: {orderDetails.toAddress}
          </Typography>
          <AddNewAddressForm
            setOrderDetails={setOrderDetails}
            orderDetails={orderDetails}
            usuarioStorage={usuarioStorage}
            registrarCambioEnOrden={registrarCambioEnOrden}
            dispatch={dispatch}
            collectionRef={collectionRef}
          />
          <hr />
        </>
      )}

      {/* 📝 Ajustar georreferencia */}
      {bloqueaGoogle !== true && (
        <>
          <AdjustGeoReferenceForm
            orderDetails={orderDetails}
            addNewGeoPoint={addNewGeoPoint}
          />
          <hr />
        </>
      )}
    </Box>
  );

  logDeCambios = (
    <Box sx={{ textAlign: "left" }}>
      {storePayload && storePayload.upload === true ? (
        <>
          {storePayload.logs.map((item, index) => (
            <Card
              key={index}
              sx={{ margin: "3px", padding: "3px" }}
              variant="outlined"
            >
              <div>{format(item.date.seconds * 1000, "dd/MM/yy HH:mm:ss")}</div>
              <div>Usuario </div>
              <div>{item.userId}</div>
              <div>Accion: {item.var}</div>
              <div>
                {typeof item.lastValue === "object" &&
                item.lastValue !== null ? (
                  <>Geopunto</>
                ) : (
                  <>
                    {" "}
                    <div>valor anterior: {item.lastValue} </div>
                    <div>nuevo valor {item.newValue}</div>
                  </>
                )}
              </div>
            </Card>
          ))}
        </>
      ) : (
        <>Sin cambios</>
      )}
    </Box>
  );

  if (loadError) {
    console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }

  return orderID !== null ? (
    <Box sx={{ width: "100%" }}>
      {/* Contenedor de Tabs */}
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        {panes.map((pane, index) => (
          <Tab key={index} label={pane.label} />
        ))}
      </Tabs>

      {/* Contenido de cada pestaña */}
      {panes.map((pane, index) => (
        <TabPanel key={index} value={activeTab} index={index}>
          <Box>{pane.content}</Box>
        </TabPanel>
      ))}
    </Box>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

// Componente auxiliar para mostrar el contenido de cada pestaña
// >>>>>>>>>>>>>>>>>>>> COMPONENTE: TabPanel
const TabPanel = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      sx={{ p: 2, display: value === index ? "block" : "none" }}
    >
      <Card sx={{ p: 2, boxShadow: 2 }}>
        <CardContent>
          <div>{children}</div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EditOrderFrom;
