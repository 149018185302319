import * as React from "react";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import { useDateRangePicker } from "../../../CustomDateRangePicker";
import DashIconsResumenIncidencias from "../../DashIconsResumenDispachs/DashIconsResumenIncidencias";
import ToolFiltersComponent from "../ToolFiltersComponent/ToolFiltersComponent";
import { Grid2, Typography } from "@mui/material";

export default function ToolDashAppBarCompanyOps(props) {
  const [viewFinFindOrdes, setViewFindOrdes] = React.useState(false);

  const [anchorElReport, setAnchorElReport] = React.useState(null);

  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  useEffect(() => {
    if (props.dataOrdersFilter) {
      setDataMergeLocalOrder(props.dataOrdersFilter);
    }
  }, [props, viewFinFindOrdes, props.dataOrdersFilter]);

  useEffect(() => {
    if (props.companiesData) {
      props.setFilteredCompanies(props.companiesData);
    }
  }, [props && props.companiesData]);

  useEffect(() => {
    if (props.companiesOrigin.length > 0) {
      props.setFilteredOrigins(props.companiesOrigin);
    }
  }, [props && props.companiesOrigin]);

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    props.onChageRangePiker(createDate, FinalDate);
  };

  const handleMenuReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseMenuReport = () => {
    setAnchorElReport(null);
  };

  const handleFilter = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleChange = (event) => {
    props.setSelectedCompanies(event.target.value);
  };

  const handleOrigins = (event) => {
    props.setSelectedOrigins(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ToolFiltersComponent
        selectedCompanies={props.selectedCompanies}
        selectedOrigins={props.selectedOrigins}
        filteredCompanies={props.filteredCompanies}
        filteredOrigins={props.filteredOrigins}
        handleChange={handleChange}
        handleOrigins={handleOrigins}
        rangeInputPicker={rangeInputPicker}
        setNewDate={setNewDate}
        rightComponent={true}
        listItems={props.listItems}
        cantListItems={props.cantListItems}
      ></ToolFiltersComponent>

      <Grid2
        container
        spacing={2}
        columns={16}
        sx={{
          borderBlockColor: grey[200],
          padding: "20px",
        }}
      >
        <Grid2
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "70%",
            },
          }}
        >
          <Box sx={{ display: "flex", direction: "row" }}></Box>
        </Grid2>
        <Grid2
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "30%",
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                textAlign: "right",
              }}
            >
              {props.dataOrdersFilter.length} Items en la consulta actual
            </Typography>
          </Box>
        </Grid2>
        <Grid2
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "center",
              },
              textAlign: "right",
            }}
          >
            <div className="">
              {props && props.datailInsures ? (
                <DashIconsResumenIncidencias
                  datailInsures={props.datailInsures}
                ></DashIconsResumenIncidencias>
              ) : null}
            </div>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}
