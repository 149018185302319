import { haversineDistance } from "./haversineDistance";

export function kmeans(data, k, maxIterations = 100) {
  // UTILIDADES PARA KMEANS Y OPTIMIZACIÓN

  function initializeCenters(data, k) {
    const indices = [];
    while (indices.length < k) {
      const idx = Math.floor(Math.random() * data.length);
      if (!indices.includes(idx)) indices.push(idx);
    }
    return indices.map((i) => ({ lat: data[i].lat, lng: data[i].lng }));
  }

  function assignToCenters(data, centers) {
    const assignments = data.map((point) => {
      let bestCenter = 0;
      let bestDist = Infinity;
      centers.forEach((c, i) => {
        const dist = haversineDistance(point.lat, point.lng, c.lat, c.lng);
        if (dist < bestDist) {
          bestDist = dist;
          bestCenter = i;
        }
      });
      return bestCenter;
    });
    return assignments;
  }

  function recalculateCenters(data, assignments, k) {
    const sums = Array.from({ length: k }, () => ({
      lat: 0,
      lng: 0,
      count: 0,
    }));
    assignments.forEach((centerIndex, i) => {
      sums[centerIndex].lat += data[i].lat;
      sums[centerIndex].lng += data[i].lng;
      sums[centerIndex].count += 1;
    });

    return sums.map((s) =>
      s.count > 0
        ? { lat: s.lat / s.count, lng: s.lng / s.count }
        : { lat: 0, lng: 0 }
    );
  }

  let centers = initializeCenters(data, k);
  let assignments = [];

  for (let iter = 0; iter < maxIterations; iter++) {
    const newAssignments = assignToCenters(data, centers);
    if (JSON.stringify(newAssignments) === JSON.stringify(assignments)) {
      break;
    }
    assignments = newAssignments;
    centers = recalculateCenters(data, assignments, k);
  }

  return { assignments, centers };
}
