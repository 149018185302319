import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Badge,
  Button,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

const ToolDasthRouteNotifications = ({ routes, nuid, ridersNames }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [page, setPage] = useState(0);
  const [readNotifications, setReadNotifications] = useState(() => {
    try {
      const storedData = JSON.parse(
        localStorage.getItem(`readNotifications_${nuid}`)
      );
      return new Set(Array.isArray(storedData) ? storedData : []);
    } catch (error) {
      console.error("Error al leer notificaciones vistas:", error);
      return new Set();
    }
  });
  const observerRefs = useRef(new Map()); // Guardará referencias de los elementos observados
  const observer = useRef(null); // Observer global

  const pageSize = 30;
  const today = new Date().toISOString().slice(0, 10); // Formato YYYY-MM-DD

  // 🔄 Cargar notificaciones leídas desde localStorage (y resetear si es un nuevo día)
  useEffect(() => {
    try {
      const storedData = JSON.parse(
        localStorage.getItem(`readNotifications_${nuid}`)
      );
      if (!storedData || storedData.date !== today) {
        localStorage.setItem(
          `readNotifications_${nuid}`,
          JSON.stringify({ date: today, notifications: [] })
        );
        setReadNotifications(new Set());
      } else {
        setReadNotifications(new Set(storedData.notifications || []));
      }
    } catch (error) {
      console.error("Error al leer notificaciones vistas:", error);
      setReadNotifications(new Set());
    }
  }, [nuid]); // Se ejecuta solo una vez al cargar

  // 🔄 Guardar cambios en localStorage cuando cambia `readNotifications`
  useEffect(() => {
    if (readNotifications.size > 0) {
      localStorage.setItem(
        `readNotifications_${nuid}`,
        JSON.stringify({
          date: today,
          notifications: Array.from(readNotifications),
        })
      );
    }
  }, [readNotifications, nuid]);

  const allObservations = routes
    .flatMap(
      (route) =>
        route.observations?.map((obs) => ({
          id: obs.id,
          orderID: obs.orderID,
          message: obs.comment,
          createdAt: obs.createdAt,
          createdBy: obs.createdBy || "System",
          driverID: ridersNames[obs.driverID] || null,
        })) || []
    )
    .sort((a, b) => (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0));

  const unreadObservations = allObservations.filter(
    (obs) => !readNotifications.has(obs.id)
  ).length;

  const toggleDrawer = (open) => () => {
    setOpenDrawer(open);
  };

  // ✅ Usa `useCallback` para evitar re-render innecesarios
  const markAsRead = useCallback((id) => {
    setReadNotifications((prev) => {
      if (prev.has(id)) return prev; // No hacer cambios si ya está leído
      const updatedSet = new Set(prev);
      updatedSet.add(id);
      return updatedSet;
    });
  }, []);

  // 🔄 Paginación
  const paginatedObservations = allObservations.slice(
    page * pageSize,
    (page + 1) * pageSize
  );

  // 🔄 Configurar observer de visibilidad
  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const id = entry.target.getAttribute("data-id");
            if (entry.isIntersecting && id && !readNotifications.has(id)) {
              markAsRead(id);
            }
          });
        },
        { threshold: 0.7 }
      );
    }

    paginatedObservations.forEach((obs) => {
      const ref = observerRefs.current.get(obs.id);
      if (ref) observer.current.observe(ref);
    });

    return () => observer.current.disconnect();
  }, [paginatedObservations, markAsRead]);

  return (
    <Box sx={{ position: "relative" }}>
      {/* 🔔 Botón de notificaciones con burbuja */}
      <IconButton onClick={toggleDrawer(true)} color="primary">
        <Badge badgeContent={unreadObservations} color="error">
          <NotificationsIcon fontSize="large" />
        </Badge>
      </IconButton>

      {/* 📋 Drawer de notificaciones */}
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 400,
            height: "100vh",
            padding: "16px",
            backgroundImage:
              "url(https://i.pinimg.com/736x/85/6f/39/856f39871adb9b76cb4d61d203104b9f.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ marginBottom: 2, color: "#030303" }}
          >
            🔔 Notificaciones ({unreadObservations} nuevas)
          </Typography>

          <List sx={{ flexGrow: 1, overflowY: "auto" }}>
            {paginatedObservations.length > 0 ? (
              paginatedObservations.map((obs, index) => {
                const isRead = readNotifications.has(obs.id);
                return (
                  <React.Fragment key={index}>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: isRead ? "#F3F3F3" : "#c9a0ef", // Amarillo para no leídos
                        borderRadius: "12px",
                        marginBottom: "8px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      data-id={obs.id}
                      ref={(el) => observerRefs.current.set(obs.id, el)} // Guardar la referencia en el ref
                      onClick={() => markAsRead(obs.id)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AccountCircleIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ChatBubbleOutlineIcon
                              sx={{ marginRight: "5px", color: "#1565c0" }}
                            />
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.primary"
                              fontWeight="bold"
                            >
                              {obs.createdBy} {obs.orderID}
                            </Typography>
                          </Box>
                        }
                        secondary={
                          <>
                            <Typography
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {obs.message}
                            </Typography>
                            <br />

                            {obs.driverID && (
                              <Typography
                                component="span"
                                variant="body2"
                                color="text.secondary"
                              >
                                🚚 Driver: {obs.driverID}
                              </Typography>
                            )}

                            <br />
                            <Typography
                              component="span"
                              variant="caption"
                              color="text.secondary"
                            >
                              🕒{" "}
                              {obs.createdAt?.seconds
                                ? new Date(
                                    obs.createdAt.seconds * 1000
                                  ).toLocaleString()
                                : "Sin fecha"}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="center"
                sx={{ marginTop: "20px" }}
              >
                No hay notificaciones recientes
              </Typography>
            )}
          </List>

          {/* 🔄 Controles de paginación */}
          {allObservations.length > pageSize && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <Button
                onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
                disabled={page === 0}
                variant="contained"
                color="primary"
              >
                Anterior
              </Button>
              <Typography variant="body2" fontWeight="bold" color="white">
                Página {page + 1} de{" "}
                {Math.ceil(allObservations.length / pageSize)}
              </Typography>
              <Button
                onClick={() =>
                  setPage((prev) =>
                    prev + 1 < Math.ceil(allObservations.length / pageSize)
                      ? prev + 1
                      : prev
                  )
                }
                disabled={
                  page + 1 >= Math.ceil(allObservations.length / pageSize)
                }
                variant="contained"
                color="primary"
              >
                Siguiente
              </Button>
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default ToolDasthRouteNotifications;
