import React, { useContext, useEffect, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import GuiasByFalabella from "../Guias/GuiasByFalabella";
import activateDevolutionUser from "../../../utils/activateDevolutionUser";
import { UserContext } from "../../../context/CurrentUserProvider";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];

const GuiasFalabellaSD = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [companyCollaborator, setCompanyCollaborator] = useState(null);
  const [userRoll, setUserRoll] = useState(null);

  // const user = useSelector((store) => store.usuario.user);
  // const userRoll = useSelector((store) => store.usuario.user.roll);
  // const { userdata } = useObtenerDatosdeCliente(user.uid);

  const { userDataContext } = useContext(UserContext);

  useEffect(() => {
    if (userDataContext) {
      setCompanyCollaborator(userDataContext.companyCollaborator);
      setUserRoll(userDataContext.roll);
      activateDevolutionUser();
    }
  }, [userDataContext]);

  //useEffect(() => {}, [userdata]);

  //console.log(companyCollaborator)
  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return userRoll && companyCollaborator ? (
    <>
      {userRoll === "biccixpressAdmin" ? (
        <GuiasByFalabella
          vertical={"SD"}
          userdata={userDataContext}
        ></GuiasByFalabella>
      ) : (
        <></>
      )}
      {userRoll === "company_admin" ? (
        <>
          {companyCollaborator.includes("falabella") ? (
            <>
              <GuiasByFalabella
                userdata={userDataContext}
                vertical={"SD"}
              ></GuiasByFalabella>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {userRoll === "client" ? (
        <Box sx={{ padding: "20px" }}>
          <h1>Acceso restringido Rol o Permisos Error </h1>
        </Box>
      ) : (
        <></>
      )}
    </>
  ) : (
    <LinearProgress />
  );
};

export default GuiasFalabellaSD;
