import { useState } from "react";
import { assignOrdersToPolygons } from "./assignOrdersToPolygons"; // Asegúrate de importar las funciones necesarias
import { buildOptimizationPayload } from "./buildOptimizationPayload";
import { routeInfo } from "./routeInfo";
import { shouldOptimize } from "./shouldOptimize";
import {
  assignClustersToOrders,
  calculateNumberOfRoutes,
  generateUpdatedOrders,
} from "./routeOptimizerHelpers";
import { kmeans } from "./kmeans";

export const useRouteOptimizer = ({
  setRouteGeometry,
  setOptimizedWaypoints,
  setMetricsByRoutes,
  setLoading,
  setReqApiOptimizationData,
  setViewDataMerge,
  setLocalDataMerge,
  setOpenOptimizeDialog,
  setOpenSnackbar,
  optimizeOptions,
  dataMerge,
  selectedOrders,
  polygons,
  routes,
}) => {
  const [error, setError] = useState(null);

  const handleApplyOptimize = async () => {
    try {
      setRouteGeometry([]);
      setOptimizedWaypoints([]);
      setMetricsByRoutes([]);
      setLoading(true);

      if (!shouldOptimize(optimizeOptions)) {
        setLoading(false);
        return;
      }

      const ordersToOptimize =
        optimizeOptions.selector === "Todos"
          ? dataMerge
          : dataMerge.filter((order) => selectedOrders.includes(order.id));

      const dataToSend = ordersToOptimize
        .filter(
          (o) =>
            o.toAddressLocation &&
            typeof o.toAddressLocation._lat === "number" &&
            typeof o.toAddressLocation._long === "number"
        )
        .map((order) => ({
          id: order.id,
          lat: parseFloat(order.toAddressLocation._lat),
          lng: parseFloat(order.toAddressLocation._long),
        }));

      if (dataToSend.length === 0) {
        setLoading(false);
        setOpenOptimizeDialog(false);
        return;
      }

      const totalOrders = dataToSend.length;
      const numberOfRoutes = calculateNumberOfRoutes(
        totalOrders,
        optimizeOptions
      );

      const { assignments } = kmeans(dataToSend, numberOfRoutes, 100);

      let updatedOrders = [];
      let ordersByRoute = {};
      let ordersByPolygon = {};

      if (optimizeOptions.applySectorization) {
        if (!polygons) {
          alert("Debe cargar algún polígono antes de continuar.");
          setOpenOptimizeDialog(false);
          setLoading(false);
          return;
        }
        ordersByPolygon = await assignOrdersToPolygons(
          ordersToOptimize,
          polygons,
          optimizeOptions
        );
      } else {
        ordersByPolygon["General"] = ordersToOptimize;
      }

      updatedOrders = generateUpdatedOrders(ordersByPolygon, optimizeOptions);

      if (!optimizeOptions.applySectorization) {
        updatedOrders = assignClustersToOrders(
          assignments,
          dataToSend,
          dataMerge
        );
        ordersByRoute = await routeInfo(updatedOrders, routes);
      }

      const optimizationData = await buildOptimizationPayload(
        optimizeOptions,
        updatedOrders,
        routes
      );

      setReqApiOptimizationData(optimizationData);

      setViewDataMerge((prevData) =>
        prevData.map(
          (order) =>
            updatedOrders.find((updated) => updated.id === order.id) || order
        )
      );

      setLocalDataMerge((prevData) =>
        prevData.map(
          (order) =>
            updatedOrders.find((updated) => updated.id === order.id) || order
        )
      );

      setTimeout(() => {
        setOpenOptimizeDialog(false);
        setLoading(false);
        setOpenSnackbar(true);
      }, 3000);
    } catch (error) {
      setError("Ocurrió un error al optimizar las rutas.");
      setLoading(false);
    }
  };

  return { handleApplyOptimize, error };
};
