import { getFarthestPoint } from "../../../MapComponentMonitorLeaflet/MapMenuFlotante/UtilsOptimize/getFarthestPoint";
import { routeInfo } from "./routeInfo";

export const buildOptimizationPayload = async (
  optimizeOptions,
  updatedOrders,
  routes
) => {
  // Validación de datos de entrada
  const missingFields = [];

  if (!optimizeOptions)
    missingFields.push("optimizeOptions (objeto de opciones de optimización)");
  if (!updatedOrders || !Array.isArray(updatedOrders))
    missingFields.push("updatedOrders (array de órdenes actualizadas)");
  if (!routes || !Array.isArray(routes))
    missingFields.push("routes (array de rutas)");

  if (missingFields.length > 0) {
    console.error("⛔ Faltan los siguientes datos de entrada:", missingFields);
    console.error("🔹 Estructura esperada:", {
      optimizeOptions: {
        inputOrigin: { lat: "número", lng: "número" },
        destinationType: "string",
        origen: { lat: "número", lng: "número" },
        destino: { lat: "número", lng: "número" },
      },
      updatedOrders: [
        {
          id: "string",
          fromAddressLocation: { _lat: "número", _long: "número" },
          toAddressLocation: { _lat: "número", _long: "número" },
        },
      ],
      routes: ["string"],
    });
    return [];
  }

  const { ordersByRoute } = await routeInfo(updatedOrders, routes); // Obtener órdenes agrupadas por ruta
  const optimizationRequests = [];

  Object.entries(ordersByRoute).forEach(([route, orders]) => {
    if (orders.length === 0) return; // Ignorar rutas sin órdenes

    // 📌 Determinar el origen  Invertir valores para el API
    let origin = optimizeOptions.inputOrigin || orders[0].fromAddressLocation;
    if (!origin || !origin.lat || !origin.lon) {
      origin = {
        lat: orders[0]?.fromAddressLocation?._long,
        lon: orders[0]?.fromAddressLocation?._lat,
      };
    }

    // 📌 Determinar el destino según `optimizeOptions.destinationType`

    let destination;
    switch (optimizeOptions.destinationType) {
      case "mas_lejano":
        destination = getFarthestPoint(origin, orders);
        break;
      case "retorno":
        destination = optimizeOptions.origen || origin;
        break;
      case "destino":
        destination = optimizeOptions.destino || origin;
        break;
      default:
        console.warn(
          `⚠️ Tipo de destino desconocido (${optimizeOptions.destinationType}), usando origen.`
        );
        destination = origin;
    }

    // 📌 Construir la lista de waypoints
    const waypoints = [
      { id: "origen", name: "", lon: origin.lon, lat: origin.lat },
      ...orders.map((order) => ({
        id: order.id,
        name: "",
        lon: order.toAddressLocation._lat,
        lat: order.toAddressLocation._long,
      })),
      {
        id: "destino",
        name: "",
        lon: destination.lat,
        lat: destination.lng ? destination.lng : destination.lon, // Invertidos para el API
      },
    ];

    // 📌 Estructura final para la optimización de esta ruta
    const routeOptimizationPayload = {
      vehicle: "driving",
      route: route,
      waypoints,
    };

    optimizationRequests.push(routeOptimizationPayload);
  });

  return optimizationRequests;
};
