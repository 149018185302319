import React, { useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
const AddNewAddressForm = ({
  setOrderDetails,
  orderDetails,
  usuarioStorage,
  registrarCambioEnOrden,
  dispatch,
  collectionRef,
}) => {
  const [direccion, setDireccion] = useState("");

  const addNewAddress = async (e) => {
    e.preventDefault();
    let data = {
      date: new Date(),
      lastValue: orderDetails.toAddress,
      newValue: direccion,
      orderId: orderDetails.id,
      type: "infoAdd",
      userId: usuarioStorage.uid,
      var: "toAddress",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${orderDetails.id}`);

    try {
      await updateDoc(orderRef1, {
        toAddress: direccion,
      });
      setOrderDetails((prev) => ({
        ...prev,
        toAddress: direccion,
      }));

      setDireccion("");
    } catch (error) {}
    setDireccion("");
  };

  return (
    <form onSubmit={addNewAddress}>
      {/* Campo de entrada para la dirección */}
      <TextField
        fullWidth
        label="Agregar nueva dirección"
        placeholder="Ingrese la dirección..."
        variant="outlined"
        margin="normal"
        value={direccion}
        onChange={(e) => setDireccion(e.target.value)}
        InputProps={{
          startAdornment: <span style={{ marginRight: "8px" }}>📍</span>,
        }}
      />

      {/* Mostrar botón solo si la dirección tiene más de 9 caracteres */}
      {direccion.length > 9 && (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            type="submit" // Se corrigió 'sutmit' a 'submit'
            sx={{
              backgroundColor: "#591E8F",
              color: "#fff",
              borderRadius: "5px",
              padding: "10px 20px",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#25b2f4",
                color: "#591e8f",
              },
            }}
          >
            Guardar nueva dirección
          </Button>
        </Box>
      )}
    </form>
  );
};

export default AddNewAddressForm;
