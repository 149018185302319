import React, { useState } from "react";
import { actualizarDatosdeCompany } from "../../../redux/accionesCompanies";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent,
  Button,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FadeInOut from "../../animations/FadeInOut";
import moment from "moment";
import Fromcompanynew from "./Fromcompanynew";
import ContainerViewComponent from "../../ContainerViewComponent/ContainerViewComponent";

const Fromcompanywiew = ({ company, userUid }) => {
  const companyData = {
    companyID: company.companyID,
    createbyAdd: company.createByUid,
    createdate: company.createdate,
    createdateAdd: company.createdateAdd,
    propietary: company.propietary,
    companyName: company.companyName,
    companyRut: company.companyRut,
    companyAddress: company.companyAddress,
    companyNumAddress: company.companyNumAddress,
    companyRegion: company.companyRegion,
    companyCity: company.companyCity,
    companyComuna: company.companyComuna,
    companyCountry: company.companyCountry,
    companyCountryCode: company.companyCountryCode,
    companyActividad: company.companyActividad,
    companyContacName: company.companyContacName,
    companyContacEmail: company.companyContacEmail,
    companyAdressPhone: company.companyAdressPhone,
    statusDocsRut: company.companyImgRut,
    statusDocsComercio: company.companyImgDocumentos,
    statusDocsDocsii: company.companyImgDocSii,
    statusDocsAcuerdo: company.statusDocsAcuerdo,
    statusDocsPatente: company.companyImgPatente,
    statusDocsLogo: company.statusDocsLogo,
    stausDocument: company.stausDocument,
    adressGeoNumAddressLat: company.adressGeoNumAddressLat,
    adressGeoNumAddressLng: company.adressGeoNumAddressLng,
    estatusCRM: company.estatusCRM,
    relateCRM: company.relateCRM,
    isActive: company.isActive,
    servicie: company.servicie,
    servicetype: company.servicetype,
    stausService: company.stausService,
    currentPayMethods: company.currentPayMethods,
    payPendings: company.payPendings,
    suportTikets: company.suportTikets,
    companyCodeFolder: company.companyCodeFolder,
  };

  const dispatch = useDispatch();

  // States
  const resperror = useSelector((store) => store.usuario.error);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [warning, setWarning] = React.useState("");

  // User Typing
  const [rutvalida, setrutvalida] = React.useState("");
  const [validatorAlert, setValidatorAlert] = React.useState("");
  const [validatorSuccess, setValidatorSuccess] = React.useState("");

  const [assigned, setAssigned] = React.useState("");
  const [propietary, setPropietary] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [companyRut, setCompanyRut] = React.useState("");

  const [companyAddress, setCompanyAddress] = React.useState("");
  const [companyNumAddress, setCompanyNumAddress] = React.useState("");
  const [companyRegion, setCompanyRegion] = React.useState("");
  const [companyCity, setCompanyCity] = React.useState("");
  const [companyComuna, setCompanyComuna] = React.useState("");
  const [companyCountry, setCompanyCountry] = React.useState("CHILE");
  const [companyCountryCode, setCompanyCountryCode] = React.useState("CL");
  const [companyAdressLat, setCompanyAdressLat] = React.useState("");
  const [companyAdressLng, setCompanyAdressLng] = React.useState("");
  const [companyAdressNumAddressTemp, setCompanyAdressNumAddressTemp] =
    React.useState("");

  const [companyActividad, setCompanyActividad] = React.useState("");
  const [companyContacName, setCompanyContacName] = React.useState("");
  const [companyContacEmail, setCompanyContacEmail] = React.useState("");

  const [companyAdressPhone, setCompanyAdressPhone] = React.useState(
    companyData["companyAdressPhone"]
  );

  const [companyCodeFolder, setCompanyCodeFolder] = React.useState(
    companyData["companyCodeFolder"]
  );

  //Animaciones
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => {
    setShowEdit(!showEdit);
    setrutvalida(companyData["companyRut"]);
    setCompanyName(companyData["companyName"]);
    setCompanyActividad(companyData["companyActividad"]);
    setCompanyAddress(companyData["companyAddress"]);
    setCompanyNumAddress(companyData["companyNumAddress"]);
    setCompanyCity(companyData["companyCity"]);
    setCompanyComuna(companyData["companyComuna"]);
    setCompanyRegion(companyData["companyRegion"]);
    setCompanyCountry(companyData["companyCountry"]);
    setCompanyAddress(companyData["companyAddress"]);
    setCompanyCountryCode(companyData["companyCountryCode"]);
    setCompanyContacName(companyData["companyContacName"]);
    setCompanyContacEmail(companyData["companyContacEmail"]);
    setCompanyAdressPhone(companyData["companyAdressPhone"]);
    setCompanyAdressLat(companyData["adressGeoNumAddressLat"]);
    setCompanyAdressLng(companyData["adressGeoNumAddressLng"]);

    setCompanyCodeFolder(companyData["companyCodeFolder"]);
  };

  const crearEmpresa = (e) => {
    e.preventDefault();
    if (!companyName.trim()) {
      setError("Ingrese Nombre de empresa");
      return;
    }
    if (!rutvalida.trim()) {
      setError("Ingrese Rut de empresa");
      return;
    }
    if (!companyActividad.trim()) {
      setError("Ingrese actividad economica de empresa");
      return;
    }
    if (!companyAddress.trim()) {
      setError("Ingrese dirección de empresa");
      return;
    }
    if (!companyNumAddress.trim()) {
      setError("Ingrese numero de dirección si no aplica escriba NA");
      return;
    }
    if (!companyRegion.trim()) {
      setError("Ingrese region de empresa");
      return;
    }
    if (!companyCity.trim()) {
      setError("Ingrese ciudad donde se ubica la empresa");
      return;
    }
    if (!companyComuna.trim()) {
      setError("Ingrese comuna donde se ubica  empresa");
      return;
    }
    if (!companyRegion.trim()) {
      setError("Ingrese region donde se ubica  empresa");
      return;
    }
    if (!companyCountry.trim()) {
      setError("Ingrese país donde se ubica  empresa");
      return;
    }

    if (!companyContacName.trim()) {
      setError("Ingrese nombre de contacto de la empresa");
      return;
    }
    if (!companyAdressPhone.trim()) {
      setError("Ingrese telefono de contacto de la  empresa");
      return;
    }
    if (companyAdressPhone.length < 12) {
      // // console.log('En el campo teléfono faltan numeros')
      setError("En el campo teléfono faltan numeros");
      return;
    }
    if (companyAdressPhone.length > 12) {
      // // console.log('En el campo teléfono existen numeros extra')
      setError("El campo teléfono existen numeros extra");
      return;
    }
    setSuccess("Guardando Datos...");
    actualizarNewCompany();
  };

  const limpiarFormulario = (e) => {
    setError(null);
    setSuccess(null);
    setWarning(null);
    return;
  };

  const actualizarNewCompany = React.useCallback(async () => {
    const data = {
      uid: userUid,
      propietary: userUid,
      companyID: companyCodeFolder,
      companyName: companyName,
      companyRut: rutvalida,
      companyAddress: companyAddress,
      companyNumAddress: companyNumAddress,
      companyRegion: companyRegion,
      companyCity: companyCity,
      companyComuna: companyComuna,
      companyCountry: companyCountry,
      companyCountryCode: companyCountryCode,
      companyActividad: companyActividad,
      companyContacName: companyContacName,
      companyContacEmail: companyContacEmail,
      companyAdressPhone: companyAdressPhone,
      adressGeoNumAddressLat: companyAdressLat,
      adressGeoNumAddressLng: companyAdressLng,
      companyCodeFolder: companyCodeFolder,
      companyAcuerdo: "",
      companyImgRut: "",
      companyImgDocumentos: "",
      companyImgPatente: "",
      companyImgDocSii: "",
      stausDocument: "",
      estatusCRM: "",
      isActive: false,
      servicie: "",
      servicetype: "",
      stausService: false,
      CurrentPayMethods: [],
      payPendings: 0,
      suportTikets: [],
      createdate: Date.now(),
      createdateAdd: moment(Date.now()).format("DD MMMM YYYY, h:mm:ss a"),
    };
    setError(null);
    try {
      dispatch(actualizarDatosdeCompany(data));
      function delay() {
        dispatch(toggleShowEdit);
        //  limpiarFormulario()
        setSuccess(null);
      }
      setTimeout(delay, 1000);
    } catch (error) {
      // console.log(error)
    }
  });

  // const actualizarData = React.useCallback(async()=>{
  //   // // console.log('is Actualizar')
  //   const data = {

  //   }
  // })

  function rutvalidator(e) {
    var valor;
    var cuerpo;
    var dv;
    var rut;
    if (e.length <= 0) {
      setValidatorAlert("Campo es obligatorio");
      setValidatorSuccess(null);
      setrutvalida(e);
      return;
    }
    valor = clean(e);

    // // console.log(e)

    cuerpo = valor.slice(0, -1);
    dv = valor.slice(-1).toUpperCase();

    rut = format(e);

    // // console.log(rut)

    if (cuerpo.length < 7) {
      setValidatorAlert("Campo debe ser mayor a 7 Caracteres");
      setValidatorSuccess(null);
      setrutvalida(rut);
      return;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    var dvEsperado;
    dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    if (dvEsperado != dv) {
      // // console.log('Rut incorrecto')
      setValidatorAlert("Rut incorrecto");
      setValidatorSuccess(null);
      //  return false;
    } else {
      // // console.log('Rut correcto')
      setValidatorSuccess("Rut correcto");
      setValidatorAlert(null);
      function delay() {
        setValidatorSuccess(null);
      }
      setTimeout(delay, 5000);
      //   return true;
    }

    setrutvalida(rut);
    var clear = replaceAll(rut, ".", "");
    setCompanyCodeFolder(clear);

    // console.log(clear)
    return;
  }
  function replaceAll(text, busca, reemplaza) {
    while (text.toString().indexOf(busca) != -1)
      text = text.toString().replace(busca, reemplaza);
    return text;
  }
  function format(rut) {
    rut = clean(rut);
    var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (var i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "." + result;
    }
    return result;
  }
  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  return (
    <>
      <Card className="typeMiniforms">
        <CardHeader
          subheader={
            <div>
              <span> Bienvenido</span>
            </div>
          }
          title={
            <div>
              <h3>
                {companyData.companyName ? (
                  <>
                    {companyData.companyName}
                    <Button
                      className="btn btn-ebiex m-1"
                      onClick={toggleShowEdit}
                    >
                      {" "}
                      <EditOutlinedIcon> </EditOutlinedIcon>
                      {showEdit ? "Editar" : "Cerrar editor"}{" "}
                    </Button>{" "}
                  </>
                ) : (
                  <></>
                )}
              </h3>{" "}
            </div>
          }
        />
        <Divider />
        <ContainerViewComponent
          className="formcontent"
          id="micuentafromIni"
          parentName="fade18"
        >
          <FadeInOut show={showEdit} duration={500}>
            <div>
              <div>
                <Card>
                  <CardContent>
                    <h2>Datos de la empresa</h2>
                    <Box
                      sx={{
                        alignItems: "left",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>{companyData["isActive"]}</div>
                      <div>{companyData["companyName"]}</div>
                      <div>{companyData["companyRut"]}</div>
                      <div>{companyData["companyActividad"]}</div>
                      <div>{companyData["createdate"]}</div>
                      <div>{companyData["createdateAdd"]}</div>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions></CardActions>
                </Card>
              </div>
              <div>
                <Divider />
                <br></br>
                <Card>
                  <CardContent>
                    <h2>Dirección</h2>
                    <Box
                      sx={{
                        alignItems: "left",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>{companyData["companyAddress"]}</div>
                      <div>{companyData["companyNumAddress"]}</div>
                      <div>{companyData["companyCity"]}</div>
                      <div>{companyData["companyComuna"]}</div>
                      <div>{companyData["companyRegion"]}</div>
                      <div>{companyData["companyCountry"]}</div>
                      <div>{companyData["companyCountryCode"]}</div>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions></CardActions>
                </Card>

                <br></br>
                <Card>
                  <CardContent>
                    <h2>Contacto</h2>
                    <Box
                      sx={{
                        alignItems: "left",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>{companyData["companyContacName"]}</div>
                      <div>{companyData["companyContacEmail"]}</div>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions></CardActions>
                </Card>
              </div>
              <div>
                <Divider />
              </div>
            </div>

            <Divider />
          </FadeInOut>
          <FadeInOut show={!showEdit} duration={500} parentName="fade19">
            <Fromcompanynew
              company={company}
              userUid={userUid}
            ></Fromcompanynew>
          </FadeInOut>
        </ContainerViewComponent>

        <Divider />
      </Card>
    </>
  );
};

export default Fromcompanywiew;
