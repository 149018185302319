import React, { useContext, useEffect } from "react";
import { LinearProgress, Box, Button, Toolbar } from "@mui/material";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import GuiasByCompany from "./GuiasByCompany";
import CreateOrderExpress from "../CreateOrderExpress/CreateOrderExpress";
import CreateOrderMasive from "../CreateOrderMasive/CreateOrderMasive";
import Moment from "moment";
import { UserContext } from "../../../context/CurrentUserProvider";
import { useOrders } from "../../../hooks/useOrdersProviders/OrdersContext";
import { useLoadScript } from "@react-google-maps/api";
import Page404 from "../../Page404";
let libraries = ["places"];
const Guias = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries: libraries,
  });

  const { userDataContext } = useContext(UserContext);
  const {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
    companiesData,
    setShouldSubscribe,
  } = useOrders({
    initDateInitial: Moment().format("YYYY-MM-DD"),
    endDateInitial: Moment().format("YYYY-MM-DD"),
  });

  const navigate = useNavigate(); // Para navegar entre rutas

  useEffect(() => {
    setShouldSubscribe(true);
  }, [setShouldSubscribe]);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return userDataContext ? (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Toolbar sx={{ height: "50px" }}>
        <Button
          sx={{
            borderRight: "4px solid #1976d2",
            paddingRight: "12px",
            borderRadius: "0px",
          }}
          onClick={() => navigate("/dashboard/guias/view")} // Navegar a "Mis envíos"
        >
          Mis envíos
        </Button>
        <Button
          sx={{
            borderRight: "4px solid #1976d2",
            paddingRight: "12px",
            borderRadius: "0px",
          }}
          onClick={() => navigate("/dashboard/guias/express")} // Navegar a "Crear Envío Express"
        >
          Crear Envío Express
        </Button>
        <Button
          sx={{ paddingRight: "12px", borderRadius: "0px" }}
          onClick={() => navigate("/dashboard/guias/masive")} // Navegar a "Subir archivo"
        >
          Subir archivo
        </Button>
      </Toolbar>

      {/* Utilizar Routes y Route para manejar el enrutamiento y renderizar los componentes */}
      <Routes>
        <Route
          path="/"
          element={
            <GuiasByCompany
              userDataContext={userDataContext}
              mergedOrders={mergedOrders}
              loadingContext={loadingContext}
              companiesData={companiesData}
              setInitDate={setInitDate}
              setEndDate={setEndDate}
              initDate={initDate}
              endDate={endDate}
            />
          }
        />
        <Route
          path="view"
          element={
            <GuiasByCompany
              userDataContext={userDataContext}
              mergedOrders={mergedOrders}
              loadingContext={loadingContext}
              companiesData={companiesData}
              setInitDate={setInitDate}
              setEndDate={setEndDate}
              initDate={initDate}
              endDate={endDate}
            />
          }
        />
        <Route
          path="express"
          element={
            <Box sx={{ width: "100%" }}>
              <CreateOrderExpress
                userDataContext={userDataContext}
                companiesData={companiesData}
              />
            </Box>
          }
        />
        <Route
          path="masive"
          element={
            <Box sx={{ width: "100%" }}>
              <CreateOrderMasive
                userDataContext={userDataContext}
                companiesData={companiesData}
              />
            </Box>
          }
        />
        <Route element={<Page404 />} path="*"></Route>
      </Routes>
    </Box>
  ) : (
    <LinearProgress />
  );
};

export default Guias;
