import React, { useState, useEffect } from "react";
import {
  Box,
  SwipeableDrawer,
  Button,
  Divider,
  AppBar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { TableNoData } from "../../../components/Table";
import getOrdesrDispachtByAny from "../../../utils/getOrdesrDispachtByAny";
import {
  stlyleClassStates,
  styleState,
} from "../../../utils/objects/stylesStates";
import { translateStatesfalabbela } from "../../../utils/objects/translateStates";

export default function SwipeableSearcherDrawer({ styleClassName, iconColor }) {
  const [dataSearchOrder, setDataSearch] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderDetailsCliente, setOrderDetailsClient] = useState(null);
  const [dataOrdesBicciExpress, setDataOrdesBicciExpress] = useState(null);
  const [dispachtHistory, setDispachtHistory] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (isActive && progress < 100) {
      const timer = setTimeout(
        () => setProgress((prev) => Math.min(prev + 10, 100)),
        1000
      );
      return () => clearTimeout(timer);
    }
  }, [isActive, progress]);

  const handleSearch = async () => {
    setProgress(0);
    setIsActive(true);

    if (!dataSearchOrder.trim()) {
      console.log("Parámetro inválido");
      return;
    }

    try {
      const result = await getOrdesrDispachtByAny(dataSearchOrder);
      if (!result || !result.id) {
        setOrderDetails(null);
        setIsActive(false);
        return;
      }

      setOrderDetails(result);
      await findOrdesrDispacht(result.id);
    } catch (error) {
      console.error("Error en la búsqueda:", error);
    } finally {
      setIsActive(false);
    }
  };

  const findOrdesrDispacht = async (id) => {
    try {
      const ordersQuery = query(
        collection(db, process.env.REACT_APP_COL_ORDERS),
        where("orderID", "==", id)
      );
      const ordesBicciexpres = await getDocs(ordersQuery);
      const dataOrders = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDataOrdesBicciExpress(dataOrders.length ? dataOrders : null);
    } catch (error) {
      console.error("Error al buscar órdenes:", error);
      setDataOrdesBicciExpress(null);
    }

    try {
      const clearID = id.replace(/F/g, "");
      const orderRef = doc(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS,
        clearID
      );
      const falabellaOrderData = await getDoc(orderRef);

      setOrderDetailsClient(
        falabellaOrderData.exists()
          ? falabellaOrderData.data()
          : { error: "Pedido no encontrado" }
      );
    } catch (error) {
      console.error("Error al buscar pedido:", error);
      setOrderDetailsClient({ error: "Ocurrió un error al buscar el pedido" });
    }

    try {
      const statusRef = doc(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS,
        id.replace(/F/g, "")
      );
      const statusDocs = collection(statusRef, "status");
      const historyData = await getDocs(statusDocs);
      const sortedData = historyData.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds ? 1 : -1));

      setDispachtHistory(sortedData);
    } catch (error) {
      console.error("Error al buscar historial de despacho:", error);
      setDispachtHistory([]);
    }
  };

  const formatDate = (dateSeconds) => {
    return dateSeconds ? format(dateSeconds * 1000, "dd/MM/yy") : "-";
  };

  return (
    <div>
      <Button
        sx={{ color: "white", fontWeight: "600", fontSize: 15 }}
        className={styleClassName || "btn-find m-2 btnsearchd"}
        onClick={() => setDrawerOpen(true)}
      >
        <SearchIcon fontSize="large" style={{ color: iconColor }} />
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <Box sx={{ width: 350, p: 2 }}>
          <Typography variant="h6">Búsqueda rápida de paquetes</Typography>
          <Divider sx={{ mb: 2 }} />

          <AppBar position="static" sx={{ bgcolor: "#f2f2f2", p: 1 }}>
            <Toolbar>
              <Button
                onClick={handleSearch}
                startIcon={<SearchIcon />}
                sx={{
                  width: "150px",
                  background: "#591e8f",
                  color: "#f8f8f8",
                  "&:hover": { backgroundColor: "#4a1468" },
                }}
              >
                Buscar
              </Button>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Código del envío..."
                value={dataSearchOrder}
                onChange={(e) => setDataSearch(e.target.value)}
                sx={{ ml: 2 }}
              />
            </Toolbar>
          </AppBar>

          {isActive && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ my: 2 }}
            />
          )}

          {orderDetails ? (
            <Card sx={{ my: 2 }}>
              <CardHeader title={`Orden: ${orderDetails.id}`} />
              <CardContent>
                <Typography variant="subtitle1">
                  Estado: {orderDetails.int_falabella_status}
                </Typography>
                <NavLink to={`/dashboard/dispatch/details/${orderDetails.id}`}>
                  Ver detalles
                </NavLink>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2">
                  Fecha de creación:{" "}
                  {formatDate(orderDetails.createdAt?.seconds)}
                </Typography>
                <Typography variant="body2">
                  Dirección origen: {orderDetails.fromAddress}
                </Typography>
                <Typography variant="body2">
                  Dirección destino: {orderDetails.toAddress}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <TableNoData
              notFound
              bannerActive={false}
              mensajeBanner="Código no coincide con un resultado de búsqueda. Verifique el código o consulte si la orden fue creada correctamente."
            />
          )}
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
