import { Box } from "@mui/material";
import React from "react";

const ContainerViewComponent = ({ children, maxWidth = "lg", sx = {} }) => {
  return (
    <Box
      sx={{
        maxWidth: maxWidth, // Controla el ancho máximo
        margin: "auto", // Centra el contenido
        padding: 2, // Espaciado interno
        backgroundColor: "background.paper", // Fondo claro
        borderRadius: 2, // Bordes redondeados
        boxShadow: 2, // Sombra ligera
        ...sx, // Permite estilos personalizados
      }}
    >
      {children}
    </Box>
  );
};

export default ContainerViewComponent;
