import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Importa los iconos que necesites
import { StyledCard, StyledTextField, StyledSelect } from "./FromStyles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  Divider,
} from "@mui/material";
import { OutlinedInput } from "@mui/material";
import GooglePlacesAutocomplete from "../../utils/objects/GooglePlacesAutocomplete";
import { Grid } from "@mui/material";
const FromShippingTo = ({
  onChange,
  dataOrigin,
  dataDirectory,
  handleDataOrder,
  companyData,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [method, setThisMethod] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [destinyAddress, setDestinyAddress] = useState({
    clientNames: "",
    clientRut: "",
    userPhone: "",
    toAddress: "",
    clientEmail: "",
    toAddressGeoreft: "",
    direccion1: "",
    direccion2: "",
    direccion3: "N/A",
    commune2: "",
    commune: "",
    region: "",
    countryDestiny: "",
  });
  const [stateDataOrigin, setFormStateDataOrigin] = useState({});

  useEffect(() => {
    handleDataOrder(destinyAddress);
  }, [destinyAddress]);

  useEffect(() => {
    if (dataOrigin) {
      setFormStateDataOrigin(dataOrigin);
    }
  }, [dataOrigin]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDestinyAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelections = (item) => {
    if (item) {
      setDestinyAddress((prevState) => ({
        ...prevState,
        toAddress: item.address,
        toAddressGeoreft: item.addressGeoreft,
        direccion1: item.direccion1,
        direccion2: item.direccion2,
        commune2: item.commune2,
        commune: item.commune,
        region: item.region,
        countryDestiny: item.country,
      }));
    }
  };

  const handleChangeMetod = (e) => {
    setThisMethod(e.target.checked);
  };

  const handleSelect = (event) => {
    const value = event.target.value;
    if (value === companyData.id) {
      setDestinyAddress((prevState) => ({
        ...prevState,
        clientNames: companyData.companyName,
        clientRut: companyData.companyRut,
        userPhone: companyData.companyAdressPhone, // DESARROLLO EL API REGQUIERE VALIDAR  +56
        clientEmail: companyData.companyContacEmail,
        toAddress: companyData.companyAddress,
        toAddressGeoreft: {
          lat: companyData.addressGeoreft.latitude,
          lng: companyData.addressGeoreft.longitude,
        },
        direccion1: companyData.direccion1,
        direccion2: companyData.companyNumAddress,
        commune: companyData.companyCity,
        commune2: companyData.companyComuna,
        region: companyData.companyRegion,
        countryDestiny: companyData.companyCountry,
      }));
      setSelectedAddress(companyData.id);
    } else {
      setSelectedAddress(value);
      const filteredData = dataDirectory.filter((item) => item.id === value);

      setDestinyAddress((prevState) => ({
        ...prevState,
        clientNames: filteredData[0].clientNamesOrigin,
        clientRut: filteredData[0].clientRutOrigin,
        userPhone: companyData.companyAdressPhone,
        clientEmail: companyData.companyContacEmail,
        toAddress: filteredData[0].toAddressOrigin,
        toAddressGeoreft: filteredData[0].addressGeoreft,
        direccion1: filteredData[0].direccion1Origin,
        direccion2: filteredData[0].direccion2Origin,
        commune: filteredData[0].communeOrigin,
        commune2: filteredData[0].commune2Origin,
        region: filteredData[0].regionOrigin,
        countryDestiny: filteredData[0].countryOrigin,
      }));
      setSelectedAddress(filteredData[0].id);
    }
  };

  return (
    <StyledCard elevation={0} variant="outlined">
      <CardHeader
        title="Destino ( Punto B )"
        sx={{
          color: "#fff", // Texto blanco
          borderRadius: "10px 10px 0 0",
          padding: "16px",
        }}
      />
      <CardContent sx={{ padding: "1px" }}>
        <Typography sx={{ color: "#fff" }}>
          {" "}
          {"Dirección de entrega"}
        </Typography>

        <Grid
          item
          sx={{
            width: {
              xs: "50%",
              sm: "50%",
              md: "50%",
              lg: "50%",
            },
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={method}
                onChange={(e) => handleChangeMetod(e)}
                name="seguro"
                color="primary"
              />
            }
            label={
              !method ? (
                <Typography sx={{ color: "#fff" }}>
                  {"Ingrese una direccíon"}
                </Typography>
              ) : (
                <Typography sx={{ color: "#fff" }}>
                  {"Seleccione una Sucursal"}
                </Typography>
              )
            }
          />
        </Grid>
        <>
          {method ? (
            <FormControl fullWidth margin="dense">
              <Select
                sx={{
                  backgroundColor: "#fff",
                  ".MuiOutlinedInput-input": {
                    borderRadius: "10px",
                    padding: "10px",
                    fontSize: "1rem",
                    color: "#333",
                  },
                }}
                labelId="Direcciones de empresa"
                id="toAddressDefault"
                value={selectedAddress}
                onChange={handleSelect}
                label=""
                input={
                  <OutlinedInput
                    notched
                    startAdornment={<LocationOnIcon color="secondary" />}
                  />
                }
              >
                {dataDirectory.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.adressName}
                  </MenuItem>
                ))}

                <MenuItem key={companyData.id} value={companyData.id}>
                  {companyData.companyName} (Default)
                </MenuItem>
              </Select>
            </FormControl>
          ) : (
            <>
              {!destinyAddress.toAddress ? (
                <GooglePlacesAutocomplete
                  adress={destinyAddress}
                  handleSelections={handleSelections}
                ></GooglePlacesAutocomplete>
              ) : (
                <StyledTextField
                  InputProps={{
                    startAdornment: <LocationOnIcon color="secondary" />,
                  }}
                  name="toAddress"
                  value={destinyAddress.toAddress}
                  onChange={handleChange}
                  placeholder="Autocomplete"
                  fullWidth
                  margin="normal"
                />
              )}
            </>
          )}
        </>

        <Divider></Divider>
      </CardContent>

      <Button onClick={() => setShowEdit(!showEdit)} sx={{ color: "#FFF" }}>
        {" "}
        <EditOutlinedIcon> </EditOutlinedIcon>
        {!showEdit ? "Editar" : "Cerrar editor"}{" "}
      </Button>
      {!showEdit ? (
        <CardContent
          sx={{
            padding: "1px",
            backgroundColor: "#fff",
            padding: "15px",
            borderRadius: "15px",
          }}
        >
          <Typography>Nombre: {destinyAddress.clientNames} </Typography>
          <Typography>Teléfono: {destinyAddress.userPhone}</Typography>
          <Typography>RUT:{destinyAddress.clientRut} </Typography>
          <Typography>email:{destinyAddress.clientEmail} </Typography>
          <Divider></Divider>
          <Typography>Calle: {destinyAddress.toAddress} </Typography>
          <Typography>Número: {destinyAddress.direccion2}</Typography>
          <Typography>Ofic / Dpto:{destinyAddress.direccion3} </Typography>
          <Typography>Comuna: {destinyAddress.commune2}</Typography>
          <Typography>Ciudad:{destinyAddress.commune} </Typography>
          <Typography>Región:{destinyAddress.region} </Typography>
          <Typography>País:{destinyAddress.countryDestiny} </Typography>
        </CardContent>
      ) : (
        <CardContent>
          <StyledTextField
            name="clientNames"
            value={destinyAddress.clientNames}
            onChange={handleChange}
            placeholder="Nombre"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="userPhone"
            value={destinyAddress.userPhone}
            onChange={handleChange}
            placeholder="Teléfono"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="clientRut"
            value={destinyAddress.clientRut}
            onChange={handleChange}
            placeholder="RUT"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="clientEmail"
            value={destinyAddress.clientEmail}
            onChange={handleChange}
            placeholder="Correo Electrónico"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />

          <StyledTextField
            name="toAddress"
            value={destinyAddress.toAddress}
            onChange={handleChange}
            placeholder="Calle"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="direccion2"
            value={destinyAddress.direccion2}
            onChange={handleChange}
            placeholder="Número Calle"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="direccion3"
            value={destinyAddress.direccion3}
            onChange={handleChange}
            placeholder="Casa Oficina Departamento"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="commune2"
            value={destinyAddress.commune2}
            onChange={handleChange}
            placeholder="Comuna"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="commune"
            value={destinyAddress.commune}
            onChange={handleChange}
            placeholder="Ciudad"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="Región"
            value={destinyAddress.region}
            onChange={handleChange}
            placeholder="Región"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
          <StyledTextField
            name="countryDestiny"
            value={destinyAddress.countryDestiny}
            onChange={handleChange}
            placeholder="País"
            fullWidth
            margin="normal"
            sx={{ marginTop: "1px", marginBottom: "1px" }}
          />
        </CardContent>
      )}
    </StyledCard>
  );
};

export default FromShippingTo;
