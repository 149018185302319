import { haversineDistance } from "../UtilsOptimize/haversineDistance";

export const assignOrdersToRoutes = (orders, routeCentroids) => {
  return orders.map((order) => {
    if (
      order.toAddressLocation &&
      typeof order.toAddressLocation._lat === "number" &&
      typeof order.toAddressLocation._long === "number"
    ) {
      let bestRoute = null;
      let bestDist = Infinity;

      Object.entries(routeCentroids).forEach(([routeName, centroid]) => {
        const dist = haversineDistance(
          order.toAddressLocation._lat,
          order.toAddressLocation._long,
          centroid.lat,
          centroid.lng
        );

        if (dist < bestDist) {
          bestDist = dist;
          bestRoute = routeName;
        }
      });

      return {
        ...order,
        driverLicensePlate: bestRoute || order.driverLicensePlate,
      };
    }
    return order;
  });
};
