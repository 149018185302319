import { Chip } from "@mui/material";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import FadeInOut from "../../../animations/FadeInOut";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const SuperAdminMenu = () => {
  const [showIntF, setShowIntF] = useState(false);

  const opctionIntMenu = [
    "management-administrators",
    "management-admins-companies",
    "management-companies",
    "management-roles",
    "roles-permisos",
    "management-permisos",
    "management-services",
  ];

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  return (
    <div>
      <Chip
        icon={<AdminPanelSettingsIcon />}
        label=" Super Admin Menu"
        variant="outlined"
        color="primary"
        onClick={() => setShowIntF(!showIntF)}
        sx={{
          color: "#F8F8F8",
          width: "100%",
          fontSize: "1em",
          padding: "2px",
          marginBottom: "5px",
          backgroundColor: opctionIntMenu.includes(splitLocation[2])
            ? "#07b107"
            : "transparent",
        }}
      />

      <FadeInOut show={showIntF} duration={500} parentName="fade7">
        <div className="btn-sidebarLink">Super Admin</div>
        <NavLink
          to="/dashboard/management-administrators"
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "management-administrators"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ManageAccountsIcon fontSize="medium" />
            <span className="btn-title">Gestionar Administradores</span>
          </div>
        </NavLink>
        <NavLink
          to="/dashboard/management-admins-companies"
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "management-admins-companies"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ManageAccountsIcon fontSize="medium" />
            <span className="btn-title">Gestionar Admin Companies</span>
          </div>
        </NavLink>
        <NavLink
          to="/dashboard/management-companies"
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "management-companies"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ManageAccountsIcon fontSize="medium" />
            <span className="btn-title">Gestionar Companies</span>
          </div>
        </NavLink>
        <NavLink to="/dashboard/management-roles" className="btn-sidebarLink">
          <div
            className={
              splitLocation[2] === "management-roles"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ManageAccountsIcon fontSize="medium" />
            <span className="btn-title">Gestionar Roles</span>
          </div>
        </NavLink>
        <NavLink to="/dashboard/roles-permisos" className="btn-sidebarLink">
          <div
            className={
              splitLocation[2] === "roles-permisos"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ManageAccountsIcon fontSize="medium" />
            <span className="btn-title">Roles & Permisos de Servicios</span>
          </div>
        </NavLink>
        <NavLink
          to="/dashboard/management-permisos"
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "management-permisos"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ManageAccountsIcon fontSize="medium" />
            <span className="btn-title">Gestionar permisos</span>
          </div>
        </NavLink>
        <NavLink
          to="/dashboard/management-services"
          className="btn-sidebarLink"
        >
          <div
            className={
              splitLocation[2] === "management-services"
                ? "btn btn-lg btn-block btn-sidebar sidebarLink-active"
                : "btn btn-lg btn-block btn-sidebar"
            }
          >
            <ManageAccountsIcon fontSize="medium" />
            <span className="btn-title">Gestionar Servicios</span>
          </div>
        </NavLink>
        <hr></hr>
      </FadeInOut>
    </div>
  );
};

export default SuperAdminMenu;
