import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  getFirestore,
  orderBy,
  collection,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { addDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as turf from "@turf/turf";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  CircleMarker,
  Polyline,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import * as XLSX from "xlsx";
import moment from "moment";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import * as routeManager from "../MapPanelViewRoutes/RouteManager";
import {
  translateStatesBicciFilter,
  translateStatesfalabbela,
} from "../../utils/objects/translateStates";

import SelectorGeoRecf from "../Dispatchdetails/SelectorGeoRecf";
import MapMenuFlotante from "./MapMenuFlotante/MapMenuFlotante";
import { useMapMenuStates } from "./MapMenuFlotante/states";
import {
  handleApplyFilter,
  handleApplyOptimize,
  handleCancelFilter,
  handleCancelOptimize,
  handleCancelSave,
  handleClosePlansDialog,
  handleNextPage,
  handleOpenFilterDialog,
  handleOpenOptimizeDialog,
  handleOpenSaveDialog,
  handlePrevPage,
  handleSaveDocument,
  handleSelectPlan,
} from "./MapMenuFlotante/handlers";

import FirestoreIndexTester from "./MapMenuFlotante/testDev";
import { colorPalette } from "../../utils/objects/stylesStates";
import StaticPolygonsLayer from "../MapStaticPolygonsLayer/StaticPolygonsLayer";

const MapComponentMonitorLeaflet = ({
  dataMerge,
  ridersDataByCompany,
  loading,
  polygons,
  userdata,
  selectedViewRoutes,
  hoveredWaypoint,
  setHoveredWaypoint,
  routes,
  routeNameArrays,
  setRouteNameArrays,
  optimizedWaypoints,
  routeColors,
  routeGeometry,
  selectedViewLines,
  setSelectedViewLines,
  ridersMonitor,
}) => {
  // console.log("inicial ", dataMerge);
  // console.log("selectedViewRoutes ", selectedViewRoutes);
  // console.log("routeColors ", routeColors);
  // console.log("optimizedWaypoints", optimizedWaypoints);
  // console.log("routeGeometry", routeGeometry);

  const [center] = useState([-33.448891, -70.669266]);
  const [zoom] = useState(13);

  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);

  // -- Estados para dibujo en mapa (shapes) --
  const [drawnItems, setDrawnItems] = useState([]);
  const [selectedMarkers, setSelectedMarkers] = useState([]);

  // ---

  const [colorIndex, setColorIndex] = useState([]);

  //DATOS MASIVOS
  const [viewDataMerge, setViewDataMerge] = useState([]);
  const dataMergeRef = useRef([]);
  const mapRef = useRef(null);

  const customIcon = new L.Icon({
    iconUrl: "/static/images/conductor.png", // 📌 Asegúrate de que esta imagen existe en "public/static/images"
    iconSize: [32, 32], // 🔍 Tamaño del icono
    iconAnchor: [16, 32], // 📍 Punto de anclaje (centro inferior)
    popupAnchor: [0, -32], // 🗨️ Posición del popup
  });

  useEffect(() => {
    setViewDataMerge(dataMerge);
  }, [dataMerge]);

  useEffect(() => {
    if (Array.isArray(optimizedWaypoints) && optimizedWaypoints.length > 0) {
      const generateOrderIndex = (array) => {
        return array.reduce((acc, item) => {
          if (!Array.isArray(item.ids)) return acc; // 🔥 Evita errores si `ids` es undefined o no es un array

          item.ids.forEach((id) => {
            if (id && id !== "origen" && id !== "destino") {
              acc[id] = item.routeId; // 🔥 Asignar el routeId a la orden
            }
          });

          return acc;
        }, {});
      };

      let colors = generateOrderIndex(optimizedWaypoints);

      setColorIndex(colors);
    }
  }, [optimizedWaypoints]);

  useEffect(() => {
    if (selectedViewRoutes.length > 0) {
      // Extraer solo los nombres de las rutas y filtrar valores nulos/vacíos
      const selectedRoutesNames = selectedViewRoutes
        .map((ruta) => ruta.route)
        .filter(Boolean); // Elimina valores undefined, null o ""
      setRouteNameArrays(selectedRoutesNames);
    } else {
      setRouteNameArrays([]);
    }
  }, [selectedViewRoutes]);

  useEffect(() => {
    dataMergeRef.current = viewDataMerge;
  }, [viewDataMerge]);

  useEffect(() => {
    // Evitar actualización innecesaria
    if (
      selectedMarkers.length !== selectedOrders.length ||
      !selectedMarkers.every((id) => selectedOrders.includes(id))
    ) {
      setSelectedOrders(selectedMarkers);
    }
  }, [selectedMarkers]);

  const homeIcon = new L.divIcon({
    className: "custom-icon",
    html: "🏠", // Usa un emoji de casa o cambia por un icono SVG
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  // ------------------------------------------------------------------------
  //  Manejadores para Leaflet Draw (crear, editar, borrar figuras)
  // ------------------------------------------------------------------------
  const handleCreated = (e) => {
    if (!dataMergeRef.current || dataMergeRef.current.length === 0) return;

    const newShape = e.layer.toGeoJSON();
    setDrawnItems((prev) => [...prev, newShape]);

    let polygonCoords = newShape.geometry.coordinates[0];

    // Validar y cerrar polígono si hace falta
    if (polygonCoords.length < 3) return;
    const firstPoint = polygonCoords[0];
    const lastPoint = polygonCoords[polygonCoords.length - 1];
    if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
      polygonCoords.push(firstPoint);
    }

    try {
      const polygon = turf.polygon([polygonCoords]);
      let selectedIds = new Set();

      dataMergeRef.current.forEach((marker) => {
        if (!marker.toAddressLocation) return;
        const position = [
          parseFloat(marker.toAddressLocation._lat),
          parseFloat(marker.toAddressLocation._long),
        ];
        if (isNaN(position[0]) || isNaN(position[1])) return;
        const markerPoint = turf.point([position[1], position[0]]);
        if (turf.booleanPointInPolygon(markerPoint, polygon)) {
          selectedIds.add(marker.id);
        }
      });
      setSelectedMarkers(Array.from(selectedIds));
    } catch (error) {
      console.error("Error al crear el polígono con Turf.js:", error);
    }
  };

  const handleEdited = (e) => {
    const editedShapes = e.layers.toGeoJSON();
    let selectedIds = new Set();

    for (let n = 0; n < editedShapes.features.length; n++) {
      let polygonCoords = editedShapes.features[n].geometry.coordinates[0];
      if (polygonCoords.length < 3) continue;
      const firstPoint = polygonCoords[0];
      const lastPoint = polygonCoords[polygonCoords.length - 1];
      if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
        polygonCoords.push(firstPoint);
      }

      try {
        const polygon = turf.polygon([polygonCoords]);
        dataMergeRef.current.forEach((marker) => {
          if (!marker.toAddressLocation) return;
          const position = [
            parseFloat(marker.toAddressLocation._lat),
            parseFloat(marker.toAddressLocation._long),
          ];
          if (isNaN(position[0]) || isNaN(position[1])) return;
          const markerPoint = turf.point([position[1], position[0]]);
          if (turf.booleanPointInPolygon(markerPoint, polygon)) {
            selectedIds.add(marker.id);
          }
        });
      } catch (error) {}
    }
    setSelectedMarkers(Array.from(selectedIds));
  };

  const handleDeleted = (e) => {};

  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      {/* Contenedor del Mapa */}
      {/* <FirestoreIndexTester></FirestoreIndexTester> */}
      <Box flexGrow={1}>
        <MapContainer
          center={center}
          zoom={zoom}
          style={{ height: "100vh", width: "100%" }}
          ref={mapRef}
        >
          <TileLayer
            attribution={routeManager.attributionBICCI}
            url={routeManager.urlGrey}
          />
          {polygons ? <StaticPolygonsLayer mapVectoriales={polygons} /> : null}
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={handleCreated}
              onEdited={handleEdited}
              onDeleted={handleDeleted}
              draw={{
                rectangle: true,
                polygon: true,
                circle: false,
                marker: false,
                polyline: false,
              }}
            />
          </FeatureGroup>
          {/* Marcadores de órdenes OK */}
          {viewDataMerge.map((order, index) => {
            if (
              !order.toAddressLocation ||
              typeof order.toAddressLocation !== "object"
            )
              return null; // 🔹 Validar que `toAddressLocation` existe y es un objeto

            const lat = parseFloat(order.toAddressLocation._lat);
            const lon = parseFloat(order.toAddressLocation._long);

            if (isNaN(lat) || isNaN(lon)) {
              return null;
            }

            const position = [lat, lon];

            const route = order.driverLicensePlate || "Sin Ruta";
            const color = routeColors[colorIndex[order.id]]
              ? route === "Sin Ruta"
                ? "grey"
                : routeColors[colorIndex[order.id]]
              : "blue";
            const isSelected = selectedMarkers.includes(order.id);

            return (
              <Marker
                key={index}
                position={position}
                icon={routeManager.createSVGIcon(
                  isSelected
                    ? "white"
                    : hoveredWaypoint === order.id
                    ? "#121828"
                    : color,
                  hoveredWaypoint === order.id ? 20 : 9
                )}
                eventHandlers={{
                  click: () => {
                    // Al hacer click en un marcador, marcamos el pedido
                    setSelectedMarkers((prev) =>
                      prev.includes(order.id)
                        ? prev.filter((id) => id !== order.id)
                        : [...prev, order.id]
                    );
                  },
                }}
              >
                <Popup>
                  <Typography variant="caption" display="block">
                    <strong>Orden ID:</strong> {order.id} <br />
                    <strong>Ruta:</strong> {route} {"   "}| Parada: {"   "}
                    {order.orderNumber ? order.orderNumber : "| ⚠️ Up"}
                    <br /> <strong>Dirección:</strong>
                    {"    "}
                    {order.toAddress || "Sin dirección"}
                  </Typography>
                </Popup>
              </Marker>
            );
          })}
          {/* Dibujar la ruta */}
          {routeGeometry && Object.keys(routeGeometry).length > 0 ? (
            <>
              {Object.values(routeGeometry)
                .filter((wp) => selectedViewLines.includes(wp.routeId))
                .map((segment, index) => {
                  const routeName = segment.routeId || `Ruta_${index + 1}`;
                  const color = routeColors[routeName] || "blue";

                  // 🔥 Validamos que segment.geometry tenga datos antes de mapearlo
                  const geometryArray =
                    segment.geometry && typeof segment.geometry === "object"
                      ? Object.values(segment.geometry).filter(
                          (point) =>
                            Array.isArray(point) &&
                            point.length === 2 &&
                            !isNaN(point[0]) &&
                            !isNaN(point[1])
                        ) // 🔹 Filtramos solo coordenadas válidas
                      : [];

                  return geometryArray.length > 0 ? (
                    <Polyline
                      key={`${routeName}-${index}`}
                      positions={geometryArray.map(([lon, lat]) => [lat, lon])} // 🔹 Invertimos coordenadas correctamente
                      color={color}
                      weight={3}
                      opacity={0.7}
                      lineCap="round"
                      stroke={true}
                      fillColor={color}
                      fillOpacity={1}
                    >
                      <Popup>
                        <Typography variant="caption" display="block">
                          <strong>Ruta:</strong> {routeName} <br />
                        </Typography>
                      </Popup>
                    </Polyline>
                  ) : null; // 🔥 Si no hay geometría válida, no renderizar nada
                })}
            </>
          ) : null}
          {/* Dibujar la Paradas ok */}
          {optimizedWaypoints &&
            optimizedWaypoints
              .filter((wp) => selectedViewRoutes.includes(wp.routeId)) // Filtrar por rutas seleccionadas
              .map((wp, index) => {
                try {
                  const lat = parseFloat(wp.location?.lat);
                  const lon = parseFloat(wp.location?.lon || wp.location?.log); // Corrección de log → lon
                  if (isNaN(lat) || isNaN(lon)) {
                    console.warn(
                      `⚠️ Coordenadas inválidas para ${wp.id}:`,
                      wp.location
                    );
                    return null;
                  }

                  if (index === 0) {
                    return (
                      <Marker
                        key={`home-${index}`}
                        position={[lat, lon]}
                        icon={homeIcon}
                      >
                        <Tooltip permanent direction="top">
                          🏡 Origen
                        </Tooltip>
                      </Marker>
                    );
                  } else {
                    return (
                      <CircleMarker key={index} center={[lat, lon]} radius={0}>
                        <Tooltip permanent direction="top">
                          {index === optimizedWaypoints.length - 1
                            ? "Destino"
                            : `P:${wp.index ?? "⚠️"} x ${
                                Array.isArray(wp.ids) ? wp.ids.length : "⚠️"
                              } 📦`}
                        </Tooltip>
                      </CircleMarker>
                    );
                  }

                  // Aquí continúa el código para los otros waypoints...
                } catch (error) {
                  console.log("Error en Marker:", error);
                  return null; // Devuelve `null` en caso de error para evitar fallos en el renderizado.
                }
              })}

          {/* 🔥 Mostrar marcadores solo si hay conductores en línea */}
          {ridersMonitor && Object.keys(ridersMonitor).length > 0 ? (
            Object.entries(ridersMonitor).map(([id, driver]) => {
              if (!driver?.driverLocation) return null; // 🚫 Evitar errores si no hay ubicación

              return (
                <Marker
                  key={id}
                  position={[
                    driver.driverLocation._lat,
                    driver.driverLocation._long,
                  ]} // 📌 Coordenadas del conductor
                  icon={customIcon} // 📍 Icono personalizado
                >
                  <Popup>
                    <strong>🚗 Conductor:</strong> {driver.name} <br />
                    <strong>📍 Ubicación:</strong> {driver.driverLocation.lat},{" "}
                    {driver.driverLocation.lng}
                  </Popup>
                </Marker>
              );
            })
          ) : (
            <p>No hay conductores en línea.</p>
          )}
        </MapContainer>
      </Box>
    </Box>
  );
};

export default MapComponentMonitorLeaflet;
