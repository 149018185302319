import React, { useEffect, useState } from "react";
import { Polygon, Popup } from "react-leaflet";

const StaticPolygonsLayer = ({ mapVectoriales }) => {
  const [polygons, setPolygons] = useState([]);

  useEffect(() => {
    if (!mapVectoriales) return;

    const leafletPolygons = mapVectoriales.features.flatMap(
      (feature, index) => {
        if (feature.geometry.type === "Polygon") {
          const paths = feature.geometry.coordinates[0].map(([lng, lat]) => [
            lat,
            lng,
          ]);

          const getRandomColor = () => {
            const colors = [
              "#FF5733",
              "#33FF57",
              "#3357FF",
              "#FF33A8",
              "#FFD433",
            ];
            return colors[Math.floor(Math.random() * colors.length)];
          };

          const polygonColor = feature.properties.color || getRandomColor();

          return (
            <Polygon
              key={index}
              positions={paths}
              pathOptions={{
                color: polygonColor,
                weight: 1,
                fillOpacity: 0.3,
                fillColor: polygonColor,
              }}
            >
              <Popup>
                <div>
                  <strong>{feature.properties.Name}</strong>
                </div>
              </Popup>
            </Polygon>
          );
        }
        return [];
      }
    );

    setPolygons(leafletPolygons);
  }, [mapVectoriales]);

  return <>{polygons}</>;
};

export default StaticPolygonsLayer;
