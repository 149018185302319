import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ingresoUsuarioAccion } from "../../../redux/usuarioDucks";
import { Box } from "@mui/material";

const BtnAceceder = () => {
  const loading = useSelector((store) => store.usuario.loading);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "150px",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <button
          className="btn"
          onClick={() => dispatch(ingresoUsuarioAccion())}
          disabled={loading}
        >
          Acceso rapido con:
          <Box
            component="img"
            src={
              "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            }
            alt=""
            sx={{ width: "100%", height: "auto" }}
          />
        </button>
      </Box>
    </Box>
  );
};

export default BtnAceceder;
