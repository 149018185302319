import React from "react";
import EmptyContent from "../EmptyContent/EmptyContent";
import { Box } from "@mui/material";

const TableNoDataBox = ({ mensajeBanner, bannerActive, sx }) => {
  return (
    <Box>
      <EmptyContent
        filled
        title={
          mensajeBanner
            ? mensajeBanner
            : "Esperando resultados, confirme si el rango de fecha es válido."
        }
        sx={{
          py: 10,
          ...sx,
        }}
        bannerActive={bannerActive}
      />
    </Box>
  );
};

export default TableNoDataBox;
