import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  Avatar,
  CircularProgress,
} from "@mui/material";
import Resizer from "react-image-file-resizer";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
let renderCount = 0;
const UploadFotoEntrega = ({
  foto,
  setFoto,
  setTokensPics,
  storage,
  orderID,
  collectionRef,
  dispatch,
  registrarCambioEnOrden,
  usuarioStorage,
  handleDeleteImage,
}) => {
  const [loading, setLoading] = useState(false);
  const firstRender = useRef(true);

  // Contador de renders (incluso sin useEffect)

  useEffect(() => {
    const fetchFotos = async () => {
      const orderRef = doc(collectionRef, orderID);
      const orderSnapshot = await getDoc(orderRef);
      const orderData = orderSnapshot.data();
      const tokensPics = orderData.pics || [];
      setFoto(tokensPics); // ⚠️ Si setFoto cambia la dependencia, esto puede generar bucles
    };

    if (firstRender.current) {
      fetchFotos();
      firstRender.current = false;
    }
  }, [foto]);

  const handleImageLoad = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const metadata = { contentType: file.type };
    let newFoto = foto.length + 1;

    setLoading(true);

    Resizer.imageFileResizer(
      file,
      480,
      640,
      "JPEG",
      100,
      0,
      (uri) => cropImage(uri, newFoto, metadata),
      "base64",
      480,
      640
    );
  };

  const cropImage = (imageDataUrl, newFoto, metadata) => {
    try {
      const img = new Image();
      img.src = imageDataUrl;

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const targetWidth = 480;
        const targetHeight = 640;
        const aspectRatio = targetWidth / targetHeight;

        let width = img.width;
        let height = Math.floor(width / aspectRatio);
        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);

        width = targetWidth;
        height = Math.floor(width / aspectRatio);
        const finalCanvas = document.createElement("canvas");
        const finalContext = finalCanvas.getContext("2d");
        finalCanvas.width = targetWidth;
        finalCanvas.height = targetHeight;
        finalContext.drawImage(
          canvas,
          0,
          0,
          width,
          height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        const stretchedImageDataUrl = finalCanvas.toDataURL("image/jpeg", 1.0);
        upLoadImage(stretchedImageDataUrl, newFoto, metadata);
      };
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const upLoadImage = (cropImageResult, newFoto, metadata) => {
    try {
      const dataUrl = cropImageResult.split(",")[1];
      uploadString(
        ref(storage, `orders/${orderID}/image${newFoto}.jpg`),
        dataUrl,
        "base64",
        metadata
      )
        .then(async (snapshot) => {
          const downloadURL = await getDownloadURL(snapshot.ref);
          setTokensPics((prev) => [...prev, downloadURL]);
          await savePics(downloadURL);
        })
        .catch((error) => {
          console.error("Error al cargar la imagen:", error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const savePics = async (newToken) => {
    let data = {
      date: new Date(),
      lastValue: "image",
      newValue: newToken,
      orderId: orderID,
      type: "infoAdd",
      userId: usuarioStorage.uid,
      var: "pics",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef = doc(collectionRef, orderID);
    const orderSnapshot = await getDoc(orderRef);
    const orderData = orderSnapshot.data();
    const tokensPics = orderData.pics || [];

    const arrayPics = [...tokensPics, newToken];
    await updateDoc(orderRef, { pics: arrayPics, picsTaken: true });
    setFoto(arrayPics);
    setLoading(false);
  };

  return (
    <Box>
      <Divider sx={{ my: 1 }} />
      <Typography variant="h6" color="primary" gutterBottom>
        Foto de Entrega
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button variant="contained" component="label" sx={{ mb: 2 }}>
          {loading ? (
            <CircularProgress size={24} sx={{ color: "#fff" }} />
          ) : (
            "Subir Imagen"
          )}
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleImageLoad}
          />
        </Button>
      </Box>

      {foto.length > 0 ? (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {foto.map((item, index) => (
            <Box key={index} sx={{ position: "relative" }}>
              <Avatar
                src={item}
                alt={`Foto ${index}`}
                sx={{
                  borderRadius: "8px",
                  width: "150px",
                  height: "150px",
                  boxShadow: 2,
                }}
              />
              {(usuarioStorage.uid === process.env.REACT_APP_SUPORTRADMIN ||
                usuarioStorage.uid === process.env.REACT_APP_SUPERADMIN) && (
                <Button
                  variant="contained"
                  sx={{
                    position: "absolute",
                    top: -5,
                    right: -5,
                    backgroundColor: "red",
                    minWidth: "30px",
                    minHeight: "30px",
                    fontSize: "12px",
                    p: 0,
                    "&:hover": {
                      backgroundColor: "darkred",
                    },
                  }}
                  onClick={() => handleDeleteImage(index)}
                >
                  ✕
                </Button>
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <Typography
          variant="body2"
          component="div"
          color="error"
          sx={{ textAlign: "center", mt: 2 }}
        >
          Sin Foto
        </Typography>
      )}

      {foto.length > 0 && (
        <Box sx={{ mt: 1, textAlign: "left" }}>
          {foto.map((item, index) => (
            <Typography key={index}>{item}</Typography>
          ))}
        </Box>
      )}

      <Divider sx={{ my: 1 }} />
    </Box>
  );
};

export default UploadFotoEntrega;
