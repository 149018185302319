import React, { useState } from "react";

import { Card, Divider, CardHeader, Grid2 } from "@mui/material";
import FadeInOut from "../../../components/animations/FadeInOut";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const Configuraciones = () => {
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => setShowEdit(!showEdit);

  return (
    <Grid2 celled="internally" id="expresscuentaContent">
      <h2>Configuraciones </h2>

      <Grid2>
        <Grid2
          computer={8}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            <CardHeader subheader="subheader" title="Agregar" />
            <Divider />
            <FadeInOut show={showEdit} duration={500}>
              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                <hr></hr>
              </ContainerViewComponent>
            </FadeInOut>

            <Divider />
          </Card>
        </Grid2>

        <Grid2
          computer={8}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            <CardHeader subheader="Resumenes" title="Metrica" />
            <Divider />
            <FadeInOut show={showEdit} duration={500}>
              <ContainerViewComponent
                className="formcontent"
                id="micuentafromIni"
              >
                <hr></hr>
              </ContainerViewComponent>
            </FadeInOut>

            <Divider />
          </Card>
        </Grid2>

        <Grid2
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            <CardHeader subheader="subheader" title="Usuarios Actuales" />
            <Divider />
            <ContainerViewComponent
              className="formcontent"
              id="micuentafromIni"
            >
              <hr></hr>
            </ContainerViewComponent>

            <Divider />
          </Card>
          <div></div>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Configuraciones;
