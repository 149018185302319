import React, { useState, useEffect, useCallback } from "react";
import { crearUsuarioAccion } from "../../redux/usuarioDucks";
import { useDispatch, useSelector } from "react-redux";
import BtnAceceder from "../Login/buttons/BtnAceceder";
import BtnTebgoCuenta from "../Login/buttons/BtnTebgoCuenta";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";
import {
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const FormRegistro = () => {
  const resperror = useSelector((store) => store.usuario.error);

  const activo = useSelector((store) => store.usuario.activo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setName] = useState("");
  //  console.log (username);
  const [userLastName, setUserLastName] = useState("");
  //  console.log (userLastName);
  const [companyName, setCompanyName] = useState("");
  //  console.log (companyName);
  const [email, setEmail] = useState("");
  //  console.log (email);
  const [phone, setPhone] = useState("");
  //  console.log (phone);
  const [userPreferenCont, setUserPreferenCont] = useState("precencial");
  //  console.log (userPreferenCont);
  const [pass, setPass] = useState("");
  const [userPassConfirm, setPassConfirm] = useState("");
  //  console.log (userPassConfirm);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [userType, setUserType] = useState("empresa");
  //  console.log (userType);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (activo) {
      navigate("/dashboard/mi-cuenta");
    }
  }, [activo]);

  const registrarUsuarios = (e) => {
    e.preventDefault();
    if (!username.trim()) {
      //    // console.log('El campo nombre es obligatorio')
      setError("El campo nombre es obligatorio");
      return;
    }
    if (!userLastName.trim()) {
      //    // console.log('El campo apellido es obligatorio')
      setError("El campo apellido es obligatorio");
      return;
    }
    if (!email.trim()) {
      // console.log('Ingrese Email')
      setError("Ingrese Email");
      return;
    }
    if (!phone.trim()) {
      // console.log('Ingrese un numero telefonico')
      setError("Ingrese un numero telefonico");
      return;
    }
    if (!pass.trim()) {
      // console.log('Ingrese Contraseña')
      setError("Ingrese Contraseña");
      return;
    }
    if (!userPreferenCont.trim()) {
      // console.log('Seleccione un medio de contacto')
      setError("Seleccione un medio de contacto");
      return;
    }
    if (!userType.trim()) {
      // console.log('Seleccione un tipo de usuario')
      setError("Seleccione un tipo de usuario");
      return;
    }
    if (pass.length < 5) {
      // console.log('Contraseña mayor a 6 caracteres')
      setError("Contraseña mayor a 6 caracteres");
      return;
    }
    if (!userPassConfirm.trim()) {
      // console.log('Ingrese una confirmacion de contraseña')
      setError("Ingrese una confirmacion de contraseña");
      return;
    }
    if (pass === userPassConfirm) {
      // console.log('contraseñas iguales')
    } else {
      // console.log('Debe ingresar contraseñas iguales')
      setError("Debe ingresar contraseñas iguales");
      return;
    }

    if (!checked === true) {
      // console.log('Debe aceptar los terminos y condiciones')
      setError("Debe aceptar los terminos y condiciones");
      return;
    }
    if (!resperror === null) {
      // console.log('Error de acceso')
      setError("Error de acceso");
      return;
    }
    // console.log('Validando usuario...')
    setError(null);
    Login();
  };

  const handleChange = () => {
    setChecked(!checked);
    //  console.log(checked);
  };
  if (checked === true) {
    //   console.log("Acepta terminos y condiciones");
  }

  const Login = useCallback(async () => {
    // console.log('is login')
    setSuccess(null);
    try {
      const userData = {
        username: username,
        userLastName: userLastName,
        email: email,
        pass: pass,
        userPhone: phone,
        userPreferenCont: userPreferenCont,
        userType: userType,
        checked: checked,
        companyName: companyName,
      };
      dispatch(crearUsuarioAccion(userData));
    } catch (error) {
      // console.log(error)
    }
  });

  return (
    <ContainerViewComponent className="formcontent" id="ajustefromIni">
      <hr></hr>

      <form onSubmit={registrarUsuarios}>
        {success && (
          <Typography variant="body1" color="success.main" sx={{ mb: 2 }}>
            {success}
          </Typography>
        )}

        {error && (
          <Typography variant="body1" color="error.main" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {resperror && (
          <Box
            sx={{
              backgroundColor: "#f8d7da",
              color: "#721c24",
              p: 2,
              borderRadius: 1,
              mb: 2,
            }}
          >
            <Typography variant="body1">{resperror}</Typography>
            <Button
              component={NavLink}
              to="/recuperar-cuenta"
              variant="text"
              color="primary"
            >
              Recuperar contraseña
            </Button>
          </Box>
        )}

        <TextField
          fullWidth
          label="Nombre"
          variant="outlined"
          value={username}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Apellido"
          variant="outlined"
          value={userLastName}
          onChange={(e) => setUserLastName(e.target.value)}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Nombre de Empresa"
          variant="outlined"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Teléfono"
          type="text"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Contraseña"
          type="password"
          variant="outlined"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Repita Contraseña"
          type="password"
          variant="outlined"
          value={userPassConfirm}
          onChange={(e) => setPassConfirm(e.target.value)}
          sx={{ mb: 2 }}
        />

        <FormControlLabel
          control={<Checkbox checked={acceptTerms} onChange={handleChange} />}
          label="Acepto términos y condiciones de BICCI."
          sx={{ mb: 2 }}
        />

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              backgroundColor: "#591E8F",
              color: "#fff",
              borderRadius: "5px",
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#25b2f4",
                color: "#591e8f",
              },
            }}
          >
            Registrarse e Iniciar
          </Button>
        </Box>
      </form>
      <hr></hr>
      <div>
        <BtnAceceder></BtnAceceder>
        <div>
          <BtnTebgoCuenta></BtnTebgoCuenta>
        </div>
      </div>
    </ContainerViewComponent>
  );
};

export default FormRegistro;
