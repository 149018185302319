import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid2 } from "@mui/material";

import { getCart } from "../../../redux/acccionesCart";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

const Navbar = (props) => {
  const dispatch = useDispatch();
  const activo = useSelector((store) => store.usuario.activo);
  const cart = useSelector((store) => store.cartAccion.cart);

  const [cartcontent, setCartcontent] = React.useState(cart);
  const history = useNavigate();

  const handleMouseEnter = () => {
    props.handleshowminicart();
  };

  const handleMouseLeave = () => {};

  React.useEffect(() => {
    dispatch(getCart());
  }, []);

  React.useEffect(() => {
    if (cart) {
      setCartcontent(cart.length);
    }
  }, [activo, cart]);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Grid2 className="navbarbici">
      <Grid2 className="navbarconntainer" id="navbarconntainer">
        <ResponsiveAppBar
          open={open}
          handleClick={handleClick}
          props={props}
          activo={activo}
        ></ResponsiveAppBar>
      </Grid2>
      {/* <Grid2 className="navbarconntainer" id="navbarconntainer">
        <Grid2 className="navbarNaw">
          <Grid2
            computer={4}
            mobile={8}
            tablet={4}
            className="navbar__left"
          >
            <div className="logo-top-container">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </Grid2>
          <Grid2
            computer={4}
            mobile={4}
            tablet={4}
            className="navbar__center"
          >
            <Movilnavbar props={props} activo={activo} />
          </Grid2>
          <Grid2
            computer={8}
            mobile={3}
            tablet={8}
            className="navbar____rigth"
          >
            <div id="socialcontainer">
              <Tooltip title="Carrito">
                <IconButton
                  sx={{ ml: 1, color: "#591E8F" }}
                  //onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => history.push("/cart")}
                >
                  <Badge badgeContent={cartcontent} color="secondary">
                    <ShoppingBag fontSize="mediun" />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Link className="btn-bicci-prupura-color" to="#">
                <span>
                  <FacebookIcon></FacebookIcon>
                </span>
              </Link>
              <Link className="btn-bicci-prupura-color" to="#">
                <span>
                  <InstagramIcon></InstagramIcon>
                </span>
              </Link>
            </div>
          </Grid2>
        </Grid2>
      </Grid2> */}
    </Grid2>
  );
};

export default Navbar;
