import React from "react";
import { format } from "date-fns";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

const CierreOpsPdf = (props) => {
  const [data, setData] = React.useState(props.metricsOps || []);
  const [localMetrics, setLocalMetrics] = React.useState(null);
  const [totalArrayVehicles, setTotalArrayVehicles] = React.useState([]);
  const [totalArrayComunas, setTotalArrayComunas] = React.useState([]);
  const [totalHeaders, setTotalHeaders] = React.useState(props.cant || {});
  const [createBy, setCreateBy] = React.useState(props.userUid || "");
  const [localMetricsOpsStores, setLocalMetricsOpsStores] = React.useState(
    props.metricsOpsStores || []
  );
  const [endDate, setEndDate] = React.useState(props.endDate || "");
  const [initDate, setInitDate] = React.useState(props.initDate || "");

  React.useEffect(() => {
    if (props.metricsOps) {
      setData(props.metricsOps);
      setTotalHeaders(props.cant || {});
      setCreateBy(props.userUid || "");
      setEndDate(props.endDate || "");
      setInitDate(props.initDate || "");
      setLocalMetricsOpsStores(props.metricsOpsStores || []);
      processMetrics(props.metricsOps);
    }
  }, [
    props.metricsOps,
    props.cant,
    props.userUid,
    props.endDate,
    props.initDate,
  ]);

  const processMetrics = (data) => {
    let totalRider = 0,
      totalVehicles = [],
      totalAssing = 0,
      totalDelivery = 0,
      totalPending = 0,
      totalZones = [];

    data.forEach((item) => {
      if (item.driverName) {
        totalRider++;
        totalAssing += item.asignados || 0;
        totalDelivery += item.delivery || 0;
        totalPending += item.issures || 0;
      } else {
        totalPending += item.issures || 0;
      }
      totalVehicles.push(item.vehicleDetailsID);
      totalZones.push(item.locality);
    });

    let ntsPerformanse = totalAssing
      ? ((totalDelivery * 100) / totalAssing).toFixed(2)
      : "0";

    const vehicleCount = {};
    totalVehicles.forEach((vehicle) => {
      if (vehicle) {
        vehicleCount[vehicle] = (vehicleCount[vehicle] || 0) + 1;
      }
    });

    const totalArrayVehicles = Object.entries(vehicleCount).map(
      ([name, value]) => ({
        name,
        value,
      })
    );

    const zoneCount = {};
    totalZones.flat().forEach((zone) => {
      const key = Object.keys(zone)[0];
      const value = Object.values(zone)[0];
      zoneCount[key] = (zoneCount[key] || 0) + parseInt(value, 10);
    });

    const totalArrayComunas = Object.entries(zoneCount).map(
      ([name, value]) => ({
        name,
        value,
      })
    );

    setTotalArrayVehicles(totalArrayVehicles);
    setTotalArrayComunas(totalArrayComunas);
    setLocalMetrics({
      totalRider,
      totalVehicles: totalArrayVehicles,
      totalAssing,
      totalDelivery,
      totalPending,
      ntsPerformanse,
      totalZones: zoneCount,
      cantTotalZones: Object.keys(zoneCount).length,
    });
  };

  const styles = StyleSheet.create({
    page: { padding: 20 },
    section: { textAlign: "left", margin: 10 },
    img: { width: 120 },
    text: { fontSize: 12 },
    table: {
      fontSize: 10,
      width: "100%",
      marginTop: 20,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "black",
      borderRadius: 5,
      overflow: "hidden",
    },
    tableRow: { flexDirection: "row", backgroundColor: "#f1f1f1" },
    tableCol: {
      width: "14%",
      fontWeight: "bold",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "black",
      padding: 3,
      textAlign: "center",
    },
    tableColBig: {
      width: "14%",
      fontWeight: "bold",
      fontSize: "20px",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "black",
      padding: 3,
      textAlign: "center",
    },
  });

  return (
    <Document>
      {/* Página principal con resumen */}
      <Page size="A4" style={styles.page}>
        <Image
          src={"/static/images/bicciexpress.png"}
          alt=""
          style={styles.img}
        ></Image>
        <Text style={styles.text}>Reporte de Operaciones</Text>
        <Text style={styles.text}>Emitido por: {createBy}</Text>
        <Text style={styles.text}>
          Fecha: {format(Date.now(), "dd/MM/yyyy HH:mm:ss")}
        </Text>
        <Text style={styles.text}>
          Periodo: {initDate} - {endDate}
        </Text>

        {localMetrics && (
          <View>
            <Text style={styles.text}>
              Total de Órdenes: {totalHeaders.Total}
            </Text>
            <Text style={styles.text}>
              Éxito: {localMetrics.ntsPerformanse}%
            </Text>
            <Text style={styles.text}>
              Total de Excepciones: {totalHeaders.Incidencias}
            </Text>
          </View>
        )}

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Total Riders</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Excepciones</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Entregados</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Pendientes</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Conductores</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Éxito</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Zonas visitadas</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableColBig}>
              <Text>{localMetrics?.totalRider || 0}</Text>
            </View>
            <View style={styles.tableColBig}>
              <Text>{totalHeaders.Incidencias}</Text>
            </View>
            <View style={styles.tableColBig}>
              <Text>{localMetrics?.totalDelivery || 0}</Text>
            </View>
            <View style={styles.tableColBig}>
              <Text>{localMetrics?.totalPending || 0}</Text>
            </View>
            <View style={styles.tableColBig}>
              <Text>{localMetrics?.totalRider || 0}</Text>
            </View>
            <View style={styles.tableColBig}>
              <Text>{localMetrics?.ntsPerformanse}%</Text>
            </View>
            <View style={styles.tableColBig}>
              <Text>{localMetrics?.cantTotalZones || 0}</Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Riders</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Vehículo</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Asignados</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Finalizados</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Pendientes</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Éxito</Text>
            </View>
          </View>

          {data.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{item.driverName || "N/A"}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>
                  {item.vehicleDetailsID || "No registrado"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{item.asignados}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{item.delivery}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{item.issures}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>
                  {item.asignados
                    ? ((item.delivery * 100) / item.asignados).toFixed(2)
                    : "0"}
                  %
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>

      {/* Página con Resumen por Comuna */}
      <Page style={styles.page}>
        <Text style={styles.text}>Resumen Folios por Comuna</Text>
        <View style={styles.table}>
          {totalArrayComunas.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.value}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={{ margin: 10 }}></Text>
        <Text style={styles.text}>Resumen Tipo de Vehículo</Text>
        <View style={styles.table}>
          {totalArrayVehicles.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text>{item.name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.value}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default CierreOpsPdf;
