import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";

const BulkCreationObject = () => {
  return (
    <div id="bulk-creation">
      <Typography variant="h4" gutterBottom sx={{ color: "#030303" }}>
        Creación Masiva
      </Typography>
      <p>Información sobre la creación masiva de registros.</p>
      <p> Se recomienda hasta 900 órdenes en cada lote</p>

      <div id="bulk-creation-variables">
        <Box p={3} sx={{ color: "#030303" }}>
          <Typography variant="h4" gutterBottom>
            API Request Example
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Tipo de Dato</TableCell>
                  <TableCell>Ejemplo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>URL</TableCell>
                  <TableCell colSpan={2}>
                    https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexImportOrdersLastMille/upload
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Método</TableCell>
                  <TableCell colSpan={2}>POST</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Headers</TableCell>
                  <TableCell colSpan={2}>
                    <pre>
                      {`
                      headers: { 
                        'companyID': 'FroY2jsadjhsakdkaJHJA', 
                        'Authorization': '••••••', 
                        ...data.getHeaders()
                      },
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Auth Type</TableCell>
                  <TableCell>Bearer Token</TableCell>
                  <TableCell>Token generado vía API</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>companyID</TableCell>
                  <TableCell> FroY2jsadjhsakdkaJHJA</TableCell>
                  <TableCell>
                    CODIGO DE EMPRESA, entregado en el inicio de la integración.{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Body</TableCell>
                  <TableCell>
                    <pre>
                      {`
                    "file": DOCS.xlsx";
                    `}
                    </pre>
                  </TableCell>
                  <TableCell>Documento para creacion masiva,</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Options</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      maxBodyLength: Infinity,
                      `}
                    </pre>
                  </TableCell>
                  <TableCell>
                    Sugerido para cargas superiores a los 300 ordenes
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" gutterBottom>
            Ejemplo de Respuesta
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Respuesta Correcta</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      {
                        "message": "Orders processed successfully",
                        "orders": 3 
                      }
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Respuesta Errónea</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      {
                          "message": "Validation failed",
                          "errors": "Filas Sin ID's en Documentos"
                      }
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Respuesta Errónea</TableCell>
                  <TableCell>
                    <pre>
                      {`
                      {
                        "result": {
                          "code": 400,
                          "message": "Error al subir el archivo"
                        }
                      }
                      `}
                    </pre>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        );
      </div>
    </div>
  );
};

export default BulkCreationObject;
