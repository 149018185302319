import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Button,
} from "@mui/material";
import SelectorGeoRecf from "../Dispatchdetails/SelectorGeoRecf";

const EditOrderDialogGeoref = ({
  open,
  onClose,
  onSave,
  editOrder,
  setEditOrder,
  newLocation,
  setNewLocation,
}) => {
  console.log(editOrder);
  const handleGeoSave = () => {
    if (newLocation && newLocation.lat && newLocation.lng) {
      setEditOrder((prev) => ({
        ...prev,
        toAddressLocation: {
          latitude: newLocation.lat,
          longitude: newLocation.lng,
        },
      }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Orden {editOrder && editOrder.id}</DialogTitle>
      <DialogContent>
        {editOrder && (
          <>
            <Typography gutterBottom variant={"h3"} sx={{ mt: 2, mb: 1 }}>
              {editOrder.address ? editOrder.address : editOrder.toAddress}
            </Typography>
            <Typography gutterBottom sx={{ mt: 2, mb: 1 }}>
              Ajustar Geo referencia de entrega:
            </Typography>

            {editOrder.toAddressLocation ? (
              <Box>
                <Typography variant="body2">Geo referencia actual:</Typography>
                <Typography variant="body2">
                  Lat:{" "}
                  {editOrder.toAddressLocation.latitude ||
                    editOrder.toAddressLocation._lat}
                  , Lng:{" "}
                  {editOrder.toAddressLocation.longitude ||
                    editOrder.toAddressLocation._long}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2">
                No hay georeferencia actual.
              </Typography>
            )}

            <Box mt={3}>
              <Typography variant="body2" fontWeight="bold">
                Ingreso Manual de Georreferencia
              </Typography>
              <Box display="flex" gap={2} mt={1}>
                <TextField
                  label="Latitud"
                  type="number"
                  fullWidth
                  value={newLocation?.lat ?? ""}
                  onChange={(e) =>
                    setNewLocation((prev) => ({
                      ...prev,
                      lat: parseFloat(e.target.value),
                    }))
                  }
                />
                <TextField
                  label="Longitud"
                  type="number"
                  fullWidth
                  value={newLocation?.lng ?? ""}
                  onChange={(e) =>
                    setNewLocation((prev) => ({
                      ...prev,
                      lng: parseFloat(e.target.value),
                    }))
                  }
                />
              </Box>
            </Box>

            {newLocation && newLocation.lat ? (
              <Box mt={1}>
                <Typography variant="body2">
                  Nueva Geo referencia Seleccionada:
                </Typography>
                <Typography variant="body2">
                  Lat: {newLocation.lat.toString()}, Lng:{" "}
                  {newLocation.lng.toString()}
                </Typography>
                <Button
                  sx={{
                    backgroundColor: "#591E8F",
                    color: "#fff",
                    mt: 1,
                    ":hover": {
                      backgroundColor: "#25b2f4 !important",
                      color: "#591e8f",
                    },
                  }}
                  variant="contained"
                  onClick={handleGeoSave}
                >
                  Guardar Geo Referencia
                </Button>
              </Box>
            ) : null}

            <Box mt={2}>
              <SelectorGeoRecf
                markersPending={editOrder.toAddressLocation}
                setNewLocation={setNewLocation}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSave} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderDialogGeoref;
