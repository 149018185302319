import React, { useState, useEffect } from "react";
import { doc, collection, getDocs, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Grid2,
  Toolbar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  Button,
  Divider,
} from "@mui/material";
import TabSecondary from "../Dispatchdetails/TabSecondary";
import {
  stlyleClassStates,
  styleState,
} from "../../utils/objects/stylesStates";
import {
  translateStatesBicciFilter,
  translateStatesfalabbela,
} from "../../utils/objects/translateStates";
import Moment from "moment";
import { useOrders } from "../../hooks/useOrdersProviders/OrdersContext";
import { NavLink } from "react-router-dom";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";
import TableNoDataBox from "../Table/TableNoDataBox";
import { TypeFormatFlags } from "typescript";

const SearchDoc = (props) => {
  const [dataSearchOrder, setDataSearch] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [orderDetailsCliente, setOrderDetailsClient] = useState(null);
  const [dataOrdesBicciExpress, setDataOrdesBicciExpress] = useState(null);
  const [dispachtHistory, setDispachtHistory] = useState(null);
  const [prefixes, setPrefixes] = useState([]);
  const [progressValue, setProgressValue] = useState([]);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");

  const { companiesData } = useOrders({ initDateInitial, endDateInitial });

  useEffect(() => {
    if (companiesData) {
      const prefixes = companiesData
        .filter((item) => item.prefix)
        .map((item) => item.prefix);
      setPrefixes(prefixes);
    }
  }, [companiesData]);

  const handleSeach = async () => {
    if (dataSearchOrder !== null && dataSearchOrder !== "" && dataSearchOrder) {
      // const result = await getOrdesrDispachtByAny(dataSearchOrder.toString());
      // console.log(result);
      let result = await findOrdesrDispacht(dataSearchOrder, prefixes);

      // if (result !== null) {
      //   setOrderDetails(result);

      //   findOrdesrDispacht(result.id);
      // } else {
      //   setOrderDetails(false);
      // }
    } else {
      console.log("parametro invalido");
      let result = await findOrdesrDispacht(dataSearchOrder, prefixes);
    }
  };

  const tryGetDoc = async (collection, id) => {
    const orderRef = doc(db, collection, id);
    const docSnapshot = await getDoc(orderRef);
    return docSnapshot.exists() ? docSnapshot.data() : null;
  };

  const tryGetDocHistory = async (collectionSeacrh, id) => {
    const orderRef = doc(db, collectionSeacrh, id);
    const statusDocs = collection(orderRef, "statuses");
    const dataallOrdersfind5 = await getDocs(statusDocs);
    const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    datadocuments.sort((a, b) =>
      a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
    );

    return datadocuments.length > 0 ? datadocuments : [];
  };

  const findOrdesrDispacht = async (id, prefixes) => {
    setDataSearch("");
    let foundDocumentData = null;

    const collections = [
      process.env.REACT_APP_COL_USERS_ORDERS,
      // process.env.REACT_APP_COL_ORDERS,
      // process.env.REACT_APP_COL_FALABELLA_ORDERS,
    ];

    try {
      for (let collection of collections) {
        for (let prefix of prefixes) {
          let idWithPrefix = prefix + id;

          // Busca el documento con el prefijo correspondiente
          let documentData = await tryGetDoc(collection, idWithPrefix);
          if (documentData) {
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(20);

          // Si no se encuentra, busca sin el prefijo
          documentData = await tryGetDoc(collection, id);
          if (documentData) {
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(30);

          // documentData = await tryQueryByAttributes(collection, "id", id);
          // if (documentData) {
          //   foundDocumentData = documentData;
          //   break;
          // }

          setProgressValue(40);

          if (documentData) {
            foundDocumentData = documentData;
            break;
          }
        }

        if (foundDocumentData) {
          setOrderDetails(foundDocumentData);
          let documentDataHistory = await tryGetDocHistory(
            collection,
            foundDocumentData.id
          );

          setDispachtHistory(documentDataHistory);

          setProgressValue(100);
          break;
        }
      }
    } catch (error) {}

    // try {
    //   const ordesBicciexpres = await getDocs(
    //     query(
    //       collection(db, process.env.REACT_APP_COL_ORDERS),
    //       where("orderID", "==", id)
    //     )
    //   );
    //   var dataOrdes = ordesBicciexpres.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));

    //   const dataOrdesBicciExpress = dataOrdes;
    //   setDataOrdesBicciExpress(dataOrdesBicciExpress);
    // } catch (error) {
    //   setDataOrdesBicciExpress({ error: "no found" });
    // }

    // try {
    //   const clearID = id.replace(/F/g, "");
    //   const falabellaOrderRef = collection(
    //     db,
    //     process.env.REACT_APP_COL_FALABELLA_ORDERS
    //   );
    //   const falabellaOrderData = await getDoc(doc(falabellaOrderRef, clearID));

    //   if (falabellaOrderData.exists()) {
    //     const orderDataSoc = falabellaOrderData.data();
    //     setOrderDetailsClient(orderDataSoc);
    //   } else {
    //     setOrderDetailsClient({ error: "Pedido no encontrado" });
    //   }
    // } catch (error) {
    //   setOrderDetailsClient({
    //     error: "Ocurrió un error al buscar el pedido",
    //   });
    // }

    // try {
    //   const clearID = id.replace(/F/g, "");
    //   const falabellaOrderRef = collection(
    //     db,
    //     process.env.REACT_APP_COL_FALABELLA_ORDERS
    //   );

    //   const statusRef = doc(falabellaOrderRef, clearID.toString());

    //   const statusDocs = collection(statusRef, "status");

    //   const dataallOrdersfind5 = await getDocs(statusDocs);
    //   const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));

    //   datadocuments.sort((a, b) =>
    //     a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
    //   );
    //   setDispachtHistory(datadocuments);
    // } catch (error) {
    //   setDispachtHistory([]);
    // }
  };

  return (
    <div id="containerDetails">
      <Grid2>
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#fff",
            width: "100%",
            height: "100%",
          }}
        >
          <AppBar
            position="static"
            sx={{
              bgcolor: "#f2f2f2",
            }}
          >
            <Toolbar sx={{ width: "100%" }}>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  onClick={handleSeach}
                  startIcon={<SearchIcon />}
                  sx={{
                    width: "150px",
                    padding: "10px",
                    background: "#591e8f",
                    borderRadius: "4px",
                    color: "#f8f8f8",
                    fontSize: "12px",
                    fontWeight: "300",
                    margin: "1px",
                    lineHeight: 1,
                    "&:hover": {
                      backgroundColor: "#4a1468",
                    },
                  }}
                >
                  Buscar
                </Button>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="ingresa el código del envío..."
                  value={dataSearchOrder}
                  onChange={(e) => setDataSearch(e.target.value)}
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "inherit",
                    paddingInlineEnd: "44px",
                    textAlign: "left",
                    "& .MuiOutlinedInput-input": {
                      height: "100%",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#591e8f",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#591e8f",
                      },
                    },
                  }}
                />
              </Box>
            </Toolbar>
          </AppBar>

          {orderDetails ? (
            <>
              {" "}
              <div id="containerDetails">
                <div>
                  <Typography>Resultados de busqueda</Typography>
                </div>
                <Box spacing={2} sx={{ display: "flex" }}>
                  <Grid2 item xs={12} sm={12} lg={7}>
                    <Card>
                      <CardHeader
                        title={
                          <div>
                            <h1>
                              <NavLink
                                to={`/dashboard/dispatch/details/${orderDetails.id}`}
                                exact="true"
                              >
                                {orderDetails.id}
                              </NavLink>
                            </h1>
                            {orderDetailsCliente ? (
                              <span
                                className={
                                  styleState[
                                    orderDetailsCliente.falabella_status
                                  ]
                                }
                              >
                                {
                                  translateStatesfalabbela[
                                    orderDetailsCliente.falabella_status
                                  ]
                                }
                              </span>
                            ) : null}
                            {orderDetails ? (
                              <span className={styleState[orderDetails.status]}>
                                {
                                  translateStatesBicciFilter[
                                    orderDetails.status
                                  ]
                                }
                              </span>
                            ) : null}

                            {orderDetails.int_falabella_status ? (
                              <div>
                                <div>
                                  Se encontro un registro de facturacion ,
                                  Validar busqueda por SOC o LPN
                                </div>
                                <div>
                                  <span>order ID : {orderDetails.orderID}</span>
                                </div>
                                <div>
                                  <span>
                                    Tipo de servicio :{" "}
                                    {orderDetails.int_serviceType}
                                  </span>
                                </div>
                                <span>LPN : {orderDetails.int_lpn}</span>
                                <span
                                  className={
                                    stlyleClassStates[
                                      orderDetails.int_falabella_status
                                    ]
                                  }
                                >
                                  {orderDetails.int_falabella_status !==
                                  "isRequire"
                                    ? orderDetails.int_falabella_status
                                    : "Requiere Actualizacion "}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        }
                      />

                      <CardContent>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "350",
                          }}
                        >
                          <div>
                            <h1>Resumen</h1>
                          </div>
                          <ContainerViewComponent className="formcontent">
                            <TabSecondary
                              props={props}
                              orderDetails={orderDetails}
                              orderDetailsCliente={orderDetailsCliente}
                              dataOrdesBicciExpress={dataOrdesBicciExpress}
                              bloqueaGoogle={true}
                            />
                          </ContainerViewComponent>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid2>

                  <Grid2 item xs={12} sm={12} lg={5}>
                    <Card sx={{ m: 1 }}>
                      <CardHeader title={<h3>Historial</h3>} />
                      <CardContent>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <ContainerViewComponent
                            className="formcontent"
                            id="micuentafromIni"
                          >
                            <div className="alert alert-info">
                              Historial
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col">Integración</th>
                                    <th scope="col">Driver</th>
                                  </tr>
                                </thead>

                                {dispachtHistory ? (
                                  <tbody>
                                    {dispachtHistory.map((item, index) => (
                                      <tr key={index}>
                                        <th scope="col">
                                          {format(
                                            item.createdAt.seconds * 1000,
                                            "dd/MM/yy HH:mm:ss"
                                          ).toString()}
                                        </th>
                                        <th scope="col">
                                          {
                                            translateStatesBicciFilter[
                                              item.status
                                            ]
                                          }
                                        </th>
                                        <th scope="col">{item.intStatus}</th>
                                        <th scope="col">
                                          <NavLink
                                            to={`/dashboard/drivers/${item.driverID}`}
                                            exact="true"
                                          >
                                            {item.driverID}
                                          </NavLink>
                                        </th>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : null}
                              </table>
                            </div>
                          </ContainerViewComponent>
                          <div>
                            {" "}
                            <div>
                              Nota: Si no posee historial, Si la orden fue
                              gestionada en una plataforma externa, puede
                              encontrar el historial de estados allí.
                            </div>
                          </div>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card sx={{ m: 1 }}>
                      <CardHeader title={<h3>Comentarios.</h3>} />
                      <CardContent>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "150",
                          }}
                        >
                          <ContainerViewComponent className="formcontent">
                            <Divider></Divider>
                            <Typography
                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                              color="textPrimary"
                              gutterBottom
                              variant="body"
                            >
                              Rider:{" "}
                            </Typography>
                            <Typography
                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                              color="textPrimary"
                              gutterBottom
                              variant="body"
                            >
                              {orderDetails.observationReceptor ? (
                                <div className="color-blue">
                                  {orderDetails.observationReceptor}
                                </div>
                              ) : (
                                "Sin Comentarios"
                              )}
                            </Typography>
                            <Divider></Divider>
                            Admin:
                            {orderDetails.observationAdmin &&
                            orderDetails.observationAdmin.length > 0
                              ? orderDetails.observationAdmin.map(
                                  (item, index) => (
                                    <div key={index} className="color-blue">
                                      <Divider></Divider>
                                      <div>{item}</div>
                                    </div>
                                  )
                                )
                              : " Sin Comentarios"}
                            <Divider></Divider>
                          </ContainerViewComponent>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid2>
                </Box>
              </div>
            </>
          ) : (
            <Box>
              {orderDetails === false ? (
                <Box>
                  <TableNoDataBox
                    notFound={true}
                    bannerActive={false}
                    mensajeBanner={
                      "Código no coincide con un resultado de búsqueda, verifique el código. O consulte si la orden fue creada correctamente."
                    }
                  ></TableNoDataBox>
                  No se encontraron resultados{" "}
                </Box>
              ) : (
                <Box>
                  <TableNoDataBox
                    notFound={true}
                    bannerActive={false}
                    mensajeBanner={
                      "Código no coincide con un resultado de búsqueda, verifique el código. O consulte si la orden fue creada correctamente."
                    }
                  ></TableNoDataBox>
                  Ingregrese Busqueda{" "}
                </Box>
              )}{" "}
            </Box>
          )}
        </Box>
      </Grid2>
    </div>
  );
};

export default SearchDoc;
