import React from "react";
import {
  registrarComentarios,
  enviarEmaildeFormularioCliente,
} from "../../../redux/accionesCRM";
import { useDispatch, useSelector } from "react-redux";
import ContainerViewComponent from "../../ContainerViewComponent/ContainerViewComponent";
import {
  Box,
  Grid,
  TextField,
  Button,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextareaAutosize,
} from "@mui/material";
// import BtnAceceder from '../../Login/buttons/BtnAceceder'
// import BtnTebgoCuenta from '../../Login/buttons/BtnTebgoCuenta'

const FrmContacto = () => {
  const dispatch = useDispatch();
  const resperror = useSelector((store) => store.usuario.error);

  const [success, setSuccess] = React.useState(null);
  let mensjaEnviado = useSelector((store) => store.mensajes.mjsstatus);
  const [username, setName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("+56");
  const [userPreferenCont, setUserPreferenCont] = React.useState("Correo");
  const [textComents, setTextComents] = React.useState("");
  const [error, setError] = React.useState(null);
  const [userType, setUserType] = React.useState("empresa");
  const [checked, setChecked] = React.useState(false);

  const registrarComentario = (e) => {
    e.preventDefault();

    if (!username.trim()) {
      setError("El campo nombre es obligatorio");
      return;
    }
    if (!companyName.trim()) {
      setError("El campo nombre de empresa es obligatorio");
      return;
    }
    if (!email.trim()) {
      setError("Ingrese Email");
      return;
    }
    if (!phone.trim()) {
      setError("Ingrese un numero telefonico");
      return;
    }
    if (!textComents.trim()) {
      setError("Ingrese Mensaje");
      return;
    }
    if (textComents.length < 8) {
      setError("Mensaje es muy corto");
      return;
    }
    if (!userPreferenCont.trim()) {
      setError("Seleccione un medio de contacto");
      return;
    }
    if (!userType.trim()) {
      setError("Seleccione un tipo de usuario");
      return;
    }
    if (!checked === true) {
      setError("Debe aceptar los terminos y condiciones");
      return;
    }
    if (!resperror === null) {
      setError("Error de acceso");
      return;
    }
    setError(null);
    comentar();
  };

  const handleChange = () => {
    setChecked(!checked);
    // console.log (checked)
  };
  if (checked === true) {
    //    console.log ('Acepta terminos y condiciones')
  }

  const setClear = () => {
    setSuccess(null);
    setName("");
    setCompanyName("");
    setEmail("");
    setPhone("+56");
    setUserPreferenCont("Correo");
    setTextComents("");
    setError(null);
    setUserType("empresa");
    setChecked(false);
  };

  const comentar = React.useCallback(async () => {
    //  console.log('Esta Comentando')
    try {
      const userData = {
        userName: username,
        userCompany: companyName,
        userType: userType,
        email: email,
        phone: phone,
        mensaje: textComents,
        userPreferenCont: userPreferenCont,
        checked: checked,
      };

      dispatch(registrarComentarios(userData));
      dispatch(enviarEmaildeFormularioCliente(userData));

      try {
        let formData = new FormData();
        for (var key in userData) {
          formData.append(key, userData[key]);
        }

        // console.log("fetch");
        // console.log(data);
        fetch(`${process.env.REACT_APP_BICCIEXPRESS_API_SHEET_COMPANIES}`, {
          method: "POST",
          mode: "no-cors",
          body: formData,
        })
          //   .then((response) => console.log("Success!", response))
          .catch((error) => console.error("Error!", error.message));

        // dispatch(createNewContactRider(data));
        // dispatch(wirteDocument(data)); //excell
        // dispatch(setEmailConfirm(data)); //Nodemailer
        // dispatch(setEmailAlert(data)); //pushOterPage
        // pushthanksyou;
        setSuccess("Gracias. Mensaje enviado...");
      } catch (error) {
        //  console.log(error);
      }

      function delay() {
        setClear();
        setSuccess(null);
      }
      setTimeout(delay, 5000);
    } catch (error) {
      //   console.log(error)
    }
  });

  return (
    <ContainerViewComponent className="formcontent" id="ajustefromIni">
      <hr></hr>

      <Box component="form" onSubmit={registrarComentario} sx={{ p: 3 }}>
        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {resperror && <Alert severity="error">{resperror}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre de contacto"
              variant="outlined"
              value={username}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre de Empresa"
              variant="outlined"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Tipo de usuario</InputLabel>
              <Select
                onChange={(e) => setUserType(e.target.value)}
                defaultValue=""
              >
                <MenuItem value="empresa">Empresa</MenuItem>
                <MenuItem value="repartidor">Repartidor</MenuItem>
                <MenuItem value="integracion">Integración</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Teléfono"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Preferencias de contacto</InputLabel>
              <Select
                onChange={(e) => setUserPreferenCont(e.target.value)}
                defaultValue=""
              >
                <MenuItem value="correo">Correo</MenuItem>
                <MenuItem value="telefono">Teléfono</MenuItem>
                <MenuItem value="video">Video</MenuItem>
                <MenuItem value="presencial">Presencial</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextareaAutosize
              minRows={3}
              placeholder="¿Cómo podemos ayudarte?"
              value={textComents}
              onChange={(e) => setTextComents(e.target.value)}
              style={{ width: "100%", padding: "10px", fontSize: "16px" }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleChange} />}
                label="Acepto términos y condiciones de BICCI EXPRESS."
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <hr></hr>
      {/* <div>
              <BtnAceceder></BtnAceceder> 
              <div >
                   
                  <BtnTebgoCuenta></BtnTebgoCuenta>
              </div>
        </div> */}
    </ContainerViewComponent>
  );
};

export default FrmContacto;
