import React from "react";
import AccountProfileDetails from "./Form_micuenta";
import { useSelector } from "react-redux";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { OpcionesLaterales } from "./OpcionesLaterales";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
  Grid2,
} from "@mui/material";
import useObtenerDetallesdecuenta from "../../../hooks/obtenerDetallesdecuenta";
import useObtenerBalance from "../../../hooks/obtenerBalance";
import { useLoadScript } from "@react-google-maps/api";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const libraries = ["places"];
const user = {
  avatar: "/static/images/avatars/avatar_12.png",
  city: "Region Metrpolitana",
  country: "CHILE",
};

const Micuenta = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });
  const navigate = useNavigate();
  const userUid = useSelector((store) => store.usuario.user.uid);

  const [userData, setUserData] = React.useState("");
  const [userDisplayName, setDisplayName] = React.useState();
  const [avatar, setAvatar] = React.useState("");

  const [dataClientCompany, setDataClienCompany] = React.useState("");

  //const { data } = useObtenerDatosdeCliente(userUid)//(userUid)
  const {
    accountDetails,
    accountDetailsHistory,
    availableservices,
    loadingDetallesdecuenta,
    errorDetallesdecuenta,
  } = useObtenerDetallesdecuenta(userData.userCompanyID); //(userUid)

  const { userBalance, loadingBalance, errorBalance } = useObtenerBalance(
    userData.userCompanyID
  ); //(userUid)

  React.useEffect(() => {
    if (userUid) {
      obtenerDatosdeCliente(userUid);
    }
  }, []);

  const obtenerDatosdeCliente = async (userUid) => {
    try {
      const accountants = await getDoc(
        doc(db, `${process.env.REACT_APP_COL_USERS}/${userUid}`)
      );
      const data = accountants.data();
      setUserData(data);
      setAvatar(data.avatar);
      setDisplayName(data.userName);
    } catch (error) {}
  };

  // React.useEffect(() => {
  //   setDataClienCompany(data)
  // }, [data ])

  React.useEffect(() => {}, [
    accountDetails &&
      accountDetailsHistory &&
      availableservices &&
      loadingDetallesdecuenta &&
      errorDetallesdecuenta,
  ]);

  React.useEffect(() => {}, [userBalance, loadingBalance, errorBalance]);

  const createnewcompany = () => {
    props.history.push(`/dashboard/companies`);
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }
  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      <Box xs={12} sm={6} md={3} lg={12}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 600, textAlign: "center", color: "primary.main" }}
        >
          Mi Cuenta
        </Typography>
      </Box>
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
          width: "100%",

          justifyContent: "space-around",
        }}
      >
        <Grid2 item xs={12} sm={6} md={3}>
          <Box sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
            <Card sx={{ mb: 3, p: 2, boxShadow: 3 }}>
              <CardContent>
                <Avatar
                  src={avatar}
                  sx={{ height: 80, width: 80, mx: "auto", mb: 2 }}
                />
                <Typography variant="h6">{userDisplayName}</Typography>
                <Typography color="textSecondary">{`${user.city}, ${user.country}`}</Typography>
              </CardContent>
              <Divider />
            </Card>
          </Box>

          <Box sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
            <Card sx={{ mb: 3, p: 2, boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6">Empresa</Typography>
                {dataClientCompany ? (
                  <>
                    <Avatar
                      src={dataClientCompany.companyImgLogo}
                      sx={{ height: 80, width: 80, mx: "auto", mb: 2 }}
                    />
                    <Typography>
                      {dataClientCompany.companyRegion},{" "}
                      {dataClientCompany.companyCity}
                    </Typography>
                    <Typography color="textSecondary">
                      {dataClientCompany.companyRut}
                    </Typography>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ mt: 2, width: "100%" }}
                    onClick={() => navigate("/dashboard/companies")}
                  >
                    Crear Empresa
                  </Button>
                )}
              </CardContent>
              <Divider />
            </Card>
          </Box>
        </Grid2>

        <Grid2 item xs={12} sm={12} md={6}>
          <Card sx={{ p: 2, boxShadow: 3 }}>
            <Divider />
          </Card>

          {userData ? (
            <AccountProfileDetails props={props} userdata={userData} />
          ) : accountDetails ? (
            <LinearProgress />
          ) : (
            <Typography textAlign="center">Sin registros</Typography>
          )}
        </Grid2>

        <Grid2 item xs={12} md={3}>
          {availableservices ? (
            <OpcionesLaterales serviciosAct={availableservices} props={props} />
          ) : accountDetails ? (
            <LinearProgress />
          ) : (
            <Box
              sx={{
                textAlign: "center",
                p: 2,
                border: "1px solid #f8f8f8",
                borderRadius: "8px",
                backgroundColor: "#8f130c",
                color: "white",
              }}
            >
              <Typography variant="body2">
                Requiere registro de su empresa.
              </Typography>
            </Box>
          )}
        </Grid2>
      </Box>
    </Box>
  );
};

export default Micuenta;
