import { Fragment, useEffect, useState } from "react";
import { collection, deleteDoc, doc } from "firebase/firestore";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  Collapse,
  IconButton,
  Tooltip,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  DialogActions,
  CardHeader,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  translateStatesBicci,
  translateStatesBicciFilter,
} from "../../utils/objects/translateStates";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import RouteIcon from "@mui/icons-material/Route";
import { db } from "../../firebase";
import axios from "axios";
import exportRoutesToExcel from "./ExportRoutesToExcel";
import DriverRoutesView from "./DriverRoutesView";
import {
  colorByStatus,
  contrastColorByStatus,
  stlyleClassStates,
} from "../../utils/objects/stylesStates";
import { getViewRouteDataResult } from "./getViewRouteDataResult";
import { DataGrid } from "@mui/x-data-grid";
import { updateDoc } from "firebase/firestore";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";

const MapPanelAdminRoutes = ({
  loading,
  routes,
  optimizedWaypoints,
  selectedViewRoutes,
  setSelectedViewRoutes,
  hoveredWaypoint,
  setHoveredWaypoint,
  routeGeometry,
  selectedViewLines,
  setSelectedViewLines,
  ridersDataByCompany,
  mapViewByRiders,
  handleMapViewChange,
  titleComponent,
  hiddenfiter,
  hiddenRouteEditor,
  hidenChangeControl,
  handleAsingDriver,
  viewMergedOrders,
  //vista individual
  waypointsOfRider,
  selectedViewRouteRider,
  setSelectedViewRouteRider,
  routeGeometryRider,
  setRouteGeometryRider,
  selectedViewLinesRider,
  setViewInfoRoute,
  selectedInfoRoute,
  viewDrivers,
  setViewDrivers,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [rutas, setRutas] = useState([]);

  const [expandedRoutes, setExpandedRoutes] = useState([]);
  const [metricsData, setMetricsData] = useState({});
  const [opemPopSeleccteprincipal, setOpemPopSeleccteprincipal] =
    useState(false);
  const [selectedRider, setSelectedRider] = useState("");
  const [routeToAsing, setRouteToAsing] = useState("");

  //test

  // Estado del switch
  useEffect(() => {}, [metricsData]);

  useEffect(() => {}, [
    optimizedWaypoints,
    selectedViewRoutes,
    selectedViewLines,
  ]);

  useEffect(() => {
    getResults();
  }, [routes, ridersDataByCompany, viewMergedOrders]);

  useEffect(() => {
    if (routes) {
      setRutas(routes);
    }
  }, [routes]);

  const handleViewChange = (filter) => {
    setSelectedFilters(
      (prevFilters) =>
        prevFilters.includes(filter)
          ? prevFilters.filter((f) => f !== filter) // 🔹 Si ya está seleccionado, lo quita
          : [...prevFilters, filter] // 🔹 Si no está seleccionado, lo agrega
    );
  };

  const toggleRouteExpansion = (routeId) => {
    setExpandedRoutes((prev) =>
      prev.includes(routeId)
        ? prev.filter((id) => id !== routeId)
        : [...prev, routeId]
    );
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "Fecha no disponible";

    // Si es un Timestamp de Firestore (objeto con `seconds` y `nanoseconds`)
    if (timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }

    // Si es una cadena de fecha (Ej: "2025-02-11T14:49:10.708Z")
    return new Date(timestamp).toLocaleString();
  };

  const handleToggleVisibility = (route) => {
    setSelectedViewRoutes((prevRoutes) => {
      // Si la ruta ya está en la lista, la eliminamos (desactivamos la visibilidad)
      if (prevRoutes.includes(route.id)) {
        return prevRoutes.filter((r) => r !== route.id);
      } else {
        // Si la ruta no está en la lista, la agregamos (activamos la visibilidad)
        return [...prevRoutes, route.id];
      }
    });
  };

  const handleToggleVisibilityLines = (route) => {
    setSelectedViewLines((prevRoutes) => {
      // Si la ruta ya está en la lista, la eliminamos (desactivamos la visibilidad)
      if (prevRoutes.includes(route.id)) {
        return prevRoutes.filter((r) => r !== route.id);
      } else {
        // Si la ruta no está en la lista, la agregamos (activamos la visibilidad)
        return [...prevRoutes, route.id];
      }
    });
  };

  const handleToggleVisibilityRider = (route) => {
    setSelectedViewRouteRider((prevRoutes) => {
      return prevRoutes.includes(route.id)
        ? prevRoutes.filter((r) => r !== route.id) //  Remover si ya está seleccionado
        : [...prevRoutes, route.id];
    });
  };

  const handleToggleVisibilityLinesRider = (route) => {
    setRouteGeometryRider((prevRoutes) => {
      return prevRoutes.includes(route.id)
        ? prevRoutes.filter((r) => r !== route.id) //  Remover si ya está seleccionado
        : [...prevRoutes, route.id];
    });
  };

  const handleToggleVisibilityInfo = (route, driverID) => {
    setViewInfoRoute((prevRoutes) => {
      if (prevRoutes.includes(route.id)) {
        // 🔹 Si se oculta la ruta, verificamos si es la última de ese conductor
        setViewDrivers((prev) => {
          const remainingRoutesForDriver = prevRoutes.filter(
            (r) => r.driverID === driverID && r.id !== route.id
          );
          return remainingRoutesForDriver.length > 0
            ? prev
            : prev.filter((d) => d !== driverID);
        });

        return prevRoutes.filter((r) => r !== route.id);
      } else {
        // 🔹 Si la ruta se muestra, asegurarse de que el `driverID` esté en `viewDrivers`
        setViewDrivers((prev) =>
          prev.includes(driverID) ? prev : [...prev, driverID]
        );

        return [...prevRoutes, route.id];
      }
    });
  };

  const handleDelete = async (id) => {
    const colletionRef = collection(db, "xpress_deliveryPlans");
    const docRef = doc(colletionRef, id);
    await deleteDoc(docRef);
  };

  const extracOptimizedWaypoints = (ruta, nameToAsing) =>
    ruta.waypoints
      ? ruta.waypoints.flatMap((wp) =>
          wp.ids.map((id) => ({
            id,
            orderNumber: wp.index,
            driverLicensePlate: nameToAsing,
          }))
        )
      : [];

  const getDataUpdate = (id) => {
    let nameToAsing = "";
    let extractedIDs = [];

    for (let n = 0; n < rutas.length; n++) {
      if (id === rutas[n].id) {
        nameToAsing = rutas[n].route;
        extractedIDs = extracOptimizedWaypoints(rutas[n], rutas[n].route);
        break;
      }
    }

    // 🔹 Validar si la ruta no tiene IDs
    if (extractedIDs.length === 0) {
      console.warn(
        `⚠️ No hay IDs en los waypoints para la ruta: ${nameToAsing}`
      );
      return;
    }

    return { extractedIDs, nameToAsing };
  };

  const handleAsingOrders = async (id) => {
    const { extractedIDs, nameToAsing } = getDataUpdate(id);

    let doc = {
      asigm: extractedIDs,
    };

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BICCIEXPRESS_UPDATE_ORDERS_ROUTE}`,
        data: { data: doc },
      };

      const response = await axios(config);

      if (response.status === 200) {
        alert("Documento subido correctamente.");
      } else {
        console.warn("⚠️ Respuesta inesperada del servidor:", response.status);
        alert("Hubo un problema al actualizar  el documento.");
      }
    } catch (error) {
      alert(
        "Error al actualizar el documento. Por favor, inténtelo nuevamente."
      );
    }
  };

  const handleCreaetaReports = async () => {
    exportRoutesToExcel(rutas, ridersDataByCompany, viewMergedOrders);
  };

  const getResults = async () => {
    let resultData = await getViewRouteDataResult(
      rutas,
      ridersDataByCompany,
      viewMergedOrders
    );

    setMetricsData(resultData);
  };

  const filters = [
    { label: "Creados", value: "creados" },
    { label: "En ruta", value: "en_ruta" },
    { label: "Incidencias", value: "incidencias" },
    { label: "Finalizadas", value: "finalizadas" },
  ];

  const handleAsingDriverPrincipal = async (id) => {
    const ruta = routes.find((item) => item.id === id);
    if (!ruta) {
      console.error(`❌ No se encontró la ruta con ID: ${id}`);
      return;
    }
    setRouteToAsing(ruta);
    setOpemPopSeleccteprincipal(true);
  };

  const handleCloseSelector = async () => {
    setOpemPopSeleccteprincipal(false);
  };

  const handleSelecRider = async (params) => {
    setSelectedRider({
      id: params.id,
      name: params.row.name,
      avatar: params.row.avatar,
    });
  };

  const columnsRiders = [
    {
      field: "conductor",
      headerName: "Seleccione un conductor",
      width: 300,
      renderCell: (params) => (
        <CardHeader
          avatar={<Avatar src={params.row.avatar} />}
          title={params.row.name}
          subheader={params.row.bicciNumber}
        />
      ),
    },
  ];

  const asignarConductor = async () => {
    if (routeToAsing.id && selectedRider.id) {
      try {
        let collectionRoute = collection(db, "xpress_deliveryPlans");
        let documentRef = doc(collectionRoute, routeToAsing.id);
        await updateDoc(documentRef, { driverID: selectedRider.id });
        setSelectedRider({});
        setRouteToAsing({});
        setOpemPopSeleccteprincipal(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Error al Asignar conductor", selectedRider.id);
      console.log("A ruta", routeToAsing.id);
    }
  };

  return (
    <Box
      width={"100%"}
      p={2}
      bgcolor="background.paper"
      borderRight={1}
      borderColor="divider"
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "grey",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">
              🚚 {titleComponent ? titleComponent : "Rutas del Día"}
            </Typography>
          </Box>
          {/* 🔹 Switch para cambiar vista */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {hidenChangeControl !== true ? (
              <ToggleButtonGroup
                value={mapViewByRiders}
                exclusive
                onChange={(event, newValue) => {
                  handleMapViewChange(newValue);
                }}
              >
                <ToggleButton value="rutas">Rutas</ToggleButton>
                <ToggleButton value="conductores">Conductores</ToggleButton>
                <ToggleButton value="status">Estados</ToggleButton>
              </ToggleButtonGroup>
            ) : (
              <></>
            )}
          </Box>
          <Tooltip title={"Exportar  reporte"}>
            <IconButton
              onClick={() => handleCreaetaReports()}
              sx={{ color: "grey" }}
            >
              <LocalPrintshopIcon
                sx={{
                  color: "grey",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
        </Box>
        {/* 📌 Multi Selector de Filtros */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "grey",
            mt: 2,
          }}
        >
          <>
            {hiddenfiter !== true ? (
              <ButtonGroup variant="outlined" aria-label="Filtro de estados">
                {filters.map((filter) => (
                  <Button
                    key={filter.value}
                    onClick={() => handleViewChange(filter.value)}
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                      border: selectedFilters.includes(filter.value)
                        ? "solid 2px hsl(256, 68%, 60.8%)"
                        : "solid 1px #030303",
                      backgroundColor: selectedFilters.includes(filter.value)
                        ? "#e3f2fd"
                        : "transparent",
                      color: selectedFilters.includes(filter.value)
                        ? "#030303"
                        : "#000",
                      "&:hover": { backgroundColor: "#d0e6fa" },
                    }}
                  >
                    {filter.label}
                  </Button>
                ))}
              </ButtonGroup>
            ) : (
              <></>
            )}
          </>
        </Box>
      </Box>
      {mapViewByRiders === "rutas" && (
        <Box>
          {loading ? (
            <Typography>Cargando rutas...</Typography>
          ) : rutas.length > 0 ? (
            <List>
              {rutas.map((ruta) => {
                const isExpanded = expandedRoutes.includes(ruta.id);

                return (
                  <Box key={ruta.id} sx={{ width: "100%" }}>
                    {/* 📌 Información de la Ruta */}
                    <ListItemButton
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <ListItemText
                        sx={{
                          fontWeight: "bold",
                          color: ruta.routeColor,
                          fontSize: "1.5em",
                        }}
                        primary={
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", color: "#030303" }}
                          >
                            📌 Paradas : {ruta.analytics?.totalOrders || 0} | 📍
                            Avance {ruta.analytics?.delivered || 0} Entregados |
                            🚛 Inicio: {ruta.initHour} | 🏁{" "}
                            {(
                              (parseInt(ruta.analytics?.delivered) * 100) /
                              parseInt(ruta.analytics?.totalOrders)
                            ).toFixed(2)}{" "}
                            %
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold", color: "#030303" }}
                            >
                              REF#: {ruta.id}
                            </Typography>
                            <Button
                              onClick={() => {
                                handleAsingDriver(ruta.id);
                              }}
                              sx={{
                                backgroundColor: "orange",
                                padding: "3px 5px",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "0.9em", color: "#fff" }}
                              >
                                <AssignmentIndIcon></AssignmentIndIcon> Asignar
                                ordenes a conductor
                              </Typography>
                            </Button>
                            <Button
                              onClick={() => {
                                const confirmDelete = window.confirm(
                                  "¿Estás seguro de que deseas eliminar esta ruta?"
                                );
                                if (confirmDelete) {
                                  handleDelete(ruta.id);
                                }
                              }}
                              sx={{ color: "red", padding: "3px 5px" }}
                            >
                              <DeleteForeverIcon></DeleteForeverIcon> Borrar
                              Plan
                            </Button>
                            <Button
                              onClick={() => {
                                const confirmAsignarOrdenesARutas =
                                  window.confirm(
                                    "¿Estás seguro de actualizar ordenes en esta ruta?"
                                  );
                                if (confirmAsignarOrdenesARutas) {
                                  handleAsingOrders(ruta.id);
                                }
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "0.9em",
                                  color: "blue",
                                  padding: "3px 5px",
                                }}
                              >
                                <CloudSyncIcon></CloudSyncIcon> Actualizar
                                paradas en ordenes
                              </Typography>
                            </Button>

                            <Button
                              onClick={() => {
                                handleAsingDriverPrincipal(ruta.id);
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "0.9em",
                                  color: "blue",
                                  padding: "3px 5px",
                                }}
                              >
                                <DirectionsBikeIcon> </DirectionsBikeIcon>
                                Conductor Responsable de ruta
                              </Typography>
                            </Button>
                          </Box>
                        }
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                          justifyContent: "center",
                          gap: 0,
                          width: "20%",
                        }}
                      >
                        <IconButton>
                          {isExpanded ? (
                            <ExpandLess
                              onClick={() => toggleRouteExpansion(ruta.id)}
                            />
                          ) : (
                            <ExpandMore
                              onClick={() => toggleRouteExpansion(ruta.id)}
                            />
                          )}
                        </IconButton>
                        {optimizedWaypoints && (
                          <Tooltip
                            title={
                              selectedViewRoutes.includes(ruta.id)
                                ? "Ocultar ruta"
                                : "Mostrar ruta"
                            }
                          >
                            <IconButton
                              onClick={() => handleToggleVisibility(ruta)}
                              sx={{
                                bgcolor: selectedViewRoutes.includes(ruta.id)
                                  ? "#d1c4e9"
                                  : "transparent", // Fondo morado claro si está activo
                                boxShadow: selectedViewRoutes.includes(ruta.id)
                                  ? "0px 0px 8px 2px green"
                                  : "none", // Sombra verde si está activo
                                borderRadius: "50%", // Redondear
                              }}
                            >
                              {selectedViewRoutes.includes(ruta.id) ? (
                                <VisibilityIcon sx={{ color: "#591e8f" }} />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}

                        {routeGeometry && (
                          <Tooltip
                            title={
                              selectedViewLines.includes(ruta.id)
                                ? "Ocultar líneas"
                                : "Mostrar líneas"
                            }
                          >
                            <IconButton
                              onClick={() => handleToggleVisibilityLines(ruta)}
                              sx={{
                                bgcolor: selectedViewLines.includes(ruta.id)
                                  ? "#d1c4e9"
                                  : "transparent",
                                boxShadow: selectedViewLines.includes(ruta.id)
                                  ? "0px 0px 8px 2px green"
                                  : "none",
                                borderRadius: "50%",
                              }}
                            >
                              <RouteIcon
                                sx={{
                                  color: selectedViewLines.includes(ruta.id)
                                    ? "#591e8f"
                                    : "inherit",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </ListItemButton>

                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      <Box pl={4}>
                        {/* 📍 Waypoints */}
                        <Typography variant="subtitle2">
                          📍 Paradas:{" "}
                        </Typography>
                        {ruta.waypoints.length > 0 ? (
                          <List dense>
                            {ruta.waypoints
                              .sort((a, b) => a.index - b.index) // Ordenar de menor a mayor por index
                              .map((wp) => (
                                <ListItem
                                  key={wp.id}
                                  sx={{
                                    borderBottom: "1px solid #ddd",
                                    paddingY: 1,
                                  }}
                                  onMouseEnter={() =>
                                    setHoveredWaypoint(wp.ids[0])
                                  } // 🔹 Activa resaltado
                                  onMouseLeave={() => setHoveredWaypoint(null)} // 🔹 Desactiva resaltado
                                >
                                  <ListItemText
                                    sx={{ color: "#030303" }}
                                    primary={
                                      <Typography
                                        variant="body1"
                                        component="div"
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#030303",
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="span"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          🛑 Parada{" "}
                                          <Typography
                                            variant="body1"
                                            component="span"
                                          >
                                            {wp.index
                                              ? wp.index
                                              : "Requiere calcular rutas"}
                                          </Typography>
                                        </Typography>
                                        <Box>
                                          <Typography
                                            sx={{
                                              fontSize: "0.85em",
                                              fontWeight: "bold",
                                              color:
                                                stlyleClassStates[
                                                  wp.currentStatus
                                                ] || "#000",
                                            }}
                                            component="div"
                                          >
                                            Estado:{" "}
                                          </Typography>
                                          <span
                                            className={
                                              stlyleClassStates[
                                                wp.currentStatus
                                              ]
                                            }
                                            style={{
                                              backgroundColor:
                                                colorByStatus[wp.currentStatus],
                                              color:
                                                contrastColorByStatus[
                                                  wp.currentStatus
                                                ],
                                            }}
                                          >
                                            {
                                              translateStatesBicciFilter[
                                                wp.currentStatus
                                              ]
                                            }
                                          </span>
                                        </Box>
                                        <Typography
                                          variant="body2"
                                          component="div"
                                          sx={{
                                            fontSize: "0.9rem",
                                            color: "#666",
                                          }}
                                        >
                                          {wp.routingData ? (
                                            <>
                                              {wp.routingData
                                                ?.formattedDistance || ""}{" "}
                                              {wp.routingData?.formattedTime ||
                                                ""}{" "}
                                              ({wp.routingData?.segment || ""})
                                            </>
                                          ) : (
                                            "Sin ruta"
                                          )}
                                        </Typography>
                                      </Typography>
                                    }
                                    secondary={
                                      <Box>
                                        <Typography
                                          variant="body1"
                                          component="div"
                                          sx={{
                                            fontWeight: "bold",
                                            color: "#030303",
                                          }}
                                        >
                                          Carga:
                                        </Typography>

                                        {/* 🔹 Se movió fuera del Typography para evitar errores de validación */}
                                        {wp.ids.map((id, index) => (
                                          <Fragment key={id}>
                                            <a
                                              href={`https://bicciexpress.cl/dashboard/dispatch/details/${id}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{
                                                fontWeight: "bold",
                                                textDecoration: "none",
                                                color: "blue",
                                              }} // 🔹 Enlace en negrita sin subrayado
                                            >
                                              {id}
                                            </a>
                                            {index < wp.ids.length - 1 && ", "}
                                          </Fragment>
                                        ))}
                                      </Box>
                                    }
                                  />
                                </ListItem>
                              ))}
                          </List>
                        ) : (
                          <Typography variant="body2">
                            🔹 No hay waypoints
                          </Typography>
                        )}

                        {/* 📝 Observaciones */}
                        <Typography variant="subtitle2">
                          📝 Observaciones:
                        </Typography>
                        {ruta.observations.length > 0 ? (
                          <List dense>
                            {ruta.observations
                              .slice(-5) // Toma los últimos 5 elementos
                              .reverse()
                              .map((obs) => (
                                <ListItem key={obs.id}>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="body1"
                                        component="span"
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#030303",
                                        }}
                                      >
                                        📄{" "}
                                        {obs.comment.replace(
                                          ", intState: undefined",
                                          ""
                                        )}
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography
                                        variant="body1"
                                        component="span"
                                        sx={{
                                          fontWeight: "bold",
                                          color: "#030303",
                                        }}
                                      >
                                        🕒 {formatTimestamp(obs.createdAt)}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))}
                          </List>
                        ) : (
                          <Typography variant="body2">
                            🔹 No hay observaciones
                          </Typography>
                        )}
                      </Box>
                    </Collapse>
                    <Divider />
                    <Dialog
                      open={opemPopSeleccteprincipal}
                      onClose={handleCloseSelector}
                      aria-labelledby="form-dialog-title"
                      maxWidth="md"
                      fullWidth
                      sx={{
                        "& .MuiDialog-paper": {
                          width: { xs: "100%", sm: "100%", md: "700px" },
                          maxWidth: "700px",
                        },
                      }}
                    >
                      <DialogTitle id="form-dialog-title">
                        Asignar conductor princital a ruta:
                        `routeToAsing?.route`
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          sx={{
                            width: "100%",
                            height: 350,
                            backgroundColor: "#fafafa",
                          }}
                        >
                          {ridersDataByCompany && (
                            <DataGrid
                              rows={ridersDataByCompany}
                              columns={columnsRiders}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              onRowClick={handleSelecRider}
                              rowHeight={100}
                            />
                          )}
                        </Box>

                        {selectedRider && (
                          <CardHeader
                            avatar={<Avatar src={selectedRider.avatar} />}
                            title={selectedRider.name}
                            subheader={`ID: ${selectedRider.id}`}
                          />
                        )}
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleCloseSelector} color="primary">
                          Cancelar
                        </Button>

                        <Button
                          onClick={() => asignarConductor()}
                          color="primary"
                        >
                          Asignar Princital a ruta
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                );
              })}
            </List>
          ) : (
            <Typography>No hay rutas para hoy.</Typography>
          )}
        </Box>
      )}

      {mapViewByRiders === "conductores" && (
        <DriverRoutesView
          viewMergedOrders={viewMergedOrders}
          rutas={rutas}
          ridersDataByCompany={ridersDataByCompany}
          handleToggleVisibilityRider={handleToggleVisibilityRider}
          handleToggleVisibilityLinesRider={handleToggleVisibilityLinesRider}
          handleToggleVisibilityInfo={handleToggleVisibilityInfo}
          waypointsOfRider={waypointsOfRider}
          selectedViewRouteRider={selectedViewRouteRider}
          routeGeometryRider={routeGeometryRider}
          selectedViewLinesRider={selectedViewLinesRider}
          viewDrivers={viewDrivers}
          selectedInfoRoute={selectedInfoRoute}
          metricsData={metricsData}
        ></DriverRoutesView>
      )}

      {mapViewByRiders === "status" && (
        <DriverRoutesView
          viewMergedOrders={viewMergedOrders}
          rutas={rutas}
          ridersDataByCompany={ridersDataByCompany}
          handleToggleVisibilityRider={handleToggleVisibilityRider}
          handleToggleVisibilityLinesRider={handleToggleVisibilityLinesRider}
          handleToggleVisibilityInfo={handleToggleVisibilityInfo}
          waypointsOfRider={waypointsOfRider}
          selectedViewRouteRider={selectedViewRouteRider}
          routeGeometryRider={routeGeometryRider}
          selectedViewLinesRider={selectedViewLinesRider}
          viewDrivers={viewDrivers}
          selectedInfoRoute={selectedInfoRoute}
          metricsData={metricsData}
        ></DriverRoutesView>
      )}
    </Box>
  );
};

export default MapPanelAdminRoutes;
