import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";

export const useActiveRidersByIds = (ridersIds = []) => {
  const [ridersMonitor, setRidersMonitor] = useState({});
  const db = getFirestore();

  useEffect(() => {
    if (ridersIds.length === 0) {
      setRidersMonitor({});
      return;
    }

    // 🔥 Dividir los IDs en grupos de 10 (Firestore solo permite hasta 10 en "in")
    const idGroups = [];
    for (let i = 0; i < ridersIds.length; i += 10) {
      idGroups.push(ridersIds.slice(i, i + 10));
    }

    // 🛑 Guardar las funciones de "unsubscribe" para limpiar luego
    const unsubscribers = [];

    idGroups.forEach((group) => {
      const ridersQuery = query(
        collection(db, "drivers"), // 📌 Asegúrate de usar el nombre correcto en Firestore
        where("id", "in", group), // 📌 Filtrar por ID (máximo 10)
        where("online", "==", true) // 📌 Solo conductores conectados
      );

      // 🔄 Suscribirse a cada grupo de 10
      const unsubscribe = onSnapshot(ridersQuery, (snapshot) => {
        setRidersMonitor((prevRiders) => {
          const updatedRiders = { ...prevRiders };

          snapshot.docs.forEach((doc) => {
            updatedRiders[doc.id] = { ...doc.data() }; // ✅ Guardar datos completos del conductor
          });

          return updatedRiders;
        });
      });

      unsubscribers.push(unsubscribe);
    });

    // 🛑 Limpiar todas las suscripciones al desmontar o actualizar IDs
    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [ridersIds]);

  return ridersMonitor; // ✅ Devuelve solo los conductores activos
};
