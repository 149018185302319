import React from "react";
import { Box } from "@mui/material";
import useGlobalConfig from "../../hooks/globalConfig";
import { Padding } from "@mui/icons-material";
const Bannerfooter = (props) => {
  const { imgbannerfooter } = useGlobalConfig();

  const FloatMensaje = (footermensaje) => {
    if (footermensaje === null) {
      return "";
    } else {
      return props.footermensaje ? (
        <Box
          className={props.footermensaje.className}
          sx={{
            color: "#f8f8f8",
            display: "float",
            justifyContent: "center",
            position: {
              xs: "block",
              sm: "block",
              md: "absolute",
              lg: "absolute",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f8f8f83b",
              p: 1,
              borderRadius: "15px",
            }}
          >
            <h1>{props.footermensaje.title}</h1>
            <p>{props.footermensaje.paragramh}</p>
          </Box>
        </Box>
      ) : null;
    }
  };

  return (
    <Box
      sx={{
        maxHeight: "350px",
        justifyContent: "center",
        height: "auto",
        display: { xs: "block", sm: "block", md: "block", lg: "flex" },
        textAlign: "center",
        backgroundColor: "#7a12b1",
      }}
    >
      {props.footermensaje ? (
        <FloatMensaje footermensaje={props.footermensaje} />
      ) : null}
      <Box sx={{ maxHeight: "350px", backgroundColor: "#7a12b1" }}>
        <Box
          component="img"
          src={imgbannerfooter}
          alt="Banner Footer"
          sx={{ maxHeight: "350px", height: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default Bannerfooter;
