import React, { useState } from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import {
  Box,
  Button,
  Divider,
  Grid2,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useEffect } from "react";
import getValidationAdressGeoRef from "../../utils/getValidationAdressGeoRef";
import getResolveValidationAdressGeoRef from "../../utils/getResolveValidationAdressGeoRef";
import {
  collection,
  doc,
  getDoc,
  Timestamp,
  GeoPoint,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import axios from "axios";
import MapWithRoute from "./MapWithRoute";
import FirestoreIndexTester from "../MapComponentMonitorLeaflet/MapMenuFlotante/testDev2";
import ContainerViewComponent from "../ContainerViewComponent/ContainerViewComponent";

const libraries = ["places", "geometry"];

const DevelopTests = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [adressDirection, setAdressManuality] = useState("");
  const [responsePlaceApi, setResponsePlaceApi] = useState("");
  const [responsePlaceApiDirections, setResponsePlaceApiDirections] =
    useState("");
  const [Validarobjeto, setValidarobjeto] = useState({});

  const [validation1, setValidation1] = useState(null);
  const [validation2, setValidation2] = useState(null);
  const [validationresolve1, setValidationResolve1] = useState(null);
  const [validationresolve2, setValidationResolve2] = useState(null);
  const [validationResponse, setValidationResponse] = useState(null);
  const [responsePlaceApiDirectionsResolve] = useState(null);

  const [developAdres, setDevelopAdres] = useState("");

  const [direccionResolver, setResolverDireccion] = useState(null);
  const [origin, setOrigin] = React.useState({
    lat: -33.4592446,
    lng: -70.6014753,
  });
  const [destination, setDestination] = React.useState({
    lat: -33.4592446,
    lng: -70.6014753,
  });

  const [route, setRoute] = useState(null); // Guarda los datos de la ruta

  const waypoints = [
    { lon: -70.6483, lat: -33.4569 }, // Inicio
    { lon: -70.64, lat: -33.45 }, // Parada 1
    { lon: -70.63, lat: -33.44 }, // Parada 2
    { lon: -70.6206, lat: -33.4372 }, // Destino
  ];

  // Construir la URL para la API de OSRM
  const buildUrl = () => {
    const coords = waypoints.map((wp) => `${wp.lon},${wp.lat}`).join(";");
    return `http://157.230.179.178:5000/route/v1/driving/${coords}?overview=full&geometries=geojson`;
  };

  useEffect(() => {
    fetchRoute();
  }, []);

  const fetchRoute = async () => {
    try {
      const response = await axios.get(buildUrl());
      if (response.data.code === "Ok") {
        setRoute(response.data.routes[0]);
      } else {
        console.error("Error al obtener la ruta:", response.data);
      }
    } catch (error) {
      console.error("Error en la solicitud a OSRM:", error);
    }
  };

  useEffect(() => {}, [
    responsePlaceApi,
    direccionResolver,
    validationResponse,
  ]);

  useEffect(() => {
    // validation(developAdres);
    validationAndResolve(developAdres);
  }, [developAdres]);

  async function validation(params) {
    if (params !== "" || !params) {
      let respuesta = await getValidationAdressGeoRef(params);
      console.log("Dirección Error: ", params);
      console.log("respuesta", respuesta);
      if (respuesta.response === "OK") {
        console.log("Direction Ok");
      } else {
        let respuesta2 = await getResolveValidationAdressGeoRef(params);
        if (respuesta2.response === "OK") {
          console.log("respuesta Resolve", respuesta2);
          setValidationResponse(
            "respuesta Dirección Correcta: " + respuesta2.response
          );
        }
      }
    }
  }

  async function validationAndResolve(params) {
    if (params !== "" || !params) {
      let respuesta = await getResolveValidationAdressGeoRef(params);

      console.log("respuesta", respuesta);
      if (respuesta.response !== "null") {
        setValidationResponse(
          <div>
            <div>{`Respuesta:   ${respuesta.response}`}</div>
            <div>{`Dirección:   ${respuesta.newDir}`}</div>
            <div>{`Respuesta Geo:  ${respuesta.newGeoRef.lat},${respuesta.newGeoRef.lng} `}</div>
          </div>
        );
      } else {
        <div></div>;
      }
    }
  }

  const HandleResponses = () => {
    buscarDireccion();
    buscarDireccionesEnRuta();
  };

  const HandleGetDirectiones = async (e) => {
    e.preventDefault();
    let address = adressDirection;

    try {
      const results = await getGeocode({ address });
      //   const results = await getGeocode({"farellones norte 50, Lo barnechea"});

      setResponsePlaceApi(results[0]);
    } catch (error) {
      // console.log(element)
    }
  };

  const buscarDireccion = async () => {
    const Validarobjeto = {
      street_number: "",
      route: "",
      locality: "",
      administrative_area_level_3: "",
      administrative_area_level_2: "",
      administrative_area_level_1: "",
      country: "",
      postal_code: "",
      lngLat: "",
      formatted_address: "",
      place_id: "",
    };

    for (let i = 0; i < responsePlaceApi.address_components.length; i++) {
      let elementAdress = responsePlaceApi.address_components[i];

      switch (elementAdress["types"][0]) {
        case "street_number":
          Validarobjeto.street_number = elementAdress.long_name;
          break;
        case "route":
          Validarobjeto.route = elementAdress.long_name;
          break;
        case "locality":
          Validarobjeto.locality = elementAdress.long_name;
          break;
        case "administrative_area_level_3":
          Validarobjeto.administrative_area_level_3 = elementAdress.long_name;
          break;
        case "administrative_area_level_2":
          Validarobjeto.administrative_area_level_2 = elementAdress.long_name;
          break;
        case "administrative_area_level_1":
          Validarobjeto.administrative_area_level_1 = elementAdress.long_name;
          break;
        case "country":
          Validarobjeto.country = elementAdress.long_name;
          break;
        case "postal_code":
          Validarobjeto.postal_code = elementAdress.long_name;
          break;

        default:
          break;
      }
    }

    const { lat, lng } = getLatLng(responsePlaceApi);

    Validarobjeto.lngLat = `${lat},${lng}`;
    Validarobjeto.formatted_address = responsePlaceApi.formatted_address;

    setValidation1(responsePlaceApi.formatted_address);

    Validarobjeto.place_id = responsePlaceApi.place_id;

    setValidarobjeto(Validarobjeto);
  };

  const buscarDireccionesEnRuta = async () => {
    let wayPointsTest = [
      {
        stopover: true,
        location: Validarobjeto.lngLat,
      },
    ];

    const DirectionsServiceOption = {
      origin: origin,
      destination: destination,
      travelMode: "BICYCLING",
      optimizeWaypoints: true,
      waypoints: wayPointsTest,
    };

    var directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: DirectionsServiceOption.origin,
        destination: DirectionsServiceOption.destination,
        travelMode: DirectionsServiceOption.travelMode,
        optimizeWaypoints: DirectionsServiceOption.optimizeWaypoints,
        waypoints: DirectionsServiceOption.waypoints,
      },
      function (response, status) {
        if (status === window.google.maps.DirectionsStatus.OK) {
          try {
            if (response.routes[0].waypoint_order.length > 0) {
              setResponsePlaceApiDirections(response.routes[0]);
              setValidation2(response.routes[0].legs[0].end_address);
              console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
              console.log("Evaluar si es necesario");
              console.log(destination);
              console.log(origin);
              console.log("Evaluar si es necesario ");
            } else {
              console.log("precondicion error");
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    );
  };

  const validarDirecciones = (validation0, validation1, validation2) => {
    if (validation0 && validation1 && validation2) {
      const dir0Elements = validation0.split(",");
      const dir1Elements = validation1.split(",");
      const dir2Elements = validation2.split(",");
      let coincidencias = 0;

      for (let i = 0; i < dir0Elements.length; i++) {
        const dir0Words = dir0Elements[i].trim().split(" ");

        for (let j = 0; j < dir1Elements.length; j++) {
          const dir1Words = dir1Elements[j].trim().split(" ");

          for (let k = 0; k < dir2Elements.length; k++) {
            const dir2Words = dir2Elements[k].trim().split(" ");

            for (let x = 0; x < dir0Words.length; x++) {
              if (
                dir1Words.includes(dir0Words[x]) &&
                dir2Words.includes(dir0Words[x])
              ) {
                coincidencias++;
                break;
              }
            }
          }
        }
      }

      if (coincidencias >= 2) {
        return <div className="alert alert-success">La direcciones Ok.</div>;
      } else {
        return <div className="alert alert-danger">Error en la dirección.</div>;
      }
    }
  };

  const validarNewDirecciones = (validation0, validation1, validation2) => {
    if (validation0 && validation1 && validation2) {
      const dir0Elements = validation0.split(",");
      const dir1Elements = validation1.split(",");
      const dir2Elements = validation2.split(",");
      let coincidencias = 0;

      for (let i = 0; i < dir0Elements.length; i++) {
        const dir0Words = dir0Elements[i].trim().split(" ");

        for (let j = 0; j < dir1Elements.length; j++) {
          const dir1Words = dir1Elements[j].trim().split(" ");

          for (let k = 0; k < dir2Elements.length; k++) {
            const dir2Words = dir2Elements[k].trim().split(" ");

            for (let x = 0; x < dir0Words.length; x++) {
              if (
                dir1Words.includes(dir0Words[x]) &&
                dir2Words.includes(dir0Words[x])
              ) {
                coincidencias++;
                break;
              }
            }
          }
        }
      }

      if (coincidencias >= 2) {
        return <div className="alert alert-success">La dirección Ok.</div>;
      } else {
        return <div className="alert alert-danger">Error en la dirección.</div>;
      }
    }
  };

  const resolverDireccion = async (dirección) => {
    // Dividir el array por comas
    const elementos = dirección.split(",");

    // Obtener el primer elemento
    const primerElemento = elementos[0];

    // Encontrar la primera palabra que es un número
    const palabras = primerElemento.split(" ");
    let indice = -1;
    for (let i = 0; i < palabras.length; i++) {
      if (!isNaN(parseInt(palabras[i]))) {
        indice = i;
        break;
      }
    }

    // Concatenar el array hasta la primera palabra que es un número, incluyendo la palabra "390"
    let nuevoArray = palabras.slice(0, indice + 1).join(" ");
    nuevoArray += ", ";
    // Concatenar el resto del array original
    nuevoArray += elementos.slice(1).join(",");

    setResolverDireccion(nuevoArray);
    let address = nuevoArray;
    //New API GeoRef
    try {
      const results = await getGeocode({ address });
      //   const results = await getGeocode({"farellones norte 50, Lo barnechea"});

      setResponsePlaceApi(results[0]);

      const Validarobjeto = {
        street_number: "",
        route: "",
        locality: "",
        administrative_area_level_3: "",
        administrative_area_level_2: "",
        administrative_area_level_1: "",
        country: "",
        postal_code: "",
        lngLat: "",
        formatted_address: "",
        place_id: "",
      };

      for (let i = 0; i < results[0].address_components.length; i++) {
        let elementAdress = results[0].address_components[i];

        switch (elementAdress["types"][0]) {
          case "street_number":
            Validarobjeto.street_number = elementAdress.long_name;
            break;
          case "route":
            Validarobjeto.route = elementAdress.long_name;
            break;
          case "locality":
            Validarobjeto.locality = elementAdress.long_name;
            break;
          case "administrative_area_level_3":
            Validarobjeto.administrative_area_level_3 = elementAdress.long_name;
            break;
          case "administrative_area_level_2":
            Validarobjeto.administrative_area_level_2 = elementAdress.long_name;
            break;
          case "administrative_area_level_1":
            Validarobjeto.administrative_area_level_1 = elementAdress.long_name;
            break;
          case "country":
            Validarobjeto.country = elementAdress.long_name;
            break;
          case "postal_code":
            Validarobjeto.postal_code = elementAdress.long_name;
            break;

          default:
            break;
        }
      }

      const { lat, lng } = getLatLng(results[0]);

      Validarobjeto.lngLat = `${lat},${lng}`;
      Validarobjeto.formatted_address = results[0].formatted_address;

      setValidationResolve1(results[0].formatted_address);

      Validarobjeto.place_id = results[0].place_id;

      setValidarobjeto(Validarobjeto);

      let wayPointsTest = [
        {
          stopover: true,
          location: Validarobjeto.lngLat,
        },
      ];

      const DirectionsServiceOption = {
        origin: origin,
        destination: destination,
        travelMode: "BICYCLING",
        optimizeWaypoints: true,
        waypoints: wayPointsTest,
      };

      var directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: DirectionsServiceOption.origin,
          destination: DirectionsServiceOption.destination,
          travelMode: DirectionsServiceOption.travelMode,
          optimizeWaypoints: DirectionsServiceOption.optimizeWaypoints,
          waypoints: DirectionsServiceOption.waypoints,
        },
        function (response, status) {
          if (status === window.google.maps.DirectionsStatus.OK) {
            try {
              if (response.routes[0].waypoint_order.length > 0) {
                setResponsePlaceApiDirections(response.routes[0]);
                // setResponsePlaceApiDirectionsResolve(response.routes[0]);
                setValidationResolve2(response.routes[0].legs[0].end_address);
                console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
                console.log("Evaluar si es necesario");
                console.log(destination);
                console.log(origin);
                console.log("Evaluar si es necesario ");
              } else {
                console.log("precondicion error");
              }
            } catch (error) {}
          }
        }
      );
    } catch (error) {
      //console.log("😱 Error: ", error);
      // console.log(element)
    }
  };

  function constructWazeLink(searchText) {
    const encodedSearchText = encodeURIComponent(searchText);
    const wazeLink = `https://waze.com/ul?q=${encodedSearchText}`;
    return wazeLink;
  }

  const verOrdendePrueba = async () => {
    const collRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
    const orderRef = doc(collRef, "19911125103");
    //
    try {
      const newOrden = await getDoc(orderRef);
      console.log(newOrden.data());
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const actulizarOrdendePrueba = async () => {
    const testData = {
      id: "RECI19912125601",
      createdAt: Timestamp.fromDate(new Date()),
      date: Timestamp.fromDate(new Date()),
      status: 1,
      totalAmount: 0,
      contribution: 0,
      serviceAmount: 0,
      deliveryAmount: 0,
      tip: 0,
      amount: 0,
      driverAmount: 750,
      discount: 0,
      deliverOption: "",
      deliveryType: "HD",
      receiver: "",
      express: false,
      retryCount: 3,
      couponID: "",
      timeleft: 60,
      fromNumber: "",
      toNumber: "",
      product: "",
      lastMile: true,
      entrega: true,
      enCocina: true,
      instructions: "",
      priceRange: "",
      couponCode: "",
      payMethod: "",
      userID: "RECIBELO",
      companyID: "FroY2JbeirD7B1MRYEXo",
      placeName: "RECIBELO",
      placeThumbnail: "",
      fromAddress: "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
      fromAddressLocation: new GeoPoint(-33.4592491, -70.6014753),
      toAddress: "Julio Zegers 3701, Ñuñoa",
      toAddressLocation: new GeoPoint(-33.4513036, -70.5935356),
      toAddressComment: "",
      userPhone: "+56987654321",
      userEmail: "usuario@integracion.cl",
      userName: "Juan Perez Cliente",
    };

    const testData2 = {
      status: 1,
      totalAmount: 0,
      contribution: 0,
      serviceAmount: 0,
      deliveryAmount: 0,
      tip: 0,
      amount: 0,
      driverAmount: 750,
      discount: 0,
      deliverOption: "",
      receiver: "",
      express: false,
      retryCount: 3,
      couponID: "",
      timeleft: 60,
      fromNumber: "",
      toNumber: "",
      lastMile: true,
      entrega: true,
      enCocina: true,
      instructions: "",
      priceRange: "",
      couponCode: "",
      payMethod: "",
      fromAddressLocation: new GeoPoint(-33.4592491, -70.6014753),
      toAddressLocation: new GeoPoint(-33.4513036, -70.5935356),
      userID: "BICCIEXPRESS",
      placeName: "BICCIEXPRESS",
      placeThumbnail:
        "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00",
      id: "BX19912125603",
      createdAt: Timestamp.fromDate(new Date()),
      date: Timestamp.fromDate(new Date()),
      deliveryEstimateDate: Timestamp.fromDate(
        new Date("2024-07-07T00:00:00.000Z")
      ),
      deliveryType: "HD",
      whcode: "WH123",
      fromAddress: "Alcalde Eduardo Castillo Velasco 3101, Ñuñoa",
      direcction1: "Alcalde Eduardo Castillo Velasco",
      direcction2: "3101",
      direcction3: "",
      comuna: "Ñuñoa",
      region: "Metropolitana",
      country: "Chile",
      toAddress: "Julio Zegers 3701, Ñuñoa",
      toAddressComment: "dejar en conserjeria",
      userPhone: "+56987654321",
      userEmail: "clientefinal@integracion.cl",
      userName: "Juan Perez Cliente",
      product: "Calzado",
      groceries: [
        {
          description: "Polera postman en test",
          id: "2czQxEsfDNi1IXqmVBAE_0",
          name: "Polera postman",
          instructions: "",
          quantity: 1,
          price: 10,
          height: 10,
          width: 20,
          length: 30,
          weight: 2,
          volume: 6000,
        },
        {
          description: "Zapatos postman en test",
          id: "2czQxEsfDNi1IXqmVBAE_1",
          name: "Bototos postman",
          instructions: "",
          quantity: 1,
          price: 10,
          height: 10,
          width: 20,
          length: 30,
          weight: 2,
          volume: 6000,
        },
      ],
    };

    const collRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
    const orderRef = doc(collRef, "BX19912125603");
    //const newOrden = await getDoc(orderRef);
    try {
      //await updateDoc(orderRef, testData);
      await setDoc(orderRef, testData2);
      const newOrden = await getDoc(orderRef);
      console.log(newOrden.data());
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const addConfigCompany = async () => {
    const companyRef = db.collection("companies").doc("companyId");

    // Configuracion recibelo
    let config = {
      active: true,
      driverAmount: 650,
      integrationsUpdateStatus: true,
      name: "BICCI EXPRESS DEV",
      placeThumbnail: "",
      prefix: "BEX",
      serviceRateRider: {
        HD: "650",
        SD: "750",
      },
      stateApproval: {
        0: "",
        1: "2",
        2: "2",
        3: "",
        4: "6",
        5: "",
        6: "7",
        7: "",
        8: "8",
      },
      nonDelivery: [
        {
          intState: "10",
          state: "cancelled",
          reasonCode: "1000",
          name: "Cliente rechazó la entrega",
        },
        {
          intState: "10",
          state: "cancelled",
          reasonCode: "1001",
          name: "Entrega cancelada por el cliente",
        },
        {
          intState: "10",
          state: "cancelled",
          reasonCode: "1002",
          name: "Cliente no reconoció la orden",
        },
        {
          intState: "9",
          state: "no_completed",
          reasonCode: "1003",
          name: "Cliente no se encuentra en domicilo",
        },
        {
          intState: "9",
          state: "no_completed",
          reasonCode: "1004",
          name: "Paquete dañado durante el transporte",
        },
        {
          intState: "9",
          state: "no_completed",
          reasonCode: "1005",
          name: "Direccion erronea",
        },
        {
          intState: "9",
          state: "no_completed",
          reasonCode: "1006",
          name: "Paquete no entregado por tiempo",
        },
        {
          intState: "11",
          state: "duplicated",
          reasonCode: "1007",
          name: "Duplicado",
        },
        {
          intState: "14",
          state: "en_devolucion",
          reasonCode: "1008",
          name: "Proceso de devolución",
        },
        {
          intState: "15",
          state: "devuelto",
          reasonCode: "2009",
          name: "Devuelto",
        },
        {
          intState: "16",
          state: "no_devuelto",
          reasonCode: "2010",
          name: "Paquete extraviado",
        },
        {
          intState: "16",
          state: "no_devuelto",
          reasonCode: "2011",
          name: "Paquete robado en ruta",
        },
        {
          intState: "16",
          state: "no_devuelto",
          reasonCode: "2012",
          name: "Paquete accidente con pérdidas",
        },
        {
          intState: "16",
          state: "no_devuelto",
          reasonCode: "2013",
          name: "Paquete dañado durante el transporte",
        },
        {
          intState: "16",
          state: "no_devuelto",
          reasonCode: "2014",
          name: "Paquete faltante en carga",
        },
        {
          intState: "16",
          state: "no_devuelto",
          reasonCode: "2015",
          name: "Producto incompleto",
        },
      ],
      urlUpdateStatus:
        "https://us-central1-bicci-dev-c842f.cloudfunctions.net/ebiexSimulatorClienteRecibeStatus",
    };
  };

  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return (
    <div className="h2-withe">
      <div>
        <h3>DevelopTests</h3>
      </div>
      <ContainerViewComponent className="dashcontent">
        <Grid2>
          <FirestoreIndexTester></FirestoreIndexTester>
          <Grid2 className="cart-Grid2  -Row">
            <Grid2 className="colors-DevelopTests">
              <div>
                <h3>API GeoRef </h3>

                <Box
                  component="form"
                  onSubmit={HandleGetDirectiones}
                  sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 2,
                  }}
                >
                  <Divider />
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Dirección
                  </Typography>

                  {/* Campo de Dirección */}
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      placeholder="Ingrese dirección"
                      type="text"
                      onChange={(e) => setAdressManuality(e.target.value)}
                      value={adressDirection}
                      InputProps={{ startAdornment: <span>▶</span> }} // Simula icono "angle right"
                    />
                  </Box>

                  {/* Botón de Consulta */}
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
                  >
                    <Button
                      sx={{
                        backgroundColor: "#591E8F",
                        color: "#fff",
                        fontSize: "14px",
                        padding: "5px",
                        minWidth: "50px",
                        fontWeight: 400,
                        margin: "0 1em 0 0",
                        ":hover": {
                          backgroundColor: "#25b2f4 !important",
                          color: "#591e8f",
                        },
                      }}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Consulta..
                    </Button>
                  </Box>
                </Box>
              </div>
              <div>
                <h3>API usePlacesAutocomplete</h3>

                <form onSubmit={HandleResponses}>
                  <Divider sx={{ mb: 2 }} />

                  {/* Título */}
                  <Typography variant="h5" gutterBottom>
                    Dirección
                  </Typography>

                  {/* Input de búsqueda */}
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="inputSearchFromOrigen"
                      label="Buscar dirección"
                      variant="outlined"
                      fullWidth
                    />
                  </Box>

                  {/* Botón */}
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
                  >
                    <Button
                      sx={{
                        backgroundColor: "#591E8F",
                        color: "#fff",
                        borderRadius: "3px",
                        fontSize: "14px",
                        padding: "5px 16px",
                        minWidth: "120px",
                        fontWeight: 400,
                        ":hover": {
                          backgroundColor: "#25b2f4 !important",
                          color: "#591e8f",
                        },
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Ver respuesta
                    </Button>
                  </Box>
                </form>

                {Validarobjeto ? (
                  <Box>
                    <div>
                      <div>{Validarobjeto.street_number}</div>
                      <div>{Validarobjeto.route}</div>
                      <div>{Validarobjeto.locality}</div>
                      <div>{Validarobjeto.administrative_area_level_3}</div>
                      <div>{Validarobjeto.administrative_area_level_2}</div>
                      <div>{Validarobjeto.administrative_area_level_1}</div>
                      <div>{Validarobjeto.country}</div>
                      <div>{Validarobjeto.postal_code}</div>
                      <div>{Validarobjeto.lngLat}</div>
                      <div>{Validarobjeto.place_id}</div>
                      <div>{Validarobjeto.formatted_address}</div>
                    </div>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
            </Grid2>
            <Grid2
              className="colors-DevelopTests"
              computer={9}
              mobile={16}
              tablet={16}
            >
              <div>
                <h3>API Routes Maps </h3>

                <form onSubmit={buscarDireccion}>
                  <Divider sx={{ mb: 2 }} />

                  {/* Título */}
                  <Typography variant="h5" gutterBottom>
                    Respuesta Dirección
                  </Typography>

                  {/* Mostrar la dirección si está disponible */}
                  {responsePlaceApiDirections && (
                    <Box
                      sx={{
                        p: 2,
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography variant="body1">
                        <strong>Dirección:</strong>{" "}
                        {responsePlaceApiDirections.legs[0].end_address}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Coordenadas:</strong>{" "}
                        {responsePlaceApiDirections.legs[0].end_location.lat()}{" "}
                        ,{" "}
                        {responsePlaceApiDirections.legs[0].end_location.lng()}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Modo de Viaje:</strong>{" "}
                        {responsePlaceApiDirections.travelMode}
                      </Typography>
                    </Box>
                  )}

                  {/* Validación de direcciones */}
                  <Box sx={{ mt: 2 }}>
                    {validarDirecciones(
                      adressDirection,
                      validation1,
                      validation2
                    )}
                  </Box>

                  {/* Sección de solución */}
                  <Stack spacing={2} mt={3}>
                    <Typography variant="h6">Solución</Typography>

                    {/* Botón de solución */}
                    <Button
                      sx={{
                        backgroundColor: "#591E8F",
                        color: "#fff",
                        borderRadius: "3px",
                        fontSize: "14px",
                        padding: "5px 16px",
                        minWidth: "120px",
                        fontWeight: 400,
                        ":hover": {
                          backgroundColor: "#25b2f4 !important",
                          color: "#591e8f",
                        },
                      }}
                      variant="contained"
                      onClick={() => resolverDireccion(adressDirection)}
                    >
                      Solucionar
                    </Button>

                    {/* Dirección resuelta */}
                    {direccionResolver && (
                      <Box>
                        <Typography variant="body1">
                          <strong>Dirección Resuelta:</strong>{" "}
                          {direccionResolver}
                        </Typography>
                        {validarNewDirecciones(
                          direccionResolver,
                          validationresolve1,
                          validationresolve2
                        )}
                      </Box>
                    )}

                    {/* Coordenadas de la dirección resuelta */}
                    {responsePlaceApiDirectionsResolve && (
                      <Typography variant="body2">
                        <strong>Coordenadas Resueltas:</strong>{" "}
                        {responsePlaceApiDirectionsResolve.legs[0].end_location.lat()}{" "}
                        ,{" "}
                        {responsePlaceApiDirectionsResolve.legs[0].end_location.lng()}
                      </Typography>
                    )}

                    {/* Enlace a Google Maps */}
                    {responsePlaceApiDirections ? (
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${responsePlaceApiDirections.legs[0].end_location.lat()},${responsePlaceApiDirections.legs[0].end_location.lng()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography variant="body2" sx={{ color: "#1976d2" }}>
                          📍 Ver en Google Maps
                        </Typography>
                      </a>
                    ) : (
                      <Typography variant="body2" color="error">
                        No se encontró dirección
                      </Typography>
                    )}

                    {/* Enlace a Waze */}
                    <a
                      href={constructWazeLink(direccionResolver)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <img src="/waze.svg" alt="Waze" width="24px" />
                        <Typography variant="body2" sx={{ color: "#1976d2" }}>
                          🚗 Navega con Waze
                        </Typography>
                      </Stack>
                    </a>
                  </Stack>
                </form>
              </div>
            </Grid2>
          </Grid2>
          <hr></hr>
          <Grid2 className="cart-Grid2  -Row">
            <Grid2
              className="colors-DevelopTests"
              computer={16}
              mobile={16}
              tablet={16}
            >
              <div>
                <h3>Direccon ingresada al sistema </h3>
                <div className="m-1">
                  <input
                    className="textdevelop"
                    type="text"
                    cols="40"
                    rows="5"
                    name="Text1"
                    value={developAdres}
                    onChange={(e) => setDevelopAdres(e.currentTarget.value)}
                  />
                </div>
              </div>
            </Grid2>
            <Grid2
              className="colors-DevelopTests"
              computer={16}
              mobile={16}
              tablet={16}
            >
              <div>
                <h3>Auto Correcion </h3>
                <div>{validationResponse}</div>
              </div>
            </Grid2>
          </Grid2>
          <Grid2 className="cart-Grid2  -Row">
            <Grid2
              className="colors-DevelopTests"
              computer={16}
              mobile={16}
              tablet={16}
            >
              <Button sx={{ m: 1 }} onClick={() => verOrdendePrueba()}>
                Ver orden de prueba
              </Button>
              <Button sx={{ m: 1 }} onClick={() => actulizarOrdendePrueba()}>
                Actualizar orden de prueba
              </Button>
              <Button sx={{ m: 1 }} onClick={() => addConfigCompany()}>
                Crear empresa
              </Button>
            </Grid2>
          </Grid2>
          <Grid2 className="cart-Grid2  -Row" style={{ minHeight: "700px" }}>
            <Grid2
              className="colors-DevelopTests"
              computer={16}
              mobile={16}
              tablet={16}
            >
              <MapWithRoute></MapWithRoute>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 className="cart-Grid2  -Row"></Grid2>
      </ContainerViewComponent>
    </div>
  );

  function Search() {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      setAdressManuality(address);
      setValue(address, false);
      clearSuggestions();

      try {
        const results = await getGeocode({ address });

        setResponsePlaceApi(results[0]);
      } catch (error) {
        //console.log("😱 Error: ", error);
        // console.log(element)
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-2"
            id="imputSearchFromTo2"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Busca tu ubicación"
          />

          <ComboboxPopover className="comboboxList-ebiex">
            <ComboboxList key={Math.random().toString()}>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                    className="dropdown-item dropdown-ebiex"
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }
};

export default DevelopTests;
