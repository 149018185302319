import { useState, useEffect } from "react";
import { Polygon, Popup } from "react-leaflet";

const StaticPolygonsLayer3d = ({ mapVectoriales }) => {
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);

  useEffect(() => {
    if (!mapVectoriales) return;

    const leafletPolygons = mapVectoriales.features.flatMap(
      (feature, index) => {
        if (feature.geometry.type === "Polygon") {
          const paths = feature.geometry.coordinates[0].map(([lng, lat]) => [
            lat,
            lng,
          ]);

          const getRandomColor = () => {
            const colors = [
              "#FF5733",
              "#33FF57",
              "#3357FF",
              "#FF33A8",
              "#FFD433",
            ];
            return colors[Math.floor(Math.random() * colors.length)];
          };

          const polygonColor = feature.properties.color || getRandomColor();

          const isSelected = selectedPolygon === index;

          return (
            <Polygon
              key={index}
              positions={paths}
              pathOptions={{
                color: polygonColor,
                weight: isSelected ? 3 : 1, // Borde más grueso al seleccionar
                fillOpacity: isSelected ? 0.7 : 0.3, // Mayor opacidad en selección
                fillColor: polygonColor,
                dashArray: isSelected ? "5, 5" : "none", // Línea punteada para resaltar
                shadowColor: "rgba(0, 0, 0, 0.7)", // Sombra para efecto 3D
                shadowBlur: isSelected ? 10 : 0, // Desenfoque de la sombra
                shadowOffsetX: isSelected ? 5 : 0, // Desplazamiento de sombra
                shadowOffsetY: isSelected ? 5 : 0,
              }}
              eventHandlers={{
                click: () => setSelectedPolygon(index),
              }}
            >
              <Popup>
                <div>
                  <strong>{feature.properties.Name}</strong>
                </div>
              </Popup>
            </Polygon>
          );
        }
        return [];
      }
    );

    setPolygons(leafletPolygons);
  }, [mapVectoriales, selectedPolygon]);

  return <>{polygons}</>;
};

export default StaticPolygonsLayer3d;
