export const shouldOptimize = (options) => {
  // console.log("🚀 Validaciones principales para determinar como llama a la función");

  // 1️⃣ Validar si se debe aplicar la sectorización
  if (options.applySectorization) {
    // console.log("✅ Aplicando sectorización por polígonos...");
    if (options.keepSectorsSeparated) {
      // console.log("✅ Manteniendo separados los polígonos...");
    }
  }

  // 2️⃣ Determinar si se debe retornar a la tienda en cada vuelta
  if (options.returnToStore) {
    // console.log("✅ Las rutas retornarán a la tienda origen en cada vuelta.");
  }

  // 3️⃣ Definir el destino
  switch (options.destinationType) {
    case "mas_lejano":
      // console.log("✅ Optimizando destino más lejano...");
      break;
    case "destino":
      // console.log("✅ Optimizando destino específico...");
      break;
    case "retorno":
      // console.log("✅ Optimizando con retorno...");
      break;
    default:
      // console.log("⚠️ Destino no definido, usando predeterminado.");
      break;
  }

  // 4️⃣ Validar si se deben respetar comunas/polígonos
  if (options.respectZones) {
    // console.log("✅ Respetando comunas o polígonos creados.");
  }

  // 5️⃣ Verificar proveedor de optimización
  if (options.provider !== "Bicci") {
    // console.log(`✅ Usando proveedor de optimización: ${options.provider}`);
  } else {
    // console.log("✅ Usando proveedor predeterminado: Bicci.");
  }

  // 6️⃣ Validar si se optimizan todos los objetos o solo los seleccionados
  if (options.selector === "Seleccionados") {
    // console.log("✅ Optimizando solo los objetos seleccionados...");
  } else {
    // console.log("✅ Optimizando todos los objetos...");
  }

  // 7️⃣ Validar la creación de vehículos
  if (options.createRequireVehicles) {
    // console.log("✅ Creando vehículos automáticamente...");
  } else if (options.vehicleCount > 0) {
    // console.log(`✅ Asignando manualmente ${options.vehicleCount} vehículos.`);
  } else {
    console.error("❌ Error: Se requiere al menos un vehículo para optimizar.");
    return false;
  }

  // 8️⃣ Validar la creación de agrupaciones
  if (options.createRequireAgrupations) {
    // console.log("✅ Creando agrupaciones de carga automáticamente...");
  } else if (options.agrupationCount > 0) {
    // console.log(`✅ Limitando agrupaciones a ${options.agrupationCount} unidades.`);
  } else {
    console.error("❌ Error: Se requiere al menos una agrupación válida.");
    return false;
  }

  // 9️⃣ Validar restricciones de optimización
  if (options.maxKilometers && options.maxKilometers > 0) {
    // console.log(`✅ Máximo permitido de kilómetros: ${options.maxKilometers} km.`);
  } else {
    // console.log("✅ Sin restricción de kilómetros.");
  }

  if (options.maxTime && options.maxTime > 0) {
    // console.log(`✅ Máximo permitido de tiempo: ${options.maxTime} minutos.`);
  } else {
    // console.log("✅ Sin restricción de tiempo.");
  }

  // console.log("✅ Todas las validaciones se han completado con éxito.");
  return true;
};
