export const routeInfo = async (updatedOrders, routes) => {
  // Validación de datos de entrada
  const missingFields = [];

  if (!updatedOrders || !Array.isArray(updatedOrders)) {
    missingFields.push("updatedOrders (array de órdenes actualizadas)");
  }
  if (!routes || !Array.isArray(routes)) {
    missingFields.push("routes (array de rutas)");
  }

  if (missingFields.length > 0) {
    console.error("⛔ Faltan los siguientes datos de entrada:", missingFields);
    console.error("🔹 Estructura esperada:", {
      updatedOrders: [
        {
          driverLicensePlate: "string (opcional)",
          id: "string",
          otherData: "...",
        },
      ],
      routes: ["string"],
    });
    return { packageCounts: {}, ordersByRoute: {} };
  }

  const packageCounts = {};
  const ordersByRoute = {};

  if (updatedOrders.length > 0) {
    updatedOrders.forEach((order) => {
      if (!order || typeof order !== "object") {
        console.warn("⚠️ Se encontró una orden inválida, omitiendo.");
        return;
      }

      const route = order.driverLicensePlate || "Sin Ruta";
      if (!packageCounts[route]) {
        packageCounts[route] = 0;
      }
      packageCounts[route] += 1; // Suponiendo 1 bulto por orden
      if (!ordersByRoute[route]) {
        ordersByRoute[route] = [];
      }
      ordersByRoute[route].push(order);
    });
  }

  // Asegurar que existan entradas para rutas sin órdenes
  routes.forEach((route) => {
    if (!ordersByRoute[route]) {
      ordersByRoute[route] = [];
      packageCounts[route] = 0;
    }
  });

  return { packageCounts, ordersByRoute };
};
