import React from "react";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";

const StateHomologation = () => {
  return (
    <div>
      <Box p={3} sx={{ color: "#030303" }}>
        <Typography variant="h4" gutterBottom>
          Homologación de Estados
        </Typography>

        <Typography variant="h6" gutterBottom>
          Homologación de Estados
        </Typography>
        <Typography variant="body1" paragraph>
          Para el cierre de la orden exitosamente, se deben homologar los
          estados. La URL donde su API recibirá avances y cambios de estado será
          proporcionada. Requerimos una lista de estados de no entrega admitidos
          en su sistema.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Entrega de Cliente Final
        </Typography>
        <Typography variant="body1" paragraph>
          Para la entrega de cliente final, enviaríamos un objeto de acuerdo a
          su requerimiento. Los datos disponibles para el envío de evidencia en
          estado de entrega serían:
        </Typography>
        <Typography variant="h6" gutterBottom>
          Producto entregado
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Nombre del receptor" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Rut del receptor" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Fotos de entrega" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Firma digital" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Georreferencia de entrega" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Nombre del conductor" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Código de conductor" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Fecha de entrega" />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Estados de No Entrega que Permiten una Segunda Visita
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Cliente ausente" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Dirección incorrecta" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Dirección incompleta" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Acceso restringido" />
          </ListItem>
          <ListItem>
            <ListItemText primary="No se pudo contactar al cliente" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Entrega fuera del horario establecido" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Condiciones climáticas adversas" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Vehículo averiado" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Problemas de tráfico" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Problemas con el paquete" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Problemas técnicos con el equipo de entrega" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente solicitó reprogramación" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente en vacaciones" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente en reunión" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente cambió de dirección sin aviso" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente solicitó entrega en otra ubicación" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Dirección no encontrada" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Paquete retenido por seguridad" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Restricciones de acceso en la zona" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente no respondió al timbre" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Mascota suelta en la propiedad" />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Estados de No Entrega que Permiten la Devolución
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Cliente rechazó la entrega" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Entrega cancelada por el cliente" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente no reconoció la orden" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Cliente no tenía efectivo para pagar" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Paquete dañado durante el transporte" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Paquete devuelto al remitente" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Paquete no autorizado para la entrega" />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Estados que Pasan a Conciliaciones
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Devolución" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Pérdida o extravío" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Siniestro robo" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Siniestro accidentes con pérdidas" />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Cancelación de Entrega Sistemático
        </Typography>
        <Typography variant="body1" paragraph>
          Con el cierre de folio sistemático, nuestras áreas deben realizar las
          tareas administrativas respectivas, incluyendo:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Conciliaciones de devoluciones completadas" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Conciliaciones de pagos" />
          </ListItem>
        </List>
      </Box>
    </div>
  );
};

export default StateHomologation;
