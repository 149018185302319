import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";

// Puedes centralizar tus items de menú en un arreglo para mejor mantenimiento.
const menuItems = [
  { label: "Introduction", value: "introduction" },
  { label: "Integration Environment", value: "integration-environment" },
  { label: "Production Environment", value: "production-environment" },
  { label: "Last Mile API", value: "last-mile-api" },
  { label: "Create API", value: "create-api" },
  { label: "Bulk Creation", value: "bulk-creation" },
  { label: "State Homologation", value: "state-homologation" },
  { label: "Create API Variables", value: "create-api-variables" },
  { label: "Bulk Creation Variables", value: "bulk-creation-variables" },
];

const Sidebar = ({ onSelect }) => {
  return (
    <Box
      sx={{
        width: "250px",
        height: "100vh",
        bgcolor: "background.paper", // color de fondo según el tema de MUI
        borderRight: "1px solid #e0e0e0",
        boxShadow: 2, // agrega una ligera sombra
        p: 2, // padding interno
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.value}>
            <ListItem>
              <ListItemButton onClick={() => onSelect(item.value)}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
            {/* Agregamos un divisor entre items (opcional) */}
            {index < menuItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
