import React from "react";

import Bannerhome from "../../../components/Bannerhome/Bannerhome";
import Tyc from "../postcontent/Tyc/Tyc";
import ContainerViewComponent from "../../../components/ContainerViewComponent/ContainerViewComponent";

const Terminosycondiciones = () => {
  return (
    <div>
      <Bannerhome />
      <ContainerViewComponent className="bodycontent">
        <div>
          <Tyc />
        </div>
      </ContainerViewComponent>
    </div>
  );
};

export default Terminosycondiciones;
