import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  LinearProgress,
  Box,
  TextField,
} from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { NavLink } from "react-router-dom";
import { Avatar } from "@mui/material";
import { format } from "date-fns";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import Chip from "@mui/material/Chip";
import ValidationsSize from "../../../../utils/ValidationsSize";
import StraightenIcon from "@mui/icons-material/Straighten";
import getValidationAdressGeoRef from "../../../../utils/getValidationAdressGeoRef";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const ToolDashOrdersContainer = (props) => {
  const [isActive, setIsACtive] = React.useState(false);
  const [isActiveBike, setIsACtiveBike] = React.useState(false);
  const [isActiveSize, setIsActiveSize] = React.useState(false);
  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);
  const [isloadValidations, setIsloadValidations] = React.useState(false);
  const [dataSearch, setDataSearch] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [render, setRender] = React.useState(true);

  useEffect(() => {
    //  //console.log("Render");
  }, [render]);

  useEffect(() => {
    newOrder();
  }, [isActive, isActiveBike, isActiveSize, props.dataMerge]);

  useEffect(() => {
    filterData(dataSearch);
  }, [dataSearch]);

  async function newOrder() {
    if (isActive === true) {
      async function ordenarPorZoneName(arr) {
        arr.sort((a, b) => {
          if (a.commune2 === null) return -1;
          if (b.commune2 === null) return 1;
          return a.commune2.localeCompare(b.commune2);
        });
      }
      await ordenarPorZoneName(dataMergeLocalOrder);
      setDataMergeLocalOrder(dataMergeLocalOrder);
      setRender(!render);
    }

    if (isActiveBike === true) {
      function ordenarPorDriverName(arr) {
        arr.sort((a, b) => {
          // Coloca los valores 'null' al principio
          if (a.driverName === null) return -1;
          if (b.driverName === null) return 1;

          // Coloca los valores 'undefined' o vacíos al final
          if (typeof a.driverName === "undefined" || a.driverName === "")
            return 1;
          if (typeof b.driverName === "undefined" || b.driverName === "")
            return -1;

          // Compara alfabéticamente los demás valores
          return a.driverName.localeCompare(b.driverName);
        });
      }

      await ordenarPorDriverName(dataMergeLocalOrder);
      setDataMergeLocalOrder(dataMergeLocalOrder);
      setRender(!render);
    }

    if (isActiveSize === true) {
      async function ordenarPorSize(arr) {
        arr.sort((a, b) => a.size.localeCompare(b.size));
      }
      await ordenarPorSize(dataMergeLocalOrder);
      setDataMergeLocalOrder(dataMergeLocalOrder);
      setRender(!render);
    }

    if (
      isActive === false &&
      isActiveBike === false &&
      isActiveSize === false
    ) {
      setDataMergeLocalOrder(props.dataMerge);
      setRender(!render);
    }
  }

  const handleRowsOrder = (e) => {
    if (e.currentTarget.id === "orderZone") {
      if (isActive === false) {
        setIsACtive(true);
        setIsACtiveBike(false);
        setIsActiveSize(false);
      } else {
        setIsACtive(false);
      }
    }
    if (e.currentTarget.id === "orderDrives") {
      if (isActiveBike === false) {
        setIsACtive(false);
        setIsActiveSize(false);
        setIsACtiveBike(true);
      } else {
        setIsACtiveBike(false);
      }
    }
    if (e.currentTarget.id === "orderSize") {
      if (isActiveSize === false) {
        setIsACtive(false);
        setIsACtiveBike(false);
        setIsActiveSize(true);
      } else {
        setIsActiveSize(false);
      }
    }
  };

  const FindValidationAddres = async () => {
    let orders = props.dataMerge;
    setIsloadValidations(true);
    for (let n = 0; n < orders.length; n++) {
      const direcctioToValidate = `${orders[n].address}, ${orders[n].commune2}, ${orders[n].commune1}`;
      //console.log(direcctioToValidate);
      let rest = await getValidationAdressGeoRef(direcctioToValidate);
      //console.log(rest.response);
      if (rest.response === "OK") {
        orders[n].alertAddresError = false;
      } else {
        orders[n].alertAddresError = true;
      }
    }
    setIsloadValidations(false);
    setDataMergeLocalOrder(orders);
    setRender(!render);
  };

  const filterData = async (dataSearch) => {
    const filterFunction = (obj, searchTerm) => {
      for (let key in obj) {
        const value = obj[key];
        if (typeof value === "string" && value.includes(searchTerm)) {
          return true;
        }
        if (typeof value === "object") {
          if (filterFunction(value, searchTerm)) {
            return true;
          }
        }
      }
      return false;
    };

    const filteredData = dataMergeLocalOrder.filter((item) =>
      filterFunction(item, dataSearch)
    );

    const rowsResult = filteredData.map((item) => {
      // Aquí puedes mapear los campos del objeto filtrado a la estructura que necesites
      return createData(
        item["id"],
        item["customer_name"],
        item["customer_phone"],
        item["customer_email"],
        item["driverName"],
        item["upDatedAtText"],
        item["address"],
        item["commune1"],
        item["id"],
        item["driverAvatar"]
      );
    });

    // console.log(rowsResult);
    setRows(rowsResult);
  };

  function createData(
    OrderID,
    Cliente,
    ClienteTelefono,
    ClienteEmail,
    Conductor,
    Fecha,
    Adress,
    Zona,
    Detalles,
    driverAvatar
  ) {
    return {
      OrderID,
      Cliente,
      ClienteTelefono,
      ClienteEmail,
      Conductor,
      Fecha,
      Adress,
      Zona,
      Detalles,
      driverAvatar,
    };
  }

  function formatDate(element) {
    try {
      // Intenta formatear la fecha
      return element.date
        ? format(element.date.seconds * 1000, "dd/MM/yy HH:mm:ss")
        : element.createdAt
        ? format(element.createdAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
        : "No Date";
    } catch (error) {
      // Maneja el error si el formateo falla
      console.log("Error al formatear la fecha:", error);
      return "Formato de fecha inválido"; // O cualquier mensaje o valor predeterminado que desees
    }
  }

  function formatDateUpdatedAt(element) {
    try {
      // Intenta formatear la fecha
      return element.updatedAt
        ? format(element.updatedAt.seconds * 1000, "dd/MM/yy HH:mm:ss")
        : "No date updatedAt";
    } catch (error) {
      // Maneja el error si el formateo falla
      console.log("Error al formatear la fecha:", error);
      return "Formato de fecha inválido"; // O cualquier mensaje o valor predeterminado que desees
    }
  }

  if (props.movil === true) {
    return (
      <Card className="card-ultimasOrdenes typeMiniforms">
        <CardHeader title="Últimas Solicitudes" />

        {props.dataMerge ? (
          <div className="table-card">
            {props.dataMerge.map((element, index) => (
              <div key={index} className="card">
                <div className="card-header">
                  <input
                    className="fontsize25"
                    type="checkbox"
                    defaultChecked={element.setectedItem}
                    id={element.id.toString()}
                    name={element.dispachtCode}
                    onChange={props.handleOrdersForAccions}
                  />
                  <span className="code">{`F${element.id}`}</span>
                </div>
                <div className="card-body">
                  <div className="card-info">
                    <div>
                      <span>Origen:</span> {element.placeName}
                    </div>
                    <div>
                      <span>Fecha de envío:</span>{" "}
                      {format(element.createdAt.seconds * 1000, "dd/MM/yy")}
                    </div>
                    <div>
                      <span>SOC:</span> {element.soc}
                    </div>
                    <div>
                      <span>Zonas:</span> {element.commune2}
                    </div>
                    <div>
                      <span>F. Creado:</span>{" "}
                      {format(element.date.seconds * 1000, "dd/MM/yy HH:mm:ss")}
                    </div>
                    <div>
                      <span>Ultimo Registro:</span>{" "}
                      {format(
                        element.updatedAt.seconds * 1000,
                        "dd/MM/yy HH:mm:ss"
                      )}
                    </div>
                    <div>
                      <span>Estatus:</span>{" "}
                      {element.falabella_status ? (
                        <>{element.falabella_status}</>
                      ) : (
                        <NewReleasesIcon className="pinEstatusCancel" />
                      )}
                    </div>
                    <div>
                      <span>Detalle:</span>{" "}
                      <NavLink
                        to={{
                          pathname: `/dashboard/dispatch/details/F${element.id}`,
                        }}
                        element={element}
                        color="primary"
                        variant="contained"
                        sx={{
                          backgroundColor: "#030303",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        ver
                      </NavLink>
                    </div>
                  </div>
                  <div className="card-driver">
                    <Avatar
                      src={element.driverAvatar}
                      sx={{ bgcolor: "#f2f2f2" }}
                      aria-label="recipe"
                    />
                    <div>{element.driverName}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <LinearProgress />
        )}
      </Card>
    );
  }
  if (props.movil === false) {
    return (
      <Card className="card-ultimasOrdenes typeMiniforms">
        <CardHeader
          title="Últimas Solicitudes"
          subheader={
            <div>
              <div id="inputstilesforce">
                <Box sx={{ display: "inline-block", margin: "3px" }}>
                  <TextField
                    variant="outlined"
                    placeholder="Buscar..."
                    type="text"
                    onChange={(e) => setDataSearch(e.target.value)}
                    value={dataSearch}
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                      fontSize: "inherit",
                      height: "auto",
                      width: "100px",
                      outline: "none",
                      paddingRight: "44px",
                      position: "relative",
                      textAlign: "left",
                      appearance: "none",
                      backgroundImage: "none",
                      maxWidth: "250px",
                    }}
                  />
                </Box>
              </div>
              {isActive ? (
                <>
                  <Chip
                    label={"Agrupación Zona"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderZone"}
                    icon={
                      <GroupWorkIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#1ef708",
                    }}
                  />{" "}
                </>
              ) : (
                <>
                  <Chip
                    label={"Agrupación Zona"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderZone"}
                    icon={
                      <GroupWorkIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#e6e6e6",
                    }}
                  />{" "}
                </>
              )}
              {isActiveBike ? (
                <>
                  <Chip
                    label={"Agrupación Riders"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderDrives"}
                    icon={
                      <DirectionsBikeIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#1ef708",
                    }}
                  />{" "}
                </>
              ) : (
                <>
                  <Chip
                    label={"Agrupación Riders"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderDrives"}
                    icon={
                      <DirectionsBikeIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#e6e6e6",
                    }}
                  />{" "}
                </>
              )}
              {isActiveSize ? (
                <>
                  <Chip
                    label={"Agrupación Medidas"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderSize"}
                    icon={
                      <StraightenIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#1ef708",
                    }}
                  />{" "}
                </>
              ) : (
                <>
                  <Chip
                    label={"Agrupación Medidas"}
                    onClick={handleRowsOrder}
                    clickable
                    id={"orderSize"}
                    icon={
                      <StraightenIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    }
                    style={{
                      color: "#010101",
                      backgroundColor: "#e6e6e6",
                    }}
                  />{" "}
                </>
              )}
              <Chip
                label={isloadValidations ? "Validando" : "Validar Direcciones"}
                onClick={FindValidationAddres}
                clickable
                id={"orderSize"}
                icon={
                  <TrackChangesIcon
                    style={{
                      color: "#fff",
                    }}
                  />
                }
                style={{
                  color: "#fff",
                  backgroundColor: isloadValidations ? "#909ea3" : "#3446eb",
                }}
              />{" "}
              {isloadValidations ? (
                <div className="m-2">
                  <LinearProgress></LinearProgress>{" "}
                </div>
              ) : null}
            </div>
          }
        />
        {dataSearch.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell align="right">Conductor</TableCell>
                  <TableCell align="right">Fecha</TableCell>
                  <TableCell align="right">Zona</TableCell>
                  <TableCell align="right">Ver&nbsp;Detalles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div>
                        <div>
                          <strong>{row.OrderID}</strong>
                        </div>
                        <div>{row.Cliente}</div>
                        <div>9{row.ClienteTelefono}</div>
                        <div>{row.ClienteEmail}</div>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <div>
                        <div>
                          <Avatar
                            src={row.driverAvatar}
                            sx={{ bgcolor: "#f2f2f2" }}
                            aria-label="recipe"
                          />
                        </div>
                        <div>
                          {row.Conductor ? row.Conductor : "No Asignado"}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="right">{row.Fecha}</TableCell>
                    <TableCell align="right">{row.Zona}</TableCell>
                    <TableCell align="right">
                      <NavLink
                        to={{
                          pathname: `/dashboard/dispatch/details/F${row.OrderID}`,
                        }}
                        color="primary"
                        variant="contained"
                        sx={{
                          backgroundColor: "#030303",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        {" "}
                        ver{" "}
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        <Box sx={{ minWidth: 800 }}>
          {props.dataMerge ? (
            <table className="table table-card">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Código</th>
                  <th scope="col">Origen</th>
                  <th scope="col">F12</th>
                  <th scope="col">Vol/Peso</th>
                  <th scope="col">Zonas</th>
                  <th scope="col">F. Creado</th>

                  <th scope="col">Ultimo Registro</th>
                  <th scope="col">Estatus</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Detalle</th>
                  {props.containerHistory &&
                  Object.keys(props.containerHistory).length !== 0 ? (
                    <th scope="col">Historial</th>
                  ) : null}
                  <th scope="col">Conductor</th>
                </tr>
              </thead>
              <tbody>
                {props.dataMerge.map((element, index) => {
                  if (element) {
                    console.log(element);
                    return (
                      <tr key={index}>
                        <td>
                          <div>
                            <div>{index + 1} </div>
                            <div style={{ position: "relative" }}>
                              {element.price > 200000 && (
                                <AttachMoneyIcon
                                  sx={{
                                    color: "#ff8f00",
                                    borderColor: "1px solid #ff8f00",
                                    borderRadius: "50%",
                                    backgroundColor: "#feff00",
                                    fontSize: "1em",
                                    fontWeight: "bold",
                                    width: "1.5em", // Aumenta el tamaño de la moneda
                                    height: "1.5em", // Aumenta el tamaño de la moneda
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    boxShadow:
                                      "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                                  }}
                                ></AttachMoneyIcon>
                              )}

                              {element.price > 400000 && (
                                <AttachMoneyIcon
                                  sx={{
                                    color: "#ff8f00",
                                    borderColor: "1px solid #ff8f00",
                                    borderRadius: "50%",
                                    backgroundColor: "#feff00",
                                    fontSize: "1em",
                                    fontWeight: "bold",
                                    width: "1.5em", // Aumenta el tamaño de la moneda
                                    height: "1.5em", // Aumenta el tamaño de la moneda
                                    position: "absolute",
                                    top: "0",
                                    left: "1em", // Establece la distancia horizontal entre las monedas
                                    boxShadow:
                                      "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                                  }}
                                ></AttachMoneyIcon>
                              )}

                              {element.price > 900000 && (
                                <AttachMoneyIcon
                                  sx={{
                                    color: "#ff8f00",
                                    borderColor: "1px solid #ff8f00",
                                    borderRadius: "50%",
                                    backgroundColor: "#feff00",
                                    fontSize: "1em",
                                    fontWeight: "bold",
                                    width: "1.5em", // Aumenta el tamaño de la moneda
                                    height: "1.5em", // Aumenta el tamaño de la moneda
                                    position: "absolute",
                                    top: "0",
                                    left: "2em", // Establece la distancia horizontal entre las monedas
                                    boxShadow:
                                      "-1px -1px 3px 1px rgba(0,0,0,0.75);",
                                  }}
                                ></AttachMoneyIcon>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            className="fontsize25"
                            type="checkbox"
                            defaultChecked={element.setectedItem}
                            id={element.id}
                            name={element.dispachtCode}
                            onChange={props.handleOrdersForAccions}
                          />
                        </td>
                        <td>
                          <div>
                            <div>{element.id}</div>
                            <div>
                              soc:{" "}
                              {element.soc
                                ? element.soc
                                : element.int_client_soc
                                ? element.soc
                                : element.int_client_soc}
                            </div>
                            <div>
                              lpn: {element.lpn ? element.lpn : element.int_lpn}
                            </div>
                          </div>
                        </td>
                        <td>
                          {element.placeName
                            ? element.placeName
                            : element.int_placeName}
                        </td>
                        <td>{element.do ? element.do : element.int_f12}</td>
                        <td>
                          <h5>{element.size}</h5>
                          <ValidationsSize
                            id={element.id}
                            weight={element.weight}
                            // multibulk={element.MULTIBULTO}
                            length={element.length}
                            height={element.height}
                            width={element.width}
                            units={element.quantity_packages}
                            // volume={element.VOLUMEN}
                            packeSize={element.size}
                          ></ValidationsSize>
                        </td>

                        <td>{element.commune2}</td>

                        <td>{formatDate(element)}</td>

                        <td>{formatDateUpdatedAt(element)}</td>

                        <td>
                          {element.falabella_status ? (
                            <>{element.falabella_status}</>
                          ) : (
                            <>
                              {element.int_falabella_status ? (
                                <>{element.int_falabella_status}</>
                              ) : (
                                <NewReleasesIcon className="pinEstatusCancel" />
                              )}
                            </>
                          )}
                        </td>

                        <td>
                          {element.alertAddresError ? (
                            <>
                              {" "}
                              {element.alertAddresError === true ? (
                                <span className="alert  background-red-color fontf8">
                                  Dir Error
                                </span>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {" "}
                              {element.alertAddresError === false ? (
                                <span className="alert  colorGreen fontf8">
                                  Dir Ok
                                </span>
                              ) : null}
                            </>
                          )}
                        </td>
                        <td className="minimalTd">
                          {props.containerHistory &&
                          Object.keys(props.containerHistory).length !== 0 &&
                          props.containerHistory[element.soc] ? (
                            <span>
                              {props.containerHistory[element.soc].length <=
                              4 ? (
                                <>
                                  1 Intento{" "}
                                  {
                                    props.translateStateBicciFalabella[
                                      element.falabella_status
                                    ]
                                  }
                                </>
                              ) : (
                                <>
                                  Con Excepciones{" "}
                                  {
                                    props.translateStateBicciFalabella[
                                      element.falabella_status
                                    ]
                                  }
                                </>
                              )}
                            </span>
                          ) : (
                            <span>
                              {" "}
                              <span
                                className={
                                  element.falabella_status &&
                                  props.styleStateBicciFalabella[
                                    element.falabella_status
                                  ]
                                    ? `${
                                        props.styleStateBicciFalabella[
                                          element.falabella_status
                                        ]
                                      }`
                                    : "Undefine"
                                }
                              >
                                {
                                  props.translateStateBicciFalabella[
                                    element.falabella_status
                                  ]
                                }
                              </span>
                            </span>
                          )}
                        </td>
                        {props.containerHistory &&
                        Object.keys(props.containerHistory).length !== 0 ? (
                          <td>
                            {props.containerHistory &&
                            Object.keys(props.containerHistory).length !== 0 &&
                            props.containerHistory[element.soc] ? (
                              <span>
                                <ul className="list-dots mb-0">
                                  {props.containerHistory[element.soc].map(
                                    (item) => (
                                      <li key={`id${item.id}`}>
                                        {
                                          props.translateStateBicciFalabella[
                                            item.status
                                          ]
                                        }
                                      </li>
                                    )
                                  )}
                                </ul>
                              </span>
                            ) : (
                              <span>
                                {" "}
                                <span
                                  className={
                                    element.falabella_status &&
                                    props.styleStateBicciFalabella[
                                      element.falabella_status
                                    ]
                                      ? `${
                                          props.styleStateBicciFalabella[
                                            element.falabella_status
                                          ]
                                        }`
                                      : "Undefine"
                                  }
                                >
                                  {
                                    props.translateStateBicciFalabella[
                                      element.falabella_status
                                    ]
                                  }
                                </span>
                              </span>
                            )}
                          </td>
                        ) : null}
                        <td>{element.driverName}</td>
                        <td>
                          <NavLink
                            to={{
                              pathname: `/dashboard/dispatch/details/F${element.id}`,
                            }}
                            element={element}
                            color="primary"
                            variant="contained"
                            sx={{
                              backgroundColor: "#030303",
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            {" "}
                            ver{" "}
                          </NavLink>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={index}>
                        <td>{element.id}</td>
                        <td> Validar si se gestiona en Otra Plataforma</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>

                        <td></td>

                        <td></td>

                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{element.driverName}</td>
                        <td></td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          ) : (
            <LinearProgress></LinearProgress>
          )}
        </Box>
      </Card>
    );
  }
  return <div>No se reconose el dispositivo</div>;
};

export default ToolDashOrdersContainer;
