export const styleState = {
  Terminado: "alert alert-success",
  8: "alert alert-success",
  C0E: "alert alert-primary",
  C01: "alert alert-info",
  C02: "alert alert-info",
  C03: "alert alert-info",
  R01: "alert alert-success",
  R02: "alert alert-success",
  R03: "alert alert-success",
  R04: "alert alert-success",
  R05: "alert alert-success",
  R06: "alert alert-success",
  CA1: "alert alert-danger",
  CA2: "alert alert-danger",
  CA3: "alert alert-danger",
  CA4: "alert alert-danger",
  Devolucion: "alert alert-danger",
  1: "alert alert-danger",
  99: "alert  colorGreen fontf8",
  1058: "alert alert-primary",
  1059: "alert alert-warning",
  1060: "alert alert-info",
  1061: "alert alert-info",
  1041: "alert alert-danger",
  1046: "alert alert-danger",
  1031: "alert alert-danger",
  1002: "alert alert-danger",
  1005: "alert alert-danger",
  1006: "alert alert-danger",
  1007: "alert alert-danger",
  1008: "alert alert-danger",
  1009: "alert alert-danger",
  1017: "alert alert-danger",
  1019: "alert alert-danger",
  1022: "alert alert-danger",
  1023: "alert alert-danger",
  1030: "alert alert-danger",
  1027: "alert alert-danger",
  1032: "alert alert-danger",
  1034: "alert alert-danger",
  1038: "alert alert-danger",
  1036: "alert alert-danger",
};

export const stlyleClassStates = {
  Terminado: "pinEstatusDelivery",
  Pendiente: "pinEstatusNoCreate",
  Devolucion: "alert alert-danger",
  isRequire: "pinEstatusNoCreate",
  0: "pinEstatusNoCreate",
  1: "pinEstatusInRoute",
  2: "pinEstatusInRoute",
  3: "pinEstatusInRoute",
  4: "pinEstatusInRoute",
  5: "pinEstatusInRoute",
  6: "pinEstatusInRoute",
  7: "pinEstatusDelivery",
  8: "pinEstatusDelivery",
  9: "pinEstatusDelivery",
  10: "pinEstatusDelivery",
};

// Paleta de colores para rutas
export const colorPalette = [
  "blue",
  "red",
  "green",
  "orange",
  "purple",
  "pink",
  "cyan",
  "magenta",
  "yellow",
  "brown",
  "teal",
  "lime",
  "indigo",
  "violet",
  "navy",
  "maroon",
  "olive",
  "chocolate",
  "coral",
  "crimson",
  "darkblue",
  "darkgreen",
  "darkmagenta",
  "darkorange",
  "darkred",
  "darkcyan",
  "darkviolet",
  "deepskyblue",
  "dodgerblue",
  "gold",
  "hotpink",
  "khaki",
  "lightcoral",
  "lightgreen",
  "lightsalmon",
  "lightskyblue",
  "lightseagreen",
  "mediumorchid",
  "mediumspringgreen",
  "peru",
  "plum",
  "rosybrown",
  "salmon",
  "sienna",
  "slateblue",
  "steelblue",
  "tomato",
  "tan",
  "thistle",
  "turquoise",
];

export const colorByStatus = {
  "-1": "#808080",
  0: "#6993a3",
  1: "#8dcfe9",
  2: "#1aa7df",
  3: "#7f8fe3",
  4: "#687de9",
  5: "#455edf",
  6: "#1231d5",
  7: "#63d77d",
  8: "#08a12b",
  9: "#08a12b",
};
export const contrastColorByStatus = {
  "-1": "#FFFFFF", // Gris oscuro -> Texto blanco
  0: "#FFFFFF", // Azul grisáceo -> Texto blanco
  1: "#000000", // Azul claro -> Texto negro
  2: "#FFFFFF", // Azul brillante -> Texto blanco
  3: "#FFFFFF", // Azul púrpura -> Texto blanco
  4: "#FFFFFF", // Azul medio -> Texto blanco
  5: "#FFFFFF", // Azul más oscuro -> Texto blanco
  6: "#FFFFFF", // Azul muy oscuro -> Texto blanco
  7: "#000000", // Verde claro -> Texto negro
  8: "#FFFFFF", // Verde oscuro -> Texto blanco
  9: "#FFFFFF", // Verde oscuro -> Texto blanco
};
